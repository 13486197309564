import { useState } from "react";

function useHandleStep(initStep) {
  const [step, setStep] = useState(initStep);
  const goToStep = (nextStep) => {
    setStep(nextStep);
  };
  return { step, goToStep };
}

export default useHandleStep;
