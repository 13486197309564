// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { GenericButton } from "../../compoents/Button";
import { PATH, TOAST_STYLE } from "../../config";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
// import ProductDataService from "../../redux/services/product.service";
import PageTitle from "../../compoents/PageTitle";
import InventoryBasicInformation from "./inventory-basic-information";
import InventoryVisibility from "./inventory-visibility";
import InventoryProductStats from "./inventory-product-stats";
import InventoryOtherInfo from "./inventory-other-info";
// import InventoryInventory from "./inventory-inventory";
// import InventoryImages from "./inventory-images";
import InventoryVariations from "./inventory-variations";
import { toast } from "react-toastify";

const EditInventoryProduct = () => {
  let match = useRouteMatch(PATH.EDIT_INVENTORY_PRODUCT);
  const history = useHistory();
  let { productId } = match.params;
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const variantRef = useRef(null);

  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState();

  const [saveVariations, setSaveVariations] = useState(false);
  const [saveImages, setSaveImages] = useState(false);

  const [updatedData, setUpdatedData] = useState();

  //
  const inputChangeHandler = (e) => {
    console.log("inputChangeHandler:", e);
    setUpdatedData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // variations logic

  useEffect(() => {
    (async () => {
      const { data } = await sellerApiService.getProduct(productId);

      const { product } = data;
      setProduct(product);
      setUpdatedData({ ...product, category: product.category.id });

      let category =
        JSON.parse(localStorage.getItem("selectedCategory")) ||
        product.category;
      if (category) {
        if (Object.keys(category).length > 0) {
          const res = await sellerApiService.getCategory(category.id);
          if (res.data) {
            const category = res.data;
            setCategory(category);
          }
        }
      }
    })();
  }, []);

  //variations logic ended
  const checkValidData = (data) => {
    const mandateFields = [
      "name",
      // "brand",
      "features",
      "country",
      "description",
      // "legal",
      "hsn",
      "gst",
      "weight",
      "dimensions",
      "mfg",
    ];
    const filteredData = Object.keys(data).filter((field) => {
      return mandateFields.includes(field) && !data[field];
    });
    if (filteredData.length) {
      toast.error("Please fill all field which is marked as *", TOAST_STYLE);
      return false;
    }
    // if (!(data.hsn && data.gst && data.weight && data.dimensions && data.mfg)) {
    //   toast.error("Please fill all field which is marked as *", TOAST_STYLE);
    //   return false;
    // }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const dimensions = updatedData.dimensions;
    const dimensionsRegex = /^[0-9]+[*,x,X][0-9]+[*,x,X][0-9]+$/;
    if (!dimensionsRegex.test(dimensions)) {
      return toast.error("Dimension should be in LxBxH or LXBXH or L*B*H.");
    }
    const invalidVariant = variantRef?.current?.validateVariation();
    if (invalidVariant) {
      toast.error("Please fill highlighted variant row.");
      return;
    }
    console.log("updatedData::", updatedData);
    if (!checkValidData(updatedData)) return;
    const payload = Object.assign({}, updatedData);
    payload["options_list"] = payload.variants[0].options_list;
    const res = await sellerApiService.updateProduct(payload);
    if (res.data) {
      setSaveVariations(true);
      toast.success("Product updated successfully", TOAST_STYLE);
      return history.push(`/inventory-product-detail/productId=${productId}`);
      // const res = await sellerApiService.getProduct(updatedData.id);
      // if (res.data) {
      //   const { product } = res.data;
      //   localStorage.setItem("product", JSON.stringify(product));

      //   setSaveImages(true);
      // }
    }
  };
  return (
    <div>
      <PageTitle text="Inventory" />
      <form onSubmit={handleSubmit} method="POST">
        {product && (
          <>
            <Row className="justify-content-between align-items-center mb-4">
              <Col xs={6} md={4}>
                <h3 className="mb-0">Edit Products</h3>
              </Col>
              <Col xs={6} md={4} className="text-end">
                <GenericButton borderradius="11px" className="" type="submit">
                  Save
                </GenericButton>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <InventoryBasicInformation
                  product={product}
                  inputChangeHandler={inputChangeHandler}
                />
              </Col>
              <Col xs={12} md={6}>
                <InventoryOtherInfo
                  product={product}
                  inputChangeHandler={inputChangeHandler}
                />
              </Col>
              {/* <Col xs={12} md={4} className="mt-3 mt-md-0">
                <InventoryVisibility
                  product={product}
                  inputChangeHandler={inputChangeHandler}
                />
                <InventoryProductStats product={product} />
              </Col> */}
            </Row>
            {/* <InventoryOtherInfo
              product={product}
              inputChangeHandler={inputChangeHandler}
            /> */}
            <InventoryVariations
              ref={variantRef}
              product={product}
              inputChangeHandler={inputChangeHandler}
              updatedData={updatedData}
              category={category}
              saveVariations={saveVariations}
            />
          </>
        )}
      </form>
    </div>
  );
};

export default EditInventoryProduct;
