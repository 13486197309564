import { Modal } from "react-bootstrap";
import React from "react";
import { GenericButton } from "../Button";
import { AiFillCloseCircle } from "react-icons/ai";

const GenericModal = ({
  show,
  children,
  tittle,
  onHide,
  size = "md",
  customModalClassName = "",
  customCloseIconClassName = "",
  showCloseIcon = false,
  handleClose = () => {},
}) => {
  return (
    <Modal
      className={`generic__modal ${customModalClassName}`}
      show={show}
      size={size}
      onHide={onHide}
    >
      <div className="generic__modalTittle">
        {tittle}
        {showCloseIcon && (
          <AiFillCloseCircle
            className={`closeIcon ${customCloseIconClassName}`}
            role="button"
            onClick={handleClose}
          />
        )}
      </div>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default GenericModal;
