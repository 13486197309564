import React, { useEffect, useState } from "react";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import OrderTable from "./OrderTable";
import CustomLoader from "../../compoents/common/CustomLoader";

const ReadyToShip = () => {
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [readyToShip, setReadyToShip] = useState([]);
  const [fetching, setFetching] = useState(true);

  const fetchReadyShippedOrder = async () => {
    try {
      const res = await sellerApiService.getConfirmedOrders();
      if (res.data) {
        const { orders } = res.data;
        setReadyToShip(orders);
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.warn("Error@fetchHoldOrder", error);
    }
  };

  useEffect(() => {
    fetchReadyShippedOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const res = await sellerApiService.getConfirmedOrders();
  //     if (res.data) {
  //       const { orders } = res.data;
  //       setReadyToShip(orders);
  //     }
  //   })();
  // }, []);

  return (
    <>
      {fetching && <CustomLoader />}
      <OrderTable data={readyToShip} orderStatus="readyToShip" />
    </>
  );
};

export default ReadyToShip;
