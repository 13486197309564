import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const StyledTable = styled.div`
  .tableWrraperClass {
    max-width: 100%;
    overflow: auto;
    .w-140 {
      width: 140px;
    }
    .mx-w-180 {
      width: 180px;
    }
    .w-80 {
      width: 80px;
    }
    table {
      tbody {
        background: rgb(255, 255, 255);
      }
      border-collapse: collapse;

      .tableHeader {
        background: gray;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        .titleHeadClass {
          width: 447px;
        }
      }
      .rowClasses {
        td {
          font-size: 14px;
          font-weight: 500;
          vertical-align: middle;
          text-align: center;
          .bodyTitle {
            display: flex;
            gap: 15px;
            width: 447px;
            .imageWrapper {
              display: flex;
              alignitems: center;
              width: 15%;
              .productImage {
                objectfit: cover;
              }
            }
            .bodyTitleDesc {
              width: 85%;
              display: flex;
              align-items: center;
              margin-bottom: 0;
            }
          }
          .actionBtn {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            button {
              width: 100% !important;
              border-radius: 4px !important;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
  .rtoAction {
    padding: 1rem 0;
    color: #000;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: blue;
    }
  }
`;

function ReturnOrderTable({ data, isRto = false }) {
  const columns = [
    {
      dataField: "product",
      text: "Product details",
      headerClasses: () => "titleHeadClass",
      formatter: (cell, row) => {
        return (
          <div className="bodyTitle">
            <div className="imageWrapper">
              <img className="w-100 productImage" src={row?.image} alt="" />
            </div>
            <p className="bodyTitleDesc">{cell}</p>
          </div>
        );
      },
    },
    {
      dataField: "order.order_ref",
      text: "Sub order ID",
      headerClasses: () => "w-140 text-center",
      formatter: (cell) => `#${cell}`,
    },
    {
      dataField: "order.sku",
      headerClasses: () => "w-140 text-center",
      text: "SKU ID",
    },
    {
      dataField: "",
      text: "Order Detail",
      headerClasses: () => "w-140 text-center",
      formatter: (_, row) => {
        if (!row?.order?.details) {
          return <div>N/A</div>;
        }
        return (
          <div>
            {Object.keys(row?.order?.details)?.map((detail) => (
              <React.Fragment key={detail}>
                <strong>{detail} :</strong>
                {row?.order?.details?.[detail]
                  ? row?.order?.details?.[detail]
                  : " N/A "}
                <br />
              </React.Fragment>
            ))}
          </div>
        );
      },
    },
    {
      dataField: "order",
      text: "Reason of Returning",
      headerClasses: () => "w-140 text-center",
      formatter: (cell) => (
        <>{cell?.return_reason ? cell?.return_reason : "-"}</>
      ),
    },
    {
      dataField: "order.created_at",
      headerClasses: () => "w-140 text-center",
      text: "Ordered on",
      formatter: (cell) => moment(cell).format("DD MMM, hh:mm A"),
    },
    {
      dataField: "order.quantity",
      headerClasses: () => "w-140 text-center",
      text: "Qty",
    },
    {
      dataField: "order.purchase_mode",
      text: "Type",
      headerClasses: () => "w-80 text-center",
    },
    {
      dataField: "order.amount",
      headerClasses: () => "w-140 text-center",
      text: "Amount",
      formatter: (cell) => <>{`₹${cell}/-`}</>,
    },
    {
      dataField: "order",
      text: "Order Status",
      headerClasses: () => "w-140 text-center",
      formatter: (cell) =>
        isRto ? (
          <Link
            className="badge rtoAction"
            to={"/order-detail/orderId=" + cell.id}
          >
            {cell?.status}
            <IoIosArrowForward size={18} />
          </Link>
        ) : (
          <div
            className="badge"
            style={{
              padding: "1rem 0",
              background: `#B10000  0% 0% no-repeat padding-box`,
              color: `#fff`,
              width: "100%",
            }}
          >
            {cell?.status}
          </div>
        ),
    },
  ];
  return (
    <StyledTable>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        wrapperClasses="tableWrraperClass"
        rowClasses="rowClasses"
        headerClasses="tableHeader"
        noDataIndication="No order found"
        // striped
        // condensed
        hover
      />
    </StyledTable>
  );
}

export default ReturnOrderTable;
