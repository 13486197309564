import styled from "styled-components";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import StyledBox from "../../../compoents/Box";
import { BreadCrumbContainer } from "../AllFaq";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import TicketChat from "./TicketChat";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ticketSelector,
  updateChat,
  updateTicket,
} from "../../../redux/slices/ticket_slice";
import supportService from "../../../redux/services/support.service";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { download_base_url } from "../../../config";

const StyledView = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 90vh;
  max-height: 90vh;
  overflow: hidden;
  position: relative;
  gap: 12px;
  .breadcrum__container {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 12px 24px 0;
    .breadcrumb {
      margin: 0;
    }
    .active {
      font-weight: 600;
    }
  }
  .ticket {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .ticket__headerSection {
      line-height: 100%;
      padding: 0 24px 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .ticket__heading {
        font-size: 16px;
        font-weight: 600;
        line-height: 100%;
      }
      .ticket__date {
        line-height: 100%;
        font-size: 14px;
        color: darkgrey;
      }
    }
    .ticket__contentSection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      .ticket__section {
        padding: 12px 24px 24px;
        border-top: 2px solid #ddd;
        height: 100%;
        overflow: hidden;
      }
      .ticket__section-section1 {
        border-right: 1px solid #ddd;
        border-radius: 0 4px 0 0;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .ticket__status {
          width: fit-content;
        }
        .ticket__description {
          font-size: 16px;
          line-height: 120%;
        }
      }
      .ticket__section-section2 {
        border-left: 1px solid #ddd;
        border-radius: 4px 0 0 0;
        .ticketChat__heading {
          font-size: 14px;
          color: grey;
          font-weight: 400;
          text-decoration: underline;
        }
      }
    }
  }
`;

const GroupedLabelStyled = styled.div`
  line-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  .groupedLabel__text {
    font-size: 14px;
    color: grey;
    font-weight: 400;
    text-decoration: underline;
  }
`;

const MyTickets = () => {
  const ticketObj = useSelector(ticketSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ticketId = location.pathname.split("ticket/")[1];
  const auth = useAuth();
  const token = auth.mee_too_user.token;
  const fetchTicketDetail = async () => {
    try {
      const res = await supportService.fetchTicketById({ id: ticketId, token });
      const { status, data } = res.data;
      if (status === "error")
        return dispatch(updateTicket({ loaded: true, ticket_detail: null }));
      dispatch(updateTicket({ loaded: true, ticket_detail: data }));
    } catch (err) {
      console.log("Error ticket detail", err);
      dispatch(updateTicket({ loaded: true, ticket_detail: null }));
    }
  };

  useEffect(() => {
    fetchTicketDetail();
    return () => dispatch(updateTicket({ loaded: false }));
  }, []);

  const handleChat = (data) => {
    dispatch(updateChat(data));
  };

  const GroupedLabel = ({ children, label = "" }) => {
    return (
      <GroupedLabelStyled>
        <div className="groupedLabel__text">{label}</div>
        <>{children}</>
      </GroupedLabelStyled>
    );
  };

  return (
    <StyledBox
      style={{
        maxWidth: "1200px",
        margin: "auto",
        overflow: "hidden",
      }}
    >
      <StyledView>
        <BreadCrumbContainer className="breadcrum__container">
          <Breadcrumb>
            <Breadcrumb.Item
              href="#"
              onClick={() => {
                history.push("/support", { ticket: true });
              }}
            >
              My Tickets
            </Breadcrumb.Item>
            <Breadcrumb.Item active>#{ticketId}</Breadcrumb.Item>
          </Breadcrumb>
        </BreadCrumbContainer>
        {!ticketObj?.loaded ? (
          "Loading..."
        ) : (
          <div className="ticket">
            <div className="ticket__headerSection">
              <div className="ticket__heading">
                {ticketObj?.ticket_detail?.ticket_type?.name}
              </div>
              <div className="ticket__date">
                Created on {ticketObj?.ticket_detail?.created}
              </div>
            </div>
            <div className="ticket__contentSection">
              <div className="ticket__section ticket__section-section1">
                <GroupedLabel label="Status">
                  <div
                    className={`badge ${
                      ticketObj?.ticket_detail?.status?.toLowerCase() ===
                      "close"
                        ? "bg-success"
                        : "bg-info"
                    } ticket__status`}
                  >
                    {ticketObj?.ticket_detail?.status}
                  </div>
                </GroupedLabel>
                <GroupedLabel label="Description">
                  <div className="ticket__description">
                    {ticketObj?.ticket_detail?.first_comment?.description}
                  </div>
                </GroupedLabel>
                {ticketObj?.ticket_detail?.first_comment?.contain_doc && (
                  <GroupedLabel label="Document">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${download_base_url}${ticketObj?.ticket_detail?.first_comment?.document?.path}`}
                    >
                      {
                        ticketObj?.ticket_detail?.first_comment?.document
                          ?.file_name
                      }
                    </a>
                  </GroupedLabel>
                )}
              </div>
              <div className="ticket__section ticket__section-section2">
                <div className="ticketChat__heading mb-2">Updates</div>
                <TicketChat
                  status={ticketObj?.ticket_detail?.status}
                  token={token}
                  id={ticketId}
                  handleChat={handleChat}
                />
              </div>
            </div>
          </div>
        )}
      </StyledView>
    </StyledBox>
  );
};

export default MyTickets;
