import React from "react";

const Paper = (props) => {
  let { children, className } = props;
  return (
    <div
      className={`fund-raise-genric-paper p-3 bg-white ${className ? className : ""
        }`}
    >
      {children}
    </div>
  );
};

export default Paper;
