import React from "react";
import "./orderSelection.css";

function OrderSelection({
  onSelectOrder,
  onCancelSelection,
  isSelectionEnabled,
  selectedCount = 0,
  onBulkAccept,
}) {
  return (
    <div className="root">
      {!isSelectionEnabled && (
        <button onClick={onSelectOrder} className="selectOrder">
          Select Bulk Order
        </button>
      )}
      {isSelectionEnabled && (
        <>
          <div className="orderCount">{`${selectedCount} order selected`}</div>
          <button onClick={onCancelSelection} className="cancelBtn">
            Clear Selection
          </button>
          {selectedCount > 0 && (
            <button className="orderBtn" onClick={onBulkAccept}>
              Accept Selected
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default OrderSelection;
