import React from 'react'
import { Form } from 'react-bootstrap';


const GenericLabel = (props) => {
    return (
        <Form.Label {...props} className={`r-pos-generic-input-label ${props.className}`}>{props.children}</Form.Label>
    )
}

export default GenericLabel
