import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import styled from "styled-components";

const GenericProgressBar = ({ variant, now, className, ...props }) => {
  const bgColor = (colorVariant) => {
    switch (colorVariant) {
      case "excellent":
        return "#0D845C";
      case "very-good":
        return "#47BD95";
      case "good":
        return " #83DDBA";
      case "average":
        return "#F47006";
      case "poor":
        return "#DC1804";
      default:
        break;
    }
  };
  const StyledProgressBar = styled(ProgressBar)`
    height: 7px;
    background-color: #e7e7e7;
    border-radius: 3px;
    .progress-bar {
      background-color: ${(props) => bgColor(props.variant)} !important;
    }
  `;
  return (
    <StyledProgressBar
      variant={variant}
      now={now}
      className={className}
      {...props}
    />
  );
};

export default GenericProgressBar;
