import styled from "styled-components";

const SliderStyled = styled.div`
  //   background: yellow;
  padding: 60px 3rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .slider__content {
    padding: 40px 0 0 0;
    position: relative;
    .slider__arrow {
      position: absolute;
      top: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      opacity: 0.5;
      color: #fff;
      background: #000;
      transition: 1s ease;
      visibility: hidden;

      &:hover {
        scale: 0.95;
        opacity: 0.9;
      }
    }
    .slider__leftArrow {
      left: 0;
      transform: translate(-50%, -70%);
    }
    .slider__rightArrow {
      right: 0;
      transform: translate(50%, -70%);
    }
  }
  .slider__rightArrow {
    right: 0;
  }
  .slider__heading {
    text-align: center;
    margin: 0;
    font-weight: 700;
  }
  @media (min-width: 1400px) {
    .slider__content {
      max-width: 1320px;
      margin: auto;
    }
  }
  @media (min-width: 1280px) {
    .slider__content {
      padding: 40px 0 0 0;
    }

    .slider__heading {
      font-size: 1.75rem;
    }
  }
  @media (max-width: 1280px) {
    padding: 40px 3rem;
    gap: 16px;
    .slider__content {
      .slider__arrow {
        visibility: visible;
      }
    }
  }
  @media (max-width: 992px) {
    padding: 40px 3rem;
    gap: 16px;
    .slider__content {
      .slider__arrow {
        visibility: visible;
      }
    }
  }
  @media (max-width: 670px) {
    padding: 40px 3rem;
    gap: 16px;
    .slider__content {
      .slider__arrow {
        visibility: visible;
      }
    }
  }
  @media (max-width: 520px) {
    padding: 40px 24px;
    .slider__content {
      .slider__arrow {
        visibility: visible;
        width: 30px;
        height: 30px;
        .slider__icon {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`;

const PartnerSliderStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: row;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  transition: 0.5s ease;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  .customSlider__imageDiv {
    min-width: 190px;
    object-fit: cover;
    display: flex;
    align-items: center;
    // flex-direction: column;
    .customSlider__img {
      width: 80%;
      height: auto;
      margin: 0 auto;
    }
  }
  @media (max-width: 670px) {
    gap: 8px;
  }
  @media (max-width: 520px) {
    gap: 10px;
    .customSlider__imageDiv {
      min-width: 150px;
      .customSlider__img {
        width: 120px;
        height: auto;
      }
      .customSlider__title {
        font-size: 18px;
      }
    }
  }
`;

export { SliderStyled, PartnerSliderStyled };
