// eslint-disable-next-line
import React, { useState } from "react";
import { Badge, Dropdown, DropdownButton, Form } from "react-bootstrap";
import GenericTable from "../../compoents/Table";
import { BiSearch } from "react-icons/bi";
import { AiOutlineDown, AiOutlinePlusSquare } from "react-icons/ai";
import GenericButton from "../../compoents/Button/simpleButton";
import GenericModal from "../../compoents/Modal";
import { GenericInput, GenericLabel } from "../../compoents/Form";
import GenericSelect from "../../compoents/Select";
const ContactsTab = () => {
  const [addContatModal, setAddContatModal] = useState(false);

  const products = [
    {
      id: 1,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
    {
      id: 2,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
    {
      id: 3,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
    {
      id: 4,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
    {
      id: 5,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
    {
      id: 6,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
    {
      id: 7,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
    {
      id: 8,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
    {
      id: 9,
      fullName: "Muhammad Adam",
      primaryEmail: "demo@gmail.com",
      phoneNumber: "xxxx-xxxxxxx",
      tags: [
        {
          tagId: 1,
          tagName: "Muslim",
        },
        {
          tagId: 2,
          tagName: "Volunteer",
        },
      ],
    },
  ];
  const columns = [
    {
      dataField: "fullName",
      classes: "align-middle",
      text: "FULL NAME",
    },
    {
      dataField: "primaryEmail",
      classes: "align-middle",
      text: "PRIMARY EMAIL",
    },
    {
      dataField: "phoneNumber",
      classes: "align-middle",
      text: "PRIMARY PHONE",
    },
    {
      dataField: "tags",
      text: "Tags",
      sort: false,
      classes: "align-middle",
      align: "left",
      formatter: (cell, row) => {
        return (
          <span className="fund-raise-tags-pills">
            {row &&
              row.tags &&
              row.tags.map((tagItem) => (
                <Badge pill variant="secondary">
                  {tagItem.tagName}
                </Badge>
              ))}
          </span>
        );
      },
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {};
      },
    },
  ];

  const onSelectAll = (isSelected) => {
    if (isSelected) {
      return products.map((row) => row.id);
    } else {
      return [];
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
    onSelectAll: onSelectAll,
  };
  return (
    <div>
      <div className="row d-flex align-items-center my-3">
        <div className="col-md-2">
          <div>
            <h5 className="text-h6 text-tags-pills-text-grey ">88 Contacts</h5>
          </div>
        </div>
        <div className="col-md-10">
          <div className="d-flex justify-content-end flex-wrap">
            <DropdownButton
              size="lg"
              className="fund-raise-dropdown filter-drop-down"
              title={
                <div className="d-flex justify-content-between align-items-center">
                  <span>Filter</span> <AiOutlineDown color="#1A95B0" />
                </div>
              }
              id={`dropdown-button-drop`}
              key={1}
            >
              <Dropdown.Item className="w-100" eventKey="1">
                Another action
              </Dropdown.Item>
            </DropdownButton>
            <GenericButton className="button-text font-weight-bold bg-grey-icon-button text-black mx-3">
              <BiSearch size={22} />
            </GenericButton>
            <GenericButton
              className="button-text font-weight-bold bg-main-accent w-180 my-2 m-sm-0 d-flex justify-content-around align-items-center"
              onClick={() => setAddContatModal(true)}
            >
              <span>
                <AiOutlinePlusSquare color="white" size={22} />
              </span>
              <span>Add Contact</span>
            </GenericButton>
          </div>
        </div>
      </div>
      <GenericTable
        className="table-responsive-md"
        selectRow={selectRowProp}
        keyField="id"
        data={products}
        columns={columns}
      />

      {addContatModal && (
        <GenericModal
          show={addContatModal}
          onHide={() => setAddContatModal(false)}
          tittle="Create New Contact"
        >
          <div>
            <div className="my-4">
              <Form.Group controlId="formBasicEmail">
                <GenericLabel>FIRST NAME</GenericLabel>
                <GenericInput type="text" />
              </Form.Group>
            </div>
            <div className="my-4">
              <GenericLabel>FIRST NAME</GenericLabel>
              <GenericSelect />
            </div>
            <div>
              <GenericButton
                className="button-text font-weight-bold bg-main-accent p-3 px-5"
                onClick={() => setAddContatModal(false)}
              >
                Continue
              </GenericButton>
            </div>
          </div>
        </GenericModal>
      )}
    </div>
  );
};

export default ContactsTab;
