import React from "react";
import styled from "styled-components";
import Box from "../../compoents/Box";

const CheckInput = styled.input`
  && {
    :focus,
    :active,
    :checked {
      background-color: #ff4d00;
      border-color: #ff4d00;
      box-shadow: none;
    }
  }
`;

const InventoryVisibility = ({product, inputChangeHandler}) => {
  return (
    <Box className="p-5">
      <h6 className="font-bold mb-3" style={{fontSize: "15px"}}>
        Visibility
      </h6>
      <div className="form-check">
        <CheckInput
          className="form-check-input"
          type="radio"
          name="visibilityRadioBTN"
          id="flexRadioDefault1"
          checked={product.is_active ? product.is_active : false}
          onChange={inputChangeHandler}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          Active
        </label>
      </div>
      <div className="form-check">
        <CheckInput
          className="form-check-input"
          type="radio"
          name="visibilityRadioBTN"
          id="flexRadioDefault2"
          onChange={inputChangeHandler}
          checked={!product.is_active ? product.is_active : null}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault2">
          In Active
        </label>
      </div>
    </Box>
  );
};

export default InventoryVisibility;
