// eslint-disable-next-line
import React, {useState, useEffect} from "react";
import {Row, Col, Alert} from "react-bootstrap";
import {useAuth} from "../Navigation/Auth/ProvideAuth";
import {useDispatch} from "react-redux";
import {GenericButton} from "./Button";
import {setSelectedCategory} from "../redux/slices/category";

import {FiChevronRight} from "react-icons/fi";

import SellerApiService from "../redux/services/seller-api-service";
import GenericModal from "./Modal";

const EditCategoryModal = ({show, onHide}) => {
  let auth = useAuth();
  const [catTick, setCatTick] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [topLevelCategories, setTopLevelCategories] = useState([]);
  const [subCategoriesArray, setSubCategoriesArray] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const [lastSelectedCategory, setLastSelectedCategory] = useState({});

  const dispatch = useDispatch();

  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const submitSelectedCategories = () => {
    dispatch(setSelectedCategory(lastSelectedCategory));
    localStorage.setItem(
      "selectedCategory",
      JSON.stringify(lastSelectedCategory)
    );
    onHide();
  };

  useEffect(
    () =>
      (async () => {
        if (!catTick) {
          const res = await sellerApiService.getAllCategoriesCount(
            auth.mee_too_user.token
          );
          let count = Number(res.data);
          let categories = [];
          if (localStorage.getItem("categories") !== null) {
            let localCategories = JSON.parse(
              localStorage.getItem("categories")
            );
            if (localCategories.length != count) {
              localStorage.removeItem("categories");
              const {data} = await sellerApiService.getAllCategories(
                auth.mee_too_user.token
              );
              categories = data;
              localStorage.setItem("categories", JSON.stringify(categories));
            } else {
              categories = JSON.parse(localStorage.getItem("categories"));
            }
          } else {
            const {data} = await sellerApiService.getAllCategories(
              auth.mee_too_user.token
            );
            categories = data;
            localStorage.setItem("categories", JSON.stringify(categories));
          }

          // getting parent categories here. 1st level caegories.

          let topLevelCategories = categories.filter(
            (cat) => cat.parent_id === null
          );
          const topLevelCategoriesFormatted = topLevelCategories?.map((c) => ({
            id: c.id,
            name: c.name,
          }));

          setTopLevelCategories(topLevelCategoriesFormatted);
          setAllCategories(categories);
          setCatTick(true);
        }
      })(),
    [catTick]
  );

  const onChangeParentCategory = (e, id, name) => {
    const selectedParentCategory = allCategories.find((cat) => cat.id === id);
    const subCategories = allCategories.filter((cat) => cat.parent_id === id);
    if (subCategories && subCategories.length > 0) {
      setAllSelected(false);
      setSubCategoriesArray([subCategories]);
    } else {
      setSubCategoriesArray([]);
      setLastSelectedCategory(selectedParentCategory);
    }

    setSelectedCategories([selectedParentCategory]);
  };

  const onChangeSubCategory = (e, id, subCategoryLevel) => {
    const selectedSubCategory = allCategories.find((cat) => cat.id === id);

    const subCategories = allCategories.filter((cat) => cat.parent_id === id);

    if (subCategories && subCategories.length > 0) {
      setAllSelected(false);

      if (subCategoriesArray.length - 1 === subCategoryLevel) {
        setSubCategoriesArray((prev) => [...prev, subCategories]);
      } else {
        setSubCategoriesArray((prevArray) => {
          prevArray[subCategoryLevel + 1] = subCategories;
          const newArray = prevArray.slice(0, subCategoryLevel + 2);
          return newArray;
        });
      }
    } else {
      setSubCategoriesArray((prev) => prev.slice(0, subCategoryLevel + 1));
      setAllSelected(true);
      setLastSelectedCategory(selectedSubCategory);
    }
    setSelectedCategories((prevArray) => {
      prevArray[subCategoryLevel + 1] = selectedSubCategory;
      const selectedCategories = prevArray.slice(0, subCategoryLevel + 2);
      return selectedCategories;
    });
  };

  return (
    <GenericModal tittle="Edit Category" show={show} size="xl" onHide={onHide}>
      <div className="py-3 px-sm-3 px-0">
        <Row>
          <Col>
            {/* <StyledBox className="h-100 p-4"> */}

            <Row className="categories mt-3">
              {!topLevelCategories.length ? (
                <Alert color="info">Loading Categories ...</Alert>
              ) : (
                <>
                  <h4 className="my-3">Select Category</h4>

                  <Col
                    sm="6"
                    md="4"
                    lg="3"
                    className="border-end mt-3"
                    style={{maxHeight: "300px", overflow: "scroll"}}
                  >
                    {topLevelCategories &&
                      topLevelCategories.map((tLC) => (
                        <button
                          className="w-100 cursor-pointer text-start
                         outline-none border-0 bg-transparent d-block my-3 fw-bolder d-flex justify-content-between align-items-center"
                          style={{
                            fontFamily: "inherit",
                            color: `${
                              selectedCategories.length &&
                              selectedCategories[0].id === tLC.id
                                ? "var(--orange-primary)"
                                : "black"
                            }`,
                          }}
                          onClick={(e) =>
                            onChangeParentCategory(e, tLC.id, tLC.name)
                          }
                        >
                          <span>{tLC.name}</span>
                          <FiChevronRight className="ms-2" size={20} />
                        </button>
                      ))}
                  </Col>
                </>
              )}

              {subCategoriesArray &&
                subCategoriesArray.length > 0 &&
                subCategoriesArray.map((subCategories, subCategoryLevel) => {
                  return (
                    <Col
                      sm="6"
                      md="4"
                      lg="3"
                      className="border-end mt-3"
                      style={{maxHeight: "300px", overflow: "scroll"}}
                    >
                      {subCategories.map((sC) => (
                        <button
                          className="w-100 cursor-pointer text-start
                       outline-none border-0 bg-transparent d-block my-3 fw-bolder d-flex justify-content-between align-items-center"
                          style={{
                            fontFamily: "inherit",
                            color: `${
                              selectedCategories.length &&
                              selectedCategories?.[subCategoryLevel + 1]?.id ===
                                sC.id
                                ? "var(--orange-primary)"
                                : "black"
                            }`,
                          }}
                          onClick={(e) =>
                            onChangeSubCategory(e, sC.id, subCategoryLevel)
                          }
                        >
                          <span>{sC.name}</span>
                          <FiChevronRight className="ms-2" size={20} />
                        </button>
                      ))}
                    </Col>
                  );
                })}
            </Row>
            <h6 className="text-muted mt-4">
              The currently selected:{" "}
              <span className="ms-2" style={{color: "var(--orange-primary"}}>
                {selectedCategories.map((c) => (
                  <>{c.name + " > "} </>
                ))}
              </span>
            </h6>

            {allSelected && (
              <GenericButton
                type="button"
                onClick={submitSelectedCategories}
                style={{width: "200px", borderRadius: "50px"}}
                className="d-block m-auto py-2 my-3 mx-0"
              >
                Done
              </GenericButton>
            )}
            {/* </StyledBox> */}
          </Col>
        </Row>
      </div>
    </GenericModal>
  );
};

export default EditCategoryModal;
