import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ProductDataService from "../services/product.service";

const productInitialState = {
  productLisitFetchLoading: true,
  productListFetchSuccess: false,
  productListFetchFailure: false,
  productListFetchError: null,
  productListFetchData: null,
};

export const productListingThunk = createAsyncThunk(
  "product/listing",
  async (data, { dispactch, getState, rejectWithValue, fulfillWithValue }) => {
    // console.log("productListingThunk => data",data)
    try {
      const res = await ProductDataService.productListing(data);
      if (!res.status === 200) {
        toast.error("An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res.data);
    } catch (err) {
      toast.error("An error occured. Please try again.");
      throw rejectWithValue(err);
    }
  }
);

const productListingSlice = createSlice({
  name: "product",
  initialState: productInitialState,
  extraReducers: {
    [productListingThunk.pending]: (state, action) => {
      // console.log("state, action", state, action);

      state.productLisitFetchLoading = true;
      state.productListFetchSuccess = false;
      state.productListFetchFailure = false;
      state.productListFetchError = null;
      state.productListFetchData = null;
    },
    [productListingThunk.fulfilled]: (state, action) => {
      // console.log("state, action", state, action);

      state.productLisitFetchLoading = false;
      state.productListFetchSuccess = true;
      state.productListFetchFailure = false;
      state.productListFetchError = null;
      state.productListFetchData = action?.payload;
    },
    [productListingThunk.rejected]: (state, action) => {
      state.productLisitFetchLoading = false;
      state.productListFetchSuccess = false;
      state.productListFetchFailure = true;
      state.productListFetchError = action?.payload;
      state.productListFetchData = null;
    },
  },
});

export const productListingSelector = (state) => state.product;

// add product slice
const addProductInitialState = {
  // item_name:"",
  // brand_name:"",
  // browse_nodes:"",
  // department:"",
  // outer_material_type:"",
  // fabric:"",
  product_info: {},
  product_offer: {},
  product_description: {},
};

const addProductSlice = createSlice({
  name: "add_product",
  initialState: addProductInitialState,
  reducers: {
    setProductInfo: (state, action) => {
      state.product_info = action?.payload;
    },
    setProductOffer: (state, action) => {
      state.product_offer = action?.payload;
    },
  },
});

export const { setProductInfo, setProductOffer } = addProductSlice.actions;

export const addProductSelector = (state) => state.addProduct;

// create product slice and thunk

export const createProductThunk = createAsyncThunk(
  "product/create",
  async (data, { dispactch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      // console.log("createProductThunk => rejectWithValue", rejectWithValue);
      // console.log(
      // 	"createProductThunk => fullfillWithValue",
      // 	fulfillWithValue
      // );
      const res = await ProductDataService.createProduct(data);
      if (!res.status === 201) {
        toast.error("An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      toast.error("An error occured. Please try again.");
      throw rejectWithValue(error);
    }
  }
);

const createInitialState = {
  createProductLoading: false,
  createProductSuccess: false,
  createProductFailure: false,
  createProductError: null,
  createProductMessage: "",
  createProductData: {},
};

const createProductSlice = createSlice({
  name: "create_product",
  initialState: createInitialState,
  reducers: {
    updateCreateProductSuccess(state, action) {
      const { value = false } = action.payload;
      state.createProductSuccess = value;
    },
  },
  extraReducers: {
    [createProductThunk.pending]: (state, action) => {
      // console.log("state, action", state, action);

      state.createProductLoading = true;
      state.createProductSuccess = false;
      state.createProductFailure = false;
      state.createProductError = null;
      state.createProductMessage = null;
      state.createProductData = null;
    },
    [createProductThunk.fulfilled]: (state, action) => {
      // console.log("state, action", state, action);
      state.createProductLoading = false;
      state.createProductSuccess = true;
      state.createProductFailure = false;
      state.createProductError = null;
      state.createProductMessage = "";
      state.createProductData = action.payload;
    },
    [createProductThunk.rejected]: (state, action) => {
      // console.log("state, action", state, action);

      state.createProductLoading = false;
      state.createProductSuccess = false;
      state.createProductFailure = true;
      state.createProductError = null;
      state.createProductMessage = "";
      state.createProductData = {};
    },
  },
});

export const { updateCreateProductSuccess } = createProductSlice.actions;

export const createProductSelector = (state) => state.create_product;

// create product view slice and thunk

export const productImageUploadThunk = createAsyncThunk(
  "product/uploadImage",
  async (data, { dispactch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      // console.log("createProductThunk => rejectWithValue", rejectWithValue);
      // console.log(
      // 	"createProductThunk => fullfillWithValue",
      // 	fulfillWithValue
      // );
      const res = await ProductDataService.uploadImageProduct(data);
      if (!res.status === 201) {
        toast.error("An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      toast.error("An error occured. Please try again.");
      throw rejectWithValue(error);
    }
  }
);

const uploadProductImageInitialState = {
  uploadProductImageLoading: false,
  uploadProductImageSuccess: false,
  uploadProductImageFailure: false,
  uploadProductImageError: null,
  uploadProductImageMessage: "",
  uploadProductImageData: {},
};

const uploadProductImageSlice = createSlice({
  name: "uploadImage",
  initialState: uploadProductImageInitialState,
  reducers: {
    resetImageUpload: (state) => {
      state.uploadProductImageLoading = false;
      state.uploadProductImageSuccess = false;
      state.uploadProductImageFailure = false;
      state.uploadProductImageError = null;
      state.uploadProductImageMessage = "";
      state.uploadProductImageData = {};
    },
  },
  extraReducers: {
    [productImageUploadThunk.pending]: (state, action) => {
      // console.log("state, action", state, action);

      state.uploadProductImageLoading = true;
      state.uploadProductImageSuccess = false;
      state.uploadProductImageFailure = false;
      state.uploadProductImageError = null;
      state.uploadProductImageMessage = null;
      state.uploadProductImageData = null;
    },
    [productImageUploadThunk.fulfilled]: (state, action) => {
      // console.log("state, action", state, action);
      state.uploadProductImageLoading = false;
      state.uploadProductImageSuccess = true;
      state.uploadProductImageFailure = false;
      state.uploadProductImageError = null;
      state.uploadProductImageMessage = "";
      state.uploadProductImageData = action.payload;
    },
    [productImageUploadThunk.rejected]: (state, action) => {
      // console.log("state, action", state, action);

      state.uploadProductImageLoading = false;
      state.uploadProductImageSuccess = false;
      state.uploadProductImageFailure = true;
      state.uploadProductImageError = null;
      state.uploadProductImageMessage = "";
      state.uploadProductImageData = {};
    },
  },
});

export const uploadProductImageSelector = (state) => state.uploadImage;

export const { resetImageUpload } = uploadProductImageSlice.actions;

// To update preview if no image present
export const updatePreviewThunk = createAsyncThunk(
  "product/update",
  async (data, { dispactch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await ProductDataService.updatePreview(data);
      if (!res.status === 201) {
        toast.error("An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      toast.error("An error occured. Please try again.");
      throw rejectWithValue(error);
    }
  }
);

const updatePreviewInitialState = {
  updatePreviewLoading: false,
  updatePreviewSuccess: false,
  updatePreviewFailure: false,
  updatePreviewError: null,
  updatePreviewMessage: "",
  updatePreviewData: {},
};

const updatePreviewSlice = createSlice({
  name: "updatePreview",
  initialState: updatePreviewInitialState,
  extraReducers: {
    [updatePreviewThunk.pending]: (state, action) => {
      state.updatePreviewLoading = true;
      state.updatePreviewSuccess = false;
      state.updatePreviewFailure = false;
      state.updatePreviewError = null;
      state.updatePreviewMessage = null;
      state.updatePreviewData = null;
    },
    [updatePreviewThunk.fulfilled]: (state, action) => {
      state.updatePreviewLoading = false;
      state.updatePreviewSuccess = true;
      state.updatePreviewFailure = false;
      state.updatePreviewError = null;
      state.updatePreviewMessage = null;
      state.updatePreviewData = action.payload;
    },
    [updatePreviewThunk.rejected]: (state, action) => {
      state.updatePreviewLoading = false;
      state.updatePreviewSuccess = false;
      state.updatePreviewFailure = true;
      state.updatePreviewError = null;
      state.updatePreviewMessage = null;
      state.updatePreviewData = {};
    },
  },
});

export const updatePreviewSelector = (state) => state.updatePreview;

const combineProductReducer = {
  product_listing: productListingSlice.reducer,
  add_product: addProductSlice.reducer,
  create_product: createProductSlice.reducer,
  uploadImage: uploadProductImageSlice.reducer,
  updatePreview: updatePreviewSlice.reducer,
};

export default combineProductReducer;
