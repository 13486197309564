import { Formik, Form } from "formik";
import React from "react";
import { OutlinedTextFields } from "../../../compoents/Forms2";
import ErrorMessage from "../../../compoents/ErrorMessage";
import { GenericButton } from "../../../compoents/Button";
import VerifyOtp from "../VerifyOtp";

function LoginForm({ initVal, validateSchema, onSubmit, goToRegisterScreen }) {
  return (
    <>
      <Formik
        initialValues={initVal}
        validationSchema={validateSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ setFieldValue, touched, errors }) => (
          <Form className="loginForm__container">
            {/* {!loginUserSuccess ? ( */}
            <>
              <h1 className="loginForm__heading">Sign In</h1>
              <div className="w-100">
                <OutlinedTextFields
                  className="loginForm__text"
                  name="mobile"
                  type="number"
                  placeholder="Mobile Number*"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 10))
                  }
                  onChange={(e) =>
                    setFieldValue("mobile", e.target.value.slice(0, 10))
                  }
                  maxlength={10}
                />
                {touched.mobile && errors.mobile && (
                  <ErrorMessage
                    className="loginForm__errorMsg text-warning"
                    message={errors.mobile}
                  />
                )}
              </div>
              <GenericButton type="submit" className="loginForm__btn">
                Send Otp
              </GenericButton>
              {/* <GenericButton
                type="button"
                onClick={goToRegisterScreen}
                variant="none"
                className="loginForm__btn loginForm__btn--createBtn"
              >
                Create Account
              </GenericButton> */}
              <div className="loginForm__btn loginForm__btn--createBtn">
                <div className="loginForm__divider">Or</div>
                {/* <hr className="m-0 mb-1" /> */}
                Don't have account?{" "}
                <span role="button" onClick={goToRegisterScreen}>
                  {" "}
                  Click Here
                </span>
              </div>
            </>
            {/* ) : (
              <VerifyOtp />
            )} */}
          </Form>
        )}
      </Formik>
    </>
  );
}

export default LoginForm;
