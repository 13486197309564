// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "../../compoents/Box";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Paper from "../../compoents/Paper";
import styled from "styled-components";
import { IMAGES } from "../../config";
import BootstrapTable from "react-bootstrap-table-next";
import { GenericButton } from "../../compoents/Button";
import { BsChevronRight } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { useSelector } from "react-redux";
import { productListingSelector } from "../../redux/slices/product";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import { FiUpload } from "react-icons/fi";
// import fdf from "../../redux/services/product.service";

const InputSearchIcon = styled.svg`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 7px;
  top: 10px;
  z-index: 1000;

  &:hover,
  &:focus {
    border-color: "#B0B0B0";
    box-shadow: none;
  }
`;

const inlineStyles = {
  fontStyle: "italic",
  position: "absolute",
  right: "15px",
  top: "5px",
  fontSize: "14px",
};

const InventoryData = [
  {
    //

    id: 1,
    images: IMAGES.CARD_IMAGE,
    sku: 123,
    category: "Women Wear",
    description: "Wine Solid Cut Outs Gown",
    inStock: 80,
    onOrder: 10,
    price: 430,
    groupByPrice: 350,
  },
];

const StyledInventoryBootstrapTable = styled(BootstrapTable)``;

const Inventory = () => {
  const auth = useAuth();
  let history = useHistory();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [products, setProducts] = useState([]);
  const [stats, setStats] = useState({});

  //
  useEffect(() => {
    (async () => {
      const res = await sellerApiService.getFilteredProducts("all");
      if (res.data) {
        const { products } = res.data;
        setProducts(products);
      }
    })();
  }, []);

  const getFilteredProducts = (filter) => {
    (async () => {
      const res = await sellerApiService.getFilteredProducts(filter);
      if (res.data) {
        const { products } = res.data;
        setProducts(products);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      const res = await sellerApiService.getProductStats();
      if (res.data) {
        setStats(res.data);
      }
    })();
  }, []);

  const inventoryColumns = [
    {
      dataField: "images",
      text: "Images",
      isDummyField: true,
      sort: false,
      classes: "align-middle table-left-hover-class",
      formatter: (cell, row) => {
        return (
          <Card
            className="text-white p-2"
            style={{
              background: "#FFFFFF",
              border: "1px solid #B8B8B8",
              borderRadius: "8px",
            }}
          >
            <Card.Img src={row.title_image} alt="Card image" />
          </Card>
        );
      },
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        };
      },
    },
    {
      dataField: "sku",
      text: "SKU",
      sort: false,
      classes: "align-middle table-left-hover-class",
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        };
      },
    },
    {
      dataField: "category",
      text: "Category",
      sort: false,
      classes: "align-middle table-left-hover-class",
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        };
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
      classes: "align-middle table-left-hover-class",
      style: (col, data, index) => {
        return {
          lineHeight: " 1.5em",
          height: "3em",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "100%",
        };
      },
      headerStyle: (column, colIndex) => {
        return {
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        };
      },
    },
    {
      dataField: "stock",
      text: "In Stock",
      sort: false,
      classes: "align-middle table-left-hover-class",
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        };
      },
    },
    {
      dataField: "onOrder",

      text: "On order",
      sort: false,
      classes: "align-middle table-left-hover-class",
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        };
      },
    },
    {
      dataField: "mrp",

      text: "Price",
      sort: false,
      classes: "align-middle table-left-hover-class",
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        };
      },
    },
    {
      dataField: "group_price",

      text: "group by price",
      sort: false,
      classes: "align-middle table-left-hover-class",

      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          whiteSpace: "nowrap",
        };
      },
    },
    {
      dataField: "more",
      text: "Actions",
      classes: "align-middle table-right-hover-class",
      formatter: (cell, row) => {
        let linkTo = `/inventory-product-detail/productId=${row.id}`;
        return (
          <span>
            {/* <Link className="text-muted" to={linkTo}> */}
            <BsChevronRight
              className="cursor-pointer"
              size={30}
              onClick={() => history.push({ pathname: linkTo, state: row })}
            />
            {/* </Link> */}
          </span>
        );
      },
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          textAlign: "center",
          background: "#122736",
          color: "#ffffff",
          fontWeight: "bold",
          width: "75px",
        };
      },
    },
  ];

  return (
    <div>
      {/* {console.log("productListFetchData", productListFetchData)} */}
      <Tab.Container id="left-tabs-example" defaultActiveKey="active">
        <Box className="position-relative">
          <p style={inlineStyles}>
            Product approval takes 2-10 hrs time after listing.
          </p>
          <Row className="gx-0 align-items-center">
            <Col sm="auto">
              <Paper
                className="overflow-auto p-0 bg-transparent "
                style={{
                  padding: "0px",
                  background: "none",
                }}
              >
                <Nav
                  variant="pills"
                  className="fund-raise-generic-tabs-nav flex-row text-center button-text flex-nowrap px-2 pt-3 pb-0"
                >
                  <Nav.Item>
                    <div className="text-muted fw-bold fs-5">
                      {stats?.active}
                    </div>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        getFilteredProducts("active");
                      }}
                      eventKey="active"
                      className="cursor-pointer mb-0 text-grey-light text-center fw-bold text-muted fw-bold fs-5 mb-2"
                    >
                      <h6>Product Active</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <div className="text-muted fw-bold fs-5">
                      {stats?.in_active}
                    </div>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        getFilteredProducts("inactive");
                      }}
                      style={{ width: "204px" }}
                      eventKey="activation-pending"
                      className="cursor-pointer mb-0 text-grey-light fw-bold text-muted fw-bold fs-5 mb-2"
                    >
                      <h6>Product Inactive</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <div className="text-muted fw-bold fs-5">
                      {stats?.not_approved}
                    </div>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        getFilteredProducts("unapproved");
                      }}
                      eventKey="blocked"
                      className="cursor-pointer mb-0 text-grey-light fw-bold text-muted fw-bold fs-5 mb-2 w-100"
                    >
                      <h6 style={{ lineHeight: "1" }}>
                        Product <br /> Approval Pending
                      </h6>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <div className="text-muted fw-bold fs-5">0</div>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      eventKey="paused"
                      className="cursor-pointer mb-0 text-grey-light fw-bold text-muted fw-bold fs-5 mb-2"
                    >
                      <h6>Paused</h6>
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Paper>
            </Col>
          </Row>
        </Box>
        <Tab.Content className="mt-0">
          <Tab.Pane eventKey="active">
            <Box className="my-3">
              <Tab.Container
                className=""
                id="left-tabs-example"
                defaultActiveKey="all-stock"
              >
                <Paper
                  className=" bg-transparent px-0"
                  // style={{
                  // 	// padding: "0px",
                  // 	background: "none",
                  // 	backgroundColo: "none",
                  // }}
                >
                  <Nav
                    variant="pills"
                    className="fund-raise-generic-tabs-nav overflow-scroll flex-row text-center button-text flex-nowrap px-3 py-2"
                  >
                    <Nav.Item>
                      <div className="text-muted fw-bold fs-5 mb-2">
                        {stats?.all_stock}
                      </div>
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          getFilteredProducts("all");
                        }}
                        eventKey="all-stock"
                        className="cursor-pointer text-grey-light text-center fw-bold"
                      >
                        <h5>All Stock</h5>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <div className="text-muted fw-bold fs-5 mb-2">
                        {stats?.out_of_stock}
                      </div>
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          getFilteredProducts("out_of_stock");
                        }}
                        style={{ width: "150px" }}
                        eventKey="out-of-stock"
                        className="cursor-pointer text-grey-light fw-bold"
                      >
                        <h5>Out Of Stock</h5>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <div className="text-muted fw-bold fs-5 mb-2">
                        {stats?.low_stock}
                      </div>
                      <Nav.Link
                        onClick={(e) => {
                          e.preventDefault();
                          getFilteredProducts("low_stock");
                        }}
                        eventKey="low-stock"
                        className="cursor-pointer text-grey-light fw-bold"
                      >
                        <h5>Low Stock</h5>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <hr />
                  <Tab.Content className="mt-2">
                    <Tab.Pane eventKey="all-stock">
                      <div>
                        <div className="container-fluid"></div>
                        {products ? (
                          <StyledInventoryBootstrapTable
                            className="table-responsive-lg"
                            keyField="id"
                            // data={InventoryData}
                            data={products}
                            columns={inventoryColumns}
                            bordered={false}
                            condensed
                            classes={`custom-table table-responsive-sm}`}
                            bodyClasses="custom-table-body"
                          />
                        ) : null}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="out-of-stock">
                      <div>
                        <div className="container-fluid">
                          <div className="row align-items-center">
                            <div className="col-lg-3 col-xl-4">
                              <h6>Catalogs: Out Of Stock</h6>
                            </div>
                          </div>
                        </div>
                        {products ? (
                          <StyledInventoryBootstrapTable
                            className="table-responsive-lg"
                            keyField="id"
                            // data={InventoryData}
                            data={products}
                            columns={inventoryColumns}
                            bordered={false}
                            condensed
                            classes={`custom-table table-responsive-sm}`}
                            bodyClasses="custom-table-body"
                          />
                        ) : null}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="low-stock">
                      <div>
                        <div className="container-fluid">
                          <div className="row align-items-center">
                            <div className="col-lg-3 col-xl-4">
                              <h6>Catalogs: Low Stock</h6>
                            </div>
                          </div>
                        </div>
                        {products ? (
                          <StyledInventoryBootstrapTable
                            className="table-responsive-lg"
                            keyField="id"
                            // data={InventoryData}
                            data={products}
                            columns={inventoryColumns}
                            bordered={false}
                            condensed
                            classes={`custom-table table-responsive-sm}`}
                            bodyClasses="custom-table-body"
                          />
                        ) : null}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Paper>
              </Tab.Container>
            </Box>
          </Tab.Pane>
          <Tab.Pane eventKey="activation-pending">
            <div>
              <Box className="my-3 py-3">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-xl-4">
                      <h6>Catalogs: Pending Activation</h6>
                    </div>
                  </div>
                </div>
                {products ? (
                  <StyledInventoryBootstrapTable
                    className="table-responsive-lg"
                    keyField="id"
                    // data={InventoryData}
                    data={products}
                    columns={inventoryColumns}
                    bordered={false}
                    condensed
                    classes={`custom-table table-responsive-sm}`}
                    bodyClasses="custom-table-body"
                  />
                ) : null}
              </Box>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="blocked">
            <div>
              <Box className="my-3 py-3">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-xl-4">
                      <h6>Catalogs: Blocked</h6>
                    </div>
                  </div>
                </div>
                {products ? (
                  <StyledInventoryBootstrapTable
                    className="table-responsive-lg"
                    keyField="id"
                    // data={InventoryData}
                    data={products}
                    columns={inventoryColumns}
                    bordered={false}
                    condensed
                    classes={`custom-table table-responsive-sm}`}
                    bodyClasses="custom-table-body"
                  />
                ) : null}
              </Box>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="paused">
            <div>
              <Box className="my-3 py-3">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-xl-4">
                      <h6>Catalogs: Paused</h6>
                    </div>
                  </div>
                </div>
                {products ? (
                  <StyledInventoryBootstrapTable
                    className="table-responsive-lg"
                    keyField="id"
                    // data={InventoryData}
                    data={products}
                    columns={inventoryColumns}
                    bordered={false}
                    condensed
                    classes={`custom-table table-responsive-sm}`}
                    bodyClasses="custom-table-body"
                  />
                ) : null}
              </Box>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Inventory;
