import { OutlinedTextFields } from "../../../compoents/Forms2";
import { GenericButton } from "../../../compoents/Button";
import ErrorMessage from "../../../compoents/ErrorMessage";
import { FaCheckCircle, FaFileUpload } from "react-icons/fa";
import { toast } from "react-toastify";

const FormField = ({
  label,
  fieldType,
  fieldName,
  placeholder,
  onChange,
  onInput,
  touched,
  errors,
  isRequired,
  value,
  ...props
}) => {
  return (
    <div className="formField__mainDiv">
      <span>
        {label} {isRequired && <span className="text-danger">*</span>}
      </span>
      <OutlinedTextFields
        className="sellerForm__text"
        name={fieldName}
        type={fieldType}
        placeholder={placeholder}
        onInput={onInput}
        onChange={onChange}
        value={value}
        {...props}
      />
      {touched[fieldName] && errors[fieldName] && (
        <ErrorMessage
          className="sellerForm__errorMsg"
          message={errors[fieldName]}
        />
      )}
    </div>
  );
};

const Form1 = ({ setFieldValue, touched, errors, onClick, values }) => {
  return (
    <>
      <FormField
        label="Company name"
        isRequired={true}
        fieldType="text"
        fieldName="company"
        placeholder="Enter company name*"
        onChange={(e) => setFieldValue("company", e.target.value)}
        touched={touched}
        errors={errors}
        value={values.company}
      />
      <FormField
        label="Pick-up address1"
        isRequired={true}
        fieldType="text"
        fieldName="address"
        placeholder="Enter address1*"
        onChange={(e) => setFieldValue("address", e.target.value)}
        touched={touched}
        errors={errors}
        value={values.address}
      />
      <FormField
        label="Pick-up address2"
        isRequired={false}
        fieldType="text"
        fieldName="address2"
        placeholder="Enter address2"
        onChange={(e) => setFieldValue("address2", e.target.value)}
        touched={touched}
        errors={errors}
        value={values.address2}
      />
      <div className="gap-2 d-flex row__group">
        <FormField
          label="Nearby Landmark"
          isRequired={true}
          fieldType="text"
          fieldName="landmark"
          placeholder="Enter landmark*"
          onChange={(e) => setFieldValue("landmark", e.target.value)}
          touched={touched}
          errors={errors}
          value={values.landmark}
        />
        <FormField
          label="City/Region"
          isRequired={true}
          fieldType="text"
          fieldName="city"
          placeholder="Enter city*"
          onChange={(e) => setFieldValue("city", e.target.value)}
          touched={touched}
          errors={errors}
          value={values.city}
        />
      </div>
      <div className="gap-2 d-flex row__group">
        <FormField
          label="State"
          isRequired={true}
          fieldType="text"
          fieldName="state"
          placeholder="Enter state*"
          onChange={(e) => setFieldValue("state", e.target.value)}
          touched={touched}
          errors={errors}
          value={values.state}
        />
        <FormField
          label="Pincode"
          isRequired={true}
          fieldType="number"
          fieldName="pincode"
          placeholder="Enter pincode*"
          onChange={(e) => setFieldValue("pincode", e.target.value)}
          touched={touched}
          errors={errors}
          value={values.pincode}
        />
      </div>
      <GenericButton className="sellerForm__btn" onClick={onClick}>
        Save & Continue
      </GenericButton>
    </>
  );
};

const Form2 = ({
  setFieldValue,
  touched,
  errors,
  onClick,
  bankAccVerified,
  verifyBankAccount,
  values,
}) => {
  return (
    <>
      <FormField
        label="Account holder name"
        isRequired={true}
        fieldType="text"
        fieldName="bank_holder_name"
        placeholder="Enter account holder name*"
        onChange={(e) => setFieldValue("bank_holder_name", e.target.value)}
        touched={touched}
        errors={errors}
        value={values.bank_holder_name}
      />
      <FormField
        label="Account number"
        isRequired={true}
        fieldType="number"
        fieldName="bank_account_number"
        placeholder="Enter account number*"
        onChange={(e) => setFieldValue("bank_account_number", e.target.value)}
        touched={touched}
        errors={errors}
        value={values.bank_account_number}
      />
      <FormField
        label="IFSC Code"
        isRequired={true}
        fieldType="text"
        fieldName="ifsc"
        placeholder="Enter IFSC Code*"
        onChange={(e) => setFieldValue("ifsc", e.target.value)}
        touched={touched}
        errors={errors}
        value={values.ifsc}
      />
      {bankAccVerified && (
        <div className="sellerForm__verify">
          <FaCheckCircle fontSize={24} className="sellerForm__verifyIcon" />
          Your Bank Details is Verified
        </div>
      )}

      <div className="sellerForm__actionForm2Btn">
        <GenericButton
          variant="secondary"
          className="sellerForm__btn sellerForm--verifyBtn"
          onClick={() => verifyBankAccount(values)}
        >
          Verify Bank AC
        </GenericButton>
        <GenericButton
          className="sellerForm__btn"
          onClick={onClick}
          disabled={!bankAccVerified}
        >
          Save & Continue
        </GenericButton>
      </div>
    </>
  );
};

const Form3 = ({
  setFieldValue,
  touched,
  errors,
  onClick,
  verifyGST,
  documentVerified,
  values,
}) => {
  return (
    <>
      <div className="documentType__Wrapper">
        <label>Select document type:</label>
        <div className="documentType__formControl">
          <div className="documentType__textWrapper">
            <input
              id="gst"
              type="radio"
              value="GST"
              checked={values?.documentType === "GST"}
              onChange={(e) => {
                setFieldValue("documentType", e.target.value);
              }}
              name="documentType"
            />
            <label htmlFor="gst">GST</label>
          </div>
          <div className="documentType__textWrapper">
            <input
              id="pan"
              type="radio"
              value="PAN"
              checked={values?.documentType === "PAN"}
              onChange={(e) => {
                setFieldValue("documentType", e.target.value);
              }}
              name="documentType"
            />
            <label htmlFor="pan">
              PAN Card <strong>(Only for Book Seller)</strong>
            </label>
          </div>
        </div>
      </div>
      <FormField
        label={values?.documentType === "PAN" ? "PAN Number" : "GST number"}
        isRequired={true}
        fieldType="text"
        fieldName="gst_number"
        placeholder={
          values?.documentType === "PAN"
            ? "Enter PAN number*"
            : "Enter GST number*"
        }
        onChange={(e) => setFieldValue("gst_number", e.target.value)}
        touched={touched}
        errors={errors}
        value={values.gst_number}
      />
      {documentVerified && values?.documentType === "GST" && (
        <div className="sellerForm__verify">
          <FaCheckCircle fontSize={24} className="sellerForm__verifyIcon" />
          Your GST Details is Verified
        </div>
      )}

      {documentVerified && values?.documentType === "PAN" && (
        <div className="sellerForm__verify">
          <FaCheckCircle fontSize={24} className="sellerForm__verifyIcon" />
          Your PAN Details is Verified
        </div>
      )}

      <div className="sellerForm__actionForm2Btn">
        <GenericButton
          variant="secondary"
          className="sellerForm__btn sellerForm--verifyBtn"
          onClick={() => verifyGST(values)}
        >
          {values?.documentType === "PAN" ? "Verify PAN" : "Verify GST AC"}
        </GenericButton>
        <GenericButton
          className="sellerForm__btn"
          onClick={onClick}
          disabled={!documentVerified}
        >
          Submit
        </GenericButton>
      </div>
    </>
  );
};

const Form4 = ({ setSignature, hiddenFileInput }) => {
  return (
    <>
      <div className="sellerForm__signatureMain">
        <div className="sellerForm__uploadSection">
          <span>
            Upload signature <span className="text-danger">*</span>
          </span>
          <div
            role="button"
            className="sellerForm__fileInput"
            onClick={() => hiddenFileInput.current.click()}
          >
            <div className="sellerForm__selectedFile">
              {hiddenFileInput &&
                hiddenFileInput?.current &&
                hiddenFileInput?.current?.files[0]?.name}
            </div>
            <FaFileUpload size={48} color="#000" />
          </div>
          *Min - Max size : 50KB
        </div>
        <input
          type="file"
          hidden
          name="img1"
          id="signature-input"
          accept="image/png, image/jpeg"
          onChange={(e) => {
            const files = e.target.files;
            if (files?.[0]) {
              if (files[0].size > 50 * 1024) {
                // 50kb max
                e.target.value = "";
                setSignature(null);
                return toast.warn("Signature should be 50kb max.");
              }
              setSignature(e.target.files[0]);
            }
          }}
          ref={hiddenFileInput}
        />
      </div>
      <GenericButton type="submit" className="sellerForm__btn mx-auto">
        Final Submit
      </GenericButton>
    </>
  );
};

export { Form1, Form2, Form3, Form4 };
