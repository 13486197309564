// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Box from "../../compoents/Box";
import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import Card from "react-bootstrap/Card";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
// import ReturnCard from "./ReturnCard";
import ReturnOrderTable from "./ReturnOrderTable";

const Return = (props) => {
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [returned, setReturned] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await sellerApiService.getReturnedOrders();
      if (res.data) {
        const { orders } = res.data;
        setReturned(orders);
      }
    })();
  }, []);

  return (
    <>
      {/* <div>
        {returned?.map((element, index) => {
          return <ReturnCard product={element} key={index} />;
        })}
      </div> */}
      <ReturnOrderTable data={returned} />
    </>
  );
};

export default Return;

/*


	<div key={index}>
										<ProductListingCard
											borderRadius="9px"
											className="py-3 px-2 mb-3"
										>
											<Row className="gy-3">
												<Col xs={12} sm={2}>
													<img
														className="w-100"
														style={{ height: "100%" }}
														src={element?.image}
														alt=""
													/>
												</Col>
												<Col>
													<div className="d-flex justify-content-between gy-5">
														<h6
															className="font-bold text-center"
															style={{ color: "#343434" }}
														>
															{element?.product}
														</h6>
														<h6
															className="font-bold text-muted text-center"
															style={{ color: "#343434" }}
														>
															Order ID: {element?.order.id}
														</h6>
													</div>
													<p className="text-muted">
														{element?.user}
													</p>
													<div className=" d-flex justify-content-between text-muted">
														<p className="text-muted">
															Ordered on {new Date(element?.order.created_at).toLocaleDateString()} At {new Date(element?.order.created_at).toLocaleTimeString()}
														</p>
														<Link to={"/order-detail/orderId=" + element?.order.id}>
															<ButtonComponent
																background="none"
																border="none"
															>
																<img
																	className=""
																	style={{ width: "16px", height: "20px" }}
																	src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/000000/external-right-arrow-arrows-dreamstale-lineal-dreamstale-15.png"
																	alt="img"
																/>
															</ButtonComponent>
														</Link>
													</div>
													<div className="d-flex justify-content-between">
														<div className="flex-grow-1">
															<p className="text-muted mb-0">Size</p>
															<b>{Object.keys(element?.order.details) ? element?.order.details?.size : ""}</b>
														</div>
														<div className="flex-grow-1">
															<p className="text-muted mb-0">Payment</p>
															<b>₹ {element?.order.amount}</b>
														</div>
														<div className="flex-grow-1">
															<p className="text-muted mb-0">QTY.</p>
															<b>{element?.order.quantity}</b>
														</div>
														<div
															className="text-end"
															style={{ flexBasis: "200px" }}
														>
															<p className="text-muted mb-0">Order Status</p>
															<b
																className="font-bold"
																style={{ color: "#6327B8" }}
															>
																{element?.order.status}
															</b>
														</div>
													</div>
												</Col>
											</Row>
										</ProductListingCard>
									</div>


 */
