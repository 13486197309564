import React from "react";
import { useSelector } from "react-redux";
import {
  failed_qc_count_selector,
  live_qc_count_selector,
  pending_qc_count_selector,
  total_count_selector,
} from "../../redux/slices/qc_slice";

function QCTab(props) {
  const { handleSelection, selectedTab } = props;
  const total_qc = useSelector(total_count_selector);
  const total_live = useSelector(live_qc_count_selector);
  const total_fail = useSelector(failed_qc_count_selector);
  const total_pending = useSelector(pending_qc_count_selector);

  return (
    // <div>
    <ul className="qcTabDiv">
      <li
        role="button"
        onClick={() => handleSelection(0)}
        className={`tabItem ${selectedTab === 0 ? "tabSelected" : ""}`}
      >
        <span className="tabLinks">All ({total_qc})</span>
      </li>
      <li
        role="button"
        onClick={() => handleSelection(1)}
        className={`tabItem ${selectedTab === 1 ? "tabSelected" : ""}`}
      >
        <span className="tabLinks">QC in Progress ({total_pending})</span>
      </li>
      <li
        role="button"
        onClick={() => handleSelection(2)}
        className={`tabItem ${selectedTab === 2 ? "tabSelected" : ""}`}
      >
        <span className="tabLinks">Live ({total_live})</span>
      </li>
      <li
        role="button"
        onClick={() => handleSelection(3)}
        className={`tabItem ${selectedTab === 3 ? "tabSelected" : ""}`}
      >
        <span className="tabLinks">QC Error ({total_fail})</span>
      </li>
    </ul>
    // </div>
  );
}

export default QCTab;
