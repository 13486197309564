import React from "react";
import { FaCheck } from "react-icons/fa";

const MultiLevels = ({ levels = 0, activeIndex = 1, handleSelectForm }) => {
  return (
    <div className="sellerForm__levels">
      <ul className="sellerForm__levelList">
        {new Array(levels).fill(null).map((data, index) => (
          <li
            key={`level-${index}`}
            className={`sellerForm__level ${
              index <= activeIndex - 1 ? "sellerForm__levelComplete" : ""
            }`}
          >
            <div
              role={index <= activeIndex ? "button" : ""}
              onClick={() =>
                index <= activeIndex && handleSelectForm(index + 1)
              }
              className="sellerForm__circle"
            >
              {index <= activeIndex - 1 && (
                <FaCheck
                  fontSize={24}
                  color="green"
                  className="sellerForm__tickIcon"
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MultiLevels;
