import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const SellerReferralPolicy = () => {
  return (
    <Container>
      <StyledBreadCrumb>
        <StyledBreadCrumb.Item active href="#">
          Dashboard
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Settings
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Legal & policies
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Seller Referral Program
        </StyledBreadCrumb.Item>
      </StyledBreadCrumb>
      <h1 className="mt-4 mb-4">GROUPICK SELLER REFERRAL PROGRAM</h1>

      <p>
        This Seller referral offer (&quot;Program&quot;) is sponsored and
        administered by Getzie Technology Pvt. Ltd. (&quot;Groupick&#39;&#39;).
        These terms governing the Program (&quot;Program Terms&quot;) shall be
        in addition to Groupick’s terms and conditions available at
        https://Groupick.in/legal/terms-conditions (&quot;Groupick Terms&quot;)
        and your Seller Agreement with Groupick (&quot;Agreement&quot;).
        Capitalised terms used herein but not defined shall have the same
        meaning as under the Groupick Terms or the Agreement. In case of any
        conflict between the Program Terms and the Groupick Terms or the
        Agreement, the Program Terms shall prevail to the extent of such
        conflict.
      </p>

      <h4>OFFER CONDITIONS</h4>
      <p>
        This Program is available exclusively for Sellers who have received at
        least 1 order, and who fulfill any additional eligibility criteria that
        Groupick may, at its discretion, introduce from time to time
        ("Referrer/you").
      </p>

      <p>
        Referrer shall be provided a link via Seller panel ("Referral Link")
        which may be shared with persons eligible to be Sellers on Groupick and
        who have never before registered with Groupick before. Alternatively,
        the Referrer may provide Groupick with the requisite details of such
        persons including name, email address, and/or telephone number, through
        the Seller panel, and Groupick may invite such persons to be Seller by
        sending the Referral Link. It is hereby clarified that for the Referrer
        and/or Referee to be eligible for any Incentive, (a) the Referee must
        register on Groupick through the Referral Link, or (b) if the Referee is
        directly invited through the Seller panel, then the Referee must
        register by accepting such invite.
      </p>

      <p>
        Such persons may fill in the required details as delineated in the
        Referral Link for the purpose of being registered as a Seller with
        Groupick ("Referee").
      </p>

      <p>
        The Referrer and Referee shall be entitled to cash prize ("Incentive")
        for each Referee joining Groupick through such Referrer's Referral Link.
        Subject to the other Program Terms, and completion of the milestones set
        out below within 45 days from the upload of the first catalog by the
        Referee, the Incentive will be provided to the Referrer and the Referee
        in the following manner:
      </p>

      <table className="table">
        <thead>
          <tr>
            <th>Stage No.</th>
            <th>Stage at which Incentive becomes payable</th>
            <th>Incentive being provided to Referrer (in INR)</th>
            <th>Incentive being provided to Referee (in INR)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>First Order Delivered</td>
            <td>10</td>
            <td>10</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>Fifth Order Delivered</td>
            <td>40</td>
            <td>10</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>Fifteenth Order Delivered</td>
            <td>150</td>
            <td>30</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>Thirtieth Order Delivered</td>
            <td>250</td>
            <td>50</td>
          </tr>
        </tbody>
      </table>

      <p>
        It is clarified however that no Referrer may refer more than 30
        Referees, and the maximum Incentive payable to any Referrer for all
        referrals shall not exceed INR 135,000. Notwithstanding the foregoing,
        if a Seller becomes ineligible to participate in this Program at any
        time during the Program duration, such Seller will not be able to refer
        any more Sellers, even if they have referred less than 30 Referees till
        date.
      </p>

      <p>
        Only one account per Seller will be considered for Incentives under this
        Program. Any Incentive earned by the Referrer will be provided to
        Referrer after 7 (seven) days of completion of each milestone as set out
        in the table paragraph 4 above.
      </p>

      <p>
        Groupick reserves the right to extend, cancel, discontinue, prematurely
        withdraw, change, alter, modify, stop this Program or any part thereof
        pertinent to this Program at its sole discretion at any time during its
        validity as may be required including in view of business exigencies
        and/or changes by a regulatory authority and/or statutory changes and/or
        any reasons beyond their control without providing any prior notice to
        Referrer/Referee and the same shall be binding on the Referrers and
        Referees.
      </p>

      <p>
        In the event that a Seller refers another person who is already
        registered as a Seller with Groupick, neither party would be entitled to
        any Incentive whatsoever. It is clarified that referral is only
        applicable for incremental sellers and not existing Sellers who create a
        new account.
      </p>

      <p>
        Sellers must not bid on or run ads using Groupick branded keywords,
        create replica websites or post their Referral Link on any other
        website. If a Seller is found to have engaged in any such behavior, they
        shall not be eligible for any Incentive and may also be penalized by
        Groupick.
      </p>

      <p>
        Groupick reserves the right to disqualify any transaction at any time.
        In cases of fraudulent/suspicious transactions, Groupick solely reserves
        the right to revoke the Program or participation or benefits of any
        Referrer/Referee.
      </p>

      <p>
        Groupick reserves the right to determine eligibility of a Seller,
        extend, cancel, discontinue, prematurely withdraw, suspend, change,
        alter, modify, stop this Program or any part thereof at its sole
        discretion at any time during its validity as may be required including
        in view of business exigencies and/or changes by a regulatory authority
        and/or statutory changes and/or any reasons beyond their control without
        providing any prior notice to the Referrer/Referee and the same shall be
        binding on all Referrers and Referees.
      </p>

      <h4>GENERAL TERMS</h4>
      <p>
        The Referrer/Referee expressly agrees that his/her participation in the
        Program is at their sole risk and is governed by the Program Terms.
      </p>

      <p>
        The Referrer hereby expressly represents and warrants that they are
        authorized to share information regarding the Referee with Groupick for
        the purposes of this Program. The Referrer/Referee shall ensure that all
        information provided by them is true and that there is no restriction on
        them in sharing the information provided to Groupick. The
        Referrer/Referee also consents to the information being stored in the
        servers of Groupick or its service providers.
      </p>

      <p>
        Subject to any applicable laws, all warranties of any kind whatsoever,
        whether express or implied, are hereby expressly disclaimed including,
        regarding the Incentive or the Program or any transactions effected
        through the Program.
      </p>

      <p>
        The Incentive(s) under this Program are neither assignable nor
        transferable under any circumstance, unless specifically provided
        hereunder.
      </p>

      <p>
        The Incentive(s) to the Referrer/Referee shall be given subject to
        compliance with all applicable statutory legislations, processes and
        formalities in connection with the Incentive and on production of all
        such documents and information as may be required by Groupick. Any
        failure on the part of the Referrer to comply with directions issued by
        Groupick for claiming the Incentive shall entitle Groupick to deem that
        the Referrer/Referee has forfeited the Incentive, at their sole
        discretion.
      </p>

      <p>
        The Referrer/Referee understand and agree that Groupick or its
        affiliates shall not be liable for any loss or damage or injury
        whatsoever that may be suffered, by a Referrer/Referee and/or any third
        party, directly or indirectly, by use or non-use of the Incentive or
        from participation in this Program.
      </p>

      <p>
        Notwithstanding anything to the contrary contained herein, in case of
        any dispute, the maximum aggregate liability of Groupick shall not
        exceed the value of the concerned Incentive mentioned herein.
      </p>
    </Container>
  );
};

export default SellerReferralPolicy;
