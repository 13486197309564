import React, { useRef, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { APP_SETTINGS } from "../../config";
import { toast } from "react-toastify";

export default function BulkUploadCatalog({ refreshQC }) {
  const [uploadedFile, setUploadFile] = useState(null);
  const [hasError, setError] = useState({ status: false, error: "" });
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef();
  let json_data = "";
  const onChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        // getting sheetname from workbook sheets.
        const sheetName = workbook.SheetNames[2];
        // getting that specific sheet.
        const worksheet = workbook.Sheets[sheetName];
        // getting data of worksheet as array
        const worksheetJson = XLSX.utils.sheet_to_json(worksheet, {
          blankrows: false,
        });
        worksheetJson.splice(0, 0);
        worksheetJson.splice(0, 1);
        worksheetJson.splice(0, 2);
        const bodyData = {
          Variations_data: {},
          file_data: [...worksheetJson],
        };
        const colour = [];
        for (const item of worksheetJson) {
          if (item.Option_for_Variation_1) {
            colour.push(item.Option_for_Variation_1);
          } else continue;
        }
        const size = [];
        for (const item of worksheetJson) {
          if (item.Option_for_Variation_2) {
            size.push(item.Option_for_Variation_2);
          } else continue;
        }
        Object.assign(bodyData.Variations_data, { colour, size });
        json_data = bodyData;
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const basicUrl = APP_SETTINGS.API_PATH.CATALOG.bulkupload;
  const basicUrlV2 = APP_SETTINGS.API_PATH.CATALOG.bulkuploadV2;
  const basicUrlGetData = APP_SETTINGS.API_PATH.CATALOG.bulkgetdata;
  const token = JSON.parse(getCookie("mee_too_user"));

  const config = {
    headers: {
      Authorization: `Bearer ${token["token"]}`,
      "content-type": "application/json",
    },
  };

  const sendData = async (e) => {
    try {
      const res = await axios.post(basicUrl, JSON.stringify(json_data), config);
      if (res.status >= 200 && res.status <= 300) {
        if (res.data["Success"]) {
          toast.success("Products added successfully.");
        } else {
          toast.error("Error while reading data from uploaded file.");
        }
      } else {
        toast.error("An error occured. Please try again");
      }
    } catch (e) {
      if (e.response.status === 404) {
        toast.error(
          e.response.data.error ?? "An error occured. Please try again"
        );
      } else {
        toast.error("An error occured. Please try again");
      }
    }
  };
  const getData = (e) => {
    axios.get(basicUrlGetData, config).then((res) => {});
  };
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const sendDataV2 = async () => {
    try {
      if (!uploadedFile) {
        setError({ status: true, error: "No file chosen." });
        return;
      }
      setUploading(true);
      const formData = new FormData();
      formData.append("upload", uploadedFile);
      const res = await axios.post(basicUrlV2, formData, {
        ...config,
        "content-type": "multipart/form-data",
      });
      if (res.status >= 200 && res.status <= 300) {
        if (res.data["Success"]) {
          toast.success(res.data["Success"]["Message"]);
          setUploadFile(null);
          refreshQC();
          inputRef.current.value = "";
        } else {
          toast.error("Error while reading data from uploaded file.");
        }
        setUploading(false);
      } else {
        toast.error("An error occured. Please try again");
        setUploading(false);
      }
    } catch (e) {
      setUploading(false);
      if (e.response.status === 404) {
        toast.error(
          e.response.data.error ?? "An error occured. Please try again"
        );
      } else {
        toast.error("An error occured. Please try again");
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <input
            ref={inputRef}
            className="btn btn-warning form-control"
            type="file"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx"
            // onChange={onChange}
            onChange={(e) => setUploadFile(e.target.files[0])}
          />
        </div>
        <div className="col-md-6">
          <button
            className="btn btn-primary form-control"
            // onClick={sendData}
            onClick={sendDataV2}
            disabled={uploading}
          >
            Upload
          </button>
        </div>
        {hasError?.status && <p className="text-danger">{hasError?.error}</p>}
        {/* <div className="col-md-4">
          <button className="btn btn-success form-control" onClick={getData}>
            Get Seller and Category info
          </button>
        </div> */}
      </div>
    </div>
  );
}
