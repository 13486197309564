import { Formik, Form } from "formik";
import React from "react";
import { OutlinedTextFields } from "../../../compoents/Forms2";
import ErrorMessage from "../../../compoents/ErrorMessage";
import { GenericButton } from "../../../compoents/Button";

function RegisterForm({
  initVal,
  validateSchema,
  onSubmit,
  goToRegisterScreen,
}) {
  const FormField = ({
    label,
    fieldType,
    fieldName,
    placeholder,
    onChange,
    onInput,
    touched,
    errors,
    isRequired,
    ...props
  }) => {
    return (
      <div className="formField__mainDiv">
        <span>
          {label} {isRequired && <span className="text-danger">*</span>}
        </span>
        <OutlinedTextFields
          className="registerForm__text"
          name={fieldName}
          type={fieldType}
          placeholder={placeholder}
          onInput={onInput}
          onChange={onChange}
          {...props}
        />
        {touched[fieldName] && errors[fieldName] && (
          <ErrorMessage
            className="registerForm__errorMsg"
            message={errors[fieldName]}
          />
        )}
      </div>
    );
  };
  return (
    <Formik
      initialValues={initVal}
      validationSchema={validateSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ setFieldValue, touched, errors }) => (
        <Form className="registerForm__container">
          <>
            <h1 className="registerForm__heading">Sign Up</h1>
            <div className="w-100">
              <FormField
                label="Full name"
                isRequired={true}
                fieldType="text"
                fieldName="name"
                placeholder="Enter name*"
                onChange={(e) => setFieldValue("name", e.target.value)}
                touched={touched}
                errors={errors}
              />

              <FormField
                label="Email id"
                isRequired={true}
                fieldType="email"
                fieldName="email"
                placeholder="Enter email*"
                onChange={(e) => setFieldValue("email", e.target.value)}
                touched={touched}
                errors={errors}
              />

              <FormField
                label="Phone number"
                isRequired={true}
                fieldType="number"
                fieldName="mobile"
                placeholder="Enter phone number*"
                onChange={(e) => setFieldValue("mobile", e.target.value)}
                touched={touched}
                errors={errors}
                maxlength={10}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
              />
            </div>
            <GenericButton type="submit" className="registerForm__btn">
              Send Otp
            </GenericButton>
          </>
        </Form>
      )}
    </Formik>
  );
}

export default RegisterForm;
