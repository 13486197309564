import { createSlice } from "@reduxjs/toolkit";

const initState = {
  faq_list: [],
};

const faqSlice = createSlice({
  name: "faq",
  initialState: initState,
  reducers: {
    addFaq(state, action) {
      const { payload } = action;
      state.faq_list = payload;
    },
  },
});

const initData = [3, 2]; // first 3 columns having 2 pre defined faq

export const getAllIssueFlated = (state) => {
  const faq_list = state.faq.faq_list;
  const flated_list = faq_list.reduce((acc, cur) => {
    return [...acc, ...cur["ticket_type"]];
  }, []);
  return flated_list;
};

export const supportGroupList = (state) => {
  const faq_list = state.faq.faq_list;
  const res = faq_list.map((faq, i) => {
    if (i + 1 <= initData[0]) {
      return { ...faq, ticket_type: faq.ticket_type.slice(0, initData[1]) };
    }
    return { ...faq, ticket_type: [] };
  });
  return res;
};
export default faqSlice;
export const { addFaq } = faqSlice.actions;
