import { useState } from "react";

function useCampaign() {
  const [addCampaign, setAddCampaign] = useState(false);
  const startCampaign = () => {
    setAddCampaign(true);
  };

  const goToDashboard = () => {
    setAddCampaign(false);
  };

  return { addCampaign, startCampaign, goToDashboard };
}

export default useCampaign;
