import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import PageTitle from "../../compoents/PageTitle";
import { Link, NavLink } from "react-router-dom";
import { PATH } from "../../config";

import ReturnIcon from "../../assets/Images/return.svg";
import OrderIcon from "../../assets/Images/order.svg";
import IncomeIcon from "../../assets/Images/income.svg";
import ProductsIcon from "../../assets/Images/products.svg";
import Live_Traning_icon from "../../assets/Images/live_traning.svg";

import pendingOrderIcon from "../../assets/Images/pending_order.svg";
import pendingDispatchIcon from "../../assets/Images/pending_dispatch.svg";
import outOfStockIcon from "../../assets/Images/out_of_stock.svg";
import viewIcon from "../../assets/Images/views.svg";

import CarouselImage1 from "../../assets/Images/dashboard-carousel-1.png";
import CarouselImage2 from "../../assets/Images/dashboard-carousel-2.png";
import CarouselImage3 from "../../assets/Images/dashboard-carousel-3.png";
import CarouselImage4 from "../../assets/Images/dashboard-carousel-4.png";
import CarouselImage5 from "../../assets/Images/dashboard-carousel-5.png";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import { FaCheckCircle } from "react-icons/fa";

const StyledDashboard = styled.main`
// new stats styling
.main-flex {
  
  display: flex;
  gap: 20px;

  .left-flex {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 60%;
    .new-card {
      width: 25%;
      height: 80px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #e0e0e0;
      border: 3px solid #000;
      border-radius: 6px;
      padding: 5px;
      .upper {
        display: flex;
        align-items: center;
        gap: 10px;
        .icon_inner {
          width: 25px;
        }
        
        .label-box {
          display: flex;
          justify-content: center;
          aligh-items: center;
          .label {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: -1px;
            
          }
          
        }
        
      }
      .lower {
        .h5-last {
          margin-top: -11px;
        }
        .label-last{
          margin-top: -13px;
          font-weight: 600;
        } 
      }
    }
    
  }
  
  .right-flex {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 40%;
    height: 80px;
    .right_left_one {
      width: 15%;
    }
    .right_left_two {
      width: 47%;
      display: flex;
      flex-direction:column;
      justify-content: center;
    }
    .right_left_three {
      width: 38%;
    }
    .live_traning_image {
      height: 75px;
    }
    .btn-lg {
      width: 100%;
      border: 0;
      background-color: #000000;
      color: #ffffff;
      padding: 8px 20px;
      border-radius: 5px;
    }
    .new-p {
      font-size: 14px;
      line-height:1.1em;
      margin-bottom: 0px;
    }
  }

  @media only screen and (max-width: 992px) {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .left-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    width: 100%;
    .new-card {
      width: 48.5%;
      height: 80px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 5px;
      
      
    }
    
  }
  
  .right-flex {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    .right_left_one {
      width: 15%;
    }
    
  }
  }

  // mobile
  @media only screen and (max-width: 600px)  {
    display: flex;
  flex-direction: column;
  gap: 20px;

  .left-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    width: 100%;
    .new-card {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 5px;
      
      
    }
    
  }
  
  .right-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    width: 100%;
    height: auto;
    .right_left_one {
      width: 15%;
    }
    .right_left_one {
      width: 100%;
    }
    .right_left_two {
      width: 100%;
    }
    .right_left_three {
      width: 100%;
    }
    
    
  }
  }

  }
}



// new stats styling ..end...//
  .stats-card {
    border-radius: 6px;
    color: white;

    .icon-box {
      background-color: rgba(white, 0.3);
    }

    &-orders {
      background-color: #008037;
    }
    &-returns {
      background-color: #f5b800;
    }
    &-products {
      background-color: #ff5722;
    }
    &-income {
      background-color: #0f122f;
    }
  }

  .carousel-image {
    max-height: 400px;
    border-radius: 5px;
  }

  // Account Management agency///////

  .training_main {
    .training_container {
      display: flex;
      gap: 30px;
      .traning_left {
        width: 75%;
      }
      .traning_right {
        width: 25%;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    .traning_video {
      display: flex;
      gap: 30px;
    }
    .check_icon {
      color: #008000;
    }
    .text-black {
      color: #000000;
      font-size: 17px;
      width: 90%;
      line-height: 1.3em;
    }
    .content_box {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
    .btn-lg {
      width: 75%;
      border: 0;
      background-color: #000000;
      color: #ffffff;
      padding: 8px 20px;
      border-radius: 5px;
    }
    .message_right {
      background-color: #e0e0e0;
      padding: 10px;
      text-align: right;
      display: flex;
      flex-direction: column;
      // gap: 5px;
      border-radius: 5px;
      border-bottom: 4px solid #ff4d00;
      .right_heading {
        font-size: 18px;
        // margin-bottom: 0px;
      }
      p {
        font-size: 16px;
        margin-bottom: 0;
      }
      .agency-offer {
        font-size: 13px;
        font-style: italic;
      }
      
    }
    
    
  }
  @media only screen and (max-width: 992px) {
    .training_main {
      .training_container {
        flex-direction:column;
        // gap: 30px;
        .traning_left {
          width: 100%;
        }
        .traning_right {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
      .text-black {
        width: 100%;
      }
  }

  // mobile
  @media only screen and (max-width: 600px) {
    .training_main {
      .training_container {
        // gap: 0px;
        .traning_left {
          width: 100%;
        }
        .traning_right {
          width: 100%;
          flex-direction: column;
        }
      }
      .traning_video {
        flex-direction:column;
      }
      
      .text-black {
        width: 100%;
      }
      
      .content_box {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
`;

const Dashboard = () => {
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [returned, setReturned] = useState("loading");
  const [stats, setStats] = useState("loading");

  useEffect(() => {
    (async () => {
      const returnedRes = await sellerApiService.getReturnedOrders();
      if (returnedRes.data) {
        const { orders } = returnedRes.data;
        setReturned(orders);
      }
      const statsRes = await sellerApiService.getProductStats();
      if (statsRes.data) {
        setStats(statsRes.data);
      }
    })();
  }, []);

  if (returned === "loading") {
    return (
      <div className="d-flex align-items-center jusitfy-content-center h-100 w-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <StyledDashboard>
      <Container>
        <PageTitle text="Dashboard" showNotification={true} />
        <Row className="w-100 mx-0 g-2">
          <Col sm="6" xl="3">
            <div className="stats-card stats-card-orders p-3">
              <div className="d-flex mb-2 gap-2 align-items-center">
                <span className="icon-box p-0 rounded-3 ">
                  <img src={OrderIcon} alt="" className="icon" />
                </span>
                <h4 className="mb-0 ml-2">Total Orders</h4>
              </div>
              <h2 className="fw-bold mb-1">0</h2>
              <p>Total for this month</p>
            </div>
          </Col>
          <Col sm="6" xl="3">
            <div className="stats-card stats-card-returns p-3">
              <div className="d-flex mb-2 gap-2 align-items-center">
                <span className="icon-box p-0 rounded-3">
                  <img src={ReturnIcon} alt="" className="icon" />
                </span>
                <h4 className="mb-0">Total Returns</h4>
              </div>
              <h2 className="fw-bold mb-1">{returned.length}</h2>
              <p>Total for this month</p>
            </div>
          </Col>
          <Col sm="6" xl="3">
            <div className="stats-card stats-card-products p-3">
              <div className="d-flex mb-2 gap-2 align-items-center">
                <span className="icon-box p-0 rounded-3">
                  <img src={ProductsIcon} alt="" className="icon" />
                </span>
                <h4 className="mb-0">Total Products</h4>
              </div>
              <h2 className="fw-bold mb-1">{stats.all_stock}</h2>
              <p>Total for this month</p>
            </div>
          </Col>
          <Col sm="6" xl="3">
            <div className="stats-card stats-card-income p-3">
              <div className="d-flex mb-2 gap-2 align-items-center">
                <span className="icon-box p-0 rounded-3">
                  <img src={IncomeIcon} alt="" className="icon" />
                </span>
                <h4 className="mb-0">Total Income</h4>
              </div>
              <h2 className="fw-bold mb-1">₹ 0.00</h2>
              <p>Total for this month</p>
            </div>
          </Col>
        </Row>
        <Container className="training_main mt-4 mb-5">
          <h4 className="mb-4">Seller Panel Training Video</h4>
          <div className="training_container">
            <div className="traning_left">
              <div className="traning_video">
                <div className="video-box">
                  <iframe
                    width="100%"
                    height="210"
                    className="rounded-2"
                    src="https://www.youtube.com/embed/YGb2t6Ec2Hw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                {/* Message */}
                <div className="content_box">
                  <div className="">
                    <h5>
                      <FaCheckCircle className="fs-3 check_icon" />{" "}
                      Congratulation, Your <br />
                      registration is completed.
                    </h5>
                    <p className="text-black">
                      Watch our seller training session and <br />
                      understand every feature of the seller panel.
                    </p>
                  </div>
                  <Link to={PATH.CATALOGUE}>
                    <button className="btn-lg">Start Product Listing</button>
                  </Link>
                </div>
              </div>
            </div>
            {/* Right */}
            <div className="traning_right">
              <div className="message_right">
                <h5 className="right_heading">
                  Account management service provider agency
                </h5>
                <p>Signature Ecom Solution</p>
                <p>
                  <span className="agency-offer">(First 20 listing free)</span>{" "}
                  8871011028
                </p>
              </div>
            </div>
          </div>
        </Container>
        {/* // New Stats */}
        {/* <Container>
         
          <div className="main-flex">
            
            <div className="left-flex">
              
              <div className="new-card">
                <div className="upper">
                  <div className="icon">
                    <img
                      src={pendingOrderIcon}
                      alt="icon"
                      className="icon_inner"
                    />
                  </div>
                  <div className="label-box">
                    <p className="label">Pending Orders</p>
                  </div>
                </div>
                <div className="lower">
                  <h5>0</h5>
                </div>
              </div>
            
              <div className="new-card">
                <div className="upper">
                  <div className="icon">
                    <img
                      src={pendingDispatchIcon}
                      alt="icon"
                      className="icon_inner"
                    />
                  </div>
                  <div className="label-box">
                    <p className="label">Pending Dispatch</p>
                  </div>
                </div>
                <div className="lower">
                  <h5>0</h5>
                </div>
              </div>
              
              <div className="new-card">
                <div className="upper">
                  <div className="icon">
                    <img
                      src={outOfStockIcon}
                      alt="icon"
                      className="icon_inner"
                    />
                  </div>
                  <div className="label-box">
                    <p className="label">Out of stock</p>
                  </div>
                </div>
                <div className="lower">
                  <h5>0</h5>
                </div>
              </div>
              
              <div className="new-card">
                <div className="upper">
                  <div className="icon">
                    <img src={viewIcon} alt="icon" className="icon_inner" />
                  </div>
                  <div className="label-box">
                    <p className="label ">Total Views</p>
                  </div>
                </div>
                <div className="lower">
                  <h5 className="h5-last">0</h5>
                  <p className="label label-last">total for this month</p>
                </div>
              </div>
            </div>
            
            <div className="right-flex">
              <div className="right_left_one">
                <img
                  src={Live_Traning_icon}
                  alt="icon"
                  className="live_traning_image"
                />
              </div>
              <div className="right_left_two">
                <h5>Join free live training</h5>
                <p className="new-p">
                  Get free live training on order processing with a Groupick
                  expert
                </p>
              </div>
              <div className="right_left_three">
                <Link>
                  <button className="btn-lg">Book training now</button>
                </Link>
              </div>
            </div>
          </div>
        </Container> 
        */}
        {/* //////////////////////////// */}
        <div className="carousel my-4">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block h-100 w-100 carousel-image"
                src={CarouselImage1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block h-100 w-100 carousel-image"
                src={CarouselImage2}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block h-100 w-100 carousel-image"
                src={CarouselImage3}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block h-100 w-100 carousel-image"
                src={CarouselImage4}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block h-100 w-100 carousel-image"
                src={CarouselImage5}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <Row className="my-3">
          {/* <Col>
            <iframe
              width="100%"
              height="350"
              className="rounded-3"
              src="https://www.youtube.com/embed/Cir_QrcUJZ0"
              title="Groupick Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Col> */}
          <Col md="6" xl="3">
            <iframe
              width="100%"
              height="200"
              className="rounded-2"
              src="https://www.youtube.com/embed/yL_S4S_3VIs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Col>
          <Col md="6" xl="3">
            <iframe
              width="100%"
              height="200"
              className="rounded-2"
              src="https://www.youtube.com/embed/r0EQTVmqn6k"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Col>
          <Col md="6" xl="3">
            <iframe
              width="100%"
              height="200"
              className="rounded-2"
              src="https://www.youtube.com/embed/SpI3ZOmeKMQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Col>
          <Col md="6" xl="3">
            <iframe
              width="100%"
              height="200"
              className="rounded-2"
              src="https://www.youtube.com/embed/GOFDSIg89G8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </StyledDashboard>
  );
};

export default Dashboard;
