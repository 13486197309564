import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { Button, Form } from "react-bootstrap";
import supportService from "../../../redux/services/support.service";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { toast } from "react-toastify";

const TicketSidebarView = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  .ticketSidebar__container {
    position: absolute;
    right: 0;
    background: #fff;
    height: 100%;
    max-width: 500px;
    width: 100%;
    padding: 16px;
    overflow-y: auto;
    animation: 1.5s
      ${keyframes({ from: { width: "0%" }, to: { width: "100%" } })} linear;
    .ticketSidebar__heading {
      border-bottom: 2px solid var(--primary-color);
      font-size: 18px;
      text-align: center;
      margin: 0;
      padding-bottom: 8px;
    }
    .ticketSidebar__closeIcon {
      position: absolute;
      left: 0;
      top: 0;
      margin: 16px;
    }
  }
  .form-label {
    margin: 0;
    font-weight: 500;
  }
`;

function TicketSidebar(props) {
  const auth = useAuth();
  const [submiting, setSubmiting] = useState(false);
  const [detail, setDetail] = useState({
    issue: props.issueName,
    issueId: props.issueId,
    description: "",
    file: null,
  });
  const [error, setError] = useState({
    name: null,
    error: null,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!detail.description) {
      return setError({ name: "description", msg: "Enter description" });
    }
    setSubmiting(true);
    setError({ name: null, error: null });

    const formData = new FormData();
    formData.append("description", detail.description);
    formData.append("file", detail.file);
    const payload = {
      id: detail.issueId,
      formData,
      token: auth?.mee_too_user.token,
    };
    supportService
      .raiseSupport(payload)
      .then((result) => {
        const { status } = result.data;
        setSubmiting(false);
        if (!status || status === "error") {
          return toast.error("Something went wrong!");
        }
        props.onClose();
        return toast.success("We will get back to you!");
      })
      .catch((err) => {
        setSubmiting(false);
        console.log("Raise error:", err);
        return toast.error("Try again later!");
      });
  };

  return (
    <TicketSidebarView>
      <div className="ticketSidebar__container">
        <AiOutlineClose
          role="button"
          onClick={props.onClose}
          size={16}
          className="ticketSidebar__closeIcon"
        />
        <h3 className="ticketSidebar__heading">Raise a Ticket</h3>
        <Form className="my-3" onSubmit={handleSubmit}>
          <Form.Group className="mb-1" controlId="issueName">
            <Form.Label>Issue</Form.Label>
            <Form.Control type="text" disabled={true} value={detail.issue} />
          </Form.Group>
          <Form.Group className="mb-1" controlId="description">
            <Form.Label>
              Description <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter issue here"
              style={{ height: "100px", resize: "none", boxShadow: "none" }}
              onChange={(e) => {
                if (!e.target.value) {
                  setError({ name: "description", msg: "Enter description" });
                } else {
                  setError({ name: null, error: null });
                }
                setDetail((prev) => ({ ...prev, description: e.target.value }));
              }}
            />

            {error.name === "description" && (
              <Form.Text className="text-danger">
                Please enter description.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="formFileSm" className="mb-3">
            <Form.Label>Upload file</Form.Label>
            <Form.Control
              onChange={(e) =>
                setDetail((prev) => ({ ...prev, file: e.target.files[0] }))
              }
              type="file"
              size="sm"
              style={{ boxShadow: "none" }}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={submiting}>
            Submit
          </Button>
        </Form>
      </div>
    </TicketSidebarView>
  );
}

export default TicketSidebar;
