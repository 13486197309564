import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const SellerAgreement = () => {
  return (
    <Container>
      <StyledBreadCrumb>
        <StyledBreadCrumb.Item active href="#">
          Dashboard
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Settings
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Legal & policies
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Suplier Agreement
        </StyledBreadCrumb.Item>
      </StyledBreadCrumb>
      <h1>SELLER AGREEMENT</h1>
      <p>
        This Agreement is entered between Getzie Technology Private Limited
        (&quot;Groupick&quot;), a company registered under the Companies Act,
        2013 having its registered office at Sairaj Complex, A/204, Sanpada
        Sector 01, Navi Mumbai, Maharashtra 400705 India; and the registered
        Seller (“Seller”) who shall list the Products (defined below) and avail
        Services (defined below) from Groupick and whose details are recorded as
        per the documents provided during registration with Groupick.
      </p>
      <p>
        This Agreement is an electronic record in terms of Information
        Technology Act, 2000 and being a system generated document does not
        require any physical signatures. By clicking the &#39;Accept&#39; or a
        similar option and registering or using Services, Seller confirms that
        he/she/it has read and understood the Agreement and agrees to be bound
        by the terms and conditions of this Agreement including in relation to
        the sale of the Products listed by Seller. Groupick and Seller shall be
        individually referred as Party and collectively as Parties.
      </p>
      <p>
        <strong>WHEREAS:</strong>
      </p>
      <p>
        1. Groupick owns and operates an e - commerce marketplace named
        www.Groupick.in and android app,
        https://play.google.com/store/apps/details?id=com.groupickapp(collectively,
        &quot;Application&quot;) where registered Sellers can offer to sell
        their respective Products to Users of the Application.
      </p>
      <p>
        2. Seller is the brand owner/manufacturer/Seller/distributor/retailer of
        the Products (defined below) to be listed on the Application and has the
        authority and capacity to list and sell the Products on the Application.
        Users place the Order (defined below) of the Products listed by the
        Seller on the Application.
      </p>
      <p>
        3. Groupick&#39;s role as a marketplace is limited to managing the
        Application, associated marketing, payment collections, order
        management, logistics, enquiry management and other incidental services
        to enable the Order(s) between Seller and the User, described in detail
        in SCHEDULE 1 (collectively, &quot;Services&quot;).
      </p>
      <p>
        NOW THEREFORE, in consideration of the foregoing promises and for other
        good and valuable consideration, the receipt and adequacy of which are
        hereby acknowledged, the Parties hereto hereby agree as follows:
      </p>
      <h4>DEFINITIONS AND INTERPRETATION</h4>
      <p>
        <strong>
          Unless the context otherwise requires, the following words and
          expressions shall have the meanings as set out herein below:
        </strong>
      </p>
      <p>
        <strong>Affiliate</strong> of a Person means (i) in the case of any
        Person that is a natural person, any other Person (other than a natural
        person) that, either directly or indirectly, is Controlled (defined
        below) by the Person, or any Person who is a Relative (defined below) of
        the Person; and (ii) in the case of any Person other than a natural
        person, any other Person that, either directly or indirectly through one
        (1) or more intermediate Persons, Controls, is Controlled by, or is
        under the common Control with the said Person &quot;Agreement&quot;
        shall mean this Seller Agreement entered between the Parties for use and
        access of the Application and it includes terms of use, Seller Policies
        and other policies intimated by Groupick over Seller Panel (or otherwise
        communicated).
      </p>
      <p>
        <strong>Applicable Law</strong> means all applicable provisions of all
        (i) constitutions, treaties, statutes, enactments, laws (including the
        common law), acts of legislature or parliament, codes, rules,
        regulations, ordinances, or byelaws including but not limited to the
        Legal Metrology Act, 2009 and the rules thereof and the Consumer
        Protection Act, 2019 and the rules thereof as well as notifications,
        guidelines or policies issued by any governmental authority; (ii)
        administrative interpretation, writ, orders, decisions, directions,
        directives, injunctions, judgments, arbitral awards, awards, decrees of
        or agreements with any governmental authority; or (iii) consent,
        approval, authorisation, waiver, permit, grant, franchise, concession,
        agreement, license, certificate, exemption, order, registration,
        declaration, filing, report or notice of, with, to or from any
        governmental authority, whether in effect as of the date of this
        Agreement or at any time thereafter; or (iv) international treaties,
        conventions and protocols including related to anti-corruption laws and
        anti-money laundering laws, in each case, which may be in force from
        time to time.
      </p>
      <p>
        <strong>&quot;Application&quot;</strong> shall have the meaning assigned
        to such term in Recital A;
      </p>
      <p>
        <strong>&quot;Business Day&quot;</strong> shall refer to any day when
        the scheduled commercial banks in India are open for business, and shall
        exclude Sundays and notified public holidays;
        <br /> <strong>&quot;Commission&quot;</strong>
        shall have the meaning assigned to such term in clause 4.1; <br />
        <strong>&quot;Confidential Information&quot;</strong> shall have the
        meaning assigned to such term in clause 10.6; <br />{" "}
        <strong>&quot;Control&quot;</strong> shall mean, with respect to a
        Person, the acquisition or control, directly or indirectly, of more than
        50% (fifty per cent) of the voting rights or of the issued share capital
        of such Person or the right to appoint and/or remove the majority of the
        members of the board of directors or other governing body of such
        Person, the power to direct or cause the direction of the management, to
        exercise significant influence on the management or policies of such
        Person, whether obtained directly or indirectly, and whether obtained by
        ownership of share capital, the possession of voting rights, through
        contract or otherwise., and the terms <br />{" "}
        <strong>&quot;Controlling,&quot;</strong>
        <strong>&quot;Controlled by&quot;</strong> and{" "}
        <strong>&quot;under common Control with&quot;</strong>
        shall have corresponding meaning;
        <br /> <strong>&quot;Data&quot; </strong>shall have the meaning assigned
        to such term in clause 5.1; <br />{" "}
        <strong>&quot;Force Majeure Event&quot;</strong> shall have the meaning
        assigned to such term in clause 14.1; <br />{" "}
        <strong>&quot;Intellectual Property&quot;</strong>
        shall have the meaning assigned to such term in clause 10.2;
        <br /> <strong>&quot;Order&quot;</strong> shall mean a final purchase
        order placed by a User with the Seller through the Application for the
        purchase of the Product; <br /> <strong>&quot;Person&quot;</strong>{" "}
        means any natural person, firm, company, joint venture, partnership,
        association or other entity (whether or not having separate legal
        personality); <br /> <strong>&quot;Policy&quot;</strong> refers to the
        policies intimated by Groupick via Seller Panel or posted in the
        Application which needs to be complied by Seller for use of the
        Application including but not limited to terms of use;
        <br /> <strong>&quot;Product(s)&quot;</strong> shall mean such products
        as are proposed to be sold by Seller through the Application pursuant to
        this Agreement; <br /> <strong>&quot;Relative&quot;</strong> shall have
        set out in Section 2(77) of the Companies Act, 2013;
        <br /> <strong>&quot;Representative&quot;</strong> shall have the
        meaning assigned to such term in clause 3.5; <br />{" "}
        <strong>&quot;Service Fee&quot;</strong>
        shall have the meaning assigned to such term in clause 4.2; <br />{" "}
        <strong>&quot;Seller Fee&quot;</strong> shall have the meaning assigned
        to such term in clause 4.6; <br />{" "}
        <strong>&quot;Seller Panel&quot;</strong> means different panels which
        are provided by Groupick to Seller on the Application or through a
        Groupick authorised web link with functionalities described in more
        detail in SCHEDULE 1; <br />{" "}
        <strong>&quot;Seller Fee Payment Date&quot;</strong> shall refer to the
        next Business Day date post completion of Seller Fee Payment Cycle which
        shall be 8th Business Day from the Order delivery date, upon which
        Groupick shall make payment of Seller’s Fee to the Seller as per clause
        4.6. <br /> <strong>&quot;Seller Fee Payment Cycle&quot;</strong> shall
        refer to the 7 Business Days’ period from the Order delivery date,
        excluding the Order delivery date. <br />{" "}
        <strong>&quot;Seller Information”</strong> refers to the details sought
        by Groupick for registering Seller over the Application; <br />{" "}
        <strong>&quot;Seller Policies&quot;</strong> means the policies of
        Groupick in force, as amended from time to time, available on the Seller
        Panel. <br /> <strong>&quot;Term&quot;</strong> shall have the meaning
        assigned to such term in clause 12.1; <br />{" "}
        <strong>&quot;Territory&quot;</strong> shall refer to the Republic of
        India; <br /> <strong>&quot;User&quot;</strong> shall refer to the any
        Person using the Application for placing an Order with the Seller
        including but not limited to reseller/entrepreneur and customers.
      </p>
      <h4>REGISTRATION AND ENROLLMENT</h4>
      <p>
        To avail the Services, Seller must complete the registration process
        available on the Application. Such registration and use of Services are
        limited to parties who can enter into a legally binding agreement and
        are competent to contract as per the Indian Contracts Act, 1972. Seller
        represents that, it is not a minor i.e. under 18 years of age. If Seller
        represents a business entity, Seller warrants that it is legally
        authorised to make representations on behalf of such entity and bind it
        to this Agreement and to list Products on the Application. Seller shall
        furnish various details including its (or its business&#39;) legal name,
        address, phone number, e-mail address, bank account details, applicable
        tax registration details as well as any other information as Groupick
        may request. The Seller consents to Groupick collecting sensitive
        personal information about the Seller. Groupick shall store, process,
        use and share the sensitive personal information and other data
        collected about the Seller in accordance with the privacy policy which
        can be accessed at https://Groupick.in/legal/privacy?embed=true. Any
        password provided to Seller by Groupick may be used only during the Term
        to access its Seller Panel account (or other tools) to use the Service,
        electronically accept transactions, and review its completed
        transactions. Seller is solely responsible for maintaining the security
        of its password. Seller shall not disclose its password to any third
        party (other than third parties authorized by Seller to use its account
        in accordance with this Agreement) and are solely responsible for any
        use of or action taken under its password. If Seller password is
        compromised, it must immediately change its password. User shall be
        responsible for authorized or unauthorized access to the account of the
        User by any other person. User shall bear all responsibility for the
        confidentiality of the User’s password and all use or charges incurred
        therefrom. Seller agrees that Groupick, at its sole discretion, retains
        the right to at any time, terminate or suspend the Sellers account or
        indefinitely block Seller from accessing the Application in accordance
        with Seller Deactivation Policy as available on the Seller Panel or
        terminate the Agreement. The Seller acknowledges that the
        Application/Seller Panel is proprietary to Groupick and is and always
        shall be the property of Groupick. Any intellectual property created on,
        or using, or for storage in the Application/Seller Panel has always and
        shall always vest in Groupick. The Seller also acknowledges and confirms
        that its access to the Portal cannot be licensed, sold or assigned. The
        Seller acknowledges and confirms that from time to time, the
        Application/Seller Panel may be inaccessible or inoperable for any
        reason, including without limitation, (i) equipment malfunction; (ii)
        periodic maintenance procedures, or (iii) causes which are beyond the
        control of Groupick or which are not foreseeable by Groupick.
        Additionally, the Seller acknowledges that Groupick has the right at any
        time to change, discontinue or remove any aspect or feature of the
        Application/Seller Panel, including, but not limited to the content,
        hours of availability and equipment needed for access or use. Groupick
        does not accept any responsibility and will not be liable for any loss
        or damage whatsoever arising out of or in connection with the Sellers’
        ability/inability to access or to use the Application/Seller Panel.
      </p>
      <h4>PRODUCT LISTING, SALES AND MANAGEMENT</h4>
      <p>
        Groupick shall permit the Seller to list the Products on the Application
        through the Seller Panel, on such terms contained in this Agreement or
        as provided in the Seller Panel from time to time, provided however,
        that Groupick reserves the right to select or delist any Product or
        delist the Seller or remove/disable access of the Seller to the
        Application/Seller Panel, in each case, at its sole discretion. Seller
        shall at all times comply with the provisions of this Agreement, the
        notifications on the Seller Panel and the Seller Policies, as amended
        from time to time on the Seller Panel. Seller is bound to accept a User
        as a contractual party and handle the Order in compliance with the
        information contained on the Seller Panel at the time the Order was
        made, including any supplementary information made known by the User.
        Seller shall be solely responsible for ensuring the quality, originality
        and sufficiency of the Products listed on Application and shall ensure
        that the Products are at all times in compliance with the standards
        prescribed for such Products under Applicable Law and the Seller
        Policies. Groupick shall in no event, be responsible for any deficiency
        in the quality, originality or sufficiency of the Products listed on the
        Application and any services provided by the Seller to the User.
        Groupick shall provide account management services (itself or through
        its agents) to the Seller if the Seller opts to receive the same as an
        add-on service that the Seller may be entitled to receive for an
        additional payment. The detailed scope of the account management
        services offered to the Sellers are set out in detail in Schedule 2
        hereto. The Sellers interested in receiving such account management
        services would be required to execute a separate addendum agreement in
        the form approved by Groupick. Groupick shall have the sole right to
        modify the composition or nature of the Services or the Application,
        including the manner in which the Services are provided, without
        Seller&#39;s prior written consent. Any changes to the Services or the
        Application shall be reasonably endeavoured to communicated to the
        Seller. Seller shall designate 1 (one) individual who shall be the
        primary point of contact for any matter that may arise under this
        Agreement (&quot;Representative&quot;). Seller shall have the right to
        change the Representative upon provision of one (1) month’s prior
        written notice to Groupick. Seller shall use the promotional and
        marketing materials including but not limited to the packaging material
        such as box, bags, covers, wrappers and other materials, bearing
        Groupick’s Intellectual Property including trademark, copyright or
        design as per the instructions provided by Groupick. Seller further
        agrees and acknowledges that the Intellectual Property rights associated
        with these materials shall solely vest with Groupick. In no manner shall
        the use of materials by the Seller purport or vest any rights or license
        in any Groupick’s Intellectual Property.
      </p>
      <h4>COMMISSION AND SERVICE FEE</h4>
      <p>
        In consideration for permitting the Seller to sell Products on the
        Application and have access to Seller Panel, Groupick shall charge a fee
        on every eligible Order placed on the Application, at such rates
        prescribed in the Seller Panel from time to time (the &quot;
        Commission&quot;). The Fee (which is a percentage of the sales amount)
        may be revised from time to time and such revision shall be communicated
        by Groupick through the Seller Panel. Groupick shall also charge Seller
        for the Services provided, including any marketing expenditure and
        logistics charges incurred by Groupick on behalf of Seller (the
        &quot;Service Fee&quot;), at such rates prescribed in the Seller Panel.
        Groupick shall submit an invoice to the Seller on a monthly basis for
        the Service Fee payable by Seller on or after the seventh (7 th )
        Business Day of the following month. All payments shall be made by
        Seller in Indian National Rupees (INR) unless otherwise mutually agreed
        by the Parties in writing. The invoicing and payment of the Seller Fee
        shall be as per the prescribed Seller Policies in this regard. At
        Groupick&#39;s option, all payments to Seller will be made to the Seller
        bank account provided by Seller during registration, via cheque or
        electronic transfers or other means as specified by Groupick. Seller
        agrees that Groupick shall not be liable for any failure to make
        payments to Seller on account of incomplete or inaccurate information
        provided by Seller with respect to its bank account. Groupick shall set
        off any losses, Service Fee or Commission payable by Seller against the
        amount/(s) payable to Seller. Groupick’s right under this clause shall
        be in addition to, and not in derogation of, all other rights available
        to Groupick under this Agreement or Applicable Law. The payment for the
        Orders shall be collected by Groupick on Seller&#39;s behalf
        (&quot;Seller Fee&quot;) acting as a marketplace with the sole intent of
        facilitating Orders. Groupick shall subject to deductions under this
        clause, transfer the balance Seller Fee to the bank account designated
        by Seller, as provided to Groupick. Groupick will raise tax invoices on
        behalf of the Seller basis the HSN code and Goods and Service Tax
        (“GST”) rate provided by the Seller. Groupick shall collect the Seller
        Fee from the User, on Seller&#39;s behalf, and shall transfer such
        amount/(s), subject to the deduction of the Commission or/and Service
        Fee or/and shipping fees, penalty and taxes prescribed by the Government
        on Seller Fee Payment Date upon completion of the Seller Fee Payment
        Cycle. Furthermore, it is clarified that the Seller Fee Payment Date is
        to be construed as the day on which the transaction is deemed to be
        completed for purposes of settlement of any funds which shall be the
        next Business Day upon completion of Seller Payment Cycle. In case the
        said agreed-upon date of transaction completion falls on a banking
        holiday or Saturday / Sunday however, it is agreed between Groupick and
        the Seller that the deemed transaction completion date would then fall
        on the next business / working day for Groupick. Notwithstanding the
        provisions of this Agreement, nothing in this Agreement shall be
        construed as Groupick operating a payments and settlement system as
        defined under the Payment and Settlement Systems Act, 2007. Furthermore
        as Seller Fee Payment Cycle is subject to Product returns, so
        notwithstanding anything to the contrary contained in this Agreement,
        the Seller Fee Payment Cycle and Seller Fee Payment Date shall change
        corresponding to Product returns by the Seller&#39;s customer(s), if any
        and accordingly in such a case, the Seller Fee Payment Cycle shall be 15
        Business Days&#39; period from the Order delivery date, excluding the
        said Order delivery date and Seller Fee Payment Date shall be the 16th
        Business Day from the Order Delivery date. If based on information
        available to Groupick, Groupick reasonably concludes that Seller actions
        and/or performance in connection with the Agreement may result in a
        significant number of customer disputes, chargebacks or other claims in
        connection with the Application, then Groupick may, in its sole
        discretion and subject to Applicable Law, delay initiating any payments
        to be made or that are otherwise due to Seller under this Agreement for
        the earlier of: (a) a period of 90 (ninety) calendar days following the
        initial date of suspension; or (b) completion of any investigation(s)
        regarding Seller actions and/or performance in connection with the
        Agreement. Seller agrees that Groupick is entitled to the interest, if
        any, paid on balances maintained as deposits in its bank accounts.
        Seller shall be responsible for any applicable value added tax, goods
        and service tax (GST), service tax, sales tax, real or personal property
        tax, income or any other taxes, cess, levy whatsoever including taxes
        relating to the Products, attributable to or incurred by Seller.
        Groupick shall withhold taxes/payments, if required under Applicable Law
        to be withheld on payments made to Seller hereunder and shall be
        required to remit to Seller only the net proceeds thereof. Groupick
        shall remit the taxes withheld to the appropriate governmental authority
        and agree to provide Seller, in a timely manner, with properly executed
        documentation or other information or receipts or certificates
        evidencing Groupick’s payment of any such tax. Seller may deposit and
        submit Form 16A to Groupick towards deduction of tax at source against
        invoices issued by Groupick towards Service Fee charged to Seller.
        Seller shall submit the TDS claim for a given financial year within 6
        (six) months from the last date of such financial year. Groupick shall
        reimburse equivalent TDS amount, on receipt of valid Form 16A within 60
        (sixty) days from such receipt within 6 (six) months from end of a given
        financial year. In case at any point of time after onboarding if
        Seller’s GSTIN is cancelled, Seller should inform Groupick about the
        same and if any charges are levied on account of such cancellation will
        be recovered from Seller. It is the primary responsibility of the Seller
        to inform Groupick in case of any cancellation/suspension of GSTIN. In
        case of any discrepancy in the reporting / returns filed by Seller,
        Seller agrees that it will resolve such discrepancy immediately and
        indemnify Groupick against any claims, losses, taxes, interest and
        penalty payable in this regard.
      </p>
      <h4>DATA</h4>
      <p>
        The Seller acknowledges that it may receive some User data including
        sensitive personal information of the User pursuant to transactions
        carried out on the Application. The Seller acknowledges and agrees that
        Groupick and its Affiliates are and shall remain the sole owner of any
        User data, case files or any other associated User information
        (including sensitive personal information of the User) (collectively
        &quot;Data&quot;) at all times during the Term and post the Term of this
        Agreement. Seller shall ensure that Data shall not be: (i) used by
        Seller other than in connection with the sale of the Products; (ii)
        sold, assigned, leased, or otherwise, in any manner or form whatsoever
        disclosed to third Persons by the Seller; or (iii) commercially
        exploited by or on behalf of the Seller, its employees, subcontractors,
        agents or affiliates. The Seller further agrees that it shall only
        retain the Data only for so long as necessary for participation on the
        Application/Seller Panel or to fulfil statutory obligations (e.g. tax)
        and that the Seller shall on Groupick’s request delete all such
        information upon termination of this Agreement. As part of the Services,
        Seller shall promptly correct any errors or inaccuracies in Data caused
        by the Seller. Upon Groupick’s request, Seller shall also promptly
        correct any other errors or inaccuracies in the Data. All Data received
        or produced during the performance of the Services hereunder, and in
        Seller possession, shall be contained in a database and shall, upon
        Groupick’s written request, be delivered to Groupick or its Affiliates
        within the time period and in the format stated in the request by
        Groupick. In the event any or all of the Data is destroyed or damaged in
        any way, Seller shall, at Seller’s cost, promptly restore the Data or
        facilitate its collection. Seller shall protect the Data at all times
        and shall use the same degree of care to prevent the loss of or
        alteration of Data in the Seller’s possession that a prudent person
        would use to protect that person’s information and one which is at any
        cost not lesser than the standard of care that Seller use to protect its
        own information and other information that may be in its possession.
        Seller shall not use the Application or the Services, in whole or in
        part, for any purpose that is unlawful or prohibited by this Agreement
        or any Applicable Law. Without limiting the generality of the foregoing,
        Seller agrees that Seller will not modify, copy, distribute, transmit,
        display, perform, reproduce, publish, license, create derivative works
        from, frame in another web page, use on any other website or product,
        transfer, or sell any information, content, artwork, graphics, software,
        lists of users, databases or other lists or products provided through or
        obtained from the Application/Services other than for use as expressly
        permitted by this Agreement. This means, among other activities, that
        Seller agrees not to engage in the practices of &quot;screen
        scraping,&quot; &quot;database scraping,&quot; or any other activity
        with the purpose of obtaining lists of User or other information. Seller
        agrees that it will not use the Application/Services in any manner that
        could damage, disable, overburden, or impair the Application/Services or
        interfere with any other person&#39;s use and enjoyment of the
        Application/Services. Seller shall not obtain or attempt to obtain any
        materials or information through any means not intentionally made
        available or provided for through the Application/Services. Except with
        the prior written permission of Groupick, Seller agrees that it will not
        access or attempt to access password protected, secure or non-public
        areas of the Application/Services. Without limiting any of Groupick’s
        rights or remedies under this Agreement or available to Groupick at law
        or in equity, if Seller violates any of the terms and conditions herein
        or those displayed on the Application/Seller Panel, its Seller Panel
        account and/or other access to Application may be terminated and Seller
        may be subject to prosecution.
      </p>
      <h4>
        ANTI-CORRUPTION AND ANTI-BRIBERY, ANTI MONEY LAUNDERING AND
        WHISTLEBLOWER MECHANISM
      </h4>
      <p>
        Seller agrees that its performance under this Agreement will be in full
        compliance with all applicable anti-corruption laws and regulations,
        including but not limited to the U.S Foreign Corrupt Practices Act and
        the UK Bribery Act. Accordingly, Seller agrees that in connection with
        its activities under this Agreement, neither the Seller nor any agent,
        affiliate, employee or other person acting on its behalf will offer,
        promise, give or authorize the giving of anything of value, or offer,
        promise, make, or authorize the making of any bribe, rebate, payoff,
        influence payment, facilitation payment, kickback, or other unlawful
        payment, to any government official, political party, or candidate for
        public office in order to obtain or retain business, gain any unfair
        advantage, or influence any act or decision of a government official.
        Seller hereby agrees and undertakes that the Seller and it’s Affiliates,
        agents, subcontractors, employee or representatives shall at all times
        comply with anti-money laundering laws. <br />
        In any event Groupick determines, in its sole discretion, that the
        Seller has engaged in any conduct that violates applicable
        anti-corruption laws, anti-money laundering laws and regulations,
        Groupick shall immediately have the right to suspend the Services and
        thereafter terminate the Agreement.
      </p>
      <p>
        <strong>Whistleblower mechanism-</strong> Groupick encourages its
        employees and partners such as Sellers, users and anyone having any
        connection with Groupick, who have reasonable suspicions of misconduct
        to report such concerns without fear of punishment. The Seller can make
        disclosures by a written communication in good faith in relation to
        information that is indicative of unethical or improper activity. It is
        hereby clarified that such disclosures should not be personal or
        speculative in nature and should be based on facts. Upon receipt of such
        disclosures, Groupick shall ensure that the Seller is not victimized and
        that the disclosure is kept confidential and is protected in all
        respects. Further, Groupick shall on a best efforts basis ensure that
        the Seller will not be subjected to any kind of discrimination and will
        not face any retaliation. Any disclosure as per this clause can be
        reported online by addressing a mail to whistleblower@Groupick.in or in
        writing to
      </p>
      <br />
      <p>The CFO.</p>
      <p>Getzie Technology Private Limited</p>
      <p>
        Sairaj Complex, A/204, Sanpada Sector 01,
        <br />
        Navi Mumbai,
        <br /> Maharashtra 400705
      </p>
      <h4>COMPLIANCE WITH APPLICABLE LAWS</h4>
      <p>
        Seller’s Products shall at all times be compliant with all the
        Applicable Law, including but not limited to, packing and labelling
        requirement under Legal Metrology Act, 2009, Food Safety and Standards
        Act, 2006, Drugs and Cosmetics Act, 1940 Consumer Protection Act, 2019
        and its rules, labour laws, environmental laws and rules made
        thereunder. Further, Seller shall also be responsible for making payment
        of applicable taxes on manufacture and sale of Products to the User. The
        Seller shall not engage in any unfair trade practises and will not
        undertake any actions which are likely to result in any User being
        mislead or deceived. <br />
        Seller shall also ensure full compliance with the applicable tax laws
        including but not limited to the provisions of Integrated Goods and
        Services Tax (IGST), Central Goods and Services Tax (CGST) and Union
        Territory Goods and Services Tax (UTGST) or State Goods and Services Tax
        (SGST) in respect of the Products supplied by the Seller. If Seller is
        responsible for B2C QR code invoicing it is the responsibility of Seller
        to inform Groupick with adequate time period of at-least 30 days for
        enabling such invoicing feature. In case of non-compliance arising for
        not creating such invoice on account misinformation/non-communication by
        the Seller, Groupick will not be responsible for such default by the
        Seller and Seller shall be liable for any fine levied by government
        accordingly.
        <br />
        In case the Seller has not complied with Section 206AB of Income Tax
        Act,1961 the Seller acknowledges and accepts that Groupick will deduct
        TDS at higher rates, as applicable.
      </p>
      <h4>Seller’S REPRESENTATIONS AND WARRANTIES</h4>
      <p>
        Seller represent and warrants to Groupick as follows that: to the extent
        applicable, Seller is duly organised, validly existing and in good
        standing under the Applicable Law of its incorporation or in the
        jurisdiction in which Seller is a resident and/ or do business and that
        Seller has full authority to enter into this Agreement and to perform
        all the obligations hereunder according to the terms hereof; Seller has
        the necessary expertise and resources to carry out its obligations
        hereunder and there is no restriction, bar, constraint or prohibition on
        its carrying out the same; Seller and its Affiliates, sub-contractors,
        agents, employees and representatives do not contravene any Applicable
        Laws or that it is hindered or obstructed in for effectively performing
        its obligations under the terms and conditions of this Agreement and the
        Products listed on the Application do not contravene any Applicable Law
        or the terms and conditions of this Agreement; all consents,
        permissions, approvals, authorisations, orders, registrations or
        qualifications of, or with, any court or governmental authority having
        jurisdiction over Seller, have been obtained and are valid and shall be
        kept current, valid and fully operational during the Term pursuant to
        Applicable Law; neither the execution and delivery of this Agreement,
        the consummation of the transactions contemplated hereby, or the
        fulfilment of or compliance with the terms and conditions of this
        Agreement, conflict with or infringe upon any third party rights or
        result in a breach of or a default under any of the terms, conditions or
        provisions of any legal restriction (including, without limitation, any
        judgment, order, injunction, decree or ruling of any court or
        governmental authority, or any federal, state, local or other law,
        statute, rule or regulation) or any covenant or agreement or instrument
        to which Seller is a party, or by which Seller or any of its property is
        bound, nor does such execution, delivery, consummation or compliance
        violate or result in the violation of its constitutional documents;
        Seller has all requisite approvals, authorisations and permissions to
        sell the Products through all channels of sale and to promote, market
        and use the Product images, Product name, brand name and Product
        description including from the brand owners; Seller is solely
        responsible for discharge of all tax liabilities and related compliances
        sales of all products and services sold on the Application; Seller and
        its Affiliates, sub-contractors, agents, employees and representatives
        shall not infringe upon any third party rights while performing its
        duties and responsibilities under this Agreement nor has any claim of
        such infringement or violation been threatened or asserted against the
        Seller and its Affiliates, sub- contractors, agents, employees and
        representatives; and The Seller and its Affiliates, sub-contractors,
        agents, employees and representatives are not subject to sanctions or
        otherwise designated on any list of prohibited or restricted parties or
        owned or controlled by such a party, including but not limited to the
        lists maintained by the United Nations Security Council, the US
        Government (e.g., the US Department of Treasury’s Specially Designated
        Nationals list and Foreign Sanctions Evaders list and the US Department
        of Commerce’s Entity List), the European Union or its member states, or
        other applicable government authority; and All Know your customer (KYC)
        information including GST registration provided at the time of
        onboarding is correct.
      </p>
      <h4>Seller’S COVENANTS AND UNDERTAKINGS</h4>
      <p>
        Seller covenants and undertakes to Groupick that it and its Affiliates,
        sub-contractors, agents, employees and representatives shall: act in
        accordance with the Agreement and exercise reasonable skill, care and
        diligence in the performance of its obligations hereunder; ensure
        compliance with all Applicable Law including but not limited to
        provisions of Legal Metrology Act, 2009, Food Safety and Standards Act,
        2006, Consumer Protection Act, 2019, Drugs and Cosmetics Act, 1940 and
        its regulations or rules as amended from time to time; not infringe upon
        any third party rights (including rights of Groupick) while performing
        its duties and responsibilities under this Agreement; obtain and
        maintain all licenses, permits and approvals required by the
        governmental authorities for performance of its duties and
        responsibilities under this Agreement, and furnish proof thereof to
        Groupick, and inform Groupick immediately of the expiration,
        termination, non-renewal, denial or revocation of any such license,
        permit or approval including (but not limited to) labour laws, health
        and safety laws, and all other local legislations that may be applicable
        to the Seller; exercise its discretion in accordance with industry best
        practices and for the benefit of Groupick and its Affiliates; observe
        and conform to all standards of business and shall not act, and shall
        refrain from acting, in any manner that could harm or tarnish the name,
        reputation, standing or goodwill of Groupick and its Affiliates; not
        incur any debt, loan or indebtedness in the name of Groupick, nor use or
        imply any authority to use the credit of Groupick; undertake all repair,
        replacement, upgrade or procurement of its own equipment/
        infrastructural facilities whether owned, leased, licensed or any form
        having control over by them which are necessary to facilitate the
        performance of this Agreement, at its own costs; for the duration of the
        Term, not sell any Product to a third Person, or list such Product on
        the platform of any third Person, at a price lower than the market price
        of such Product determined by Groupick in the manner provided in clause
        1.1 of Pricing Policy as per Seller Policies; and breach of this clause
        shall be considered material breach of this Agreement and upon
        occurrence of such event, Groupick shall be entitled to terminate this
        Agreement in accordance with clause 12.2.(b) of this Agreement; comply
        with Prohibited and Restricted Product Policy as available on the Seller
        Panel; provide all details to Groupick as required and mandated by
        Applicable Law; and bring to the notice of Groupick any expiry,
        modification, or suspension of any such approvals/ licenses and the
        initiation of any adverse action by the relevant authority concerned in
        relation thereto and which may interfere or have an adverse impact on
        the performance of its obligations under this Agreement including GST
        details. Failure to intimate Groupick and the liabilities arising
        thereof shall be the sole responsibility of the Seller. Seller shall and
        shall ensure that its Affiliates, sub-contractors, agents, employees and
        representatives do not host, display, upload, modify, publish, transmit,
        update or share any information or image or Product which: belongs to
        any third party and over which Seller has no right; is grossly harmful,
        harassing, blasphemous, defamatory, bigotry, obscene, pornographic,
        paedophilic, libellous, invasive of another’s privacy, hateful, or
        racially, ethnically objectionable, disparaging, relating to or
        encouraging money laundering or gambling, or otherwise unlawful in any
        manner whatever, or unlawfully threatening or harassing, including but
        not limited to ‘indecent representation of women’ within the meaning of
        the Indecent Representation of Women (Prohibition) Act, 1986; is false,
        inaccurate or misleading in any way; is patently offensive to the online
        community, such as sexually explicit content or content that promotes
        obscenity, pedophilia, racism, bigotry, hatred, or physical harm of any
        kind against any group or individual; harasses or advocates harassment
        of another person; promotes illegal activity or conduct that is abusive,
        threatening, obscene, defamatory, or libelous; infringes upon or
        violates any third party&#39;s rights [including but not limited to
        intellectual property rights, rights of privacy (including without
        limitation unauthorized disclosure of a person&#39;s name, email
        address, physical address, or phone number) or rights of publicity];
        contains restricted or password-only access pages, hidden pages or
        images or URLs leading to any other pages (those not linked to or from
        another accessible page); provides material that exploits people in a
        sexual, violent or otherwise inappropriate manner or solicits personal
        information from anyone; engages in commercial activities and/or sales
        such as contests, sweepstakes, barter, advertising, pyramid schemes, or
        the buying or selling of ‘virtual’ items related to the Application
        without Groupick&#39;s prior written consent. interferes with another’s
        use and enjoyment of the Application; refers to any website/URL which,
        at Groupick&#39;s sole discretion, contains material that is
        inappropriate for the Application or any other website and content that
        is prohibited or violates the letter and spirit of Agreement; harms
        minors in any way; infringes any patent, trademark, copyright,
        proprietary rights, third-party’s trade secrets, rights of publicity, or
        privacy, is fraudulent, or involves the sale of counterfeit or stolen
        items; violates any law for the time being in force; deceives or
        misleads the addressee/ Users about the origin of messages or
        communicates any information which is grossly offensive or menacing in
        nature; deceives or misleads the addressee/Users of the Products
        available on the Application or any other platform of Groupick;
        impersonates another Person; threatens the unity, integrity, defense,
        security or sovereignty of India, friendly relations with foreign
        states, or public order or causes incitement to the commission of any
        offence or prevents investigation of any offence or is insulting any
        other nation; offends the religious and national sentiments of the
        nation; creates liability for Groupick or causes Groupick to lose (in
        whole or part) the services of Groupick&#39;s internet service provider
        or other Sellers; or promotes any of the competitors to Groupick or any
        products associated with any competitors to Groupick. Failure to adhere
        to the above, as discovered by Groupick or as intimated to it by a third
        party shall result in immediate termination of this Agreement. The
        Seller acknowledges that the reputation of Groupick and its products and
        services is of the highest order. In no event shall the Seller and its
        Affiliates, sub-contractors, agents, employees and representatives take
        any action that adversely affects Groupick or any of Affiliates and/or
        their respective image, brand, reputation, products and/or services, or
        that casts any of the foregoing in a disparaging light, as determined by
        Groupick in its sole and absolute discretion, as applicable under law.
        In particular, Seller shall ensure that, at all times during this
        Agreement, that its conduct shall reflect adherence to the highest
        professional, moral and ethical standards. If, in the absolute judgment
        of Groupick, any act or omission of the Seller is inconsistent with any
        of the foregoing, Groupick may immediately terminate this Agreement for
        cause.
      </p>
      <h4>INTELLECTUAL PROPERTY AND CONFIDENTIALITY</h4>
      <p>
        Groupick grants the Seller with a limited, non-exclusive,
        non-transferable, non- sublicensable license to access, and make use of
        the Application/Seller Panel as set out under this Agreement. All rights
        not expressly granted to the Seller under this Agreement shall be
        reserved and retained by Groupick and its Affiliates. In the event that
        this Agreement is terminated, the license provided by Groupick to the
        Seller to access and make use of the Application/Seller Panel shall also
        lapse. During the Term, Seller hereby grants to Groupick and its
        Affiliates a limited, royalty free, worldwide, non-exclusive license for
        the use of its Intellectual Property and to display its name,
        trademarks, brand name, company name, business associates etc., and all
        Intellectual Property pertaining to the Products, including any Product
        details, logos, images, trademarks and brand names in order to perform
        its obligations under this Agreement. Groupick can assign, sub-license
        or transfer such license to its Affiliates, representatives or agents.
        Nothing in this Agreement will prevent or impair Groupick’s right to use
        the Sellers Intellectual Property or any Intellectual Property in the
        Products without the Sellers consent to the extent that such use is
        allowable without a licence from the Seller or its Affiliates under
        applicable Law (e.g., fair use under copyright law, referential use
        under trademark law, or valid licence from a third party). The Parties
        agree that this Agreement will not be deemed by implication or otherwise
        to grant to other Party any right in any Intellectual Property. Any use
        of Groupick’s Intellectual Property by the Seller shall inure to the
        benefit of Groupick and its Affiliates, and Seller shall not, now or in
        the future, apply for or contest the validity of any Groupick’s
        Intellectual Property or apply for or use any intellectual property
        confusingly similar to any Groupick’s Intellectual Property. Seller
        acknowledges that Groupick and its Affiliates are and will at all times
        be the owner of Groupick’s Intellectual Property and also undertakes not
        to challenge, directly or indirectly, the rights of Groupick or its
        Affiliates associated with Groupick’s Intellectual Property or otherwise
        jeopardise Groupick or its Affiliate’s rights over Groupick’s
        Intellectual Property. The intellectual property rights associated with
        the Products and the brand thereof are the Seller&#39;s property and
        that the Seller undertakes such Products do not infringe the
        intellectual rights of any third party or that the Seller has necessary
        authorization and permissions to allow the Seller to list/sell the
        Products on the Application. Intellectual Property for the purposes of
        this Agreement means all inventions, software, know-how, algorithms,
        source code, object code, technical and business plans, specifications,
        modules, hardware, circuits, computer languages, computer programs,
        databases, user interfaces, encoding techniques, mask works, words,
        marks, names, numerals, letters, devices, labels, brands and other
        documents, materials and innovations of any kind, whether or not the
        foregoing are eligible for patent, copyright, mask work, trade secret,
        trademark, service mark or other legal protection and includes all
        worldwide patents and other patent rights, utility models, trademarks,
        service marks, domain names, copyrights and mask work rights whether
        registered or unregistered, including right to apply for any of the
        foregoing before any Intellectual Property Office in any part of the
        world. Either Party agrees and undertakes that the (i) Intellectual
        Property; (ii) Data; (iii) identity and sensitive personal information
        of the Users; (iv) information pertaining to the Products sold to the
        User; (v) buying history of a User; (vi) software, documentation,
        hardware equipment devices, tools and (vii) any information concerning
        the organisation, finance, transactions, customers or affairs of either
        Party or its Affiliates (whether in graphic, written, electronic or
        machine readable form or oral or any other form and whether or not the
        information is expressly stated to be confidential or marked as such)
        (collectively the &quot;Confidential Information&quot;). Confidential
        Information shall include (i) customer, distribution, manufacturing,
        Seller or business data, in particular, names, addresses, sales figures
        and sales conditions of each Party and its present or prospective
        clients; (ii) software data, particularly information relating to
        software products and the modules thereof as well as any devices
        designed by each Party or its Affiliates to prevent unauthorized copying
        and use; (iii) research and development data, and particularly
        information relating to the Intellectual Property under this Agreement;
        (iv) original information supplied by each Party or its Affiliates; (v)
        information not known to each Parties’ competitors or its Affiliates’
        competitors nor intended by each Party or its Affiliates for general
        dissemination, including but not limited to, policies, strategies, the
        identity of various product and technology Sellers or service-providers,
        information about Groupick and its Affiliates and their executives,
        officers, directors and service providers; (vi) Each Party’s and its
        Affiliates’ product schematics, drawings, data, database, research and
        development, product and property plans, designs, protocols, prices,
        finances, marketing plans, business opportunities, personnel related
        information, sales and customer information, business policies,
        practices and strategies; (vii) any technical information relating to
        each Party and its Affiliates, including but not limited to financial
        information, equipment, documentation, strategies, marketing plans,
        prospective leads or target accounts, pricing information, information
        relating to existing, previous and potential customers and contracts
        disclosed by each Party or its Affiliates to the receipt of such
        information; (viii) information of third Parties which each Party or its
        Affiliates are obligated to keep confidential; (ix) all information that
        is disclosed to under this Agreement; and (x) any copies of the
        above-mentioned information Each Party’s Confidential Information shall
        be the exclusive property of the respective Party and its Affiliates and
        shall not be disclosed by recipient Party to any third Person or used
        for any purpose other than to perform the obligations under this
        Agreement, except as required under Applicable Law or with the prior
        written consent of the disclosing Party or its Affiliates. All
        Confidential Information shall only be used in a manner consistent with
        the intention and the spirit of the Agreement. In the event the
        ownership of any Confidential Information does not automatically vest in
        Groupick or its Affiliates by virtue of this Agreement, or otherwise,
        and vests in Seller instead, Seller hereby transfers and assigns to
        Groupick or its Affiliates, upon the creation thereof, all rights, title
        and interest Seller may have in and to such Confidential Information
        (and waives any and all moral rights, as applicable), including the
        right to sue and recover for past, present and future violations
        thereof. Seller shall take all reasonable steps, to protect Groupick’s
        Confidential Information and shall ensure the Seller, it’s employees,
        affiliates, representatives, agents and sub-contractors who have access
        to such Confidential Information shall treat the same with the
        obligations of confidentiality as stringent as those covered under this
        Agreement. The rights conveyed and assigned hereunder to Groupick may be
        exercised by Groupick at any point in time at its discretion worldwide
        including the territory of India. Seller agrees that, notwithstanding
        the provisions of Section 19(4) of the Indian Copyright Act, 1957, the
        assignment in terms of this clause shall not lapse nor shall the rights
        transferred therein revert to the Seller even if Groupick does not
        exercise the rights assigned and transferred to it, within a period of 1
        (one) year from the date of the assignment. The Seller agrees that it
        waives any right it may have and will not raise any objection or claims
        before the copyright board or any other appropriate authority with
        respect to the assignment, pursuant to Section 19A of the Indian
        Copyright Act, 1957. Seller acknowledge that the Confidential
        Information constitutes unique, valuable and special trade secret and
        business information of Groupick and its Affiliates, and that any
        disclosure thereof (otherwise than as provided for herein) may cause
        irreparable injury to Groupick. Accordingly, the Parties acknowledge and
        agree that as the breach or threatened breach of this clause by Seller
        would result in irreparable damage to Groupick or its Affiliates which
        cannot be adequately compensated by monetary relief alone, and that
        Groupick or its Affiliates shall be entitled to seek injunctive relief
        from an appropriate court of law. Upon Seller discovering a breach of
        the confidentiality obligations by its personnel, Seller shall
        immediately inform Groupick and its Affiliates of the same and take all
        steps necessary to mitigate such breach of confidentiality. The
        provisions of this clause 10 shall survive the termination of this
        Agreement.
      </p>
      <h4>DISCLAIMER</h4>
      <p>
        The Application is presented &quot;as is.&quot; neither Groupick nor its
        affiliates make any representations or warranties of any kind
        whatsoever, express or implied, in connection with these terms and
        conditions or the Application or any of the content, including but not
        limited to warranties of merchantability, non- infringement or fitness
        for a particular purpose, except to the extent such representations and
        warranties are not legally excludable.
        <br />
        Seller agrees that, to the fullest extent permitted by Applicable Law,
        neither Groupick not its Affiliates will be responsible or liable
        (whether in contract, tort (including negligence) or otherwise) under
        any circumstances for any (a) interruption of business; (b) access
        delays or access interruptions to the Applications; (c) data
        non-delivery, loss, theft, mis- delivery, corruption, destruction or
        other modification; (d) loss or damages of any sort incurred as a result
        of dealings with or the presence of off- website links on the site; (e)
        viruses, system failures or malfunctions which may occur in connection
        with the Sellers’ use of the site, including during hyperlink to or from
        third party websites; (f) any inaccuracies or omissions in content; or
        (g) events beyond the reasonable control of Groupick. Groupick makes no
        representations or warranties that defects or errors will be corrected.
        <br />
        References on the Application to any names, marks, products or services
        of third parties or hypertext links to third party sites or information
        are provided solely as a convenience to the Seller. This does not in any
        way constitute or imply Groupick endorsement, sponsorship or
        recommendation of the third party, information, product or service or
        any association and relationship between Groupick and those third
        parties. This disclaimer constitutes an essential part of this
        Agreement.
      </p>
      <h4>TERM, TERMINATION AND CONSEQUENCES OF TERMINATION</h4>
      <p>
        This Agreement shall continue in full force until the Seller uses the
        Application and avails the Services (the &quot;Term&quot;) unless
        terminated under Clauses 12.2 or 12.3. Groupick shall be entitled to
        terminate this Agreement: In accordance with Clauses 5.4, 6.3 and/or
        9.2, immediately without notice; By issuing a prior written notice of
        three (3) Business Days to Seller for terminating this Agreement without
        cause at any time during the Term. On breach by Seller of this
        Agreement, Seller Policies or other Policies of Groupick, as the case
        may be, (including any representation, warranty or covenant hereunder),
        which breach is not remedied by Seller within three (3) Business Days of
        receiving notice of such breach from Groupick; Immediately, without any
        further action or notice requirement (save for intimation to Seller of
        such termination), in the event that: Misconduct or negligence committed
        by Seller or its personnel; Violation of any Applicable Law; Upon
        reasonable believe that Seller has infringed intellectual property of
        any third party; or Seller being ordered to be wound up or declared
        insolvent for any reasons by any court and/or an official
        liquidator/receiver being appointed with respect to its affairs save and
        except mergers, amalgamations, acquisitions or other schemes or
        arrangements in which Seller may (directly or indirectly) be a part.
        <br />
        Seller is entitled to terminate this Agreement on the commission of any
        breach by Groupick of this Agreement which breach is not remedied by
        Groupick within 30 (thirty) Business Days of receiving notice of such
        breach from Seller.
        <br />
        Groupick shall, in its sole discretion, also have the right to take
        down/delist the Products, and/or temporarily suspend this Agreement
        and/or blacklist/deactivate the Seller in the event of any breach
        committed by the Seller as per the Seller Deactivation Policy as
        available on the Seller Panel.
        <br />
        The termination of this Agreement in any of the circumstances aforesaid
        shall not in any way affect or prejudice any right accrued to any Party
        against the others prior to such termination. Groupick shall forthwith
        pay Seller all undisputed amounts outstanding, in terms of this
        Agreement subject to Groupick&#39;s Service Fee/Commission being
        received from the Seller, as the case may be.
        <br />
        Seller shall, upon request by Groupick at any time, or upon the expiry
        or the earlier termination of this Agreement, (i) promptly return to
        Groupick, in the format and on the media in use as of the date of the
        request, all or a portion of the Data, as requested; and (ii) erase or
        destroy all of the Data remaining in its possession after the return set
        out above.
        <br />
        Parties shall promptly, on a written request made by each party or upon
        the termination of this Agreement either return any Confidential
        Information supplied by it and all copies or extracts thereof, or erase
        or destroy such Confidential Information within a period of seven (7)
        days of such request. Parties shall certify in writing to each other
        that it has complied with its obligations under this clause.
      </p>
      <h4>INDEMNITY</h4>
      <p>
        Without prejudice to any other right available to Groupick under
        Applicable Law or under this Agreement, Seller hereby defends,
        indemnifies and holds harmless Groupick and its directors, officers,
        agents, and assigns from and against any and all losses, liabilities,
        damages, deficiencies, demands, claims (including third Person claims),
        actions, judgments or causes of action, assessments, interests, fines,
        penalties, diminution in value and other costs or expenses (including,
        without limitation, amounts paid in settlement, court costs and all
        attorneys’ fees and out of pocket expenses) directly or indirectly based
        upon, resulting from, or arising out of, or in relation to or otherwise
        in respect of: any failure by Seller or its Affiliates or its personnel
        to perform or otherwise fulfil any covenant, undertaking or other
        agreement or obligation contained in this Agreement; any breach of terms
        of this Agreement including Policies, Seller Policies, representations,
        warranties, covenants by the Seller or its Affiliates or its personnel;
        any liability due to any non-compliance or violation of any Applicable
        Law by the Seller during the Term; gross misconduct, wilful negligence
        or fraud committed by Seller or its Affiliates; any act or omission by
        the Seller resulting in any third-party claims to Groupick; any loss,
        misappropriations, misuse, infringement or damage to the Confidential
        Information which are in its possession or its personnel or any other
        persons engaged by Seller or within the control its control; any losses
        including loss of input tax credit, claims, demands, liabilities, suits,
        proceedings, penalties, costs or expenses of any kind (including,
        attorneys&#39; fees and expenses) on account of violation of applicable
        tax laws by the Seller (including but not limited to non-filing of the
        requisite forms with the tax authorities to claim tax credit etc.);
        fines, or punitive damages resulting from supervisory actions against
        Seller and caused by Seller, as well as private settlements due to
        omissions and commissions by Seller; any liability arising from a claim
        from a User, resulting from a deficiency in any Product sold by Seller;
        or any third party infringement action initiated against Groupick as a
        consequence of using intellectual property as provided by Seller to
        Groupick under this Agreement or its breach of such third party right.
        Any compensation or indemnity as referred to in Clause 13.1 above shall
        be such as to place Groupick in the same position as it would have been
        in, had there not been any failure to perform or liability, or breach of
        any representation and warranty. The indemnification obligations
        hereunder shall survive termination or expiration of this Agreement.
      </p>
      <h4>FORCE MAJEURE</h4>
      <p>
        If the performance of Groupick’s obligations hereunder is prevented,
        restricted or interfered with by reason of any epidemic, pandemic or
        fire, or other casualty or accident; strike or labour disputes; war or
        other violence; or any act or condition beyond the reasonable control of
        Groupick (each a &quot;Force Majeure Event&quot;), then Groupick shall
        be excused from such performance to the extent of such prevention,
        restriction or interference; provided, however, that Groupick shall
        endeavour to give prompt notice within a period of 10 (ten) Business
        Days from the date of occurrence of the Force Majeure Event and
        providing a description to Seller of such Force Majeure Event in such
        notice, including a description, in reasonable specificity, of the cause
        of the Force Majeure Event and the likely duration of the impact or
        delay cause by the Force Majeure Event; and provided further that Seller
        shall use reasonable efforts to avoid or remove such cause of
        non-performance and shall continue performance hereunder whenever such
        causes are removed. If Groupick’s performance of its obligations under
        this Agreement is suspended due to the occurrence of a Force Majeure
        Event for a period in excess of 30 (thirty) Business Days, Groupick may
        terminate this Agreement without incurring any charges.
      </p>
      <h4>NOTICES</h4>
      <p>
        Seller consent to receive notices through any mode including SMS,
        e-mail, phone calls etc .All notices, requests, demands, claims and
        other communications hereunder shall be in writing. Any notice, request,
        demand, claim or other communication hereunder shall be deemed duly
        given if sent electronically, by registered or certified mail, return
        receipt requested or postage prepaid and addressed to the intended
        recipient as set forth below:
      </p>
      <p>
        <strong>In the case of notices to Groupick:</strong>
      </p>
      <p>
        Address:
        <br />
        Getzie Technology Private Limited
        <br />
        Sairaj Complex, A/204, Sanpada Sector 01,
        <br />
        Navi Mumbai, Maharashtra 400705
      </p>
      <p>
        <strong>In the case of notices to the Seller:</strong> To such address
        as provided for registration process.
        <br />
        Either Party may, from time to time, change its address or
        representative for receipt of notices provided for in this Agreement by
        giving to the other Party not less than 10 (ten) days’ prior written
        notice.
      </p>
      <h4>RESTRICTIVE COVENANT</h4>
      <p>
        Seller shall not at any time during the Term, and for a period of 6
        (six) months from the termination or expiry of this Agreement, either on
        Groupick’s account or in connection with or on behalf of any other
        Person, firm or company in competition with Groupick, solicit, hire,
        canvass or engage any resellers or employee of Groupick or its
        Affiliates.
      </p>
      <h4>LIMITATION OF LIABILITY</h4>
      <p>
        Notwithstanding anything contained in the provisions of this Agreement,
        Groupick shall not be liable to the other Party for any punitive,
        exemplary, consequential, incidental, indirect or special damages.
        Groupick expressly exclude their liability for any indirect, incidental,
        special, punitive, exemplary or consequential damages including (but not
        limited to) loss of anticipated profit or savings, loss of business,
        loss of revenue, depletion of goodwill, loss or corruption of data
        and/or similar losses, even if the Seller has been advised of the
        possibility of such loss or damages. The total liability of Groupick
        hereunder for any single event or a series of events constituting a
        breach of the Agreement or any default hereunder shall not exceed, under
        any circumstances, 100% (one hundred per cent) of the Commission and
        Services Fees paid or payable to Groupick over the immediately preceding
        3 (three) month period from the date of claim. Seller agrees that no
        claims or action arising out of, or related to, the use of the
        Application or these terms and conditions may be brought by the Seller
        more than 6 (six) months year after the cause of action relating to such
        claim or action arose. Seller agrees that in the event that the Suppler
        has a dispute or is dissatisfied with the Application, termination of
        the use of the Application is the Sellers’ sole remedy. Groupick has no
        other obligation, liability, or responsibility to the Seller.
      </p>
      <h4>GOVERNING LAW AND DISPUTE RESOLUTION</h4>
      <p>
        This Agreement shall be governed by the laws of India and the courts of
        Mumbai shall have exclusive jurisdiction to try all disputes between the
        Parties pursuant to this Agreement. Further, each Party shall comply
        with all applicable state or local laws, regulations, or ordinances in
        effect or hereafter governing the terms of this Agreement. In case of
        any dispute, the Parties shall initially try to find an amicable
        solution. If the Parties are unable to agree on an amicable solution
        within 15 (fifteen) Business Days of receipt by one Party of written
        notice from the other Party, then such disputes arising out of or in
        connection with this Agreement shall be settled exclusively and finally
        through arbitration process by the sole arbitrator as mutually appointed
        by the Parties. The arbitration shall be held in accordance with the
        provisions of the Arbitration and Conciliation Act, 1996 and subsequent
        amendments thereof, language of the arbitration shall be English, and
        the seat and venue of the Arbitration will be Bangalore. The arbitration
        award delivered by the tribunal shall be final and binding on both
        Parties. Each Party shall bear its own costs relating to such
        arbitration, and the Parties shall equally share the arbitrator’s fees.
        The award rendered may be entered and enforced in any court having
        jurisdiction at Bangalore. This arbitration clause shall be severable
        and may be enforced independently.
      </p>
      <h4>MISCELLANEOUS</h4>
      <p>
        All the information on the Application is published in good faith.
        Groupick does not make any warranties about the completeness,
        reliability and accuracy of this information. Any action Seller take
        relying upon the information Seller find on the Application, is strictly
        at its own risk. Groupick will not be liable for any losses and/or
        damages in connection with the use of our Application.
        <br />
        <strong>Audit Rights</strong>
        Seller shall keep books, records, and accounts with enough detail and
        precision as to clearly reflect its transactions and the use or
        disposition of its resources or assets. Seller agrees that Groupick has
        the rights to audit the transactions related to its execution of its
        obligations under this agreement at any time and upon reasonable notice.
        If Seller or any of its Affiliates elect to provide or make available
        suggestions, comments, ideas, improvements, or other feedback or
        materials to us in connection with or related to the Application or
        Services (including any related technology) or any testimonials,
        reviews, content, video clip, interviews, audio clips or any other media
        information used for promotion, marketing or advertisement of Groupick,
        it’s brand name, Application or Services, Seller will, to the extent
        necessary and authorized by law, irrevocably grant to Groupick, a
        royalty-free, perpetual, exclusive and worldwide license on all right,
        title, and interest in and to the suggestions and such content developed
        by the Seller or its Affiliates, for the duration of protection of the
        underlying rights. Groupick shall unequivocally own and use such content
        over any platform or channels of communication as it may deem fit and
        that Seller consents to use of such content by Groupick accordingly.
        <br />
        <strong>Audit Rights</strong>
        Independent Parties This Agreement is entered on a
        principal-to-principal basis. The Parties are independent to each other
        and nothing contained herein shall be deemed to construe either Party to
        be the employee, agent, servant, partner, joint venture partner,
        subsidiary, Affiliate or group company of the other Party. Seller
        acknowledges and agrees that Groupick is an independent contractor for
        all purposes and does not have control of or liability for the Products
        that are listed on the Application and paid for by using the payment
        facility. Groupick does not guarantee the identity of any other User(s)
        nor does it ensure that any User will complete a transaction. Assignment
        Neither Party shall in any manner whatsoever transfer or otherwise
        assign this Agreement or any of its rights or obligations hereunder
        without the prior written consent of the other Party, which consent
        shall not be unreasonably withheld. Notwithstanding anything contained
        herein, Groupick may assign this Agreement to any of its group
        companies, Affiliates or subsidiaries, without any prior written consent
        of the Seller. Modification Groupick may change or modify the Agreement,
        Seller Policies or the Policies in the Seller Panel at any time with
        immediate effect for any reason that Groupick deems fit and appropriate.
        Groupick will reasonably endeavour, at its sole discretion to notify
        Seller about any change or modification by sending an email on the
        registered email id of the Seller and/or displaying any such change on
        the Seller’s notice board in the Seller Panel. Seller’s continued use of
        the Services after the effective date of any such change in accordance
        with this clause will constitute its acceptance of that change. If any
        change is unacceptable to Seller, Seller agrees not to use the Services
        and to terminate the Agreement as described in Clause 12. Entire
        Agreement This Agreement along with the Schedule, Seller Policies and
        Policies shall constitute the entire Agreement and understanding of the
        Parties with respect to its subject matter and shall supersede all prior
        representations, promises, communications, understanding or agreements,
        both written and oral, with respect to such subject matter. Survival Any
        provision of the Agreement that contemplates performance or observance
        subsequent to termination or expiration of the Agreement will survive
        termination or expiration of the Agreement and continue in full force
        and effect, including without limitation Clauses 5 (Data), 6
        (Anti-Corruption And Anti-Bribery, Anti Money Laundering And
        Whistleblower Mechanism), 7 (Compliance with Applicable Law), 8 (Seller
        Representations and Warranties), 9 (Seller Covenants and Undertakings),
        10 (Intellectual Property and Confidentiality), 11 (Disclaimer), 12.6,
        12.7, 13 (Indemnity), 15 (Notices), 16 (Restrictive Covenants), 17
        (Limitation of Liability), 18 (Governing Law and Dispute Resolution), 19
        (Miscellaneous) Waiver No failure or delay by any Party hereto in
        exercising any right, power or remedy under this Agreement shall operate
        as a waiver thereof or a waiver of any other rights, powers or remedies,
        nor shall any single or partial exercise of any such right, power or
        remedy preclude any other or further exercise of any such right, power,
        or remedy or the exercise of any other right, power or remedy; no waiver
        by either Party shall be effective unless it is given in writing by a
        duly authorised representative of such Party. Severability If any
        provision of this Agreement is determined to be invalid or unenforceable
        in whole or in part, such invalidity or unenforceability shall attach
        only to such provision or part of such provision and the remaining part
        of such provision, and all other provisions of this Agreement shall
        continue to remain in full force and effect.
      </p>
      <h4>SCHEDULE 1 SERVICES</h4>
      <p>
        <strong>
          Seller agrees to receive the following Services subject to payment of
          Service Fee, Seller Fee, and other charges as and when finalized by
          Groupick towards provision of these Services, either by Groupick or
          through a third party, as applicable:
        </strong>
      </p>

      <ol>
        <li>
          Listing services for listing of the Products on the Application.
        </li>
        <li>
          Groupick shall provide Seller with access to a Seller Panel to enable
          Seller to add new products, update their description, HSN code, GST
          rate, inventory, fulfill orders, check for payments, view sales
          reports, etc.
        </li>
        <li>Marketing services to Seller for its Products.</li>
        <li>Payment collection services to Seller.</li>
        <li>
          Logistics and/or warehousing/fulfillment center services to Seller for
          its Products.
        </li>
        <li>Account management services.</li>
        <li>
          Any other service as may be agreed by the Parties from time to time.
        </li>
      </ol>

      <p>
        <strong>SCHEDULE 2</strong>
      </p>

      <p>
        <strong>ACCOUNT MANAGEMENT SERVICES</strong>
      </p>

      <p>
        <strong>
          1. Groupick shall (directly or through third party service providers
          engaged by Groupick) provide account managers to perform the following
          services for the Seller:
        </strong>
      </p>

      <p>
        <strong>1. Account Analysis</strong>
      </p>

      <ul>
        <li>
          The Account Manager shall analyze the accounts as per status and will
          notify the Seller regarding product potential and actions required
          from the seller.
        </li>
        <li>
          The Account Manager shall review current account status such as POA,
          product block ticket, SLA breaches, etc.
        </li>
      </ul>

      <p>
        <strong>2. Listing Optimization</strong>
      </p>

      <ul>
        <li>
          The Account Manager shall optimize listing content and add relevant
          content in order to increase the visibility of the products.
        </li>
        <li>
          The Account Manager shall create up to 100 new catalogs every month in
          the service period for Sellers.
        </li>
        <li>
          The Seller shall provide documents relating to Brand Approval and
          Category Approval.
        </li>
        <li>
          The Seller will provide required details such as dimensions,
          materials, fabrics, and other special features and specifications.
        </li>
        <li>
          The Seller will provide images as per guidelines for product listing
          and confirm the prices.
        </li>
      </ul>

      <p>
        <strong>3. Advertisement</strong>
      </p>

      <ul>
        <li>
          The Account Manager shall create up to 50 new campaigns or 50 SKU per
          one month on behalf of the Sellers with the approval of the Sellers.
        </li>
        <li>
          Optimization - Optimized Budget and Bidding as per Product selection.
        </li>
        <li>
          Reporting - Reporting weekly to sellers about performances and ROI.
        </li>
        <li>The Account Manager shall recommend catalogues and budgets.</li>
        <li>
          The Account Manager shall recommend cost per click bids in order to
          optimize ROI for all campaigns.
        </li>
      </ul>

      <p>
        <strong>
          4. Account Health Management - Monitoring on different parts of the
          account like SLA Breaches, policies violations
        </strong>
      </p>

      <ul>
        <li>
          The Account Manager shall monitor customer returns, RTO, and other
          quality issues.
        </li>
        <li>
          The Account Manager shall be responsible for Inventory Management to
          prevent fulfillment issues.
        </li>
        <li>
          The Account Manager shall generate SX tickets to resolve concerns
          related to account health.
        </li>
        <li>
          The Account Manager shall take necessary action and case
          representation for account quality management.
        </li>
      </ul>

      <p>
        <strong>5. Recommendation</strong>
      </p>

      <ul>
        <li>
          The Account Manager shall recommend prices and share the same with
          Seller and will be implemented with the approval of the Seller.
        </li>
        <li>
          The Account Manager shall recommend products as suggested by the
          Company.
        </li>
      </ul>

      <p>
        <strong>6. Reporting</strong>
      </p>

      <ul>
        <li>
          The Account Manager shall email and call and update the Seller about
          the work.
        </li>
        <li>
          The Account Manager shall call the Seller three (3) times a week.
        </li>
        <li>The Sellers may call the Account Manager at any time.</li>
        <li>The Account Manager shall send monthly plans.</li>
        <li>
          The Account Manager shall share weekly/monthly business reports.
        </li>
      </ul>

      <p>
        <strong>7. Miscellaneous</strong>
      </p>

      <ul>
        <li>
          The Account Manager shall take approval from the Seller before
          performing any action on the Seller account, and Seller shall be
          responsible for providing approval in a timely fashion.
        </li>
        <li>
          The Seller shall not share their login email and password to Account
          Manager under any circumstance and agrees that Groupick shall not be
          liable for any misconduct resulting from such unauthorized sharing of
          login details.
        </li>
      </ul>
    </Container>
  );
};

export default SellerAgreement;
