import jsonDta from "./assetlinks.json";

function Assetlinks() {
  const jsonString = JSON.stringify(jsonDta, null, 2);

  return (
    <>
      <pre>{jsonString}</pre>
    </>
  );
}

export default Assetlinks;
