import {Modal, ModalBody, Row, Col} from "react-bootstrap";
import React from "react";
import {FiCopy} from "react-icons/fi";
import {IoMdClose} from "react-icons/io";

const AllImagesModal = ({show, onHide, allImages}) => {
  const reversed = allImages.reverse();
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <div className="p-3">
        <IoMdClose size={20} onClick={onHide} className="cursor-pointer" />

        <h3 className="mb-0 text-center">Copy Images Link</h3>
      </div>
      <ModalBody className="px-2 px-lg-5">
        {reversed.map((img) => (
          <div className="px-2 border border-3 rounded">
            <div className="d-flex">
              <div className="border-end border-3 p-3">
                <img
                  src={img.url}
                  alt=""
                  width="60"
                  height="65"
                  style={{objectFit: "cover"}}
                />
              </div>
              <div className="d-flex justify-content-center align-items-center  flex-grow-1">
                <h5 className="mb-0 text-center">
                  {img.name.substring(0, 25)}
                  {img.name.length > 25 && "....."}
                </h5>
              </div>
              <div className="d-flex justify-content-center align-items-center p-3 border-start border-3">
                <button
                  className="btn"
                  onClick={() => navigator.clipboard.writeText(img.name)}
                >
                  <FiCopy size={18} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default AllImagesModal;
