import styled from "styled-components";

const GalleryStyled = styled.div`
  position: relative;
  .gallery__newBtn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px 16px;
  }
  .gallery__uploadSection {
    position: relative;
  }
  .gallery__clearIcon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-50%, 25%);
    cursor: pointer;
    animation-duration: 2s;
  }
  .gallery__images {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 50% 50%;
    @media (max-width: 700px) {
      grid-template-columns: 100%;
    }
  }
`;

export { GalleryStyled };
