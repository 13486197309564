import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { GenericButton } from "../../compoents/Button";
import { IMAGES, PATH } from "../../config";
const StyledAuthHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  // border: 1px solid #707070;
  /* position: fixed;
  top: 0px; */
`;

const StyledLandingPage = styled.div`

.heading-4{
color:#122736;
font-size:33px;
line-height:44px;
}

.heading-3{
    color:#676767;
    font-size:30px;
    line-height:50px
    font-weight:400;
}
.thumbnail-img{
    height:100%;
    width:100%;
}
.thumbnail-text-container{
    padding: 30px;
    background-color: #00000029;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.why-use-tittle{
    max-width: 100%;
    width: 440px;
    text-align: left !important;
}

.why-us-desc{
    width:440px;
    max-width: 100%;
    font-size:20px;
    font-weight:400;
    word-spacing:0.42px;
    color:#3D3D3D;
}

.footer{
    .text-grey{
        color:#818181 !important;
    }
    /* padding */
    .below-logo-desc{
        font-size:18px;
    }
    .list-style-none{
        list-style:none;
    }
    
}

.copy-right-footer{
    color:#252525;
}
.border-top-1{
        border-top: 1px solid black;
        font-size:20px;
    }
`;

const FooterBgSection = styled.div`
  background-image: url(${IMAGES.FOOTER_BG});
  min-height: 600px;

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const FooterBgWithColor = styled.div`
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0.6979166666666667) 0%,
    rgba(255, 255, 255, 0.29175420168067223) 0%
  );
  /* opacity: 0.73; */
  min-height: 600px;
  height: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  .hero-text-tittle {
    font-size: 62px;
    margin: auto;
    max-width: 800px;
    width: 100%;
  }
  .hero-text-sub-tittle {
    font-size: 32px;
    max-width: 800px;
    width: 100%;
    margin: 30px auto;
  }
`;

const Refund = () => {
  let history = useHistory();
  const goToLoginScreen = () => {
    history.push(PATH.LOGIN);
  };

  return (
    <StyledLandingPage>
      <StyledAuthHeader className="container-fluid px-md-5 px-3 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link to={PATH.LANDING_PAGE}>
              <img
                style={{ width: "70px" }}
                src={IMAGES.COMPANY_LOGO}
                alt="fund-raiser-logo"
              />
            </Link>
          </div>
          <div>
            <GenericButton
              onClick={goToLoginScreen}
              variant="none"
              className={"py-2 px-5"}
            >
              Login
            </GenericButton>
          </div>
        </div>
      </StyledAuthHeader>

      <div className="container py-4">
        <h2>Returns, Refunds and Exchange Policy</h2>
        <p>
          Returns, Refunds and Exchange is the scheme provided by various
          sellers listed on ‘Groupick’s’ website available at www.Groupick.in or
          the mobile application under the brand name “Groupick” (collectively
          “Platform”), in relation to specific products. Returns, refund and
          exchange policy gives you an option to return, or exchange items
          purchased on the Platform, for any reason within the specified
          return/exchange period, as detailed on the product details page.
          However, the return/exchange shall be eligible for products that are
          in good condition, as may be determined by Groupick.
        </p>
        <h3>What can I return?</h3>
        <p>
          You may request returns for most items you buy from sellers and
          Sellers listed on the Platform that are within the return window.
          However, products that are explicitly identified as ‘not returnable’
          on the product detail page cannot be returned. Products which are
          identified as ‘returnable’ are eligible for return with the conditions
          as specified in the product description page and this policy. All
          returns are subject to the details provided in the product description
          page and other Sections of this policy. Products that are not eligible
          for return on the Platform, may be exchangeable for reasons mentioned
          on the product description page on the Platform.
        </p>
        <p>
          However, if you receive a damaged/defective/wrong product from any
          seller listed on the Platform, you will still be allowed to exchange
          the product for reasons as provided in the product description page.
        </p>
        <p>
          In case you have purchased a product with which a free product is
          delivered, and you raise a request for return the main product, you
          will have to return the free product as well. Further, a product which
          forms a part of a package of other products, you must return all
          products that form part of the package to process the refund.
        </p>
        <p>
          In circumstances where you return an extra or a different product,
          Groupick will not be accountable for misplacement or replacement of
          such product and will not be responsible for its delivery back to you.
        </p>
        <h3>What are the return options?</h3>
        <p>
          Certain products on Groupick will have two return options available:
          (a) Wrong/Defect item return option, and (b) all return option. If a
          product does not have the above-mentioned options for return, the
          return option available for the Product in the product description
          page will be applicable. All return options are subject to the product
          being identified as returnable in the product description page.
        </p>
        <h4>
          What is the Wrong/Defect item return option and all return options?
        </h4>
        <p>
          Groupick provides an ‘all return option’ to its users, wherein users
          will be able to enjoy certain privileges on a specific product for
          payment of a premium on the product cost. Features of an all return
          option are as follows:
        </p>
        <ul>
          <li>
            Subject to seller’s return/exchange policy provided on the product
            description, the user will be allowed to return/refund an already
            purchased product, if the said product is eligible for
            return/exchange.
          </li>
          <li>
            The user will be eligible to return the specific product for reasons
            other than the product being wrong or damaged.
          </li>
          <li>
            The all return option is available only for specific products.
          </li>
        </ul>
        <p>
          For users not availing an all return option, shall be eligible for a
          ‘Wrong/Defect item return option’.
        </p>
        <ul>
          <li>
            Subject to seller’s return/exchange policy, the user will be allowed
            to return/refund an already purchased product, if the said product
            is eligible for return/exchange.
          </li>
          <li>
            The user will be eligible for return/refund of the product if the
            product received is defective, damaged, wrong, or incomplete due to
            seller’s fault or if the user has received the wrong product.
          </li>
        </ul>
        <h3>Will my return be free of cost?</h3>
        <p>
          If you have opted for an all return option, you may return the product
          free of cost no matter what the reason.
        </p>
        <p>
          If you have opted for the Wrong/Defect return option, you may return
          the product free of cost if the fault with the product lies with the
          Seller. The following are the faults which lie with the Seller:
        </p>
        <table>
          <thead>
            <tr className="border">
              <th>Return Reason Category</th>
              <th>Actual Return Reason</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Wrong Product</td>
              <td>Wrong Size Delivered</td>
            </tr>
            <tr>
              <td></td>
              <td>Same product in different color</td>
            </tr>
            <tr>
              <td></td>
              <td>Completely different product from the product shown</td>
            </tr>
            <tr>
              <td>Defective Product</td>
              <td>Product is dirty or with stains</td>
            </tr>
            <tr>
              <td></td>
              <td>Product is broken</td>
            </tr>
            <tr>
              <td></td>
              <td>Product is torn</td>
            </tr>
            <tr>
              <td>Received incomplete product</td>
              <td>Part of the product is missing</td>
            </tr>
            <tr>
              <td></td>
              <td>Less quantity than ordered</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          The following faults will need to be verified before arranging return
          free of cost:
        </p>
        <ul>
          <li>The quality is not merchantable; or</li>
          <li>Product has low performance; and</li>
          <li>Late Delivery</li>
        </ul>
        <h4>How to return items?</h4>
        <p>
          You can return the products purchased on the Platform within the
          specified return/exchange period provided on the product description
          page. To return a product, please follow the steps mentioned below:
        </p>
        <ul className="list-unstyled">
          <li>
            a. If a product is eligible for return, the user will be able to
            initiate the return request under the ‘My Orders’ section.{" "}
          </li>
          <li>
            b. Create a ‘return request’ under the ‘My Orders’ section on the
            Platform and follow the instructions provided on the Platform.
          </li>
          <li>
            c. After the ‘return request’ has been raised, Return ID will be
            generated by Groupick.
          </li>
          <li>
            d. In case you purchase multiple products in a single order, return
            requests for individual items can also be raised.
          </li>
          <li>
            e. Once a return request is raised, Groupick shall analyse the
            request and accordingly process the request internally. All return /
            exchange claims shall be subject to Groupick’s discretion.{" "}
          </li>
          <li>
            f. If you have scheduled a pick – up for returning the product,
            please keep the items ready to be picked up by the delivery
            partners.{" "}
          </li>
          <li>
            g. Please keep the product in the original manufacturer’s packaging,
            all tags should be intact and warranty card, if any, should also be
            placed with the product.
          </li>
          <li>
            h. At the time of pick up, our delivery partner may conduct a
            quality check on the product to ensure the product meets the
            criteria specified under return guidelines. If the product fails
            such a quality check, the product shall not be returned, and no
            refund shall be made against such product.
          </li>
          <li>
            i. Please note that the product will be picked up from the same
            address where delivery was made. In case pick up is to be made from
            a different address, the same should be notified to Groupick by
            writing to legalsupport@Groupick.in. Such request may be accepted at
            Groupick’s sole discretion and subject to the new address being in
            serviceable area.{" "}
          </li>
          <li>
            j. Further, on receipt of returned product, the product shall
            undergo a quality check. If the product returned in an acceptable
            condition, as determined by Groupick at its sole discretion, the
            refund shall be initiated, otherwise the product will be re-shipped
            to you, at your cost, and no refund/exchange shall be initiated.{" "}
          </li>
        </ul>
        <h4>Can my order be exchanged?</h4>
        <p>
          All products which are returnable are eligible to be exchanged,
          subject to the return options mentioned hereinabove. However, there
          may be certain products which are not eligible for return but are
          eligible for exchange. Further, exchange is subject to stock
          availability and subject to your address being serviceable for
          exchange. In the event that a product for which only exchange is
          available as per the product description page, the user can opt for a
          ‘call me back’ option and the returns will be enabled for that user
          once it is found that the return has been initiated for genuine
          reasons on the sole discretion of Groupick.
        </p>
        <p>
          The exchange request must be raised within the return window period
          specified on the product detail page.
        </p>
        <p>
          In case of exchange, if the cost of the new product is more than the
          original product delivered, you will have to pay the difference amount
          and if the cost of the new product is less than the original product
          delivered, the difference amount will be refunded.
        </p>
        <p>
          Further, once a product has been exchanged, there cannot be any more
          exchanges on the same order.
        </p>
        <h4>How to exchange items?</h4>
        <p>
          You can exchange the products purchased on the Platform within the
          specified exchange period, unless the product description page
          specifies that the product is not exchangeable. It is hereby clarified
          that exchange in this policy means exchange with the same product and
          not with a different product. To exchange a product, please follow the
          steps mentioned below:
        </p>
        <ol>
          <li>
            If a product is eligible for exchange, the option shall be available
            against the product under ‘My Orders’ section.
          </li>
          <li>
            Create a ‘exchange request’ under the ‘My Orders’ section on the
            Platform and follow the screens that are prompted.
          </li>
          <li>
            In case you purchase multiple products in a single order, exchange
            requests for individual items can also be raised.
          </li>
          <li>
            After an exchange request has been raised, Exchange ID will be
            generated.
          </li>
          <li>
            Keep the items ready for exchange pick-up. Please keep the product
            in the original manufacturer’s packaging, all tags should be intact,
            and warranty card, if any, should also be placed with the product.
          </li>
          <li>
            Once the original product is handed over to the delivery agent, the
            exchanged product will be delivered to you separately.
          </li>
          <li>
            At the time of pick-up, the delivery agent may conduct a quality
            check on the product to ensure the product meets the criteria
            specified under return guidelines. If the product fails such a
            quality check, the product shall not be exchanged, and no refund
            shall be made against such product.
          </li>
          <li>
            The product will be picked up from the same address where delivery
            was made. In case pick-up is to be made from a different address,
            the same should be notified to Groupick by writing to
            legalsupport@Groupick.in. Such request may be accepted or rejected
            at Groupick’s sole discretion and subject to the new address being
            in a serviceable area.
          </li>
          <li>
            Further, on receipt of the returned product, the product shall
            undergo a quality check. If the product is in an acceptable
            condition, as determined by Groupick at its sole discretion, the
            refund shall be initiated, otherwise, the product will be re-shipped
            to you, at your cost, and no refund shall be initiated.
          </li>
        </ol>
        <h4>What are the return and exchange guidelines?</h4>
        <p>
          Prior to creating a return or exchange request on the platform, you
          shall ensure that, the product which is being return either for
          returning the product or for exchanging the product:
        </p>
        <ul>
          <li>
            Must be in its original condition with all the packaging including
            brand/manufacturer’s box/packaging, tags, warranty cards, and other
            accessories intact;
          </li>
          <li>Must not be damaged in your possession;</li>
          <li>
            Must be unused, unwashed, unsoiled, without any stains, and with
            non-tampered quality check seals/return tags/warranty seals
            (wherever applicable);
          </li>
          <li>Must not be used or altered;</li>
          <li>Must be the same product that was delivered to you;</li>
          <li>
            Must be returned within the return window specified against a
            product on the product detail page.
          </li>
        </ul>
        <p>
          Further, IMEI/ name/ image/ brand/ serial number/ article number/ bar
          code of the returned product should match the records of Groupick. Any
          additional conditions provided in the product details against each
          product shall be applicable as well. Further:
        </p>
        <ul>
          <li>
            Your address and the item that you wish to return must be eligible
            for return.
          </li>
          <li>
            If the return is not eligible for pickup, a return option will not
            be available.
          </li>
          <li>
            Once the return is received, you will be provided with a refund or
            exchange, as requested.
          </li>
          <li>
            If you intend to return an electronic device that stores any
            personal information, you must remove/delete all such personal
            information from the device prior to returning. Groupick shall not
            be liable in any manner whatsoever for any use of such information.
            Such electronic device should be formatted, and screen lock should
            be disabled.
          </li>
          <li>
            No return or exchange shall be accepted if the warranty card, if
            any, is missing while returning or exchanging the product.
          </li>
        </ul>
        <p>
          The products available on the Platform are usually returnable within 7
          days of delivery. However, specific details in relation to each
          products are mentioned in the product description. Further, a product
          which has been returned/exchanged cannot be returned/exchanged again.
        </p>
        <h4>How will I get my refund?</h4>
        <ul>
          <li>
            Refund will be credited to the same source of payment from which
            payment was received, after deducting the cost of return (where
            applicable), once the return is received.
          </li>
          <li>
            If you desire to return a product, then you shall be entitled to
            receive only the amount actually paid by you for the purchase of the
            product.
          </li>
          <li>
            In the event any product is returned, then any offer, promotion,
            discount applied to such product shall be forfeited.
          </li>
          <li>
            If you have made the payment through your bank account, the refund
            will be credited to the same bank account from which payment was
            received after deducting the cost of return (where applicable), once
            the return is received.
          </li>
          <li>
            If you have made the payment as cash on delivery, you will be asked
            to provide us with your bank account details and the refund amount
            will be credited to the bank account details provided by you.
            Groupick shall not be liable to you in case incorrect bank account
            details have been provided by you.
          </li>
          <li>
            In case of any discrepancies regarding the receipt of the refund
            amount, Groupick may request additional information such as a bank
            statement or any other relevant document.
          </li>
        </ul>
        <h4>When will I get my refund?</h4>
        <p>
          Following are the processing timelines after the product is received
          by us or when the seller notifies us of the receipt of the products.
        </p>
        <table
          style={{ borderCollapse: "collapse", border: "1px solid black" }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid black" }}>Refund Method</th>
              <th style={{ border: "1px solid black" }}>Refund Time Frame</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black" }}>
                Credit Card, Debit Card, Net Banking, UPI Linked Bank Account
              </td>
              <td style={{ border: "1px solid black" }}>3-7 Business Days</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>
                Cash on Delivery (Refund to Bank Account in the event that the
                conditions for Instant Refund mentioned below are not met)
              </td>
              <td style={{ border: "1px solid black" }}>
                3-7 Business Days after updating the bank account details
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>Groupick Balance</td>
              <td style={{ border: "1px solid black" }}>3-7 Business Days</td>
            </tr>
          </tbody>
        </table>
        <h4>Instant Refund:</h4>
        <p>
          Groupick offers instant refund whereby the refund will get within 4
          (four) hours of the product being picked up and marked by the delivery
          partners as successfully picked up (“Instant Refund”). The refund will
          be reflected to the bank account owithin 3 -7 Bsuiness Days. The
          following are the conditions which are required to be met for the
          return to be eligible for an Instant Refund:
        </p>
        <ul>
          <li>
            Instant Refunds are only available for cash on delivery orders.
          </li>
          <li>
            The product which is being returned should have successfully
            undergone a quality check by the delivery partners and should have
            been marked as successfully picked up for return on the systems of
            Groupick. Groupick reserves the right to not accept the return in
            the event the product is damaged, incorrect or for such other
            reasons as Groupick deems fit.
          </li>
          <li>
            In the event where the delivery partner is unable to mark the
            product as successfully picked up in the systems of Groupick, as a
            result of technical issues, Groupick shall not be liable to process
            the Instant Refund.
          </li>
          <li>
            Further, Groupick shall not be held liable for not processing the
            Instant Refund for reasons attributable to the delivery partner,
            which include instances as, product being marked canceled instead of
            picked up, tracking details not available for the product picked up,
            etc. In such instances, Groupick may not be able to process the
            Instant Refund.
          </li>
          <li>
            Instant Refunds will not be processed in case of instances whereby
            the systems of Groupick cannot generate a refund without manual
            intervention, i.e., it is not a system generated refund. Manual
            intervention is typically required in the following instances:
          </li>
          <ul>
            <li>
              In the event the refund request is raised for a missing product or
              a product that was not delivered to the customer.
            </li>
            <li>
              In the event that the delivery partner is unable to update
              accurate details in the systems of Groupick regarding the product
              that has been picked up including tracking details for the
              product.
            </li>
            <li>
              In the event the customer has opted for self-shipment, for any
              reason, and not routed the return via the delivery agents assigned
              by Groupick. In the event that the customer has mistakenly
              returned additional products along with the products for which the
              return was initiated.
            </li>
          </ul>
          <li>
            Instant Refunds are not available for customers who have purchased
            products from Resellers on the Groupick platform. Groupick may not
            be able to process Instant Refunds in the event of unavailability of
            services due to any reason including technical issues and downtime,
            which may include: (a) downtime in the bank servers which will
            hamper Groupick’s ability to process the refunds, (b) downtime in
            the Groupick platform or communication channel which disrupts the
            ability of the delivery partner to update the status of the pick up,
            or (c) other technical reasons which disrupt the process of return
            and refunds, as may be applicable.
          </li>
          <li>
            Instant Refunds will not be processed if the bank details or UPI ID
            is not provided or is inaccurate.
          </li>
          <li>
            Groupick reserves the right in its sole discretion to refuse Instant
            Refund for reasons including but not limited to: (i) any fraud or
            malpractice committed by the user and detected by Groupick at any
            point of time, (ii) for any other reason that Groupick may deem fit.
          </li>
        </ul>
        <h4>Miscellaneous</h4>
        <p>
          Please note that all the customer claims and requests for
          return/refund shall be subject to Groupick’s satisfaction.{" "}
        </p>
        <p>
          Groupick reserves the right, at its sole discretion, to amend, change,
          modify, add or remove any portion of this policy at any time without
          any prior written notice to you. It is your responsibility to review
          this policy periodically for any updates/ changes.
        </p>
        <p>
          For any further queries regarding return, exchange or refund, please
          reach out to customer support at legalsupport@Groupick.in.
        </p>
      </div>

      <div>
        <div className="footer p-md-5 p-3">
          <div className="container-fluid row justify-content-between">
            <div className="col-md-3">
              <h3>Groupick</h3>
              <p className="below-logo-desc text-grey">
                Groupick is India's #1 group shopping app. Our vision is to
                create India's most reliable and frictionless social commerce
                ecosystem that creates life-changing experiences for buyers and
                sellers.
              </p>
            </div>
            <div className="col-md-3">
              <h3>Links</h3>
              <ul className="list-style-none p-0 text-grey">
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.ABOUT_US}
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.PRIVACY_POLICY}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.TERM_AND_CONDITION}
                  >
                    Terms of Services
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.REFUND}
                  >
                    Return & Refund Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h3>Contact Us</h3>
              <p className="below-logo-desc text-grey">contact@groupick.in</p>
              <p className="below-logo-desc text-grey">
                Sanpada sector 01, Navi Mumbai 400705
              </p>
              <p className="below-logo-desc text-grey">+918668950480</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center border-top-1 mx-5 py-4 copy-right-footer">
        <h4>@ 2022 Getzie Technology Pvt Ltd. All Right Reserved.</h4>
      </div>
    </StyledLandingPage>
  );
};

export default Refund;
