import React from "react";

const ErrorMessage = (props) => {
  return (
    <div
      className={`text-danger my-2 ${props.className ? props.className : ""}`}
    >
      <div className="fv-help-block">{props.message}</div>
    </div>
  );
};

export default ErrorMessage;
