import React, { useEffect, useState } from "react";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import OrderTable from "./OrderTable";
import OrderSelection from "./OrderSelection";
import CustomLoader from "../../compoents/common/CustomLoader";
import { toast } from "react-toastify";

const OnHold = ({ fetchOrderCount }) => {
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [onHold, setOnHold] = useState([]);
  const [selected, setSelected] = useState({});
  const [enableSelectOrder, setEnableSelectOrder] = useState(false);
  const [fetching, setFetching] = useState(true);

  const fetchHoldOrder = async () => {
    try {
      const res = await sellerApiService.getOnHoldOrders();
      if (res.data) {
        const { orders } = res.data;
        setOnHold(orders);
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.warn("Error@fetchHoldOrder", error);
    }
  };

  useEffect(() => {
    fetchHoldOrder();
    return () => {
      setSelected({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (id) => {
    setSelected((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (newSelected[id]) {
        delete newSelected[id];
      } else {
        newSelected[id] = true;
      }
      console.log("id", id, typeof id, newSelected);
      return newSelected;
    });
  };

  const closeOrderSelection = () => {
    setEnableSelectOrder(false);
    setSelected({});
  };
  const onSelectOrder = () => {
    setEnableSelectOrder(true);
  };

  const handleBulkAccept = async () => {
    console.log("Accept bulk order", Object.keys(selected));
    try {
      setFetching(true);
      const formData = new FormData();
      formData.append("order_list", JSON.stringify(Object.keys(selected)));
      const res = await sellerApiService.acceptBulkOrder(formData);
      closeOrderSelection();
      if (res?.data && res?.data?.status) {
        fetchOrderCount();
        fetchHoldOrder();
        toast.success("Orders are getting processed!");
        return;
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
      closeOrderSelection();
    }
  };

  return (
    <>
      {fetching && <CustomLoader />}
      {onHold?.length > 0 && (
        <OrderSelection
          onSelectOrder={onSelectOrder}
          onCancelSelection={closeOrderSelection}
          isSelectionEnabled={enableSelectOrder}
          selectedCount={Object.keys(selected)?.length ?? 0}
          onBulkAccept={handleBulkAccept}
        />
      )}
      <OrderTable
        data={onHold}
        selectable={enableSelectOrder}
        handleSelect={handleSelect}
        selected={selected}
        disabledBtnList={
          enableSelectOrder
            ? {
                cancelBtn: true,
                acceptBtn: true,
              }
            : undefined
        }
      />
    </>
  );
};

export default OnHold;
