// eslint-disable-next-line
import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
import { GenericButton } from "../../compoents/Button";
const Description = () => {
  return (
    <div>
      <Form>
        <Row className="mb-3">
          <Col xs={12}>
            <Form.Group as={Col} controlId="formGridProductDesc">
              <Form.Label className="" style={{ color: "#122736" }}>
                Product Desciption
              </Form.Label>
              <FloatingLabel controlId="floatingTextarea2" label="write here">
                <Form.Control
                  as="textarea"
                  placeholder="write here..."
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6} xs={12}>
            <Form.Group as={Col} controlId="formGridKPF">
              <Form.Label className="" style={{ color: "#122736" }}>
                Key Product Features
              </Form.Label>
              <Form.Control type="text" placeholder="write here...." />
            </Form.Group>
          </Col>
          <Col xs={12} className="my-3">
            <Form.Group as={Col} controlId="formGridLDD">
              <Form.Label className="" style={{ color: "#122736" }}>
                Legal Disclaimer Description
              </Form.Label>
              <FloatingLabel controlId="floatingTextarea2" label="write here">
                <Form.Control
                  as="textarea"
                  placeholder="write here..."
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-5 text-center">
          <GenericButton>Save and Finish</GenericButton>
        </div>
      </Form>
    </div>
  );
};

export default Description;
