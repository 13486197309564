import axios from "axios";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { APP_SETTINGS } from "../../config";
import { update_qc } from "../../redux/slices/qc_slice";
import "./qc.css";
import QCTab from "./QCTab";
import QcTable from "./QcTable";
import UploadFileModal from "./UploadFileModal";
import RefreshIcon from "../../assets/Images/refresh.png";

function QC(props, ref) {
  const [selectedTab, setSelectedTab] = useState(0);
  // const [qcData, setQcData] = useState([]);
  const dispatch = useDispatch();
  // const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedQC, setSelectedQC] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const fetchQc = useCallback(async () => {
    try {
      const url = APP_SETTINGS.API_PATH.CATALOG.qc;
      const token = JSON.parse(getCookie("mee_too_user"));
      const config = {
        headers: {
          Authorization: `Bearer ${token["token"]}`,
          "content-type": "application/json",
        },
      };
      const res = await axios.get(url, config);
      const { status, data } = res;
      setTimeout(() => {
        setRefreshing(false);
      }, 500);
      if (status >= 200 && status < 300) {
        dispatch(update_qc(data));
        return;
      }
      return toast.error("Something went wrong");
    } catch (error) {
      setTimeout(() => {
        setRefreshing(false);
      }, 500);
      console.log("Error while fetch QC");
      toast.error(error?.response?.data?.error ?? "Something went wrong");
    }
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    refreshQc: () => fetchQc(),
  }));

  useEffect(() => {
    console.log("Fetching...");
    fetchQc();
  }, [fetchQc]);

  const handleSelection = useCallback((value) => {
    console.log("selected:", value);
    setSelectedTab(value);
  }, []);

  // const handleModalClose = () => {
  //   setShow(false);
  //   setSelectedQC(null);
  // };

  const handleUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("upload", file);

      const url = APP_SETTINGS.API_PATH.CATALOG.qc_upload + "/" + selectedQC;
      const token = JSON.parse(getCookie("mee_too_user"));
      const config = {
        headers: {
          Authorization: `Bearer ${token["token"]}`,
          "content-type": "application/json",
        },
      };

      const res = await axios.post(url, formData, {
        ...config,
        "content-type": "multipart/form-data",
      });

      if (res.status >= 200 && res.status <= 300) {
        if (res.data["Success"]) {
          fetchQc();
          toast.success(res.data["Success"]["Message"]);
        } else {
          toast.error("Error while reading data from uploaded file.");
        }
        setShow(false);
        setSelectedQC(null);
      } else {
        setShow(false);
        setSelectedQC(null);
        toast.error("An error occured. Please try again");
      }
    } catch (e) {
      setShow(false);
      setSelectedQC(null);
      if (e.response.status === 404) {
        toast.error(
          e.response.data.error ?? "An error occured. Please try again"
        );
      } else {
        toast.error("An error occured. Please try again");
      }
    }
  };

  return (
    <>
      <UploadFileModal
        handleClose={handleClose}
        show={show}
        handleUpload={handleUpload}
      />

      <div className="qcContainer">
        <h4>Bulk Uploads</h4>
        <div className="qcContent">
          <QCTab handleSelection={handleSelection} selectedTab={selectedTab} />
          <img
            src={RefreshIcon}
            alt="refresh"
            className={`refreshIcon ${refreshing ? "rotate" : ""}`}
            onClick={() => {
              fetchQc();
              setRefreshing(true);
            }}
          />
          <QcTable
            tabId={selectedTab}
            setShow={setShow}
            setSelectedQC={setSelectedQC}
          />
        </div>
      </div>
    </>
  );
}

export default forwardRef(QC);
