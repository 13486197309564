// A wrapper for <Route> that redirects to the login

import React from "react";
import {Redirect, Route} from "react-router-dom";
import {PATH} from "../../config";
import {useAuth} from "./ProvideAuth";

function PublicRoute({children, ...rest}) {
  let auth = useAuth();

  return (
    <Route
      {...rest}
      render={({location}) =>
        auth.mee_too_user &&
        auth.mee_too_user.token &&
        auth.mee_too_user.approved === true ? (
          <Redirect
            to={{
              pathname: PATH.DASHBOARD,
              state: {from: location},
            }}
          />
        ) : auth.mee_too_user &&
          auth.mee_too_user.token &&
          auth.mee_too_user.approved === false ? (
          <Redirect
            to={{
              pathname: PATH.SELLER_PROFILE,
              state: {from: location},
            }}
          />
        ) : (
          <React.Fragment>{children}</React.Fragment>
        )
      }
    />
  );
}
export default PublicRoute;
