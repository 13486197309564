import styled from "styled-components";

const PaidServiceStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .paidService__main {
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-item: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    display: grid;
    padding: 24px 12px;
    height: 100%;
    gap: 16px;
    button {
      margin: auto auto 0;
    }
  }
`;

const PaidServicesListStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    margin: 0;
    font-size: 18px;
    color: var(--primary-color);
    text-transform: capitalize;
  }
  .paidList__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 400px;
    min-height: 150px;
    margin: 12px auto 0;
    padding: 24px;
    position: relative;
    border-radius: 16px;
    border: 2px solid #000;
    overflow: hidden;
    .paidList__amountDiv {
      font-size: 22px;
      text-align: center;
      width: 100%;
      &:after {
        content: "";
        width: 100%;
        height: 3px;
        background: rgba(0, 0, 0, 0.3);
        display: block;
        max-width: 65%;
        margin: 6px auto 0;
      }
      .paidList__prefix {
        font-size: 14px;
      }
      .paidList__striked {
        font-size: 22px;
        text-decoration: line-through;
        color: gray;
      }
    }
    .paidList__points {
      margin: 24px 0 24px;
      font-size: 18px;
      list-style: none;
      padding: 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      line-height: 100%;
      gap: 8px;
      font-weight: 500;
      .paidList__point {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
      }
      .paidList__checkIcon {
        width: fit-content;
        height: 100%;
      }
    }
  }
  .paidList__submitBtn {
    width: 100%;
    margin: auto auto 0;
    border-radius: 8px;
  }
`;

const ActiveServiceStyled = styled.div`
  .activePlan__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    .activePlan__label {
      width: 100%;
      text-align: end;
      font-weight: 500;
    }
  }
  .activePlan__container {
    width: fit-content;
    margin: auto;
  }
  .activePlan__main {
    border: 1px solid;
    padding: 8px 24px;
    border-radius: 24px;
    width: fit-content;
    .activePlan__heading {
      margin: 0;
      text-align: center;
      font-size: 18px;
      color: var(--primary-color);
    }
    .activePlan__points {
      margin: 16px 0;
      font-size: 18px;
      list-style: none;
      padding: 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      line-height: 100%;
      gap: 8px;
      font-weight: 500;
      .activePlan__point {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
      }
    }
  }
  .divider {
    content: "";
    width: 100%;
    display: block;
    height: 2px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    margin: 16px auto;
  }
`;

export { PaidServiceStyled, PaidServicesListStyled, ActiveServiceStyled };
