import React, { useEffect, useState } from "react";
import { GenericLabel, OutlinedTextFields } from "../../compoents/Forms2";
import { FiPhone } from "react-icons/fi";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import { GenericButton } from "../../compoents/Button";
import { toast } from "react-toastify";

const MobileNumber = () => {
  let auth = useAuth();
  const timerCount = {
    min: 1,
    sec: 30,
  };
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [user, setUser] = useState(auth.mee_too_user);
  const [newMobile, setNewMobile] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [counter, setCounter] = useState({
    min: 0,
    sec: 0,
  });
  const [otp, setOtp] = useState("");
  const [isVerifyOtp, setIsverify] = useState(false);

  const sendOtp = async () => {
    setOtp("");
    if (!newMobile) return toast.error("Please enter new number.");
    else if (newMobile.length < 10)
      return toast.error("Please enter valid mobile number.");
    // send otp
    sellerApiService
      .sendOtp({ mobile: newMobile })
      .then((resp) => {
        const { data } = resp;
        if (data && data.error) {
          setIsOtpSent(false);
          return toast.error(data.error);
        }
        setIsOtpSent(true);
        setCounter(timerCount);
        toast.success(data?.success ?? "Otp sent.");
      })
      .catch((err) => {
        setIsOtpSent(false);
        setOtp("");
        console.log("error in send_otp:", err);
        toast.error("Error while sending otp.");
      });
  };

  const verifyOtp = () => {
    setIsverify(true);
    if (!newMobile || newMobile.length < 10)
      return toast.error("Please enter valid mobile number.");
    if (!otp) return toast.error("Please enter verification code.");
    sellerApiService
      .updateMobile({
        mobile: newMobile,
        otp,
      })
      .then((resp) => {
        setIsverify(false);
        const { data } = resp;
        if (data && data.error) {
          return toast.error(data.error);
        }
        setUser((prev) => ({ ...user, mobile: newMobile }));
        setOtp("");
        setNewMobile("");
        setIsOtpSent(false);
        toast.success(data?.success ?? "Mobile number updated.");
      })
      .catch((err) => {
        setIsverify(false);
        setOtp("");
        toast.error("Error while verifying mobile number.");
      });
  };

  useEffect(() => {
    let timer;
    if (isOtpSent) {
      timer = setInterval(() => {
        let tempMin = counter.min;
        let tempSec = counter.sec;
        if (tempSec > 0) {
          tempSec -= 1;
        } else if (tempSec === 0) {
          if (tempMin === 0) {
            setIsOtpSent(false);
            clearInterval(timer);
          } else {
            tempMin -= 1;
            tempSec = 59;
          }
        }
        if (timer) {
          setCounter({ min: tempMin, sec: tempSec });
        }
      }, 1000);
    } else if (!newMobile && timer) {
      clearInterval(timer);
    }
    return () => {
      // setIsOtpSent(false);
      if (timer) clearInterval(timer);
    };
  }, [counter, isOtpSent, newMobile]);

  useEffect(() => {
    (async () => {
      const res = await sellerApiService.getUserProfile();
      if (res.data) {
        const { user } = res.data;
        setUser(user);
      }
    })();
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center border-botton-grey pb-2">
        <FiPhone size={25} className="me-3" />
        <h4>Change Your Mobile Number</h4>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="my-4">
            <GenericLabel>
              <h6>Your Mobile Number</h6>
            </GenericLabel>
            <OutlinedTextFields
              name="firstName"
              type="text"
              value={user.mobile}
              disabled
              // onChange={handleChange}
              // onBlur={handleBlur}
            />
          </div>
          <div className="my-4">
            <GenericLabel>
              <h6>New Mobile Number</h6>
            </GenericLabel>
            <OutlinedTextFields
              name="mobile"
              type="text"
              placeholder="Enter your new mobile number"
              value={newMobile}
              disabled={isOtpSent || isVerifyOtp}
              onChange={(e) => setNewMobile(e.target.value)}
              // onBlur={handleBlur}
              // value={values.firstName}
            />
          </div>
          <div>
            <GenericButton
              disabled={isOtpSent || isVerifyOtp}
              onClick={sendOtp}
            >
              Send OTP
            </GenericButton>
            {isOtpSent && (
              <span className="text-danger">{` retry in ${counter.min
                .toString()
                .padStart(2, "0")}:${counter.sec
                .toString()
                .padStart(2, "0")} min`}</span>
            )}
          </div>

          <div className="mb-4 mt-5">
            <GenericLabel>
              <h6>Enter OTP</h6>
            </GenericLabel>
            <OutlinedTextFields
              name="otp"
              type="text"
              placeholder="Enter six digit number"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              // onChange={handleChange}
              // onBlur={handleBlur}
              // value={values.firstName}
            />
          </div>
          <GenericButton disabled={isVerifyOtp} onClick={verifyOtp}>
            Save
          </GenericButton>
        </div>
      </div>
    </div>
  );
};

export default MobileNumber;
