import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const SellerDeactivationPolicy = () => {
  return (
    <>
      <Container>
        <StyledBreadCrumb>
          <StyledBreadCrumb.Item active href="#">
            Dashboard
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Settings
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Legal & policies
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Seller Deactivation Policy
          </StyledBreadCrumb.Item>
        </StyledBreadCrumb>
        <h1 className="mt-4 mb-4">SELLER DEACTIVATION POLICY</h1>

        <p>
          Seller Deactivation Policy has been designed to maintain standards set
          by Getzie Technology Private Limited (“the Company”). This policy also
          includes the cases related to violation of listing policies as well as
          any gross negligence or misconduct committed by the Seller or its
          personnel with the Company or else reseller or customer (“User”).
          Capitalized words not specified herein shall hold the meaning as
          ascribed in the Seller Agreement entered with the Seller.
        </p>

        <p>
          The Company reserves the right to deactivate the Seller permanently in
          case of:
        </p>

        <h4>1. Selling Fake Products:</h4>
        <p>
          Seller must ensure that all the Products listed by them are genuine
          and not fake or counterfeit. If in case the Seller lists or attempts
          to list any fake or counterfeit products on the Application, then the
          Seller shall be forthwith deactivated permanently. The Seller must
          comply with Applicable Laws and shall not sell any counterfeit,
          parallel imports, fake, knock-off, unauthorized or imitation products
          which may lead to infringement of trademark, copyright, patent or
          design and other applicable intellectual property rights of any third
          party.
        </p>

        <h4>2. Violation of intellectual property rights:</h4>
        <p>
          In furtherance to this policy, the Seller shall refrain from doing the
          following:
        </p>
        <ul>
          <li>
            a) Using any brand name, trademark, logo or tagline whether on a
            Product or on the hand tags, package, cover or while listing such
            Products, which might lead to infringement of the third-party
            rights.
          </li>
          <li>
            b) Using a brand name which is deceptively similar or causes any
            likelihood of confusion with a brand name owned, registered or
            licensed to any third party and that such use by Seller is without
            any authorization from the rightful brand owner, proprietor, license
            holder et. al.
          </li>
          <li>
            c) Using any brand name, trademark or logo while listing a Product
            which is not rightfully owned by the Seller or that Seller has not
            been authorized/licensed to use.
          </li>
          <li>
            d) Any other act or omission which results in breach of intellectual
            property rights of the third party(s).
          </li>
        </ul>

        <h4>3. Non-compliance with Applicable Laws:</h4>
        <p>
          Company shall reserve the right to permanently deactivate the Seller
          in case of non-compliance with Applicable Laws of India including but
          not limited to privacy laws, Legal Metrology Act, 2009, IT Act, 2000
          and its allied rules.
        </p>

        <h4>4. Violation of third-party rights and privacy rights:</h4>
        <p>
          Any breach of third-party rights including intellectual property
          rights, privacy rights or unauthorized use and disclosure of personal
          information or breach of privacy policy of the Company or unauthorized
          access or breach of Company’s data (including User’s data), shall
          result in permanent deactivation of the Seller.
        </p>

        <h4>5. Listing Products:</h4>
        <p>
          The Seller may list Product(s) for sale on the Application in
          accordance with Applicable Law and the policies which are incorporated
          by way of reference in the Agreement. Seller must be legally able to
          sell the Product(s) being listed for sale on the Application. In case
          Seller holds multiple accounts or has duplicate/multiple catalogs over
          Application then all such duplicate listings/accounts shall be
          delisted, and the Seller shall be permanently deactivated by the
          Company.
        </p>

        <h4>6. Breach of Prohibited/Restricted Products Policy:</h4>
        <p>
          Breach of Prohibited/Restricted Products Policy as intimated by Seller
          shall result in delisting of Products from the Application and
          permanently deactivating the Seller.
        </p>

        <h4>7. Use of Indecent or Adult Materials for Listing:</h4>
        <p>
          Seller shall not list any indecent or adult/pornographic or explicit
          content for listing Products over Application which might be for adult
          use or view or might not be appropriate for minors (individuals under
          18 years of age) or are prohibited as per Applicable Law. Violation of
          this covenant shall result in immediate de-listing of all the Products
          under Seller’s portfolio/account and deactivating the Seller,
          permanently. Company shall also reserve its rights to seek relevant
          remedy under Applicable Law in case of any claim arising out of such
          use of inappropriate content for listing Products over the
          Application.
        </p>

        <h4>8. Defaulting in sale/delivery of Products:</h4>
        <p>
          Company reserves the right to permanently deactivate the Seller in
          case of any of the following events:
        </p>
        <ul>
          <li>a) Shipping and delivery of incorrect orders.</li>
          <li>
            b) Shipping and delivery of Products which are inconsistent with the
            order placed by the Users in terms of weight of the Product,
            specifications including but not limited to dimension, color, type,
            model, unit, and form.
          </li>
          <li>
            c) Delivery of incomplete orders which includes orders with missing
            Products, improper packaging, partially damaged Products or packages
            filled with irrelevant materials (such as stones, bricks for
            illustration).
          </li>
          <li>
            d) Shipping and delivery of defective or damaged Products including
            any Product without warranty, used Products, Products which are not
            suitable for use or consumption.
          </li>
          <li>e) Direct drop-shipping of Products to the Users.</li>
          <li>
            f) In case of a high number of cancellation of orders; (i) by the
            Seller on its own; or (ii) by the Company on account of Seller’s
            failure to dispatch the Products after Seller’s expected dispatch
            date.
          </li>
        </ul>

        <h4>9. Poor Performance:</h4>
        <p>
          Company reserves the right to permanently deactivate the Seller in
          case of poor performance based on either single or multiple reasons as
          mentioned below:
        </p>
        <ul>
          <li>
            a) High number of returns by the Users on account of reasons
            including but not limited to misshipment, missing Products,
            defective or damaged Products, used or expired Products, Products
            not meeting the specifications and representations committed by the
            Seller.
          </li>
          <li>
            b) Products not complying with the Applicable Laws and quality
            standards regarding manufacturing, use and sale of Products in the
            Territory.
          </li>
          <li>
            c) Bad customer feedback/review over a relevant period of time.
          </li>
          <li>d) Multiple/regular/repeated customer complaints.</li>
          <li>
            e) Selling Products over and above MRP or mentioning price higher
            than the MRP mentioned on the labels on the Product’s package.
          </li>
          <li>f) Abuse of price/discounting.</li>
          <li>g) Using sub-standard or poor-quality packaging material.</li>
          <li>
            h) Any alleged or actual fraudulent activity towards the Users
            resulting in loss or damage to the Company’s reputation/goodwill.
          </li>
          <li>
            i) Any abusive behavior with Users or Company or its personnel.
          </li>
          <li>
            j) Non-payment of dues to Company or any third-party service
            provider involved for providing services to the Seller.
          </li>
          <li>
            k) Sourcing Products from sanctioned countries in contravention to
            the trade embargo of India.
          </li>
          <li>
            l) Any other reasons solely attributable to the Seller causing
            irreparable harm and loss to the Seller.
          </li>
        </ul>

        <h4>10. Misconduct by the Seller, its personnel or Users:</h4>
        <p>
          Company has no tolerance towards abusive, indecent, improper and gross
          misconduct by the Seller or its personnel during the course of
          business or while communicating with the Company or its personnel. In
          case the Company finds that Seller or its personnel indulges in any
          activity which results in causing harm or injury or any form of mental
          agony or physical harassment or abuse then Company shall deactivate
          the Seller. Furthermore, the Seller or its personnel shall not indulge
          in any form of threat or abuse causing bodily harm or mental agony to
          the Company’s personnel or the Users which might lead to loss, injury,
          damage or claim to the Company on account of such acts or omissions by
          the Seller. These acts shall include but not be limited to indecent
          calls, abusive e-mails, spam calls, fake advertisements and
          solicitations, illicit messages, indecent and improper communication
          to the Company or the Users of the Application. Seller shall be given
          warning by the Company and in case the Seller continues to act with
          any willful intention to commit fraud against fellow Sellers, Users or
          Company, it will lead to permanent deactivation of the Seller.
        </p>

        <p>
          Notwithstanding anything to the contrary contained in this policy, the
          Company reserves the right to deactivate the Seller temporarily in
          case of:
        </p>

        <h4>11. Failure to Ship and Deliver Products:</h4>
        <p>
          In case of Seller’s failure to ship and deliver the Product within
          Seller’s expected dispatch date(s) then the entire catalog of the
          Seller shall be pulled down unless all the pending dispatches are
          completed by the Seller. Any failure of the Seller to deliver the
          Products post one (1) intimation by the Company shall result in
          temporary deactivation of the Seller. Seller shall be reactivated only
          once all pending dispatches are completed upon the sole discretion of
          the Company. In case of repeated default by the Seller in this regard,
          the Company reserves the right to permanently deactivate the Seller.
        </p>

        <h4>12. Incomplete KYC documentation:</h4>
        <p>
          In case the Seller fails to complete the Know-Your Customer (KYC)
          documents and that Company sends intimation to the Seller three (3)
          times, then post the third intimation, the Company shall temporarily
          deactivate the Seller. In such case, if the Seller wishes to
          reactivate its engagement, then it shall request the Company in
          writing and finish the KYC documentation thereafter.
        </p>
      </Container>
    </>
  );
};

export default SellerDeactivationPolicy;
