import React from "react";
import { MdAccountBalanceWallet } from "react-icons/md";
import styled from "styled-components";
import { BiRupee } from "react-icons/bi";
import WalletDeposit from "./WalletDeposit";
import { formatNumberToCurrency } from "../../utilities";
import useWallet from "../../hooks/useWallet";
import { useEffect } from "react";
import Loader from "../../compoents/Loader";
import { Spinner } from "react-bootstrap";

const WalletStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .wallet__content {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 24px;
    padding: 24px;
    height: 100%;
    .walletDetail {
      height: 100%;
      border-right: 2px solid #c3c3c3;
      display: flex;
      flex-direction: column;
      align-items: center;
      .walletDetail__heading {
        margin-top: 32px;
      }
      .walletDetail__details {
        display: flex;
        justify-content: center;
        align-items: center;
        .walletDetail__amount {
          font-weight: 500;
        }
      }
    }
    .walletDeposit {
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 8px 8px 16px;
      height: fit-content;
      .walletDeposit__heading {
        text-align: center;
        border-bottom: 2px solid #ddd;
        padding-bottom: 4px;
      }
    }
  }
`;

function Wallet() {
  const { fetchWalletDetail, fetching, total } = useWallet(true);

  useEffect(() => {
    fetchWalletDetail();
  }, []);

  return (
    <WalletStyled>
      <div className="d-flex align-items-center border-botton-grey pb-2">
        <MdAccountBalanceWallet size={25} className="me-3" />
        <h4>My wallet</h4>
      </div>
      <div className="wallet__content">
        <div className="walletDetail">
          <h5 className="walletDetail__heading">Balance Available:</h5>
          {fetching ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <div className="walletDetail__details">
              <BiRupee size={24} className="m-0" />
              <div className="walletDetail__amount">
                {formatNumberToCurrency(total)}/-
              </div>
            </div>
          )}
        </div>
        <div className="walletDeposit">
          <h5 className="walletDeposit__heading">Deposit</h5>
          <WalletDeposit fetchWalletDetail={fetchWalletDetail} />
        </div>
      </div>
    </WalletStyled>
  );
}

export default Wallet;
