import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GenericButton } from "../../compoents/Button";

import {
  productImageUploadThunk,
  resetImageUpload,
} from "../../redux/slices/product";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { useSelector } from "react-redux";
import { createProductSelector } from "../../redux/slices/product";
import { toast } from "react-toastify";
import { TOAST_STYLE } from "../../config";
import ProductDataService from "../../redux/services/product.service";
import { BiImage } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { useHistory } from "react-router-dom";
import {
  handleBasicUserProductAdd,
  updateLoggedIn,
} from "../../redux/slices/account";

const Images = (props) => {
  const { finalData, setFinalData, addProductHandler, setTabActiveKey } = props;
  const createProductState = useSelector(createProductSelector);
  const [files, setFiles] = useState([]);
  const [tick, setTick] = useState(false);

  const imageUploadRef = useRef(0);

  let {
    uploadProductImageSuccess,
    uploadProductImageFailure,
    uploadProductImageError,
  } = useSelector((state) => state.uploadProductImage);

  const hiddenFileInput = useRef(null);
  let dispatch = useDispatch();
  let auth = useAuth();
  const history = useHistory();

  let { createProductSuccess } = useSelector(createProductSelector);

  useEffect(() => {
    if (uploadProductImageFailure) {
      toast.error(
        uploadProductImageError
          ? uploadProductImageError
          : "Error While uploading Image",
        TOAST_STYLE
      );
    } else {
      imageUploadRef.current = imageUploadRef.current + 1;
      if (imageUploadRef.current === 2) {
        localStorage.removeItem("variations");
        // history.push("/inventory");
        history.push("/catalogue");
        dispatch(handleBasicUserProductAdd());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProductImageSuccess, uploadProductImageFailure]);

  function uploadFile(e) {
    let file = e.target.files[0];
    if (file.name > 100) {
      toast.error("Make sure that filename is less than 100 characters.");
      return;
    }
    if (file.size / 1024 > 1024) {
      toast.error("File size should be less than 1MB.");
      return;
    }
    let images = [...files];
    if (images.length < 5) {
      images.push(file);
      setFiles([...images]);
    } else {
      toast.error("You can upload only 5 images", TOAST_STYLE);
    }

    // if (createProductState?.createProductData) {
    //
    //   let finalPayload = {
    //     uploadImage: formData,
    //     productId: createProductState.createProductData.id,
    //     header: auth,
    //   };
    //   dispatch(productImageUploadThunk(finalPayload));
    // } else {
    //   toast.error("First Add Product And then upload images");
    // }

    // setImagArray((prev) => [...prev, formData]);
  }

  const addProduct = () => {
    // Step 1: Create Product
    toast.info("Adding Product", TOAST_STYLE);
    addProductHandler({ ...finalData });
  };

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  useEffect(() => {
    (async () => {
      if (createProductSuccess && !tick) {
        setTick(true);
        files.map((file, index) => {
          let formData = new FormData();
          let fileNameWithSpace = file.name.replace(/\s+/g, "");
          formData.append("file", file, fileNameWithSpace);
          formData.append("sequence", index);

          let finalPayload = {
            uploadImage: formData,
            productId: createProductState.createProductData.id,
            header: auth,
          };
          dispatch(productImageUploadThunk(finalPayload));
        });

        let variations = JSON.parse(localStorage.getItem("variations"));

        if (variations) {
          for (const variation of variations) {
            variation.parent = createProductState.createProductData.id;
            let v = {
              parent: createProductState.createProductData.id,
              name: createProductState.createProductData.name,
              description: createProductState.createProductData.description,
              mrp: variation.mrp,
              stock: variation.stock,
              selling_price: variation.selling_price,
              group_price: variation.group_price,
              sku: variation.sku,
              category: createProductState.createProductData.category,
              specifications: "[]",
              options: "[]",
              // options_list: JSON.stringify({
              //   size: variation.size ?? "",
              //   color: variation.color ?? "",
              // }),
              options_list: variation?.option_types
                ? JSON.stringify(variation?.option_types)
                : "",
            };
            let data = new FormData();
            for (let key in v) {
              data.append(key, v[key]);
            }
            let finalPayload = {
              header: auth,
              addProductFormData: data,
            };
            const res = await ProductDataService.createProduct(finalPayload);
            if (res.status == 201) {
              let product = res.data;
              dispatch(resetImageUpload());
              variation.images.map((file, index) => {
                let formData = new FormData();
                formData.append("file", dataURItoBlob(file));
                formData.append("sequence", index);
                let finalPayload = {
                  uploadImage: formData,
                  productId: product.id,
                  header: auth,
                };
                dispatch(productImageUploadThunk(finalPayload));
              });
            }
          }
        }
      }
    })();
  }, [createProductSuccess]);

  return (
    <div className="p-3">
      <h3 className="py-3">Upload your product Images</h3>
      <div className="row">
        <div className="col-12">
          <div className="">
            <div className="images-container d-flex flex-wrap flex-lg-nowrap">
              {[...Array(5)].map((item, index) => {
                const component = files[index] ? (
                  <span className="position-relative flex-grow-1">
                    <img
                      src={URL.createObjectURL(files[index])}
                      alt="bank_proof"
                      style={{ objectFit: "cover", height: "92%" }}
                      width="120px"
                      title="Click to remove image"
                      className="img-thumbnail img-fluid m-2"
                      key={index}
                    />
                    <span
                      class="position-absolute "
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4px",
                        borderRadius: "50%",
                        color: "white",
                        top: "3px",
                        right: "0px",
                        cursor: "pointer",
                        backgroundColor: "var(--orange-primary)",
                      }}
                      onClick={(e) => {
                        files.splice(index, 1);
                        setFiles([...files]);
                      }}
                    >
                      <IoIosClose />
                    </span>
                  </span>
                ) : (
                  <div>
                    <label
                      htmlFor={`upload-input-${index}`}
                      className="cursor-pointer px-2 m-2"
                    >
                      <span className="p-4 d-flex flex-column justify-content-center align-items-center  border border-2 rounded">
                        <BiImage size={50} className="mb-2" />
                        Add Image
                      </span>
                    </label>
                    <input
                      type="file"
                      hidden
                      name="img1"
                      id={`upload-input-${index}`}
                      onChange={(event) => {
                        uploadFile(event);
                      }}
                      ref={hiddenFileInput}
                    />
                  </div>
                );
                return (
                  <div className="d-flex flex-column">
                    {component}
                    <span className="text-center">{index + 1}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <GenericButton type="button" onClick={addProduct}>
          Save and Finish
        </GenericButton>
      </div>
    </div>
  );
};

export default Images;
