import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const IntellectualPropertyPolicy = () => {
  return (
    <>
      <Container>
        <StyledBreadCrumb>
          <StyledBreadCrumb.Item active href="#">
            Dashboard
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Settings
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Legal & policies
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Intellectual Property Policy
          </StyledBreadCrumb.Item>
        </StyledBreadCrumb>
        <h1 className="mt-4 mb-4">INTELLECTUAL PROPERTY POLICY</h1>

        <h4>1. Applicability of the Policy</h4>
        <p>
          Company (as defined in the Terms & Conditions) respects all
          third-party intellectual property. If you believe that your
          intellectual property rights have been used in a way that gives rise
          to concerns of infringement, please refer to the procedure for
          registration of a complaint mentioned herein below.
        </p>
        <p>
          To facilitate and assist people and organizations protect their
          intellectual property rights and as a trust building exercise between
          Company and its users, the Company has put in place this Intellectual
          Property Policy ("IP Policy") to identify and remove infringing or
          unlicensed items and material listed on its Platform (as defined in
          the Terms & Conditions) when an owner of intellectual property reports
          such infringement to us. The IP Policy is made in compliance with the
          applicable Indian Laws.
        </p>

        <h4>2. Intellectual Property</h4>
        <p>
          For the purpose of this IP Policy, "Intellectual Property" includes
          the following:
        </p>
        <p>
          (a) "Trade Marks" means a mark capable of being represented
          graphically and which is capable of distinguishing the goods or
          services of one person from of the others and may include service
          marks, logos, designs, trade dress, shape of goods, their packaging or
          combination of colours, as are duly registered and/or pending
          registration under the Trade Marks Act, 1999.
        </p>
        <p>
          (b) "Copyrights" in, copyrighted works and copyrighted materials i.e.
          literary, dramatic or musical work or artistic work, owned, acquired
          or validly licensed to any person in terms of the Copyright Act, 1957.
        </p>
        <p>
          (c) Other intellectual property rights legally valid and recognised in
          India such as trade secrets, proprietary know-how, any patentable or
          non-patentable inventions, discoveries or any modifications,
          adaptations, derivatives or improvements of each of the above.
        </p>

        <h4>3. Eligibility to Report</h4>
        <p>
          If you are the owner/valid licensee of any Intellectual Property in
          any content ("IP Owner") available/uploaded on Platform without your
          consent, or you believe that your content has been copied/misused in
          any way that constitutes infringement of your Intellectual Property
          rights, you may report the same to Company.
        </p>

        <h4>4. Procedure to report Intellectual Property right infringement</h4>
        <p>
          <strong>
            4.1. IP Owner is required to email us at legalsupport@Groupick.in;
          </strong>{" "}
          a notice of infringement in the format set out in SCHEDULE I hereto
          ("Notice Form") specifying the allegedly infringing listings on
          Platform.
        </p>
        <p>
          <strong>
            4.2. Company, on receipt of the Notice Form, may satisfy itself with
            the (a) genuineness of a duly and completely filled Notice Form and
            (b) the veracity thereof.
          </strong>{" "}
          Basis the same, Company may:
        </p>
        <p>
          (i) immediately delist the infringing products from the Platform;
          <br />
          (ii) block the relevant supplier/reseller/vendor/merchant/advertiser
          account from Platform and/or bar such
          supplier/reseller/vendor/merchant/advertiser from listing any products
          on Platform.
        </p>

        <h4>5. Indemnity</h4>
        <p>
          IP Owner understands that by submitting Notice Form, IP Owner grants
          to Company, the right to inter alia forwarding the Notice Form to the
          parties involved in the provision of the allegedly infringing content.
          You agree to indemnify Company for all claims brought by a third party
          against Company arising out of or in connection with the submission of
          a Notice Form.
        </p>

        <h4>6. Consequences of Incomplete/Frivolous Reporting</h4>
        <p>
          <p>
            6.1. If the Notice Form is incomplete or Company opines (in its sole
            discretion) that the Notice Form is frivolous and/or fake, Company
            may not take any action as stated above and may, if it deems
            necessary, report to the relevant supplier/ reseller/ vendor/
            merchant/ advertiser.
          </p>
        </p>
        <p>
          <p>
            6.2. If relevant supplier/ reseller/ vendor/ merchant/ advertiser
            believes that a report against it is frivolous, such relevant
            supplier/ reseller/ vendor/ merchant/ advertiser may intimate
            Company about the same along with documentary proof thereof
            ("Counter Claim").
          </p>
        </p>
        <p>
          <p>
            6.3. Company may reactivate the account of such relevant supplier/
            reseller/ vendor/ merchant/ advertiser and list the products on the
            Platform if Company is satisfied, in its sole discretion, of the
            genuineness of such Counter Claim.
          </p>{" "}
          Company may require such supplier/ reseller/ vendor/ merchant/
          advertiser to provide such information or documents, as it may deem
          fit for the said purpose.
        </p>
        <p>
          <p>
            6.4. You acknowledge that Company will exercise its discretion based
            on the documents and information provided by you and shall not
            independently verify the genuineness of your claim. Accordingly,
            Company shall not be held liable in any manner for any act/omission,
            so far as Company exercises reasonable and due diligence with
            respect to any Notice Form and/or a Counter Claim.
          </p>
        </p>
        <p>
          <p>
            6.5. You acknowledge that Company is not a statutory or judicial
            body and may act or omit to act basis the documents provided to it
            either by an alleged IP Owner or relevant supplier/ reseller/
            vendor/ merchant/ advertiser. You accordingly, acknowledge that
            Company shall not be liable for any such action or omission.
          </p>
        </p>

        <h4>SCHEDULE I NOTICE FORM</h4>
        <p>
          <strong>To,</strong>
          <br />
          Getzie Technology Private Limited
        </p>
        <p>
          I/We, [__] [insert name] (s/d/o or a company/LLP registered under the
          Companies Act, 1956/2013/ Limited Liability Partnership Act 2008)
          residing at /having registered office at [__] [insert address],
          through authorised representative Mr. [__] [insert name and
          designation], authorised vide [__] [insert details of Board
          Resolution, if any] do solemnly and sincerely declare as follows:
        </p>
        <p>
          1. I/We am/are the right owner of certain Intellectual Property
          rights, created/modified and applied for registration/registered under
          [__] [insert details of relevant statute]. Please see enclosed
          certified true copy of [__] [insert details proof of ownership of
          Intellectual Property by IP Owner];
        </p>
        <p>
          2. I/We believe that the item listings or materials identified in the
          annexure attached hereto [link and other details to be provided] are
          not authorised by me/us, our agent, or the law and therefore infringe
          my/our Intellectual Property rights;
        </p>
        <p>
          3. [__] [insert reasons for comprehending that the products in
          question violate IP Owner&#39;s Intellectual Property rights and the
          basis of your decision such as test purchases, laboratory reports or
          such similar documentary proof].
        </p>
        <p>
          4. In light of above, you are requested to expeditiously remove or
          disable access to the material or products claimed to be infringing
          from Groupick.in/Portal.
        </p>
        <p>5. I/We may be contacted at:</p>
        <p>
          Name- [__] <br /> Designation &amp; Company- [__]
          <br /> Address- [__] <br />
          Email- [__] <br />
          Telephone/Fax- [__]
        </p>
        <p>
          and make this declaration conscientiously believing it to be true and
          correct.
        </p>
        <p>
          Declared by [__] <br />
          on [__] [insert date] at [__] [insert place]
        </p>
        <p>
          Yours sincerely,
          <br />
          Signature
        </p>
        <p>
          <strong>Note:</strong>
        </p>
        <p>
          (i) Please provide documentary proof of ownership of Intellectual
          Property;
        </p>
        <p>
          (ii) Please provide documentary proof supporting your claim (if any);
        </p>
        <p>
          (iii) Please note that all documentary proof submitted along with this
          Notice should be &quot;certified as true&quot;.
        </p>
        <p>
          (iv) Please note that the information and documents provided in this
          notice may be shared with the person who is alleged to have infringed
          your Intellectual Property.
        </p>
      </Container>
    </>
  );
};

export default IntellectualPropertyPolicy;
