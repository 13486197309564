import { APP_SETTINGS } from "../../config";
import axios from "axios";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";

class ProductDataService {
  productListing(data) {
    // console.log("ProductDataService => productListing => data", data);
    return axios.get(`${APP_SETTINGS.API_PATH.PRODUCT.productListing}`, {
      headers: {
        Authorization: "Bearer " + data.mee_too_user.token,
      },
    });
  }
  createProduct(data) {
    return axios.post(
      `${APP_SETTINGS.API_PATH.PRODUCT.createProduct}`,
      data?.addProductFormData,
      {
        headers: {
          Authorization: "Bearer " + data?.header?.mee_too_user?.token,
        },
      }
    );
  }
  uploadImageProduct(data) {
    return axios.post(
      `${APP_SETTINGS.API_PATH.PRODUCT.uploadImage}/${data?.productId}/previews/`,
      data?.uploadImage,
      {
        headers: {
          Authorization: "Bearer " + data?.header?.mee_too_user?.token,
        },
      }
    );
  }
  updatePreview(data) {
    return axios.post(
      `${APP_SETTINGS.API_PATH.PRODUCT.updatePreview}/${data?.productId}/previews_update`,
      data?.updatePreviewData,
      {
        headers: {
          Authorization: "Bearer " + data?.header?.mee_too_user?.token,
        },
      }
    );
  }
}

export default new ProductDataService();
