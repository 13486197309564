import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import supportService from "../../../redux/services/support.service";
import { useEffect } from "react";
import Loader from "../../../compoents/Loader";
import FaqView from "./FaqView";

function ViewFaq() {
  const location = useLocation();
  const id = location.pathname?.split("/").pop();
  const [loaded, setLoaded] = useState(false);
  const [faq, setFaq] = useState(null);
  const fetchDetail = async (id) => {
    try {
      const res = await supportService.fetchSupportById(id);
      const { status, data } = res.data;
      setLoaded(true);
      if (status === "error") return toast.error("Something went wrong!");
      setFaq(data);
    } catch (err) {
      toast.error("Something went wrong!");
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchDetail(id);
  }, [id]);

  if (!loaded)
    return (
      <>
        <Loader />
        <Loader />
        <Loader />
      </>
    );
  return <div>{faq ? <FaqView data={faq} /> : <h1>No data</h1>}</div>;
}

export default ViewFaq;
