import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { GenericButton } from "../../compoents/Button";
import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import { GENERIC_MESSAGE } from "../../config";
import { OutlinedTextFields } from "../../compoents/Forms2";
import ErrorMessage from "../../compoents/ErrorMessage";
import { useSelector } from "react-redux";
// import {
//   dashboardCateSelector,
//   setSelectedCategory,
//   // getcategoryList,
//   // getCateSelector,
//   // setFirstLevelCate,
//   // setSecondLevelCate,
//   // setThirdLevelCate,
// } from "../../redux/slices/category";
// import SellerApiService from "../../redux/services/seller-api-service";
// import { useHistory } from "react-router-dom";
import EditCatgeoryModal from "../../compoents/EditCategoryModal";
import CustomTooltip from "./CustomTooltip";
import { setSelectedCategory } from "../../redux/slices/category";

const InfoDataSchema = Yup.object().shape({
  item_name: Yup.string()
    .max(180, "Your product name is long. Keep it under 180 characters.")
    .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  // brand_name: Yup.string(),
  country: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  description: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  features: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  // legal: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
});

const ContactsTab = (props) => {
  let { finalData, setFinalData, setTabActiveKey } = props;

  const selectedCategory = useSelector(
    (state) => state.dashboard_category_level.selectedCategory
  );

  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [specification, setSpecification] = useState([]);

  const selectedBrand = localStorage.getItem("selectedBrand")
    ? JSON.parse(localStorage.getItem("selectedBrand"))
    : null;
  const sessionItemName = sessionStorage.getItem("new-product-name");

  const getCategory = () => {
    let catgeoryValue;
    if (selectedCategory && selectedCategory.id) {
      catgeoryValue = selectedCategory.name;
    }
    return catgeoryValue;
  };

  const getSpecificationOption = (id) => {
    let fillteredOptions = selectedCategory?.specifications?.filter(
      (item) => item.type.id === id
    );
    return fillteredOptions;
  };

  const onChangeSelect = (e) => {
    let isPresent = specification.find((item) => item.type === e.target.name);
    let idInInteger = parseInt(e.target.value);
    if (!isPresent) {
      setSpecification((prev) => [
        ...prev,
        {
          type: e.target.name,
          value: idInInteger,
        },
      ]);
    } else {
      let findIndexOfExistingElem = specification.findIndex(
        (item) => item.type === e.target.name
      );
      if (findIndexOfExistingElem !== -1) {
        let newArray = specification;
        newArray[findIndexOfExistingElem] = {
          type: e.target.name,
          value: idInInteger,
        };
        setSpecification(newArray);
      }
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          item_name: sessionItemName,
          // brand_name: '',
          country: "India",
          material_type: "",
          fabric: "",
          description: "",
          features: "",
          // legal: "",
        }}
        validationSchema={InfoDataSchema}
        onSubmit={(values) => {
          let arrayTest = specification?.map((item) => item.value);
          let finalPayload = {
            ...values,
            specifications: arrayTest,
            is_branded: false,
          };
          if (selectedBrand && selectedBrand?.id) {
            finalPayload["brand"] = selectedBrand["id"];
            finalPayload["is_branded"] = true;
          }
          setFinalData(finalPayload);
          setTabActiveKey("offer");
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <FormikForm>
            <div className="px-5 py-3">
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Product Title <span className="text-danger">*</span>{" "}
                      <CustomTooltip
                        description="Add the name of the product which will be visible on the app. Include information like fabric, material, capacity etc., within the name"
                        placement="right"
                        customStyledClass={{ transform: "translateY(15%)" }}
                      />
                    </Form.Label>
                    {/* <Form.Control type="text" placeholder="Item Name" /> */}

                    <OutlinedTextFields
                      className="my-2"
                      name="item_name"
                      type="text"
                      placeholder="Write here..."
                      value={values.item_name}
                      onChange={(e) =>
                        setFieldValue("item_name", e.target.value)
                      }
                      hasError={touched.item_name && errors.item_name}
                    />
                    {touched.item_name && errors.item_name && (
                      <ErrorMessage message={errors.item_name} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {selectedBrand && (
                <Row className="mb-3">
                  <Col md={6} xs={12}>
                    <Form.Group as={Col} controlId="formGridBrandName">
                      <Form.Label className="" style={{ color: "#122736" }}>
                        Brand Name
                      </Form.Label>

                      <OutlinedTextFields
                        disabled={true}
                        className="my-2"
                        name="brand_name"
                        type="text"
                        placeholder="Enter Brand Name"
                        value={selectedBrand?.name ?? "abc"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridBrandName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Country
                    </Form.Label>

                    <select
                      id="country"
                      name="country"
                      className="form-select"
                      onChange={(e) => setFieldValue("country", e.target.value)}
                      value={values.country}
                    >
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Aland Islands">Aland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bonaire, Sint Eustatius and Saba">
                        Bonaire, Sint Eustatius and Saba
                      </option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">
                        British Indian Ocean Territory
                      </option>
                      <option value="Brunei Darussalam">
                        Brunei Darussalam
                      </option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, Democratic Republic of the Congo">
                        Congo, Democratic Republic of the Congo
                      </option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Curacao">Curacao</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">
                        Falkland Islands (Malvinas)
                      </option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">
                        French Southern Territories
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-Bissau">Guinea-Bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value="Holy See (Vatican City State)">
                        Holy See (Vatican City State)
                      </option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">
                        Iran, Islamic Republic of
                      </option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">
                        Korea, Democratic People's Republic of
                      </option>
                      <option value="Korea, Republic of">
                        Korea, Republic of
                      </option>
                      <option value="Kosovo">Kosovo</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">
                        Lao People's Democratic Republic
                      </option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">
                        Libyan Arab Jamahiriya
                      </option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, the Former Yugoslav Republic of">
                        Macedonia, the Former Yugoslav Republic of
                      </option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">
                        Micronesia, Federated States of
                      </option>
                      <option value="Moldova, Republic of">
                        Moldova, Republic of
                      </option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">
                        Netherlands Antilles
                      </option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">
                        Palestinian Territory, Occupied
                      </option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">
                        Russian Federation
                      </option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Barthelemy">Saint Barthelemy</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">
                        Saint Kitts and Nevis
                      </option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Martin">Saint Martin</option>
                      <option value="Saint Pierre and Miquelon">
                        Saint Pierre and Miquelon
                      </option>
                      <option value="Saint Vincent and the Grenadines">
                        Saint Vincent and the Grenadines
                      </option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">
                        Sao Tome and Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Serbia and Montenegro">
                        Serbia and Montenegro
                      </option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Sint Maarten">Sint Maarten</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and the South Sandwich Islands">
                        South Georgia and the South Sandwich Islands
                      </option>
                      <option value="South Sudan">South Sudan</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">
                        Svalbard and Jan Mayen
                      </option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">
                        Syrian Arab Republic
                      </option>
                      <option value="Taiwan, Province of China">
                        Taiwan, Province of China
                      </option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">
                        Tanzania, United Republic of
                      </option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-Leste">Timor-Leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">
                        Trinidad and Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">
                        Turks and Caicos Islands
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">
                        United States Minor Outlying Islands
                      </option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">
                        Virgin Islands, British
                      </option>
                      <option value="Virgin Islands, U.s.">
                        Virgin Islands, U.s.
                      </option>
                      <option value="Wallis and Futuna">
                        Wallis and Futuna
                      </option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                    {touched.country && errors.country && (
                      <ErrorMessage message={errors.country} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <hr />
            <div className="px-5 py-3">
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridBrandName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Recommended Browse Nodes
                    </Form.Label>

                    <Form.Control
                      value={getCategory()}
                      name="category"
                      type="text"
                      placeholder="No Category Selected"
                      aria-label="Disabled input example"
                      disabled
                      readOnly
                    />
                    {touched.category && errors.category && (
                      <ErrorMessage message={errors.category} />
                    )}
                  </Form.Group>
                </Col>
                <Col md={2} xs={3}>
                  <Form.Group
                    as={Col}
                    className="pt-1 mt-1 h-100 d-flex align-items-end"
                    controlId="formGridBrandName"
                  >
                    <GenericButton
                      type="button"
                      onClick={() => setEditCategoryModal(true)}
                    >
                      Edit
                    </GenericButton>
                  </Form.Group>
                </Col>
              </Row>

              {selectedCategory &&
                selectedCategory.specification_types &&
                selectedCategory.specification_types.length > 0 &&
                selectedCategory.specification_types.map(
                  (specification_type) => (
                    <Row className="mb-3">
                      <Col md={6} xs={12}>
                        <Form.Group as={Col} controlId="formGxfcridOMT">
                          <Form.Label className="" style={{ color: "#122736" }}>
                            {specification_type.name}
                          </Form.Label>
                          <Form.Select
                            className="my-2"
                            name={specification_type?.name?.replace(/\s/g, "")}
                            onChange={(e) => onChangeSelect(e)}
                            aria-label="Default select example"
                          >
                            <option value="select category">
                              Select Category
                            </option>
                            {getSpecificationOption(specification_type.id).map(
                              (element) => {
                                return (
                                  <option value={element.id} key={element.id}>
                                    {element.name}
                                  </option>
                                );
                              }
                            )}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  )
                )}
              <Row className="mb-3">
                <Col xs={12} md="6">
                  <Form.Group as={Col} controlId="formGridProductDesc">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Product Description <span className="text-danger">*</span>{" "}
                      <CustomTooltip
                        description="Provide the description of the product which will be
                        visible on the app"
                        placement="right"
                        customStyledClass={{ transform: "translateY(15%)" }}
                      />
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="description"
                      // type="text"
                      placeholder="Write Here..."
                      style={{ height: "100px" }}
                      as="textarea"
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                      hasError={touched.description && errors.description}
                    />
                    {touched.description && errors.description && (
                      <ErrorMessage message={errors.description} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridKPF">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Key Product Feature <span className="text-danger">*</span>
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="key_product_features"
                      type="text"
                      placeholder="Key Product Features"
                      onChange={(e) =>
                        setFieldValue("features", e.target.value)
                      }
                      hasError={touched.features && errors.features}
                    />
                    {touched.features && errors.features && (
                      <ErrorMessage message={errors.features} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                <Col xs={12} md="6" className="my-3">
                  <Form.Group as={Col} controlId="formGridLDD">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Legal Disclaimer Description
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="legal_disclaimer_description"
                      type="text"
                      style={{ height: "100px" }}
                      placeholder="Write Here..."
                      as="textarea"
                      onChange={(e) => setFieldValue("legal", e.target.value)}
                    />
                    {touched.legal && errors.legal && (
                      <ErrorMessage message={errors.legal} />
                    )}
                  </Form.Group>
                </Col>
              </Row> */}
              <div className="mt-5">
                <GenericButton type="submit">Save and Continue</GenericButton>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
      {/* </Row> */}
      {editCategoryModal && (
        <EditCatgeoryModal
          show={editCategoryModal}
          onHide={() => setEditCategoryModal(false)}
        />
      )}
    </div>
  );
};

export default ContactsTab;
