import React, { useEffect, useRef, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import Box from "../../compoents/Box";
import { GenericButton, ButtonComponent } from "../../compoents/Button";
import { PATH } from "../../config";
import SellerApiService from "../../redux/services/seller-api-service";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { current } from "@reduxjs/toolkit";
import { Spinner } from "react-bootstrap";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

let StyledCarousel = styled("div")`
  height: 70%;
  .carousel {
    height: 100%;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none;
  }
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }
`;

const InventoryProductDetail = () => {
  let match = useRouteMatch(PATH.INVENTORY_PRODUCT_DETAIL);
  const auth = useAuth();
  const [productId, setProductId] = useState(match.params.productId);

  const linkTo = `/edit-inventory-product/productId=${match.params.productId}`;

  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const [product, setProduct] = useState("loading");
  const [carouselImages, setCarouselImages] = useState([]);
  const [carouselImgIndex, setCarouselImgIndex] = useState(0);

  const parentProductRef = useRef();

  //
  useEffect(() => {
    (async () => {
      const res = await sellerApiService.getProduct(productId);
      if (res.data) {
        const { product } = res.data;
        if (!parentProductRef.current) {
          parentProductRef.current = product;
        }
        setCarouselImages(product.images);
        setProduct(product);
      }
    })();
  }, [productId]);

  return (
    <Box className="p-4">
      <StyledBreadCrumb>
        <StyledBreadCrumb.Item active href="#">
          Dashboard
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Inventory
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Products
        </StyledBreadCrumb.Item>
      </StyledBreadCrumb>
      <div className="d-flex justify-content-between">
        <h4 className="fw-bold">Product</h4>
        <Link to={linkTo}>
          <GenericButton style={{ boxShadow: "none" }}>Edit</GenericButton>
        </Link>
      </div>
      <Row className="gy-2 mt-0 mb-4 ">
        {product === "loading" ? (
          <Spinner />
        ) : (
          <>
            <h5>
              Category:{" "}
              <span className="fw-light">{product.category.name}</span>
            </h5>
            <Col xs={12} md={5}>
              <div className="d-flex h-100 flex-column justify-content-around">
                <StyledCarousel>
                  <Carousel
                    activeIndex={carouselImgIndex}
                    indicators={false}
                    variant="dark"
                    className="h-100"
                    key="caurosel"
                  >
                    {carouselImages.length
                      ? carouselImages?.map((element, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <img
                                style={{ height: "100%", objectFit: "cover" }}
                                className="d-block w-100"
                                src={element.file}
                                // src={element.image_name}
                                alt={element.file}
                              />
                            </Carousel.Item>
                          );
                        })
                      : "No Image found"}
                  </Carousel>
                </StyledCarousel>
                <div className="d-flex mt-3 align-items-center">
                  {carouselImages.length
                    ? carouselImages?.map((element, index) => (
                        <img
                          src={element.file}
                          style={{
                            width: "80px",
                            height: "80px",
                            padding: "5px",
                            borderRadius: "8px",
                            cursor: "pointer",
                            border:
                              carouselImgIndex === index
                                ? "2px solid var(--orange-primary)"
                                : "",
                          }}
                          alt=""
                          onClick={() => setCarouselImgIndex(index)}
                        />
                      ))
                    : ""}
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} className="ms-2">
              <h5 className="font-bold">{product.name}</h5>
              <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                <p className="fw-normal">MRP Rs. {product.mrp}</p>

                <p>Selling Price: {product.selling_price}</p>

                <p>
                  MRP Rs.
                  {product.group_price} (Group Buy)
                </p>
              </div>
              {product?.sku && <p className=" ">SKU ID #{product.sku}</p>}
              {product?.specifications?.length ? (
                <Row className=" gx-1 gy-2 align-items-center justify-content-start">
                  <Col xs={12} md={12} lg={3}>
                    <span className="font-bold" style={{ fontSize: "14px" }}>
                      Select Size
                    </span>
                  </Col>
                  {["S", "M", "L", "XL", "XXL"].map((element) => (
                    <Col xl={1} xs={2} className="mx-1">
                      <ButtonComponent
                        style={{ padding: "7px" }}
                        width="40px"
                        background="white"
                        borderRadius="50%"
                        color="#C2C2C2"
                        border="1px solid #C2C2C2"
                      >
                        {element}
                      </ButtonComponent>
                    </Col>
                  ))}
                </Row>
              ) : null}
              {parentProductRef.current.variants?.length > 0 ? (
                <div className="d-flex align-items-center">
                  <h5 className="me-2 mb-0 mt-3">Variation:</h5>
                  <div className="d-flex">
                    {parentProductRef.current.variants.map((v) => (
                      <img
                        // src={carouselImages?.[0]?.file}
                        src={`${v?.images?.file}`}
                        style={{
                          width: "80px",
                          height: "80px",
                          padding: "5px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          border:
                            product.id === v.id
                              ? "2px solid var(--orange-primary)"
                              : "1px solid black",
                        }}
                        alt=""
                        onClick={() => {
                          productId === v.id
                            ? setProductId(parentProductRef.current.id)
                            : setProductId(v.id);
                        }}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                " variations found."
              )}

              <h5 className="font-bold mt-4">Description</h5>
              <p className="text-muted w-75">{product.description}</p>
              <h5 className="font-bold mt-4">Product Detail</h5>
              <p className="text-muted w-75">
                <span>Stock: {product.stock}</span>
                <br />
                <span>Weight: {product.weight}</span>
                <br />
                <span>Dimensions: {product.dimensions}</span>
                <br />
                <span>HSN: {product.hsn}</span>
                <br />
                <span>Tax (%): {product.gst}</span>
                <br />
                <span>Country of Origin: {product.country}</span>
                <br />
              </p>
            </Col>
          </>
        )}
      </Row>
    </Box>
  );
};

export default InventoryProductDetail;
