import { BrowserRouter } from "react-router-dom";
import RouterConfig from "./Navigation/RouterConfig";
import { ProvideAuth } from "./Navigation/Auth/ProvideAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <ProvideAuth>
        <RouterConfig />
      </ProvideAuth>
    </BrowserRouter>
  );
}

export default App;
