import React, { useRef } from "react";
import { IMAGES } from "../../config";
import styled from "styled-components";
import { RiEdit2Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import accountService from "../../redux/services/account.service";
import "./profile.css";

const ProfileStyled = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  gap: 24px;
  .profile__imageContainer {
    width: 75%;
    max-width: 250px;
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    padding: 10px;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      border: 3px solid #ff4d00;
      padding: 2px;
      opacity: 0.8;
      box-shadow: 0px 0px 10px orange;
      object-fit: fill;
    }
    .profile__delete {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 3.5rem;
      width: 3.5rem;
      padding: 4px;
      border-radius: 50%;
      background: #ff4d00;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(-70%, -15%);
      cursor: pointer;
      overflow: hidden;
      box-shadow: 4px 4px 10px #ff4d00;
      &:hover {
        color: #ff4d00;
        background: #fff;
        border: 2px solid #ff4d00;
      }
    }
  }
`;

function Profile({ user }) {
  const { image = null } = user;
  const inputRef = useRef(null);
  const auth = useAuth();
  const handleUpload = async (file) => {
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      let fsize = file[i].size;
      fsize = Math.round(fsize / 1024);
      // The size of the file.
      if (fsize >= 1024) {
        toast.error("Image size should not be greater than 1mb.");
        return;
      } else {
        formData.append(`image`, file[i]);
      }
    }
    try {
      const res = await accountService.uploadProfileImage(formData, auth);
      const { error = null } = res;
      if (error) {
        toast.error("Something went wrong.");
        return;
      }
      window.location.reload();
    } catch (err) {
      toast.error("Something went wrong.");
    }
  };

  return (
    <ProfileStyled className="my-4">
      <div className="profile__imageContainer">
        <img
          src={image ? image : IMAGES.PROFLE_IMAGE}
          alt="User"
          className="avatar-img rounded-circle"
        />
        <input
          ref={inputRef}
          className="invisible position-absolute w-1"
          type="file"
          onChange={(e) => {
            console.log("Upload file");
            handleUpload(e.target.files);
          }}
        />
        <div
          role="button"
          title="Edit"
          className="profile__delete"
          onClick={() => inputRef?.current?.click()}
        >
          <RiEdit2Line size={32} />
        </div>
      </div>
    </ProfileStyled>
  );
}

export default Profile;
