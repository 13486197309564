import { useState } from "react";
import { useAuth } from "../Navigation/Auth/ProvideAuth";
import walletService from "../redux/services/wallet.service";

function useWallet(isFormated = false) {
  const [fetching, setFetching] = useState(true);
  const [total, setTotal] = useState(0);
  const auth = useAuth();
  const { token } = auth?.mee_too_user;

  const fetchWalletDetail = async () => {
    try {
      const res = await walletService.fetchWallet({ token }, isFormated);
      const { status, data } = res.data;
      setFetching(false);
      if (status === "error") return;
      const { total = 0 } = data;
      setTotal(total);
    } catch (err) {
      console.log("Wallet detail error", err);
      setFetching(false);
    }
  };
  return { fetchWalletDetail, fetching, total };
}

export default useWallet;
