import React from "react";
import {Link, useHistory} from "react-router-dom";
import styled from "styled-components";
import {GenericButton} from "../../compoents/Button";
import {IMAGES, PATH} from "../../config";
const StyledAuthHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  // border: 1px solid #707070;
  /* position: fixed;
  top: 0px; */
`;

const StyledLandingPage = styled.div`

.heading-4{
color:#122736;
font-size:33px;
line-height:44px;
}

.heading-3{
    color:#676767;
    font-size:30px;
    line-height:50px
    font-weight:400;
}
.thumbnail-img{
    height:100%;
    width:100%;
}
.thumbnail-text-container{
    padding: 30px;
    background-color: #00000029;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.why-use-tittle{
    max-width: 100%;
    width: 440px;
    text-align: left !important;
}

.why-us-desc{
    width:440px;
    max-width: 100%;
    font-size:20px;
    font-weight:400;
    word-spacing:0.42px;
    color:#3D3D3D;
}

.footer{
    .text-grey{
        color:#818181 !important;
    }
    /* padding */
    .below-logo-desc{
        font-size:18px;
    }
    .list-style-none{
        list-style:none;
    }
    
}

.copy-right-footer{
    color:#252525;
}
.border-top-1{
        border-top: 1px solid black;
        font-size:20px;
    }
`;

const FooterBgSection = styled.div`
  background-image: url(${IMAGES.FOOTER_BG});
  min-height: 600px;

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const FooterBgWithColor = styled.div`
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0.6979166666666667) 0%,
    rgba(255, 255, 255, 0.29175420168067223) 0%
  );
  /* opacity: 0.73; */
  min-height: 600px;
  height: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  .hero-text-tittle {
    font-size: 62px;
    margin: auto;
    max-width: 800px;
    width: 100%;
  }
  .hero-text-sub-tittle {
    font-size: 32px;
    max-width: 800px;
    width: 100%;
    margin: 30px auto;
  }
`;

const Landing = () => {
  let history = useHistory();
  const goToLoginScreen = () => {
    history.push(PATH.LOGIN);
  };

  return (
    <StyledLandingPage>
      <StyledAuthHeader className="container-fluid px-md-5 px-3 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link to={PATH.LANDING_PAGE}>
              <img
                style={{width: "70px"}}
                src={IMAGES.COMPANY_LOGO}
                alt="fund-raiser-logo"
              />
            </Link>
          </div>
          <div>
            <GenericButton
              onClick={goToLoginScreen}
              variant="none"
              className={"py-2 px-5"}
            >
              Login
            </GenericButton>
          </div>
        </div>
      </StyledAuthHeader>

      <div className="container py-4">
        <h2>About Groupick</h2>
        <p
          className="below-logo-desc text-grey"
          style={{lineHeight: "40px", fontSize: "24px"}}
        >
          India’s 1st Online Group Shopping app, Groupick vision is to create
          India’s most reliable and frictionless Social commerce ecosystem that
          creates life-changing experiences for buyers and sellers. Launched in
          2022 - India’s 1st Group shopping marketplace, with the widest
          assortment of products across 8 plus diverse categories from over
          thousands of regional, national, and international brands and
          retailers. Groupick is the online group shopping app for Internet
          users across the country, delivering to 6000+ cities and towns in
          India. Groupick is the preferred choice of thousands of online
          shoppers given its mammoth assortment of Thousands of products, quick
          delivery even to the remotest corners of the country, and daily deals,
          discounts & offers to make products available at slashed down prices
          to our valuable customers. Shopping online particularly at Groupick is
          a child’s play; all you need is a mobile phone with an Internet
          connection to get started. Simply Install the Groupick app and browse
          through the wide assortment of products across categories. Once you
          have zeroed in on your favorite products, Initiate a Group buying with
          2/3/4 people, bigger the group is bigger the saving, simply place your
          order by filling in the details; You get 24hrs time period to complete
          group 1)share link with friends/family & invite them for shopping
          2)your group displayed on the bottom of products so any stranger
          customer can also join you, when your group is completed, the products
          will be delivered right at your doorstep.
        </p>
      </div>
      <div className="container py-4">
        <h3> For sellers -</h3>
        <p
          className="below-logo-desc text-grey"
          style={{lineHeight: "40px", fontSize: "24px"}}
        >
          For sellers - Fulfill Your Entrepreneurial Dreams! Sell Today at
          Groupick Thanks to easy-to-understand, flexible policies and MTS
          Advisors to help sellers at each step, anyone from a manufacturer to
          wholesaler to retailer can sell on Groupick. Begin your
          entrepreneurial journey with Groupick as a seller by filling a simple
          registration form here. Once the registration process is done, you can
          start selling your products to the entire country by sitting at your
          home or office. Doesn't it sound thrilling? Of course, it is and the
          excitement will build up with every order you receive! Start selling
          at Groupick today and see your business reach staggering heights. Shop
          on the Go – Install the Groupick App Today! You can shop for your
          favorite products at Groupick on the go using Groupick App. Available
          for both Android and Apple users. The app is quick, user-friendly, and
          enables shoppers to buy products in a breeze. What's more, get timely
          notifications on your phone or tablet so that you don't miss amazing
          deals and offers. Download the app right now and experience how fun it
          is to shop on your mobile!
        </p>
      </div>

      <div>
        <div className="footer p-md-5 p-3">
          <div className="container-fluid row justify-content-between">
            <div className="col-md-3">
              <h3>Groupick</h3>
              <p className="below-logo-desc text-grey">
                Groupick is India's #1 group shopping app. Our vision is to
                create India's most reliable and frictionless social commerce
                ecosystem that creates life-changing experiences for buyers and
                sellers.
              </p>
            </div>
            <div className="col-md-3">
              <h3>Links</h3>
              <ul className="list-style-none p-0 text-grey">
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.ABOUT_US}
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.PRIVACY_POLICY}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.TERM_AND_CONDITION}
                  >
                    Terms of Services
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.REFUND}
                  >
                    Return & Refund Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h3>Contact Us</h3>
              <p className="below-logo-desc text-grey">contact@groupick.in</p>
              <p className="below-logo-desc text-grey">
                Sanpada sector 01, Navi Mumbai 400705
              </p>
              <p className="below-logo-desc text-grey">+918668950480</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center border-top-1 mx-5 py-4 copy-right-footer">
        <h4>@ 2022 Getzie Technology Pvt Ltd. All Right Reserved.</h4>
      </div>
    </StyledLandingPage>
  );
};

export default Landing;
