import moment from "moment";

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const loadRazorpay = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
  });
};

const formatNumberToCurrency = (num) => {
  const intl = new Intl.NumberFormat("hi-IN");
  return intl.format(Number(num));
};

const isToday = (sDate) => {
  return moment(sDate).isSame(new Date(), "day");
};

const isPast = (sDate) => {
  return moment(sDate).isBefore(new Date());
};

const getDateDiff = (date1, date2) => {
  return Math.abs(moment(date1).diff(date2, "hour"));
};

const amountWithSymbol = (amount) => <span>&#8377;{amount}</span>;

const getOrderStatus = (status = "onHold", created_at, delivery_data) => {
  let newStatus = "";
  switch (status) {
    case "onHold":
      newStatus = "Waiting for Order Approval.";
      break;
    case "readyToShip":
      newStatus = "Pickup will be scheduled within 2 days.";
      break;
    case "shipped":
      newStatus = (
        <>
          Customer Expected Delivery Date:{" "}
          <span className="text-danger fw-bolder">
            {moment(created_at).add(7, "days").format("DD-MM-YYYY")}
          </span>
        </>
      );
      break;
    case "delivered":
      newStatus = (
        <>
          Delivered On:{" "}
          <span className="text-danger fw-bolder">
            {delivery_data ? moment(delivery_data).format("DD-MM-YYYY") : "N/A"}
          </span>
        </>
      );
      break;
    default:
      break;
  }
  return newStatus;
};

function humanReadableNumber(num) {
  const units = ["", "K", "M", "B", "T"];
  let index = 0;
  while (num >= 1000) {
    num /= 1000;
    index++;
  }
  return `${Math.floor(num)}${units[index] ? units[index] + "+" : ""}`;
}

export {
  isEmpty,
  loadRazorpay,
  amountWithSymbol,
  formatNumberToCurrency,
  isToday,
  isPast,
  getDateDiff,
  getOrderStatus,
  humanReadableNumber,
};
