import React from "react";
import { SliderStyled } from "./sliderStyled";
import CustomSlider from "./CustomSlider";
import { IMAGESV2 } from "../../../../config";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

function Slider({ lists, title = "Available categories to sell" }) {
  const sliderRef = React.useRef(null);

  const categories = [
    {
      imgSrc: IMAGESV2.beauty_category,
      title: "Beauty",
    },
    {
      imgSrc: IMAGESV2.womenwear_category,
      title: "Women's Wear",
    },
    {
      imgSrc: IMAGESV2.menswear_category,
      title: "Men's Wear",
    },
    {
      imgSrc: IMAGESV2.mom_baby_category,
      title: "Mom & Baby",
    },
    {
      imgSrc: IMAGESV2.kidsfashion_category,
      title: "Kids Fashion",
    },
    {
      imgSrc: IMAGESV2.accessories_category,
      title: "Accessories",
    },
    {
      imgSrc: IMAGESV2.footwear_category,
      title: "Footwear",
    },
    {
      imgSrc: IMAGESV2.electronics_category,
      title: "Electronics",
    },
    {
      imgSrc: IMAGESV2.books_category,
      title: "Books",
    },
    {
      imgSrc: IMAGESV2.camera_category,
      title: "Camera",
    },
    // {
    //   imgSrc: IMAGESV2.gaming_category,
    //   title: "Gaming",
    // },
    // {
    //   imgSrc: IMAGESV2.food_category,
    //   title: "Food",
    // },
    // {
    //   imgSrc: IMAGESV2.music_category,
    //   title: "Music",
    // },
    // {
    //   imgSrc: IMAGESV2.computer_category,
    //   title: "Computer",
    // },
    // {
    //   imgSrc: IMAGESV2.motorcycle_category,
    //   title: "Motorcycle",
    // },
    // {
    //   imgSrc: IMAGESV2.automobile_category,
    //   title: "Automobile",
    // },
    // {
    //   imgSrc: IMAGESV2.hobbies_category,
    //   title: "Hobbies",
    // },
    // {
    //   imgSrc: IMAGESV2.stationary_category,
    //   title: "Stationary",
    // },
    // {
    //   imgSrc: IMAGESV2.sports_category,
    //   title: "Sports",
    // },
    // {
    //   imgSrc: IMAGESV2.pets_category,
    //   title: "Pets",
    // },
    // {
    //   imgSrc: IMAGESV2.homedecor_category,
    //   title: "Home Decor",
    // },
    // {
    //   imgSrc: IMAGESV2.luggage_category,
    //   title: "Luggage",
    // },
    // {
    //   imgSrc: IMAGESV2.health_category,
    //   title: "Health",
    // },
  ];

  const scroll = (direction) => {
    const { current } = sliderRef;
    const scrollPixel = 400;
    if (direction === "left") {
      current.scrollLeft -= scrollPixel;
    } else {
      current.scrollLeft += scrollPixel;
    }
  };

  return (
    <SliderStyled>
      <h3 className="slider__heading">{title}</h3>
      <div className="slider__content">
        <CustomSlider list={lists ? lists : categories} sliderRef={sliderRef} />
        <div
          role="button"
          onClick={() => scroll("left")}
          className="slider__arrow slider__leftArrow"
        >
          <AiOutlineLeft size={24} className="slider__icon" />
        </div>
        <div
          role="button"
          onClick={() => scroll("right")}
          className="slider__arrow slider__rightArrow"
        >
          <AiOutlineRight size={24} className="slider__icon" />
        </div>
      </div>
    </SliderStyled>
  );
}

export default Slider;
