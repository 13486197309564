import React from "react";
import StyledBox from "../../compoents/Box";
import { ButtonComponent, GenericButton } from "../../compoents/Button";
import { APP_SETTINGS } from "../../config";
import PaymentView from "./PaymentView";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../compoents/Loader";
import accountService from "../../redux/services/account.service";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { useCallback } from "react";
import PageTitle from "../../compoents/PageTitle";
import styled from "styled-components";

const DownloadBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const Payment = () => {
  const InsideStyledBoxProps = {
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #CCCCCC",
  };

  const initPaymentDetails = {
    next_payment: {
      amount: 0,
      penality: 0.0,
      waiver: 0.0,
      net_amount: 0,
    },
    last_payment: {
      amount: 0,
      penality: 0.0,
      waiver: 0.0,
      net_amount: 0,
    },
    previous_payment: {
      amount: 0,
      penality: 0.0,
      waiver: 0.0,
      net_amount: 0,
    },
    outstanding_payment: {
      amount: 0.0,
      penality: 0.0,
      waiver: 0.0,
      net_amount: 0.0,
    },
  };

  const [paymentDetail, setPaymentDetail] = useState(initPaymentDetails);
  const [loading, setLoading] = useState(false);
  const download_file_link = APP_SETTINGS.API_PATH.CATALOG.download_calculator;
  const auth = useAuth();

  const fetchPaymentDetails = useCallback(async () => {
    try {
      setLoading(true);
      const res = await accountService.getPaymentDetails(auth);
      const { data = {} } = res.data;
      if (data) {
        setPaymentDetail(data);
        setLoading(false);
      }
    } catch (err) {
      console.log("Error in fetchPayment:", err);
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    fetchPaymentDetails();
  }, [fetchPaymentDetails]);

  return (
    <>
      <div className="position-relative">
        <PageTitle text="Payment" />
        <DownloadBtn>
          <ButtonComponent
            borderRadius="4px"
            style={{ maxWidth: "242px", fontSize: "12px" }}
            background="#1492E6"
            className="py-2"
            border="none"
          >
            <a
              style={{ color: "white" }}
              href={download_file_link}
              download
              target={"blank"}
            >
              DOWNLOAD CALCULATOR
            </a>
          </ButtonComponent>
        </DownloadBtn>
      </div>
      <StyledBox>
        {/* <div>
          <div className="d-flex justify-content-end align-items-center">
            <div className="m-4 d-flex ">
              <div class="d-grid gap-2 justify-content-center me-3">
                <ButtonComponent
                  borderRadius="4px"
                  style={{ maxWidth: "242px", fontSize: "12px" }}
                  background="#1492E6"
                  className="py-2"
                  border="none"
                >
                  <a
                    style={{ color: "white" }}
                    href={download_file_link}
                    download
                    target={"blank"}
                  >
                    DOWNLOAD CALCULATOR
                  </a>
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div> */}
        {loading ? (
          <div
            style={{ height: "60vh" }}
            className="row justify-content-center align-items-center"
          >
            <Loader />
            <Loader />
            <Loader />
          </div>
        ) : (
          <div className="row p-4 justify-content-center">
            <div className="col-xs-7 col-md-5 col-xl-4 my-3">
              <PaymentView
                InsideStyledBoxProps={InsideStyledBoxProps}
                heading="Next Payment"
                description="Estimated value of next payment. this may change due to returns that
          come in before the next payout."
                route="next"
                details={paymentDetail?.next_payment}
              />
              {/* <StyledBox
            {...InsideStyledBoxProps}
            className="p-3 h-100 d-flex justify-content-around flex-column"
          >
            <div>
              <h5>Next Payment</h5>
              <p className="text-grey-light">
                Estimated value of next payment. this may change due to returns
                that come in before the next payout.
              </p>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Ammount</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Penalties</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Waivers and Compensation</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Net Ammount</h6>
                <span>Rs 0</span>
              </div>
            </div>{" "}
            <div className="d-flex justify-content-end mt-2">
              <GenericButton variant="primary">View Details</GenericButton>
            </div>
          </StyledBox> */}
            </div>
            <div className="col-xs-7 col-md-5 col-xl-4 my-3">
              <PaymentView
                InsideStyledBoxProps={InsideStyledBoxProps}
                heading="Last Payment"
                description="Amount transferred to your account on the last payment date."
                route="last"
                details={paymentDetail?.last_payment}
              />
              {/* <StyledBox
            {...InsideStyledBoxProps}
            className="p-3 h-100 d-flex justify-content-around flex-column"
          >
            <div>
              <h5>Last Payment</h5>
              <p className="text-grey-light">
                Amount transferred to your account on the last payment date.
              </p>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Ammount</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Penalties</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Waivers and Compensation</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Net Ammount</h6>
                <span>Rs 0</span>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <GenericButton variant="primary">View Details</GenericButton>
            </div>
          </StyledBox> */}
            </div>
            <div className="col-xs-7 col-md-5 col-xl-4 my-3">
              <PaymentView
                InsideStyledBoxProps={InsideStyledBoxProps}
                heading="Total Outstanding Payment"
                description=" Estimated value of the payments that are due to you. this may
            change due to returns that come in before the payment settlement
            dates."
                route="oustanding"
                details={paymentDetail?.outstanding_payment}
              />
              {/* <StyledBox
            {...InsideStyledBoxProps}
            className="p-3 h-100 d-flex justify-content-around flex-column"
          >
            <div>
              <h5>Total Outstanding Payment</h5>
              <p className="text-grey-light">
                Estimated value of the payments that are due to you. this may
                change due to returns that come in before the payment settlement
                dates.
              </p>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Ammount</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Penalties</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Waivers and Compensation</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Net Ammount</h6>
                <span>Rs 0</span>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <GenericButton variant="primary">View Details</GenericButton>
            </div>
          </StyledBox> */}
            </div>
            <div className="col-xs-7 col-md-5 col-xl-4 my-3">
              <PaymentView
                InsideStyledBoxProps={InsideStyledBoxProps}
                heading="Previous Payment"
                description="History of amounts transferred to your account"
                route="previous"
                details={paymentDetail?.previous_payment}
              />
              {/* <StyledBox
            {...InsideStyledBoxProps}
            className="p-3 h-100 d-flex justify-content-around flex-column"
          >
            <div>
              <h5>Previous Payment</h5>
              <p className="text-grey-light">
                History of amounts transferred to your account
              </p>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Ammount</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Penalties</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Waivers and Compensation</h6>
                <span>Rs 0</span>
              </div>
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Net Ammount</h6>
                <span>Rs 0</span>
              </div>
            </div>{" "}
            <div className="d-flex justify-content-end mt-2">
              <GenericButton variant="primary">View Details</GenericButton>
            </div>
          </StyledBox> */}
            </div>
            <div className="col-xs-7 col-md-5 col-xl-4 my-3">
              <StyledBox
                {...InsideStyledBoxProps}
                className="p-3 h-100 d-flex justify-content-between flex-column"
              >
                <div>
                  <h5>Commission Tax Invoice</h5>
                  <p className="text-grey-light">
                    History of Commission Tax Invoice
                  </p>
                </div>
                {/* <div>
                            <div className='d-flex justify-content-between align-items-center my-2'>
                                <h6>Ammount</h6>
                                <span>Rs 0</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center my-2'>
                                <h6>Penalties</h6>
                                <span>Rs 0</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center my-2'>
                                <h6>Waivers and Compensation</h6>
                                <span>Rs 0</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center my-2'>
                                <h6>Net Ammount</h6>
                                <span>Rs 0</span>
                            </div>
                       <div className="d-flex justify-content-end mt-2">
              <GenericButton variant="primary">View Details</GenericButton>
            </div>  </div> */}
                <div className="d-flex justify-content-end mt-2">
                  <GenericButton variant="primary">View Details</GenericButton>
                </div>
              </StyledBox>
            </div>
            <div className="col-xs-7 col-md-5 col-xl-4 my-3">
              <StyledBox
                {...InsideStyledBoxProps}
                className="p-3 h-100 d-flex justify-content-around flex-column"
              >
                <div>
                  <h5>Deductions And Compensations</h5>
                  <p className="text-grey-light">
                    Detailed data on Penalties, Waivers, Compensations with
                    reason
                  </p>
                </div>
                <div>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <h6>Ammount</h6>
                    <span>Rs 0</span>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-center my-2">
                  <h6>Penalties</h6>
                  <span>Rs 0</span>
                </div> */}
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <h6>Waivers and Compensation</h6>
                    <span>Rs 0</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <h6>Net Ammount</h6>
                    <span>Rs 0</span>
                  </div>
                </div>{" "}
                <div className="d-flex justify-content-end mt-2">
                  <GenericButton variant="primary">View Details</GenericButton>
                </div>
              </StyledBox>
            </div>
          </div>
        )}
      </StyledBox>
    </>
  );
};

export default Payment;
