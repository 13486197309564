import styled from "styled-components";
import { IMAGESV2 } from "../../../config";

const LoginStyle = styled.div`
  height: 100vh;
  width: 100vw;
  min-height: 100dvh;
  height: 100dvh;
  overflow-x: hidden;
  padding: 0 56px 48px;
  display: flex;
  flex-direction: column;
  .login__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${IMAGESV2.LoginImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgb(20, 27, 70);
      top: 0;
      left: 0;
      opacity: 0.88;
    }
  }
  .login__createBtn {
    font-weight: 500;
    &:hover {
      background: none;
    }
  }
  .login__navbar {
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    height: 96px;
    .login__logo {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
    .navbar-brand {
      padding: 0;
      margin: 0;
    }
  }
  .login__container {
    height: calc(100vh - 96px);
    z-index: 999;
    // color: #fff;
    // background: red;
    display: flex;
    h1,
    p {
      margin: 0;
      padding: 0;
    }
    .login__content {
      height: 100%;
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      flex: 1;
      margin: auto;
      .login__heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        color: #fff;
        h1 {
          font-size: 48px;
          font-weight: 700;
          line-height: 100%;
        }
        p {
          margin: 12px 0;
          font-size: 20px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          &::after {
            content: "";
            width: 100%;
            height: 2px;
            background: #fff;
            margin-top: 32px;
          }
        }
      }
    }
    .login__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.01rem;
      border-radius: 8px;
      min-height: 40px;
      // background: none;
      &:hover,
      &:focus {
        background: none;
        border-color: var(--primary-color);
      }
    }
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
    align-items: center;
    width: 100%;
    margin: auto;
    .login__navbar {
      width: 100%;
    }
    .login__container {
      width: 100%;
    }
  }
  @media (max-width: 748px), (max-height: 600px) {
    padding: 10px 32px 32px;
    .login__navbar {
      height: fit-content;
      .login__logo {
        width: 70px;
        height: 70px;
      }
    }
    .login__container {
      margin: auto 0;
      height: fit-content;
      .login__content {
        .login__heading {
          display: none;
        }
      }
    }
  }
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;

  .loginForm__container,
  .login__verifyOtpDiv {
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    padding: 48px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.22);
    border: 1px solid rgba(255, 255, 255, 0.22);
    color: #fff;
    .loginForm__heading {
      font-weight: 700;
    }

    .loginForm__text {
      color: #fff;
      font-weight: 500;
      background-color: rgba(255, 255, 255, 0.25);
      box-shadow: none;
      outline: none;
      border-radius: 24px;
      height: 40px;
      &::placeholder {
        color: #fff;
        font-weight: 400;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        color: #fff;
        font-weight: 400;
      }
      &::-ms-input-placeholder {
        color: #fff;
        font-weight: 400;
      }
    }
    .loginForm__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.01rem;
      border-radius: 8px;
      min-height: 40px;
      &:hover,
      &:focus {
        background: none;
        border-color: var(--primary-color);
      }
    }
    .loginForm__btn--createBtn {
      display: block;
      background: none;
      color: #fff;
      border: none;

      span {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
    .loginForm__errorMsg {
      margin: 0 !important;
      color: rgb(255, 193, 7) !important;
      width: 100%;
    }
    .loginForm__divider {
      display: flex;
      align-items: center;
      gap: 4px;
      &::after,
      &::before {
        content: "";
        flex: 1;
        width: 100px;
        height: 1px;
        display: block;
        border: 1px solid;
        opacity: 0.1;
      }
    }
  }

  @media (max-width: 748px), (max-height: 600px) {
    width: 100%;
    .loginForm__container {
      padding: 24px;
      max-width: 400px;
    }
  }
`;

export { LoginStyle, LoginContainer };
