import axios from "axios";
import { APP_SETTINGS } from "../../config";

class BrandApiService {
  addBrand(data) {
    const url = `${APP_SETTINGS.API_PATH.BRAND.brandCreate}`;
    return axios.post(url, data.brandData, {
      headers: {
        Authorization: "Bearer " + data?.header?.mee_too_user?.token,
      },
    });
  }
  updateBrand(data) {
    let url = `${APP_SETTINGS.API_PATH.BRAND.brandUpdate}`;
    url = url.replace("{{brand_id}}", data.brand_id);
    return axios.put(url, data.brandData, {
      headers: {
        Authorization: "Bearer " + data?.header?.mee_too_user?.token,
      },
    });
  }
  addBrandSample(data) {
    let url = `${APP_SETTINGS.API_PATH.BRAND.uploadBrandSample}`;
    url = url.replace("{{brand_id}}", data.brandId);
    return axios.post(url, data.sampleData, {
      headers: {
        Authorization: "Bearer " + data?.header?.mee_too_user?.token,
      },
    });
  }
  getAllBrands({ data, page = 0, size = 0 }) {
    const searchParam = new URLSearchParams();
    if (page) {
      searchParam.append("p", page);
    }
    if (size) {
      searchParam.append("s", size);
    }
    let url = `${APP_SETTINGS.API_PATH.BRAND.getAllBrands}`;
    url += `?${searchParam}`;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + data?.header?.mee_too_user?.token,
      },
    });
  }
  getBrandWithoutPagination({ data }) {
    const searchParam = new URLSearchParams({
      no_paginate: true,
    });
    let url = `${APP_SETTINGS.API_PATH.BRAND.getAllBrands}`;
    url += `?${searchParam}`;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + data?.header?.mee_too_user?.token,
      },
    });
  }
  getBrandById(data) {
    let url = `${APP_SETTINGS.API_PATH.BRAND.getBrandById}`;
    url = url.replace("{{brand_id}}", data.id);
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + data?.header?.mee_too_user?.token,
      },
    });
  }
}

export default new BrandApiService();
