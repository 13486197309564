import React from "react";
import PageTitle from "../../compoents/PageTitle";
import {Container, Col, Row, Badge} from "react-bootstrap";
import StyledBox from "../../compoents/Box";
import {IoMdClose} from "react-icons/io";

const Notifications = () => {
  return (
    <div>
      <PageTitle text="Notifications" />
      <section>
        {["success", "primary"].map((i) => (
          <div className="p-2 bg-white border border-2 rounded my-3 d-flex gx-3">
            <div>
              <Badge bg="light" text="dark">
                <IoMdClose />{" "}
              </Badge>
            </div>
            <div className="flex-grow-1 ms-3  d-flex justify-content-between flex-wrap justify-items-end">
              <div>
                <Badge bg={i} className="rounded-2 px-3 py-2">
                  Joined new seller
                </Badge>
                <h5 className="mt-2 mb-1">New Registration</h5>
                <p className="text-muted">
                  Complete the whole process to start your selling and get your
                  business started
                </p>
              </div>
              <div className="align-self-end text-end">
                <p className="text-muted mb-0">{"24 Nov 2021 at 09:30 am"}</p>
              </div>
            </div>
            <div></div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Notifications;
