import axios from "axios";
import { APP_SETTINGS } from "../../config";

class WalletService {
  init_payment(data, auth) {
    const { token } = auth;
    const url = `${APP_SETTINGS.API_PATH.WALLET.init_payment}`;
    return axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  verify_payment(data, auth) {
    const { token } = auth;
    const url = `${APP_SETTINGS.API_PATH.WALLET.verify_payment}`;
    return axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  fetchWallet(auth, format = false) {
    const { token } = auth;
    const url = `${APP_SETTINGS.API_PATH.WALLET.get_wallet}?isFormated=${format}`;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
}

export default new WalletService();
