import React from "react";
import { Form } from "react-bootstrap";

const Textfield = (props) => {
  const { className, hasError = false, ...rest } = props;
  return (
    <Form.Control
      className={`seller-dashboard-generic-input-field ${className} ${
        hasError ? "error_input_border" : ""
      }`}
      {...rest}
    />
  );
};

export default Textfield;
