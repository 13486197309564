import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GenericButton } from "../../../compoents/Button";
import { OutlinedTextFields } from "../../../compoents/Forms2";
import {
  GENERIC_MESSAGE,
  IMAGES,
  IMAGESV2,
  PATH,
  TOAST_STYLE,
} from "../../../config";

import * as Yup from "yup";
import { Formik, Form, validateYupSchema } from "formik";
import { toast } from "react-toastify";

import VerifyOtp from "../VerifyOtp";
import { loginUser, userSelector } from "../../../redux/slices/account";
import Loader from "../../../compoents/Loader";
import ErrorMessage from "../../../compoents/ErrorMessage";
import { NContainer, av, Navbar, Container, Nav } from "react-bootstrap";
import { LoginContainer, LoginContentStyle, LoginStyle } from "./LoginStyled";
import LoginForm from "./LoginForm";

const Login = () => {
  const [phone, setPhone] = useState();
  // let account = useSelector((state) => state.account);

  let dispatch = useDispatch();
  let {
    createUserLoading,
    createUserSuccess,
    createUserFailure,
    createUserError,
    createUserMessage,

    loginUserLoading,
    loginUserSuccess,
    loginUserFailure,
    loginUserMessage,
    loginUserError,
  } = useSelector(userSelector);

  useEffect(() => {
    if (loginUserFailure) {
      toast.error(
        loginUserMessage ? loginUserMessage : "Error while logging in.",
        TOAST_STYLE
      );
    }
  }, [loginUserSuccess, loginUserFailure]);

  let history = useHistory();
  const goToRegisterScreen = () => {
    history.push(PATH.REGISTER);
  };
  const goToLoginScreen = () => {
    history.push(PATH.LOGIN);
  };
  const goToLanding = () => {
    history.push(PATH.LANDING_PAGE);
  };

  const LoginAccountSchema = Yup.object().shape({
    mobile: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    // password: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  });

  const initVal = {
    mobile: "",
  };

  const onSubmit = (values) => {
    dispatch(loginUser(values));
    setPhone(values && values.mobile);
    sessionStorage.setItem("phone", values?.mobile);
  };

  return (
    <LoginStyle>
      <div className="login__bg" />
      <Navbar expand="lg" className="login__navbar">
        <Navbar.Brand>
          <Link to={PATH.LANDING_PAGE}>
            <img
              className="login__logo"
              src={IMAGES.COMPANY_LOGO}
              alt="fund-raiser-logo"
            />
          </Link>
        </Navbar.Brand>
        <GenericButton
          className="login__createBtn"
          onClick={goToRegisterScreen}
        >
          Create Account
        </GenericButton>
      </Navbar>
      <div className="login__container">
        <div className="login__content">
          <div className="login__heading">
            <h1>Login</h1>
            <p>Sign in to continue</p>
            <GenericButton className="login__btn" onClick={goToLanding}>
              Learn more
            </GenericButton>
          </div>
          {/* </div>
        <div className="login__rightSide"> */}
          <LoginContainer>
            {!loginUserSuccess ? (
              <LoginForm
                onSubmit={onSubmit}
                validateSchema={LoginAccountSchema}
                initVal={initVal}
                goToRegisterScreen={goToRegisterScreen}
              />
            ) : (
              <div className="login__verifyOtpDiv">
                <VerifyOtp />
              </div>
            )}
          </LoginContainer>
        </div>
      </div>
    </LoginStyle>
  );
};

export default Login;
