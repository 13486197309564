import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

const Table = (props) => {
  let { className } = props;

  return (
    <BootstrapTable
      {...props}
      bordered={false}
      condensed
      classes={`custom-table ${className ? className : ""}`}
      bodyClasses="custom-table-body"
      // pagination={paginationFactory(options)}
    />
  );
};

export default Table;
