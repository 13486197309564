import React, {useEffect} from "react";
import {Form, Row, Col, FloatingLabel} from "react-bootstrap";
import {GenericButton} from "../../compoents/Button";
import {useDispatch, useSelector} from "react-redux";
import {Formik, Form as FormikForm} from "formik";
import {OutlinedTextFields} from "../../compoents/Forms2";
import * as Yup from "yup";
import {GENERIC_MESSAGE} from "../../config";
import ErrorMessage from "../../compoents/ErrorMessage";
import {
  createProductSelector,
  createProductThunk,
} from "../../redux/slices/product";
import {useAuth} from "../../Navigation/Auth/ProvideAuth";
import Loader from "../../compoents/Loader";
// import { ProductAddContext } from "./addProduct";

const productDescriptionSchema = Yup.object().shape({
  description: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  features: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  legal: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
});

const Description = (props) => {
  const {finalData, setFinalData, addProductHandler, setTabActiveKey} = props;
  const createProductState = useSelector(createProductThunk);
  //
  const dispatch = useDispatch();
  //
  const {
    createProductLoading,
    createProductSuccess,
    createProductFailure,
    createProductError,
    createProductMessage,
    createProductData,
  } = useSelector(createProductSelector);

  useEffect(() => {
    // console.log("Description => finalData", finalData);
  }, []);

  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={{
          description: "",
          features: "",
          legal: "",
        }}
        validationSchema={productDescriptionSchema}
        onSubmit={(values) => {
          // console.log("onSubmit => values", values);
          // setFinalData((prev) => ({ ...prev, ...values }));
          // addProductHandler({ ...finalData, ...values });
          setFinalData((prev) => ({...prev, ...values}));
          // setFinalData({ type:"ADD_PRODUCT",payload:values });
          // console.log("Offer => finalData", finalData);
          setTabActiveKey("variations");
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <FormikForm>
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group as={Col} controlId="formGridProductDesc">
                  <Form.Label className="" style={{color: "#122736"}}>
                    Product Description
                  </Form.Label>
                  <OutlinedTextFields
                    className="my-2"
                    name="description"
                    // type="text"
                    placeholder="write here..."
                    style={{height: "100px"}}
                    as="textarea"
                    onChange={(e) =>
                      setFieldValue("description", e.target.value)
                    }
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    // value={values.firstName}
                  />
                  {touched.description && errors.description && (
                    <ErrorMessage message={errors.description} />
                  )}
                  {/* <FloatingLabel
										controlId="floatingTextarea2"
										label="write here"
									>
										<Form.Control
											as="textarea"
											placeholder="write here..."
											style={{ height: "100px" }}
										/>
									</FloatingLabel> */}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6} xs={12}>
                <Form.Group as={Col} controlId="formGridKPF">
                  <Form.Label className="" style={{color: "#122736"}}>
                    Key Product Features
                  </Form.Label>
                  {/* <Form.Control
										type="text"
										placeholder="write here...."
									/> */}
                  <OutlinedTextFields
                    className="my-2"
                    name="key_product_features"
                    type="text"
                    placeholder="Key Product Features"
                    onChange={(e) => setFieldValue("features", e.target.value)}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    // value={values.firstName}
                  />
                  {touched.features && errors.features && (
                    <ErrorMessage message={errors.features} />
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} className="my-3">
                <Form.Group as={Col} controlId="formGridLDD">
                  <Form.Label className="" style={{color: "#122736"}}>
                    Legal Disclaimer Description
                  </Form.Label>
                  {/* <FloatingLabel
										controlId="floatingTextarea2"
										label="write here"
									>
										<Form.Control
											as="textarea"
											placeholder="write here..."
											style={{ height: "100px" }}
										/>
									</FloatingLabel> */}
                  <OutlinedTextFields
                    className="my-2"
                    name="legal_disclaimer_description"
                    type="text"
                    style={{height: "100px"}}
                    placeholder="write here..."
                    as="textarea"
                    onChange={(e) => setFieldValue("legal", e.target.value)}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    // value={values.firstName}
                  />
                  {touched.legal && errors.legal && (
                    <ErrorMessage message={errors.legal} />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className="mt-5 text-center">
              <GenericButton type="submit">Save and Continue</GenericButton>
            </div>
            {/*<div className="mt-5 text-center">*/}
            {/*  <GenericButton type="submit" disabled={createProductLoading}>*/}
            {/*    {createProductLoading === true ? (*/}
            {/*      <>*/}
            {/*        Loading....*/}
            {/*        <Loader />*/}
            {/*      </>*/}
            {/*    ) : (*/}
            {/*      "Add Product"*/}
            {/*    )}*/}
            {/*  </GenericButton>*/}
            {/*</div>*/}
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default Description;
