import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { IMAGESV2 } from "../../../../config";

const StyleFeedback = styled.section`
  .main {
    height: 380px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // gap: 80px;
    // padding: 200px 0 0;
  }

  .testimonial {
    position: relative;
  }

  .black {
    margin: 0 auto;
    display: block;
    width: 55%;
    z-index: 2;
  }

  .orange {
    margin: 0 auto;
    display: block;
    width: 73%;
    position: absolute;
    top: 20px;
    right: 14%;
    // left: -50px;
    z-index: 10;
  }

  .feedback-text {
    // width: 50%;
    position: absolute;
    top: 24%;
    // left: 2%;
    right: 26%;
    z-index: 20;
    transform: rotate(-3deg);
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    font-style: italic;
    line-height: 1.2em;
  }
  .highlight {
    color: #000;
  }

  .author-details {
    position: absolute;
    top: 94%;
    left: 14%;
    // right: 0%;
    // bottom: 20;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 10px;
    z-index: 25;
  }

  .a-image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 3px solid #4e5562;
    border-radius: 100%;
    display: block;
  }

  .quote {
    width: 25px;
    height: auto;
  }

  .name,
  .address,
  .company {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    margin: 0;
  }

  .company {
    font-weight: bold;
  }
  .reviewHeading {
    font-weight: 700;
    // padding: 32px 0;
    margin: 0;
  }

  @media only screen and (max-width: 1400px) {
    .feedback-text {
      // width: 50%;
      position: absolute;
      top: 25%;
      // left: 2%;
      right: 27%;
      z-index: 20;
      transform: rotate(-3deg);
      color: #fff;
      font-size: 1.2rem;
      font-weight: 400;
      font-style: italic;
      line-height: 1.2em;
    }
  }

  @media only screen and (max-width: 992px) {
    .main {
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      // gap: 80px;
      // padding: 200px 0 0;
      // background-color: #000;
    }

    .testimonial {
      position: relative;
    }

    .black {
      margin: 0 auto;
      display: block;
      width: 55%;
      z-index: 2;
    }

    .orange {
      margin: 0 auto;
      display: block;
      width: 73%;
      position: absolute;
      top: 15px;
      right: 14%;
      // left: -50px;
      z-index: 10;
    }

    .feedback-text {
      // width: 50%;
      position: absolute;
      top: 25%;
      // left: 2%;
      right: 27%;
      z-index: 20;
      transform: rotate(-3deg);
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      font-style: italic;
      line-height: 1.2em;
    }
    .highlight {
      color: #000;
    }

    .author-details {
      top: 94%;
      left: 14%;
      // right: 0%;
      // bottom: 20;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      // justify-content: center;
      gap: 5px;
      z-index: 25;
    }

    .a-image {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border: 3px solid #4e5562;
      border-radius: 100%;
      display: block;
    }

    .quote {
      width: 20px;
      height: auto;
    }

    .name,
    .address,
    .company {
      font-size: 10px;
      font-weight: 500;
      margin: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    .main {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 100px;
      // padding: 200px 0 0;
    }

    .testimonial {
      position: relative;
    }

    .black {
      margin: 0 auto;
      display: block;
      width: 55%;
      z-index: 2;
    }

    .orange {
      margin: 0 auto;
      display: block;
      width: 73%;
      position: absolute;
      top: 20px;
      right: 14%;
      // left: -50px;
      z-index: 10;
    }

    .feedback-text {
      // width: 50%;
      position: absolute;
      top: 26%;
      // left: 2%;
      right: 30%;
      z-index: 20;
      transform: rotate(-3deg);
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      font-style: italic;
      line-height: 1.2em;
    }
    .highlight {
      color: #000;
    }

    .author-details {
      position: absolute;
      top: 94%;
      left: 12%;
      // right: 0%;
      // bottom: 20;
      width: auto;
      margin: 0 auto;
      display: flex;
      align-items: center;
      // justify-content: center;
      gap: 10px;
      z-index: 25;
    }

    .a-image {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border: 3px solid #4e5562;
      border-radius: 100%;
      display: block;
    }

    .quote {
      width: 25px;
      height: auto;
    }

    .name,
    .address,
    .company {
      font-size: 13px;
      color: #000;
      font-weight: 500;
      margin: 0;
    }

    .company {
      font-weight: bold;
    }
    .reviewHeading {
      font-weight: 700;
      // padding: 32px 0;
      margin: 0;
    }
  }
`;

const reviews = [
  {
    // id: 1,
    data: "मुझे पूरे इंडिया से ऑर्डर्स आते हैं, Thank you Groupick.",
    name: "Pankaj Sharma",
    shop: "A1 fashion",
    location: "Delhi",
    imgSrc: IMAGESV2.seller1,
  },
  {
    // id: 2,
    data: "Groupick App pe seller banane ke bad, mere orders 5x bad gaye.",
    name: "Neeraj shelar",
    shop: "Handy craft",
    location: "Mumbai",
    imgSrc: IMAGESV2.seller2,
  },
  {
    // id: 3,
    data: "Group Buying feature se mere orders badh gaye",
    name: " Iqbal azhar",
    shop: "IZ collection",
    location: "Tamilnadu",
    imgSrc: IMAGESV2.seller3,
  },
];

const Feedback = () => {
  return (
    <StyleFeedback>
      <Container>
        <h3 className="text-center reviewHeading">Sellers feedback</h3>
        <section className="main">
          <div className="testimonial">
            <div className="feedback">
              <img src={IMAGESV2.black} alt="" className="black" />
              <img src={IMAGESV2.feedback_1} alt="" className="orange" />
              {/* <h3 className="feedback-text">
                "मुझे पूरे इंडिया से ऑर्डर्स <br />
                आते हैं, Thank you <br />
                <span className="highlight">Groupick</span>."
              </h3> */}
            </div>
            {/* Details */}
            <div className="author-details">
              {/* quote icon */}
              <div className="quotes">
                <img src={IMAGESV2.quote_left} alt="" className="quote" />
              </div>
              {/* Author image */}
              <div className="author-image">
                <img src={reviews[0].imgSrc} alt="" className="a-image" />
              </div>
              {/* quote icon */}
              <div className="quotes">
                <img src={IMAGESV2.quote_right} alt="" className="quote" />
              </div>
              <div className="author-name">
                <p className="name">{reviews[0].name}</p>
                <p className="company">{reviews[0].shop}</p>
                <p className="address">{reviews[0].location}</p>
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className="testimonial">
            <div className="feedback">
              <img src={IMAGESV2.black} alt="" className="black" />
              <img src={IMAGESV2.feedback_2} alt="" className="orange" />
              {/* <h3 className="feedback-text">
                "<span className="highlight">Groupick</span> App pe seller{" "}
                <br />
                banane ke bad, mere <br /> orders 5x bad gaye."
              </h3> */}
            </div>
            {/* Details */}
            <div className="author-details">
              {/* quote icon */}
              <div className="quotes">
                <img src={IMAGESV2.quote_left} alt="" className="quote" />
              </div>
              {/* Author image */}
              <div className="author-image">
                <img src={reviews[1].imgSrc} alt="" className="a-image" />
              </div>
              {/* quote icon */}
              <div className="quotes">
                <img src={IMAGESV2.quote_right} alt="" className="quote" />
              </div>
              <div className="author-name">
                <p className="name">{reviews[1].name}</p>
                <p className="company">{reviews[1].shop}</p>
                <p className="address">{reviews[1].location}</p>
              </div>
            </div>
          </div>
          {/* 3 */}
          <div className="testimonial">
            <div className="feedback">
              <img src={IMAGESV2.black} alt="" className="black" />
              <img src={IMAGESV2.feedback_3} alt="" className="orange" />
              {/* <h3 className="feedback-text">
                "Group Buying feature <br /> se mere orders <br /> gaye"
                <span className="highlight">Groupick</span>. 
              </h3> */}
            </div>
            {/* Details */}
            <div className="author-details">
              {/* quote icon */}
              <div className="quotes">
                <img src={IMAGESV2.quote_left} alt="" className="quote" />
              </div>
              {/* Author image */}
              <div className="author-image">
                <img src={reviews[2].imgSrc} alt="" className="a-image" />
              </div>
              {/* quote icon */}
              <div className="quotes">
                <img src={IMAGESV2.quote_right} alt="" className="quote" />
              </div>
              <div className="author-name">
                <p className="name">{reviews[2].name}</p>
                <p className="company">{reviews[2].shop}</p>
                <p className="address">{reviews[2].location}</p>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </StyleFeedback>
  );
};

export default Feedback;
