import React from "react";
import { PartnerSliderStyled } from "./partnerSliderStyled";

function CustomSlider({ list = [], sliderRef }) {
  return (
    <PartnerSliderStyled ref={sliderRef}>
      {list?.map((item, i) => (
        <div className="customSlider__imageDiv" key={i}>
          <img
            className="customSlider__img"
            src={item.imgSrc}
            alt={item.title}
          />
        </div>
      ))}
    </PartnerSliderStyled>
  );
}

export default CustomSlider;
