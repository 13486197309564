import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const AdditionalSellerPolicies = () => {
  return (
    <>
      <Container>
        <StyledBreadCrumb>
          <StyledBreadCrumb.Item active href="#">
            Dashboard
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Settings
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Legal & policies
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Additional Seller Policies
          </StyledBreadCrumb.Item>
        </StyledBreadCrumb>
        <h1 className="mt-4 mb-4">ADDITIONAL SELLER POLICIES</h1>
        <p>
          <em>Privileged & Confidential</em>
        </p>

        <p>
          You agree that these policies shall form a part of the Seller
          agreement executed between you/Seller and Getzie Technology Private
          Limited (Meesho). This policy and the Seller agreement taken together
          will constitute the same agreement. All terms not defined herein shall
          have the same meaning as assigned to them under the Seller agreement.
          Any breach of the terms of this policy will be deemed to mean breach
          of the Seller agreement, and in the event of any such breach, Meesho
          shall have the same recourse as is available under the terms of the
          Seller agreement.
        </p>

        <h4>PRICING POLICY</h4>
        <p>
          Seller shall offer to list the Products on the Application at a
          competitive price (inclusive of taxes) on the Application.
          Notwithstanding anything contained in this clause, Seller authorizes
          Meesho in the capacity of a marketplace to discover & intimate the
          prevailing best price for Products for Seller’s consideration.
          Fixation of sale price shall be at the sole discretion of Seller and
          it shall be communicated to Meesho. Meesho shall act as a
          'Marketplace', under Applicable Law, and shall have no role in
          fixation of the sale price of the products on the Application.
        </p>

        <p>
          Seller shall have the right to withdraw a particular Product from the
          Application if there is a dispute as to the price of that particular
          Product. All such withdrawals will take effect within twelve (12) to
          twenty-four (24) hours from notification to Meesho upon raising
          request under Seller support ticketing.
        </p>

        <p>
          Seller understands, accepts, and agrees that the payment facility
          provided by Meesho is neither a banking nor financial service, but
          merely a facilitator providing an electronic, automated online
          electronic payment facility for receiving payment, or cash on delivery
          (CoD) payment, collection and remittance for transactions on the
          Application using the existing authorized banking infrastructure and
          credit card payment gateway (PG) network. Further, by providing
          payment facility, Meesho neither acts as a trustee nor fiduciary with
          respect to transaction or transaction price. All online bank transfers
          from valid bank accounts are processed using the gateway provided by
          the respective issuing bank that supports payment facility to provide
          these services to the users. All such online bank transfers on payment
          facility are also governed by the terms and conditions of the
          respective issuing bank.
        </p>

        <h4>LISTING POLICY</h4>
        <p>
          <strong>Product Stocking:</strong> Meesho and Seller shall determine
          an estimated quantity of pieces to be stocked as against each Product
          listed on the Application, based on the expected sales. Seller shall
          ensure that such number of Products shall be reserved for sale on the
          Application at all times.
        </p>

        <p>
          Seller hereby declares and confirms that it has the requisite
          permission to deal in Products represented through the respective
          brands. Seller has procured the authentic, original and genuine
          products from legitimate channels and has the right to enter into this
          Agreement with Meesho, allowing Meesho and/or its agents and marketing
          partners, to arrange the display with an objective to sell and
          distribute the products to User without infringing any third-party
          rights. Seller hereby declares and confirms that in the fulfilment of
          its obligations under this Agreement, Seller will not infringe any
          intellectual property rights of Meesho or any other third party.
        </p>

        <p>
          Seller shall provide Meesho with details and images of Product that it
          offers for sale on the Application in accordance with the Seller
          Policies. Seller shall be responsible to ensure the accuracy of the
          Product description and shall update the details of the Products on
          the Application from time to time, in the manner and at such frequency
          as prescribed by Meesho.
        </p>

        <p>
          Meesho shall, at its own discretion, provide the Services including
          the necessary backend infrastructure to Seller such as product
          uploading facilities, call centre, order management system, etc., for
          capturing the Orders placed to Seller over the Application. The Orders
          placed by the User on the Application may be viewed and accessed by
          Seller directly on the Seller Panel. On receipt of an Order, Seller
          shall pack the Product and dispatch the Products strictly in
          accordance with the Seller Policies and Applicable Law. In the event
          of a delay, Meesho will be immediately informed of such delay by the
          Seller, along with the expected time of dispatch, so that the User may
          be appropriately updated regarding the delivery time through the
          Application.
        </p>

        <p>
          Seller also undertake that Seller shall not independently (other than
          on the Application) promote products, services, offers, packages, etc.
          to the User directly, indirectly and /or through email, SMS or any
          other electronic or physical mode.
        </p>

        <p>
          Seller shall ensure listing of the Products and the Product
          descriptions as well as information shall be as per the Seller
          Policies and Applicable Law including but not limited to Legal
          Metrology Act, 2009 Food Safety and Standards Act, 2006, Consumer
          Protection Act, 2019 and its rules.
        </p>

        <p>
          For the Product listings Seller to provide the HSN and GST rates
          applicable on the Products. Seller to ensure correctness of these
          details and shall be solely responsible in case of any discrepancies.
        </p>

        <h4>Actions against Seller for non-compliance of Listing Policy</h4>
        <p>
          Seller shall be held liable for missed orders according to the Seller
          Policies, which will be shared with Seller from time to time on the
          Seller Panel.
        </p>

        <p>
          Meesho shall have the right to withdraw a particular Product(s) from
          the Application in the event of:
        </p>

        <ul>
          <li>any dispute as to the price of the Product(s);</li>
          <li>any manufacturing defects;</li>
          <li>any claims as to the rights over the Product(s);</li>
          <li>
            incorrect Product details or incorrect Product/missing Product from
            the Orders; or
          </li>
          <li>any issues with the quality of the Product(s); or</li>
          <li>
            any issue with the package or listing of the Products not in
            accordance with Applicable Law.
          </li>
        </ul>

        <p>
          All such withdrawals will take effect within seven (7) hours from
          notification to the Seller.
        </p>

        <h4>LOGISTICS POLICY</h4>
        <p>
          The title & ownership in the Product(s) shall be transferred to the
          User at the place of removal of Product(s). As a marketplace, Meesho
          has tied up with third party logistics providers to provide shipping /
          logistics service to Seller. Meesho will invoice Seller for logistics
          services which Seller will consider in the price of the Products
          listed on the Application. In any event, the Seller shall not ship
          /post the products directly to an address of the User. Any damage in
          transit on account of inadequate / unsuitable packaging shall be
          charged to Seller account. However, in case of any damage to the
          Product in transit due to mishandling by logistic partner appointed by
          Meesho, Meesho shall facilitate recovery of Product value from the
          logistic partner.
        </p>

        <p>
          On receipt of any Order, Seller shall ensure that the Orders are
          shipped within the expected dispatch date, as displayed on the Seller
          panel. "Expected dispatch date” shall mean the dispatch date/ SLA,
          displayed on the orders tab against the respective orders on the
          Seller Panel.
        </p>

        <h4>RETURN, REPAIR, REPLACEMENT AND CANCELLATION POLICY</h4>
        <p>
          Seller shall be responsible for determining a return and exchange
          policy for its Products and communicating the same to the User. In the
          event of any dispute between Seller and User(s), Meesho reserves the
          right to determine if such Product falls under Seller's return and
          exchange policy. Meesho’s decision in this regard shall be final and
          binding.
        </p>

        <p>
          Seller shall issue suitably, duly stamped manufacturer’s warranty card
          (if applicable) to the User with the Product at the time of dispatch
          of the Products. If any Products are found defective by the User,
          Seller shall ensure repair, replacement of such Product, or refund of
          the corresponding product value, as per the Seller’s return policy.
        </p>

        <p>
          In the event of any return/exchange of the Products, Seller shall bear
          all costs and charges, including any logistics charges, incurred in
          this regard. The return logistics charges will be as per the weight
          slabs. All returns of the Products shall be returned to Seller's
          shipping address only.
        </p>

        <p>
          In case Seller fails to make the Product ready for dispatch within the
          time stipulated or if the Order gets cancelled due to reasons stated
          under this clause, Meesho will be entitled to take necessary action
          against the Seller.
        </p>

        <h4>ORDER FULFILMENT POLICY</h4>
        <p>
          <strong>Dispatch Service Level Arrangement (“SLA”) Adherence</strong>
        </p>

        <p>
          <strong>Average Dispatch SLA:</strong>
        </p>

        <ul>
          <li>
            All orders should be dispatched within the expected dispatch date,
            as displayed on the Seller Panel.
          </li>
          <li>
            An average dispatch time exceeding the expected dispatch date will
            result in reduced visibility on the app and reduced order volume.
          </li>
          <li>
            All the Sellers who have dispatched more than 100 orders per day in
            the preceding week, shall dispatch their Products on all days of the
            week (including Sundays). However, in case the Seller is unable to
            dispatch on any particular day, they will be required to raise a
            ’leave request’ on the Seller Panel, in adherence to the leave
            policies as may be amended from time to time.
          </li>
          <li>
            The accounts of those Sellers who apply for leave, for more than 3
            consecutive calendar days shall be temporarily de-activated. The
            account shall be automatically re-activated when the leave period
            comes to an end.
          </li>
          <li>
            Sellers are advised to not apply for leaves for more than 2 times in
            a single calendar month.
          </li>
          <li>
            Default dispatch SLA for all Sellers is two (2) calendar days,
            unless otherwise specified by Seller while listing the product.
          </li>
        </ul>

        <p>
          Any delay beyond the SLA provided by Seller will count towards Seller
          Late Dispatch Rate (“LDR”), and will result in reduced visibility on
          the Application, thereby reducing Seller sales.
        </p>

        <p>
          To avoid pick-ups or delayed scanning issues, it’s advised Seller to
          manifest the orders at least 1 day before the SLA, and before 11 AM
          for the Orders eligible for same day delivery. In case pick-up does
          not happen then Seller to raise ticket with the Seller support team
          over Seller Panel.
        </p>

        <p>
          In case of pick-up or delayed scanning issues, Seller need to raise
          the ticket through Seller Panel using support option before 12 noon
          the next day with the list of Order IDs along with signed manifest (in
          case of delayed scanning issue).
        </p>

        <p>
          In case of any wrong returns or exchange disputes, Seller needs to
          raise the ticket through Seller Panel using support option within 48
          hours of receipt of the Product along with wrong Product images and
          order id, else it will not be considered.
        </p>

        <h4>
          Actions against Seller for non-compliance of Order Fulfilment Policy:
        </h4>
        <p>
          Cancellation of orders by Seller without proper rationale and prior
          intimation amounts to material breach of this Agreement. This is
          applicable on fresh as well as exchange Orders, whenever the Order is
          cancelled.
        </p>
      </Container>
    </>
  );
};

export default AdditionalSellerPolicies;
