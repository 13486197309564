import React from "react";
import { Nav, Tab } from "react-bootstrap";
import Paper from "../../compoents/Paper";
import CompaniesTab from "./CompaniesTab";
import ContactsTab from "./ContactsTab";
import HouseHoldsTab from "./HouseHoldsTab";
import SegmentsTab from "./SegmentsTab";
import TagsTab from "./TagsTab";
import Info from "./Info";
import Description from './Description'

const Contact = () => {
	return (
		<div>
			<Tab.Container id="left-tabs-example" defaultActiveKey="contacts">
				<Paper>
					<Nav
						variant="pills"
						className="fund-raise-generic-tabs-nav flex-row text-center button-text"
					>
						<Nav.Item>
							<Nav.Link eventKey="contacts" className="cursor-pointer">
								Contacts
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="info" className="cursor-pointer">
								Info
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="description" className="cursor-pointer">
								Description
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="companies" className="cursor-pointer">
								Companies
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="households" className="cursor-pointer">
								Households
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="tags" className="cursor-pointer">
								Tags
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="segments" className="cursor-pointer">
								Segments
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</Paper>
				<Paper className="mt-3">
					<Tab.Content>
						<Tab.Pane eventKey="contacts">
							<ContactsTab />
						</Tab.Pane>
						<Tab.Pane eventKey="info">
							<Info />
						</Tab.Pane>
						<Tab.Pane eventKey="description">
							<Description />
						</Tab.Pane>
						{/*  */}
						<Tab.Pane eventKey="companies">
							<CompaniesTab />
						</Tab.Pane>
						<Tab.Pane eventKey="households">
							<HouseHoldsTab />
						</Tab.Pane>
						<Tab.Pane eventKey="tags">
							<TagsTab />
						</Tab.Pane>
						<Tab.Pane eventKey="segments">
							<SegmentsTab />
						</Tab.Pane>
					</Tab.Content>
				</Paper>
			</Tab.Container>
		</div>
	);
};

export default Contact;
