import React from "react";
import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import { useState } from "react";
import brandApiServices from "../../../redux/services/brand-api-services";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { AiOutlineDownload } from "react-icons/ai";
import { requiredDocumentList } from "../brandConstant";
import Box from "../../../compoents/Box";
import styled from "styled-components";
import { Breadcrumb, Carousel, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { GenericButton } from "../../../compoents/Button";
import BrandForm from "../BrandForm/BrandForm";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

let StyledCarousel = styled("div")`
  height: 70%;
  .carousel {
    height: 100%;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none;
  }
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }
`;

function BrandView() {
  const location = useLocation();
  const [details, setDetail] = useState({});
  const [document, setDocument] = useState({});
  const [loading, setLoading] = useState({});
  const [carouselImgIndex, setCarouselImgIndex] = useState(0);
  const [carouselImages, setCarouselImages] = useState([]);
  const [showEditBrand, setShowEditBrand] = useState(false);
  const pathname = location?.pathname;
  const brandId = pathname.split("/").slice(-1)[0];
  const auth = useAuth();

  const fetchBrandById = async (id) => {
    try {
      const res = await brandApiServices.getBrandById({
        id,
        header: auth,
      });
      const { data } = res;
      setCarouselImages(data?.brand_sample ?? []);
      setDocument(() => {
        const doc = requiredDocumentList?.filter(
          (doc) => doc.value === data?.document
        );
        return { ...doc[0], link: data?.document_proof };
      });
      setDetail(data);
      setLoading(false);
    } catch (err) {
      setDetail([]);
      setLoading(false);
    }
  };
  useLayoutEffect(() => {
    setLoading(true);
    fetchBrandById(brandId);
  }, []);

  // if (loading && !details.length) {
  //   return null;
  // }

  const handleCloseForm = () => {
    setShowEditBrand(false);
    setLoading(false);
    fetchBrandById(brandId);
  };

  if (showEditBrand) {
    return (
      <BrandForm closeForm={handleCloseForm} isEdit={true} editData={details} />
    );
  }
  return (
    <Box className="p-4">
      <StyledBreadCrumb>
        <StyledBreadCrumb.Item active href="#">
          Dashboard
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Manage Brand
        </StyledBreadCrumb.Item>
        <StyledBreadCrumb.Item active href="#">
          Brand
        </StyledBreadCrumb.Item>
      </StyledBreadCrumb>
      {!(loading && !details.length) && (
        <>
          <div className="d-flex justify-content-between">
            <h4 className="fw-bold">Samples</h4>
            <GenericButton
              className="shadow-none fs-6"
              onClick={() => setShowEditBrand(true)}
            >
              Edit
            </GenericButton>
          </div>
          <Row className="gy-2 mt-0 mb-4">
            <Col xs={12} md={5}>
              <div className="d-flex h-100 flex-column justify-content-around">
                <StyledCarousel>
                  <Carousel
                    activeIndex={carouselImgIndex}
                    indicators={false}
                    variant="dark"
                    className="h-100"
                    key="caurosel"
                  >
                    {carouselImages.length
                      ? carouselImages?.map((element, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <img
                                style={{ height: "100%", objectFit: "cover" }}
                                className="d-block w-100"
                                src={element.file}
                                alt={element.file}
                              />
                            </Carousel.Item>
                          );
                        })
                      : "No Image found"}
                  </Carousel>
                </StyledCarousel>
                <div className="d-flex mt-3 align-items-center">
                  {carouselImages.length
                    ? carouselImages?.map((element, index) => (
                        <img
                          src={element.file}
                          style={{
                            width: "80px",
                            height: "80px",
                            padding: "5px",
                            borderRadius: "8px",
                            cursor: "pointer",
                            border:
                              carouselImgIndex === index
                                ? "2px solid var(--orange-primary)"
                                : "",
                          }}
                          alt=""
                          onClick={() => setCarouselImgIndex(index)}
                        />
                      ))
                    : ""}
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} className="ms-2 brand__content">
              <h5 className="font-bold">{details?.name}</h5>
              <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                <p>
                  Approved:{" "}
                  <span
                    className={`fw-bold ${
                      details?.is_approved ? "text-success" : "text-danger"
                    }`}
                  >
                    {details?.is_approved ? "Yes" : "No"}
                  </span>
                </p>

                <p className="fw-normal">
                  Active:{" "}
                  <span
                    className={`fw-bold ${
                      details?.is_active ? "text-success" : "text-danger"
                    }`}
                  >
                    {details?.is_active ? "Yes" : "No"}
                  </span>
                </p>

                <p>
                  Website:{" "}
                  {details?.website_link ? (
                    <a
                      href={details?.website_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {details?.website_link}
                    </a>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <h5 className="me-2 mb-0 mt-3">Logo:</h5>
                <img
                  src={details?.logo}
                  style={{
                    width: "80px",
                    height: "80px",
                    padding: "5px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    border: "2px solid var(--orange-primary)",
                  }}
                  alt=""
                />
              </div>
              <h5 className="font-bold mt-4">Additional Info.:</h5>
              <p className="text-muted w-75">
                {details?.additional_info ?? "-"}
              </p>
              <h5 className="font-bold mt-4">Brand Detail</h5>
              <div className="d-flex gap-2">
                <span>Document: </span>
                <p className="m-0">
                  {document?.label ? document?.label : "-"}{" "}
                  <a href={document?.link} target="_blank" rel="noreferrer">
                    <AiOutlineDownload size={18} />
                  </a>
                </p>
              </div>
              <div className="d-flex gap-2">
                <span>Website: </span>
                {details?.website_link ? (
                  <a
                    href={details?.website_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {details?.website_link}
                  </a>
                ) : (
                  "-"
                )}
              </div>
              <div className="d-flex flex-column">
                <h5 className="font-bold mt-4">Category:</h5>
                {details?.brand_ref?.map((category, i) => (
                  <div className="brand__category" key={`category-${i}`}>
                    {category?.label ?? "-"}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </>
      )}
    </Box>
  );
}

export default BrandView;
