import React from "react";
import { CustomOrderSliderStyled } from "./OrderSliderStyled";

function OrderSlider({ list = [], sliderRef }) {
  return (
    <CustomOrderSliderStyled ref={sliderRef}>
      {list?.map((item, i) => (
        <div className="customSlider__imageDiv" key={i}>
          <img
            className="customSlider__img"
            src={item.imgSrc}
            alt={item.title}
          />
          {item?.title && (
            <div className="customer_details">
              <div className="customSlider__title">{item.name}</div>
              <div className="customSlider__title">{item.title}</div>
            </div>
          )}
        </div>
      ))}
    </CustomOrderSliderStyled>
  );
}

export default OrderSlider;
