// import http from "../http-common";

import { APP_SETTINGS } from "../../config";

// import axios from "axios";
const axios = require("axios");
class AccountDataService {
  registerUser(data, auth) {
    return axios.post(
      `${APP_SETTINGS.API_PATH.ACCOUNT.registerAccount}`,
      data,
      {
        headers: {},
      }
    );
  }

  verifyOtp(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.ACCOUNT.verifyOtp}`, data, {
      headers: {},
    });
  }

  userLogin(data, auth) {
    return axios.post(`${APP_SETTINGS.API_PATH.ACCOUNT.loginAccount}`, data, {
      headers: {},
    });
  }

  updateProfile(data, auth) {
    return axios.post(
      `${APP_SETTINGS.API_PATH.ACCOUNT.profileUpdate}`,
      data.updateProfileData,
      {
        headers: {
          Authorization: "Bearer " + data?.header.mee_too_user.token,
        },
      }
    );
  }

  updateCompanyDetailsReq(data, auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.updateCompanyDetails;
    console.log("updateCompanyDetails::", data, url, auth);
    return axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  getPaymentDetails(auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.getPaymentDetails;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  getPaymentByType(auth, param) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.getPaymentByType.replace(
      "{{param}}",
      param
    );
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  uploadProfileImage(data, auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.uploadProfileImage;
    return axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  deleteProfileImage(auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.deleteProfileImage;
    return axios.delete(url, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  create_fee_order(auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.createRegisterFee;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  verify_register_fee(data, auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.verifyRegisterFee;
    return axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  payment_failed(data, auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.registerFeePaymentFailed;
    return axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  payment_detail(auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.registerFeePaymentDetail;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  // Paid plan service
  get_paid_plan(auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.paidPlanDetail;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  create_paid_service_order(auth, id) {
    let url = APP_SETTINGS.API_PATH.ACCOUNT.createPaidServiceOrder;
    url = url?.replace("{{PLAN_ID}}", id);
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  verify_paid_service_payment(data, auth) {
    const url = APP_SETTINGS.API_PATH.ACCOUNT.paidServiceVerifyPayment;
    return axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }

  fetch_active_plan(auth, id) {
    let url = APP_SETTINGS.API_PATH.ACCOUNT.paidServiceDetails;
    url = url?.replace("{{PLAN_ID}}", id);
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + auth?.mee_too_user.token,
      },
    });
  }
}

export default new AccountDataService();
