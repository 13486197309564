import { Form, Formik } from "formik";

import React from "react";
import MultiLevels from "./MultiLevels";
import { Form1, Form2, Form3, Form4 } from "./Forms";

function SellerForm({
  initVal,
  validateSchema,
  onSubmit,
  formCount,
  setFormCount,
  verifyGST,
  documentVerified,
  verifyBankAccount,
  bankAccVerified,
  hiddenFileInput,
  setSignature,
}) {
  const goToForm = (formNum) => setFormCount(formNum);

  const handleForm1 = (values, setFieldTouched, setFieldError) => {
    if (
      !values.company ||
      !values.address ||
      !values.landmark ||
      !values.city ||
      !values.state ||
      !values.pincode
    ) {
      if (!values.company) {
        setFieldTouched("company", true);
        setFieldError("company", "This field is required.");
      }
      if (!values.address) {
        setFieldTouched("address", true);
        setFieldError("address", "This field is required.");
      }
      if (!values.landmark) {
        setFieldTouched("landmark", true);
        setFieldError("landmark", "This field is required.");
      }
      if (!values.city) {
        setFieldTouched("city", true);
        setFieldError("city", "This field is required.");
      }
      if (!values.state) {
        setFieldTouched("state", true);
        setFieldError("state", "This field is required.");
      }
      if (!values.pincode) {
        setFieldTouched("pincode", true);
        setFieldError("pincode", "This field is required.");
      }
    } else {
      goToForm(2);
    }
  };

  const handleForm2 = (values, setFieldTouched, setFieldError) => {
    if (
      !values.bank_holder_name ||
      !values.bank_account_number ||
      !values.ifsc
    ) {
      if (!values.bank_holder_name) {
        setFieldTouched("bank_holder_name", true);
        setFieldError("bank_holder_name", "This field is required.");
      }
      if (!values.bank_account_number) {
        setFieldTouched("bank_account_number", true);
        setFieldError("bank_account_number", "This field is required.");
      }
      if (!values.ifsc) {
        setFieldTouched("ifsc", true);
        setFieldError("ifsc", "This field is required.");
      }
    } else {
      goToForm(3);
    }
  };

  const handleForm3 = (values, setFieldTouched, setFieldError) => {
    if (!values.gst_number) {
      if (!values.gst_number) {
        setFieldTouched("gst_number", true);
        setFieldError("gst_number", "This field is required.");
      }
    } else {
      goToForm(4);
    }
  };

  return (
    <Formik
      initialValues={initVal}
      validationSchema={validateSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        setFieldValue,
        touched,
        errors,
        setFieldError,
        values,
        setFieldTouched,
      }) => (
        <>
          <MultiLevels
            levels={4}
            activeIndex={formCount - 1}
            handleSelectForm={setFormCount}
          />
          <Form className="sellerForm__container">
            <>
              <div className="w-100">
                {formCount === 1 && (
                  <Form1
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    onClick={() =>
                      handleForm1(values, setFieldError, setFieldTouched)
                    }
                    values={values}
                  />
                )}
                {formCount === 2 && (
                  <Form2
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    onClick={() =>
                      handleForm2(values, setFieldError, setFieldTouched)
                    }
                    bankAccVerified={bankAccVerified}
                    verifyBankAccount={verifyBankAccount}
                    values={values}
                  />
                )}
                {formCount === 3 && (
                  <Form3
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    onClick={() =>
                      handleForm3(values, setFieldError, setFieldTouched)
                    }
                    documentVerified={documentVerified}
                    verifyGST={verifyGST}
                    values={values}
                  />
                )}
                {formCount === 4 && (
                  <Form4
                    hiddenFileInput={hiddenFileInput}
                    setSignature={setSignature}
                  />
                )}
              </div>
            </>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default SellerForm;
