import React from "react";
import styled from "styled-components";

const StyledBox = styled.div`
  background: ${(props) => props.backgroudColor};
  box-shadow: ${(props) => props.boxShadow};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
`;

const Box = ({children, className, ...props}) => {
  return (
    <StyledBox className={className} {...props}>
      {children}
    </StyledBox>
  );
};

StyledBox.defaultProps = {
  boxShadow: "0px 1px 5px 0px #0000004a",
  borderRadius: "9px",
  border: "none",
  backgroudColor: "#FFFFFF",
};

export default Box;
