import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../../redux/services/seller-api-service";
import { FiChevronRight } from "react-icons/fi";
import { Col } from "react-bootstrap";
import { BrandCategoryStyled } from "./brandFormStyled";

function BrandCategorySelectModal({ saveRef = null }, ref) {
  const [cateFetched, setCateFetched] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [topLevelCategories, setTopLevelCategories] = useState([]);
  const [subCategoriesArray, setSubCategoriesArray] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  let auth = useAuth();

  const sellerApiService = useMemo(
    () => new SellerApiService(auth.mee_too_user.token),
    [auth.mee_too_user.token]
  );

  const fetchAllCategory = useCallback(async () => {
    try {
      const { data } = await sellerApiService.getAllCategories(
        auth.mee_too_user.token
      );
      return data;
    } catch (error) {
      return [];
    }
  }, [sellerApiService, auth.mee_too_user.token]);

  const fetchCategoryCount = useCallback(async () => {
    const res = await sellerApiService.getAllCategoriesCount(
      auth.mee_too_user.token
    );
    return res;
  }, [sellerApiService, auth.mee_too_user.token]);

  // fetch category
  useEffect(() => {
    (async () => {
      if (!cateFetched) {
        const res = await fetchCategoryCount();
        const count = Number(res.data); // to get latest catecount and compare with local
        let categories = [];
        if (!localStorage.getItem("categories")) {
          const data = await fetchAllCategory();
          categories = data;
          localStorage.setItem("categories", JSON.stringify(categories));
        } else {
          const localCategories = JSON.parse(
            localStorage.getItem("categories")
          );
          if (localCategories.length !== count) {
            localStorage.removeItem("categories");
            const data = await fetchAllCategory();
            categories = data;
            localStorage.setItem("categories", JSON.stringify(categories));
          } else {
            categories = localCategories;
          }
        }
        setCateFetched(true);
        setAllCategories(categories);
        // format toplevel category
        const topLevelCategories = categories.filter(
          (cat) => cat.parent_id === null
        );
        const topLevelCategoriesFormatted = topLevelCategories?.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setTopLevelCategories(topLevelCategoriesFormatted);
      }
    })();
  }, [cateFetched, fetchAllCategory, fetchCategoryCount]);

  const onChangeParentCategory = (id) => {
    const tempSelectedCategory = allCategories.find((cat) => cat.id === id);
    const subCategories = allCategories.filter((cat) => cat.parent_id === id);
    if (subCategories && subCategories.length > 0) {
      setSubCategoriesArray([subCategories]);
    } else {
      setSubCategoriesArray([]);
    }
    showSaveButton(false);
    setSelectedCategories([tempSelectedCategory]);
  };

  const showSaveButton = useCallback(
    (value) => {
      const display = value ? "block" : "none";
      if (saveRef && saveRef.current) {
        saveRef.current.style.display = display;
      }
    },
    [saveRef]
  );

  const onChangeSubCategory = (id, subCategoryLevel) => {
    const tempSelectedCategory = allCategories.find((cat) => cat.id === id);
    const subCategories = allCategories.filter((cat) => cat.parent_id === id);

    if (subCategories && subCategories.length > 0) {
      showSaveButton(false);
      if (subCategoriesArray.length - 1 === subCategoryLevel) {
        setSubCategoriesArray((prev) => [...prev, subCategories]);
      } else {
        setSubCategoriesArray((prevArray) => {
          prevArray[subCategoryLevel + 1] = subCategories;
          const newArray = prevArray.slice(0, subCategoryLevel + 2);
          return newArray;
        });
      }
    } else {
      showSaveButton(true);
      setSubCategoriesArray((prev) => prev.slice(0, subCategoryLevel + 1));
    }
    setSelectedCategories((prevArray) => {
      prevArray[subCategoryLevel + 1] = tempSelectedCategory;
      const selectedCategories = prevArray.slice(0, subCategoryLevel + 2);
      return selectedCategories;
    });
  };

  useImperativeHandle(ref, () => ({
    getSelectedCategory() {
      return selectedCategories;
    },
  }));

  return (
    <BrandCategoryStyled className="row">
      {selectedCategories.length > 0 && (
        <h6>
          The currently selected:{" "}
          <span className="brandCategory__selected">
            {selectedCategories.map((c, i) => (
              <span key={`selectedCate-${i}`}>{c.name + " > "}</span>
            ))}
          </span>
        </h6>
      )}
      <Col
        sm="6"
        md="4"
        lg="3"
        className="border-end brandCategory__col"
        style={{ maxHeight: "300px", overflow: "scroll" }}
      >
        {topLevelCategories &&
          topLevelCategories.map((tLC, i) => (
            <div
              key={`topLevel-${i}`}
              className="brandCategory__level fw-bolder"
              style={{
                fontFamily: "inherit",
                color: `${
                  selectedCategories.length &&
                  selectedCategories[0].id === tLC.id
                    ? "var(--orange-primary)"
                    : "black"
                }`,
              }}
              type="button"
              onClick={() => {
                onChangeParentCategory(tLC.id);
              }}
            >
              <span>{tLC.name}</span>
              <FiChevronRight className="ms-2" size={20} />
            </div>
          ))}
      </Col>
      {subCategoriesArray.length > 0 &&
        subCategoriesArray.map((subCategories, subCategoryLevel) => {
          return (
            <Col
              sm="6"
              md="4"
              lg="3"
              key={`catLevel-${subCategoryLevel}`}
              className="border-end brandCategory__col"
              style={{ maxHeight: "300px", overflow: "scroll" }}
            >
              {subCategories.map((sC, i) => (
                <div
                  role="button"
                  key={`sC-${subCategoryLevel}${i}`}
                  className="brandCategory__level fw-bolder"
                  style={{
                    fontFamily: "inherit",
                    color: `${
                      selectedCategories.length &&
                      selectedCategories?.[subCategoryLevel + 1]?.id === sC.id
                        ? "var(--orange-primary)"
                        : "black"
                    }`,
                  }}
                  onClick={() => {
                    onChangeSubCategory(sC.id, subCategoryLevel);
                  }}
                >
                  <span>{sC.name}</span>
                  <FiChevronRight className="ms-2" size={20} />
                </div>
              ))}
            </Col>
          );
        })}
    </BrandCategoryStyled>
  );
}

export default forwardRef(BrandCategorySelectModal);
