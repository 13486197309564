// eslint-disable-next-line
import React, {useEffect, useState, useReducer} from "react";
import {Nav, Tab} from "react-bootstrap";
import Paper from "../../compoents/Paper";
import Info from "./info";
import Description from "./description";
import Variation from "./variation";
import Images from "./images";
import Offer from "./offer";
import {
  createProductThunk,
  createProductSelector,
} from "../../redux/slices/product";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../Navigation/Auth/ProvideAuth";
import {PATH, TOAST_STYLE} from "../../config";
import {toast} from "react-toastify";
import {
  dashboardCateSelector,
  setSelectedCategory,
} from "../../redux/slices/category";
import {useRouteMatch} from "react-router-dom";
import SellerApiService from "../../redux/services/seller-api-service";

const EditProduct = () => {
  const [finalData, setFinalData] = useState(null);
  const [tabActiveKey, setTabActiveKey] = useState("info");
  const [tabsDisabled, setTabsDisabled] = useState(false);
  let match = useRouteMatch(PATH.EDIT_INVENTORY_PRODUCT);
  let {productId} = match.params;
  let auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const dispatch = useDispatch();
  const createProductState = useSelector(createProductThunk);

  const {
    // createProductLoading,
    createProductSuccess,
    createProductFailure,
    createProductError,
    createProductMessage,
    createProductData,
  } = useSelector(createProductSelector);
  const {selectedCategory} = useSelector(dashboardCateSelector);
  useEffect(() => {
    if (createProductFailure) {
      toast.error(
        createProductError ? createProductError : "Error While adding product",
        TOAST_STYLE
      );
    }
  }, [createProductSuccess, createProductFailure]);

  useEffect(() => {
    if (createProductData?.id) {
      setTabsDisabled(false);
    } else {
      setTabsDisabled(true);
    }
  }, [createProductData]);

  useEffect(() => {
    (async () => {
      const res = await sellerApiService.getProduct(productId);
      if (res.data) {
        const {product} = res.data;
        localStorage.setItem("product", JSON.stringify(product)); // for edit
        const categoriesRes = await sellerApiService.getAllCategories(
          auth.mee_too_user.token
        );
        const categories = categoriesRes.data;

        dispatch(
          setSelectedCategory(
            categories.find((cat) => cat.id === parseInt(product.category.id))
          )
        );
      }
    })();
  }, []);

  const EditProductHandler = (data) => {
    var formdata = new FormData();
    formdata.append("name", data?.item_name);
    formdata.append("description", data?.description);
    formdata.append("country", data?.country);
    formdata.append("stock", data?.offer_quantity);
    formdata.append("gst", data?.gst);
    formdata.append("weight", data?.weight);
    formdata.append("dimensions", data?.dimensions);
    formdata.append("group_price", data?.group_buy_price);
    formdata.append("selling_price", data?.your_price);
    formdata.append("mrp", data?.retail_price);
    formdata.append("category", selectedCategory?.id);
    formdata.append("price", data?.your_price);
    formdata.append("sku", data?.seller_sku);
    formdata.append("specifications", JSON.stringify(data?.specifications));
    formdata.append("options", "[]");
    let finalPayload = {
      header: auth,
      EditProductFormData: formdata,
    };
    // dispatch(createProductThunk(finalPayload));
  };
  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="info"
        activeKey={tabActiveKey}
        onSelect={(key) => setTabActiveKey(key)}
      >
        <Paper className="p-0" style={{padding: "0px"}}>
          <Nav
            variant="pills"
            className="fund-raise-generic-tabs-nav flex-row text-center button-text flex-nowrap px-5 py-3 overflow-auto"
          >
            <Nav.Item>
              <Nav.Link
                eventKey="info"
                className="cursor-pointer text-grey-light"
              >
                <h5>Info</h5>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="offer"
                className="cursor-pointer text-grey-light"
              >
                <h5>Offer</h5>
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link
                eventKey="description"
                className="cursor-pointer text-grey-light"
              >
                <h5>Description</h5>
              </Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
              <Nav.Link
                eventKey="variations"
                // disabled={tabsDisabled}
                // createProductData
                className="cursor-pointer text-grey-light"
              >
                <h5>Variations</h5>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="images"
                className="cursor-pointer text-grey-light"
              >
                <h5>Images</h5>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-2 border-top">
            <Tab.Pane eventKey="info">
              <Info
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                finalData={finalData}
                setTabActiveKey={setTabActiveKey}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="variations">
              <Variation
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                finalData={finalData}
                setTabActiveKey={setTabActiveKey}
              />
            </Tab.Pane>
            {/*  */}
            <Tab.Pane eventKey="offer">
              <Offer
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                setTabActiveKey={setTabActiveKey}
              />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="description">
              <Description
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                finalData={finalData}
                EditProductHandler={EditProductHandler}
                setTabActiveKey={setTabActiveKey}
              />
            </Tab.Pane> */}
            <Tab.Pane eventKey="images">
              <Images
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                finalData={finalData}
                EditProductHandler={EditProductHandler}
                setTabActiveKey={setTabActiveKey}
              />
              {/* <SegmentsTab /> */}
            </Tab.Pane>
          </Tab.Content>
        </Paper>
      </Tab.Container>
    </div>
  );
};

export default EditProduct;
