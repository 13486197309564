import { configureStore } from "@reduxjs/toolkit";
// import counterReducer from "./features/counterSlice";
import accountReducer from "./slices/account";
import combineProductReducer from "./slices/product";
import combineCateSliceReducer from "./slices/category";
// import category from "./slices/category";
import logger from "redux-logger";
import qc_slice from "./slices/qc_slice";
import combineBrandReducer from "./slices/brand";
import faqSlice from "./slices/faq";
import ticket_slice from "./slices/ticket_slice";
import campaignSlice from "./slices/campaignSlice";

// console.log("store",accountReducer)

const reducer = {
  account: accountReducer,
  product: combineProductReducer.product_listing,
  addProduct: combineProductReducer.add_product,
  create_product: combineProductReducer.create_product,
  uploadProductImage: combineProductReducer.uploadImage,
  category: combineCateSliceReducer.fetchCategorySlice,
  dashboard_category_level: combineCateSliceReducer.dashboardCateSelectedSlice,
  qc: qc_slice,
  brand: combineBrandReducer.brand_sample,
  ticket: ticket_slice,
  [faqSlice.name]: faqSlice.reducer,
  // dashboardCate:category,
  campaign: campaignSlice.reducer,
};

export default configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});
