import React from "react";
import styled from "styled-components";
import BootstrapTable from "react-bootstrap-table-next";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { useEffect } from "react";
import supportService from "../../redux/services/support.service";
import { useState } from "react";

const TicketStyled = styled.div`
  padding: 12px;
  .table_head {
    th {
      font-weight: 500;
      font-size: 14px;
    }
  }
  .action_btn {
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #ddd;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }
  .issue_desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .issue_row {
    font-size: 14px;
  }
`;

function MyTickets() {
  const history = useHistory();
  const auth = useAuth();
  const [ticket, setTicket] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fetchTicketData = async () => {
    try {
      const res = await supportService.fetchTicket({
        token: auth.mee_too_user.token,
      });
      const { status, data } = res.data;
      setLoaded(true);
      if (status === "error") return setTicket([]);
      setTicket(data);
    } catch (error) {
      setLoaded([]);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchTicketData();
  }, []);

  const actionFormatter = (_, row) => {
    return (
      <button
        key={row.id}
        onClick={() => {
          history.push(`/support/ticket/${row.id}`);
        }}
        className="action_btn"
      >
        View
      </button>
    );
  };

  const lastUpdateFormatter = (cell, row) => {
    return <div className="issue_desc">{cell}</div>;
  };

  const descFormatter = (cell, row) => {
    return <div className="issue_desc">{cell}</div>;
  };

  const statusFormatter = (cell, row) => {
    return (
      <span
        className={`badge ${
          cell.toLowerCase() === "close"
            ? "bg-success"
            : ["hold", "block"].includes(cell.toLowerCase())
            ? "bg-warning"
            : "bg-info"
        } text-capitalize`}
      >
        {cell}
      </span>
    );
  };

  const column = [
    {
      dataField: "created",
      text: "Created On",
    },
    {
      dataField: "id",
      text: "Ticket ID",
      sort: true,
    },
    {
      dataField: "ticket_type.name",
      text: "Issue",
      formatter: descFormatter,
    },
    {
      dataField: "first_comment.description",
      text: "Last Update",
      formatter: lastUpdateFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "status",
      order: "desc",
    },
  ];

  if (!loaded) {
    return null;
  }

  return (
    <TicketStyled>
      <BootstrapTable
        keyField="id"
        data={ticket}
        columns={column}
        bordered={false}
        striped
        hover
        condensed
        headerWrapperClasses="table_head"
        defaultSorted={defaultSorted}
        rowClasses="issue_row"
      />
    </TicketStyled>
  );
}

export default MyTickets;
