import React, { useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import styled from "styled-components";
import StyledBox from "../../compoents/Box";
import BulkUploadCatalog from "../../compoents/BulkUploadCatalog/bulkUploadCatalog";
import BulkUploadImages from "../../compoents/BulkUploadCatalog/bulkUploadImages";
import PageTitle from "../../compoents/PageTitle";
import QC from "../../compoents/QC";

const UploadInBulk = () => {
  const qcRef = useRef(null);
  const StyledBreadcrumb = styled(Breadcrumb)`
    color: #606060;
    font-size: 20px;

    /* .breadcrumb-item+.breadcrumb-item::before{
	float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: ">>";
} */
  `;

  const refreshQC = () => {
    qcRef?.current?.refreshQc();
  };
  return (
    <>
      <PageTitle text="Upload Catalogue in Bulk" />

      <StyledBox className={"p-5"}>
        <h5>
          To get your first order quickly, upload more catalogs. We recommend
          uploading 6-7 catalogs.
        </h5>
        <div className="my-4">
          <StyledBreadcrumb>
            <Breadcrumb.Item active>More catalogs</Breadcrumb.Item>
            <Breadcrumb.Item active>Heigher visiblity</Breadcrumb.Item>
            <Breadcrumb.Item active>
              Better chances of getting orders
            </Breadcrumb.Item>
          </StyledBreadcrumb>
        </div>
        {/* <GenericButton variant="primary">
              Upload More catalogs
            </GenericButton> */}
        <BulkUploadCatalog refreshQC={refreshQC} />
        <BulkUploadImages />
      </StyledBox>
      <br />
      <div>
        <QC ref={qcRef} />
      </div>
    </>
  );
};

export default UploadInBulk;
