import React, {useEffect, useState} from "react";
import {useAuth} from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import SingleProductView from "./single-product-view";
import {Alert, Spinner} from "react-bootstrap";
import PageTitle from "../../compoents/PageTitle";

const ReviewsAndRating = () => {
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [productsReviewsDetails, setProductsReviewsDetails] = useState(false);
  //
  useEffect(() => {
    (async () => {
      const productsIds = [];
      const productsWithDetails = [];

      const res = await sellerApiService.getAllProductsWithRatings();
      if (res.data) {
        const {products} = res.data;
        for (const p of products) {
          productsIds.push(p.id);
        }
      }

      for (const pId of productsIds) {
        const res = await sellerApiService.getProductWithRatings(pId);
        if (res.data) {
          const {product} = res.data;
          productsWithDetails.push(product);
        }
      }

      setProductsReviewsDetails(productsWithDetails);
    })();
  }, []);

  return (
    <div>
      <PageTitle text={"Review & Ratings"} />
      {productsReviewsDetails ? (
        productsReviewsDetails.map((product) => (
          <div className="my-3" key={product.id}>
            <SingleProductView product={product} />
          </div>
        ))
      ) : (
        <Alert>
          Loading <Spinner />
        </Alert>
      )}
    </div>
  );
};

export default ReviewsAndRating;
