import { GenericButton } from "../../compoents/Button";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { TOAST_STYLE } from "../../config";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { dashboardCateSelector } from "../../redux/slices/category";
import SellerApiService from "../../redux/services/seller-api-service";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { BiImage } from "react-icons/bi";
import { IoIosClose, IoMdClose } from "react-icons/io";
import {
  createProductSelector,
  productImageUploadThunk,
  resetImageUpload,
  updateCreateProductSuccess,
  updatePreviewThunk,
} from "../../redux/slices/product";
import ProductDataService from "../../redux/services/product.service";
import CustomTooltip from "./CustomTooltip.js";

const StyledTable = styled("div")`
  overflow-x: auto;
  .table td {
    min-width: 135px;
  }
  .mandatory-icon {
    position: absolute;
    top: 0;
    left: 0;
    color: red;
    font-weight: 600;
  }
  .error-row {
    // background-color: rgb(227 1 1 / 47%);
    background-color: #ff000575;
    border: 1px solid #fff;
  }
  .image-info {
    position: absolute;
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    bottom: 0;
    margin-bottom: 4px;
    color: var(--primary-color);
  }
`;

const VariationContainer = styled.div`
  .headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .top-border {
      border-top: 1px solid #ccc;
      width: fit-content;
    }
  }
`;

const Variation = (props, ref) => {
  const {
    finalData,
    setFinalData,
    addProductHandler,
    hideSave = false,
  } = props;
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [errorRow, setErrorRow] = useState([]);
  const [imgPlaceholders, setImgPlaceholders] = useState([1, 2, 3, 4, 5]);

  const hiddenFileInput = useRef(null);
  const { selectedCategory } = useSelector(dashboardCateSelector);

  let auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [category, setCategory] = useState({});

  const [isValidating, setValidating] = useState(false);
  const [tick, setTick] = useState(false);
  let { createProductSuccess } = useSelector(createProductSelector);
  const createProductState = useSelector(createProductSelector);
  let dispatch = useDispatch();

  const addVariation = async () => {
    let opt_types = {};
    if (Object.keys(selectedCategory).length <= 0) {
      const { data } = await sellerApiService.getCategory(selectedCategory.id);
      if (data) {
        const category = data;
        setCategory(category);

        if (category?.option_types) {
          opt_types = category?.option_types?.reduce(
            (accumulator, currentValue) => {
              accumulator[currentValue?.name] = null;
              return accumulator;
            },
            {}
          );
        }
      }
    }
    setSelectedVariations([
      ...selectedVariations,
      {
        sku: "",
        mrp: "",
        parent: 0,
        group_price: 0,
        selling_price: 0,
        stock: 0,
        images: [],
        option_types: opt_types,
      },
    ]);
  };

  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const checkFileSize = (file) => {
    const fileSize = file.size;
    const maxFileSize = 204800; //200Kb = 200*1024
    return fileSize < maxFileSize;
  };

  function uploadFile(e, variationIndex) {
    console.log({ e, variationIndex });
    if (e.target.files.length < 1) {
      toast.error("Please select image.", TOAST_STYLE);
      return;
    }
    let file = e.target.files[0];
    if (!isValidFileUploaded(file)) {
      return toast.error("Image should be of format .png, .jpeg, .jpg.");
    }
    if (!checkFileSize(file)) {
      return toast.error("Image size should not exceed 200Kb.");
    }
    imgPlaceholders.pop();
    setImgPlaceholders(imgPlaceholders);
    let tempVariations = [...selectedVariations];
    if (tempVariations[variationIndex]?.images?.length < 5) {
      tempVariations[variationIndex].images.push(file);
      setSelectedVariations(tempVariations);
    } else {
      toast.error("You can upload only 5 images", TOAST_STYLE);
    }
  }

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  function getDataURI(data) {
    // Check if data parameter is a string or an object
    if (typeof data === "string") {
      // If it is a base64 string, convert it to File Object and return
      return dataURItoBlob(data);
    } else if (typeof data === "object") {
      // If it is an object, check if it is a file object
      return data;
    }
  }

  const validateVariation = useCallback(() => {
    const errorRowCount = [];
    const firstDefaultVariantList = [];
    for (let index in selectedVariations) {
      index = parseInt(index);
      const variant = selectedVariations[index];
      const defaultOption =
        variant.option_types[Object.keys(variant.option_types)[0]]; // first key in option_type is always default option
      if (index === 0) {
        // check for default row
        if (
          !variant.sku ||
          !variant.mrp ||
          !variant.group_price ||
          !variant.selling_price ||
          !variant.stock ||
          !(variant.images.length > 2) ||
          Object.keys(variant.option_types).length < 2
        ) {
          errorRowCount.push(index);
        } else if (
          variant.mrp < variant.selling_price ||
          variant.selling_price < variant.group_price
        ) {
          errorRowCount.push(index);
        } else {
          firstDefaultVariantList.push(defaultOption);
        }
      } else if (
        //check if all rows have input field filled except 1st row
        !variant.sku ||
        !variant.mrp ||
        !variant.group_price ||
        !variant.selling_price ||
        !variant.stock ||
        Object.keys(variant.option_types).length < 2
      ) {
        errorRowCount.push(index);
      } else if (
        variant.mrp < variant.selling_price ||
        variant.selling_price < variant.group_price
      ) {
        errorRowCount.push(index);
      } else if (
        !(variant.images.length > 2) &&
        !firstDefaultVariantList.includes(defaultOption)
      ) {
        errorRowCount.push(index);
      } else if (!(variant.images.length > 2)) {
        errorRowCount.push(index);
      } else if (
        variant.images.length > 2 &&
        !firstDefaultVariantList.includes(defaultOption)
      ) {
        firstDefaultVariantList.push(defaultOption);
      }
    }
    const errorRowList = Array.from(new Set(errorRowCount));
    console.log("errorcount>>>", errorRowList, firstDefaultVariantList);
    setErrorRow(errorRowList);
    // setValidating(false);
    if (errorRowList.length) {
      return true;
    }
    return false;
  }, [selectedVariations]);

  const saveAndContinueV2 = () => {
    setValidating(true);
    const tempVariations = [...selectedVariations];
    const hasError = validateVariation();
    if (hasError) {
      setValidating(false);
      toast.error("Please fill highlighted row.", TOAST_STYLE);
      return;
    }
    setFinalData({ ...finalData, variations: tempVariations });
    setValidating(false);
    const default_variation = tempVariations[0];

    addProductHandler({
      ...finalData,
      offer_quantity: default_variation["stock"],
      group_buy_price: default_variation["group_price"],
      your_price: default_variation["selling_price"],
      retail_price: default_variation["mrp"],
      seller_sku: default_variation["sku"],
      // variations: ,
      options_list: JSON.stringify(default_variation["option_types"]),
    });
    toast.info("Adding Product", TOAST_STYLE);
    return;
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        variationSubmit() {
          setValidating(true);
          const tempVariations = [...selectedVariations];
          const hasError = validateVariation();
          if (hasError) {
            setValidating(false);
            toast.error("Please fill highlighted row.", TOAST_STYLE);
            return;
          }
          setValidating(false);
          toast.info("Adding Product", TOAST_STYLE);
          return tempVariations;
        },
      };
    },
    [selectedVariations, validateVariation]
  );

  useEffect(() => {
    (async () => {
      if (Object.keys(selectedCategory).length > 0) {
        const { data } = await sellerApiService.getCategory(
          selectedCategory.id
        );
        if (data) {
          const category = data;
          setCategory(category);
        }
      }
    })();

    addVariation();
  }, []);

  useEffect(() => {
    (async () => {
      if (createProductSuccess && !tick) {
        dispatch(updateCreateProductSuccess({ value: false }));
        setTick(true);
        if (selectedVariations.length) {
          for (const variation of selectedVariations) {
            variation.parent = createProductState.createProductData.id;
            const variant_obj = {
              parent: createProductState.createProductData.id,
              name: createProductState.createProductData.name,
              description: createProductState.createProductData.description,
              mrp: variation.mrp,
              stock: variation.stock,
              selling_price: variation.selling_price,
              group_price: variation.group_price,
              sku: variation.sku,
              category: createProductState.createProductData.category,
              specifications: "[]",
              options: "[]",
              options_list: variation?.option_types
                ? JSON.stringify(variation?.option_types)
                : "",
            };

            const data = new FormData();
            for (const key in variant_obj) {
              data.append(key, variant_obj[key]);
            }

            const finalPayload = {
              header: auth,
              addProductFormData: data,
            };
            const res = await ProductDataService.createProduct(finalPayload);
            if (res.status == 201) {
              const product = res.data;
              dispatch(resetImageUpload());
              if (variation.images.length) {
                variation.images.map((file, index) => {
                  const formData = new FormData();
                  let fileNameWithSpace = file.name.replace(/\s+/g, "");
                  formData.append("file", file, fileNameWithSpace);
                  formData.append("sequence", index);
                  const finalPayload = {
                    uploadImage: formData,
                    productId: product.id,
                    header: auth,
                  };
                  dispatch(productImageUploadThunk(finalPayload));
                });
              } else {
                const updateData = {
                  updatePreviewData: {},
                  productId: product.id,
                  header: auth,
                };
                dispatch(updatePreviewThunk(updateData));
              }
            }
          }
        }
      }
    })();
  }, [createProductSuccess]);

  const infoDescription = {
    mrp: `Maximum Retail Price (inclusive of all taxes) shall be the maximum price of the product as per the applicable laws and as mentioned on the package of the product.`,
    single_price: `This is the normal/regular price at which you sell single unit on Groupick. This price shall be lower than the Maximum Retail Price (MRP) of the Product.`,
    group_price: `This is a price at which, customer place orders in groups of 2 and get an extra discount for grouping.This price shall be lower than the Maximum Retail Price (MRP) & Single Unit Price of the Product.`,
    image: `You can add minimum 3 and maximum 5 product photos to create a catalog`,
  };

  return (
    <VariationContainer>
      {/* Add a button to add variable */}
      {category?.option_types?.length > 0 && (
        <div>
          <div className="my-3 headingContainer">
            <div>
              <div className="d-flex align-items-baseline">
                <h4 className="mb-0">Add Variations</h4>
                <span className="ms-2 text-muted">
                  (
                  {`${selectedVariations?.length} variation${
                    selectedVariations?.length > 0 ? "'s" : ""
                  }`}
                  )
                </span>
              </div>
              <p className="text-muted mb-0 top-border">
                A minimum of 1 variation is compulsory for all products.
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <GenericButton type="button" onClick={addVariation}>
                Add Variation
              </GenericButton>
            </div>
          </div>
          <Row className="mb-3">
            <Col xs={12}>
              <StyledTable>
                <div className="table-responsive">
                  <table className="table table-borderless table-striped">
                    <thead className="bg-dark text-white">
                      <tr>
                        {category?.option_types?.map((option, index) => (
                          <th key={index}>{option.name}</th>
                        ))}
                        <th>Seller SKU</th>
                        <th>
                          MRP{" "}
                          <CustomTooltip description={infoDescription.mrp} />
                        </th>
                        <th>
                          Single Price{" "}
                          <CustomTooltip
                            description={infoDescription.single_price}
                          />
                        </th>
                        <th>
                          Group Price{" "}
                          <CustomTooltip
                            description={infoDescription.group_price}
                          />
                        </th>
                        <th>Stock</th>
                        <th colSpan="2">
                          Images{" "}
                          <CustomTooltip description={infoDescription.image} />
                        </th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedVariations?.map((variation, variationIndex) => (
                        <tr
                          className={`position-relative ${
                            errorRow.includes(variationIndex) && "error-row"
                          }`}
                          key={variationIndex}
                        >
                          {category?.option_types?.map((option, vIndex) => (
                            <td key={vIndex}>
                              <select
                                className="form-select"
                                onChange={(e) => {
                                  const default_option =
                                    category?.option_types[0]?.name;
                                  const selected_option = e.target.value;
                                  if (default_option === option.name) {
                                    // change only on change of first option
                                    const existingVariant =
                                      selectedVariations.find((val) => {
                                        return (
                                          val.option_types[option.name] ==
                                            selected_option &&
                                          val.images.length > 0
                                        );
                                      });
                                    // check if variant image exists
                                    if (existingVariant) {
                                      if (existingVariant["images"]) {
                                        selectedVariations[variationIndex][
                                          "images"
                                        ] = existingVariant["images"].slice(0);
                                      }
                                      if (existingVariant["stock"]) {
                                        selectedVariations[variationIndex][
                                          "stock"
                                        ] = existingVariant["stock"];
                                      }
                                      if (existingVariant["group_price"]) {
                                        selectedVariations[variationIndex][
                                          "group_price"
                                        ] = existingVariant["group_price"];
                                      }
                                      if (existingVariant["selling_price"]) {
                                        selectedVariations[variationIndex][
                                          "selling_price"
                                        ] = existingVariant["selling_price"];
                                      }
                                      if (existingVariant["mrp"]) {
                                        selectedVariations[variationIndex][
                                          "mrp"
                                        ] = existingVariant["mrp"];
                                      }
                                    }
                                  }
                                  selectedVariations[variationIndex][
                                    "option_types"
                                  ][option.name] = selected_option;
                                  setSelectedVariations([
                                    ...selectedVariations,
                                  ]);
                                }}
                              >
                                <option value=""> Select {option.name}</option>
                                {category?.options?.map(
                                  (opt, i) =>
                                    opt?.type_id == option?.id && (
                                      <option key={i} value={opt?.name}>
                                        {opt.name}
                                      </option>
                                    )
                                )}
                              </select>
                            </td>
                          ))}
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                selectedVariations[variationIndex].sku =
                                  e.target.value;
                                setSelectedVariations([...selectedVariations]);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedVariations[variationIndex].mrp}
                              onChange={(e) => {
                                selectedVariations[variationIndex].mrp =
                                  e.target.value;
                                setSelectedVariations([...selectedVariations]);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                selectedVariations[variationIndex]
                                  ?.selling_price
                              }
                              onChange={(e) => {
                                selectedVariations[
                                  variationIndex
                                ].selling_price = Number(e.target.value);
                                setSelectedVariations([...selectedVariations]);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                selectedVariations[variationIndex]?.group_price
                              }
                              onChange={(e) => {
                                selectedVariations[variationIndex].group_price =
                                  Number(e.target.value);
                                setSelectedVariations([...selectedVariations]);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedVariations[variationIndex]?.stock}
                              onChange={(e) => {
                                selectedVariations[variationIndex].stock =
                                  Number(e.target.value);
                                setSelectedVariations([...selectedVariations]);
                              }}
                            />
                          </td>

                          <td className="d-flex position-relative pb-4">
                            {selectedVariations[variationIndex].images.length <
                              3 && (
                              <div
                                className={`image-info ${
                                  errorRow.includes(variationIndex)
                                    ? "text-dark"
                                    : ""
                                }`}
                              >
                                Upload atleast 3 images
                              </div>
                            )}
                            {[...Array(5)].map((item, index) => {
                              const component = selectedVariations[
                                variationIndex
                              ].images[index] ? (
                                <div className="position-relative flex-grow-1">
                                  <img
                                    src={window.URL?.createObjectURL(
                                      getDataURI(
                                        selectedVariations[variationIndex]
                                          .images[index]
                                      )
                                    )}
                                    alt="bank_proof"
                                    style={{
                                      objectFit: "cover",
                                      height: "50px",
                                      width: "40px",
                                    }}
                                    className="img-thumbnail img-fluid me-2"
                                    key={index}
                                  />
                                  <span
                                    className="position-absolute "
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      padding: "3px",
                                      borderRadius: "50%",
                                      color: "white",
                                      top: "-3px",
                                      left: "0px",
                                      cursor: "pointer",
                                      backgroundColor: "var(--orange-primary)",
                                    }}
                                    onClick={(e) => {
                                      selectedVariations[
                                        variationIndex
                                      ].images.splice(index, 1);
                                      setSelectedVariations([
                                        ...selectedVariations,
                                      ]);
                                    }}
                                  >
                                    <IoIosClose />
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <label
                                    htmlFor={`upload-input-${index}-${variationIndex}`}
                                    className="cursor-pointer m-2"
                                  >
                                    <span
                                      style={{ fontSize: "10px" }}
                                      className="p-1 d-flex justify-content-center align-items-center  border border-2 rounded"
                                    >
                                      <BiImage size={20} />
                                    </span>
                                  </label>
                                  <input
                                    type="file"
                                    hidden
                                    name="img1"
                                    id={`upload-input-${index}-${variationIndex}`}
                                    onChange={(event) => {
                                      console.log({ variationIndex });
                                      uploadFile(event, variationIndex);
                                    }}
                                    ref={hiddenFileInput}
                                  />
                                </div>
                              );
                              return (
                                <div
                                  className="d-inline-block"
                                  style={{ width: "max-content" }}
                                >
                                  {component}
                                </div>
                              );
                            })}
                          </td>
                          <td align="middle">
                            {variationIndex > 0 && (
                              <IoMdClose
                                size={22}
                                className="me-2 cursor-pointer text-muted"
                                onClick={() => {
                                  let tempVariations = [...selectedVariations];
                                  tempVariations.splice(variationIndex, 1);
                                  setSelectedVariations(tempVariations);
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </StyledTable>
            </Col>
          </Row>
          {!hideSave && (
            <div className="my-5 d-flex justify-content-center">
              <GenericButton
                type="button"
                disable={true}
                onClick={saveAndContinueV2}
                className={isValidating && "disabled"}
              >
                Save
              </GenericButton>
            </div>
          )}
        </div>
      )}
      {category?.option_types?.length <= 0 && (
        <div>
          <div className="my-5 text-center">
            No variation available for this product.
          </div>
        </div>
      )}
    </VariationContainer>
  );
};

export default forwardRef(Variation);
