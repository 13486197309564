import { Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {
  AiOutlineClose,
  AiOutlineCheck,
  AiOutlineArrowRight,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { requiredDocumentList } from "./brandConstant";

function BrandList({ list = [] }) {
  // console.log("LLLLL", list);
  // const requiredDocumentList = [
  //   { value: 0, label: "Trademark certificate" },
  //   { value: 1, label: "Brand authorization letter" },
  //   { value: 2, label: "Invoice" },
  // ];
  const commonStyle = {
    headerStyle: {
      background: "#122736",
      color: "#ffffff",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: "fit-content",
    },
    style: {
      fontSize: "18px",
      textAlign: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      border: "none",
      background: "#fff",
    },
  };
  const columns = [
    {
      dataField: "logo",
      text: "",
      classes: "align-middle",
      formatter: (cell, row) => {
        return (
          <Card
            className="text-white p-2"
            style={{
              background: "#FFFFFF",
              border: "1px solid #B8B8B8",
              borderRadius: "8px",
              maxWidth: "100px",
              aspectRatio: "1/1",
            }}
          >
            <Card.Img
              src={row.logo}
              style={{ height: "100%", objectFit: "fill" }}
              alt="Card image"
            />
          </Card>
        );
      },
      style: (col, data, index) => ({
        ...commonStyle.style,
        width: "100px",
      }),
      ...commonStyle,
      headerStyle: (column, colIndex) => ({
        ...commonStyle.headerStyle,
        width: "100px",
      }),
    },
    {
      dataField: "name",
      text: "Name",
      classes: "align-middle",
      ...commonStyle,
    },
    {
      dataField: "website_link",
      text: "Website",
      classes: "align-middle",
      ...commonStyle,
    },
    {
      dataField: "is_active",
      text: "Active",
      classes: "align-middle",
      formatter: (cell, row) => {
        return cell ? (
          <AiOutlineCheck size={24} color="green" />
        ) : (
          <AiOutlineClose size={24} color="red" />
        );
      },
      ...commonStyle,
    },
    {
      dataField: "is_approved",
      text: "Approved",
      classes: "align-middle",
      formatter: (cell, row) => {
        return cell ? (
          <AiOutlineCheck size={24} color="green" />
        ) : (
          <AiOutlineClose size={24} color="red" />
        );
      },
      ...commonStyle,
    },
    {
      dataField: "document",
      text: "Document Type",
      classes: "align-middle",
      formatter(cell) {
        return (
          <span>
            {
              requiredDocumentList.filter((type) => type.value === cell)[0]
                .label
            }
          </span>
        );
      },
      ...commonStyle,
    },
    {
      dataField: "document_proof",
      text: "Proof",
      classes: "align-middle",
      formatter(cell) {
        return (
          <a href={cell} target="_blank" rel="noreferrer">
            view
          </a>
        );
      },
      ...commonStyle,
    },
    {
      dataField: "id",
      text: "Action",
      classes: "align-middle",
      formatter(cell) {
        return (
          <Link to={`/brand/${cell}`}>
            <AiOutlineArrowRight size={24} />
          </Link>
        );
      },
      ...commonStyle,
    },
  ];
  return list.length ? (
    <>
      <BootstrapTable
        className="table-responsive-lg"
        keyField="id"
        data={list}
        columns={columns}
        classes={`custom-table table-responsive-sm}`}
        bodyClasses="custom-table-body"
      />
    </>
  ) : (
    "No data"
  );
}

export default BrandList;
