import React, { useEffect, useState } from "react";
import { MdPermContactCalendar } from "react-icons/md";
import { GenericButton } from "../../compoents/Button";
import { GenericLabel, OutlinedTextFields } from "../../compoents/Forms2";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import { toast } from "react-toastify";

const initFundVal = {
  ifsc: "",
  bank_name: "",
  name: "",
  account_number: "",
};

const BankDetails = ({ user, handleChange }) => {
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const [details, setDetails] = useState(() => {
    let data = Object.keys(user.seller_razorpay).length
      ? user?.seller_razorpay?.razorpay_fund_account[0]
      : null;
    // if (data?.razorpay_account_details?.notes) {
    //   delete data?.razorpay_account_details?.notes;
    // }
    return data?.razorpay_account_details ?? initFundVal;
  });
  const handleSubmit = async () => {
    try {
      const oldFundAccount = Object.keys(user?.seller_razorpay).length
        ? user?.seller_razorpay?.razorpay_fund_account[0]
            ?.razorpay_account_details
        : initFundVal;
      if (
        !details?.name ||
        !details?.ifsc ||
        !details?.bank_name ||
        !details?.account_number
      ) {
        toast.error("Please fill all fields.");
        return;
      }
      if (
        oldFundAccount?.name !== details?.name ||
        oldFundAccount?.ifsc !== details?.ifsc ||
        oldFundAccount?.bank_name !== details?.bank_name ||
        oldFundAccount?.account_number !== details?.account_number
      ) {
        const res = await sellerApiService.updateFundAccount({
          account_id: user?.seller_razorpay?.id ?? undefined,
          newData: details,
          razorpay_contact_id:
            user?.seller_razorpay?.razorpay_contact_id ?? undefined,
        });
        if (res.status === 200) {
          toast.success("Details updated successfully!");
        } else {
          toast.error("Error while updating!");
        }
      } else {
        toast.error("Please update details!");
      }
    } catch (err) {
      console.log("Error-handleSubmit:", err);
    }
  };

  const handleInputChange = (e) => {
    setDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <div>
      <div className="d-flex align-items-center border-botton-grey pb-2">
        <MdPermContactCalendar size={25} className="me-3" />
        <h4>Bank Details</h4>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="my-4">
            <GenericLabel>
              <h6>Your Name</h6>
            </GenericLabel>
            <OutlinedTextFields
              name="name"
              type="text"
              placeholder="Enter your full name"
              onChange={handleInputChange}
              // onBlur={handleBlur}
              value={details?.name}
            />
          </div>
          <div className="my-4">
            <GenericLabel>
              <h6>Account Number</h6>
            </GenericLabel>
            <OutlinedTextFields
              name="account_number"
              type="text"
              placeholder="000011100011100"
              // value={user?.bank_account_number}
              value={details?.account_number}
              // onChange={handleChange}
              onChange={handleInputChange}
              // onBlur={handleBlur}
              // value={values.firstName}
            />
          </div>
          <div className="my-4">
            <GenericLabel>
              <h6>Bank Name</h6>
            </GenericLabel>
            <OutlinedTextFields
              name="bank_name"
              type="text"
              placeholder="Enter your bank name"
              // value={
              //   user?.bank_name ||
              //   user?.bank_details?.name ||
              //   user.bank_details?.bank_name
              // }
              value={details?.bank_name}
              // onChange={handleChange}
              onChange={handleInputChange}
              // onBlur={handleBlur}
              // value={values.firstName}
            />
          </div>
          <div className="my-4">
            <GenericLabel>
              <h6>IFSC Code</h6>
            </GenericLabel>
            <OutlinedTextFields
              name="ifsc"
              type="text"
              placeholder="Enter your IFSC Code"
              // value={user?.ifsc}
              value={details?.ifsc}
              // onChange={handleChange}
              onChange={handleInputChange}
              // onBlur={handleBlur}
              // value={values.firstName}
            />
          </div>
          <GenericButton onClick={() => handleSubmit()}>Save</GenericButton>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
