import React, { useRef, useState } from "react";
import axios from "axios";
import { APP_SETTINGS } from "../../config";
import { toast } from "react-toastify";
import AllImagesModal from "../AllImagesModal";

function BulkUploadImages(props) {
  const inputRef = useRef();
  const { onRefresh } = props;
  const [files, setFiles] = useState(null);
  const [allImages, setAllImages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const uploadImagesURL = APP_SETTINGS.API_PATH.CATALOG.bulkimageupload;
  // const getAllImagesURL = APP_SETTINGS.API_PATH.CATALOG.getAllImages;

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const token = JSON.parse(getCookie("mee_too_user"));

  const handleFile = (e) => {
    setFiles(e.target.files);
  };

  // const getAllImages = async () => {
  //   try {
  //     const {data} = await axios({
  //       // Endpoint to send files
  //       url: getAllImagesURL,
  //       method: "GET",
  //       headers: {
  //         // Add any auth token here
  //         Authorization: `Bearer ${token["token"]}`,
  //         "content-type": "application/json",
  //       },
  //     });
  //     setAllImages(data);
  //     setShowModal(true);
  //   } catch (error) {
  //     toast.error("An error has occured.");
  //   }
  // };

  const handleUpload = async (e) => {
    if (files === null) {
      // getAllImages();
      return;
    }
    toast.info("Uploading image");

    let formData = new FormData();
    //Adding files to the formdata
    // formData.append("file",files);
    for (let i = 0; i < files.length; i++) {
      const fsize = files[i].size;
      const file = Math.round(fsize / 1024);
      // The size of the file.
      if (file >= 1024) {
        toast.error("Image size should not be greater than 1mb.");
        return;
      } else {
        formData.append(`file`, files[i]);
      }
    }
    try {
      const res = await axios({
        // Endpoint to send files
        url: uploadImagesURL,
        method: "POST",
        headers: {
          // Add any auth token here
          Authorization: `Bearer ${token["token"]}`,
          "content-type": "multipart/form-data",
        },
        // Attaching the form data
        data: formData,
      });
      if (res.status >= 200 && res.status < 300) {
        toast.success("Image Uploaded");
        inputRef.current.value = "";
        setAllImages(res.data.data);
        setShowModal(true);
        onRefresh && onRefresh();

        // getting all images
        // getAllImages();
      }
    } catch (err) {
      toast.error("An error occured. Try again.");
    }
  };

  return (
    <div className="container-fluid">
      <h5 className="mt-4 mb-2">Select your images</h5>
      <div className="row">
        <div className="col-md-6">
          <input
            ref={inputRef}
            className="btn btn-warning form-control"
            type="file"
            multiple="multiple" //To select multiple files
            onChange={(e) => handleFile(e)}
          />
        </div>
        <div className="col-md-6">
          <button
            className="btn btn-success form-control"
            onClick={(e) => handleUpload(e)}
          >
            Generate Link
          </button>
        </div>
      </div>
      <AllImagesModal
        show={showModal}
        onHide={() => setShowModal(false)}
        allImages={allImages}
      />
    </div>
  );
}

export default BulkUploadImages;
