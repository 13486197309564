import React from "react";
import styled from "styled-components";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { GenericButton } from "../../../compoents/Button";
import Loader from "../../../compoents/Loader";
import { amountWithSymbol } from "../../../utilities";

const StyledMemberPLanList = styled.div`
  width: 100%;
  h3 {
    margin: 0;
  }
  .membership__container {
    position: relative;
    padding: 24px;
    max-width: 400px;
    border-radius: 16px;
    border: 2px solid #000;
    margin: 24px auto 0;
    overflow: hidden;
    .membership__tag {
      position: absolute;
      background: green;
      font-weight: 500;
      color: #fff;
      top: 0;
      left: 0;
      width: 50%;
      transform: rotate(-45deg) translate(-30%, -50%);
      display: flex;
      text-align: center;
      justify-content: center;
      z-index: 1;
      padding: 4px 12px;
    }
  }
  .membership__amountDiv {
    font-size: 22px;
    text-align: center;
    width: 100%;
    .membership__prefix {
      font-size: 14px;
    }
    .membership__striked {
      font-size: 22px;
      text-decoration: line-through;
      color: gray;
    }
    &:after {
      content: "";
      width: 100%;
      height: 3px;
      background: rgba(0, 0, 0, 0.3);
      display: block;
      max-width: 65%;
      margin: 6px auto 0;
    }
  }
  .membership__points {
    margin: 24px 0 24px;
    font-size: 18px;
    list-style: none;
    padding: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    line-height: 100%;
    gap: 8px;
    font-weight: 500;
    .membership__point {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
    }
  }
  .mambership__submitBtn {
    width: 100%;
    margin: auto;
    border-radius: 8px;
  }
`;

const membershipPoints = [
  "Unlimited product listing",
  "Unlimited orders",
  "Unlimited sales",
  "Unlimited support tickets",
  "Unlimited payouts",
  "Free SMS/Email alerts",
  "Free business reports",
  "Quality Dashboard",
  // "First 20 listing free",
];
function MemberPlanList(props) {
  const {
    paymentStarted,
    onClickHandler,
    showPayButton = false,
    showTag = false,
    origin_price = 0,
    amount = 0,
  } = props;
  return (
    <StyledMemberPLanList>
      <h3 className="text-center fw-bold">Seller lifetime membership plan</h3>
      <div className="membership__container">
        {showTag && <div className="membership__tag">Active</div>}
        <div className="membership__amountDiv">
          <div className="membership__striked">
            {amountWithSymbol(origin_price)}/-
          </div>
          <div className="fw-bold">
            {amountWithSymbol(amount)}
            <span className="fw-normal membership__prefix">/(Lifetime)</span>
          </div>
        </div>
        <ul className="membership__points">
          <div className="mb-2">Includes :</div>
          {membershipPoints?.map((point, i) => (
            <li className="membership__point" key={`point-${i}`}>
              <AiOutlineCheckCircle className="mambership__checkIcon" />
              {point}
            </li>
          ))}
        </ul>
        {showPayButton && (
          <GenericButton
            className="mambership__submitBtn"
            onClick={onClickHandler}
            disabled={paymentStarted}
          >
            {!paymentStarted ? (
              "Activate now"
            ) : (
              <>
                <Loader />
                <Loader />
                <Loader />
              </>
            )}
          </GenericButton>
        )}
      </div>
    </StyledMemberPLanList>
  );
}

export default MemberPlanList;
