import axios from "axios";
import { APP_SETTINGS } from "../../config";

class CampaignService {
  create_campaign(data, token) {
    const url = APP_SETTINGS.API_PATH.CAMPAIGN.create_campaign;
    return axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  get_campaigns(token) {
    const param = new URLSearchParams();
    param.append("t", Date.now());
    const url = APP_SETTINGS.API_PATH.CAMPAIGN.fetch_campaign + "?" + param;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
}

export default CampaignService;
