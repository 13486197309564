const slFormatter = (cell, row, rowIndex) => {
  return rowIndex + 1;
};

const budgetFormat = (cell, row, rowIndex) => {
  return cell ? `Rs. ${cell}` : "-";
};
const statusFormatter = (cell, row, rowIndex) => {
  switch (cell) {
    case "active":
    case "restart":
      return "Live";
    case "schedule":
      return "Scheduled";
    case "pause":
      return "Paused";
    case "complete":
      return "Complete";
    default:
      return cell;
  }
};

export const columns = [
  {
    dataField: "",
    text: "SL No.",
    formatter: slFormatter,
    headerClasses: "w-140",
  },
  {
    dataField: "name",
    text: "Campaign Name",
    classes: "single-line",
    headerClasses: "w-140",
  },
  {
    dataField: "budget",
    text: "Budget",
    formatter: budgetFormat,
    headerClasses: "w-140",
  },
  {
    dataField: "utilized",
    text: "Utilized Budget",
    formatter: budgetFormat,
    headerClasses: "w-140",
  },
  {
    dataField: "viewsClick.views",
    text: "Views",
    headerClasses: "w-140",
  },
  {
    dataField: "viewsClick.click",
    text: "Clicks",
    headerClasses: "w-140",
  },
  {
    dataField: "orders",
    text: "Orders",
    headerClasses: "w-140",
  },
  {
    dataField: "revenue",
    text: "Revenue",
    headerClasses: "w-140",
  },
  {
    dataField: "roi",
    text: "ROI",
    headerClasses: "w-140",
  },
  {
    dataField: "CPC",
    text: "CPC",
    headerClasses: "w-140",
  },
  {
    dataField: "status",
    text: "Action",
    formatter: statusFormatter,
    headerClasses: "w-140",
  },
];
