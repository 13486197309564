import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BrandDataService from "./../services/brand-api-services";

const brandSampleInitialState = {
  brandFetchLoading: true,
  brandFetchSuccess: false,
  brandFetchFailure: false,
  brandFetchError: null,
  brandFetchData: null,
  brand: [],
};

const BrandSampleCreateThunk = createAsyncThunk(
  "brandSample/create",
  async (
    dataObj,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const res = await BrandDataService.addBrandSample(dataObj);
      const { data } = res;
      if (data?.status === "error") {
        throw rejectWithValue(data?.msg);
      }
      return fulfillWithValue();
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

const BrandWithOutPagination = createAsyncThunk(
  "brand/withoutPaginate",
  async (
    dataObj,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const res = await BrandDataService.getBrandWithoutPagination(dataObj);
      const { data } = res;
      if (data?.status === "error") {
        throw rejectWithValue(data?.msg);
      }
      return fulfillWithValue(res);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

const BrandsampleSlice = createSlice({
  name: "brand",
  initialState: brandSampleInitialState,
  reducers: {
    addBrand(state, action) {
      const { payload } = action;
      state.brand = payload;
    },
  },
  extraReducers: {
    [BrandSampleCreateThunk.pending]: (state, action) => {
      state.brandFetchLoading = false;
      state.brandFetchSuccess = false;
      state.brandFetchFailure = false;
      state.brandFetchError = null;
      state.brandFetchData = null;
    },
    [BrandSampleCreateThunk.fulfilled]: (state, action) => {
      state.brandFetchLoading = false;
      state.brandFetchSuccess = false;
      state.brandFetchFailure = false;
      state.brandFetchError = null;
      state.brandFetchData = null;
    },
    [BrandSampleCreateThunk.rejected]: (state, action) => {
      state.brandFetchLoading = false;
      state.brandFetchSuccess = false;
      state.brandFetchFailure = false;
      state.brandFetchError = null;
      state.brandFetchData = null;
    },
    // Brand Without paging
    [BrandWithOutPagination.pending]: (state, action) => {
      state.brandFetchLoading = false;
      state.brandFetchSuccess = false;
      state.brandFetchFailure = false;
      state.brandFetchError = null;
      state.brand = [];
    },
    [BrandWithOutPagination.fulfilled]: (state, action) => {
      state.brandFetchLoading = false;
      state.brandFetchSuccess = false;
      state.brandFetchFailure = false;
      state.brandFetchError = null;
      state.brand = action?.payload?.data;
    },
    [BrandWithOutPagination.rejected]: (state, action) => {
      state.brandFetchLoading = false;
      state.brandFetchSuccess = false;
      state.brandFetchFailure = false;
      state.brandFetchError = null;
      state.brand = [];
    },
  },
});

const brandSelector = (state) => {
  return state.brand;
};
export { BrandSampleCreateThunk, brandSelector, BrandWithOutPagination };
export const { addBrand } = BrandsampleSlice.actions;
const combineBrandReducer = { brand_sample: BrandsampleSlice.reducer };
export default combineBrandReducer;
