import {Button} from "react-bootstrap";
// import { useState } from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background: ${({background}) => background};
  border: ${({border}) => border};
  /* box-shadow: 2px 3px 6px rgba(221, 37, 88, 0.2), -2px 0px 6px rgba(221, 37, 88, 0.2); */
  border-radius: ${(props) =>
    props.borderRadius || props.borderradius
      ? props.borderradius ?? props.borderRadius
      : "9px"};
  width: ${({width}) => width};
  height: ${({height}) => height};
  color: ${(color) => (color ? color : "#FF4D00")};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
  /* height: 57px; */
  && {
    :active,
    :active:not(:disabled):not(.disbled),
    :hover,
    :focus {
      background: ${({background, bghover}) =>
        background || bghover ? bghover ?? background : "none"};
      border-color: ${({borderColor}) => borderColor};
      border: ${({border}) => border};
      box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
      color: ${({color, colorhover}) =>
        color || colorhover ? colorhover ?? color : ""};
    }
  }
`;

const ButtonComponent = ({className, children, ...rest}) => {
  // console.log("background",rest)
  // let [active, setActive] = useState(false);
  // const { size, active } = props;
  // let handleClick = () => setActive(true);
  return (
    <StyledButton className={className} {...rest}>
      {children}
    </StyledButton>
  );
};

export default ButtonComponent;
