import styled from "styled-components";

const StyledContainer = styled.div`
  height: 100vh;
  width: 100vw;
  min-height: 100dvh;
  height: 100dvh;
  overflow-x: hidden;
  padding: 0 56px 48px;
  display: flex;
  flex-direction: column;
  .seller__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgb(20, 27, 70);
      top: 0;
      left: 0;
      opacity: 0.88;
    }
  }
  .seller__navbar {
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    height: 96px;
    .seller__logo {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
    .navbar-brand {
      padding: 0;
      margin: 0;
    }
    .seller_btn:hover {
      background: none;
      font-weight: 500;
    }
  }
  .seller__container {
    height: calc(100vh - 96px);
    z-index: 999;
    display: flex;
    h1,
    p {
      margin: 0;
      padding: 0;
    }
    .seller__content {
      height: 100%;
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      flex: 1;
      margin: auto;
      .seller__heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        color: #fff;
        .seller__showOnLowResolution {
          display: none;
        }
        h1 {
          font-size: 48px;
          font-weight: 700;
          line-height: 100%;
        }
        p {
          width: 100%;
          margin: 12px 0;
          font-size: 20px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          &::after {
            content: "";
            width: 25%;
            height: 2px;
            margin: 16px 0;
            background: #fff;
          }
        }
      }
    }
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
    align-items: center;
    width: 100%;
    margin: auto;
    .seller__navbar {
      width: 100%;
    }
    .seller__container {
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    .seller__container {
      margin: auto 0;
      height: fit-content;
      .seller__content {
        flex-direction: column;
        .seller__heading {
          .seller__hideOnLowResolution {
            display: none;
          }
          .seller__showOnLowResolution {
            display: block;
            font-size: 32px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
  @media (max-width: 748px), (max-height: 600px) {
    padding: 10px 32px 32px;
    .seller__navbar {
      height: fit-content;
      .seller__logo {
        width: 70px;
        height: 70px;
      }
    }
    .seller__container {
      margin: auto 0;
      height: fit-content;
      .seller__content {
        .register__heading {
          display: none;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .seller__container {
      margin-top: 16px;
    }
  }
`;

const SellerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  flex-direction: column;
  max-width: 500px;
  .sellerForm__levels {
    width: 100%;
    margin-bottom: 16px;
    height: 40px;
    .sellerForm__levelList {
      height: 100%;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      justify-content: space-between;
      .sellerForm__level {
        height: 100%;
        width: calc(100% / 4);
        position: relative;
        .sellerForm__circle {
          background: rgba(255, 255, 255, 0.22);
          border-radius: 50%;
          height: 100%;
          aspect-ratio: 1/1;
          margin: auto;
          display: flex;
          z-index: 2;
          position: relative;
        }
        .sellerForm__tickIcon {
          margin: auto;
        }
        &:not(:last-child)::after {
          content: "";
          height: 2px;
          width: calc(100% - 40px);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateX(50%);
          background: rgba(255, 255, 255, 0.22);
          z-index: 1;
        }
      }
      .sellerForm__levelComplete {
        .sellerForm__circle {
          background: rgba(255, 255, 255, 1);
        }
        &:not(:last-child)::after {
          background: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .sellerForm__container {
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    padding: 24px 48px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.22);
    border: 1px solid rgba(255, 255, 255, 0.22);
    color: #fff;
    .sellerForm__text {
      color: #fff;
      font-weight: 500;
      background-color: rgba(255, 255, 255, 0.25);
      box-shadow: none;
      outline: none;
      border-radius: 24px;
      height: 40px;
      &::placeholder {
        color: #fff;
        font-weight: 400;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        color: #fff;
        font-weight: 400;
      }
      &::-ms-input-placeholder {
        color: #fff;
        font-weight: 400;
      }
    }
    .sellerForm__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.01rem;
      border-radius: 32px;
      min-height: 40px;
      margin-left: auto;
      &:hover,
      &:focus {
        background: none;
        border-color: var(--primary-color);
      }
      &:disabled {
        background: rgb(164, 167, 186);
        cursor: not-allowed;
        border: none;
      }
    }
    .sellerForm__verify {
      color: rgb(67, 211, 38);
      .sellerForm__verifyIcon {
        background: #fff;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
    .documentType__Wrapper {
      margin-bottom: 16px;
      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      .documentType__formControl {
        display: flex;
        gap: 16px;
        .documentType__textWrapper {
          display: flex;
          align-items: center;
          gap: 4px;
          label {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
    .sellerForm__errorMsg {
      margin: 0 !important;
      color: rgb(255, 193, 7) !important;
      width: 100%;
    }
    .formField__mainDiv {
      margin-bottom: 16px;
    }
    .sellerForm--verifyBtn {
      background: blue;
      color: #fff;
      border: none;
      &:hover,
      &:focus {
        background: blue;
      }
    }
    .sellerForm__actionForm2Btn {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      button {
        margin-left: 0;
      }
    }
    .sellerForm__signatureMain {
      width: 100%;
      .sellerForm__uploadSection {
        margin-bottom: 32px;
      }
      .sellerForm__fileInput {
        width: 50%;
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.25);

        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        padding: 12px 8px;
        flex-direction: row;
        .sellerForm__selectedFile {
          flex: 1;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }
      }
    }
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    .sellerForm__container {
      padding: 16px;
      .row__group {
        flex-direction: column;
        gap: 0 !important;
      }
      .sellerForm__actionForm2Btn {
        margin-top: 32px;
        flex-direction: column;
        gap: 8px;
      }
      .sellerForm__btn {
        width: 100%;
      }
      .sellerForm__signatureMain {
        .sellerForm__uploadSection {
          width: fit-content;
          margin: 0 auto 32px;
          text-align: center;
          .sellerForm__fileInput {
            margin: 0;
            width: 100%;
            height: 48px;
            svg {
              height: 24px;
            }
          }
        }
      }
    }
  }
`;

const SellerSuccessFormStyled = styled.div`
  z-index: 2;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 666px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 48px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  gap: 24px;
  border-radius: 32px;
  position: relative;
  .success__icon {
    position: absolute;
    width: 15em;
    height: 15em;
    bottom: 0;
    opacity: 0.5;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .success--leftIcon {
    left: 0;
    transform: translate(-50%, 50%);
  }
  .success--rightIcon {
    right: 0;
    transform: translate(50%, 50%) scaleX(-1);
  }
  .sellerSuccessForm__heading {
    font-size: 48px;
    font-weight: 400;
    color: var(--primary-color);
    margin: 0;
  }
  .sellerSuccessForm__msg {
    font-size: 24px;
    max-width: 50%;
    font-weight: 500;
    color: #fff;
  }
  .sellerSuccessForm__btn {
    border-radius: 32px;
    z-index: 2;
    &:hover {
      background: none;
    }
  }
  @media (max-width: 576px), (max-height: 576px) {
    padding: 24px;
    gap: 16px;
    max-width: 500px;
    .success__icon {
      display: none;
    }
    .sellerSuccessForm__heading {
      font-size: 32px;
    }
    .sellerSuccessForm__msg {
      font-size: 20px;
    }
  }
`;

export { StyledContainer, SellerContainer, SellerSuccessFormStyled };
