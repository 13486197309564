import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ticket_detail: null,
  loaded: false,
};
const ticket_slice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    updateTicket(state, action) {
      return { ...state, ...action.payload };
    },
    reset(state) {
      state = initialState;
    },
    updateChat(state, action) {
      state.ticket_detail.chat_ticket.push(action.payload);
      return state;
    },
  },
});

export const { updateTicket, reset, updateChat } = ticket_slice.actions;
export default ticket_slice.reducer;

const ticketSelector = (state) => state.ticket;
const chatSelector = (state) => {
  const detail = state.ticket?.ticket_detail;
  return { chatList: detail?.chat_ticket ?? [], user: detail?.user ?? "" };
};
export { ticketSelector, chatSelector };
