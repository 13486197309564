import React from "react";
import { Button } from "react-bootstrap";

const GenericButton = (props) => {
  let { children, className } = props;
  return (
    <Button
      variant="secondary"
      className={`fund-raise-generic-button ${className ? className : ""}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default GenericButton;
