import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import GenericTable from "../../compoents/Table";
import { BiSearch } from "react-icons/bi";
import { AiOutlineDown, AiOutlinePlusSquare } from "react-icons/ai";
import GenericButton from "../../compoents/Button/simpleButton";
const SegmentTab = () => {
  const products = [
    {
      id: 1,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
    {
      id: 2,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
    {
      id: 3,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
    {
      id: 4,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
    {
      id: 5,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
    {
      id: 6,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
    {
      id: 7,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
    {
      id: 8,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
    {
      id: 9,
      name: "Gala Attendence",
      created: "24th Aug, 2021",
    },
  ];
  const columns = [
    {
      dataField: "name",
      classes: "align-middle",
      text: "NAME",
    },
    {
      dataField: "created",
      classes: "align-middle",
      text: "CREATED",
    },
  ];

  const onSelectAll = (isSelected) => {
    if (isSelected) {
      return products.map((row) => row.id);
    } else {
      return [];
    }
  };

  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
    onSelectAll: onSelectAll,
  };
  return (
    <div>
      <div className="row d-flex align-items-center my-3">
        <div className="col-md-2">
          <div>
            <h5 className="text-h6 text-tags-pills-text-grey ">15 Tags</h5>
          </div>
        </div>
        <div className="col-md-10">
          <div className="d-flex justify-content-end flex-wrap">
            <DropdownButton
              size="lg"
              className="fund-raise-dropdown filter-drop-down"
              title={
                <div className="d-flex justify-content-between align-items-center">
                  <span>Filter</span> <AiOutlineDown color="#1A95B0" />
                </div>
              }
              id={`dropdown-button-drop`}
              key={1}
            >
              <Dropdown.Item className="w-100" eventKey="1">
                Another action
              </Dropdown.Item>
            </DropdownButton>
            <GenericButton className="button-text font-weight-bold bg-grey-icon-button text-black mx-3">
              <BiSearch size={22} />
            </GenericButton>
            <GenericButton className="button-text font-weight-bold bg-main-accent w-180 my-2 m-sm-0 d-flex justify-content-around align-items-center">
              <span>
                <AiOutlinePlusSquare color="white" size={22} />
              </span>
              <span>Create Segments</span>
            </GenericButton>
          </div>
        </div>
      </div>

      <GenericTable
        className="table-responsive-xs"
        selectRow={selectRowProp}
        keyField="id"
        data={products}
        columns={columns}
      />
    </div>
  );
};

export default SegmentTab;
