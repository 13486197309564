import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../compoents/PageTitle";
import Loader from "../../compoents/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { APP_SETTINGS } from "../../config";
import BulkUploadImages from "../../compoents/BulkUploadCatalog/bulkUploadImages";
import { FiCopy } from "react-icons/fi";
import { GenericButton } from "../../compoents/Button";
import { GalleryStyled } from "./GalleryStyle";
import { AiOutlineArrowUp } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import { useSelector } from "react-redux";
import { allowedForProductListingSelector } from "../../redux/slices/account";
import MakeFeePayment from "../../compoents/common/MakeFeePayment";
function Gallery() {
  const isListingAllowed = useSelector(allowedForProductListingSelector);
  const [showUploadBtn, setShowUploadBtn] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const getAllImagesURL = APP_SETTINGS.API_PATH.CATALOG.getAllImages;

  const getAllImages = useCallback(async () => {
    const getCookie = (cname) => {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };

    const token = JSON.parse(getCookie("mee_too_user"));

    try {
      const { data } = await axios({
        url: getAllImagesURL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token["token"]}`,
          "content-type": "application/json",
        },
      });
      setAllImages(data.data);
    } catch (error) {
      toast.error("An error has occured.");
    }
  }, [getAllImagesURL]);

  useEffect(() => {
    getAllImages();
  }, [getAllImages]);

  if (isListingAllowed === null) return null;

  return (
    <GalleryStyled>
      <PageTitle text="Gallery" />
      {isListingAllowed ? (
        <>
          {!showUploadBtn ? (
            <GenericButton
              className="gallery__newBtn"
              type="button"
              onClick={() => setShowUploadBtn(true)}
            >
              <AiOutlineArrowUp size={18} />
              Upload
            </GenericButton>
          ) : (
            <MdClear
              size={24}
              color="#FF4D00"
              className="gallery__clearIcon"
              onClick={() => setShowUploadBtn(false)}
            />
          )}
          {showUploadBtn && (
            <div className="gallery__uploadSection mb-4">
              <BulkUploadImages onRefresh={getAllImages} />
            </div>
          )}

          {allImages?.length ? (
            <div className="gallery__images">
              {allImages?.map((img, index) => {
                return (
                  <div className="gallery__image" key={`img-${index}`}>
                    <div className="px-2 border border-3 rounded">
                      <div className="d-flex">
                        <div className="border-end border-3 p-3 w-30">
                          <img
                            src={img.url}
                            alt=""
                            width="60"
                            height="65"
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                        <div className="m-auto p-2 w-40 one-line-text">
                          {img.name}
                          {/* {img.name.substring(0, 25)}
                      {img.name.length > 25 && "....."} */}
                        </div>
                        <div className="d-flex justify-content-center align-items-center p-3 border-start border-3 w-30">
                          <button
                            className="btn"
                            onClick={() => {
                              navigator.clipboard.writeText(img.name);
                              toast.success("Link Copied.");
                            }}
                          >
                            <FiCopy size={18} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <Loader />
              <Loader />
              <Loader />
            </>
          )}
        </>
      ) : (
        <MakeFeePayment />
      )}
    </GalleryStyled>
  );
}

export default Gallery;
