import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const HowToGrowStyle = styled.section`
  // .working {
  .container {
    background-color: #f8f8f8;
    display: grid;
    grid-template-columns: repeat(5, minmax(2.5rem, 1fr));
    row-gap: 10px;
    padding: 40px 25px;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .work-card {
    position: relative;
    h6 {
      font-size: 18px;
      font-weight: bolder;
    }
    .divider__line {
      height: 2px;
      width: 70%;
      background-color: var(--orange-primary);
      margin-left: 1rem;
    }
  }
  .work-info {
    width: 70%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  @media (max-width: 1200px) {
    .container {
      min-width: fit-content;
      margin: 0 3rem;
    }
  }
  .hide-on-large {
    display: none;
  }
  @media (max-width: 670px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px !important;
    width: 100%;
    .hide-on-large {
      display: none;
    }
    .container {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 0;
      padding: 40px 16px;
      margin: 30px 24px;
      .work-card {
        display: flex;
        align-items: baseline;
        .card-points {
          flex: 1;
          flex-direction: column;
          height: 100%;
          position: absolute;
        }
        .divider__line {
          height: 100%;
          width: 2px;
          margin: auto;
        }
        .work-info {
          flex: 3;
          padding: 0 0 16px 36px;
          ul {
            margin: 0;
          }
        }
      }
    }
  }
  // }
`;
function HowToGrow() {
  return (
    <HowToGrowStyle className="working">
      <h3 className="text-center fw-bolder mb-md-4">
        How to grow business with Groupick?
      </h3>
      <Container className="rounded-3">
        <article className="work-card">
          <div className=" d-flex card-points align-items-center">
            <span
              style={{
                minWidth: "25px",
                minHeight: "25px",
                backgroundColor: "var(--orange-primary)",
                aspectRatio: "1/1",
              }}
              className="d-flex justify-content-center align-items-center rounded-circle text-white"
            >
              1
            </span>
            <span className="rounded-2 divider__line" />
          </div>
          <div className="work-info">
            <h6 className="my-md-3 my-sm-0">Create Account</h6>
            <p className="mb-1">All you need is</p>
            <ul>
              <li>GSTIN</li>
              <li>Bank Account</li>
            </ul>
          </div>
        </article>
        <article className="work-card">
          <div className=" d-flex card-points align-items-center">
            <span
              style={{
                minWidth: "25px",
                minHeight: "25px",
                backgroundColor: "var(--orange-primary)",
                aspectRatio: "1/1",
              }}
              className="d-flex justify-content-center align-items-center rounded-circle text-white"
            >
              2
            </span>
            <span className="rounded-2 divider__line" />
          </div>
          <div className="work-info">
            <h6 className="my-md-3 my-sm-0">List Products</h6>
            <p className="mb-1">
              List the products you want to sell in your seller panel
            </p>
          </div>
        </article>
        <article className="work-card">
          <div className=" d-flex card-points align-items-center">
            <span
              style={{
                minWidth: "25px",
                minHeight: "25px",
                backgroundColor: "var(--orange-primary)",
                aspectRatio: "1/1",
              }}
              className="d-flex justify-content-center align-items-center rounded-circle text-white"
            >
              3
            </span>
            <span className="rounded-2 divider__line" />
          </div>
          <div className="work-info">
            <h6 className="my-md-3 my-sm-0">Get Orders</h6>
            <p className="mb-1">
              Start getting orders from crores of Indians actively shopping on
              our platform.
            </p>
          </div>
        </article>
        <article className="work-card">
          <div className=" d-flex card-points align-items-center">
            <span
              style={{
                minWidth: "25px",
                minHeight: "25px",
                backgroundColor: "var(--orange-primary)",
                aspectRatio: "1/1",
              }}
              className="d-flex justify-content-center align-items-center rounded-circle text-white"
            >
              4
            </span>
            <span className="rounded-2 divider__line" />
          </div>
          <div className="work-info">
            <h6 className="my-md-3 my-sm-0">Lowest Cost Shipping</h6>
            <p className="mb-1">
              Products are shipped to customers at lowest costs
            </p>
          </div>
        </article>
        <article className="work-card">
          <div className=" d-flex card-points align-items-center">
            <span
              style={{
                minWidth: "25px",
                minHeight: "25px",
                backgroundColor: "var(--orange-primary)",
                aspectRatio: "1/1",
              }}
              className="d-flex justify-content-center align-items-center rounded-circle text-white"
            >
              5
            </span>
            <span className="rounded-2 divider__line hide-on-large" />
          </div>
          <div className="work-info">
            <h6 className="my-md-3 my-sm-0">Receive Payments</h6>
            <p className="mb-1">
              Payments are deposited directly to your bank account following a
              7- day payment cycle.
            </p>
          </div>
        </article>
      </Container>
    </HowToGrowStyle>
  );
}

export default HowToGrow;
