import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import styled from "styled-components";
import { getOrderStatus } from "../../utilities";
import moment from "moment";
import ProductCardButtons from "./ProductCardButtons";

const StyledTable = styled.div`
  .tableWrraperClass {
    max-width: 100%;
    overflow: auto;
    .w-140 {
      width: 140px;
    }
    .mx-w-180 {
      width: 180px;
    }
    .w-80 {
      width: 80px;
    }
    table {
      tbody {
        background: rgb(255, 255, 255);
      }
      border-collapse: collapse;

      .tableHeader {
        background: gray;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        .titleHeadClass {
          width: 447px;
        }
      }
      .rowClasses {
        td {
          font-size: 14px;
          font-weight: 500;
          vertical-align: middle;
          text-align: center;
          .bodyTitle {
            display: flex;
            gap: 15px;
            width: 447px;
            .imageWrapper {
              display: flex;
              alignitems: center;
              width: 15%;
              .productImage {
                objectfit: cover;
              }
            }
            .bodyTitleDesc {
              width: 85%;
              display: flex;
              align-items: center;
              margin-bottom: 0;
            }
          }
          .actionBtn {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            button {
              width: 100% !important;
              border-radius: 4px !important;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
  .selectHeader {
    width: 100px;
  }
`;

function OrderTable({
  data,
  orderStatus = "onHold",
  selectable = false,
  handleSelect = undefined,
  selected = [],
  disabledBtnList = undefined,
}) {
  const columns = [
    ...(selectable
      ? [
          {
            dataField: "order.id",
            text: "Select Order",
            headerClasses: () => "selectHeader",
            formatter: (cell, row, rowIndex, formatExtraData) => {
              return (
                <input
                  type="checkbox"
                  style={{ cursor: "pointer", width: 20, height: 20 }}
                  checked={selected[row.order.id]}
                  onChange={() => handleSelect(row.order.id)}
                />
              );
            },
          },
        ]
      : []),
    {
      dataField: "product",
      text: "Product details",
      headerClasses: () => "titleHeadClass",
      formatter: (cell, row) => {
        return (
          <div className="bodyTitle">
            <div className="imageWrapper">
              <img className="w-100 productImage" src={row?.image} alt="" />
            </div>
            <p className="bodyTitleDesc">{cell}</p>
          </div>
        );
      },
    },
    {
      dataField: "order.order_ref",
      text: "Sub order ID",
      headerClasses: () => "w-140 text-center",
      formatter: (cell) => `#${cell}`,
    },
    {
      dataField: "order.sku",
      headerClasses: () => "w-140 text-center",
      text: "SKU ID",
    },
    {
      dataField: "order",
      text: "Order Detail",
      headerClasses: () => "w-140 text-center",
      formatter: (_, row) => {
        if (!row?.order?.details) {
          return <div>N/A</div>;
        }
        return (
          <div>
            {Object.keys(row?.order?.details)?.map((detail) => (
              <React.Fragment key={detail}>
                <strong>{detail} : </strong>
                {row?.order?.details?.[detail]
                  ? row?.order?.details?.[detail]
                  : " N/A "}
                <br />
              </React.Fragment>
            ))}
          </div>
        );
      },
    },
    {
      dataField: "order.status",
      text: "Delivery Detail",
      headerClasses: () => "w-140 text-center",
      formatter: (_, row) => {
        const { order } = row;
        return getOrderStatus(
          orderStatus,
          order?.created_at,
          order?.delivery_data
        );
      },
    },
    {
      dataField: "order.quantity",
      text: "Quantity",
      headerClasses: () => "w-80 text-center",
    },
    {
      dataField: "order.purchase_mode",
      text: "Type",
      headerClasses: () => "w-80 text-center",
    },
    {
      dataField: "order.created_at",
      text: "Order Date",
      headerClasses: () => "w-140 text-center",
      formatter: (cell) => moment(cell).format("DD MMM YYYY"),
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: () => "w-140 mx-w-180 text-center",
      formatter: (_, row) => {
        return (
          <div className="actionBtn">
            <ProductCardButtons
              status={row?.order?.status}
              orderId={row?.order?.id}
              disabledBtnList={disabledBtnList}
            />
          </div>
        );
      },
    },
  ];
  return (
    <StyledTable>
      <BootstrapTable
        keyField="order.id"
        data={data}
        columns={columns}
        wrapperClasses="tableWrraperClass"
        rowClasses="rowClasses"
        headerClasses="tableHeader"
        noDataIndication="No order found"
        // striped
        // condensed
        hover
      />
    </StyledTable>
  );
}

export default OrderTable;
