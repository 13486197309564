import React from "react";

import { FiBell } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { Link } from "react-router-dom";
import DashboardWallet from "./DashboardWallet";

const PageTitle = ({
  text,
  showNotification = false,
  customClassName = "",
}) => {
  return (
    <div className={`d-flex justify-content-between mb-4 ${customClassName}`}>
      <h4>{text}</h4>
      {showNotification && (
        <div className="d-flex align-items-center">
          {" "}
          {/* <IconContainer className="nav-item dropdown noti-dropdown d-none d-md-flex align-items-center"> */}
          {/* <div className="icon-container-wrapper d-flex align-items-center"> */}
          <Link to="/support" className="text-black">
            <BiSupport className="mx-2" size={20} title="Support" />
          </Link>
          <Link to="/notifications" className="text-black">
            <FiBell className="fe fe-bell Icon_feather mx-2" size={20} />
          </Link>
          <DashboardWallet />
        </div>
      )}
    </div>
  );
};

export default PageTitle;
