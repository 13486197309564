import {APP_SETTINGS} from "../../config";

const axios = require("axios");
class CategoryDataService {
  getCatgeories(auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.CATEGORY.categoryListing}`, {
      headers: {
        Authorization: "Bearer " + auth.mee_too_user.token,
      },
    });
  }
}

export default new CategoryDataService();
