const axios = require("axios");
const { APP_SETTINGS } = require("../../config");

export const user_type = {
  seller: "SELLER",
  user: "USER",
};

class SupportService {
  fetchSupportList(_type = user_type.seller) {
    const url = `${APP_SETTINGS.API_PATH.FAQ.faqFetch}`.replace(
      "{{USER_TYPE}}",
      _type
    );
    return axios.get(url);
  }
  fetchSupportById(id) {
    const url = `${APP_SETTINGS.API_PATH.FAQ.faqById}`.replace(
      "{{FAQ_ID}}",
      id
    );
    return axios.get(url);
  }
  raiseSupport(payload) {
    const { id, formData, token } = payload;
    const url = `${APP_SETTINGS.API_PATH.FAQ.raiseTicket}`.replace(
      "{{TICKET_ID}}",
      id
    );
    return axios.post(url, formData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  sendMsg(payload) {
    const { id, formData, token } = payload;
    const url = `${APP_SETTINGS.API_PATH.FAQ.sendMsg}`.replace(
      "{{TICKET_ID}}",
      id
    );
    return axios.post(url, formData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  fetchTicket(payload) {
    const { token } = payload;
    const url = APP_SETTINGS.API_PATH.FAQ.getTicket;
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  fetchTicketById(payload) {
    const { id, token } = payload;
    const url = `${APP_SETTINGS.API_PATH.FAQ.getTicketById}`.replace(
      "{{TICKET_ID}}",
      id
    );
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
}

export default new SupportService();
