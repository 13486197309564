import React from "react";
import { GenericButton } from "../../../compoents/Button";
import { SellerSuccessFormStyled } from "./SellerStyled";
import { IMAGESV2 } from "../../../config";

function SuccessForm({ onClick }) {
  return (
    <SellerSuccessFormStyled>
      <h3 className="sellerSuccessForm__heading">
        <b>Congratulation</b>, your registration is completed.
      </h3>
      <div className="sellerSuccessForm__msg">
        Login again & Start product listing
      </div>
      <GenericButton className="sellerSuccessForm__btn" onClick={onClick}>
        Login Now
      </GenericButton>
      <div className="success__icon success--leftIcon">
        <img src={IMAGESV2.congrats} alt="congrats" />
      </div>
      <div className="success__icon success--rightIcon">
        <img src={IMAGESV2.congrats} alt="congrats" />
      </div>
    </SellerSuccessFormStyled>
  );
}

export default SuccessForm;
