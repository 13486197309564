import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import mapImage from "../../../../assets/Images/v2/map.png";

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0f122f;
  color: white;
  margin-bottom: 40px;

  .left {
    width: 50%;
    border-right: 5px solid #ff4d00;
    display: flex;
    justify-content: center;
    padding: 25px;

    .map_Image {
      width: 90%;
    }
  }

  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    .heading {
      color: #ff4d00;
    }

    .subheading {
      color: #fff;
      font-size: 20px;
    }

    // Counter styling **

    .stats {
      width: 100%;
      display: flex;
      gap: 1%;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;
      margin-top: 10px;
    }

    .stat {
      width: 49%;
      padding: 40px;
    }
  }

  // Medium screen size
  @media only screen and (max-width: 992px) and (min-width: 600px) {
    margin-bottom: 40px;

    .left {
      padding: 20px;

      .map_Image {
        width: 100%;
      }
    }

    .right {
      padding: 20px;

      .subheading {
        font-size: 18px;
      }

      // Counter styling **

      .stats {
        margin-top: 10px;
      }

      .stat {
        width: 49%;
        padding: 15px;
      }

      .counter {
        font-size: 25px;
      }

      .stats-title {
        font-size: 18px;
      }
    }
  }

  // Small screen size
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;

    .left {
      width: 100%;
      border-bottom: 2px solid #ff4d00;
      border-right: none;

      .map_Image {
        width: 100%;
      }
    }

    .right {
      width: 100%;
      padding: 40px 0 0 0;

      .subheading {
        font-size: 18px;
      }

      // Counter styling **

      .stats {
        padding-top: 0;
      }

      .stat {
        width: 49%;
        padding: 20px;
      }
    }
  }
`;

const StyleHeading = styled.h1`
  font-size: 27px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0 40px 0;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

const SellerTrust = () => {
  return (
    <>
      <Container>
        <StyleHeading>Sellers trusted from all over India</StyleHeading>
        <StyledDiv>
          <div className="left">
            <img src={mapImage} alt="" className="map_Image" />
          </div>
          <div className="right">
            <h2 className="heading">Groupick</h2>
            <p className="subheading">Online Shopping Made Affordable</p>
            <div className="stats">
              <div className="stat">
                <h1 className="counter">5000+</h1>
                <h5 className="stats-title">Sellers</h5>
              </div>
              <div className="stat">
                <h1 className="counter">25000+</h1>
                <h5 className="stats-title">Products</h5>
              </div>
              <div className="stat">
                <h1 className="counter">20000+</h1>
                <h5 className="stats-title">Enquires</h5>
              </div>
              <div className="stat">
                <h1 className="counter">1600+</h1>
                <h5 className="stats-title">Categories</h5>
              </div>
            </div>
          </div>
        </StyledDiv>
      </Container>
    </>
  );
};

export default SellerTrust;
