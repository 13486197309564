import { Link } from "react-router-dom";
import Box from "../../compoents/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { IMAGES, TOAST_STYLE } from "../../config";
import { ButtonComponent, GenericButton } from "../../compoents/Button";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const StyledBadge = styled(Badge)`
  background-color: ${({ bg }) =>
    bg && bg !== "" ? bg : "#FFE7E7"} !important;
  color: ${({ color }) => (color && color !== "" ? color : "#FF0000")};
  border: ${({ border }) => (border && border !== "" ? border : "none")};
  font-size: ${({ fontSize }) =>
    fontSize && fontSize !== "" ? fontSize : "14px"};
  border-radius: ${({ borderRadius }) =>
    borderRadius && borderRadius !== "" ? borderRadius : "8px"};
  padding: ${({ padding }) =>
    padding && padding !== "" ? padding : "10px 25px"};

  /* @media (min-width: 992px) and (max-width: 1200px) {
		margin-top: "20px";
		margin-bottom: "20px";
	} ; */
`;

const CardStyledImage = styled(Image)`
  border: 1px solid #a6a6a6;
  border-radius: 8px;
  @media (min-width: 992px) {
    min-height: 152px;
  }
`;

const CashImage = styled(Image)`
  width: 43px;
  height: 43px;
`;

const OrderReturnDetail = () => {
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [order, setOrder] = useState({});
  let orderId = window.location.pathname.split("/")[2].split("=")[1];

  useEffect(() => {
    (async () => {
      const res = await sellerApiService.getOrder(orderId);
      if (res.data) {
        const { order } = res.data;
        setOrder(order);
      }
    })();
  }, []);

  const shipOrder = async () => {
    const res = await sellerApiService.shipOrder(order?.id);
    if (res.data.status == true) {
      toast.success("Order marked as shipped", TOAST_STYLE);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error("Error While marking order as shipped", TOAST_STYLE);
    }
  };

  return (
    <>
      <Row className="gy-4 mb-5">
        <Col xl={8}>
          <Box className="p-4 gx-1">
            <Row className="align-items-center gy-3">
              <Col xxl={1} md={1} xs={12}>
                <Link to="/orders">
                  <img
                    alt="back-icon"
                    style={{ width: "25px", height: "25px" }}
                    src="https://img.icons8.com/external-prettycons-lineal-prettycons/98/000000/external-left-arrow-orientation-prettycons-lineal-prettycons.png"
                  />
                </Link>
              </Col>
              <Col xxl={8} md={7} sm={12}>
                <div className="d-flex align-items-center flex-wrap">
                  <h3 className="text-muted mb-0 me-2 fs-6">
                    Order <span className="text-dark">#{order?.id}</span>
                  </h3>
                  <StyledBadge className="fw-bold d-flex me-2 align-items-center my-2">
                    Paid
                  </StyledBadge>
                  <StyledBadge
                    color="#0DB691"
                    bg="#E3FFF9"
                    className="fw-bold d-flex align-items-center my-2"
                  >
                    {order?.status}
                  </StyledBadge>
                </div>
              </Col>
              <Col xxl={3} md={4} sm={12} className="text-end">
                <span>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    width="610.398"
                    height="610.398"
                    viewBox="0 0 610.398 610.398"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <path d="M159.567 0h-15.329c-1.956 0-3.811.411-5.608.995-8.979 2.912-15.616 12.498-15.616 23.997v51.613c0 2.611.435 5.078 1.066 7.44 2.702 10.146 10.653 17.552 20.158 17.552h15.329c11.724 0 21.224-11.188 21.224-24.992V24.992c0-13.804-9.5-24.992-21.224-24.992zM461.288 0h-15.329c-11.724 0-21.224 11.188-21.224 24.992v51.613c0 13.804 9.5 24.992 21.224 24.992h15.329c11.724 0 21.224-11.188 21.224-24.992V24.992C482.507 11.188 473.007 0 461.288 0z" />
                    <path d="M539.586 62.553h-37.954v14.052c0 24.327-18.102 44.117-40.349 44.117h-15.329c-22.247 0-40.349-19.79-40.349-44.117V62.553H199.916v14.052c0 24.327-18.102 44.117-40.349 44.117h-15.329c-22.248 0-40.349-19.79-40.349-44.117V62.553H70.818c-21.066 0-38.15 16.017-38.15 35.764v476.318c0 19.784 17.083 35.764 38.15 35.764h468.763c21.085 0 38.149-15.984 38.149-35.764V98.322c.005-19.747-17.059-35.769-38.144-35.769zM527.757 557.9l-446.502-.172V173.717h446.502V557.9z" />
                    <path d="M353.017 266.258h117.428c10.193 0 18.437-10.179 18.437-22.759s-8.248-22.759-18.437-22.759H353.017c-10.193 0-18.437 10.179-18.437 22.759 0 12.575 8.243 22.759 18.437 22.759zM353.017 348.467h117.428c10.193 0 18.437-10.179 18.437-22.759 0-12.579-8.248-22.758-18.437-22.758H353.017c-10.193 0-18.437 10.179-18.437 22.758 0 12.58 8.243 22.759 18.437 22.759zM353.017 430.676h117.428c10.193 0 18.437-10.18 18.437-22.759s-8.248-22.759-18.437-22.759H353.017c-10.193 0-18.437 10.18-18.437 22.759s8.243 22.759 18.437 22.759zM353.017 512.89h117.428c10.193 0 18.437-10.18 18.437-22.759 0-12.58-8.248-22.759-18.437-22.759H353.017c-10.193 0-18.437 10.179-18.437 22.759 0 12.579 8.243 22.759 18.437 22.759zM145.032 266.258H262.46c10.193 0 18.436-10.179 18.436-22.759s-8.248-22.759-18.436-22.759H145.032c-10.194 0-18.437 10.179-18.437 22.759.001 12.575 8.243 22.759 18.437 22.759zM145.032 348.467H262.46c10.193 0 18.436-10.179 18.436-22.759 0-12.579-8.248-22.758-18.436-22.758H145.032c-10.194 0-18.437 10.179-18.437 22.758.001 12.58 8.243 22.759 18.437 22.759zM145.032 430.676H262.46c10.193 0 18.436-10.18 18.436-22.759s-8.248-22.759-18.436-22.759H145.032c-10.194 0-18.437 10.18-18.437 22.759s8.243 22.759 18.437 22.759zM145.032 512.89H262.46c10.193 0 18.436-10.18 18.436-22.759 0-12.58-8.248-22.759-18.436-22.759H145.032c-10.194 0-18.437 10.179-18.437 22.759.001 12.579 8.243 22.759 18.437 22.759z" />
                  </svg>
                </span>
                <span className="text-muted ms-1" style={{ fontSize: "12px" }}>
                  {new Date(order?.created_at).toLocaleDateString()} At{" "}
                  {new Date(order?.created_at).toLocaleTimeString()}
                </span>
              </Col>
            </Row>
            <Row className="my-4 justify-content-center gy-4">
              <Col md={3} xs={12} sm={8}>
                <CardStyledImage
                  className="w-100 py-2 px-3"
                  thumbnail
                  style={{ minHeight: "152px" }}
                  src={order?.product?.image}
                />
              </Col>
              <Col md={9} sm={8}>
                <div className="d-flex justify-content-between flex-wrap">
                  <h6 className="font-bold text-dark mb-0">
                    {order?.product?.name}
                  </h6>
                  <p className="fs-6 mb-0">
                    <span className="me-2" style={{ color: "#FF0000" }}>
                      ₹ {order?.amount}
                    </span>
                    <del className="text-muted">₹ {order?.product?.mrp}</del>
                  </p>
                  <span className="text-dark fs-6 fw-bold">
                    {order?.quantity}
                  </span>
                  <span className="text-dark fs-6 fw-bold">
                    ₹ {order?.amount}
                  </span>
                </div>
                <p className="mb-0 mt-2">
                  <span className="text-muted">Color: </span>
                  <span className="text-dark fw-bold">
                    {order?.details?.color}
                  </span>
                </p>
                <p className="mb-0 mt-2">
                  <span className="text-muted">Size: </span>
                  <span className="text-dark fw-bold">
                    {order?.details?.size}
                  </span>
                </p>
              </Col>
            </Row>
            {order?.status == "PLACED" && (
              <Row className="my-4 justify-content-center gy-4">
                <Col md={12} xs={12} sm={12}>
                  <div className="mt-1 text-center">
                    <GenericButton type="button" onClick={shipOrder}>
                      Ship Order
                    </GenericButton>
                  </div>
                </Col>
              </Row>
            )}
            <hr />
            <div className="my-4">
              <h4>Payment</h4>
              <Row className="align-items-center">
                <Col xs={2}>
                  <Box
                    background="#F9F9F9"
                    boxShadow="0px 3px 6px #00000029"
                    borderRadius="8px"
                    style={{ width: "82px", height: "58px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <CashImage src={IMAGES.DOLLAR} />
                  </Box>
                </Col>
                <Col>
                  <p className="mb-0">Pay on delivery.</p>
                </Col>
                <Col className="text-end">
                  <span className="fw-bold fs-4">₹ 0</span>
                </Col>
              </Row>
            </div>
            <hr />
            <div className="px-2 my-4">
              <h6 className="fs-5 mb-3">Price Details</h6>
              <div className="d-flex justify-content-between">
                <span className="text-muted fs-6">Product Charges</span>
                <span className="text-dark fs-6">₹{order?.amount}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted fs-6">
                  Delivery Charges
                  <img src={IMAGES.INFO} style={{ width: "10px" }} alt="" />
                </span>
                <span className="text-dark fs-6">
                  +₹{order?.shipping_charge}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted fs-6">
                  Special Offer
                  <img src={IMAGES.INFO} style={{ width: "10px" }} alt="" />
                </span>
                <span className="text-dark fs-6">+₹0</span>
              </div>
              <hr />
              <div className="d-flex justify-content-between ">
                <span className="font-bold fs-5">Order Total</span>
                <span className="font-bold fs-5">
                  ₹{" "}
                  {Number(
                    Number(order?.amount) + Number(order?.shipping_charge)
                  ).toFixed(2)}
                </span>
              </div>
            </div>
            {order?.return_reason != "" && (
              <div>
                <h5 className="font-bold">Reason of Returning</h5>
                <Card
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #9D9D9D",
                    borderRadius: "8px",
                  }}
                >
                  <Card.Body>
                    <Card.Text className="text-muted">
                      {order?.return_reason}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            )}
          </Box>
        </Col>
        <Col xl={4}>
          <Box className="px-4 py-4">
            <h4 className="text-dark font-bold mb-4">Customer</h4>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Image
                  style={{ width: "35px" }}
                  roundedCircle
                  fluid
                  src={IMAGES.PROFLE_IMAGE}
                />
                <h6 className="ms-4 mb-0 text-muted font-bold">
                  {order?.user?.name}
                </h6>
              </div>
              <ButtonComponent background="none" border="none" className="">
                <img
                  className=""
                  style={{ width: "14px", height: "14px" }}
                  src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/000000/external-right-arrow-arrows-dreamstale-lineal-dreamstale-15.png"
                  alt=""
                />
              </ButtonComponent>
            </div>
            <hr />
            <div className="d-flex align-items-center py-3">
              <Box
                background="#F9F9F9"
                boxShadow="0px 3px 6px #00000029"
                borderRadius="31px"
                style={{ width: "55px", height: "55px" }}
                className="d-flex align-items-center justify-content-center"
              >
                <Image
                  roundedCircle
                  style={{ width: "35px", height: "43px" }}
                  fluid
                  src={IMAGES.DOLLAR}
                />
              </Box>
              <h6 className="ms-4 mb-0 text-muted font-bold">
                {order?.user?.orders} Order
              </h6>
            </div>
            <hr />
            <div>
              <h5 className="text-bold mt-5 mb-4">Contact info</h5>
              <div className="text-muted mb-3">
                <img
                  width="20px"
                  src="https://img.icons8.com/wired/64/000000/phone.png"
                  alt=""
                />
                <span className="ms-2">{order?.user?.email}</span>
              </div>
              <div className="text-muted mb-3">
                <img
                  width="20px"
                  src="https://img.icons8.com/fluency-systems-regular/48/000000/new-post.png"
                  alt=""
                />
                <span className="ms-2">{order?.user?.mobile}</span>
              </div>
            </div>
            <div>
              <h5 className="text-bold">Shipping Address</h5>
              <p className="text-muted">{order?.address?.landmark}</p>
              <p className="text-muted">{order?.address?.street}</p>
              <p className="text-muted">
                {order?.address?.city} - {order?.address?.pincode}
              </p>
              <p className="text-muted">{order?.address?.state}</p>
              <p className="text-muted">{order?.address?.country}</p>
              <p className="text-muted">Mobile: {order?.address?.mobile}</p>
            </div>
          </Box>
        </Col>
      </Row>
    </>
  );
};

export default OrderReturnDetail;
