import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import SellerApiService from "../../../redux/services/seller-api-service";
import { useEffect } from "react";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import Loader from "../../../compoents/Loader";
import { GenericButton } from "../../../compoents/Button";
import styled from "styled-components";

const CampaignInventoryStyled = styled.div`
  min-width: 500px;
  max-width: 1200px;
  overflow-x: auto;
  padding: 8px 24px;
`;

function CampaignInventory({
  onSelect,
  removeAllSelected,
  selected = [],
  handleSubmit,
}) {
  const auth = useAuth();
  const [fetching, setFetching] = useState(true);
  const [product, setProducts] = useState([]);
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    selected,
    onSelectAll: (isSelect, rows) => {
      if (!isSelect) {
        removeAllSelected();
      } else {
        onSelect(rows, isSelect, false);
      }
    },
    classes: "selection-row",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (!isSelect && e.target.name === "bugdet") {
        return false;
      }
      onSelect(row, isSelect, true);
    },
  };

  const columns = [
    {
      dataField: "title_image",
      text: "Product",
      formatter: (cell, row) => {
        console.log("=========", row, cell);
        return (
          <div style={{ width: "80px", aspectRatio: "1/1" }}>
            <img width="100%" src={cell} alt="Product_image" />
          </div>
        );
      },
    },
    {
      dataField: "name",
      text: "Title",
    },
    {
      dataField: "mrp",
      text: "Price",
      formatter: (cell) => `Rs.${cell}`,
    },
    {
      dataField: "group_price",
      text: "Group Price",
      formatter: (cell) => `Rs.${cell}`,
    },
    {
      dataField: "stock",
      text: "Stocks",
      formatter: (cell) => `Qty - ${cell}`,
    },
  ];

  const getFilteredProducts = async () => {
    try {
      setFetching(true);
      const res = await sellerApiService.getFilteredProducts("all");

      if (res.data) {
        const { products = [] } = res.data;
        setProducts(products);
      }
      setFetching(false);
    } catch (err) {
      setProducts([]);
      setFetching(false);
      console.log("getFilteredProducts_err", err);
    }
  };

  useEffect(() => {
    getFilteredProducts();
  }, []);

  return (
    <CampaignInventoryStyled>
      {fetching ? (
        <Loader animation="border" />
      ) : (
        <div className="d-flex flex-column">
          <BootstrapTable
            data={product}
            keyField="id"
            columns={columns}
            bordered={false}
            condensed
            striped
            hover
            selectRow={selectRow}
          />
          <GenericButton className="ms-auto" onClick={handleSubmit}>
            Proceed
          </GenericButton>
        </div>
      )}
    </CampaignInventoryStyled>
  );
}

export default CampaignInventory;
