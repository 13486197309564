import React, { useState } from "react";
import { Formik, Form } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { OutlinedTextFields } from "../../compoents/Forms2";
import ErrorMessage from "../../compoents/ErrorMessage";
import { GenericButton } from "../../compoents/Button";
import { BiRupee } from "react-icons/bi";
import walletService from "../../redux/services/wallet.service";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { toast } from "react-toastify";
import { loadRazorpay } from "../../utilities";
import { APP_SETTINGS } from "../../config";

const WalletDepositStyled = styled.div`
  padding: 0 8px;
  .walletDeposit__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .walletDeposit__inputGroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px;

      label {
        font-size: 14px;
        font-weight: 500;
      }
      .walletDeposit__inputControl {
        position: relative;
        flex: 1;
        border: 2px solid #c3c3c3;
        display: flex;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        .walletDeposit__rupee {
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(-40%);
          margin-left: 4px;
        }
        input {
          padding-left: 24px;
          border: none;
        }
      }
    }
  }
  .walletDeposit__btn {
    font-weight: 400;
    &:disabled {
      opacity: 0.2;
      background: #000;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      cursor: not-allowed;
      border: none;
    }
  }
  .note {
    font-size: 14px;
    text-align: center;
    margin: 16px 0 8px;
    color: gray;
  }
`;

const StyledInput = styled(OutlinedTextFields)`
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    opacity: 0.5; /* Firefox */
  }
`;

function WalletDeposit({ fetchWalletDetail }) {
  const razorpaySdk = APP_SETTINGS.API_PATH.razorpaySdk;
  const auth = useAuth();
  const { token } = auth?.mee_too_user;
  const [paymentInit, setPaymentInit] = useState(false);
  const initValue = {
    amount: 500,
  };

  const schema = Yup.object().shape({
    amount: Yup.number().required("Please enter amount."),
  });

  const fetchRzpOrder = async (values, callback) => {
    try {
      const date = (Date.now() / 1000).toString().split(".")[0];
      const payload = { ...values, date };
      const res = await walletService.init_payment(payload, { token });
      const { status, data } = res.data;
      if (status === "error") return toast.error("Please try after sometime.");
      callback(data ?? {});
    } catch (error) {
      console.log("fetchRzpOrder:", error);
      setPaymentInit(false);
    }
  };

  const handlePaymentSuccess = async (response) => {
    try {
      console.warn("handlePaymentSuccess:", response);
      const res = await walletService.verify_payment(response, { token });
      const { status, msg } = res?.data;
      if (!status || status === "error")
        return toast.error(msg ?? "Payment failed.");
      fetchWalletDetail();
      return toast.success(msg ?? "Wallet updated.");
    } catch (error) {
      console.log("handlePaymentSuccess:::", error);
      toast.error("Payment failed.");
    }
  };

  const handlePayment = async (data) => {
    try {
      const { id, payload } = data;
      const handler = (response) => {
        console.warn("razorpay_handler:", response);
        handlePaymentSuccess(response);
      };
      const theme = {
        color: "#ff4d00",
      };
      payload.handler = handler;
      payload.theme = theme;
      const rzpy = new window.Razorpay(payload);
      rzpy.on("payment.failed", async function (response) {
        console.warn("razorpay failed3:", response);
        //   await accountService.payment_failed(response, auth);
        toast.error("Payment failed.");
        setPaymentInit(false);
      });
      rzpy?.open();
      setPaymentInit(false);
    } catch (err) {
      toast.error("Payment failed.");
      setPaymentInit(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setPaymentInit(true);
      if (!window.hasOwnProperty("Razorpay")) {
        await loadRazorpay(razorpaySdk);
      }
      await fetchRzpOrder(values, (data) => handlePayment(data));
    } catch (error) {
      console.log("init_payment", error);
      setPaymentInit(false);
      return toast.error("Please try after sometime.");
    }
  };

  return (
    <WalletDepositStyled>
      <p className="note">
        Amount above 100 can only be deposited.
        <span className="fw-bold text-danger">*</span>
      </p>
      <Formik
        initialValues={initValue}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, setFieldValue, values }) => (
          <Form className="walletDeposit__form">
            <div className="walletDeposit__inputGroup">
              <label htmlFor="amount">Enter Amount : </label>
              <div className="walletDeposit__inputControl">
                <BiRupee size={20} className="walletDeposit__rupee" />
                <StyledInput
                  id="amount"
                  name="amount"
                  type="number"
                  placeholder="0.00"
                  value={values.amount}
                  onChange={(e) => setFieldValue("amount", e.target.value)}
                />
              </div>

              {touched.amount && errors.amount && (
                <ErrorMessage message={errors.amount} />
              )}
            </div>
            <GenericButton
              className="walletDeposit__btn"
              disabled={values.amount < 100 || paymentInit}
              type="submit"
            >
              {paymentInit ? "Please Wait..." : "DEPOSIT MONEY"}
            </GenericButton>
          </Form>
        )}
      </Formik>
    </WalletDepositStyled>
  );
}

export default WalletDeposit;
