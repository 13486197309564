import React from "react";
import styled from "styled-components";
import AuthHeader from "../Authentication/AuthHeader";
import { IMAGESV2 } from "../../config";
import { BsFillTelephoneFill, BsGlobe } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import AuthCTA from "../Authentication/AuthCTA";
import { Container } from "react-bootstrap";
import AuthFooter from "../Authentication/AuthFooter";

import Banner from "../../assets/Images/v2/contact-bg.png";

const StyledContact = styled.div`
  padding-top: 96px;
  width: 100%;
  .contact__main {
    width: 100%;
    height: calc(100vh - 96px);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .main_container {
      display:flex;
      flex-direction: column;
      gap: 20px;
    }
    .contact__heading {
      margin: 0;
      color: #ff4d00;
      font-size: 80px;
      font-weight: 700;
      line-height: 1em;
      z-index: 3;
    }
    .contact__desc {
      font-size: 27px;
      max-width: 50%;
      line-height: 1.3em;
      font-weight: 500;
      z-index: 3;
    }
    .contact__details {
      display: flex;
      flex-direction: column;
      gap: 15px;
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 500;
      z-index: 3;
        .contact__info {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          gap: 15px;
          margin-top: 8px;
          .contact__icon {
            color: #ff4d00;
          }
          .contact__type {
            font-size: 20px;
            font-weight: 400;
            line-height: 100%;
          }
          .contact_link {
            text-decoration: none;
            color:#000;
          }
        }
      }
      
    }
  }
  .contact__divider {
    margin: 0;
    box-shadow: 0 1px 5px #000;
  }
  @media (min-width: 1400px) {
    .contact__main {
      max-width: 100%;
      margin: 0 auto;
    }
  }
  @media (max-width: 992px) {
    .contact__main {
      background-position: center left;
      // padding: 10px;
      text-align: center;
      .main_container {
        gap: 20px;
      }
      .contact__heading {
        font-size: 40px;
        
      }
      .contact__desc {
        font-size: 25px;
        max-width: 100%;
        font-weight: 400;
      }
      .contact__details {
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        z-index: 3;
          .contact__info {
            
          }
        }
        
      }
    }
    .contact__divider {
      margin: 0;
      box-shadow: 0 1px 5px #000;
    }
    }
  }
`;

const companyDetails = {
  phone: "+91 90820 60855",
  email: "support@groupick.in",
  site: "seller.groupick.in",
};

function ContactUs() {
  return (
    <StyledContact>
      <AuthHeader activePage="contact" />
      <div
        className="contact__main"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <Container className="main_container">
          <h1 className="contact__heading">
            How to <br /> Contact Us?
          </h1>
          <h3 className="contact__desc">
            To find out more information, <br />
            please contact us
          </h3>
          <ul className="contact__details">
            <li className="contact__info">
              <BsFillTelephoneFill className="contact__icon" size={18} />
              <div className="contact__type">
                <a className="contact_link" href="tel:9082060855">
                  {companyDetails?.phone}
                </a>
              </div>
            </li>
            <li className="contact__info">
              <GrMail className="contact__icon" size={18} />
              <div className="contact__type">
                <a href="mailto:support@groupick.in" className="contact_link">
                  {companyDetails?.email}
                </a>
              </div>
            </li>
            <li className="contact__info">
              <BsGlobe className="contact__icon" size={18} />
              <div className="contact__type">
                <a href="https://seller.groupick.in" className="contact_link">
                  {companyDetails?.site}
                </a>
              </div>
            </li>
          </ul>
        </Container>
      </div>
      <hr className="contact__divider" />
      <AuthFooter />

      <div className="text-center border-top-1 mx-5 py-4 copy-right-footer">
        <h4>@ 2023 Getzie Technology Pvt Ltd. All Right Reserved.</h4>
      </div>
    </StyledContact>
  );
}

export default ContactUs;

{
  /* <h3 className="text-danger fw-bolder contact__heading">
          How to Contact Us?
        </h3>
        <div className="contact__desc">
          To find out more information, please contact us
        </div>
        <ul className="contact__details">
          <li className="contact__info">
            <BsFillTelephoneFill className="contact__icon" size={18} />
            <div className="contact__type">{companyDetails?.phone}</div>
          </li>
          <li className="contact__info">
            <GrMail className="contact__icon" size={18} />
            <div className="contact__type">{companyDetails?.email}</div>
          </li>
          <li className="contact__info">
            <BsGlobe className="contact__icon" size={18} />
            <div className="contact__type">{companyDetails?.site}</div>
          </li>
        </ul>
        <div className="contact__image">
          <img src={IMAGESV2.about} alt="contact" />
        </div> */
}
