import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import styled from "styled-components";
import { columns } from "./campaignTableConfig";
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import Loader from "../../compoents/Loader";
import CampaignService from "../../redux/services/campaign.service";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  allCampaign,
  completeCampaign,
  getCount,
  liveCampaign,
  pausedCampaign,
  scheduleCampaign,
  updateCampaign,
} from "../../redux/slices/campaignSlice";

const StyledCampaignTable = styled.div`
  .w-140 {
    width: 140px;
  }
  .tableWrapper {
    max-width: 100%;
    overflow: auto;
  }
  .campaignTable {
    border-collapse: collapse;
    border-spacing: 0;
    .tableHeader {
      background: #fff;
      color: #000;
      text-align: center;
    }
    .single-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .nav-item {
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    .nav-link {
      color: #000;
    }
    .active {
      background: transparent;
      border-top: 2px solid #0d6efd;
      color: #0d6efd;
    }
  }
`;

function CampaignDashboard() {
  const count = useSelector(getCount);
  const all = useSelector(allCampaign);
  const live = useSelector(liveCampaign);
  const schedule = useSelector(scheduleCampaign);
  const paused = useSelector(pausedCampaign);
  const complete = useSelector(completeCampaign);
  const [tab, setTab] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const auth = useAuth();
  const campaignService = new CampaignService();
  const { token } = auth.mee_too_user;
  const dispatch = useDispatch();

  const fetchCampaign = useCallback(async (token) => {
    try {
      const res = await campaignService.get_campaigns(token);
      const { status, msg, data } = res.data;
      setLoaded(true);
      if (status === "error") {
        toast.error(msg ?? "Try again later.");
        return;
      }
      dispatch(updateCampaign(data));
    } catch (err) {
      setLoaded(true);
      toast.error("Try again later.");
    }
  }, []);

  useEffect(() => {
    if (!loaded) fetchCampaign(token);
  }, [token, loaded, fetchCampaign]);

  const tabs = ["All", "Live", "Schedule", "Paused", "Complete"];

  const getTableData = useCallback(() => {
    if (tab === 2) return live;
    if (tab === 3) return schedule;
    if (tab === 4) return paused;
    if (tab === 5) return complete;
    return all;
  }, [tab, all, live, schedule, complete, paused]);

  if (!loaded) {
    return (
      <div className="d-flex gap-2">
        <Loader />
        <Loader />
        <Loader />
      </div>
    );
  }

  const displayCount = (tabName) => {
    switch (tabName) {
      case "All":
        return <b>{count.all}</b>;
      case "Live":
        return <b>{count.live}</b>;
      case "Schedule":
        return <b>{count.schedule}</b>;
      case "Paused":
        return <b>{count.paused}</b>;
      case "Complete":
        return <b>{count.complete}</b>;
      default:
        return <b>0</b>;
    }
  };

  return (
    <StyledCampaignTable>
      <h3>Campaign Details</h3>
      <ul className="nav nav-tabs my-3">
        {tabs?.map((tabName, i) => (
          <li
            key={tabName}
            className="nav-item"
            onClick={() => {
              setTab(i + 1);
            }}
          >
            <div className={`nav-link ${tab === i + 1 ? "active" : ""}`}>
              {tabName} ({displayCount(tabName)})
            </div>
          </li>
        ))}
      </ul>
      <BootstrapTable
        keyField="id"
        data={getTableData()}
        columns={columns}
        striped
        bordered
        hover
        condensed
        rowClasses={"text-center"}
        headerClasses="tableHeader"
        classes="campaignTable"
        noDataIndication={<p className="m-0 p-0">No Camapign</p>}
        wrapperClasses="tableWrapper"
      />
    </StyledCampaignTable>
  );
}

export default CampaignDashboard;
