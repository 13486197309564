import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {GenericButton} from "../../compoents/Button";

import {productImageUploadThunk} from "../../redux/slices/product";
import {useAuth} from "../../Navigation/Auth/ProvideAuth";
import {useSelector} from "react-redux";
import {
    createProductThunk,
    uploadProductImageSelector,
    createProductSelector,
} from "../../redux/slices/product";
import {toast} from "react-toastify";
import {TOAST_STYLE} from "../../config";
import Loader from "../../compoents/Loader";
import ProductDataService from "../../redux/services/product.service";
import SellerApiService from "../../redux/services/seller-api-service";

const StyledUploadImage = styled(GenericButton)`
  /* color: black !important;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #868686;
border-radius: 7px;
color:#FF4D00 !important;
font-size:24px;
font-weight:bold;
padding:20px;
display: flex !important;
flex-direction: column;
justify-content: center;
align-items: center; */

  background: #ffffff;
  border: 1px solid #122736;
  border-radius: 43px;
  color: #122736 !important;
`;

const Images = (props) => {
    const {finalData, setFinalData, addProductHandler, setTabActiveKey} = props;
    let [imagArray, setImagArray] = useState([]);
    const createProductState = useSelector(createProductSelector);
    const [files, setFiles] = useState([]);
    const [tick, setTick] = useState(false);

    let {
        uploadProductImageLoading,
        uploadProductImageSuccess,
        uploadProductImageFailure,
        uploadProductImageError,
        uploadProductImageMessage,
        uploadProductImageData,
    } = useSelector((state) => state.uploadProductImage);

    const hiddenFileInput = useRef(null);
    const [product, setProduct] = useState({});
    const [deletedVariationImages, setDeletedVariationImages] = useState([]);
    let auth = useAuth();
    const sellerApiService = new SellerApiService(auth.mee_too_user.token);
    const dispatch = useDispatch();

    let {
        createProductSuccess,
    } = useSelector(createProductSelector);

    useEffect(() => {
        let product = JSON.parse(localStorage.getItem("product"));
        setProduct(product);
    }, []);

    function uploadFile(e) {
        let file = e.target.files[0];
        let images = [...product.images];
        if (images.length < 5) {
            images.push(file);
            setProduct({...product, images});
        } else {
            toast.error('You can upload only 5 images', TOAST_STYLE);
        }

        // if (createProductState?.createProductData) {
        //
        //   let finalPayload = {
        //     uploadImage: formData,
        //     productId: createProductState.createProductData.id,
        //     header: auth,
        //   };
        //   dispatch(productImageUploadThunk(finalPayload));
        // } else {
        //   toast.error("First Add Product And then upload images");
        // }

        // setImagArray((prev) => [...prev, formData]);
    }

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    };

    function getDataURI(data) {
        // Check if data parameter is a string or an object
        if (typeof data === 'string') {
            // If it is a base64 string, convert it to File Object and return
            return dataURItoBlob(data);
        } else if (typeof data === 'object') {
            // If it is an object, check if it is a file object
            return data;
        }
    }

    const getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
    };

    const save = async () => {
        deletedVariationImages.forEach(image => {
            sellerApiService.deleteProductImages(product.id, image.id).then(res => {
            });
        });
        let index = getRandomIntInclusive(9999, 999999);
        for (let image of product.images) {
            if (!image.file?.includes('/')) {
                let formData = new FormData();
                formData.append("file", image);
                formData.append("sequence", index);
                let finalPayload = {
                    uploadImage: formData,
                    productId: product.id,
                    header: auth,
                };
                dispatch(productImageUploadThunk(finalPayload));
                index++;
            }
        }
        const res = await sellerApiService.getProduct(product.id);
        if (res.data) {
            toast.success(
                "Product updated successfully",
                TOAST_STYLE
            );
            const {product} = res.data;
            localStorage.setItem("product", JSON.stringify(product));
            setTimeout(() => {
                window.location.reload();
            }, 3500);
        }

    };

    const imageSrc = (image) => {
        image = getDataURI(image);
        if (image.file?.includes('/')) {
            return "http://api.groupick.in/media/" + image.file;
        } else {
            return window.URL?.createObjectURL(image);
        }
    };

    return (
        <div className="p-3">
            <h3>Upload your product Images</h3>
            <div className="row">
                <div className="col-lg-6">
                    <div className="">
                        <div className="mt-0 mb-3 mx-2">
                            <StyledUploadImage
                                className="text-white d-block ml-auto py-2 px-5"
                                onClick={() => hiddenFileInput.current.click()}
                                variant="r-pos-light"
                            >
                                {"Upload Image"}
                            </StyledUploadImage>
                            <input
                                type="file"
                                hidden
                                name="img1"
                                onChange={(event) => {
                                    uploadFile(event);
                                }}

                                ref={hiddenFileInput}
                            />
                        </div>
                        <div className="py-3">
                            <small>Click on an image to remove it</small>
                        </div>
                        {product.images?.map((image, i) => (
                            <div className="d-inline-block px-2 py-2" key={i}>
                                <img
                                    src={imageSrc(image)}
                                    alt="bank_proof"
                                    width="120px"
                                    onClick={(e) => {
                                        let deleted = [...deletedVariationImages];
                                        deleted.push(image);
                                        setDeletedVariationImages(deleted);
                                        let images = [...product.images];
                                        images.splice(i, 1);
                                        setProduct({...product, images});
                                    }}
                                    title="Click to remove image"
                                    className="img-thumbnail img-fluid"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="my-5">
                <GenericButton type="button" onClick={save}>Save</GenericButton>
            </div>
            {/* <GenericButton
        disabled={uploadProductImageLoading}
        className={"d-block m-auto px-5 py-3"}
      >
        {uploadProductImageLoading === true ? (
          <>
            Loading....
            <Loader />
          </>
        ) : (
          "Craete Account"
        )}
        
      </GenericButton> */}
        </div>
    );
};

export default Images;
