import React, { useLayoutEffect, useState } from "react";
import StyledBox from "../../compoents/Box";
import styled from "styled-components";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import Accordion from "react-bootstrap/Accordion";
import PageTitle from "../../compoents/PageTitle";
const NoticeContainer = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .border-bottom {
    border-bottom: 1px solid black;
  }
`;

const NoticeBox = styled.div`
  border-radius: 9px;
  .notice-card {
    border: none;
  }
  .accordion-header {
    .accordion-button {
      background: none;
      box-shadow: none;
      color: white;
      &::after {
        position: absolute;
        right: 0;
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position-x: center;
        background-position-y: center;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }
  .notice-header {
    display: flex;
    flex-wrap: wrap;
    .notice-title {
      font-weight: 700;
    }
    .collapsed {
      border-bottom: 1px solid !important;
      border-radius: 9px !important;
    }
    .accordion-button {
      border: 1px solid #ff4d00;
      border-bottom: none;
      border-radius: 9px 9px 0 0;
      color: #ff4d00;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 50px 8px 12px;
    }
  }
  .notice-body {
    border-width: 1px 2px 2px 2px;
    border-color: #ff4d00;
    border-style: solid;
    border-radius: 0px 0px 9px 9px;
    color: #9b9b9b;
  }
`;

const Notice = () => {
  const [notices, setNotice] = useState([]);
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  useLayoutEffect(() => {
    (async () => {
      const res = await sellerApiService.getLatestNotice();
      if (res.data) {
        const { notice } = res.data;
        setNotice(notice);
      }
    })();
  }, []);

  return (
    <>
      <PageTitle text={"Notice"} />

      <StyledBox>
        <NoticeContainer>
          {notices?.length ? (
            notices?.map((notice) => (
              <div key={notice?.id} className="row w-100 m-0">
                <div className="col-lg-12">
                  <div className="px-sm-1 px-1">
                    <NoticeBox>
                      <Accordion defaultActiveKey={notices[0]?.id} alwaysOpen>
                        <Accordion.Item
                          eventKey={notice?.id}
                          className="notice-card"
                        >
                          <Accordion.Header className="notice-header">
                            <span className="notice-title">
                              {notice?.title}
                            </span>
                            <span>
                              Posted:{" "}
                              {new Date(notice?.created_at).toDateString()}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body className="notice-body">
                            <div>
                              <h5 className="text-dark">{notice?.title}</h5>
                              <p className="py-1">{notice?.content}</p>
                              <p>
                                Regards,
                                <br />
                                Team Groupick
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </NoticeBox>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h1 className="text-center text-dark">Nothing to show!</h1>
          )}
        </NoticeContainer>
      </StyledBox>
    </>
  );
};

export default Notice;
