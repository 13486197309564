import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const CourierPrtnerClaims = () => {
  return (
    <>
      <Container>
        <StyledBreadCrumb>
          <StyledBreadCrumb.Item active href="#">
            Dashboard
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Settings
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Legal & policies
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Courier Partner Preference and Claims
          </StyledBreadCrumb.Item>
        </StyledBreadCrumb>
        <h1 className="mt-4 mb-4">
          T&amp;Cs - Courier Partner Preference and Claims
        </h1>
        <section>
          <h4>
            Choosing a courier partner for forward shipping/order dispatch
          </h4>
          <p>
            Currently, it is not possible to choose your courier partner for
            order dispatch. Your delivery partner for forward shipping/order
            dispatch will be assigned by Groupick based on the serviceability.
          </p>
        </section>
        <section>
          <h4>
            Choosing courier partner for reverse shipping/customer returns
          </h4>
          <p>
            You can set preferences between the courier partners by navigating
            to the ‘Courier Partner’ tab under ‘Returns’ page. Returns Page will
            give you information on each courier partner's performance so you
            may analyze it and adjust your choices as necessary. Set your
            preference by clicking on “Set my choice”, against the courier
            partner and by clicking on ‘Save’ on the bottom right.
          </p>
        </section>
        <section>
          <h4>
            The preference provided by you for courier partners on customer
            returns
          </h4>
          <p>
            will not affect their performance of fulfilling your order dispatch.
          </p>
        </section>
        <section>
          <h4>Choosing courier partner for RTOs</h4>
          <p>
            Currently, it is not possible to choose your courier partner for
            RTOs. Your RTOs will be fulfilled by the same delivery partner who
            will be assigned for forward shipping/order dispatch of the
            concerned order.
          </p>
        </section>
        <section>
          <h4>Getting a courier partner which was not your 1st choice</h4>
          <p>
            While assigning courier partners, both your preference order and
            availability of courier partners in your pin code will be
            considered. If your first preference courier partner is not
            available, the courier partner will be assigned as per the
            subsequent choices (second, third or fourth) basis their
            availability.
          </p>
        </section>
        <section>
          <h4>Not choosing a courier partner</h4>
          <p>
            If you do not provide a courier partner preference, then we will
            automatically assign a courier partner for your reverse
            shipping/customer return based on the process followed prior to Jan
            1, 2023. The courier partner assigned to you will review your
            reimbursement claims for customer returns.
          </p>
        </section>
        <section>
          <h4>Return shipping charges</h4>
          <p>
            While assigning courier partners, both your preference order and
            availability of courier partners in your pin code will be
            considered. If your first preference courier partner is not
            available, the courier partner given as per subsequent choices
            (second, third or fourth) will be assigned basis their availability.
            The return shipping fee charged will be dependent on the final
            courier partner assigned to you. You can go Returns &gt; Courier
            partner page on Seller Panel to check the return shipping charges
            for each courier partner.
          </p>
        </section>
        <section>
          <h4>Performance metrics for courier partners</h4>
          <p>
            Performance metrics for each courier partner are given on Returns
            &gt; Courier partner page on Seller Panel. Please note that all the
            metrics provided are specific to your pin-code for the respective
            courier partners
          </p>
          <ul>
            <li>
              <strong>Average return time:</strong> The average time, in days,
              taken by the courier partner to pick up a customer return from the
              customer and deliver it back to the seller. The lower this number
              the better, since it indicates that the courier partner will take
              lesser time to deliver a customer return to you.
            </li>
            <li>
              <strong>Claim Raised%:</strong> Out of all the customer returns
              picked up by the respective courier partner, how many returns have
              a claim raised against them. The claim raised may be related to
              wrong, missing or damaged returns. The lower this number the
              better, since it indicates that the courier partner has less
              instances of wrong, missing or damaged returns.
            </li>
            <li>
              <strong>Claim Approval%:</strong> Out of all the claims raised
              against the courier partner, how many claims have been approved by
              the courier partner. The courier partner approves or rejects the
              claims basis evidence available. The higher this number the
              better, since it indicates that this courier partner has a higher
              probability of approving genuine claims on returns.
            </li>
          </ul>
        </section>
        <section>
          <h4>
            Choosing only one courier partner or not including any partners in
            your preference list
          </h4>
          <p>
            The preference order - 1&gt;2&gt;3&gt;4 - against all courier
            partners is mandatory. If your first choice partner is not
            available, we would take the partner as per the subsequent choices
            (second, third or fourth) and assign them for your return
            fulfillment, based on the availability of the partner. If you do not
            provide your preference order, we will automatically assign a
            courier partner for your reverse leg.
          </p>
        </section>
        <section>
          <h4>Raising claims with the courier partner</h4>
          <p>
            You can raise a claim on your return in the support section under
            “Return/RTO &amp; Exchange ” with the mandatory details. Please
            note, from Jan 1, 2023 onwards, all your eligible claims will be
            investigated by your assigned courier partner. The process of
            raising claims on your returns remains the same as before Jan 1,
            2023.
          </p>
          <p>
            You can assess the performance of your courier partner and adjust
            your preferences based on the metrics provided on the courier
            partner page, including Average return time, Claim raised%, Claim
            approval%, and Reverse rate card. The designated courier partner
            would validate your claim and resolve by either accepting or
            rejecting the claim.
          </p>
        </section>
        <section>
          <h4>Claims rejected by the courier partner</h4>
          <p>
            Your claim may be rejected by the courier partner basis the below
            validation steps:
          </p>
          <ul>
            <li>
              Shared evidence is deemed insufficient. Video guidelines for your
              reference:
            </li>
            <ul>
              <li>
                The packaging has to be opened in the video only. Cases, where
                the packaging has been pre-opened before recording the video,
                will not be accepted.
              </li>
              <li>
                All the sides of the package should be clearly displayed in the
                video.
              </li>
              <li>
                Label must be visible on the video itself clearly showing the
                AWB Number and order ID.
              </li>
              <li>
                The video has to be recorded in one stretch, clearly showing all
                actions. Any edited videos, with cuts in between, will not be
                accepted.
              </li>
            </ul>
            <li>Product Image</li>
            <li>Reverse way bill (Reverse shipping label)</li>
            <li>If you do not raise the claim within the given TAT</li>
            <li>
              Other reasons based on your transaction history on the platform
            </li>
            <li>
              If there is any potential indicator of fraud, misconduct, or
              unauthorized/illegal activity on the platform
            </li>
            <li>
              If you do not meet any other conditions which get communicated to
              you from time to time
            </li>
          </ul>
        </section>
        <section>
          <h4>Time for response on claim ticket raised</h4>
          <p>
            It will take 3 to 7 working days, from the date of claim was raised,
            to review and investigate your ticket.
          </p>
        </section>
      </Container>
    </>
  );
};

export default CourierPrtnerClaims;
