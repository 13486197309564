import React from "react";
import { CustomSliderStyled } from "./sliderStyled";

function CustomSlider({ list = [], sliderRef }) {
  return (
    <CustomSliderStyled ref={sliderRef}>
      {list?.map((item, i) => (
        <div className="customSlider__imageDiv" key={i}>
          <img
            className="customSlider__img"
            src={item.imgSrc}
            alt={item.title}
          />
          {item?.title && (
            <div className="customSlider__title">{item.title}</div>
          )}
        </div>
      ))}
    </CustomSliderStyled>
  );
}

export default CustomSlider;
