// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
import StyledBox from "../../compoents/Box";
import { ButtonComponent, GenericButton } from "../../compoents/Button";
import { setSelectedCategory } from "../../redux/slices/category";
import BrandDataService from "../../redux/services/brand-api-services";
import { FiChevronRight } from "react-icons/fi";

import SellerApiService from "../../redux/services/seller-api-service";
import PageTitle from "../../compoents/PageTitle";
import { OutlinedTextFields } from "../../compoents/Forms2";
import UploadInBulk from "./UploadInBulk";
import { APP_SETTINGS } from "../../config";
import QC from "../../compoents/QC";
import {
  BrandWithOutPagination,
  brandSelector,
} from "../../redux/slices/brand";
import MakeFeePayment from "../../compoents/common/MakeFeePayment";
import { allowedForProductListingSelector } from "../../redux/slices/account";

const Catalogue = () => {
  const isListingAllowed = useSelector(allowedForProductListingSelector);
  let auth = useAuth();
  const [catTick, setCatTick] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [topLevelCategories, setTopLevelCategories] = useState([]);
  const [subCategoriesArray, setSubCategoriesArray] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isBranded, setBranded] = useState(false);
  const allBrands = useSelector(brandSelector);
  const [brandCategoryList, setBrandCategoryList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});

  const [productName, setProductName] = useState("");

  const [lastSelectedCategory, setLastSelectedCategory] = useState({});
  const [showBulk, setShowBulk] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const download_file_link = APP_SETTINGS.API_PATH.CATALOG.download_excel_file;

  const submitSelectedCategories = () => {
    dispatch(setSelectedCategory(lastSelectedCategory));

    localStorage.setItem(
      "selectedCategory",
      JSON.stringify(lastSelectedCategory)
    );
    if (isBranded) {
      localStorage.setItem("selectedBrand", JSON.stringify(selectedBrand));
    } else {
      localStorage.removeItem("selectedBrand");
    }
    sessionStorage.setItem("new-product-name", productName);
    history.push("/add-product");
  };

  useEffect(
    () =>
      (async () => {
        if (!catTick) {
          const res = await sellerApiService.getAllCategoriesCount(
            auth.mee_too_user.token
          );
          let count = Number(res.data);
          let categories = [];
          if (localStorage.getItem("categories")) {
            let localCategories = JSON.parse(
              localStorage.getItem("categories")
            );
            if (localCategories.length !== count) {
              localStorage.removeItem("categories");
              const { data } = await sellerApiService.getAllCategories(
                auth.mee_too_user.token
              );
              categories = data;
              localStorage.setItem("categories", JSON.stringify(categories));
            } else {
              categories = JSON.parse(localStorage.getItem("categories"));
            }
          } else {
            const { data } = await sellerApiService.getAllCategories(
              auth.mee_too_user.token
            );
            categories = data;
            localStorage.setItem("categories", JSON.stringify(categories));
          }

          // getting parent categories here. 1st level caegories.

          let topLevelCategories = categories.filter(
            (cat) => cat.parent_id === null
          );
          const topLevelCategoriesFormatted = topLevelCategories?.map((c) => ({
            id: c.id,
            name: c.name,
          }));

          setTopLevelCategories(topLevelCategoriesFormatted);
          setAllCategories(categories);
          setCatTick(true);
        }
      })(),
    [catTick]
  );

  const fetchBrand = async () => {
    const config = {
      header: auth,
    };
    dispatch(BrandWithOutPagination({ data: config }));
  };

  useEffect(() => {
    if (isBranded) {
      fetchBrand();
    } else {
      setBrandCategoryList([]);
      setSelectedBrand({});
      setLastSelectedCategory({});
      setAllSelected(false);
    }
  }, [isBranded]);

  const onChangeParentCategory = (e, id, name) => {
    const selectedParentCategory = allCategories.find((cat) => cat.id === id);
    const subCategories = allCategories.filter((cat) => cat.parent_id === id);
    if (subCategories && subCategories.length > 0) {
      setSubCategoriesArray([subCategories]);
    } else {
      setSubCategoriesArray([]);
      setLastSelectedCategory(selectedParentCategory);
    }

    setSelectedCategories([selectedParentCategory]);
  };

  const onChangeSubCategory = (e, id, subCategoryLevel) => {
    const selectedSubCategory = allCategories.find((cat) => cat.id === id);

    const subCategories = allCategories.filter((cat) => cat.parent_id === id);

    if (subCategories && subCategories.length > 0) {
      setAllSelected(false);

      if (subCategoriesArray.length - 1 === subCategoryLevel) {
        setSubCategoriesArray((prev) => [...prev, subCategories]);
      } else {
        setSubCategoriesArray((prevArray) => {
          prevArray[subCategoryLevel + 1] = subCategories;
          const newArray = prevArray.slice(0, subCategoryLevel + 2);
          return newArray;
        });
      }
    } else {
      setSubCategoriesArray((prev) => prev.slice(0, subCategoryLevel + 1));
      setAllSelected(true);
      setLastSelectedCategory(selectedSubCategory);
    }
    setSelectedCategories((prevArray) => {
      prevArray[subCategoryLevel + 1] = selectedSubCategory;
      const selectedCategories = prevArray.slice(0, subCategoryLevel + 2);
      return selectedCategories;
    });
  };

  // const handleCategorySearch = (e) => {
  //   if (e.key === "Enter") {
  //     if (
  //       e.target.value.toLowerCase() === "others" ||
  //       e.target.value.toLowerCase() === "other"
  //     ) {
  //       toast.error(
  //         `Enter a specific category name other than ${e.target.value}`
  //       );
  //       return;
  //     }
  //     const foundCategory = allCategories.find((c) =>
  //       c.name
  //         .toLowerCase()
  //         .replace(" ", "")
  //         .includes(e.target.value.toLowerCase().replace(" ", ""))
  //     );
  //     if (!foundCategory) {
  //       toast.error("No category found with provided name.");
  //       return;
  //     } else {
  //       toast.info("Category Found.");

  //       dispatch(setSelectedCategory(foundCategory));

  //       localStorage.setItem(
  //         "selectedCategory",
  //         JSON.stringify(lastSelectedCategory)
  //       );
  //       sessionStorage.setItem("new-product-name", productName);
  //       history.push("/add-product");
  //     }
  //   }
  // };

  const getSearchSelectOptions = () => {
    let options;
    if (subCategoriesArray.length === 0) {
      options = topLevelCategories.map((c) => ({ label: c.name, value: c.id }));
    } else {
      options = subCategoriesArray[subCategoriesArray.length - 1].map((c) => ({
        label: c.name,
        value: c.id,
      }));
    }

    return options;
  };

  const searchSelectOptions = getSearchSelectOptions();
  let selectValue;
  if (selectedCategories.length === 0)
    selectValue = {
      label: "Search Categories",
      value: 0,
    };
  else
    selectValue = {
      label: selectedCategories[selectedCategories.length - 1]?.name,
      value: selectedCategories[selectedCategories.length - 1]?.id,
    };
  if (isListingAllowed === null) return null;
  return !showBulk ? (
    <div className="position-relative">
      <PageTitle text="Upload Catalogue" />
      {isListingAllowed ? (
        <>
          <div className="d-flex position-absolute top-0 end-0 px-sm-3 px-0">
            <div className="d-grid gap-2 justify-content-center me-3">
              <ButtonComponent
                borderRadius="4px"
                style={{ maxWidth: "242px", fontSize: "12px" }}
                background="#1492E6"
                className="py-2"
                border="none"
              >
                <a
                  style={{ color: "white" }}
                  href={download_file_link}
                  download
                  target={"blank"}
                >
                  DOWNLOAD BULK LISTING EXCEL SHEET
                </a>
              </ButtonComponent>
            </div>

            <GenericButton
              className="shadow-none fs-6"
              style={{ width: "148px" }}
              onClick={() => setShowBulk(true)}
            >
              Upload in Bulk
            </GenericButton>
          </div>
          <div className="py-3 px-sm-3 px-0">
            <Row>
              <Col>
                <StyledBox className="h-100 p-4 position-relative">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>
                      <h3>Add single product</h3>
                      <h6 className="mb-0 text-muted">
                        Please choose the right category for your product.
                      </h6>
                    </span>
                  </div>
                  <br />
                  {/* <div className="mt-5 d-flex align-items-center">
                <h6 className=" text-nowrap me-3">Product Name:</h6>
                <OutlinedTextFields
                  className="my-2"
                  name="brand_name"
                  type="text"
                  placeholder="Enter product name"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div> */}

                  {/* <span
                className="d-flex align-items-center mt-3 border border-2 px-4 py-2"
                style={{borderRadius: "20px", maxWidth: "400px"}}
              > */}
                  {/* <HiOutlineSearch /> */}
                  {/* <input
                  type="text"
                  className=" border-0 outline-0 ms-2 focus-none"
                  placeholder="Category Name"
                  onKeyUp={handleCategorySearch}
                /> */}
                  {/* <Select options={getSearchSelectOptions()} />
              </span> */}

                  <div className="form-check form-switch mb-3">
                    <div
                      className="cursor-pointer"
                      style={{ width: "fit-content" }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="selectBrand"
                        style={{
                          backgroundColor: isBranded ? "#ff4d00" : "",
                          outline: "none",
                          borderColor: isBranded ? "#ff4d00" : "",
                          boxShadow: "none",
                          cursor: "pointer",
                        }}
                        checked={isBranded}
                        onChange={() => setBranded(!isBranded)}
                      />
                      <label
                        className="form-check-label fw-bold"
                        for="selectBrand"
                        style={{ cursor: "inherit" }}
                      >
                        Select By Brand
                      </label>
                    </div>
                  </div>

                  {isBranded && (
                    <div className="w-70">
                      {!allBrands?.brandFetchLoading ? (
                        <>
                          <div className="fw-bold">Select Brand:</div>
                          <Select
                            options={allBrands?.brand}
                            placeholder="Select Brand"
                            value={selectedBrand}
                            onChange={(e) => {
                              setSelectedBrand(e);
                              const brandCategory = e?.brand_ref ?? [];
                              setBrandCategoryList(brandCategory);
                              setLastSelectedCategory({});
                              setAllSelected(false);
                            }}
                          />
                        </>
                      ) : (
                        "Loading..."
                      )}
                      <div className="my-4">
                        <div className="fw-bold">Select Category:</div>
                        <Select
                          options={brandCategoryList}
                          placeholder="Select Category"
                          value={lastSelectedCategory}
                          isDisabled={!Object.keys(selectedBrand).length}
                          onChange={(e) => {
                            console.log("Selected brand::", e);
                            setLastSelectedCategory(e);
                            setAllSelected(true);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {!isBranded && (
                    <>
                      <div className="w-70">
                        <Select
                          options={searchSelectOptions}
                          value={selectValue}
                          placeholder="Search Categories"
                          onChange={(val) => {
                            if (subCategoriesArray.length === 0) {
                              onChangeParentCategory(val, val.value, val.label);
                            } else {
                              onChangeSubCategory(
                                val,
                                val.value,
                                subCategoriesArray.length - 1
                              );
                            }
                          }}
                        />
                      </div>

                      <Row className="categories mt-3">
                        {!topLevelCategories.length ? (
                          <Alert color="info">Loading Categories ...</Alert>
                        ) : (
                          <>
                            <h4 className="my-3">Select Category</h4>

                            <Col
                              sm="6"
                              md="4"
                              lg="3"
                              className="border-end mt-3"
                              style={{ maxHeight: "300px", overflow: "scroll" }}
                            >
                              {topLevelCategories &&
                                topLevelCategories.map((tLC) => (
                                  <button
                                    className="w-100 cursor-pointer text-start
                         outline-none border-0 bg-transparent d-block my-3 fw-bolder d-flex justify-content-between align-items-center"
                                    style={{
                                      fontFamily: "inherit",
                                      color: `${
                                        selectedCategories.length &&
                                        selectedCategories[0].id === tLC.id
                                          ? "var(--orange-primary)"
                                          : "black"
                                      }`,
                                    }}
                                    onClick={(e) =>
                                      onChangeParentCategory(
                                        e,
                                        tLC.id,
                                        tLC.name
                                      )
                                    }
                                  >
                                    <span>{tLC.name}</span>
                                    <FiChevronRight
                                      className="ms-2"
                                      size={20}
                                    />
                                  </button>
                                ))}
                            </Col>
                          </>
                        )}

                        {subCategoriesArray &&
                          subCategoriesArray.length > 0 &&
                          subCategoriesArray.map(
                            (subCategories, subCategoryLevel) => {
                              return (
                                <Col
                                  sm="6"
                                  md="4"
                                  lg="3"
                                  className="border-end mt-3"
                                  style={{
                                    maxHeight: "300px",
                                    overflow: "scroll",
                                  }}
                                >
                                  {subCategories.map((sC) => (
                                    <button
                                      className="w-100 cursor-pointer text-start
                       outline-none border-0 bg-transparent d-block my-3 fw-bolder d-flex justify-content-between align-items-center"
                                      style={{
                                        fontFamily: "inherit",
                                        color: `${
                                          selectedCategories.length &&
                                          selectedCategories?.[
                                            subCategoryLevel + 1
                                          ]?.id === sC.id
                                            ? "var(--orange-primary)"
                                            : "black"
                                        }`,
                                      }}
                                      onClick={(e) =>
                                        onChangeSubCategory(
                                          e,
                                          sC.id,
                                          subCategoryLevel
                                        )
                                      }
                                    >
                                      <span>{sC.name}</span>
                                      <FiChevronRight
                                        className="ms-2"
                                        size={20}
                                      />
                                    </button>
                                  ))}
                                </Col>
                              );
                            }
                          )}
                      </Row>
                      <h6 className="text-muted mt-4">
                        The currently selected:{" "}
                        <span
                          className="ms-2"
                          style={{ color: "var(--orange-primary" }}
                        >
                          {selectedCategories.map((c) => (
                            <>{c.name + " > "} </>
                          ))}
                        </span>
                      </h6>
                    </>
                  )}

                  {allSelected && (
                    <GenericButton
                      type="button"
                      onClick={submitSelectedCategories}
                      style={{ width: "200px", borderRadius: "50px" }}
                      className="d-block m-auto py-2 my-3 mx-0"
                    >
                      Done
                    </GenericButton>
                  )}
                </StyledBox>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <MakeFeePayment />
      )}
    </div>
  ) : (
    <UploadInBulk />
  );
};

export default Catalogue;
