import React from "react";
import Select from "react-select";

const GenericSelect = () => {
  let selectOption = [{ label: "label", value: "label" }];
  return (
    <Select
      className="fund-raise-select basic-single"
      classNamePrefix="select"
      isClearable={false}
      name="color"
      options={selectOption}
    />
  );
};

export default GenericSelect;
