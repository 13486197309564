import React, { useLayoutEffect, useState } from "react";
import PageTitle from "../../compoents/PageTitle";
import { GenericButton } from "../../compoents/Button";
import Loader from "../../compoents/Loader";
import BrandForm from "./BrandForm/BrandForm";
import BrandList from "./BrandList";
import { toast } from "react-toastify";
import BrandDataService from "./../../redux/services/brand-api-services";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import styled from "styled-components";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { addBrand } from "../../redux/slices/brand";

const BrandPagination = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  .brand__pageLink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    gap: 4px;
    user-select: none;
  }
  .brand__pageDisable {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

function Brands() {
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [pageDetail, setPageDetils] = useState({
    pageNumber: 1,
    next: null,
    prev: null,
  });
  const dispatch = useDispatch();
  const auth = useAuth();
  const perPageCount = 5;

  const fetchAllBrands = async (page = 0) => {
    try {
      const config = {
        header: auth,
      };
      const res = await BrandDataService.getAllBrands({
        data: config,
        size: perPageCount,
        page,
      });
      const { data } = res;
      setLoading(() => false);
      setPageDetils((prev) => ({
        ...prev,
        pageNumber: page,
        next: data?.next,
        prev: data?.previous,
        total: data?.count,
        totalPage: Math.ceil(data?.count / perPageCount),
      }));
      dispatch(addBrand(data?.results));
      setBrandList(() => data?.results ?? []);
    } catch (error) {
      setLoading(() => false);
      toast.error("Error while fetching.");
    }
  };

  useLayoutEffect(() => {
    setLoading(true);
    fetchAllBrands(pageDetail.pageNumber);
  }, []);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  if (showForm) {
    return <BrandForm closeForm={handleCloseForm} />;
  }

  return (
    <div className="position-relative">
      <PageTitle text="Manage Brand" />
      <>
        {loading ? (
          <>
            <Loader />
            <Loader />
            <Loader />
          </>
        ) : (
          <>
            <div className="d-flex position-absolute top-0 end-0 px-sm-3 px-0">
              <GenericButton
                className="shadow-none fs-6"
                onClick={() => setShowForm(true)}
              >
                Add Brand
              </GenericButton>
            </div>
            <BrandList list={brandList} />
            {(pageDetail?.next || pageDetail?.prev) && (
              <BrandPagination>
                <div
                  className={`brand__pageLink ${
                    !pageDetail?.prev ? "brand__pageDisable" : null
                  }`}
                  role="button"
                  onClick={() => fetchAllBrands(pageDetail?.pageNumber - 1)}
                >
                  <AiOutlineDoubleLeft />
                  Prev
                </div>
                <div
                  className={`brand__pageLink ${
                    !pageDetail?.next ? "brand__pageDisable" : null
                  }`}
                  role="button"
                  onClick={() => fetchAllBrands(pageDetail?.pageNumber + 1)}
                >
                  Next
                  <AiOutlineDoubleRight />
                </div>
              </BrandPagination>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default Brands;
