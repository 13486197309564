import React from "react";
import styled from "styled-components";
import { MdFileDownload } from "react-icons/md";
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { chatSelector } from "../../../redux/slices/ticket_slice";
import { download_base_url } from "../../../config";

const StyledMessage = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  .chatMsg__messageDiv {
    max-width: 80%;
    min-width: 50%;
    padding: 8px 8px;
    margin-bottom: 8px;
    .chatMsg__heading {
      display: flex;
      justify-content: space-between;
      line-height: 100%;
      font-size: 12px;
    }
    .chatMsg__msg {
      line-height: 120%;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .chatMsg__link {
        padding: 12px;
        background: #fff;
        border-radius: 8px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        max-width: 100%;
        overflow: hidden;
      }
    }
  }
  .chatMsg__messageDiv-receiver {
    border-radius: 8px 8px 8px 0;
    background: lightblue;
  }
  .chatMsg__messageDiv-sender {
    border-radius: 8px 8px 0 8px;
    margin-left: auto;
    background: lightgrey;
  }
`;

function TicketMessage() {
  const details = useSelector(chatSelector);
  const scrollRef = useRef(null);

  useEffect(() => {
    const interval = setTimeout(() => {
      scrollRef?.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
    return () => clearTimeout(interval);
  }, []);

  return (
    <StyledMessage>
      {details?.chatList?.map((data) => (
        <div
          key={data.id}
          className={`chatMsg__messageDiv ${
            data?.user !== details?.user
              ? "chatMsg__messageDiv-receiver"
              : "chatMsg__messageDiv-sender"
          }`}
        >
          <div className="chatMsg__heading">
            <div>{data?.user !== details?.user ? data.user_name : "Me"}</div>
            <div>{data.created.split("T")[0]}</div>
          </div>
          <hr className="my-2" />
          <div className="chatMsg__msg">
            <>{data?.description}</>
            {data?.contain_doc && (
              <a
                className="chatMsg__link"
                target="_blank"
                rel="noreferrer"
                href={`${download_base_url}${data.document?.path}`}
              >
                <MdFileDownload size={18} />
                {data.document?.file_name}
              </a>
            )}
          </div>
        </div>
      ))}
      <div ref={scrollRef} />
    </StyledMessage>
  );
}

export default TicketMessage;
