// eslint-disable-next-line
import React from "react";
import { MdPermContactCalendar } from "react-icons/md";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PATH } from "../../config";

const StyledLink = styled.li`
  color: #ff4d00;
  font-weight: bold;
  a {
    color: #ff4d00;
    letter-spacing: 0px;
  }
`;

const LegalPolices = () => {
  return (
    <div>
      <div className="d-flex align-items-center border-botton-grey pb-2">
        <MdPermContactCalendar size={30} />
        <h4>Legal & policies</h4>
      </div>
      <ol className="my-3">
        <StyledLink className="p-2">
          <Link to={PATH.ANTI_PHISHING_POLICY} className="text-decoration-none">
            Anti Phishing Policy
          </Link>
        </StyledLink>
        <StyledLink className="p-2">
          <Link to={PATH.PRIVACY_POLICIES} className="text-decoration-none">
            Privacy Policy
          </Link>
        </StyledLink>
        <StyledLink className="p-2">
          <Link
            to={PATH.INTELLECTUAL_PROPERTY_POLICY}
            className="text-decoration-none"
          >
            Intellectual Property Policy
          </Link>
        </StyledLink>

        <StyledLink className="p-2">
          <Link to={PATH.SUPLIER_AGREEMENT} className="text-decoration-none">
            Suplier Agreement
          </Link>
        </StyledLink>

        <StyledLink className="p-2">
          <Link to={PATH.SUPLIER_DEACTIVATION} className="text-decoration-none">
            Seller Deactivation Policy
          </Link>
        </StyledLink>

        <StyledLink className="p-2">
          <Link
            to={PATH.PROHABITIED_AND_RESTRICTED_POLICY}
            className="text-decoration-none"
          >
            Prohabitied and Restricted Product Policy
          </Link>
        </StyledLink>

        <StyledLink className="p-2">
          <Link to={PATH.TERMS_CONDITION} className="text-decoration-none">
            Terms and Conditions
          </Link>
        </StyledLink>

        <StyledLink className="p-2">
          <Link
            to={PATH.ADDITIONAL_SELLER_POLICIES}
            className="text-decoration-none"
          >
            Additional Seller Policies
          </Link>
        </StyledLink>

        <StyledLink className="p-2">
          <Link
            to={PATH.SELLER_REFERRAL_PROGRAM}
            className="text-decoration-none"
          >
            Seller Referral Program
          </Link>
        </StyledLink>

        <StyledLink className="p-2">
          <Link
            to={PATH.WHISTLE_BLOWSER_POLICY}
            className="text-decoration-none"
          >
            Whistle Blower Policy
          </Link>
        </StyledLink>

        <StyledLink className="p-2">
          <Link
            to={PATH.COURIER_PARTNER_CLAIMS}
            className="text-decoration-none"
          >
            Courier Partner Preference and Claims
          </Link>
        </StyledLink>
      </ol>
    </div>
  );
};

export default LegalPolices;
