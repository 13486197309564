import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { GenericButton } from "../../../compoents/Button";
import { GENERIC_MESSAGE, IMAGES, PATH } from "../../../config";
import { registerAccount, userSelector } from "../../../redux/slices/account";
import * as Yup from "yup";
import VerifyOtp from "../VerifyOtp";
import { Navbar } from "react-bootstrap";
import { RegisterContainer, StyledContainer } from "./RegisterStyled";
import RegisterForm from "./RegisterForm";

const Register = () => {
  let history = useHistory();
  let dispatch = useDispatch();
  let { createUserSuccess, createUserFailure } = useSelector(userSelector);
  // verified state

  const goToLoginScreen = () => {
    history.push(PATH.LOGIN);
  };

  const CreateAccountSchema = Yup.object().shape({
    mobile: Yup.number()
      .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE)
      .min(10, "Number should be 10 digits only."),
    name: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    // type: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    email: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  });

  useEffect(() => {}, [createUserSuccess, createUserFailure]);

  const onSubmit = (values) => {
    dispatch(registerAccount(values));
    sessionStorage.setItem("phone", values?.mobile);
  };

  const initVal = {
    mobile: "",
    name: "",
    email: "",
    type: "SELLER",
  };

  return (
    <StyledContainer>
      <div className="register__bg" />
      <Navbar expand="lg" className="register__navbar">
        <Navbar.Brand>
          <Link to={PATH.LANDING_PAGE}>
            <img
              className="register__logo"
              src={IMAGES.COMPANY_LOGO}
              alt="fund-raiser-logo"
            />
          </Link>
        </Navbar.Brand>
        <GenericButton className="register__navBtn" onClick={goToLoginScreen}>
          Login
        </GenericButton>
      </Navbar>
      <div className="register__container">
        <div className="register__content">
          <div className="register__heading">
            <h1>
              {!createUserSuccess ? (
                <>
                  Create
                  <br />
                  New Account
                </>
              ) : (
                <>Enter Otp</>
              )}
            </h1>
            <>
              <p>
                {!createUserSuccess && (
                  <span>
                    Already Registered?{" "}
                    <Link to={PATH.LOGIN} className="register__link">
                      Login
                    </Link>
                  </span>
                )}
              </p>
              <div className="register__points">
                {!createUserSuccess ? (
                  <>
                    <div>Mandatory thing -</div>
                    <ul>
                      <li>Company details</li>
                      <li>GST details</li>
                      <li>Bank details (For Pyouts)</li>
                      <li>Signature Image</li>
                    </ul>
                  </>
                ) : (
                  <div>
                    Enter OTP, which is received on the register phone no.{" "}
                  </div>
                )}
              </div>
            </>
          </div>
          <RegisterContainer>
            {!createUserSuccess ? (
              <RegisterForm
                onSubmit={onSubmit}
                initVal={initVal}
                validateSchema={CreateAccountSchema}
              />
            ) : (
              <div className="register__verifyOtpDiv">
                <VerifyOtp />
              </div>
            )}
          </RegisterContainer>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Register;
