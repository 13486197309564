import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { GenericButton } from "../../compoents/Button";
import { IMAGES, PATH } from "../../config";
const StyledAuthHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  // border: 1px solid #707070;
  /* position: fixed;
  top: 0px; */
`;

const StyledLandingPage = styled.div`

.heading-4{
color:#122736;
font-size:33px;
line-height:44px;
}

.heading-3{
    color:#676767;
    font-size:30px;
    line-height:50px
    font-weight:400;
}
.thumbnail-img{
    height:100%;
    width:100%;
}
.thumbnail-text-container{
    padding: 30px;
    background-color: #00000029;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.why-use-tittle{
    max-width: 100%;
    width: 440px;
    text-align: left !important;
}

.why-us-desc{
    width:440px;
    max-width: 100%;
    font-size:20px;
    font-weight:400;
    word-spacing:0.42px;
    color:#3D3D3D;
}

.footer{
    .text-grey{
        color:#818181 !important;
    }
    /* padding */
    .below-logo-desc{
        font-size:18px;
    }
    .list-style-none{
        list-style:none;
    }
    
}

.copy-right-footer{
    color:#252525;
}
.border-top-1{
        border-top: 1px solid black;
        font-size:20px;
    }
`;

const FooterBgSection = styled.div`
  background-image: url(${IMAGES.FOOTER_BG});
  min-height: 600px;

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const FooterBgWithColor = styled.div`
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0.6979166666666667) 0%,
    rgba(255, 255, 255, 0.29175420168067223) 0%
  );
  /* opacity: 0.73; */
  min-height: 600px;
  height: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  .hero-text-tittle {
    font-size: 62px;
    margin: auto;
    max-width: 800px;
    width: 100%;
  }
  .hero-text-sub-tittle {
    font-size: 32px;
    max-width: 800px;
    width: 100%;
    margin: 30px auto;
  }
`;

const TermsAndCondition = () => {
  let history = useHistory();
  const goToLoginScreen = () => {
    history.push(PATH.LOGIN);
  };

  return (
    <StyledLandingPage>
      <StyledAuthHeader className="container-fluid px-md-5 px-3 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link to={PATH.LANDING_PAGE}>
              <img
                style={{ width: "70px" }}
                src={IMAGES.COMPANY_LOGO}
                alt="fund-raiser-logo"
              />
            </Link>
          </div>
          <div>
            <GenericButton
              onClick={goToLoginScreen}
              variant="none"
              className={"py-2 px-5"}
            >
              Login
            </GenericButton>
          </div>
        </div>
      </StyledAuthHeader>

      <div className="container py-4">
        <h4>TERMS AND CONDITIONS</h4>
        <p>
          Please read these terms and conditions ("Terms") carefully before
          accessing or using the Platform (defined hereinafter). These Terms
          along with the Privacy Policy published on the Platform ("Privacy
          Policy") and other policies (as may be notified/displayed/published on
          the Platform) constitutes the contract between the Users of this
          Platform and Company (collectively "Agreement"). By use of the
          Platform, Users agree to be bound by these Agreement as posted on the
          Platform from time to time.
        </p>

        <h5>1. ABOUT THE TERMS</h5>
        <p>
          <strong>1.1. What is Groupick and who operates it?</strong>
          <br />
          (a) Groupick is an application which provides an online marketplace
          ("Application") where registered Sellers ("Sellers") can offer to sell
          their products to registered users of Application including to
          resellers ("Resellers") and other Users.
          <br />
          (b) The Application and the website at www.Groupick.in ("Website")
          (collectively, "Platform") are operated by Getzie Technology Private
          Limited ("Company").
          <br />
          (c) The Company’s role is limited to the managing Application and
          associated marketing, facilitating payment collections, fulfillment,
          order management, enquiry management and other incidental services to
          enable the transactions between the Sellers and the Reseller
          ("Services").
          <br />
          (d) Services are not made available on the Website and to avail the
          same, Users are required to install the Application.
        </p>

        <h5>1.2. When are these Terms applicable and binding on User?</h5>
        <p>
          (a) The Agreement is applicable to any person when they install,
          download or even merely visit or access any part of the Platform or
          utilize the Services, such persons are referred to as users, which
          include without limitation users who are browsers, Sellers, Resellers,
          merchants, other purchaser or contributors of content (collectively,
          "User").
          <br />
          (b) The Agreement between User and Company is effective on the date on
          which the Application is downloaded/Website is accessed and/or the
          date on which terms of Agreement are updated, creating a legally
          binding arrangement between the User and the Company.
        </p>

        <h5>1.3. Whether the terms of this Agreement can be modified?</h5>
        <p>
          (a) Users can review the most current version of the Agreement at any
          time on the Website. Company reserves the right to unilaterally
          update, change or replace any part of the Agreement by publishing
          updates or changes on the Platform and such amended provisions of the
          Agreement shall be effective immediately upon being posted on the
          Platform.
          <br />
          (b) It is the responsibility of the Users to check this page
          periodically for changes. The Users’ continued use of or access to the
          Application following the posting of any changes constitutes
          acceptance of those changes.
        </p>

        <h5>
          1.4. What if the terms of the Agreement are not acceptable to User?
        </h5>
        <p>
          If the User does not agree with the terms of the Agreement, the User
          is advised to refrain from using the Platform. By accessing or using
          the platform, the user irrevocably accepts the Agreement and agrees to
          abide by the same (as updated from time to time).
        </p>

        <h5>2. ACCOUNT REGISTRATION, SUSPENSION AND TERMINATION</h5>
        <p>
          <strong>
            2.1. Does a User necessarily need to create an account on the
            Platform?
          </strong>
          <br />
          Company does not permit Users to avail the Services on the Platform
          without prior registration. Users may access the Application by
          registering to create an account and become a member. The membership
          is limited for the purpose of buying or selling products, is subject
          to this Agreement and strictly not transferable.
        </p>

        <h5>
          2.2. For the use of Platform, is a User subject to any eligibility
          criteria?
        </h5>
        <p>
          (a) The Services on the Platform shall be availed by User(s) who can
          form legally binding contracts under Indian Contract Act, 1872 and are
          at least eighteen (18) years of age.
          <br />
          (b) The Company reserves the right to terminate the Users account and
          / or deny access to the Platform if it is brought to the Company’s
          notice or if it is discovered that the User does not meet the
          conditions herein. User(s) accessing or using the Platform represent
          and warrant that they have the right to access or use the Platform.
        </p>

        <h5>
          2.3. Are there any specific requirements for registering an account on
          Platform?
        </h5>
        <p>
          (a) The Users are required to enter a valid phone number while
          registering on Platform. By such registration, User consents to be
          contacted by Company via phone calls, SMS notifications, instant
          messages or other such means of communication inter alia for
          subscription/services/promotional updates etc. Users may 'opt-out' of
          such subscription/service/promotional updates either through the ‘opt
          out’ means provided or by writing to the support team.
          <br />
          (b) It is the responsibility of the Users to provide the correct
          mobile number so that the Company can communicate with the Users via
          SMS. The Users understand and agree that if the Company sends an SMS
          but the Users do not receive it because the Users’ mobile number is
          incorrect or out of data or blocked by the User's service provider, or
          the Users are otherwise unable to receive SMS, the Company shall be
          deemed to have provided the communication to the Users effectively.
          <br />
          (c) It is the User’s responsibility to provide accurate, current and
          complete information during the registration process and to update
          such information to keep it accurate, current and complete.
        </p>

        <h5>
          2.4. Can User account registered on Platform be suspended or
          terminated?
        </h5>
        <p>
          (a) The Company reserves the right to suspend or terminate the account
          or access to Services (or any part thereof) on the Application
          including blocking any amounts due to the User and associated account
          without notice, and the Users will remain liable for all amounts due
          up to and including the date of termination if:
          <br />
          (i) any information provided during the registration process or
          thereafter proves to be inaccurate, not current or incomplete; and/or
          <br />
          (ii) in Company’s assessment, the User has: ● charged an unreasonably
          high price; ● unreasonable instances of returns and/or cancellations
          initiated; ● engaged in actions that are fraudulent, negligent or
          derogatory to the Company’s interests. ● failed or is suspected to
          have failed to comply with any term or provision of the Agreement or
          applicable law.
          <br />
          (iii) User is found to be non-compliant with the Agreement.
          <br />
          (b) Further, where the violation of the Agreement gives rise to
          criminal or civil action, the Company may at its sole discretion
          pursue such action.
          <br />
          (c) Without prejudice to the above stated rights of the Company, in
          case of alleged fraud or other breach of this Agreement by User,
          Company may at its sole discretion (a) withhold all amounts payable to
          such User; and (b) impose penalties as the Company may reasonably
          determine and set off such penalties from the monies payable by
          Company to such User.
        </p>

        <h5>
          2.5. What are User obligations vis-à-vis its registered account on
          Platform?
        </h5>
        <p>
          (a) Having an account on the Platform gives authenticity to the
          actions of the User. It means that the User is solely responsible for
          all activities that occur under its account and that all transactions
          made by such User are intended for bona fide sale or consumption in
          the course of their business activities.
          <br />
          (b) Any and every activity undertaken by a User under his/her account
          shall be the sole responsibility of such User, and the Company shall
          not be liable for such activity in any manner. Hence it shall be the
          responsibility of the User to treat the user identification code,
          password, and any other piece of information that is provided by the
          Company, as part of the security procedures, as confidential and not
          disclose the same to any person or entity other than the Company.
          <br />
          (c) User acknowledges and agrees that having an account on Platform
          does not grant it any rights on Platform not specifically granted to
          them by the Company, and that the User has no ownership or other
          interest in the account. The User understands that all rights in and
          to the account are and shall forever be owned by and inure to the
          benefit of the Company.
          <br />
          (d) On registration, the Users may receive a password-protected
          account and an identification. The Users agree to:
          <br />
          (i) maintain the confidentiality of their password, if applicable;
          <br />
          (ii) take full responsibility for all activities by Users accessing
          the Application through their account;
          <br />
          (iii) immediately notify the Company of any unauthorized use of their
          account or any other breach of security that they become aware of; and
          <br />
          (iv) ensure that they exit from their account at the end of each
          session.
        </p>
        <h4>3. PLACING ORDERS AND FINANCIAL TERMS</h4>

        <h5>3.1. How does order placement work on the Platform?</h5>
        <p>
          (a) The Application allows Users to place orders for the products
          listed by Sellers on Application, and the Application, subject to
          Agreement herein, facilitates the placement of orders for the products
          by the Users.
          <br />
          (b) On receipt of an order from a User, Company shall send
          electronically a confirmation of such order to Seller and the User
          concerned. Further, the Company may inform the User about the
          availability or unavailability or change in price of the order as
          informed by Seller concerned, from time to time. Confirmation of the
          order by Seller shall be treated as final.
          <br />
          (c) The Company does not own, sell, or resell any products on its own
          and/or does not control the Sellers and only facilitates the
          transaction between buyers and sellers, including User and Seller as a
          'marketplace'. Company makes all reasonable efforts to promptly update
          the Users account and other information in order to assist facilitate
          the transaction completion. Hence, Users are required to provide
          current, complete, and accurate purchase and account information for
          all purchases made at on the Application.
          <br />
          (d) Additionally, fulfillment of orders to their end users/consumers
          by Reseller shall be the responsibility of the Reseller inter se such
          end user/consumer. Any add-on service provided by Platform towards
          such order shall be provided merely as a service provider of such
          Reseller by the Platform and accordingly, Platform shall not have any
          privity of contract with such end user/consumer.
        </p>

        <h5>3.2. How are the commercial terms fixed on Application?</h5>
        <p>
          (a) All commercial/contractual terms of sale are offered by Sellers
          and agreed to between Sellers and the Users alone. The
          commercial/contractual terms include without limitation, price, date,
          period, and mode of delivery, warranties related to products, etc.
          Company does not have any control or does not determine or advise or
          in any way involve itself in the offering or acceptance of such
          commercial/contractual terms between the Sellers and the Users. All
          discounts and offers are by the Sellers and not by the Company.
          <br />
          (b) Similarly, in case of deliveries effected by Resellers using the
          Platform, the Platform only acts as a service provider for the
          Reseller facilitating delivery or other service related to an order.
          Company does not have any control nor does not determine or advise or
          in any way involve itself in the offering or acceptance of such
          commercial/contractual terms between Reseller and its end
          user/consumer.
          <br />
          (c) Policies related to returns/exchanges, penalties, refunds,
          cancellation will be updated in the Application from time to time. The
          Company holds the right to change these policies as required in the
          Application without any permission from the Users.
        </p>

        <h5>
          3.3. How does payment and settlement of payment work on the Platform?
        </h5>
        <p>
          (a) The Users acknowledge and agree that the Company may, at the
          request of the Seller or the Reseller, act as the payment agent for
          the limited purpose of accepting payments on behalf of such Sellers
          and Resellers. The Users understand, accept and agree that the payment
          facility provided by the Company is neither a banking nor financial
          service but is merely a facilitator providing a third party payment
          processor for the transactions on the Application. Further, by
          providing payment facility, the Company is neither acting as a trustee
          nor acting in a fiduciary capacity with respect to the transaction or
          the transaction price. The Company will not be liable for any charges
          made by the Users bank in relation to payment of the total amount.
          <br />
          (b) In connection with any order, information such as name, billing
          address and credit card information may need to be provided either to
          the Company or the third party payment processor. If the Users are
          directed to the third party payment processor, they may be subject to
          terms and conditions governing use of that third party’s service and
          that third party’s personal information collection practices. Users
          are requested to review such terms and conditions and privacy policy
          before using the Application. In case of Reseller providing billing
          information, delivery address or other contact information of its end
          user/consumer to Company or other delivery service provider, Reseller
          shall ensure that it has necessary consents and approvals from the
          respective end user/consumer as may be required under applicable law.
          <br />
          (c) Company merely collects the payment on behalf of the Seller or
          Reseller, as the case may be. All applicable taxes and levies, the
          rates thereof and the manner of applicability of such taxes are to be
          charged and determined by the Seller or Reseller. Company holds no
          responsibility for the legal correctness/validity of the levy of such
          taxes. The sole liability with respect to any legal issue arising on
          the taxes payable shall be with the Reseller.
          <br />
          (d) The transaction is bilateral between Sellers & Users and/or
          Reseller & end users/consumers ("User Transactions"), the Company is
          not liable to charge or deposit any taxes applicable on such
          transaction.
        </p>

        <h5>
          3.4. Whether Company charges User(s) for Services provided by Company
          on the Platform?
        </h5>
        <p>
          (a) Services on Platform may require payment of charges, rate of which
          shall be solely at the discretion of the Company and shall be subject
          to User approval at the time of placing an order on the Platform.
          Company reserves the right to revise charges towards Service at any
          time at its sole discretion. The charge, applicable at any given time,
          will be the charge displayed at the time of purchase/booking of the
          respective Service (if any) by User on the Platform.
          <br />
          (b) Company reserves the right to introduce additional chargeable
          services on the Platform including charges for a premium return
          service, cancellation charges, cash on delivery handling fees etc.
        </p>

        <h5>
          3.5. Whether for transacting on Platform, User is required to be
          registered under the Central or State Goods and Services Tax
          Legislations ("GST Laws")?
        </h5>
        <p>
          Company is not obligated towards any direct or indirect tax obligation
          of the User that may arise as a result of User's access or use of
          Services on the Platform. The requirement for registration and
          compliances under the GST Laws and other tax laws is the sole
          responsibility of the User including Reseller, the Company is not
          liable for any omissions or commissions by such User who acts in
          violation of any applicable law. Accordingly, User is advised to seek
          independent tax advice relating to its business and/or transaction
          through Platform including whether it is liable for GST registration.
        </p>
        <h5>4. USE OF THE PLATFORM</h5>

        <p>
          <strong>
            4.1. Can User access and use the Platform at any time or could there
            be any limitations?
          </strong>
        </p>

        <p>
          (a) Company endeavours to make the Application available 24X7.
          However, the Company does not represent that access to the Application
          will be uninterrupted, timely, error-free, free of viruses or other
          harmful components or that such defects will be corrected.
        </p>

        <p>
          (b) Users understand and acknowledge that the use of Application
          requires internet connectivity and telecommunication links. Users
          shall bear the costs incurred to access and use the Application and
          avail Services, and Company shall not, under any circumstances
          whatsoever, be responsible or liable for such costs.
        </p>

        <p>
          (c) Company does not warrant that Application will be compatible with
          all hardware and software which is used by Users.
        </p>

        <p>
          (d) Application may be under constant upgrades, and some functions and
          features may not be fully operational. Application is provided on an
          'as is' and 'as available' basis. Company expressly disclaims all
          warranties of any kind, whether express or implied with respect to the
          records and other data that is made available by it to Users.
        </p>

        <p>
          (e) Users shall be solely responsible for damages to their data system
          or for loss of data arising from download of content from Application.
          No guidance or information, written or oral, obtained from Company or
          via Platform, shall constitute any warranty unless stated otherwise.
        </p>
        <p>
          <strong>
            4.2. Does the Company guarantee performance of the agreement or
            other arrangements inter se between User(s) or otherwise in respect
            of products on Platform?
          </strong>
        </p>

        <p>
          (a) Company, through Platform, is a mere facilitator of the
          transaction including between Seller and User and is not responsible
          for any non-performance or breach of any contract entered into towards
          User Transactions. The Company cannot and does not guarantee that the
          concerned Sellers will perform any transaction concluded on the
          Platform. The Company shall not and is not required to mediate or
          resolve any dispute or disagreement between the Users concerned
          including with any other third party.
        </p>

        <p>
          (b) The Company does not represent any of User or Seller, and
          disclaims any liability with respect to any error or inconsistency
          with respect to any information relating to such Seller or User
          displayed on the Platform.
        </p>

        <p>
          (c) The Company does not make any representation or warranty as to the
          item-specifics (such as legal title, creditworthiness, identity, etc.)
          of any of its Users. Company shall not be liable for any misuse of
          information shared by Users with it; or through the Users profile; or
          with a third party on the Platform, chat rooms, forums, or comments.
        </p>

        <p>
          (d) Users acknowledge and agree that the Company is not an arbitrator
          or judge of disputes concerning intellectual property and it cannot,
          by any means, verify that any Seller or Reseller selling or supplying
          merchandise on/through the Platform have the right to sell the
          products. Company encourages Users to assist it in identifying
          listings on the Platform, which, according to the Users’ knowledge or
          belief infringe their rights or third party rights.
        </p>

        <p>
          (e) Company does not at any point of time during any transaction
          between any Seller and a User take possession of any product offered
          nor does it at any point gain title to or have any rights or claims
          over such products. At no time shall the Company hold any right, title
          or interest over the products nor shall the Company have any
          obligations or liabilities in respect of such contract entered into
          between the Users. Company is not responsible for damages or delays as
          a result of products which are out of stock, unavailable or back
          ordered.
        </p>
        <p>
          <strong>
            4.3. Whether the use of Platform (a) is restricted in any manner;
            and (b) requires any generic compliances from User?
          </strong>
        </p>

        <p>
          (a) User should not use the Platform to host, display, upload,
          download, modify, publish, transmit, update, or share any information
          which:
        </p>

        <ul className="list-unstyled">
          <li>
            (i) is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, libellous, slanderous, criminally
            inciting, or invasive of another’s privacy, hateful, or racially,
            ethnically objectionable, disparaging, relating or encouraging money
            laundering or gambling, or otherwise unlawful in any manner
            whatsoever; or unlawfully threatening or unlawfully harassing,
            including but not limited to "indecent representation of women"
            within the meaning of the Indecent Representation of Women
            (Prohibition) Act, 1986;
          </li>
          <li>
            (ii) is patently offensive to the online community, such as sexually
            explicit content, or content that promotes obscenity, paedophilia,
            racism, bigotry, hatred, or physical harm of any kind against any
            group or individual;
          </li>
          <li>(iii) harasses or advocates harassment of another person;</li>
          <li>
            (iv) infringes upon or violates any third party’s rights including,
            but not limited to, intellectual property rights, rights of privacy
            (including without limitation unauthorized disclosure of a person’s
            name, email address, physical address, or phone number) or rights of
            publicity;
          </li>
          <li>
            (v) promotes an illegal or unauthorized copy of another person's
            copyrighted work, such as providing pirated computer programs or
            links to them, providing information to circumvent
            manufacture-installed copy-protect devices;
          </li>
          <li>
            (vi) tries to gain unauthorized access or exceeds the scope of
            authorized access to the Application or to the profiles, blogs,
            communities, account information, or other areas of the Application
            or solicits passwords or personal identifying information for
            commercial or unlawful purposes from other Users;
          </li>
          <li>
            (vii) interferes with another User’s use and enjoyment of the
            Platform or any third party users' enjoyment of similar services;
          </li>
          <li>
            (viii) refers to any website or URL that, in our sole discretion,
            contains material that is inappropriate for the Platform or any
            other website, contains content that would be prohibited or violates
            the spirit of these Terms;
          </li>
          <li>(ix) violates any law for the time being in force;</li>
          <li>(x) impersonates another person;</li>
          <li>
            (xi) contains software viruses or other computer programming
            routines that may damage, detrimentally interfere with, diminish the
            value of, surreptitiously intercept, or expropriate any system,
            data, or personal information; and
          </li>
          <li>
            (xii) directly or indirectly, offers, attempts to offer, trades, or
            attempts to trade in any item, dealing of which is prohibited or
            restricted in any manner under the provisions of any applicable law,
            rule, regulation, or guideline for the time being in force.
          </li>
        </ul>

        <p>
          (b) When accessing or using the Platform or availing the Services, the
          User has to comply and ensure the following:
        </p>

        <ul className="list-unstyled">
          <li>
            (i) All registration information submitted by User is truthful,
            lawful, and accurate;
          </li>
          <li>
            (ii) User's use of the Application/Platform shall be solely for
            their use, and they shall not authorize others to use the account;
          </li>
          <li>
            (iii) User does not submit, post, upload, distribute, or otherwise
            make available or transmit any information that: (i) is defamatory,
            abusive, harassing, insulting, threatening, or that could be deemed
            to be stalking or constitute an invasion of a right of privacy of
            another person; (ii) is bigoted, hateful, or racially or otherwise
            offensive; (iii) is violent, vulgar, obscene, pornographic or
            otherwise sexually explicit; (iv) is illegal or encourages or
            advocates illegal activity or the discussion of illegal activities
            with the intent to commit them;
          </li>
          <li>
            (iv) All necessary licenses, consents, permissions, and rights are
            owned by Users, and there is no need for any payment or permission
            or authorization required from any other party or entity to use,
            distribute, or otherwise exploit in all manners permitted by the
            Agreement, all trademarks, copyrights, patents, trade secrets,
            privacy and publicity rights and / or other proprietary rights
            contained in any content that Users submit, post, upload,
            distribute, or otherwise transmit or make available;
          </li>
          <li>
            (v) User will not use Platform in any way that is unlawful, or harms
            the Company or any other person or entity;
          </li>
          <li>
            (vi) User will not post, submit, upload, distribute, or otherwise
            transmit or make available any software or other computer files that
            contain a virus or other harmful component, or otherwise impair or
            damage the Platform or any connected network, or otherwise interfere
            with any person or entity’s use or enjoyment of Application;
          </li>
          <li>
            (vii) User will not use another person’s username, password or other
            account information, or another person’s name, likeness, voice,
            image, or photograph or impersonate any person or entity or
            misrepresent your identity or affiliation with any person or entity;
          </li>
          <li>
            (viii) User will not or attempt to delete or modify any content of
            Platform, including but not limited to, disclaimers or proprietary
            notices such as copyright or trademark symbols, logos;
          </li>
          <li>
            (ix) User will not post or contribute any information or data that
            may be obscene, indecent, pornographic, vulgar, profane, racist,
            sexist, discriminatory, offensive, derogatory, harmful, harassing,
            threatening, embarrassing, malicious, abusive, hateful, menacing,
            defamatory, untrue or political or contrary to our interest;
          </li>
          <li>
            (x) User shall not access Platform without authority or use Platform
            in a manner that damages, interferes or disrupts, any part of
            Platform or any equipment or any network on which Platform is stored
            or any equipment of any third party;
          </li>
          <li>
            (xi) User shall not attempt to gain unauthorized access to any
            portion or feature of the Application, or any other systems or
            networks connected to the Platform by any means. User shall not
            probe, scan or test the vulnerability of Platform nor breach the
            security or authentication measures on Platform or any network
            connected to Platform;
          </li>
          <li>
            (xii) User agrees not to use any device, software or routine to
            interfere or attempt to interfere with the proper working of
            Platform or any transaction being conducted on Platform, or with any
            other person’s use of Platform. User may not use Platform or any of
            its content for any purpose that is unlawful or prohibited by this
            Agreement;
          </li>
          <li>
            (xiii) User shall at all times ensure full compliance with the
            applicable law, as amended from time to time, including that of the
            Information Technology Act, 2000 and the rules thereunder; all
            applicable domestic laws, rules and regulations (including the
            provisions of any applicable exchange control laws or regulations in
            force); and international laws, foreign exchange laws, statutes,
            ordinances and regulations (including, but not limited to Direct and
            Indirect Taxes applicable as per current statue in the country)
            regarding the use of the Application and listing, purchase,
            solicitation of offers to purchase, and sale of products or
            Services. User shall not engage in any transaction which is
            prohibited by the provisions of any applicable law including
            exchange control laws or regulations for the time being in force;
          </li>
          <li>
            (xiv) In order to allow Company to use the information supplied by
            the Users, without violating any rights or any laws, Users agree to
            grant Company a non-exclusive, worldwide, perpetual, irrevocable,
            royalty-free, sub-licensable (through multiple tiers) right to
            exercise the copyright, publicity, database rights or any other
            rights. Company will only use the information in accordance with
            this Agreement, applicable to use of Platform and for provision of
            Services.
          </li>
        </ul>

        <p>
          (c) Company shall at times and at their sole discretion reserve the
          right to disable any user identification code or password if any User
          has failed to comply with any of the provisions of this Agreement.
          Company shall have all the rights to take necessary action and claim
          damages that may occur due to User's involvement/participation in any
          way either on their own or through group/s of people, intentionally or
          unintentionally in hacking.
        </p>
        <p>
          <strong>
            5. ACCURACY AND COMPLETENESS OF INFORMATION ON PLATFORM
          </strong>
        </p>

        <p>
          <strong>
            5.1. What is the accuracy and completeness of all information
            displayed on the Platform?
          </strong>
        </p>

        <p>
          (a) Company takes all endeavors to the best of its efforts to keep
          information on the Platform accurate. However, the material and
          content on the Platform is provided for general information only and
          should not be relied upon or used as the sole basis for making
          decisions without consulting primary, more accurate, more complete, or
          timely sources of information. Reference to paragraph 5.2 below, User
          will agree that a majority of content including products displayed on
          Platform are provided by the respective Sellers, who shall at all
          times be responsible for the provision of information related to such
          products listed by them. Apart from reasonable checks to ensure
          general hygiene of Platform, Company does not exercise any control
          over such content or information.
        </p>

        <p>
          (b) Company undertakes no obligation to update, amend, or clarify
          information in the Platform, including without limitation, pricing
          information, except as required by law. Company does not own any
          responsibility or obligation whatsoever towards either ensuring the
          accuracy of the information provided by the Users. Any reliance on the
          material on Platform is at the Users’ own risk.
        </p>

        <p>
          (c) Platform may contain certain historical information. Historical
          information, necessarily, is not current and is provided for your
          reference only. The Company reserves the right to modify the contents
          of Platform at any time, but has no obligation to update any
          information on Platform. User is solely responsible to monitor changes
          to the information on Platform. No specified update or refresh date
          applied to Platform, should be taken to indicate that all information
          on Platform or pertaining to the Services have been modified or
          updated.
        </p>

        <p>
          (d) Occasionally there may be information on Platform that contains
          typographical errors, inaccuracies, or omissions that may relate to
          information pertaining to the products, pricing, promotions, offers,
          shipping charges, transit times, and availability. Company reserves
          the right to correct any errors, inaccuracies, or omissions, and to
          change or update information if any information on Platform is
          inaccurate at any time without prior notice.
        </p>

        <p>
          (e) The Information is provided 'as is' with no guarantee of
          completeness, accuracy, timeliness, or of the results obtained from
          the use of the Information, and without warranty of any kind, express
          or implied, including, but not limited to warranties of performance,
          merchantability, and fitness for a particular purpose. Nothing
          contained in this Agreement shall to any extent substitute for the
          independent investigations and the sound technical and business
          judgment of User.
        </p>

        <p>
          <strong>
            5.2. Is information related to products on Platform provided by the
            Company?
          </strong>
        </p>

        <p>
          (a) Not all information on the Platform is provided by Company. From
          time to time, Users who are Sellers provide information relating to
          the products proposed to be sold by them and are hence responsible for
          the same. In this connection, Sellers undertake that all such
          information shall be accurate in all respects. Sellers are discouraged
          from and should not exaggerate or overemphasize the attributes of such
          products so as to mislead Users in any manner.
        </p>

        <p>
          (b) Company reserves the right, but has no obligation, to monitor the
          materials posted on Platform. Company, however, has the right to
          remove or edit any content that in its sole discretion violates or is
          alleged to violate any applicable law or either the spirit of these
          Terms. In no event shall Company assume any responsibility or
          liability for any content posted or for any claims, damages, or losses
          resulting from the use of content and/or appearance of content on
          Platform.
        </p>

        <p>
          (c) Sellers take sole responsibility for the correctness of the
          details pertaining to specifics (such as quality, value, saleability,
          etc.) of the products proposed to be sold or offered to be sold or
          purchased on Platform. Company does not implicitly or explicitly
          support or endorse the sale or purchase of any products nor provide
          any warranty/guarantee of the products sold to Users, and in no event
          shall such products be the responsibility of Company. Company does not
          represent or warrant that the information available on Platform will
          be correct, accurate, or otherwise reliable.
        </p>

        <p>
          (d) Company is not responsible for any inaccuracy, incompleteness, or
          outdated information made available on the Application, either
          provided by any User including Sellers.
        </p>
        <p>
          <strong>6. LISTING AND SELLING</strong>
        </p>

        <p>
          <strong>
            6.1. As Seller, in addition to this Agreement, what other terms is a
            User required to abide by?
          </strong>
        </p>

        <p>
          (a) Sellers, in addition to this Agreement are also bound by
          applicable laws of India, including without limitation, the following
          laws:
        </p>

        <p>
          (i) The Legal Metrology Act, 2009 and Legal Metrology (Packaged
          Commodities) Rules, 2011 (Packaging Rules);
        </p>

        <p>
          (ii) Drugs and Cosmetics Act, 1940 and Drugs and Cosmetics Rules, 1945
          (D&amp;C Rules);
        </p>

        <p>
          (iii) Food Safety and standard Act, 2006, Food Safety and Standard
          (Packaging and labelling) Regulation 2011, (FSS Packaging Regulation)
          Food Safety and Standard (Food Product Standard and Food Addictive)
          Regulation, 2011 (FSS Standard Regulations) and Food Safety and
          Standard (food or Health Supplements, Nutraceuticals, Food for Special
          Medical Purpose, Functional Food and Novel Food) Regulation 2016 (FSS
          Supplement Regulation).
        </p>

        <p>
          (b) As per above-mentioned statutes and regulations and any other
          relevant law in place during the tenure of this association, Company
          understands that there is an obligation on Seller to ensure that the
          package in which the products are sold complies with labeling and
          packing requirements and other laws that may be prescribed in this
          regard. Hence, it shall be the sole responsibility of Seller to comply
          with applicable laws and the Company shall not be held responsible in
          any manner. Sellers shall indemnify Company and Platform for any harm
          or loss in relation to contravention of above regulations or other
          applicable laws.
        </p>

        <p>
          <strong>6.2. When can the Sellers get their products listed?</strong>
        </p>

        <p>
          (a) Sellers are permitted to list products for sale on the Application
          in accordance with the terms mentioned in this Agreement and other
          contract entered into with Company for the said purpose, wherein other
          rights and obligations of the parties are defined in detail.
        </p>

        <p>
          (b) By listing its products on the Platform, the Sellers represent and
          warrant that they are legally capable to sell or list the products on
          Platform; and the listed items do not infringe upon the intellectual
          property, trade secret or other proprietary rights or rights of
          publicity or privacy rights of any third party. Sellers and Users
          agree that the Company is not responsible for the breach of the same.
        </p>
        <p>
          <strong>7. USER INFORMATION AND THIRD PARTY TOOLS/LINKS</strong>
        </p>

        <p>
          <strong>
            7.1. What information is collected from the User? How does Company
            deal with the information provided to it by a User while using
            Platform?
          </strong>
        </p>

        <p>
          (a) Company collects various types of information, some information is
          non-personal information and some is personal information.
        </p>

        <p>
          (b) All information about Users that are collected, stored or
          transmitted in any way on Platform is processed for facilitating
          various operations on Platform, including registration, order
          placement, listing, or payments.
        </p>

        <p>
          (c) For a more comprehensive understanding, Users are encouraged to
          view the Platform’s Privacy Policy available on the Platform.
        </p>

        <p>
          <strong>
            7.2. Does the Company use Third Party tools on Platform?
          </strong>
        </p>

        <p>
          (a) The Company may provide User with access to third-party tools over
          the Platform which Company neither monitors nor has any control nor
          input. User acknowledges and agrees that access to such tools is on an
          'as is' and 'as available' basis, without any warranties,
          representations or conditions of any kind and without any endorsement
          by Company. Company shall have no liability whatsoever arising from or
          relating to your use of optional third-party tools.
        </p>

        <p>
          (b) Any use by the User of the optional tools offered through the
          Application/Platform is entirely at its own risk and discretion and it
          is the responsibility of User that it ensures that it is familiar with
          and approves the terms on which such tools are provided by the
          relevant third-party provider(s).
        </p>

        <p>
          (c) The Company may from time to time, offer new features through
          Platform which may include the use of new third-party tools and
          resources. Such new features shall also be subject to this Agreement.
          Complaints, claims, concerns, or questions regarding third-party tools
          or third party websites should be directed to the third-party.
        </p>

        <p>
          <strong>
            7.3. Does Company use third party links or third party tools on
            Platform? Are these links and tools accurate and secure?
          </strong>
        </p>

        <p>
          (a) Certain content or products available via the Platform may include
          materials from third-parties. Third-party links on the
          Application/Platform may direct User to third-party websites that are
          not affiliated with the Company. The Company is not responsible for
          examining or evaluating the content or accuracy and does not warrant
          and will not have any liability or responsibility for any third-party
          materials or websites, or for any other materials, products, or
          services of third-parties.
        </p>

        <p>
          (b) Company is not liable for any harm or damages related to the
          purchase or use of goods, services, resources, content, or any other
          transactions made in connection with any third-party websites
          regardless of the existence of any third-party link on Platform.
          Please review carefully such third-party’s policies and practices and
          make sure to understand them before engaging in any transactions.
        </p>
        <p>
          <strong>8. INTELLECTUAL PROPERTY (IP) AND IP INFRINGEMENT</strong>
        </p>

        <p>
          <strong>
            8.1. Can User use the content published on Platform such as
            "Groupick" mark when doing business with other parties?
          </strong>
        </p>

        <p>
          (a) Users may not use any trademark, service mark or logo of any
          independent third parties without prior written approval from such
          parties.
        </p>

        <p>
          (b) "Groupick" and related icons and logos whether registered or
          unregistered are the trademarks of the Company and are protected under
          applicable copyright, trademark and other proprietary and intellectual
          property laws. Users’ unauthorized adoption copying, modification, use
          or publication of these marks is strictly prohibited.
        </p>

        <p>
          (c) Users must not modify the paper or digital copies of any materials
          printed or downloaded in any way, and they must not use any
          illustrations, photographs, video or audio sequences or any graphics
          separately from any accompanying text.
        </p>

        <p>
          (d) Users must not use any part of the materials on Platform for
          commercial purposes without obtaining a licence to do so from Company.
          All rights, not otherwise claimed under this Agreement by Company are
          hereby reserved.
        </p>

        <p>
          (e) User understands that Platform and software embodied within
          Platform may include security components that permit digital materials
          to be protected, and that use of these materials is subject to usage
          rules set by Company or other parties that facilitate the same. User
          agrees that it will not attempt to override, disable, circumvent or
          otherwise interfere with any such security components and usage rules
          embedded in the Platform.
        </p>

        <p>
          <strong>8.2. How does the Company deal with IP infringement?</strong>
        </p>

        <p>
          (a) Any trademark, word mark or intellectual property of any User(s)
          or Seller(s) belongs to such User(s)/Seller(s) alone, and Company has
          no right or claim over the same.
        </p>

        <p>
          (b) Company reserves the right in its sole discretion to remove any
          material/content/photos/offers displayed on the Platform which in
          Company’s reasonable belief is unlawful or could subject Company to
          liability or is in violation of this Agreement or is otherwise found
          inappropriate in the Company’s opinion. Company reserves the right to
          cooperate with any investigation in this regard.
        </p>

        <p>
          (c) Company reserves the right to suspend or terminate the account of
          a User as deemed appropriate by it. Users agree that the Company shall
          have no liability to any Users, including liability in respect of
          consequential or any other damages, in the event Company takes any of
          the actions pursuant to allegations of IP infringement.
        </p>

        <p>
          (d) Users acknowledge and agree that Company is not an arbitrator or
          judge of disputes concerning intellectual property and it cannot, by
          any means, verify that any Seller selling or supplying merchandise on
          the Platform have the right to sell the products. Company encourages
          Users to assist it in identifying listings on the Platform, which,
          according to Users’ knowledge or belief infringe their rights or third
          party rights.
        </p>

        <p>
          (e) The delisting of product from Platform is to safeguard Company’s
          interest, by taking down a listing, Company does not and cannot be
          deemed to be endorsing a claim of infringement and further in those
          instances in which Company declines to take down a listing, Company
          does not and cannot be deemed to be endorsing that the listing is not
          infringing of third party rights or endorsing any sale or supply of
          merchandise or services pursuant to or on account of such listing.
        </p>

        <p>
          (f) We request you to review the Intellectual Property Policy
          available on the Application for more information.
        </p>
        <p>
          <strong>9. DISCLAIMER AND LIABILITIES</strong>
        </p>

        <p>
          <strong>
            9.1. What are the standard disclaimers in relation to the Platform
            and the Services?
          </strong>
        </p>

        <p>
          (a) Company, in no event, is or will be liable to User including the
          Reseller or anyone claiming through a User in respect of product or
          other User Transaction under contract, negligence, strict liability or
          other legal or equitable theory for any special, incidental, indirect,
          consequential, exemplary or punitive damages, loss of goodwill, loss
          of revenue, loss of opportunity, loss of anticipated profits,
          whatsoever, including those resulting from loss of use, data or
          profits, whether or not foreseeable or whether or not Company has been
          advised of the possibility of such damages, or based on any theory of
          liability, including breach of contract or warranty or negligence or
          any other claim arising out of or in connection with the use of or
          access of Platform.
        </p>

        <p>
          (b) Company shall not be liable for: any injury, loss, claim, or any
          direct, indirect, incidental, punitive, special, or consequential
          damages of any kind, including, without limitation any financial
          losses, loss of data, replacement costs, or any similar damages,
          whether based in contract, tort, strict liability or otherwise,
          arising from the use of Platform, or for any other claim related in
          any way to the use of the Application, including, but not limited to,
          any errors or omissions in any content, or any loss or damage of any
          kind incurred as a result of the use of the Application/Platform or
          any content posted, transmitted, or otherwise made available via the
          Application/Platform, even if advised of their possibility.
        </p>

        <p>
          (c) Users shall be solely responsible for damages, if any, to their
          data system or for loss of data arising from download of content from
          Platform. No guidance or information, written or oral, obtained from
          Company or via the Platform, shall constitute any warranty unless
          stated otherwise.
        </p>

        <p>
          <strong>
            9.2. What happens to User order in case of a lockdown or other force
            majeure event?
          </strong>
        </p>

        <p>
          (a) Company shall not be liable for any damages whatsoever arising out
          of force majeure or other similar circumstances, directly or
          indirectly affecting Company and/or the Platform. Examples of force
          majeure events include without limitation real or potential labor
          disputes, governmental actions, war or threat of war, sabotage, civil
          unrest, demonstrations, fire, storm, flooding, explosion, earthquake,
          epidemic or pandemic, provisions or limitations of materials or
          resources, inability to obtain the relevant authorization, accident,
          and defect in electricity or telecommunication network.
        </p>

        <p>
          (b) Force majeure or other events beyond the Company’s control,
          hindrance, delay, or complication in the maintenance of the Platform
          entitles the Company to suspend or limit the Platform until further
          notice.
        </p>

        <p>
          <strong>
            9.3. Under what circumstances may User be liable for any damages to
            Company?
          </strong>
        </p>

        <p>
          User shall indemnify, defend, and hold harmless Company and its
          subsidiaries, affiliates, partners, officers, directors, agents,
          contractors, licensors, service providers, subcontractors, Sellers,
          interns, and employees, from and against any and all losses,
          liabilities, claims, suits, proceedings, penalties, interests,
          damages, demands, costs, and expenses (including legal and statutory
          fees and disbursements in connection therewith and interest chargeable
          thereon) asserted against or incurred by Company that arise out of,
          result from, or in connection with:
        </p>

        <p>(a) User’s breach of this Agreement;</p>

        <p>
          (b) any claims made by any third party due to, or arising out of, or
          in connection with User’s use of Platform including by end
          users/consumers of Reseller(s);
        </p>

        <p>
          (c) the User’s violation of any rights of another, including
          intellectual property rights; and
        </p>

        <p>(d) the User’s violation of any applicable laws.</p>
        <p>
          <strong>10. COMMUNICATION</strong>
        </p>

        <p>
          <strong>
            10.1. How to contact Company in case of any queries regarding this
            Agreement or grievances relating to Platform?
          </strong>
        </p>

        <p>
          All queries, concerns, or questions about the Agreement should be sent
          to Company at query@Groupick.in or legalsupport@Groupick.in. Lopamudra
          Rao is the designated Grievance Officer in respect of this Agreement.
          Any complaints or concerns with regard to the Platform or any breach
          of this Agreement can be directed to the designated Grievance Officer
          in writing at the following address: Sairaj Complex, A/204, Sanpada
          sector 01, Navi Mumbai, Maharashtra - 400705.
        </p>

        <p>
          <strong>10.2. How will the Company contact User?</strong>
        </p>

        <p>
          (a) All notices or demands to or upon a User(s) shall be effective if
          either delivered personally, sent by courier, certified mail, by
          facsimile or email to the last-known correspondence, fax, or email
          address provided by User(s) on the Platform, or by posting such notice
          or demand on an area of the Platform that is publicly accessible.
        </p>

        <p>
          (b) Notice to a User(s) shall be deemed to be received by such User(s)
          if and either when sent to User at the address, email, or other
          communication details provided by such User at the time of
          registration, whether in physical or electronic form, has been sent to
          such User(s), or immediately upon publishing of such notice on an area
          of the Platform that is publicly accessible.
        </p>

        <p>
          <strong>
            10.3. In case of a call from a person asking for access to User
            account registered with Company, what should User do?
          </strong>
        </p>

        <p>
          Company urges the users to beware of fake offers and fraudulent
          callers/messengers who may impersonate themselves as representatives
          of the Company. The Company’s authorized representatives will never
          contact the Users to demand money for prizes or ask for
          password/PIN/CVV. In the event you are asked for confidential details
          by anyone posing as the Company’s representatives, please ask them to
          communicate with you through email and only respond to emails from
          Groupick.in domain. Please see our Anti-Phishing communication
          available on the Platform.
        </p>

        <p>
          <strong>
            10.4. Can User disclose its communication through calls with the
            Company to third parties?
          </strong>
        </p>

        <p>
          All calls to the Company are completely confidential. However, the
          Users’ calls may be recorded to ensure the quality of service.
          Further, for training purposes and to ensure excellent customer
          service, calls from the Company may be monitored and recorded.
        </p>

        <p>
          <strong>11. MISCELLANEOUS PROVISIONS APPLICABLE TO AGREEMENT</strong>
        </p>

        <p>
          <strong>
            11.1. This Agreement is governed by the laws of India. Any action,
            suit, or other legal proceeding, which is commenced to resolve any
            matter arising under or relating to this Agreement or the Platform
            shall be subject to the jurisdiction of the courts at Bangalore,
            India.
          </strong>
        </p>

        <p>
          <strong>
            11.2. Company shall have the right to assign its obligations and
            duties in this Agreement to any person or entity.
          </strong>
        </p>

        <p>
          <strong>
            11.3. The failure of Company to exercise or enforce any right or
            provision of this Agreement shall not constitute a waiver of such
            right or provision.
          </strong>
        </p>

        <p>
          <strong>
            11.4. Platform is controlled and operated from India, and Company
            makes no representation that the content, information, or materials
            made available herein are appropriate or will be available for use
            in other locations. Access and use of this Platform from outside
            India is entirely at User's sole risk, and User agrees and
            undertakes to be responsible for compliance with all applicable
            local laws and agrees to release, discharge, and absolve Company
            from any liability or loss in this respect.
          </strong>
        </p>

        <p>
          <strong>
            11.5. Company reserves the right to introduce and initiate new
            features, functionalities, and components to Platform and/or change,
            alter, modify, suspend, discontinue, or remove the existing ones
            without any prior notice to you. Further, Company is entitled to
            discontinue (either permanently or temporarily) one or more of the
            Services provided or terminate the Platform or charge for Service
            which were early free of cost, without any prior notice to User.
          </strong>
        </p>
      </div>

      <div>
        <div className="footer p-md-5 p-3">
          <div className="container-fluid row justify-content-between">
            <div className="col-md-3">
              <h3>Groupick</h3>
              <p className="below-logo-desc text-grey">
                Groupick is India's #1 group shopping app. Our vision is to
                create India's most reliable and frictionless social commerce
                ecosystem that creates life-changing experiences for buyers and
                sellers.
              </p>
            </div>
            <div className="col-md-3">
              <h3>Links</h3>
              <ul className="list-style-none p-0 text-grey">
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.ABOUT_US}
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.PRIVACY_POLICY}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.TERM_AND_CONDITION}
                  >
                    Terms of Services
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none text-grey"
                    to={PATH.REFUND}
                  >
                    Return & Refund Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h3>Contact Us</h3>
              <p className="below-logo-desc text-grey">contact@groupick.in</p>
              <p className="below-logo-desc text-grey">
                Sanpada sector 01, Navi Mumbai 400705
              </p>
              <p className="below-logo-desc text-grey">+918668950480</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center border-top-1 mx-5 py-4 copy-right-footer">
        <h4>@ 2022 Getzie Technology Pvt Ltd. All Right Reserved.</h4>
      </div>
    </StyledLandingPage>
  );
};

export default TermsAndCondition;
