import React from "react";
import styled from "styled-components";
import { IoLogoFacebook } from "react-icons/io";
import { PATH } from "../../config";
import {
  AiFillYoutube,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { Link } from "react-router-dom";

const StyledAuthFooter = styled.footer`
  .footer-icon {
    font-size: 24px;
    margin-right: 8px;
    min-width: 24px;
  }

  .flex-start {
    align-items: f;
  }
  .list-style-none {
    list-style-type: none;
  }

  @media only screen and (max-width: 600px) {
    text-align: center;

    .item-center {
      justify-content: center;
    }

    .cntct {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const AuthFooter = () => {
  return (
    <StyledAuthFooter>
      <div className="footer p-md-5 p-3">
        <div className="container-fluid row justify-content-between">
          <div className="col-md-3">
            <h4 className="mb-3">Groupick</h4>
            <p className="text-muted mb-0">
              Trusted by many Indian <br />
              Sellers
            </p>
            {/* <p className="text-muted">Group Buy | Free Delivery</p> */}

            <p className="text-muted mt-4 mb-0">Reach to us</p>
            <div className="mt-2 d-flex align-items-center item-center">
              <a
                href="https://youtube.com/@sellongroupick"
                target="_blank"
                rel="noreferrer"
                className=" text-muted footer-icon"
              >
                <AiFillYoutube />
              </a>
              {/* Instagram */}
              <a
                href="https://www.instagram.com/groupick_seller"
                target="_blank"
                rel="noreferrer"
                className=" text-muted footer-icon"
              >
                <AiOutlineInstagram />
              </a>
              {/* Twitter */}
              <a
                href="https://twitter.com/Groupickapp"
                target="_blank"
                rel="noreferrer"
                className=" text-muted footer-icon"
              >
                <AiOutlineTwitter />
              </a>
              {/* Facebook */}
              <a
                href="https://www.facebook.com/SellonGroupick"
                target="_blank"
                rel="noreferrer"
                className=" text-muted footer-icon"
              >
                <IoLogoFacebook />
              </a>
              {/* Linkedin */}
              <a
                href="https://www.linkedin.com/company/groupick-app"
                target="_blank"
                rel="noreferrer"
                className=" text-muted footer-icon"
              >
                <FaLinkedinIn />
              </a>
              {/* <Link to="#" className=" text-muted footer-icon"></Link> */}
              {/* <Link to="#" className=" text-muted footer-icon"></Link> */}
              {/* <Link to="#" className=" text-muted footer-icon"></Link> */}
              {/* <Link to="#" className=" text-muted footer-icon"></Link> */}
              {/* <Link to="#" className=" text-muted footer-icon"></Link> */}
            </div>
          </div>
          <div className="col-md-3">
            <h4 className="mb-3">Links</h4>
            <ul className="list-style-none p-0 text-muted">
              <li>
                <Link
                  className="text-decoration-none text-muted"
                  to={PATH.ABOUT_US}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  className="text-decoration-none text-muted"
                  to={PATH.PRIVACY_POLICY}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  className="text-decoration-none text-muted"
                  to={PATH.TERM_AND_CONDITION}
                >
                  Terms of Services
                </Link>
              </li>
              <li>
                <Link
                  className="text-decoration-none text-muted"
                  to={PATH.REFUND}
                >
                  Return & Refund Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 cntct">
            <h4 className="mb-3">Contact Us</h4>
            <span className="d-flex text-muted mb-2 align-items-start">
              <GoLocation className="footer-icon" />
              Sairaj complex, A/204, Sanpada sector 01, Plot no 103, Navi
              Mumbai, Maharashtra - 400705
            </span>
            <span className=" text-muted d-flex mb-2">
              <AiOutlineMail className="footer-icon" />
              support@groupick.in
            </span>
            <span className="d-flex text-muted mb-2">
              <AiOutlinePhone className="footer-icon" />
              +91 90820 60855
            </span>
            <span className="d-flex text-muted">
              {/* <AiOutlinePhone className="footer-icon" /> */}
              GSTIN : 27AAJCG6281H1CL
            </span>
          </div>
        </div>
      </div>
    </StyledAuthFooter>
  );
};

export default AuthFooter;
