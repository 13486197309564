import React, { useState, useEffect } from "react";
import StyledBox from "../../compoents/Box";
import { Nav, Tab } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PageTitle from "../../compoents/PageTitle";
import supportService, {
  user_type,
} from "../../redux/services/support.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addFaq, supportGroupList } from "../../redux/slices/faq";
import AllFaq from "./AllFaq";
import MyTickets from "./MyTickets.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import HelpSearch from "./HelpSearch";

const SupportContainer = styled.div`
  .border-bottom {
    border-bottom: 1px solid black;
  }
  .supportSearch {
    max-width: 300px;
    width: fit-content;
    flex: 1;
  }
`;

const Support = () => {
  const tab1 = "support";
  const tab2 = "ticket";
  const [issueCategorySet, setIssueCategorySet] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [activeTab, setActiveTab] = useState(
    state && state.ticket ? tab2 : tab1
  );

  const fetchData = () => {
    supportService
      .fetchSupportList(user_type.seller)
      .then((result) => {
        const { status, data } = result.data;
        if (!status || status === "error")
          return toast.error("Something went wrong!");
        dispatch(addFaq(data));
      })
      .catch((err) => {
        console.log("Fetch error:", err);
        return toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  return (
    <SupportContainer>
      <PageTitle text="Support" />
      <StyledBox>
        <Tab.Container
          onSelect={handleTabSelect}
          id="left-tabs-example"
          defaultActiveKey={activeTab}
        >
          <div
            className="overflow-auto p-0 border-bottom"
            style={{ padding: "0px" }}
          >
            <Nav
              variant="pills"
              className="fund-raise-generic-tabs-nav flex-row text-center button-text flex-nowrap px-5 pt-3 border-bottom"
              style={{ borderRadius: "5px" }}
            >
              <Nav.Item>
                <Nav.Link
                  eventKey={tab1}
                  onSelect={handleTabSelect}
                  className="cursor-pointer text-grey-light"
                >
                  <h5>Support</h5>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={tab2}
                  onSelect={handleTabSelect}
                  className="cursor-pointer text-grey-light"
                >
                  <h5>My Tickets</h5>
                </Nav.Link>
              </Nav.Item>
              {activeTab === tab1 && (
                <div className="my-auto ms-auto supportSearch">
                  <HelpSearch />
                </div>
              )}
            </Nav>
            <Tab.Content className="mt-2">
              <Tab.Pane eventKey={tab1}>
                {!issueCategorySet ? (
                  <HelpTab setIssueCategorySet={setIssueCategorySet} />
                ) : (
                  <AllFaq
                    setIssueCategorySet={setIssueCategorySet}
                    issueCategorySet={issueCategorySet}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey={tab2}>
                <MyTickets />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </StyledBox>
    </SupportContainer>
  );
};

export default Support;

const CardContainer = styled.div`
  .hover {
    &:hover {
      background: #ddd;
      border-radius: 8px;
    }
  }
  .help-card-tittle {
    font-size: 17px;
    font-weight: 500;
  }

  .help-card-body {
    color: #b4b4b4;
    font-size: 16px;
    margin: 0 24px;
    a {
      text-decoration: none;
    }

    .help_card {
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        color: var(--primary-color);
        background: #ddd;
      }
    }
  }

  .view-all-link {
    text-decoration: none;
    color: #ff4d00;
    font-weight: "bold";
    margin-left: auto;
    width: fit-content;
  }

  .text-right {
    text-align: right;
  }
`;

const HelpTab = ({ setIssueCategorySet }) => {
  const faq = useSelector(supportGroupList);

  return (
    <CardContainer className="px-sm-5 px-3 py-4">
      <h4>Select Issue category</h4>
      <div className="row my-4" key="help-tab">
        {faq?.map((data) => {
          return (
            <div key={data.id} className="col-lg-4 h-100 mb-4">
              <StyledBox style={{ overflow: "hidden" }}>
                <div
                  className={`py-3 px-1 text-center ${
                    !data?.ticket_type?.length ? "hover" : null
                  }`}
                  role={!data?.ticket_type?.length ? "button" : ""}
                  onClick={() => {
                    if (!data?.ticket_type?.length) {
                      setIssueCategorySet(data.id);
                    }
                  }}
                >
                  <span className="help-card-tittle">{data?.name}</span>
                </div>
                <div className="help-card-body">
                  {data?.ticket_type?.map((ticket) => {
                    return (
                      <Link key={ticket.id} to={`/support/${ticket.id}`}>
                        <div className="help_card border-bottom p-2 justify-content-between align-items-center cursor-pointer">
                          {ticket.name}
                        </div>
                      </Link>
                    );
                  })}
                </div>
                {data.ticket_type.length > 0 && (
                  <div className="fw-bolder text-right p-3">
                    <div
                      className="view-all-link"
                      role="button"
                      onClick={() => {
                        setIssueCategorySet(data.id);
                      }}
                    >
                      View All
                    </div>
                  </div>
                )}
              </StyledBox>
            </div>
          );
        })}
      </div>
    </CardContainer>
  );
};
