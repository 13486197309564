import React from "react";
import Loader from "../../compoents/Loader";
import styled from "styled-components";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useSearch from "../../hooks/useSearch";
import { useSelector } from "react-redux";
import { getAllIssueFlated } from "../../redux/slices/faq";

const HelpSerachStyled = styled.div`
  position: relative;
  .search__inputDiv {
    .search__inputField {
      width: 100%;
      padding: 8px;
      border-radius: 4px;
      outline: none;
      border: 1px solid darkgray;
    }
  }
  .search__resultDiv {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid darkgray;
    width: 100%;
    bottom: -2px;
    left: 0;
    transform: translateY(100%);
    border-radius: 8px;
    z-index: 999;
    background: #fff;
    .search__resultItems {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      max-height: 175px;
      overflow-y: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      .search__resultItem {
        text-align: start;
        padding: 8px;
        border-bottom: 1px solid #ddd;
        transition: background-color 0.5s ease;
        font-weight: 400;
        font-size: 12px;
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
  }
`;

function HelpSearch() {
  const issue_list = useSelector(getAllIssueFlated);
  const history = useHistory();
  const { searchText, setSearchText, searching, filterData, setSearching } =
    useSearch(issue_list);
  return (
    <HelpSerachStyled>
      <div className="search__inputDiv">
        <input
          type="text"
          value={searchText}
          className="search__inputField"
          placeholder="Search Issue"
          onChange={(e) => {
            setSearching(true);
            setSearchText(e.target.value);
          }}
        />
      </div>
      {searchText.length > 2 && (
        <div className="search__resultDiv">
          {searching ? (
            <Loader animation="border" />
          ) : (
            <ul className="search__resultItems">
              {filterData.length ? (
                filterData?.map((data, i) => (
                  <li
                    key={i}
                    role="button"
                    onClick={() => {
                      history.push(`/support/${data.id}`);
                    }}
                    className="search__resultItem"
                  >
                    {data.name}
                  </li>
                ))
              ) : (
                <li className="search__resultItem">
                  No results to show. Try Return, Order, Catalog..
                </li>
              )}
            </ul>
          )}
        </div>
      )}
    </HelpSerachStyled>
  );
}

export default HelpSearch;
