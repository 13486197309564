import React from "react";
import {Container} from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {GenericButton} from "../../compoents/Button";
import { PATH } from "../../config";

const StyledAuthCTA = styled.section`
  .auth-cta {
  }
`;

const AuthCTA = () => {
  let history = useHistory();
  return (
    <StyledAuthCTA className="auth-cta text-white">
      <Container>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2>Start selling today</h2>
          <p>Put your products in front of crores of people</p>
          <GenericButton
                  className="my-3 py-2 px-4"
                  onClick={() => history.push(PATH.LOGIN)}
                >
          Start Selling</GenericButton>
        </div>
      </Container>
    </StyledAuthCTA>
  );
};

export default AuthCTA;
