import React, { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import StyledBox from "../../compoents/Box";
import BootstrapTable from "react-bootstrap-table-next";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import Loader from "../../compoents/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLayoutEffect } from "react";
import accountService from "../../redux/services/account.service";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { lastColumn, nextColumn, sheetHeader } from "./TableConstants";
import moment from "moment";

const StyledContainer = styled.div`
  padding: 60px 20px 0;
  position: relative;
  min-height: calc(100vh - 100px);
  height: fit-content;
  .downloadText {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 130%;
    font-weight: 400;
    font-size: 1rem;
    margin: 20px;
    background-color: #0d6efd;
    color: #fff;
    transition: all color 3s;
    padding: 8px 24px;
    border-radius: 4px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .actionDiv {
    color: #0d6efd;
    cursor: pointer;
    text-decoration: underline;
  }
  .backBtn {
    position: absolute;
    top: 0;
    left: 0;
    margin: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #ff4d00;
    text-decoration: underline;
  }
`;

function ViewMore() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [headers, setHeader] = useState([]);
  const auth = useAuth();
  const searchParam = useMemo(() => {
    const param = new URLSearchParams(location.search);
    const res = {
      size: param.size,
      page: param.has("page") && param.get("page"),
      index: param.has("index") && param.get("index"),
    };
    return res;
  }, [location.search]);

  const ActionColumn = ({ payouts, headers, sheetName }) => {
    return (
      <CSVLink
        className="actionDiv"
        filename={sheetName}
        headers={headers}
        data={payouts}
      >
        Export Data
      </CSVLink>
    );
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <ActionColumn
        sheetName={`${searchParam?.page}_${moment(row.date).format(
          "DD_MM_YYYY"
        )}_payment_details.csv`}
        payouts={row?.payouts ?? []}
        headers={formatExtraData}
      />
    );
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const param = new URLSearchParams({
        payment_type: searchParam?.page,
      });
      const res = await accountService.getPaymentByType(auth, param);
      const { data = {}, status = "error" } = res.data;
      if (status === "error") {
        return;
      }
      if (data) {
        setTableData(data);
        switch (searchParam?.page) {
          case "next":
            setColumns(nextColumn);
            setHeader(sheetHeader);
            break;
          case "previous":
          case "last":
            sheetHeader.unshift({
              key: "date",
              label: "Date",
            });
            const formatterObj = {
              dataField: "action",
              text: "",
              formatter: actionFormatter,
              formatExtraData: sheetHeader,
            };
            if (!lastColumn.some((obj) => obj.dataField === "action")) {
              lastColumn.push(formatterObj);
            }
            setColumns(lastColumn);
            setHeader(sheetHeader);
            break;
          default:
            break;
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("Error in getPaymentByType:", error);
      setLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    fetchData();
  }, []);

  return (
    <StyledBox>
      {loading ? (
        <div
          style={{ height: "60vh" }}
          className="row justify-content-center align-items-center"
        >
          <Loader />
          <Loader />
          <Loader />
        </div>
      ) : (
        <StyledContainer>
          {tableData?.length ? (
            <>
              <div
                role="button"
                className="backBtn"
                onClick={() => {
                  if (searchParam?.index) {
                    history.push(`?page=${searchParam?.page}`);
                  } else {
                    history.push("/payment");
                  }
                }}
              >
                Back
              </div>
              {searchParam?.page === "next" && (
                <CSVLink
                  className="downloadText"
                  filename={`${searchParam?.page}_payment_details.csv`}
                  headers={headers}
                  data={tableData}
                >
                  Export Data
                </CSVLink>
              )}

              <BootstrapTable
                keyField="id"
                data={tableData}
                columns={columns}
                bordered={false}
                condensed
                striped
                hover
                rowClasses="customRow"
                headerClasses="customHeader"
              />
            </>
          ) : (
            <h1>
              Oops!
              <br /> No data found.
            </h1>
          )}
        </StyledContainer>
      )}
    </StyledBox>
  );
}

export default ViewMore;
