import React from "react";
import styled from "styled-components";
import { IoIosArrowForward } from "react-icons/io";
import { IMAGESV2 } from "../../../../config";

const HowItWorkStyle = styled.section`
  padding: 0 !important;
  .howItWork__container {
    background: #22263e;
    color: #fefefe;
    padding: 70px 5rem;
    .howItWork__heading {
      text-align: center;
      position: relative;
      margin-bottom: 32px;
      .howItWork__heading1 {
        color: #3c3f5a;
        font-size: 4rem;
        margin: 0;
        font-weight: 700;
        line-height: 90%;
        text-transform: uppercase;
      }
      .howItWork__heading2 {
        color: #fefefe;
        margin: 0;
        // font-size: 2rem;
        font-weight: 700;
        line-height: 130%;
        transform: translateY(-50%);
      }
      &::after {
        content: "";
        position: absolute;
        border: 2px solid #0e123a;
        width: 80px;
        bottom: 0;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
      }
    }
    //content
    .howItWork__content {
      border: 2px solid var(--primary-color);
      border-radius: 8px;
      padding: 60px 25px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      //   gap: 12px;
    }
    .step__main {
      flex: 1;
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 300px;
      gap: 10px;

      padding-right: 40px;
      .step__imgDiv {
        width: 7rem;
        overflow: hidden;
        .step__image {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/1;
        }
      }
      .step__steps {
        font-size: 27px;
        font-weight: 600;
      }
      .step__desc {
        font-size: 19px;
        font-weight: 400;
      }
      .step__icon {
        color: #fff;
        font-weight: 900;
        position: absolute;
        right: 0;
        top: 30px;
        bottom: 25%;
        margin-bottom: 24px;
        opacity: 0.9;
      }
      &:last-child {
        padding: 0;
        .step__icon {
          display: none;
        }
      }
    }
  }
  @media (min-width: 1400px) {
    .howItWork__container {
      .howItWork__content {
        max-width: 1320px;
        margin: auto;
      }
    }
  }
  @media (max-width: 1200px) {
    .howItWork__container {
      // padding: 24px 3rem;
      padding: 60px 24px;
    }
  }
  @media (max-width: 670px) {
    padding: 0 !important;
    .howItWork__container {
      .howItWork__heading {
        .howItWork__heading1 {
          font-size: 48px;
        }
        howItWork__heading2 {
          font-size: 24px;
        }
      }
      .howItWork__content {
        width: fit-content;
        flex-direction: column;
        align-items: center;
        margin: auto;
        .step__main {
          gap: 0;
          padding: 0 0 40px;
          width: 100%;
          &:last-child {
            padding: 0;
          }
          .step__imgDiv {
            width: 4rem;
          }
          .step__icon {
            margin: 0;
            right: 50%;
            top: auto;
            bottom: -20px;
            transform: translateX(50%) rotate(90deg);
          }
        }
      }
    }
  }
  @media (max-width: 520px) {
    .howItWork__container {
      padding: 60px 24px;
      .howItWork__heading {
        .howItWork__heading1 {
          font-size: 2rem;
        }
        .howItWork__heading2 {
          font-size: 23px;
        }
      }
      .howItWork__content {
        padding: 40px 25px;
        gap: 40px;
      }
    }
  }
`;

const Steps = ({ step, desc, imgSrc }) => {
  return (
    <div className="step__main">
      {imgSrc && (
        <div className="step__imgDiv">
          <img src={imgSrc} alt="select_product" className="step__image" />
        </div>
      )}
      <div className="step__steps text-center">{step}</div>
      <div className="step__desc text-center">{desc}</div>
      <IoIosArrowForward size={40} className="step__icon" />
    </div>
  );
};
function HowItWorks() {
  const steps = [
    {
      step: "Select Product",
      desc: "Choose from wide range of product",
      imgSrc: IMAGESV2.select_product,
    },
    {
      step: "Start a group",
      desc: "To get a group discount",
      imgSrc: IMAGESV2.start_group,
    },
    {
      step: "Invite friends",
      desc: "Try to complete the group by inviting friends & family",
      imgSrc: IMAGESV2.invite_people,
    },
    {
      step: "Order placed",
      desc: "Boom! your wait is over",
      imgSrc: IMAGESV2.place_order,
    },
  ];
  return (
    <HowItWorkStyle>
      <div className="howItWork__container">
        <div className="howItWork__heading">
          {/* <h1 className="howItWork__heading1">How it's work</h1> */}
          <h3 className="howItWork__heading2">How group shopping works?</h3>
        </div>
        <div className="howItWork__content">
          {steps?.map((step, i) => (
            <Steps
              key={i}
              step={step.step}
              desc={step.desc}
              imgSrc={step?.imgSrc}
            />
          ))}
        </div>
      </div>
    </HowItWorkStyle>
  );
}

export default HowItWorks;
