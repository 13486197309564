import React from "react";
import CampaignHeader from "./CampaignHeader";
import CampaignDashboard from "./CampaignDashboard";
import styled from "styled-components";
import useCampaign from "./useCampaign";
import StartCampaignForm from "./StartCampaignForm";

const CampaignStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

function Campaign() {
  const { addCampaign, startCampaign, goToDashboard } = useCampaign();
  return (
    <CampaignStyled>
      <CampaignHeader hideBtn={addCampaign} startCampaign={startCampaign} />
      {!addCampaign && <CampaignDashboard />}
      {addCampaign && <StartCampaignForm goToDashboard={goToDashboard} />}
    </CampaignStyled>
  );
}

export default Campaign;
