import React from "react";
import { Form } from "react-bootstrap";

const GenericLabel = ({ children }) => {
  return (
    <Form.Label className="fund-raise-label button-text">{children}</Form.Label>
  );
};

export default GenericLabel;
