import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./uploadFile.css";
import { useState } from "react";

function UploadFileModal(props) {
  const { handleClose, show, handleUpload } = props;
  const [file, setFile] = useState(null);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload New File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            {/* <Form.Label>Default file input example</Form.Label> */}
            <Form.Control
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            className="saveBtn"
            disabled={!file}
            variant="primary"
            onClick={() => handleUpload(file)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadFileModal;
