import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import styled from "styled-components";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { HiOutlineThumbUp, HiOutlineThumbDown } from "react-icons/hi";
import { GenericButton } from "../../../compoents/Button";
import {
  GenericLabel,
  GenericSelect,
  OutlinedTextFields,
} from "../../../compoents/Forms2";
import { useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BreadCrumbContainer = styled.div`
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, ">");
  }
  .breadcrumb-item a {
    color: #b4b4b4;
    font-size: 17px;
    text-decoration: none;
  }
  .breadcrumb-item.active a {
    color: #4b4b4b;
  }
`;

export { BreadCrumbContainer };

const StyledFaq = styled.div`
  display: flex;
  .faq_list {
    max-width: 800px;
    margin: 0 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }
  .faq__ticket {
    width: 100%;
    border: 1px solid;
    border-color: #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition-duration: 0.5s;
    transition-property: color border-color;
    &:hover {
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
    .faq__ticketName {
      flex: 1;
    }
    .faq__arrow {
      width: fit-content;
      display: inline-flex;
    }
  }
  .otherHelp {
    border: 1px solid #ddd;
    box-shadow: 0 0 5px #ddd;
    width: fit-content;
    flex: 1;
    max-width: 450px;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: fit-content;
    .otherHelp_heading {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #ddd;
    }
    .otherHelp_list {
      list-style: none;
      margin: 0;
      padding: 0;
      .otherHelp_listItem {
        &:hover {
          text-decoration: underline;
          color: var(--primary-color);
          cursor: pointer;
        }
      }
    }
  }
`;

const AllFaq = ({ setIssueCategorySet, issueCategorySet }) => {
  const faqList = useSelector((state) => state.faq.faq_list);
  const [data, setData] = useState(null);
  const history = useHistory();
  const [faqId, setFaqId] = useState(issueCategorySet);

  useEffect(() => {
    const faqObj = faqList.filter((faq) => {
      return faq.id === faqId;
    });
    if (faqObj) setData(faqObj[0]);
  }, [faqId, faqList]);

  if (!data) return null;

  return (
    <>
      <div className="px-sm-5 px-3 py-2">
        <BreadCrumbContainer>
          <Breadcrumb>
            <Breadcrumb.Item href="#" onClick={() => setIssueCategorySet(null)}>
              Support
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{data?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </BreadCrumbContainer>
      </div>
      <StyledFaq className="px-3 pb-3">
        <div className="faq_list">
          {data?.ticket_type?.map((help) => {
            return (
              <div
                key={help.id}
                className="faq__ticket"
                role="button"
                onClick={() => {
                  history.push(`/support/${help.id}`);
                }}
              >
                <div className="faq__ticketName">{help.name}</div>
                <IoIosArrowForward className="faq__arrow" />
              </div>
            );
          })}
        </div>
        <div className="otherHelp">
          <div className="otherHelp_heading">Find More Help</div>
          <ul className="otherHelp_list">
            {faqList
              ?.filter((list) => list.id !== data.id)
              .map((list) => (
                <li
                  key={list.id}
                  className="otherHelp_listItem"
                  role="button"
                  onClick={() => {
                    setFaqId(list.id);
                  }}
                >
                  {list.name}
                </li>
              ))}
          </ul>
        </div>
      </StyledFaq>
    </>
  );
};
export default AllFaq;
