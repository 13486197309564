import React from "react";
import { useSelector } from "react-redux";
import {
  all_qc_selector,
  failed_qc_selector,
  live_qc_selector,
  pending_qc_selector,
} from "../../redux/slices/qc_slice";
import BootstrapTable from "react-bootstrap-table-next";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import "./qc.css";
import { download_base_url } from "../../config";

function QcTable(props) {
  const { tabId, setShow, setSelectedQC } = props;
  const [tableData, setTableData] = useState([]);
  const qc_all = useSelector(all_qc_selector);
  const qc_pending = useSelector(pending_qc_selector);
  const qc_failed = useSelector(failed_qc_selector);
  const qc_live = useSelector(live_qc_selector);

  function dateFormatter(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("DD-MM-YYYY HH:mm:ss");
  }

  const handleFileUpload = (id) => {
    console.log("Send file::", id);
    setShow(true);
    setSelectedQC(id);
  };
  const ActionColumn = ({ errorLink, qc_id }) => (
    <div className="actionDiv">
      <a
        href={`${download_base_url}/media/${errorLink}`}
        rel="noreferrer"
        target="_blank"
        className="errorFileLink"
      >
        Download Error File
      </a>
      <div
        role="button"
        className="uploadBtn"
        onClick={() => handleFileUpload(qc_id)}
      >
        Upload New File
      </div>
    </div>
  );

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status.toLowerCase() === "failed" ? (
      <ActionColumn errorLink={row.error_file} qc_id={row.id} />
    ) : (
      "-"
    );
  };

  const slFormatter = (cell, row, rowIndex) => {
    return rowIndex + 1;
  };
  const statusFormatter = (cell) => {
    let status = "Live";
    switch (cell) {
      case "PENDING":
        status = "In Progress";
        break;
      case "FAILED":
        status = "QC Error";
        break;
      default:
        status = "Live";
        break;
    }
    return status;
  };
  const qc_column = [
    {
      dataField: "",
      text: "SL No.",
      formatter: slFormatter,
    },
    {
      dataField: "id",
      text: "QC Id",
      sort: true,
    },
    {
      dataField: "rows",
      text: "Rows",
      sort: true,
    },
    {
      dataField: "error_row_count",
      text: "Errors",
      sort: true,
    },
    // {
    //   dataField: "created_at",
    //   text: "Created Date",
    //   formatter: dateFormatter,
    //   sort: true,
    // },
    {
      dataField: "updated_at",
      text: "Update Date",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "status",
      text: "QC Status",
      formatter: statusFormatter,
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  useEffect(() => {
    switch (tabId) {
      case 0:
        return setTableData(qc_all);
      case 1:
        return setTableData(qc_pending);
      case 2:
        return setTableData(qc_live);
      case 3:
        return setTableData(qc_failed);
      default:
        return setTableData(qc_all);
    }
  }, [tabId, qc_all, qc_pending, qc_failed, qc_live]);

  return (
    <>
      {qc_all ? (
        <BootstrapTable
          //   className="table-responsive-lg"
          keyField="id"
          data={tableData}
          columns={qc_column}
          bordered={false}
          condensed
          striped
          hover
          rowClasses="customRow"
          headerClasses="customHeader"
          //   classes={`custom-table table-responsive-sm`}
          //   bodyClasses="custom-table-body"
        />
      ) : (
        "no data"
      )}
    </>
  );
}

export default QcTable;
