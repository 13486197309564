import React, { memo, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import quoteImage from "../../assets/Images/quotes.svg";
import styled from "styled-components";
import { IMAGESV2 } from "../../config";

const ReviewsCarouselStyled = styled.div`
  .reviewsSection {
    // height: 450px;
    height: fit-content;
    background: #fff;
    padding: 0;
    margin: 3rem 0;
    overflow: hidden;
    .reviewHeading {
      font-weight: 700;
      padding: 32px 0;
      margin: 0;
    }
    .Carousel {
      position: relative;
      width: 100%;
      min-height: 250px;
      overflow: hidden;
      .card {
        position: absolute;
        left: var(--left, 0);
        right: var(--right, 0);
        top: 0;
        width: 350px;
        height: fit-content;
        margin: auto;
        transform: scale(var(--scale, 0.8)) translateX(var(--X, 100vw));
        padding: 32px;
        display: none;
        transition: transform 1000ms;
        opacity: 0;
        max-width: 500px;
        width: 100%;
        .seller_info {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          margin-top: 16px;
          font-weight: 500;
          position: relative;
          .seller_img {
            position: absolute;
            width: 40px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              scale: 1.25;
            }
          }
          .seller_details {
            margin-left: 45px;
          }
        }
      }
      .active {
        --X: 0;
        --scale: 1;
        z-index: 99;
        border-bottom: 3px solid #ff4d00;
        display: block;
        opacity: 1;
      }
      .leftSlide {
        --X: -40vw;
        display: block;
        opacity: 0.5;
      }
      .rightSlide {
        --X: 40vw;
        display: block;
        opacity: 0.5;
      }
      .quotesIcon {
        width: 4rem;
        height: 4rem;
      }
      .card {
        .reviewData {
          font-size: 18px;
          font-weight: 500;
          -webkit-line-clamp: 1;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .active {
        .reviewData {
          -webkit-line-clamp: unset;
          white-space: normal;
        }
      }
    }
    @media (min-width: 1400px) {
      .reviewsCarousel {
        max-width: 1320px;
      }
    }
    @media (max-width: 670px) {
      margin: 24px 0;
      .reviewsCarousel {
        padding: 16px 0;
      }
      .reviewHeading {
        padding: 0;
        margin-bottom: 16px;
      }
      .Carousel {
        min-height: 235px;
        .card {
          padding: 24px;
          .quotesIcon {
            width: 32px;
            height: 32px;
          }
        }
        .active {
          max-width: 75%;
        }
      }
    }
  }
`;

const reviews = [
  {
    // id: 1,
    data: "मुझे पूरे इंडिया से ऑर्डर्स आते हैं, Thank you Groupick.",
    name: "Pankaj Sharma",
    shop: "A1 fashion",
    location: "Delhi",
    imgSrc: IMAGESV2.seller1,
  },
  {
    // id: 2,
    data: "Groupick App pe seller banane ke bad, mere orders 5x bad gaye.",
    name: "Neeraj shelar",
    shop: "Handy craft",
    location: "Mumbai",
    imgSrc: IMAGESV2.seller2,
  },
  {
    // id: 3,
    data: "Group Buying feature se mere orders badh gaye",
    name: " Iqbal azhar",
    shop: "IZ collection",
    location: "Tamilnadu",
    imgSrc: IMAGESV2.seller3,
  },
];

function CustomCarousel() {
  const mod = (n, m) => {
    const result = n % m;
    return result;
  };

  const calculateSlidePos = useCallback((active) => {
    active = mod(active, reviews.length);
    const left = active === 0 ? reviews.length - 1 : active - 1 ?? undefined;
    const right = mod(active + 1, reviews.length) ?? undefined;
    return {
      left,
      active,
      right,
    };
  }, []);
  const [activeSlide, setActiveSlide] = useState(() => {
    const midSlide = Math.floor(reviews.length / 2);
    return calculateSlidePos(midSlide);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide(calculateSlidePos(activeSlide.active + 1));
    }, 3000);
    return () => clearInterval(interval);
  }, [activeSlide.active, calculateSlidePos]);

  return (
    <ReviewsCarouselStyled>
      <section className="reviewsSection">
        <Container className="reviewsCarousel">
          <h3 className="text-center reviewHeading">Sellers feedback</h3>
          <div className="Carousel">
            {reviews.map((review, i) => {
              return (
                <div
                  key={`carousel-${i}`}
                  className={`card ${i === activeSlide.active && "active"} ${
                    i === activeSlide.left && "leftSlide"
                  } ${i === activeSlide.right && "rightSlide"}
                  `}
                >
                  <img src={quoteImage} alt="" className="quotesIcon" />
                  <div className="reviewData">{review.data}</div>
                  {/* <div className="text-left text-capitalize fw-bolder mt-2">
                    - {review.name}
                  </div> */}
                  <div className="seller_info">
                    <div className="seller_img">
                      <img src={review?.imgSrc} alt={`seller-${i}`} />
                    </div>
                    <div className="seller_details">{`${review?.shop}, ${review?.location}`}</div>
                    <div className="seller_details">- {review?.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </section>
    </ReviewsCarouselStyled>
  );
}

export default memo(CustomCarousel);
