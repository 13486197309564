import React, { useEffect } from "react";
import { Switch, useLocation } from "react-router";
import { PATH } from "../config";
import { WEB_PAGES } from "../pages";
import PrivateRoute from "./Auth/PrivateRoute";
import PublicRoute from "./Auth/PublicRoute";
const RouterConfig = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Switch>
      {/* <PublicRoute exact path={PATH.DASHBOARD}></PublicRoute> */}

      <PublicRoute exact path={PATH.LANDING_PAGE}>
        <WEB_PAGES.LANDING_PAGE />
      </PublicRoute>
      <PublicRoute exact path={PATH.WELL_KNOWN}>
        <WEB_PAGES.WELL_KNOWN />
      </PublicRoute>
      <PublicRoute exact path={PATH.ABOUT_US}>
        <WEB_PAGES.ABOUT_US />
      </PublicRoute>
      <PublicRoute exact path={PATH.CONTACT_US}>
        <WEB_PAGES.CONTACT_US />
      </PublicRoute>
      <PublicRoute exact path={PATH.REFUND}>
        <WEB_PAGES.REFUND />
      </PublicRoute>
      <PublicRoute exact path={PATH.TERM_AND_CONDITION}>
        <WEB_PAGES.TERM_AND_CONDITION />
      </PublicRoute>
      <PublicRoute exact path={PATH.PRICING}>
        <WEB_PAGES.PRICING />
      </PublicRoute>
      <PublicRoute exact path={PATH.DELIVERY}>
        <WEB_PAGES.DELIVERY />
      </PublicRoute>
      <PublicRoute exact path={PATH.PRIVACY_POLICY}>
        <WEB_PAGES.PRIVACY_POLICY />
      </PublicRoute>
      <PublicRoute exact path={PATH.LOGIN}>
        <WEB_PAGES.LOGIN />
      </PublicRoute>
      <PublicRoute exact path={PATH.REGISTER}>
        <WEB_PAGES.REGISTER />
      </PublicRoute>
      <PrivateRoute exact path={PATH.SELLER_PROFILE}>
        <WEB_PAGES.SELLER_PROFILE />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.DASHBOARD}>
        <WEB_PAGES.DASHBOARD />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.CATALOGUE}>
        <WEB_PAGES.CATALOGUE />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.BRAND}>
        <WEB_PAGES.BRANDS />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.VIEWBRAND}>
        <WEB_PAGES.VIEWBRAND />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.RETURN_RTO_ORDERS}>
        <WEB_PAGES.RETURN_RTO_ORDERS />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.RETURN_ORDER_DETAIL}>
        <WEB_PAGES.RETURN_ORDER_DETAIL />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.INVENTORY}>
        <WEB_PAGES.INVENTORY />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.CONTACTS}>
        <WEB_PAGES.CONTACTS />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.ORDERS}>
        <WEB_PAGES.ORDER />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.ORDER_DETAIL}>
        <WEB_PAGES.ORDER_DETAIL />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.RETURN_RTO_ORDERS}>
        <WEB_PAGES.RETURN_RTO_ORDERS />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.INVENTORY}>
        <WEB_PAGES.INVENTORY />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.INVENTORY_PRODUCT_DETAIL}>
        <WEB_PAGES.INVENTORY_PRODUCT_DETAIL />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.PAYMENT}>
        <WEB_PAGES.PAYMENT />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.PAYMENT_VIEW_MORE}>
        <WEB_PAGES.PAYMENT_VIEW_MORE />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.REVIEW_AND_RATING}>
        <WEB_PAGES.REVIEW_AND_RATING />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.REVIEW_AND_RATING_SINGLE_PRODUCT}>
        <WEB_PAGES.REVIEW_AND_RATING_SINGLE_PRODUCT />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.SETTINGS}>
        <WEB_PAGES.SETTINGS />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.GALLERY}>
        <WEB_PAGES.GALLERY />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.NOTICE}>
        <WEB_PAGES.NOTICE />
      </PrivateRoute>

      {/* <PrivateRoute exact path={PATH.ADD_PODUCT}>
        <WEB_PAGES.ADD_PRODUCT />
      </PrivateRoute> */}

      <PrivateRoute exact path={PATH.ADD_PODUCT}>
        <WEB_PAGES.ADD_PRODUCT_V2 />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.EDIT_INVENTORY_PRODUCT}>
        <WEB_PAGES.EDIT_INVENTORY_PRODUCT />
      </PrivateRoute>
      <PrivateRoute exact path={PATH.NOTIFICATIONS}>
        <WEB_PAGES.NOTIFICATIONS />
      </PrivateRoute>

      {/* <PrivateRoute exact path={PATH.EDIT_INVENTORY_PRODUCT}>
        <WEB_PAGES.EDIT_PRODUCT />
      </PrivateRoute> */}

      <PrivateRoute exact path={PATH.SUPPORT}>
        <WEB_PAGES.SUPPORT />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.TICKET_VIEW}>
        <WEB_PAGES.TICKET_VIEW />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.SUPPORT_BY_ID}>
        <WEB_PAGES.VIEW_SUPPORT />
      </PrivateRoute>

      {/* Dashboard Policies  */}
      <PrivateRoute exact path={PATH.TERMS_CONDITION}>
        <WEB_PAGES.TERMS_CONDITION />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.ANTI_PHISHING_POLICY}>
        <WEB_PAGES.ANTI_PHISHING_POLICY />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.PRIVACY_POLICIES}>
        <WEB_PAGES.PRIVACY_POLICIES />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.INTELLECTUAL_PROPERTY_POLICY}>
        <WEB_PAGES.INTELLECTUAL_PROPERTY_POLICY />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.SUPLIER_AGREEMENT}>
        <WEB_PAGES.SUPLIER_AGREEMENT />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.SUPLIER_DEACTIVATION}>
        <WEB_PAGES.SUPLIER_DEACTIVATION />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.PROHABITIED_AND_RESTRICTED_POLICY}>
        <WEB_PAGES.PROHABITIED_AND_RESTRICTED_POLICY />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.ADDITIONAL_SELLER_POLICIES}>
        <WEB_PAGES.ADDITIONAL_SELLER_POLICIES />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.SELLER_REFERRAL_PROGRAM}>
        <WEB_PAGES.SELLER_REFERRAL_PROGRAM />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.WHISTLE_BLOWSER_POLICY}>
        <WEB_PAGES.WHISTLE_BLOWSER_POLICY />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.COURIER_PARTNER_CLAIMS}>
        <WEB_PAGES.COURIER_PARTNER_CLAIMS />
      </PrivateRoute>

      <PrivateRoute exact path={PATH.CAMPAIGN}>
        <WEB_PAGES.CAMPAIGN />
      </PrivateRoute>
    </Switch>
  );
};
export default RouterConfig;
