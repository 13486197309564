import React from "react";
import { Col, Row } from "react-bootstrap";
import { GenericButton } from "../../Button";
import StyledBox from "../../Box";
import { useHistory } from "react-router-dom";

function MakeFeePayment() {
  const history = useHistory();

  const redirectToFeePage = () => {
    history.push("settings?tab=6");
  };

  return (
    <div>
      <div className="py-3 px-sm-3 px-0">
        <Row>
          <Col>
            <StyledBox className="h-100 p-4">
              <div className="d-flex flex-column justify-content-between align-items-center text-center">
                <h3>You have reached product listing limit.</h3>
                <h6 className="mb-0 text-muted">
                  Please activate your seller membership plan for listing more
                  products.
                </h6>
                <GenericButton onClick={redirectToFeePage} className="my-4">
                  Activate now
                </GenericButton>
              </div>
            </StyledBox>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default MakeFeePayment;
