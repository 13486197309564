import React from "react";
import { Container, Alert } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const AntiPhishingPolicy = () => {
  return (
    <>
      <Container className="mt-5">
        <StyledBreadCrumb>
          <StyledBreadCrumb.Item active href="#">
            Dashboard
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Settings
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Legal & policies
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Anti Phishing Policy
          </StyledBreadCrumb.Item>
        </StyledBreadCrumb>
        <Alert variant="danger">
          <h2>BEWARE! IT'S NOT US, BUT A SCAMSTER</h2>
          <p>
            We have noticed that there have been several incidents where
            Groupick’s name is misused to collect personal information as well
            as to cheat and mislead customers. Please note that such incidents
            are a scam and must be immediately reported to us at{" "}
            <a href="mailto:legalsupport@Groupick.in">
              legalsupport@Groupick.in
            </a>
            .
          </p>
          <p>
            BEWARE of such frauds and phishing activities and by following these
            steps you can identify and safeguard yourself from such scamsters:
          </p>
          <ol>
            <li>
              Be vigilant. Please do not divulge any personal or sensitive data
              including bank details such as OTP, UPI/ATM PIN, CVV, or
              credit/debit card details to anyone claiming to be a Groupick’s
              representative.
            </li>
            <li>
              Ensure that you do not click on any suspicious links or any
              unauthorized web portals or social media posts.
            </li>
            <li>
              Watch out for any suspicious calls, fake messages, unsolicited or
              spam e-mails and any communication sent to you by the unauthorized
              person(s) asking you to share any personal information malafidely
              under the pretext of processing refund claims, soliciting to
              participate in any unauthorized offers, lotteries, contests or
              scheme or asking for payment of money for such participation or to
              receive any award thereof or offering any job opportunity.
            </li>
            <li>
              Avoid paying any money or deposit funds to any person wrongfully
              claiming to be Groupick’s representative or job consultant(s).
              Neither Groupick nor any of it’s representative or authorized
              recruitment consultants take money or any other kind of payment
              for jobs.
            </li>
            <li>
              Report any incident which you believe to be fake or misleading,
              immediately to us to stay safe from such fraud or phishing.
            </li>
            <li>
              Ensure that you transact with us using the authorized channels of
              Groupick including it’s platform, approved social media pages or
              valid and genuine contact details to keep yourselves safe and
              secure from such fraud or phishing.
            </li>
          </ol>
          <p>
            We are concerned about the security of your online transactions with
            us and always strive to keep a safe and secure user experience for
            you. In case of any queries please reach out to us at{" "}
            <a href="mailto:legalsupport@Groupick.in">
              legalsupport@Groupick.in
            </a>
          </p>
        </Alert>
      </Container>
    </>
  );
};

export default AntiPhishingPolicy;
