import React from "react";
import { PaidServiceStyled } from "./paidServiceStyled";
import PaidServicesList from "./PaidServicesList";
import { BsCashStack } from "react-icons/bs";
import { useSelector } from "react-redux";
import { active_paid_service_selector } from "../../../redux/slices/account";
import { useEffect } from "react";
import ActiveService from "./ActiveService";
import { useState } from "react";

function PaidServices() {
  const active_paid_service = useSelector(active_paid_service_selector);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);
  if (loading) return null;
  return (
    <PaidServiceStyled>
      <div className="d-flex align-items-center border-botton-grey pb-2">
        <BsCashStack size={25} className="me-3" />
        <h4>Groupick Membership Plans</h4>
      </div>
      <div className="paidService__main">
        {!active_paid_service && <PaidServicesList />}
        {active_paid_service && <ActiveService plan_id={active_paid_service} />}
      </div>
    </PaidServiceStyled>
  );
}

export default PaidServices;
