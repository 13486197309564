import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AccountDataService from "../services/account.service";
// import { ACCOUNT } from "../utlities";
import { ACCOUNT, failure, request, success } from "../utlities";

const ENUM_REGISTER_TYPE = {
  BASIC: "BASIC",
  PAID: "PAID",
};

const initialState = {
  createUserLoading: false,
  createUserSuccess: false,
  createUserFailure: false,
  createUserError: null,
  createUserMessage: "",

  loginUserLoading: false,
  loginUserSuccess: false,
  loginUserFailure: false,
  loginUserError: null,
  loginUserMessage: "",

  updateProfileLoading: false,
  updateProfileSuccess: false,
  updateProfileFailure: false,
  updateProfileError: null,
  updateProfileMessage: "",

  getProfileLoading: true,
  getProfileSuccess: false,
  getProfileFailure: false,
  getProfileError: null,
  getProfileMessage: "",

  verifyOtpLoading: false,
  verifyOtpSuccess: false,
  verifyOtpFailure: false,
  verifyOtpError: null,
  verifyOtpMessage: null,
  verifiedUser: {},
  token: null,
  loggedIn: {},
};

export const getProfile = createAsyncThunk(
  "account/get-profile",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await AccountDataService.getProfile();
      if (!res.status === 200) {
        toast.error("An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res);
    } catch (error) {
      toast.error("An error occured. Please try again.");

      throw rejectWithValue(error);
    }
  }
);

export const registerAccount = createAsyncThunk(
  "account/create",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await AccountDataService.registerUser(data);
      if (!res.status === 200) {
        toast.error(res.data?.message ?? "An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? "An error occured. Please try again."
      );

      throw rejectWithValue(error);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "verify/otp",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await AccountDataService.verifyOtp(data);
      if (!res.status === 200) {
        toast.error("An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res);
    } catch (error) {
      toast.error("An error occured. Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const loginUser = createAsyncThunk(
  "account/login",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await AccountDataService.userLogin(data);
      if (!res.status === 200) {
        toast.error("An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "account/update",
  async (data, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await AccountDataService.updateProfile(data);
      if (!res.status === 200) {
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res);
    } catch (error) {
      console.log(error);
      throw rejectWithValue(error);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setLoggedIn(state, action) {
      const { payload } = action;
      state.loggedIn = payload;
    },
    updateLoggedIn(state, action) {
      const { payload } = action;
      state.loggedIn = { ...state.loggedIn, ...payload };
    },
    handleBasicUserProductAdd(state, action) {
      const {
        register_type,
        product_limit,
        isExistingUser,
        products_count,
        available_limit,
      } = state.loggedIn?.register_details;
      if (
        isExistingUser ||
        product_limit === -1 ||
        register_type !== ENUM_REGISTER_TYPE.BASIC
      )
        return state;
      const updatedProductCount = products_count + 1;
      const updatedAvailablleLimit = available_limit - 1;
      state.loggedIn.register_details = {
        ...state.loggedIn.register_details,
        available_limit: updatedAvailablleLimit,
        products_count: updatedProductCount,
      };
    },
    resetAuth: (state) => {
      state.createUserLoading = false;
      state.createUserSuccess = false;
      state.createUserFailure = false;
      state.createUserError = null;
      state.createUserMessage = "";

      state.loginUserLoading = false;
      state.loginUserSuccess = false;
      state.loginUserFailure = false;
      state.loginUserError = null;
      state.loginUserMessage = "";

      state.updateProfileLoading = false;
      state.updateProfileSuccess = false;
      state.updateProfileFailure = false;
      state.updateProfileError = null;
      state.updateProfileMessage = "";

      state.getProfileLoading = true;
      state.getProfileSuccess = false;
      state.getProfileFailure = false;
      state.getProfileError = null;
      state.getProfileMessage = "";

      state.verifyOtpLoading = false;
      state.verifyOtpSuccess = false;
      state.verifyOtpFailure = false;
      state.verifyOtpError = null;
      state.verifyOtpMessage = null;
      state.verifiedUser = {};
      state.loggedIn = {};
      state.token = null;
      if (state?.userUpdatedProfile) {
        state.userUpdatedProfile = null;
      }
      if (state?.userUpdatedProfiletoken) {
        state.userUpdatedProfiletoken = null;
      }
      if (state?.verifyOtpMessage) {
        state.verifyOtpMessage = null;
      }
      if (state?.verifiedUser) {
        state.verifiedUser = null;
      }
    },
  },
  extraReducers: {
    [registerAccount.pending]: (state, action) => {
      state.createUserLoading = true;
      state.createUserSuccess = false;
      state.createUserFailure = false;
      state.createUserError = null;
      state.createUserMessage = null;
    },
    [registerAccount.fulfilled]: (state, action) => {
      state.createUserLoading = false;
      state.createUserSuccess = true;
      state.createUserFailure = false;
      state.createUserError = null;
      state.createUserMessage = action.payload.data.message;
    },
    [registerAccount.rejected]: (state, action) => {
      state.createUserLoading = false;
      state.createUserSuccess = false;
      state.createUserFailure = true;
      state.createUserError = action.payload?.response?.data?.message;
      state.createUserMessage = null;
    },

    // loginUserLoading: false,
    // loginUserSuccess: false,
    // loginUserFailure: false,
    // loginUserError: null,

    [loginUser.pending]: (state, action) => {
      state.loginUserLoading = true;
      state.loginUserSuccess = false;
      state.loginUserFailure = false;
      state.loginUserError = null;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loginUserLoading = false;
      state.loginUserSuccess = true;
      state.loginUserFailure = false;
      state.loginUserError = null;
      state.loginUserMessage = action.payload.data.message;
      //   state.verifiedUser = action.payload.data.user;
      //   state.token = action.payload.data.token;
    },
    [loginUser.rejected]: (state, action) => {
      state.loginUserLoading = false;
      state.loginUserSuccess = false;
      state.loginUserFailure = true;
      state.loginUserError = null;
      state.loginUserMessage = action.payload.response.data.message;
      //   state.verifiedUser = {};
      //   state.token = null;
    },

    [verifyOtp.pending]: (state, action) => {
      state.verifyOtpLoading = true;
      state.verifyOtpSuccess = false;
      state.verifyOtpFailure = false;
      state.verifyOtpError = null;
    },
    [verifyOtp.fulfilled]: (state, action) => {
      state.verifyOtpLoading = false;
      state.verifyOtpSuccess = true;
      state.verifyOtpFailure = false;
      state.verifyOtpError = null;
      state.verifyOtpMessage = action.payload.data.message;
      state.verifiedUser = action.payload.data.user;
      state.token = action.payload.data.token;
    },
    [verifyOtp.rejected]: (state, action) => {
      state.verifyOtpLoading = false;
      state.verifyOtpSuccess = false;
      state.verifyOtpFailure = true;
      state.verifyOtpError = null;
      state.verifyOtpMessage = action.payload.response.data.message;
      state.verifiedUser = {};
      state.token = null;
    },

    //   updateProfileLoading: false,
    // updateProfileSuccess: false,
    // updateProfileFailure: false,
    // updateProfileError: null,
    // updateProfileMessage: "",
    [updateProfile.pending]: (state, action) => {
      state.updateProfileLoading = true;
      state.updateProfileSuccess = false;
      state.updateProfileFailure = false;
      state.updateProfileError = null;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.updateProfileLoading = false;
      state.updateProfileSuccess = true;
      state.updateProfileFailure = false;
      state.updateProfileError = null;
      state.userUpdatedProfile = action.payload.data.user;
      state.userUpdatedProfiletoken = action.payload.data.token;
      state.updateProfileMessage = action.payload.data.message;
    },
    [updateProfile.rejected]: (state, action) => {
      state.updateProfileLoading = false;
      state.updateProfileSuccess = false;
      state.updateProfileFailure = true;
      state.updateProfileError = null;
      state.userUpdatedProfile = {};
      state.userUpdatedProfiletoken = null;
      state.updateProfileMessage = action.payload.response.data.message;
    },

    [getProfile.pending]: (state, action) => {
      state.getProfileLoading = true;
      state.getProfileSuccess = false;
      state.getProfileFailure = false;
      state.getProfileError = null;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.getProfileLoading = false;
      state.getProfileSuccess = true;
      state.getProfileFailure = false;
      state.getProfileError = null;
      state.usergetdProfile = action.payload.data.user;
      state.usergetdProfiletoken = action.payload.data.token;
      state.getProfileMessage = action.payload.data.message;
    },
    [getProfile.rejected]: (state, action) => {
      state.getProfileLoading = false;
      state.getProfileSuccess = false;
      state.getProfileFailure = true;
      state.getProfileError = null;
      state.usergetdProfile = {};
      state.usergetdProfiletoken = null;
      state.getProfileMessage = action.payload.response.data.message;
    },
  },
});

export const userSelector = (state) => state.account;
// export const registrationFeePaidSelector = (state) => {
//   return state.account.loggedIn?.isRegistrationFeePaid ?? null;
// };
export const allowedForProductListingSelector = (state) => {
  if (!state?.account?.loggedIn?.register_details) return null;
  const register_details = state.account?.loggedIn?.register_details;
  const {
    product_limit = -999,
    isExistingUser = false,
    available_limit = -999,
  } = register_details;
  if (isExistingUser || product_limit === -1) return true; // Existing user unlimited listing
  if (available_limit !== 0) {
    return true;
  }
  return false;
};

export const register_details_selector = (state) => {
  return state?.account?.loggedIn?.register_details;
};
export const registrationFeeAmountSelector = (state) => {
  return state.account.loggedIn?.registrationFee ?? 0;
};
export const registrationOriginalFeeAmountSelector = (state) => {
  return state.account.loggedIn?.originalRegistrationFee ?? 0;
};
export const paid_service_selector = (state) => {
  return state.account.loggedIn?.paid_service ?? null;
};
export const active_paid_service_selector = (state) => {
  return state.account.loggedIn?.active_paid_service ?? null;
};

export const {
  resetAuth,
  setLoggedIn,
  updateLoggedIn,
  handleBasicUserProductAdd,
} = accountSlice.actions;

const { reducer } = accountSlice;
export default reducer;
