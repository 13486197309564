import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import CustomTooltip from "../Product/CustomTooltip";
import { OutlinedTextFields } from "../../compoents/Forms2";
import { GenericButton } from "../../compoents/Button";
import boxImage from "./../../assets/Images/deliveryInfo.png";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import Variation from "../Product/variation";
import PageTitle from "../../compoents/PageTitle";
import { Formik, Form as FormikForm } from "formik";
import CountryOptions from "./CountryOptions";
import ErrorMessage from "../../compoents/ErrorMessage";
import * as Yup from "yup";
import { GENERIC_MESSAGE, TOAST_STYLE } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EditCategoryModal from "../../compoents/EditCategoryModal";
import {
  dashboardCateSelector,
  setSelectedCategory,
} from "../../redux/slices/category";
import moment from "moment";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import {
  createProductSelector,
  createProductThunk,
} from "../../redux/slices/product";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { handleBasicUserProductAdd } from "../../redux/slices/account";

const tax = [
  { value: 0, label: "0%" },
  { value: 3, label: "3%" },
  { value: 5, label: "5%" },
  { value: 12, label: "12%" },
  { value: 18, label: "18%" },
  { value: 28, label: "28%" },
];

const ProductStyledContainer = styled.div`
  // max-width: 1200px;
  .formControl {
    position: relative;
    margin-bottom: 10px;
    input {
      outline: none;
      box-shadow: none;
    }
    .formError {
      position: absolute;
      bottom: 0;
      transform: translateY(90%);
      font-size: 12px;
      font-weight: 500;
      margin: 0 !important;
    }
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .basicDetailContainer {
    width: 100%;
    height: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 8px 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    h5 {
      margin: 0;
      border-bottom: 2px solid #ccc;
      color: rgba(0, 0, 0, 0.5);
    }
    label {
      margin: 0;
      font-size: 14px;
      color: #122736;
      font-weight: 500;
    }
    .fieldWrapper {
      display: flex;
      flex-direction: column;
    }
    .categoryContainer {
      display: flex;
      width: 100%;
      gap: 12px;
      align-items: center;
    }
  }

  .otherInfoContainer {
    width: 100%;
    height: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 8px 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    h5 {
      margin: 0;
      border-bottom: 2px solid #ccc;
      color: rgba(0, 0, 0, 0.5);
    }
    label {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #122736;
    }
    .boxImage {
      margin: auto;
      max-width: 250px;
      max-height: 150px;
      width: 100%;
      height: 100%;
    }
  }
  .input-border {
    border: 2px solid #c3c3c3;
  }
`;

function ProductV2() {
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const dispatch = useDispatch();
  const auth = useAuth();
  const variationRef = useRef(null);
  const {
    uploadProductImageSuccess,
    uploadProductImageFailure,
    uploadProductImageError,
  } = useSelector((state) => state.uploadProductImage);
  const imageUploadRef = useRef(0);
  const selectedBrand = localStorage.getItem("selectedBrand")
    ? JSON.parse(localStorage.getItem("selectedBrand"))
    : null;
  const history = useHistory();
  const selectedCategory = useSelector(dashboardCateSelector);

  const { createProductSuccess, createProductFailure, createProductError } =
    useSelector(createProductSelector);

  useEffect(() => {
    if (uploadProductImageFailure) {
      toast.error(
        uploadProductImageError
          ? uploadProductImageError
          : "Error While uploading Image",
        TOAST_STYLE
      );
    } else {
      imageUploadRef.current = imageUploadRef.current + 1;
      if (imageUploadRef.current === 2) {
        localStorage.removeItem("variations");
        toast.success("Product added successfully", TOAST_STYLE);
        history.push("/catalogue");
        dispatch(handleBasicUserProductAdd());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProductImageSuccess, uploadProductImageFailure]);

  useEffect(() => {
    if (createProductFailure) {
      toast.error(
        createProductError ? createProductError : "Error While adding product",
        TOAST_STYLE
      );
    }
  }, [createProductSuccess, createProductFailure, createProductError]);

  const checkCategory = useCallback(() => {
    if (!Object.keys(selectedCategory?.selectedCategory).length) {
      let _selectedCategory = window.localStorage.getItem("selectedCategory");
      try {
        if (typeof _selectedCategory === "string" && _selectedCategory.length) {
          _selectedCategory = JSON.parse(_selectedCategory);
        }
      } catch (err) {
        _selectedCategory = {};
      }
      dispatch(setSelectedCategory(_selectedCategory));
    }
  }, [selectedCategory, dispatch]);

  useEffect(() => {
    checkCategory();
  }, [checkCategory]);

  const getCategory = useMemo(() => {
    let catgeoryValue;
    if (
      selectedCategory?.selectedCategory &&
      selectedCategory?.selectedCategory.id
    ) {
      catgeoryValue = selectedCategory?.selectedCategory.name;
    }
    return catgeoryValue;
  }, [selectedCategory]);

  const [specification, setSpecification] = useState([]);
  const onChangeSelect = (e) => {
    let isPresent = specification.find((item) => item.type === e.target.name);
    let idInInteger = parseInt(e.target.value);
    if (!isPresent) {
      setSpecification((prev) => [
        ...prev,
        {
          type: e.target.name,
          value: idInInteger,
        },
      ]);
    } else {
      let findIndexOfExistingElem = specification.findIndex(
        (item) => item.type === e.target.name
      );
      if (findIndexOfExistingElem !== -1) {
        let newArray = specification;
        newArray[findIndexOfExistingElem] = {
          type: e.target.name,
          value: idInInteger,
        };
        setSpecification(newArray);
      }
    }
  };

  const getSpecificationOption = (id) => {
    let fillteredOptions =
      selectedCategory?.selectedCategory?.specifications?.filter(
        (item) => item.type.id === id
      );
    return fillteredOptions;
  };

  const initialValues = {
    item_name: "",
    country: "India",
    description: "",
    features: "",
    brand_name: selectedBrand?.name ?? "",

    // offer tab
    gst: "",
    hsn: "",
    weight: "",
    dimensions: "",
    mfg: "",
    importer: "",
    packer_details: "",
    fssai_no: "",
    expiry_details: "",
  };
  const schema = Yup.object().shape({
    item_name: Yup.string()
      .max(180, "Your product name is long. Keep it under 180 characters.")
      .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    country: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    description: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    features: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    brand_name: Yup.string(),

    // offer
    gst: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    hsn: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    weight: Yup.string()
      .trim()
      .matches(/^[0-9]\d*(\.\d+)?$/, "Weight should be only in number.")
      .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    dimensions: Yup.string()
      .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE)
      .matches(
        /^[0-9]+[*,x,X][0-9]+[*,x,X][0-9]+$/,
        "Dimension should be in LxBxH or LXBXH or L*B*H."
      ),
    mfg: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    importer: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    packer_details: Yup.string().required(
      GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE
    ),
    fssai_no: Yup.string(),
    expiry_details: Yup.string(),
  });

  const handleSubmit = (values) => {
    try {
      let arrayTest = specification?.map((item) => item.value);
      values = { ...values, specifications: arrayTest, is_branded: false };
      if (selectedBrand && selectedBrand?.id) {
        values["brand"] = selectedBrand["id"];
        values["is_branded"] = true;
      }
      console.log(
        "values@submit",
        values,
        selectedCategory?.selectedCategory?.id
      );
      const tempVariations = variationRef?.current?.variationSubmit();
      if (!tempVariations) return;
      const default_variation = tempVariations[0];
      console.log("variations@submit", tempVariations);
      const formdata = new FormData();
      formdata.append("name", values?.item_name);
      formdata.append("description", values?.description);
      if (values?.brand) {
        formdata.append("brand", values?.brand);
      }
      formdata.append("is_branded", values?.is_branded ?? false);
      formdata.append("features", values?.features);
      formdata.append("legal", values?.legal);
      formdata.append("country", values?.country);
      formdata.append("gst", values?.gst);
      formdata.append("weight", values?.weight);
      formdata.append("dimensions", values?.dimensions);
      formdata.append("category", selectedCategory?.selectedCategory?.id);
      formdata.append("hsn", values?.hsn);
      formdata.append("specifications", JSON.stringify(values?.specifications));
      formdata.append("options", "[]");
      formdata.append("mfg", values?.mfg);
      formdata.append("importer", values?.importer);
      formdata.append("packer_details", values?.packer_details);
      formdata.append("fssai_no", values?.fssai_no);

      formdata.append("stock", default_variation["stock"]);
      formdata.append("group_price", default_variation["group_price"]);
      formdata.append("selling_price", default_variation["selling_price"]);
      formdata.append("price", default_variation["selling_price"]);
      formdata.append("mrp", default_variation["mrp"]);
      formdata.append("sku", default_variation["sku"]);
      formdata.append(
        "options_list",
        JSON.stringify(default_variation["option_types"])
      );
      formdata.append(
        "expiry_details",
        values?.expiry_details
          ? moment(values?.expiry_details).format("YYYY-MM-DD HH:mm")
          : ""
      );
      formdata.append("variantions", tempVariations);
      let finalPayload = {
        header: auth,
        addProductFormData: formdata,
      };

      // return;
      dispatch(createProductThunk(finalPayload));
    } catch (error) {
      console.log("handleSubmitError", error);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ errors, values, touched, setFieldValue }) => (
          <FormikForm>
            <div className="d-flex justify-content-between align-items-baseline">
              <PageTitle text="Upload Product" />
              <GenericButton type="submit">Save</GenericButton>
            </div>
            <ProductStyledContainer>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="basicDetailContainer">
                    <h5>Basic Detail:</h5>
                    <div className="row flex-grow-1">
                      <Col md={12} sm={12} className="fieldWrapper">
                        <Row>
                          <Col md={12} sm={12}>
                            <Form.Group as={Col} controlId="formGridItemName">
                              <Form.Label>
                                Product Title{" "}
                                <span className="text-danger">*</span>{" "}
                                <CustomTooltip
                                  description="Add the name of the product which will be visible on the app. Include information like fabric, material, capacity etc., within the name"
                                  placement="right"
                                  customStyledClass={{
                                    transform: "translateY(15%)",
                                  }}
                                />
                              </Form.Label>
                              <div className="formControl">
                                <OutlinedTextFields
                                  name="item_name"
                                  type="text"
                                  placeholder="Product title..."
                                  value={values.item_name}
                                  onChange={(e) =>
                                    setFieldValue("item_name", e.target.value)
                                  }
                                  hasError={
                                    touched.item_name && errors.item_name
                                  }
                                />
                                {touched.item_name && errors.item_name && (
                                  <ErrorMessage
                                    className="formError"
                                    message={errors.item_name}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} sm={12}>
                            <Form.Group as={Col} controlId="formGridBrandName">
                              <Form.Label>Brand Name</Form.Label>
                              <div className="formControl">
                                <OutlinedTextFields
                                  type="text"
                                  disabled={true}
                                  name="brand_name"
                                  placeholder="Enter Brand Name"
                                  value={values?.brand_name}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} sm={12}>
                            <Form.Group as={Col} controlId="formGridBrandName">
                              <Form.Label>Country</Form.Label>
                            </Form.Group>
                            <div className="formControl">
                              <select
                                id="country"
                                name="country"
                                className="form-select"
                                onChange={(e) =>
                                  setFieldValue("country", e.target.value)
                                }
                                value={values.country}
                              >
                                <CountryOptions />
                              </select>
                              {touched.country && errors.country && (
                                <ErrorMessage
                                  className="formError"
                                  message={errors.country}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} sm={12}>
                            <Form.Group as={Col} controlId="formGridBrandName">
                              <Form.Label>Recommended Browse Nodes</Form.Label>
                            </Form.Group>
                            <div className="formControl">
                              <div className="categoryContainer">
                                <div className="w-100">
                                  <Form.Control
                                    value={getCategory}
                                    name="category"
                                    type="text"
                                    placeholder="No Category Selected"
                                    aria-label="Disabled input example"
                                    disabled
                                    readOnly
                                  />
                                </div>
                                <Form.Group as={Col} controlId="cartegoryEdit">
                                  <GenericButton
                                    type="button"
                                    onClick={() => setEditCategoryModal(true)}
                                  >
                                    Edit
                                  </GenericButton>
                                </Form.Group>
                              </div>
                              {touched.category && errors.category && (
                                <ErrorMessage
                                  className="formError"
                                  message={errors.category}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>

                        {selectedCategory?.selectedCategory &&
                          selectedCategory?.selectedCategory
                            ?.specification_types &&
                          selectedCategory?.selectedCategory.specification_types
                            .length > 0 &&
                          selectedCategory?.selectedCategory?.specification_types.map(
                            (specification_type) => (
                              <Row className="mb-3">
                                <Col md={6} xs={12}>
                                  <Form.Group
                                    as={Col}
                                    controlId="formGxfcridOMT"
                                  >
                                    <Form.Label
                                      className=""
                                      style={{ color: "#122736" }}
                                    >
                                      {specification_type.name}
                                    </Form.Label>
                                    <Form.Select
                                      className="my-2"
                                      name={specification_type?.name?.replace(
                                        /\s/g,
                                        ""
                                      )}
                                      onChange={(e) => onChangeSelect(e)}
                                      aria-label="Default select example"
                                    >
                                      <option value="select category">
                                        Select Category
                                      </option>
                                      {getSpecificationOption(
                                        specification_type.id
                                      ).map((element) => {
                                        return (
                                          <option
                                            value={element.id}
                                            key={element.id}
                                          >
                                            {element.name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                            )
                          )}

                        <Row>
                          <Col md={12} sm={12}>
                            <Form.Group as={Col} controlId="keyFeature">
                              <Form.Label>
                                Key Product Feature{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <div className="formControl">
                                <OutlinedTextFields
                                  type="text"
                                  placeholder="Key Product Features"
                                  name="key_product_features"
                                  onChange={(e) =>
                                    setFieldValue("features", e.target.value)
                                  }
                                  hasError={touched.features && errors.features}
                                />
                                {touched.features && errors.features && (
                                  <ErrorMessage
                                    className="formError"
                                    message={errors.features}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="flex-grow-1">
                          <Col md={12} sm={12} className="d-flex">
                            <Form.Group
                              as={Col}
                              controlId="formGridProductDesc"
                              className="d-flex flex-column flex-grow-1"
                            >
                              <Form.Label>
                                Product Description
                                <span className="text-danger">*</span>
                                <CustomTooltip
                                  description="Provide the description of the product which will be
                        visible on the app"
                                  placement="right"
                                  customStyledClass={{
                                    transform: "translateY(15%)",
                                  }}
                                />
                              </Form.Label>
                              <div className="formControl d-flex flex-grow-1">
                                <OutlinedTextFields
                                  placeholder="Describe your product..."
                                  style={{ flex: 1, resize: "none" }}
                                  as="textarea"
                                  name="description"
                                  onChange={(e) =>
                                    setFieldValue("description", e.target.value)
                                  }
                                  hasError={
                                    touched.description && errors.description
                                  }
                                />
                                {touched.description && errors.description && (
                                  <ErrorMessage
                                    className="formError"
                                    message={errors.description}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div>
                    <div className="row">
                      <div className="otherInfoContainer">
                        <h5>Delivery Information:</h5>
                        <Row>
                          <Col md={6} sm={12}>
                            <Row>
                              <Col md={12} sm={12}>
                                <Form.Group
                                  as={Col}
                                  controlId="formGridItemName"
                                >
                                  <Form.Label>
                                    Weight (in grams){" "}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <div className="formControl">
                                    <OutlinedTextFields
                                      type="text"
                                      placeholder="Weight"
                                      name="weight"
                                      onChange={(e) =>
                                        setFieldValue("weight", e.target.value)
                                      }
                                      hasError={touched.weight && errors.weight}
                                    />
                                    {touched.weight && errors.weight && (
                                      <ErrorMessage
                                        className="formError"
                                        message={errors.weight}
                                      />
                                    )}
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={12} sm={12}>
                                <Form.Group
                                  as={Col}
                                  controlId="formGridItemName"
                                >
                                  <Form.Label>
                                    Dimensions (in cm)
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <div className="formControl">
                                    <OutlinedTextFields
                                      type="text"
                                      placeholder="Dimensions: Length x Width x Height"
                                      name="dimensions"
                                      onChange={(e) =>
                                        setFieldValue(
                                          "dimensions",
                                          e.target.value
                                        )
                                      }
                                      hasError={
                                        touched.dimensions && errors.dimensions
                                      }
                                    />
                                    {touched.dimensions &&
                                      errors.dimensions && (
                                        <ErrorMessage
                                          className="formError"
                                          message={errors.dimensions}
                                        />
                                      )}
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6} sm={12} className="d-none d-lg-block">
                            <img
                              src={boxImage}
                              className="boxImage"
                              alt="boxImage"
                            />
                          </Col>
                        </Row>
                        <h5>Packaging Information:</h5>
                        <Row>
                          <Col md={6} sm={12}>
                            <Form.Group as={Col} controlId="formGridItemName">
                              <Form.Label>
                                Manufacture Name & Address
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <div className="formControl">
                                <OutlinedTextFields
                                  type="text"
                                  name="mfg"
                                  placeholder="Manufacturer"
                                  onChange={(e) =>
                                    setFieldValue("mfg", e.target.value)
                                  }
                                  hasError={touched.mfg && errors.mfg}
                                />
                                {touched.mfg && errors.mfg && (
                                  <ErrorMessage
                                    className="formError"
                                    message={errors.mfg}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group as={Col} controlId="formGridItemName">
                              <Form.Label>
                                Importer Name & Address
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <div className="formControl">
                                <OutlinedTextFields
                                  type="text"
                                  placeholder="Importer"
                                  name="importer"
                                  onChange={(e) =>
                                    setFieldValue("importer", e.target.value)
                                  }
                                  hasError={touched.importer && errors.importer}
                                />
                                {touched.importer && errors.importer && (
                                  <ErrorMessage
                                    className="formError"
                                    message={errors.importer}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group as={Col} controlId="formGridItemName">
                              <Form.Label>
                                Packer Name & Address
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <div className="formControl">
                                <OutlinedTextFields
                                  type="text"
                                  name="packer_details"
                                  placeholder="Packer Details"
                                  onChange={(e) =>
                                    setFieldValue(
                                      "packer_details",
                                      e.target.value
                                    )
                                  }
                                  hasError={
                                    touched.packer_details &&
                                    errors.packer_details
                                  }
                                />
                                {touched.packer_details &&
                                  errors.packer_details && (
                                    <ErrorMessage
                                      className="formError"
                                      message={errors.packer_details}
                                    />
                                  )}
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group as={Col} controlId="formGridItemName">
                              <Form.Label>
                                Fssai no. (Compulsory for food products)
                              </Form.Label>
                              <div className="formControl">
                                <OutlinedTextFields
                                  type="text"
                                  placeholder="Fssai no."
                                  name="fssai_no"
                                  onChange={(e) =>
                                    setFieldValue("fssai_no", e.target.value)
                                  }
                                  hasError={touched.fssai_no && errors.fssai_no}
                                />
                              </div>
                              {touched.fssai_no && errors.fssai_no && (
                                <ErrorMessage
                                  className="formError"
                                  message={errors.fssai_no}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group as={Col} controlId="formGridItemName">
                              <Form.Label>Expiry Date</Form.Label>
                              <div className="formControl">
                                <ReactDatePicker
                                  placeholderText="Expiry date"
                                  selected={values?.expiry_details ?? ""}
                                  className="form-control input-border"
                                  onChange={(date) => {
                                    setFieldValue("expiry_details", date);
                                  }}
                                  minDate={new Date()}
                                  showTimeSelect
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                />
                                {touched.expiry_details &&
                                  errors.expiry_details && (
                                    <ErrorMessage
                                      className="formError"
                                      message={errors.expiry_details}
                                    />
                                  )}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <h5>Tax Information:</h5>
                        <Row>
                          <Col md={6} sm={12}>
                            <Form.Group as={Col} controlId="formGridItemName">
                              <Form.Label
                                className=""
                                style={{ color: "#122736" }}
                              >
                                HSN Code <span className="text-danger">*</span>
                              </Form.Label>
                              <div className="formControl">
                                <OutlinedTextFields
                                  type="text"
                                  placeholder="HSN Code"
                                  name="hsn"
                                  value={values?.hsn}
                                  onChange={(e) =>
                                    setFieldValue("hsn", e.target.value)
                                  }
                                  hasError={touched.hsn && errors.hsn}
                                />
                                {touched.hsn && errors.hsn && (
                                  <ErrorMessage
                                    className="formError"
                                    message={errors.hsn}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group as={Col} controlId="formGridItemName">
                              <Form.Label
                                className=""
                                style={{ color: "#122736" }}
                              >
                                Tax (%) <span className="text-danger">*</span>
                              </Form.Label>
                              <div className="formControl">
                                <Select
                                  options={tax}
                                  placeholder="Search tax"
                                  onChange={(selected) => {
                                    setFieldValue("gst", selected.value);
                                  }}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      border:
                                        touched.gst && errors.gst
                                          ? "1px solid #dc3545"
                                          : "2px solid #c3c3c3",
                                    }),
                                  }}
                                  // className={`${
                                  //   touched.gst && errors.gst
                                  //     ? "error_input_border"
                                  //     : ""
                                  // }`}
                                />
                                {touched.gst && errors.gst && (
                                  <ErrorMessage
                                    className="formError"
                                    message={errors.gst}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ProductStyledContainer>
          </FormikForm>
        )}
      </Formik>
      {editCategoryModal && (
        <EditCategoryModal
          show={editCategoryModal}
          onHide={() => setEditCategoryModal(false)}
        />
      )}
      <Variation
        hideSave={true}
        ref={variationRef}
        // setFinalData={() => {}}
        // finalData={[]}
        // addProductHandler={() => {}}
      />
    </>
  );
}

export default ProductV2;
