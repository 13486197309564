import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import playStore from "../../../../assets/Images/v2/playstore.png";
import appStore from "../../../../assets/Images/v2/app-store.png";
import appImage from "../../../../assets/Images/v2/app.png";
import groupickTitle from "../../../../assets/Images/v2/groupick_title.png";

const StyledSection = styled.section`
  background-color: #0f122f;
  color: white;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2%;

    .left {
      width: 49%;
      display: flex;
      justify-content: center;
      align-items: center;

      .appImage {
        width: 85%;
      }
    }

    .right {
      width: 49%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title_image {
        width: 65%;
      }

      .subHeading {
        font-size: 2rem;
        margin-top: 10px;
      }

      .buttons {
        width: 30%;
        display: flex;
        justify-content: center;
        // gap: 15px;
        margin-top: 80px;
      }

      .btns_image {
        width: 100%;
        height: 55px;
      }

      .desc {
        text-align: center;
        font-size: 25px;
        line-height: 1.2em;
        margin-top: 20px;
      }

      .btns {
        width: 100%;
      }
    }
  }
  // Medium screen ****
  @media only screen and (max-width: 992px) and (min-width: 600px) {
    .container {
      .right {
        .subHeading {
          font-size: 23px;
          // margin-top: 10px;
        }

        .buttons {
          width: 100%;
          margin-top: 40px;
        }

        .btns_image {
          width: 100%;
          height: 50px;
        }

        .desc {
          font-size: 1.25rem;
        }

        .btns {
          width: 50%;
        }
      }
    }
  }

  // Small screen ***
  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column;
      justify-content: center;

      .left {
        width: 100%;

        .appImage {
          width: 90%;
        }
      }

      .right {
        width: 100%;
        margin-top: 40px;
        padding: 0 24px;

        .title_image {
          width: 80%;
        }

        .subHeading {
          font-size: 1.25rem;
        }

        .buttons {
          width: 65%;
          margin-top: 40px;
        }

        p br {
          display: none;
        }

        .desc {
          text-align: center;
          font-size: 1.2rem;
          margin-top: 20px;
        }
      }
    }
  }
`;

const Cta = () => {
  return (
    <StyledSection>
      <Container className="container">
        <div className="left">
          <img src={appImage} alt="" className="appImage" />
        </div>
        <div className="right">
          <img src={groupickTitle} alt="" className="title_image" />
          <p className="subHeading">India's First Group Shopping App</p>
          <div className="buttons">
            <div className="btns">
              <a
                href="https://play.google.com/store/apps/details?id=com.groupickapp"
                target="_blank"
                rel="noreferrer"
              >
                <img src={playStore} alt="" className="btns_image" />
              </a>
            </div>
            {/* <div className="btns">
              <img src={appStore} alt="" className="btns_image" />
            </div> */}
          </div>
          <p className="desc">
            Download our app to experience
            <br /> group shopping feature
          </p>
        </div>
      </Container>
    </StyledSection>
  );
};

export default Cta;
