import { createSlice } from "@reduxjs/toolkit";

const initState = {
  qc_live: [],
  qc_failed: [],
  qc_pending: [],
  total_count: 0,
};

const qcSlice = createSlice({
  name: "qcSlice",
  initialState: initState,
  reducers: {
    update_qc(state, action) {
      const { payload } = action;
      state.qc_failed = [];
      state.qc_live = [];
      state.qc_pending = [];
      for (let qc of payload.data) {
        switch (qc.status.toUpperCase()) {
          case "SUCCESS":
            state.qc_live.push(qc);
            break;
          case "PENDING":
            state.qc_pending.push(qc);
            break;
          case "FAILED":
            state.qc_failed.push(qc);
            break;
          default:
            break;
        }
      }
      state.total_count = payload?.count ?? 0;
    },
  },
});

export const total_count_selector = (state) => state.qc.total_count;
export const live_qc_count_selector = (state) => state.qc.qc_live.length;
export const failed_qc_count_selector = (state) => state.qc.qc_failed.length;
export const pending_qc_count_selector = (state) => state.qc.qc_pending.length;
export const live_qc_selector = (state) => state.qc.qc_live;
export const failed_qc_selector = (state) => state.qc.qc_failed;
export const pending_qc_selector = (state) => state.qc.qc_pending;
export const all_qc_selector = (state) => [
  ...state.qc.qc_live,
  ...state.qc.qc_failed,
  ...state.qc.qc_pending,
];
export const { update_qc } = qcSlice.actions;

export default qcSlice.reducer;
