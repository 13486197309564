import React, { useEffect, useState } from "react";
import { MdPermContactCalendar, MdAccountBalanceWallet } from "react-icons/md";
import { AiOutlineUser, AiOutlineBank, AiOutlinePhone } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import StyledBox from "../../compoents/Box";
import { Link } from "react-router-dom";

import styled, { css } from "styled-components";
import MobileNumber from "./mobileNumber";
import CompanyDetails from "./companyDetails";
import BankDetails from "./bankDetails";
import LegalPolices from "./legalPolices";
import PageTitle from "../../compoents/PageTitle";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import RegistrationFee from "./RegistrationFee";
import {
  register_details_selector,
  setLoggedIn,
} from "../../redux/slices/account";
import { useDispatch, useSelector } from "react-redux";
import PaidServices from "./PaidServices";
import { BsCurrencyExchange } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Wallet from "../Wallet";

const StyledTag = styled.div`
  position: relative;
  right: 0;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(74, 30, 91, 1) 0%,
    rgba(26, 27, 73, 1) 100%
  );
  color: #fff;
  padding: 4px 12px;
  text-align: left;
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 8px;
  a {
    color: #fff;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    border-radius: 4px;
    margin-bottom: 8px;
    position: relative;
  }
`;

const StyledSettingList = styled.div`
  ${(props) =>
    props.active &&
    css`
      border-radius: 8px;
      //   border: 2px solid #ff4d00;
      background-color: var(--orange-primary);
      color: white;
    `};
`;

const tabKeyPair = {
  mobile: { key: 1, label: "Mobile Number" },
  company_details: { key: 2, label: "Company Details" },
  bank_details: { key: 3, label: "Bank Details" },
  legal: { key: 4, label: "Legal & policies" },
  register: { key: 5, label: "Registeration" },
  membership: { key: 6, label: "Membership Plans" },
  wallet: { key: 7, label: "My Wallet" },
};
const Settings = () => {
  const history = useHistory();
  const register_details = useSelector(register_details_selector);
  const [tab, setTab] = useState(1);

  const dispatch = useDispatch();
  let auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [user, setUser] = React.useState(auth.mee_too_user);

  const [updatedData, setUpdatedData] = useState({});

  const handleChange = (e) => {
    let temp = { ...updatedData };
    temp[e.target.name] = e.target.value;

    setUpdatedData(temp);
  };

  const fetchProfile = async () => {
    const res = await sellerApiService.getUserProfile();
    if (res.data) {
      const { user } = res.data;
      dispatch(setLoggedIn(user));
      setUser(user);
    }
  };

  useEffect(() => {
    const param = new URLSearchParams(window.location.search);
    if (param && param.has("tab")) {
      setTab(param.get("tab"));
    }
    fetchProfile();
  }, []);

  return (
    <div>
      <PageTitle text={"Settings"} />

      <div className="row  mb-5">
        <div className="col-md-4">
          <StyledBox className="p-3 h-100">
            <h3>Settings</h3>
            <div className="list-group">
              <StyledSettingList
                className="p-3 cursor-pointer my-2 d-flex align-items-center"
                active={tab === tabKeyPair.mobile.key}
                onClick={() => setTab(tabKeyPair.mobile.key)}
              >
                <AiOutlinePhone size={25} className="mr-4" />
                <span className="mx-2">{tabKeyPair.mobile.label}</span>
              </StyledSettingList>
              <StyledSettingList
                className="p-3 cursor-pointer my-2 d-flex align-items-center"
                active={tab === tabKeyPair.company_details.key}
                onClick={() => setTab(tabKeyPair.company_details.key)}
              >
                <AiOutlineUser size={25} className="mr-4" />
                <span className="mx-2">{tabKeyPair.company_details.label}</span>
              </StyledSettingList>
              <StyledSettingList
                className="p-3 cursor-pointer my-2 d-flex align-items-center"
                active={tab === tabKeyPair.bank_details.key}
                onClick={() => setTab(tabKeyPair.bank_details.key)}
              >
                <AiOutlineBank size={25} className="mr-4" />
                <span className="mx-2">{tabKeyPair.bank_details.label}</span>
              </StyledSettingList>
              <StyledSettingList
                className="p-3 cursor-pointer my-2 d-flex align-items-center"
                active={tab === tabKeyPair.legal.key}
                onClick={() => setTab(tabKeyPair.legal.key)}
              >
                <MdPermContactCalendar size={25} className="mr-4" />
                <span className="mx-2">{tabKeyPair.legal.label}</span>
              </StyledSettingList>
              <StyledSettingList
                className="p-3 cursor-pointer my-2 d-flex align-items-center"
                active={tab == tabKeyPair.membership.key}
                onClick={() => setTab(tabKeyPair.membership.key)}
              >
                <BsCurrencyExchange size={25} className="mr-4" />
                <span className="mx-2">{tabKeyPair.membership.label}</span>
              </StyledSettingList>
              <StyledSettingList
                className="p-3 cursor-pointer my-2 d-flex align-items-center"
                active={tab == tabKeyPair.wallet.key}
                onClick={() => setTab(tabKeyPair.wallet.key)}
              >
                <MdAccountBalanceWallet size={25} className="mr-4" />
                <span className="mx-2">{tabKeyPair.wallet.label}</span>
              </StyledSettingList>
              <Link
                to="/support"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <StyledSettingList className="p-3 cursor-pointer my-2 d-flex align-items-center">
                  <BiSupport size={25} className="mr-4" />
                  <span className="mx-2">Support</span>
                </StyledSettingList>
              </Link>
            </div>
          </StyledBox>
        </div>
        <div className="col-md-8">
          {tab == tabKeyPair.membership.key &&
            register_details &&
            register_details?.product_limit !== -1 && (
              <StyledTag>
                First {register_details?.product_limit} catalogue listing is
                FREE, after that Activate Seller Membership Plan.
              </StyledTag>
            )}
          <StyledBox className="p-3" style={{ height: "100%" }}>
            {tab === tabKeyPair.mobile.key && (
              <MobileNumber user={user} handleChange={handleChange} />
            )}
            {tab === tabKeyPair.company_details.key && (
              <CompanyDetails user={user} handleChange={handleChange} />
            )}
            {tab === tabKeyPair.bank_details.key && (
              <BankDetails user={user} handleChange={handleChange} />
            )}
            {tab === tabKeyPair.legal.key && (
              <LegalPolices user={user} handleChange={handleChange} />
            )}
            {tab == tabKeyPair.register.key && <RegistrationFee />}
            {tab == tabKeyPair.membership.key && <PaidServices />}
            {tab == tabKeyPair.wallet.key && <Wallet />}
          </StyledBox>
        </div>
      </div>
    </div>
  );
};

export default Settings;
