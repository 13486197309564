import React from "react";
import { Dropdown } from "react-bootstrap";

import { IMAGES } from "../config";
import { useCookies } from "react-cookie";
import { FiBell } from "react-icons/fi";
import { BsQuestionCircle } from "react-icons/bs";
import styled from "styled-components";
import { VscThreeBars } from "react-icons/vsc";

const IconContainer = styled.li`
  display: flex;
  align-items: center;

  .icon-container-wrapper {
    margin: 0px 30px;
    padding: 0px 20px;
    height: 60%;
    border-right: 1px solid #c9c9c9;
  }
`;
const Header = (props) => {
  let [cookie, remove] = useCookies(["mee_too_user"]);

  const signutHandler = () => {
    remove("mee_too_user", { path: "/" });
  };

  return (
    <>
      <div className="header d-flex justify-content-between">
        {/* Logo */}
        {!props.show && (
          <div className="header-left">
            <a href="/" className="logo">
              <img src={IMAGES.COMPANY_LOGO} alt="fund-raiser-logo" />
            </a>
          </div>
        )}

        {/* /Logo */}
        <a href="#0" id="toggle_btn">
          <i className="fe fe-text-align-justify toggleset" />
        </a>

        {/* Mobile Menu Toggle */}

        <a href="#0" className="mobile_btn" id="mobile_btn">
          {/* <FaBars></FaBars> */}
        </a>
        {/* /Mobile Menu Toggle */}

        <ul className="nav calling_meeting user-menu h-100 ">
          <IconContainer className="nav-item dropdown noti-dropdown d-none d-md-flex align-items-center">
            <div className="icon-container-wrapper d-flex align-items-center">
              <BsQuestionCircle className="mx-3" size={30} />
              <FiBell className="fe fe-bell Icon_feather mx-3" size={30} />
            </div>
          </IconContainer>
          <li className="nav-item dropdown noti-dropdown d-none d-md-flex align-items-end justify-content-center flex-column">
            <h6 className="m-0" style={{ fontSize: "20px" }}>
              {cookie?.mee_too_user?.type}
            </h6>
            <p className="m-0" style={{ fontSize: "12px", color: "#8C8C8C" }}>
              {/* something@gmail.com */}
              {cookie?.mee_too_user?.mobile}
            </p>
          </li>
          <li className="nav-item dropdown has-arrow d-flex align-items-center">
            <VscThreeBars
              size={30}
              className="d-sm-none d-block"
              onClick={() => props.setShow(!props.show)}
            />
            <Dropdown className="user-dropdown">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="user_dropdown"
              >
                <span className="user-img mt-0">
                  <img
                    height="35px"
                    width="35px"
                    src={IMAGES.PROFLE_IMAGE}
                    alt="User"
                    className="avatar-img rounded-circle img_cover_fit"
                  />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" className="no-padding">
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={IMAGES.PROFLE_IMAGE}
                        alt="User"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                    <div className="user-text">
                      <h6>{"Name"}</h6>
                      <p className="text-muted mb-0">Admin</p>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={signutHandler}
                  className="d-block d-flex justify-content-center"
                >
                  {/* <SignOut />
                   */}
                  SignOut
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
