import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IMAGES, IMAGESV2, PATH } from "../../../config";

import AuthHeader from "../AuthHeader";
import AuthFooter from "../AuthFooter";
import AuthCTA from "../AuthCTA";

const StyledDeliveryPage = styled.main`
  background-color: white;
  height: calc(100vh - 96px);
  margin-top: 96px;
  section {
    padding: 3rem 5rem;
  }
  .deliver__main {
    padding: 3rem 5rem;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .deliver__img {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      img {
        width: 100%;
        max-width: 500px;
        animation-name: ride;
        animation-duration: 2.5s;
      }
    }
    .deliver_logistics {
      flex: 1;
      animation-name: scaleUp;
      animation-duration: 2s;
      .deliver__logistic {
        font-size: 48px;
        font-weight: 700;
        text-align: end;
        display: flex;
        flex-direction: column;
        line-height: 100%;
      }
      .deliver__logistic--list {
        gap: 0.5rem;
      }
      hr {
        width: 80%;
        margin-left: auto;
        border: 2px solid black;
      }
    }
  }
  @keyframes ride {
    from {
      transform: translateX(-120%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes scaleUp {
    from {
      scale: 0;
    }
    to {
      scale: 1;
    }
  }
  @media (min-width: 1400px) {
    .deliver__main {
      max-width: 1320px;
      margin: auto;
    }
  }
  @media (max-width: 670px) {
    .deliver__main {
      padding: 24px;
      flex-direction: column;
      gap: 12px;
      .deliver__img {
        max-height: 40%;
        aspect-ratio: 1/1;
      }
      .deliver_logistics {
        width: 100%;
        animation: none;
        hr {
          width: 50%;
          margin: 8px auto;
        }
        .deliver__logistic {
          font-size: 24px;
          text-align: center;
        }
        .deliver__logistic--list {
          gap: 0;
        }
      }
    }
  }
`;
const Delivery = () => {
  let history = useHistory();

  return (
    <StyledDeliveryPage>
      <AuthHeader activePage="delivery" />
      <div className="deliver__main">
        <div className="deliver__img">
          <img src={IMAGESV2.delivery} alt="delivery" />
        </div>
        <div className="deliver_logistics">
          <div className="deliver__logistic">
            Our Delivery <br />
            Partners
          </div>
          <hr />
          <div className="deliver__logistic deliver__logistic--list">
            <span>DELHIVERY &</span>
            <span>
              <span className="text-danger">Ecom</span> <br /> Express
            </span>
          </div>
        </div>
      </div>
      <AuthCTA />
      <AuthFooter />

      <div className="text-center border-top-1 mx-5 py-4 copy-right-footer">
        <h4>@2023 Getzie Technology Pvt Ltd. All Right Reserved.</h4>
      </div>
    </StyledDeliveryPage>
  );
};

export default Delivery;
