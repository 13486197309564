import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import CategoryDataService from "../services/category.service";
import {combineReducers} from "redux";
import {toast} from "react-toastify";

const initialState = {
  catgeoryListLoading: false,
  catgeoryListSuccess: false,
  catgeoryListFailure: false,
  catgeoryListError: null,
  catgeoryListMessage: "",
  categoryList: [],

  firstLevelCate: null,
  secondLevelCate: null,
  thirdLevelCate: null,
};

export const getcategoryList = createAsyncThunk(
  "category/get",
  async (auth, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
    try {
      const res = await CategoryDataService.getCatgeories(auth);
      if (!res.status === 200) {
        toast.error("An error occured. Please try again.");
        return rejectWithValue(res.data);
      }
      return fulfillWithValue(res);
    } catch (error) {
      toast.error("An error occured. Please try again.");
      throw rejectWithValue(error);
    }
  }
);

const fetchCategorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: {
    [getcategoryList.pending]: (state, action) => {
      state.catgeoryListLoading = true;
      state.catgeoryListSuccess = false;
      state.catgeoryListFailure = false;
      state.catgeoryListError = null;
      state.catgeoryListMessage = null;
    },
    [getcategoryList.fulfilled]: (state, action) => {
      // console.log("getcategoryList => action", action);
      state.catgeoryListLoading = false;
      state.catgeoryListSuccess = true;
      state.catgeoryListFailure = false;
      state.catgeoryListError = null;
      state.catgeoryListMessage = action.payload.data.message;
      state.categoryList = action?.payload?.data?.results;
    },
    [getcategoryList.rejected]: (state, action) => {
      state.catgeoryListLoading = false;
      state.catgeoryListSuccess = false;
      state.catgeoryListFailure = true;
      state.catgeoryListError = action.payload?.response?.data?.message;
      state.catgeoryListMessage = null;
    },
  },
});

export const getCateSelector = (state) => state.category;

// second slice
const dashboardSelectedInitialState = {
  firstLevelCate: {},
  secondLevelCate: {},
  thirdLevelCate: {},
  selectedCategory: {},
};

const dashboardCateSelectedSlice = createSlice({
  name: "dashboard_category",
  initialState: dashboardSelectedInitialState,
  reducers: {
    setFirstLevelCate: (state, action) => {
      state.firstLevelCate = action.payload;
      state.secondLevelCate = {};
      state.thirdLevelCate = {};
      state.selectedCategory = {};
    },
    setSecondLevelCate: (state, action) => {
      state.firstLevelCate = state.firstLevelCate;
      state.secondLevelCate = action.payload;
      state.thirdLevelCate = {};
      state.selectedCategory = {};
    },
    setThirdLevelCate: (state, action) => {
      state.firstLevelCate = state.firstLevelCate;
      state.secondLevelCate = state.secondLevelCate;
      state.thirdLevelCate = action.payload;
      state.selectedCategory = {};
    },
    setSelectedCategory: (state, action) => {
      state.firstLevelCate = state.firstLevelCate;
      state.secondLevelCate = state.secondLevelCate;
      state.thirdLevelCate = state.thirdLevelCate;
      state.selectedCategory = action.payload;
    },
  },
});

export const {
  setFirstLevelCate,
  setSecondLevelCate,
  setThirdLevelCate,
  setSelectedCategory,
} = dashboardCateSelectedSlice.actions;

export const dashboardCateSelector = (state) => state.dashboard_category_level;

const combineCateSlice = {
  fetchCategorySlice: fetchCategorySlice.reducer,
  dashboardCateSelectedSlice: dashboardCateSelectedSlice.reducer,
};

export default combineCateSlice;
