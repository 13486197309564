import { Link } from "react-router-dom";
import Box from "../../compoents/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { IMAGES } from "../../config";

const StyledBadge = styled(Badge)`
	background-color: ${({ bg }) =>
		bg && bg !== "" ? bg : "#FFE7E7"} !important;
	color: ${({ color }) => (color && color !== "" ? color : "#FF0000")};
	border: ${({ border }) => (border && border !== "" ? border : "none")};
	font-size: ${({ fontSize }) =>
		fontSize && fontSize !== "" ? fontSize : "18px"};
	border-radius: ${({ borderRadius }) =>
		borderRadius && borderRadius !== "" ? borderRadius : "8px"};
	padding: ${({ padding }) =>
		padding && padding !== "" ? padding : "12px 25px"};

	/* @media (max-width: 576px) {
		margin-top: 20px;
		margin-bottom: 20px;
	}  */
`;

const CardStyledImage = styled(Image)`
	/* border: 1px solid #a6a6a6;
	border-radius: 8px;
	@media (min-width: 992px) {
		min-height: 152px;
	} */
	height: 100%;
	width:100%;
	max-height: 500px;
	min-height: 100%;
	@media (max-width: 992px) {
		max-height: 262px;
	}
	@media (max-width: 576px) {
		max-height: 500px;
	}
`;

const CashImage = styled(Image)`
	width: 43px;
	height: 43px;
`;

const BackArrow = styled.img`
	width: 23px;
	height: 23px;
`;

const OrderReturnDetail = () => {
	return (
		<Box className="p-3">
			<Row className="align-items-center justify-content-between gx-1 gy-2">
				<Col xs={12} sm={1} xxl={1}>
					<Link to="/return-rto-order">
						<BackArrow
							alt="back-icon"
							src="https://img.icons8.com/external-prettycons-lineal-prettycons/98/000000/external-left-arrow-orientation-prettycons-lineal-prettycons.png"
						/>
					</Link>
				</Col>
				<Col xs={12} sm={7} md={7} xxl={8}>
					<div class="d-flex align-items-center justify-content-center flex-wrap">
						<h3
							className="text-muted mb-0 me-2"
							style={{ fontSize: "18px" }}
						>
							Order <span className="text-dark">#1003</span>
						</h3>
						<StyledBadge
							fontSize="15px"
							className="fw-bold d-flex me-2 align-items-center my-sm-0 my-2"
						>
							Paid
						</StyledBadge>
						<StyledBadge
							color="#0DB691"
							bg="#E3FFF9"
							fontSize="15px"
							className="fw-bold d-flex align-items-center my-0"
						>
							Delivered
						</StyledBadge>
					</div>
				</Col>
				<Col sm={4} xxl={3}>
					<span>
						<svg
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							x="0"
							y="0"
							width="610.398"
							height="610.398"
							viewBox="0 0 610.398 610.398"
							style={{ width: "23px", height: "23px" }}
						>
							<path d="M159.567 0h-15.329c-1.956 0-3.811.411-5.608.995-8.979 2.912-15.616 12.498-15.616 23.997v51.613c0 2.611.435 5.078 1.066 7.44 2.702 10.146 10.653 17.552 20.158 17.552h15.329c11.724 0 21.224-11.188 21.224-24.992V24.992c0-13.804-9.5-24.992-21.224-24.992zM461.288 0h-15.329c-11.724 0-21.224 11.188-21.224 24.992v51.613c0 13.804 9.5 24.992 21.224 24.992h15.329c11.724 0 21.224-11.188 21.224-24.992V24.992C482.507 11.188 473.007 0 461.288 0z" />
							<path d="M539.586 62.553h-37.954v14.052c0 24.327-18.102 44.117-40.349 44.117h-15.329c-22.247 0-40.349-19.79-40.349-44.117V62.553H199.916v14.052c0 24.327-18.102 44.117-40.349 44.117h-15.329c-22.248 0-40.349-19.79-40.349-44.117V62.553H70.818c-21.066 0-38.15 16.017-38.15 35.764v476.318c0 19.784 17.083 35.764 38.15 35.764h468.763c21.085 0 38.149-15.984 38.149-35.764V98.322c.005-19.747-17.059-35.769-38.144-35.769zM527.757 557.9l-446.502-.172V173.717h446.502V557.9z" />
							<path d="M353.017 266.258h117.428c10.193 0 18.437-10.179 18.437-22.759s-8.248-22.759-18.437-22.759H353.017c-10.193 0-18.437 10.179-18.437 22.759 0 12.575 8.243 22.759 18.437 22.759zM353.017 348.467h117.428c10.193 0 18.437-10.179 18.437-22.759 0-12.579-8.248-22.758-18.437-22.758H353.017c-10.193 0-18.437 10.179-18.437 22.758 0 12.58 8.243 22.759 18.437 22.759zM353.017 430.676h117.428c10.193 0 18.437-10.18 18.437-22.759s-8.248-22.759-18.437-22.759H353.017c-10.193 0-18.437 10.18-18.437 22.759s8.243 22.759 18.437 22.759zM353.017 512.89h117.428c10.193 0 18.437-10.18 18.437-22.759 0-12.58-8.248-22.759-18.437-22.759H353.017c-10.193 0-18.437 10.179-18.437 22.759 0 12.579 8.243 22.759 18.437 22.759zM145.032 266.258H262.46c10.193 0 18.436-10.179 18.436-22.759s-8.248-22.759-18.436-22.759H145.032c-10.194 0-18.437 10.179-18.437 22.759.001 12.575 8.243 22.759 18.437 22.759zM145.032 348.467H262.46c10.193 0 18.436-10.179 18.436-22.759 0-12.579-8.248-22.758-18.436-22.758H145.032c-10.194 0-18.437 10.179-18.437 22.758.001 12.58 8.243 22.759 18.437 22.759zM145.032 430.676H262.46c10.193 0 18.436-10.18 18.436-22.759s-8.248-22.759-18.436-22.759H145.032c-10.194 0-18.437 10.18-18.437 22.759s8.243 22.759 18.437 22.759zM145.032 512.89H262.46c10.193 0 18.436-10.18 18.436-22.759 0-12.58-8.248-22.759-18.436-22.759H145.032c-10.194 0-18.437 10.179-18.437 22.759.001 12.579 8.243 22.759 18.437 22.759z" />
						</svg>
					</span>
					<span className="text-muted ms-1">06.11.2021 at 10:40 AM</span>
				</Col>
			</Row>
			<Row className="my-4 justify-content-center gy-4">
				<Col xs={12} sm={8} md={3} xl={3} xxl={3}>
					<CardStyledImage
						className="py-2 px-3"
						thumbnail
						src={IMAGES.Return_Detail_Card_Image}
					/>
				</Col>
				<Col md={9} sm={8} xxl={9}>
					<div className="d-flex justify-content-between flex-wrap">
						<h5 className="font-bold text-dark mb-0">
							Wine Solid Cut Outs Gown
						</h5>
						<p className="fs-5 mb-0">
							<span className="me-2" style={{ color: "#FF0000" }}>
								₹ 430
							</span>
							<del className="text-muted">₹ 999</del>
						</p>
						<span className="text-dark fs-5 fw-bold">1</span>
						<span className="text-dark fs-5 fw-bold">₹ 430</span>
					</div>
					<p className="mb-0 mt-2">
						<span className="text-muted">Color: </span>
						<span className="text-dark fw-bold">Wind Red </span>
					</p>
					<p className="mb-0 mt-2">
						<span className="text-muted">Size: </span>
						<span className="text-dark fw-bold">M</span>
					</p>
				</Col>
			</Row>
			<hr />
			<div className="my-4">
				<h4>Payment</h4>
				<Row className="align-items-center">
					<Col xs={2}>
						<Box
							background="#F9F9F9"
							boxShadow="0px 3px 6px #00000029"
							borderRadius="8px"
							style={{ width: "82px", height: "58px" }}
							className="d-flex align-items-center justify-content-center"
						>
							<CashImage src={IMAGES.DOLLAR} />
						</Box>
					</Col>
					<Col>
						<p className="mb-0">Pay on delivery.</p>
					</Col>
					<Col className="text-end">
						<span className="fw-bold fs-4">₹ 0</span>
					</Col>
				</Row>
			</div>
			<hr />
			<div className="px-2 my-4">
				<h6 className="fs-5 mb-3">Price Details</h6>
				<div className="d-flex justify-content-between">
					<span className="text-muted fs-6">Product Charges</span>
					<span className="text-dark fs-6">₹430</span>
				</div>
				<div className="d-flex justify-content-between">
					<span className="text-muted fs-6">
						Delivery Charges
						<img src={IMAGES.INFO} style={{ width: "10px" }} alt="" />
					</span>
					<span className="text-dark fs-6">+₹0</span>
				</div>
				<div className="d-flex justify-content-between">
					<span className="text-muted fs-6">
						Special Offer
						<img src={IMAGES.INFO} style={{ width: "10px" }} alt="" />
					</span>
					<span className="text-dark fs-6">+₹0</span>
				</div>
				<hr />
				<div className="d-flex justify-content-between ">
					<span className="font-bold fs-5">Order Total</span>
					<span className="font-bold fs-5">₹430</span>
				</div>
			</div>
			<div>
				<h5 className="font-bold">Reason of Returning</h5>
				<Card
					style={{
						background: "#FFFFFF",
						border: "1px solid #9D9D9D",
						borderRadius: "8px",
					}}
				>
					<Card.Body>
						<Card.Title className="font-bold fs-6">
							Not as Expected
						</Card.Title>
						<Card.Text className="text-muted">
							The size is big …. I need a smaller size too and the
							quality of the product is not as expected
						</Card.Text>
					</Card.Body>
				</Card>
			</div>
		</Box>
	);
};

export default OrderReturnDetail;
