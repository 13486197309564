// eslint-disable-next-line
import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { GenericButton } from "../../compoents/Button";
const ContactsTab = () => {
  return (
    <div>
      {/* <Row> */}
      <Form>
        <Row className="mb-3">
          <Col md={6} xs={12}>
            <Form.Group as={Col} controlId="formGridItemName">
              <Form.Label className="" style={{ color: "#122736" }}>
                Item Name
              </Form.Label>
              <Form.Control type="text" placeholder="Item Name" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6} xs={12}>
            <Form.Group as={Col} controlId="formGridBrandName">
              <Form.Label className="" style={{ color: "#122736" }}>
                Brand Name
              </Form.Label>
              <Form.Control type="text" placeholder="Enter Brand Name" />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row className="mb-3">
          <Col md={6} xs={12}>
            <Form.Group as={Col} controlId="formGridBrandName">
              <Form.Label className="" style={{ color: "#122736" }}>
                Recommended Browse Nodes
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Men Wear> T-shirts> Round neck"
                aria-label="Disabled input example"
                disabled
                readOnly
              />
            </Form.Group>
          </Col>
          <Col md={2} xs={3}>
            <Form.Group
              as={Col}
              className="pt-1 mt-1"
              controlId="formGridBrandName"
            >
              {/* <Form.Label className="" style={{ color: "#122736" }}>
									This is
								</Form.Label> */}
              <Form.Control
                className="mt-4"
                type="text"
                placeholder="Edit"
                aria-label="Disabled input example"
                disabled
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6} xs={12}>
            <Form.Group as={Col} controlId="formGridDepartment">
              <Form.Label className="" style={{ color: "#122736" }}>
                Department
              </Form.Label>
              <Form.Control type="text" placeholder="Men" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6} xs={12}>
            <Form.Group as={Col} controlId="formGridOMT">
              <Form.Label className="" style={{ color: "#122736" }}>
                Outar Material Type
              </Form.Label>
              <Form.Control type="text" placeholder="Fabric" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6} xs={12}>
            <Form.Group as={Col} controlId="formGridOMT">
              <Form.Label className="" style={{ color: "#122736" }}>
                Fabric
              </Form.Label>
              <Form.Control type="text" placeholder="Cotton" />
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-5 text-center">
          <GenericButton>Save and Finish</GenericButton>
        </div>
      </Form>
      {/* </Row> */}
    </div>
  );
};

export default ContactsTab;
