import React from "react";
import { IMAGESV2 } from "../../../../config";
import { useHistory } from "react-router-dom";
import HeroSection from "../../Landing/HeroSection";

function PricingHeroSection() {
  const history = useHistory();
  return (
    <HeroSection
      heading1="Get Fast & Secure"
      heading2="Payment with Groupick"
      desc="7-day payment cycle"
      buttonText="Start Selling"
      bgImg={IMAGESV2.pricing_bg}
      rightImgSrc={IMAGESV2.Hero2}
      onClick={() => history.push("/auth/register")}
      isParallax={true}
    />
  );
}

export default PricingHeroSection;
