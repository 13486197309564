import React from "react";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import useWallet from "../../hooks/useWallet";

function DashboardWallet() {
  const { fetchWalletDetail, fetching, total } = useWallet();

  useEffect(() => {
    fetchWalletDetail();
  }, []);

  if (fetching) {
    return null;
  }

  return (
    <Link
      data-toggle="tooltip"
      data-placement="bottom"
      title="Wallet"
      to="/settings?tab=7"
      className="mx-2 text-decoration-none primary-color d-inline-flex flex-column align-items-center"
    >
      <MdOutlineAccountBalanceWallet size={20} />
      <sub>
        <span className="fw-bold">{total}</span>
      </sub>
    </Link>
  );
}

export default DashboardWallet;
