import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { GenericButton } from "../../../compoents/Button";
import { OutlinedTextFields } from "../../../compoents/Forms2";
import { GENERIC_MESSAGE, IMAGES, PATH, TOAST_STYLE } from "../../../config";
import { verifyOtp, userSelector } from "../../../redux/slices/account";
import Loader from "../../../compoents/Loader";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import ErrorMessage from "../../../compoents/ErrorMessage";

const StyledContainer = styled.div`
  width: 100%;
  .input-box {
    color: #fff;
    border-radius: 32px !important;
    padding: 10px;
    height: 40px;
    border: 1px solid #b7b7b7;
    padding: 0 12px;
  }
  .customBtn {
    font-weight: 500;
    border-radius: 8px;
    margin-top: 16px;
    &:hover {
      background: none;
    }
  }
`;

const StyledOutlinedTextField = styled(OutlinedTextFields)`
  border: none;
  background: transparent !important;

  color: #fff;
  max-height: 40px;
  font-weight: 500;

  :focus {
    background: transparent !important;

    border-color: none;
    box-shadow: none;
    outline: none;
    color: #fff;
  }
`;

const VerifyOtp = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  const phone = sessionStorage.getItem("phone"); // let account = useSelector((state) => state.account);

  let {
    verifyOtpLoading,
    verifyOtpSuccess,
    verifyOtpFailure,
    verifiedUser,
    token,
  } = useSelector(userSelector);
  const [cookies, setCookie] = useCookies(["mee_too_user"]);

  const goToLoginScreen = () => {
    history.push(PATH.LOGIN);
  };

  useEffect(() => {
    if (verifyOtpSuccess) {
      let mee_too_user = {
        ...verifiedUser,
        token,
      };
      setCookie("mee_too_user", mee_too_user);
    }
    if (verifyOtpFailure) {
      toast.error("Invalid OTP provided", TOAST_STYLE);
    }
  }, [verifyOtpSuccess, verifyOtpFailure]);

  const CreateAccountSchema = Yup.object().shape({
    mobile: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    otp: Yup.string()
      .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE)
      .min(6, "Please enter 6 digits OTP."),
  });

  return (
    <StyledContainer>
      <div className="text-center">
        <h5 className="mb-4">Verify OTP to continue</h5>
      </div>
      <Formik
        initialValues={{
          mobile: phone,
          otp: "",
        }}
        validationSchema={CreateAccountSchema}
        onSubmit={(values) => {
          dispatch(verifyOtp(values));
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <div className="d-flex rounded align-items-center input-box">
              <span className="pe-1">+91</span>

              <StyledOutlinedTextField
                className="disabled"
                disabled={true}
                style={{ marginRight: "5px" }}
                name="mobile"
                type="number"
                maxlength={10}
                placeholder="Enter Mobile*"
                onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
                onChange={(e) =>
                  setFieldValue("mobile", e.target.value.slice(0, 10))
                }
                // onBlur={handleBlur}
                value={values.mobile}
              />
            </div>
            <div className=" rounded input-box my-2">
              <StyledOutlinedTextField
                style={{ marginRight: "5px" }}
                name="otp"
                type="text"
                placeholder="Enter OTP*"
                onChange={(e) => setFieldValue("otp", e.target.value)}
                // onBlur={handleBlur}
                // value={values.firstName}
              />
            </div>
            {touched.otp && errors.otp && <ErrorMessage message={errors.otp} />}
            {/* <div className="text-center my-3">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
                className="text-center text-black border-0 bg-transparent"
              >
                Re-enter number
              </button>
            </div> */}
            <div className="text-center">
              {/* <GenericButton>Cancel</GenericButton> */}
              <GenericButton
                type="submit"
                // onClick={registerUserHandler}
                disabled={verifyOtpLoading}
                className={"py-2 customBtn  px-5 text-center"}
              >
                {verifyOtpLoading === true ? (
                  <>
                    Loading....
                    <Loader />
                  </>
                ) : (
                  "Verify Otp"
                )}
              </GenericButton>
            </div>
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};

export default VerifyOtp;
