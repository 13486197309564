import moment from "moment";

export const sheetHeader = [
  //   {
  //     key: "date",
  //     label: "Date",
  //   },
  {
    key: "order_id",
    label: "Order",
  },
  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "commission_amount",
    label: "Commission",
  },
  {
    key: "tcs",
    label: "TCS",
  },
  {
    key: "tds",
    label: "TDS",
  },
  {
    key: "penality",
    label: "Penality",
  },
  {
    key: "waiver",
    label: "Waiver/Compensation",
  },
  {
    key: "net_amount",
    label: "Net Amount",
  },
];

const dateFormatter = (cell, row) => {
  const orderDate = moment(cell).format("DD-MM-YYYY");
  return orderDate;
};

export const nextColumn = [
  //   {
  //     dataField: "date",
  //     text: "Date",
  //     formatter: dateFormatter,
  //   },
  {
    dataField: "order_id",
    text: "Order",
  },
  {
    dataField: "amount",
    text: "Amount",
  },
  {
    dataField: "penality",
    text: "Penality",
  },
  {
    dataField: "waiver",
    text: "Waiver/Compensation",
  },
  {
    dataField: "net_amount",
    text: "Net Amount",
  },
];

export const lastColumn = [
  {
    dataField: "date",
    text: "Date",
    formatter: dateFormatter,
  },
  {
    dataField: "payment_ref",
    text: "Payment ref",
  },
  {
    dataField: "amount",
    text: "Amount",
  },
  {
    dataField: "penality",
    text: "Penality",
  },
  {
    dataField: "waiver",
    text: "Waiver/Compensation",
  },
  {
    dataField: "net_amount",
    text: "Net Amount",
  },
];
