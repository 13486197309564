import React from "react";
import { RiCalendarEventFill } from "react-icons/ri";
import styled from "styled-components";

const CustomDatePickerStyled = styled.div`
  display: flex;
  gap: 4px;
  .customDatePicker__icon {
    color: var(--primary-color);
  }
  .customDatePicker__label {
    font-weight: 400;
    color: #4f4f4f;
    font-size: 14px;
  }
  .disabled-icon {
    color: grey;
    cursor: not-allowed;
  }
`;

const CustomDatePicker = React.forwardRef((props, ref) => {
  return (
    <CustomDatePickerStyled>
      <RiCalendarEventFill
        className={`customDatePicker__icon ${
          props?.disabled ? "disabled-icon" : ""
        }`}
        size={24}
        role="button"
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
      />
      {props.value && (
        <label
          className="customDatePicker__label"
          role="button"
          onClick={(e) => {
            e.preventDefault();
            props.onClick();
          }}
          ref={ref}
        >
          {props.value || props.placeholder}
        </label>
      )}
    </CustomDatePickerStyled>
  );
});

export default CustomDatePicker;
