import React from "react";
import styled from "styled-components";
import SellCategory from "../Slider";

const VideoTutorialStyled = styled.div`
  padding: 3rem 5rem;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  .videoTut__heading {
    font-weight: 700;
    font-size: 1.75rem;
    margin: 0 0 15px 0;
    text-align: center;
  }
  .videoTut__content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    flex-wrap: wrap;
    .videoTut__iframe {
      animation: skeleton-loading 1s linear infinite alternate;
      flex: 1;
      border-radius: 8px;
      padding: 0;
    }
  }
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  @media (min-width: 1400px) {
    .videoTut__content {
      max-width: 1320px;
    }
  }
  @media (max-width: 670px) {
    padding: 24px;
    gap: 16px;
  }

  @media (max-width: 992px) {
    .videoTut__content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 576px) {
    .videoTut__content {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const yTLink = [
  {
    link: "https://www.youtube.com/embed/yL_S4S_3VIs",
    title: "YouTube video player",
  },
  {
    link: "https://www.youtube.com/embed/r0EQTVmqn6k",
    title: "YouTube video player",
  },
  {
    link: "https://www.youtube.com/embed/SpI3ZOmeKMQ",
    title: "YouTube video player",
  },
  {
    link: "https://www.youtube.com/embed/GOFDSIg89G8",
    title: "YouTube video player",
  },
];

function VideoTutorial() {
  return (
    <VideoTutorialStyled>
      <h3 className="videoTut__heading">Video Tutorial</h3>
      <div className="videoTut__content">
        {yTLink?.map(({ link, title }, i) => (
          <iframe
            key={`tut-${i}`}
            width="100%"
            height="200"
            className="videoTut__iframe"
            src={link}
            title={title}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        ))}
      </div>
    </VideoTutorialStyled>
  );
}

export default VideoTutorial;
