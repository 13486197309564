import styled from "styled-components";

const StyledHeroSection = styled.div`
  height: calc(100vh - 96px);
  overflow: hidden;
  margin-top: 96px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  .parallax {
    background-attachment: fixed;
  }
  .hero__bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    .hero__bg-overlay {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.9;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 30%,
        rgba(255, 255, 255, 0.5) 70%
      );
    }
  }
  .hero__section {
    width: 100%;
    height: 100%;
    position: relative;
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .hero__img {
      position: absolute;
      right: 80px;
      bottom: 0;
      scale: 1;
      width: fit-content;
      max-height: 100%;
      transform: scale(1.5) translate(-10%, -5%);
    }

    .hero__section--details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: left;
      max-width: 83%;
      gap: 0px;
      .hero_heading {
        width: 100%;
        font-weight: 700;
        line-height: 160%;
        font-size: 45px;
        .hero__heading--highlight {
          color: var(--primary-color);
          font-size: 80px;
        }
        margin: 0;
      }
      .hero__desc {
        width: 100%;
        font-size: 22px;
        font-weight: 400;
        text-align: left;
        margin: 0;
      }
    }
  }
  .hero__button {
    margin-right: auto;
    margin-top: 30px;
    height: 45px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    letter-spacing: 1px;
    border-radius: 6px;
  }
  @media (max-width: 1600px) {
    .hero__section {
      .hero__img {
        transform: scale(1.5) translate(-5%, -5%);
        max-height: 100%;
      }
    }
  }
  @media (max-width: 1400px) {
    .hero__section {
      .hero__section--details {
        max-width: 83%;
        .hero_heading {
          font-size: 32px;
          .hero__heading--highlight {
            color: var(--primary-color);
            font-size: 60px;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .hero__section {
      .hero__img {
        transform: scale(1.25) translate(0%, 0%);
        max-height: 100%;
      }
      .hero__section--details {
        max-width: 100%;
        padding-left: 40px;
        .hero_heading {
          font-size: 32px;
          .hero__heading--highlight {
            color: var(--primary-color);
            font-size: 40px;
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    .hero__section {
      .hero__img {
        transform: scale(1.35) translate(5%, -10%);
      }
      .hero__section--details {
        .hero_heading {
          font-size: 32px;
          .hero__heading--highlight {
            color: var(--primary-color);
            font-size: 40px;
          }
        }
        .hero__desc {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: 950px) {
    flex-direction: column;
    justify-content: center;
    .hero__section--bottomdiv {
      display: none;
    }
    .hero__section--topdiv {
      order: 2;
      align-items: center;
      justify-content: center;
      .hero__section--details {
        gap: 16px;
        padding: 0 23px;
        .hero_heading {
          text-align: center;
          font-size: 24px;
          .hero__heading--highlight {
            color: var(--primary-color);
            font-size: 40px;
          }
        }
        .hero__desc {
          text-align: center;
          font-size: 18px;
        }
        .hero__button {
          margin: auto;
        }
      }
    }
    .hero__section--bottomdiv {
      order: 1;
    }
  }
`;

export { StyledHeroSection };
