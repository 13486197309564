import HttpApiService from "./http-api-service";

class SellerApiService extends HttpApiService {
  constructor(token) {
    super(token);
  }

  async getRTOOrders() {
    return this.get("orders/failed-delivery");
  }

  async getAllProducts() {
    return this.get("inventory/all");
  }

  async getFilteredProducts(filter) {
    return this.get(`inventory/filtered?filter=${filter}`);
  }

  async getProductStats() {
    return this.get("inventory/stats");
  }

  async getAllProductsWithRatings() {
    return this.get("reviews/products");
  }

  async getProductWithRatings(id) {
    return this.get(`reviews/product/${id}`);
  }

  async getProduct(id) {
    return this.get(`inventory/view/${id}`);
  }

  async getProductImages(id) {
    return this.get(`inventory/preview/${id}`);
  }

  async deleteProductImages(productId, imageId) {
    return this.delete(`inventory/${productId}/preview`, imageId);
  }

  async updateProduct(product) {
    return this.update(
      `products/${JSON.parse(product.id)}/`,
      JSON.stringify(product),
      {
        headers: {
          // 'application/json' is the modern content-type for JSON, but some
          // older servers may use 'text/json'.
          // See: http://bit.ly/text-json
          "content-type": "application/json",
        },
      }
    );
  }

  async getOnHoldOrders() {
    return this.get(`orders/on-hold`);
  }
  async getConfirmedOrders() {
    return this.get("orders/confirmed");
  }
  async getShippedOrders() {
    return this.get(`orders/shipped`);
  }
  async getReturnedOrders() {
    return this.get("orders/returned");
  }
  async getCancelledOrders() {
    return this.get("orders/cancelled");
  }

  async getDeliveredOrders() {
    return this.get(`orders/delivered`);
  }

  async confirmOrder(id) {
    return this.post(`order/confirm/${id}`);
  }
  async cancelOrder(id) {
    return this.post(`order/cancel/${id}`);
  }
  async deliverOrder(id) {
    return this.post(`order/deliver/${id}`);
  }
  async shipOrder(id) {
    return this.post(`order/ship/${id}`);
  }

  async getOrder(orderId) {
    return this.get(`orders/get/${orderId}`);
  }

  async getLatestNotice() {
    return this.get(`notices/latest`);
  }

  async getAllCategories() {
    return this.get(`categories/all`);
  }

  async getCategory(id) {
    let _id = id;
    if (!_id) {
      let selectedCategory = window.localStorage.getItem("selectedCategory");
      try {
        if (typeof selectedCategory === "string" && selectedCategory.length) {
          selectedCategory = JSON.parse(selectedCategory);
          _id = selectedCategory.id;
        }
      } catch (err) {
        _id = null;
      }
    }
    return this.get(`categories/get/${_id}`);
  }

  async getAllCategoriesCount() {
    return this.get(`categories/count`);
  }

  async verifyGst(gst) {
    return this.post(`verify/gst`, gst);
  }

  async verifyBankAccount(data) {
    return this.post(`verify/bank-account`, data);
  }

  async getUserProfile() {
    return this.get(`auth/profile`);
  }

  async getShipmentLable(id) {
    return this.get(`shipment/label/${id}/`);
  }

  async updateFundAccount(data) {
    return this.update(`payment/fund/`, JSON.stringify(data), {
      headers: {
        "content-type": "application/json",
      },
    });
  }

  async sendOtp(data) {
    return this.post("auth/send-otp", JSON.stringify(data), {
      headers: {
        "content-type": "application/json",
      },
    });
  }

  async updateMobile(data) {
    return this.post("auth/update-mobile", JSON.stringify(data), {
      headers: {
        "content-type": "application/json",
      },
    });
  }

  async getOrderCount() {
    return this.get("order/stats");
  }

  async acceptBulkOrder(payload) {
    return this.post("order/bulk-confirm", payload);
  }
}

export default SellerApiService;
