import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import Banner from "../../../../assets/Images/v2/footer_banner.png";

const StyledSection = styled.div`
  .banner {
    width: 100%;
    height: auto;
  }
`;

const StyleHeading = styled.h1`
  font-size: 27px;
  font-weight: 700;
  text-align: center;
  margin: 30px 0;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

const FooterBanner = () => {
  return (
    <>
      <Container>
        <StyleHeading>Proudly make in india platform</StyleHeading>
      </Container>
      <StyledSection>
        <img src={Banner} alt="" className="banner" />
      </StyledSection>
    </>
  );
};

export default FooterBanner;
