import { createSlice } from "@reduxjs/toolkit";

const initVal = {
  campaignCount: {
    all: 0,
    live: 0,
    schedule: 0,
    paused: 0,
    complete: 0,
  },
  campaignList: [],
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState: initVal,
  reducers: {
    updateCampaign: (state, action) => {
      const campaigns = action.payload;
      // state.campaignCount.all = campaigns.length;
      state.campaignCount = { ...initVal.campaignCount, all: campaigns.length };
      for (const data of campaigns) {
        if (data.status === "active" || data.status === "restart") {
          state.campaignCount.live += 1;
        } else if (data.status === "schedule") {
          state.campaignCount.schedule += 1;
        } else if (data.status === "pause") {
          state.campaignCount.paused += 1;
        } else if (data.status === "complete") {
          state.campaignCount.complete += 1;
        }
      }
      state.campaignList = campaigns;
      // return action.payload;
    },
  },
});

export default campaignSlice;
export const { updateCampaign } = campaignSlice.actions;

export const allCampaign = (state) => {
  return state.campaign.campaignList;
};

export const liveCampaign = (state) => {
  const data = state.campaign.campaignList?.filter(
    (data) => data?.status === "active" || data?.status === "restart"
  );
  return data;
};
export const scheduleCampaign = (state) => {
  const data = state.campaign.campaignList?.filter(
    (data) => data?.status === "schedule"
  );
  return data;
};
export const pausedCampaign = (state) => {
  const data = state.campaign.campaignList?.filter(
    (data) => data?.status === "pause"
  );
  return data;
};
export const completeCampaign = (state) => {
  const data = state.campaign.campaignList?.filter(
    (data) => data?.status === "complete"
  );
  return data;
};
export const getCount = (state) => {
  const data = state.campaign?.campaignCount;
  return data;
};
