// eslint-disable-next-line
import React, { useEffect, useState, useReducer, useCallback } from "react";
import { Nav, Tab } from "react-bootstrap";
import Paper from "../../compoents/Paper";
import Info from "./info";
import Description from "./description";
import Variation from "./variation";
import Images from "./images";
import Offer from "./offer";
import {
  createProductThunk,
  createProductSelector,
} from "../../redux/slices/product";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { TOAST_STYLE } from "../../config";
import { toast } from "react-toastify";
import {
  dashboardCateSelector,
  setSelectedCategory,
} from "../../redux/slices/category";
import moment from "moment";

const AddProduct = () => {
  const [finalData, setFinalData] = useState(null);
  const [tabActiveKey, setTabActiveKey] = useState("info");
  const [tabsDisabled, setTabsDisabled] = useState(false);

  let dispatch = useDispatch();
  const createProductState = useSelector(createProductThunk);

  let {
    // createProductLoading,
    createProductSuccess,
    createProductFailure,
    createProductError,
    createProductMessage,
    createProductData,
  } = useSelector(createProductSelector);
  const { selectedCategory } = useSelector(dashboardCateSelector);

  let auth = useAuth();
  // console.log("finalData", finalData);
  useEffect(() => {
    if (createProductFailure) {
      toast.error(
        createProductError ? createProductError : "Error While adding product",
        TOAST_STYLE
      );
    }
  }, [createProductSuccess, createProductFailure]);

  useEffect(() => {
    if (createProductData?.id) {
      setTabsDisabled(false);
    } else {
      setTabsDisabled(true);
    }
  }, [createProductData]);

  const addProductHandler = (data) => {
    console.log("==============", data);
    var formdata = new FormData();
    formdata.append("name", data?.item_name);
    formdata.append("description", data?.description);
    // formdata.append("brand", data?.brand_name);
    if (data?.brand) {
      formdata.append("brand", data?.brand);
    }
    formdata.append("is_branded", data?.is_branded ?? false);
    formdata.append("features", data?.features);
    formdata.append("legal", data?.legal);
    formdata.append("country", data?.country);
    formdata.append("stock", data?.offer_quantity);
    formdata.append("gst", data?.gst);
    formdata.append("weight", data?.weight);
    formdata.append("dimensions", data?.dimensions);
    formdata.append("group_price", data?.group_buy_price);
    formdata.append("selling_price", data?.your_price);
    formdata.append("mrp", data?.retail_price);
    formdata.append("category", selectedCategory?.id);
    formdata.append("price", data?.your_price);
    formdata.append("sku", data?.seller_sku);
    formdata.append("hsn", data?.hsn);
    formdata.append("specifications", JSON.stringify(data?.specifications));
    formdata.append("options", "[]");
    formdata.append("mfg", data?.mfg);
    formdata.append("importer", data?.importer);
    formdata.append("packer_details", data?.packer_details);
    formdata.append("fssai_no", data?.fssai_no);

    formdata.append("options_list", data?.options_list);
    formdata.append(
      "expiry_details",
      data?.expiry_details
        ? moment(data?.expiry_details).format("YYYY-MM-DD HH:mm")
        : ""
    );
    formdata.append("variantions", data?.variations);
    let finalPayload = {
      header: auth,
      addProductFormData: formdata,
    };
    dispatch(createProductThunk(finalPayload));
  };

  const checkCategory = useCallback(() => {
    if (!Object.keys(selectedCategory).length) {
      let selectedCategory = window.localStorage.getItem("selectedCategory");
      try {
        if (typeof selectedCategory === "string" && selectedCategory.length) {
          selectedCategory = JSON.parse(selectedCategory);
        }
      } catch (err) {
        selectedCategory = {};
      }

      dispatch(setSelectedCategory(selectedCategory));
    }
  }, []);

  useEffect(() => {
    checkCategory();
  }, []);

  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="info"
        activeKey={tabActiveKey}
        onSelect={(key) => setTabActiveKey(key)}
      >
        <Paper className="p-0" style={{ padding: "0px" }}>
          <Nav
            variant="pills"
            className="fund-raise-generic-tabs-nav flex-row text-center button-text flex-nowrap px-5 py-3 overflow-auto"
          >
            <Nav.Item>
              <Nav.Link
                eventKey="info"
                className="cursor-pointer text-grey-light"
              >
                <h5>Info</h5>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="offer"
                className="cursor-pointer text-grey-light"
              >
                <h5>Offer</h5>
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link
                eventKey="description"
                className="cursor-pointer text-grey-light"
              >
                <h5>Description</h5>
              </Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
              <Nav.Link
                eventKey="variations"
                // disabled={tabsDisabled}
                // createProductData
                className="cursor-pointer text-grey-light"
              >
                <h5>Variations</h5>
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link
                eventKey="images"
                className="cursor-pointer text-grey-light"
              >
                <h5>Images</h5>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          <Tab.Content className="mt-2 border-top">
            <Tab.Pane eventKey="info">
              <Info
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                finalData={finalData}
                setTabActiveKey={setTabActiveKey}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="variations">
              <Variation
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                finalData={finalData}
                setTabActiveKey={setTabActiveKey}
                addProductHandler={addProductHandler}
              />
            </Tab.Pane>
            {/*  */}
            <Tab.Pane eventKey="offer">
              <Offer
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                setTabActiveKey={setTabActiveKey}
              />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="description">
              <Description
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                finalData={finalData}
                addProductHandler={addProductHandler}
                setTabActiveKey={setTabActiveKey}
              />
            </Tab.Pane> */}
            <Tab.Pane eventKey="images">
              <Images
                tabactivekey={tabActiveKey}
                setFinalData={setFinalData}
                finalData={finalData}
                addProductHandler={addProductHandler}
                setTabActiveKey={setTabActiveKey}
              />
              {/* <SegmentsTab /> */}
            </Tab.Pane>
          </Tab.Content>
        </Paper>
      </Tab.Container>
    </div>
  );
};

export default AddProduct;
