import React from "react";
import StyledBox from "../../compoents/Box";
import { GenericButton } from "../../compoents/Button";
import { useHistory } from "react-router-dom";

function PaymentView(props) {
  const history = useHistory();
  const {
    InsideStyledBoxProps,
    description = "",
    heading = "",
    route: routeParam,
    details = {},
  } = props;
  const { amount = 0, penality = 0, waiver = 0, net_amount = 0 } = details;
  return (
    <StyledBox
      {...InsideStyledBoxProps}
      className="p-3 h-100 d-flex justify-content-around flex-column"
    >
      <div>
        <h5>{heading}</h5>
        <p className="text-grey-light">{description}</p>
      </div>
      <div>
        <div className="d-flex justify-content-between align-items-center my-2">
          <h6>Amount</h6>
          <span>Rs. {amount}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center my-2">
          <h6>Penalties</h6>
          <span>Rs. {penality}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center my-2">
          <h6>Waivers and Compensation</h6>
          <span>Rs. {waiver}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center my-2">
          <h6>Net Amount</h6>
          <span>Rs. {net_amount}</span>
        </div>
      </div>{" "}
      <div className="d-flex justify-content-end mt-2">
        <GenericButton
          onClick={() => history.push(`/view-more?page=${routeParam}`)}
          variant="primary"
        >
          View Details
        </GenericButton>
      </div>
    </StyledBox>
  );
}

export default PaymentView;
