import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import {
  addProductSelector,
  setProductOffer,
} from "../../redux/slices/product";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FormikForm } from "formik";
import { OutlinedTextFields } from "../../compoents/Forms2";
import * as Yup from "yup";
import { GENERIC_MESSAGE } from "../../config";
import ErrorMessage from "../../compoents/ErrorMessage";
import { GenericButton } from "../../compoents/Button";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { ProductAddContext } from "./addProduct"

// import image
import boxImage from "../../assets/Images/deliveryInfo.png";

const boxImg = {
  width: "280px",
  position: "absolute",
  right: "100px",
  top: "20px",
};

const OfferDataSchema = Yup.object().shape({
  // seller_sku: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  gst: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  hsn: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  weight: Yup.string()
    .trim()
    .matches(/^[0-9]\d*(\.\d+)?$/, "Weight should be only in number.")
    .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  dimensions: Yup.string()
    .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE)
    .matches(
      /^[0-9]+[*,x,X][0-9]+[*,x,X][0-9]+$/,
      "Dimension should be in LxBxH or LXBXH or L*B*H."
    ),
  // your_price: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  // retail_price: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  // group_buy_price: Yup.string().required(
  //   GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE
  // ),
  // offer_quantity: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  mfg: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  importer: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  packer_details: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  fssai_no: Yup.string(),
  // expiry_details: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
});

const Offer = (props) => {
  const [expiry_details, setExpiryDetails] = useState();
  let { finalData, setFinalData, setTabActiveKey } = props;
  // let { setTabActiveKey } = props;
  //
  const dispatch = useDispatch();
  //
  // const { finalData,setFinalData } = React.useContext(ProductAddContext)
  //
  // const { product_offer } = useSelector(addProductSelector);
  //
  const [tax, setTax] = React.useState([
    { value: 0, label: "0%" },
    { value: 3, label: "3%" },
    { value: 5, label: "5%" },
    { value: 12, label: "12%" },
    { value: 18, label: "18%" },
    { value: 28, label: "28%" },
  ]);
  useEffect(() => {
    // console.log("Offer => finalData", finalData);
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          // seller_sku: "",
          gst: "",
          hsn: "",
          weight: "",
          dimensions: "",
          // your_price: "",
          // retail_price: "",
          // group_buy_price: "",
          mfg: "",
          importer: "",
          packer_details: "",
          fssai_no: "",
        }}
        validationSchema={OfferDataSchema}
        onSubmit={(values) => {
          // console.log("onSubmit => values", values, expiry_details);
          setFinalData((prev) => ({ ...prev, ...values, expiry_details }));
          // setFinalData({ type:"ADD_PRODUCT",payload:values });
          // console.log("Offer => finalData", finalData);
          setTabActiveKey("variations");
          // dispatch(setProductOffer(values));
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <FormikForm>
            <div className="px-0 px-md-5 py-3">
              <h4 className="border-bottom pb-2">Tax Information</h4>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      HSN Code <span className="text-danger">*</span>
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="hsn"
                      type="text"
                      placeholder="HSN Code"
                      onChange={(e) => setFieldValue("hsn", e.target.value)}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                      hasError={touched.hsn && errors.hsn}
                    />
                    {touched.hsn && errors.hsn && (
                      <ErrorMessage message={errors.hsn} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Tax (%) <span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                      options={tax}
                      placeholder="Search tax"
                      onChange={(selected) => {
                        setFieldValue("gst", selected.value);
                      }}
                      className={`my-2 ${
                        touched.gst && errors.gst ? "error_input_border" : ""
                      }`}
                    />
                    {touched.gst && errors.gst && (
                      <ErrorMessage message={errors.gst} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <h4 className="border-bottom pb-2">Delivery Information</h4>
              <Row className="mb-3 position-relative">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Weight (in grams) <span className="text-danger">*</span>
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="weight"
                      type="text"
                      placeholder="Weight"
                      onChange={(e) => setFieldValue("weight", e.target.value)}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                      hasError={touched.weight && errors.weight}
                    />
                    {touched.weight && errors.weight && (
                      <ErrorMessage message={errors.weight} />
                    )}
                  </Form.Group>
                </Col>
                <img
                  src={boxImage}
                  className="d-none d-lg-block"
                  alt="boxImage"
                  style={boxImg}
                />
              </Row>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Dimensions (in cm) <span className="text-danger">*</span>
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="dimensions"
                      type="text"
                      placeholder="Dimensions: Length x Width x Height"
                      onChange={(e) =>
                        setFieldValue("dimensions", e.target.value)
                      }
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                      hasError={touched.dimensions && errors.dimensions}
                    />
                    {touched.dimensions && errors.dimensions && (
                      <ErrorMessage message={errors.dimensions} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <h4 className="border-bottom pb-2">Packaging Information</h4>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Manufacture Name & Address{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="mfg"
                      type="text"
                      placeholder="Manufacturer"
                      onChange={(e) => setFieldValue("mfg", e.target.value)}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                      hasError={touched.mfg && errors.mfg}
                    />
                    {touched.mfg && errors.mfg && (
                      <ErrorMessage message={errors.mfg} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Importer Name & Address{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="importer"
                      type="text"
                      placeholder="Importer"
                      onChange={(e) =>
                        setFieldValue("importer", e.target.value)
                      }
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                      hasError={touched.importer && errors.importer}
                    />
                    {touched.importer && errors.importer && (
                      <ErrorMessage message={errors.importer} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Packer Name & Address{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="packer_details"
                      type="text"
                      placeholder="Packer Details"
                      onChange={(e) =>
                        setFieldValue("packer_details", e.target.value)
                      }
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                      hasError={touched.packer_details && errors.packer_details}
                    />
                    {touched.packer_details && errors.packer_details && (
                      <ErrorMessage message={errors.packer_details} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Fssai no. (Compulsory for food products)
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="fssai_no"
                      type="text"
                      placeholder="Fssai no."
                      onChange={(e) =>
                        setFieldValue("fssai_no", e.target.value)
                      }
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                      hasError={touched.fssai_no && errors.fssai_no}
                    />
                    {touched.fssai_no && errors.fssai_no && (
                      <ErrorMessage message={errors.fssai_no} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Expiry Date
                    </Form.Label>
                    {/* <OutlinedTextFields
                      className="my-2"
                      name="fssai_no"
                      type="text"
                      placeholder="Fssai no."
                      // onChange={(e) => setFieldValue("weight", e.target.value)}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                    /> */}
                    <DatePicker
                      selected={expiry_details}
                      className="my-2 form-control"
                      onChange={(date) => {
                        setExpiryDetails(date);
                      }}
                      minDate={new Date()}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                    {touched.expiry_details && errors.expiry_details && (
                      <ErrorMessage message={errors.expiry_details} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {/* <h4 className="border-bottom pb-2">Offer Information</h4> */}
              {/* <Row className="mb-3">
                <Col md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Seller SKU
                    </Form.Label>
                    <OutlinedTextFields
                      className="my-2"
                      name="seller_sku"
                      type="text"
                      placeholder="Seller SKU"
                      onChange={(e) =>
                        setFieldValue("seller_sku", e.target.value)
                      }
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                    />
                    {touched.seller_sku && errors.seller_sku && (
                      <ErrorMessage message={errors.seller_sku} />
                    )}
                  </Form.Group>
                </Col>
              </Row> */}
              {/* <Row className="mb-3">
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Single Product Price
                    </Form.Label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">RS</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        id="inlineFormInputGroup"
                        name="your_price"
                        onChange={(e) =>
                          setFieldValue("your_price", e.target.value)
                        }
                        // placeholder="Username"
                      />
                    </div>
                    {touched.your_price && errors.your_price && (
                      <ErrorMessage message={errors.your_price} />
                    )}
                    {/* <Form.Control type="text" placeholder="Item Name" /> /}
                  </Form.Group>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Maximum Retail Price
                    </Form.Label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">RS</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        id="inlineFormInputGroup"
                        name="retail_price"
                        onChange={(e) =>
                          setFieldValue("retail_price", e.target.value)
                        }
                        // placeholder="Username"
                      />
                    </div>
                    {touched.retail_price && errors.retail_price && (
                      <ErrorMessage message={errors.retail_price} />
                    )}
                    {/* <Form.Control type="text" placeholder="Item Name" /> /}
                  </Form.Group>
                </Col>
                <Col xl={3} lg={4} md={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Group buy Price
                    </Form.Label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">RS</div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        id="inlineFormInputGroup"
                        name="group_buy_price"
                        onChange={(e) =>
                          setFieldValue("group_buy_price", e.target.value)
                        }
                        // placeholder="Username"
                      />
                    </div>
                    {touched.group_buy_price && errors.group_buy_price && (
                      <ErrorMessage message={errors.group_buy_price} />
                    )}
                    {/* <Form.Control type="text" placeholder="Item Name" /> /}
                  </Form.Group>
                </Col>
              </Row> */}

              {/* <Row className="mb-3">
                <Col lg={6} xs={12}>
                  <Form.Group as={Col} controlId="formGridItemName">
                    <Form.Label className="" style={{ color: "#122736" }}>
                      Quantity
                    </Form.Label>
                    {/* <Form.Control
											type="text"
											placeholder="Quantity"
											name="offer_quantity"
										/> /}
                    <OutlinedTextFields
                      // className="my-2"
                      name="offer_quantity"
                      type="text"
                      placeholder="Offer Quantity"
                      onChange={(e) =>
                        setFieldValue("offer_quantity", e.target.value)
                      }
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.firstName}
                    />
                    {touched.offer_quantity && errors.offer_quantity && (
                      <ErrorMessage message={errors.offer_quantity} />
                    )}
                  </Form.Group>
                </Col>
              </Row> */}
              <div className="mt-5">
                <GenericButton type="submit">Save and Continue</GenericButton>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default Offer;
