import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { IMAGES, PATH } from "../../config";
import { GenericButton } from "../../compoents/Button";

const StyledAuthHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;

  .nav-link.active {
    color: var(--orange-primary) !important;
  }
`;

const AuthHeader = ({ activePage }) => {
  return (
    <StyledAuthHeader className="container-fluid fixed-top">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand>
            <Link to={PATH.LANDING_PAGE}>
              <img
                style={{ width: "75px" }}
                src={IMAGES.COMPANY_LOGO}
                alt="fund-raiser-logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <div className="links d-flex align-items-lg-center flex-column flex-lg-row gap-3">
                <Link
                  className={`nav-link ${
                    activePage === "landing" ? "active" : ""
                  } `}
                  to={PATH.LANDING_PAGE}
                >
                  Home
                </Link>
                <Link
                  className={`nav-link ${
                    activePage === "pricing & charges" ? "active" : ""
                  } `}
                  to={PATH.PRICING}
                >
                  Pricing & Charges
                </Link>
                <Link
                  className={`nav-link ${
                    activePage === "delivery" ? "active" : ""
                  } `}
                  to={PATH.DELIVERY}
                >
                  Delivery
                </Link>
                <Link
                  className={`nav-link ${
                    activePage === "contact" ? "active" : ""
                  } `}
                  to={PATH.CONTACT_US}
                >
                  Contact Us
                </Link>
              </div>
            </Nav>
            <NavLink
              to={PATH.REGISTER}
              className={
                "py-2 pe-3 border-end me-4 mt-3 mt-lg-0 d-block d-lg-inline"
              }
              style={{ color: "var(--orange-primary)", textDecoration: "none" }}
            >
              Create Account
            </NavLink>
            <NavLink to={PATH.LOGIN}>
              <GenericButton
                variant="primary"
                className={"py-2  px-5 mt-3 mt-lg-0"}
              >
                Login
              </GenericButton>
            </NavLink>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </StyledAuthHeader>
  );
};

export default AuthHeader;
