import React, { useCallback, useEffect, useState } from "react";
import { GrTransaction } from "react-icons/gr";
import styled from "styled-components";
import { toast } from "react-toastify";
import accountService from "../../../redux/services/account.service";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  registrationFeeAmountSelector,
  allowedForProductListingSelector,
  registrationOriginalFeeAmountSelector,
  updateLoggedIn,
} from "../../../redux/slices/account";
import MemberPlanList from "./MemberPlanList";
import { APP_SETTINGS } from "../../../config";
import { loadRazorpay } from "../../../utilities";

const StyledRegisterationFee = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .registerationFee__main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-item: center;
    padding: 32px;
    height: 100%;
    button {
      margin: 0 auto;
    }
  }
`;

function RegistrationFee() {
  const dispatch = useDispatch();
  const isListingAllowed = useSelector(allowedForProductListingSelector);
  const registrationFeeAmount = useSelector(registrationFeeAmountSelector);
  const originalRegistrationFee = useSelector(
    registrationOriginalFeeAmountSelector
  );
  const [payData, setPayData] = useState({});
  const [paymentStarted, setPaymentStarted] = useState(false);

  const razorpaySdk = APP_SETTINGS.API_PATH.razorpaySdk;
  const auth = useAuth();

  const fetchOrderId = async (callback) => {
    try {
      const res = await accountService.create_fee_order(auth);
      const { error, data } = res;
      if (error) {
        toast.error("Error while initiating payment.");
        return;
      }
      callback(data?.data ?? {});
      setPaymentStarted(false);
    } catch (error) {
      setPaymentStarted(false);
      toast.error("Error while initiating payment.");
    }
  };
  const handlePaymentSuccess = async (response) => {
    try {
      console.warn("handlePaymentSuccess:", response);
      const res = await accountService.verify_register_fee(response, auth);
      console.warn("res:", res);
      const { data = "" } = res;
      if (data?.success) {
        toast.success(data?.success);
        dispatch(updateLoggedIn({ isListingAllowed: true }));
        return;
      }
      toast.error("Payment failed.");
    } catch (error) {
      toast.error("Payment failed.");
    }
  };
  const handlePayment = async (data) => {
    const handler = (response) => {
      console.warn("razorpay_handler:", response);
      handlePaymentSuccess(response);
    };
    const theme = {
      color: "#ff4d00",
    };
    data.handler = handler;
    data.theme = theme;
    // const { amount = 0, order_id = null } = data;
    // if (!order_id) {
    //   toast.error("Error while initiating payment.");
    //   return;
    // }
    // const options = {
    //   key: "", // Enter the Key ID generated from the Dashboard
    //   amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    //   currency: "INR",
    //   name: "Groupick", //your business name
    //   description: "Registration Fee",
    //   order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    //   handler: function (response) {
    //     console.warn("razorpay_handler:", response);
    //     handlePaymentSuccess(response);
    //   },
    //   theme: {
    //     color: "#ff4d00",
    //   },
    // };
    const rzpy = new window.Razorpay(data);
    rzpy.on("payment.failed", async function (response) {
      console.warn("razorpay failed:", response);
      await accountService.payment_failed(response, auth);
      toast.error("Payment failed.");
    });
    rzpy?.open();
    setPaymentStarted(false);
  };

  const checkout = async () => {
    setPaymentStarted(true);
    if (!window.hasOwnProperty("Razorpay")) {
      await loadRazorpay(razorpaySdk);
    }
    await fetchOrderId((data) => handlePayment(data));
  };

  const fetchPaymentDetail = useCallback(async () => {
    try {
      const res = await accountService.payment_detail(auth);
      const { data } = res;
      if (data?.success) {
        setPayData(data?.data ?? {});
        return;
      }
      setPayData({});
    } catch (error) {
      setPayData({});
    }
  }, [auth]);

  useEffect(() => {
    if (isListingAllowed) {
      fetchPaymentDetail();
    }
  }, [isListingAllowed, fetchPaymentDetail]);

  if (!isListingAllowed && !registrationFeeAmount) {
    return null;
  }

  return (
    <StyledRegisterationFee>
      <div className="d-flex align-items-center border-botton-grey pb-2">
        <GrTransaction size={25} className="me-3" />
        <h4>Membership</h4>
      </div>
      <div className="registerationFee__main">
        <MemberPlanList
          paymentStarted={paymentStarted}
          onClickHandler={checkout}
          showPayButton={!isListingAllowed}
          showTag={isListingAllowed}
          amount={payData?.amount ?? registrationFeeAmount}
          origin_price={payData?.origin_price ?? originalRegistrationFee}
        />
        {/* <>
          <div className="registerationFee__amount">
            <span className="fw-bold">
              {isListingAllowed ? "Amount Paid:" : "Amount Due:"}
            </span>{" "}
            {`Rs.${payData?.amount ?? registrationFeeAmount ?? 0}/-`}
            {isListingAllowed && (
              <>
                <div>
                  <span className="fw-bold">Date:</span>{" "}
                  {payData?.date?.split("T")[0]}
                </div>
              </>
            )}
          </div>
          {!isListingAllowed && (
            <GenericButton onClick={checkout} disabled={paymentStarted}>
              {!paymentStarted ? (
                "Activate now"
              ) : (
                <>
                  <Loader />
                  <Loader />
                  <Loader />
                </>
              )}
            </GenericButton>
          )}
        </> */}
      </div>
    </StyledRegisterationFee>
  );
}

export default RegistrationFee;
