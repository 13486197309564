import React from "react";
import StyledBox from "../../compoents/Box";
import styled from "styled-components";
import { AiFillStar } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import GenericProgressBar from "../../compoents/ProgressBar";
import { GenericButton } from "../../compoents/Button";
import GenericTable from "../../compoents/Table";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

const ImageContainer = styled.div`
  max-width: 200px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
`;

const SingleProductView = ({ product }) => {
  const [showAllReviews, setShowAllReviews] = React.useState(false);
  const getRatingAvg = (rating, totalRating) => {
    return (rating * 100) / totalRating;
  };
  return (
    <div>
      <StyledBox className="p-3 position-relative">
        <h4>Product Detail</h4>
        <div className="row mt-4 g-5 p-3">
          <div className="col-md-5 p-0 pe-3 ">
            <div className="d-flex justify-content-around">
              <ImageContainer className="p-3">
                <img className="h-100 w-100" src={product?.image} alt="" />
              </ImageContainer>
              <div className="ms-3">
                <h4>{product?.name} </h4>
                <span className="font-bold mt-2">₹ {product?.mrp}</span>
              </div>
            </div>

            <div className="my-4 ms-3">
              <h4>Description</h4>
              <p className="text-grey-light">{product?.description}</p>
            </div>
          </div>
          <div className="col-md-7 p-0 border-start border-3">
            <div className="container-fluid">
              <div className="row w-100 justify-content-between">
                <div className="col-12 border-bottom border-2 py-3">
                  <h3 className="text-center">Product Ratings & Reviews</h3>
                  <div className="row g-5 ">
                    <div className="col-md-12 col-xl-8">
                      <div>
                        <div className="my-2 d-flex flex-column flex-sm-row align-items-sm-center">
                          <span className="font-bold w-120">Excellent</span>
                          <div className="d-flex align-items-center flex-grow-1">
                            <GenericProgressBar
                              className={"w-100 mx-sm-4 me-3"}
                              variant="excellent"
                              now={getRatingAvg(
                                product?.five_rating ?? 0,
                                product?.total_ratings ?? 0
                              )}
                            />
                            <span className="text-grey-light">
                              {product?.five_rating}
                            </span>
                          </div>
                        </div>
                        <div className="my-2 d-flex align-items-center">
                          <span className="font-bold w-120">Very Good</span>
                          <div className="d-flex align-items-center flex-grow-1">
                            <GenericProgressBar
                              className={"w-100 mx-sm-4 me-3"}
                              variant="very-good"
                              now={getRatingAvg(
                                product?.four_rating ?? 0,
                                product?.total_ratings ?? 0
                              )}
                            />
                            <span className="text-grey-light">
                              {product?.four_rating}
                            </span>
                          </div>
                        </div>
                        <div className="my-2 d-flex align-items-center">
                          <span className="font-bold w-120">Good</span>
                          <div className="d-flex align-items-center flex-grow-1">
                            <GenericProgressBar
                              className={"w-100 mx-sm-4 me-3"}
                              variant="good"
                              now={getRatingAvg(
                                product?.three_rating ?? 0,
                                product?.total_ratings ?? 0
                              )}
                            />
                            <span className="text-grey-light">
                              {product?.three_rating}
                            </span>
                          </div>
                        </div>

                        <div className="my-2 d-flex align-items-center">
                          <span className="font-bold w-120">Average</span>
                          <div className="d-flex align-items-center flex-grow-1">
                            <GenericProgressBar
                              className={"w-100 mx-sm-4 me-3"}
                              variant="average"
                              now={getRatingAvg(
                                product?.two_rating ?? 0,
                                product?.total_ratings ?? 0
                              )}
                            />
                            <span className="text-grey-light">
                              {product?.two_rating}
                            </span>
                          </div>
                        </div>
                        <div className="my-2 d-flex align-items-center">
                          <span className="font-bold w-120">Poor</span>
                          <div className="d-flex align-items-center flex-grow-1">
                            <GenericProgressBar
                              className={"w-100 mx-sm-4 me-3"}
                              variant="poor"
                              now={getRatingAvg(
                                product?.one_rating ?? 0,
                                product?.total_ratings ?? 0
                              )}
                            />
                            <span className="text-grey-light">
                              {product?.one_rating}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-4">
                      <div className="h-100 d-flex justify-content-center flex-column">
                        <div className="d-flex align-items-center ">
                          <span
                            style={{ fontSize: "60px", lineHeight: "0px" }}
                            className="me-2"
                          >
                            {Number(product?.avg_ratings).toFixed(1)}
                          </span>
                          <AiFillStar size={50} color="yellow" />
                        </div>
                        <div className="mt-3">
                          <p className="text-grey-light m-0">
                            {product?.total_ratings} ratings
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <GenericButton
                  onClick={() => {
                    setShowAllReviews((prevState) => !prevState);
                  }}
                  className="my-3 rounded-3 "
                >
                  {showAllReviews ? "Hide" : "See"} Reviews
                </GenericButton>
              </div>
            </div>
          </div>
        </div>
        <AllRatingsModal
          show={showAllReviews}
          onHide={() => setShowAllReviews(false)}
          allReviews={product.all_reviews}
        />
      </StyledBox>
    </div>
  );
};

export default SingleProductView;

const AllRatingsModal = ({ allReviews, show, onHide }) => {
  const [showImage, setShowImage] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");

  const StyledModalBody = styled.main``;

  const columns = [
    // {
    //   dataField: "order_id",
    //   classes: "align-middle",
    //   text: "Order Id",
    //   headerStyle: () => {
    //     return {width: "15%"};
    //   },
    // },
    {
      dataField: "rating",
      classes: "align-middle",
      text: "Rating",
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "body",
      classes: "align-middle",
      text: "Review",
      headerStyle: () => {
        return { width: "45%" };
      },
    },
    {
      dataField: "image_one",
      classes: "align-middle",
      text: "Pictures",
      formatter: (cell, row) => {
        return (
          <div className="overflow-auto text-center">
            {row &&
              row.pictures &&
              row.pictures.map((pic) => (
                <span
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                    marginRight: "3px",
                    display: "inline-block",
                  }}
                  onClick={() => {
                    setSelectedImage(pic);
                    setShowImage(true);
                  }}
                >
                  <img
                    src={pic}
                    alt=""
                    className="w-100 h-100 d-inline-block"
                  />
                </span>
              ))}
          </div>
        );
      },
      headerStyle: () => {
        return { width: "30%" };
      },
    },
  ];

  // const allReviews = [
  //   {
  //     order_id: "#44322",
  //     rating: 4.5,
  //     review: "lorem ipsum dolor sit amet",
  //     pictures: [
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //     ],
  //   },
  //   {
  //     order_id: "#44322",
  //     rating: 4.5,
  //     review: "lorem ipsum dolor sit amet",
  //     pictures: [
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //       "https://picsum.photos/200",
  //     ],
  //   },
  // ];

  return (
    <>
      <Modal show={show} size="lg" onHide={onHide}>
        <ModalHeader className="py-2 border-0 d-flex justify-content-end ">
          <MdClose onClick={onHide} className="cursor-pointer " size={25} />
        </ModalHeader>
        <ModalBody>
          <StyledModalBody>
            <h4 className="text-center fw-normal">Reviews</h4>
            <div className="my-4"></div>

            <GenericTable
              columns={columns}
              keyField="order_id"
              data={allReviews}
            />
          </StyledModalBody>
        </ModalBody>
      </Modal>

      <Modal show={showImage} onHide={() => setShowImage(false)}>
        <img src={selectedImage} className="w-100 h-100" alt="" />
      </Modal>
    </>
  );
};
