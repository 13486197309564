import { useEffect } from "react";
import { useState } from "react";
import useDebounce from "./useDebounce";
import { useCallback } from "react";

function useSearch(search_list) {
  const debounce_delay = 300;
  const [searchText, setSearchText] = useState("");
  const [searching, setSearching] = useState(true);
  const tempText = useDebounce(searchText, debounce_delay);
  const [filterData, setFilterData] = useState([]);

  const filterSearch = useCallback((list, text) => {
    const filteredData = list.filter((data) => {
      return data.name.toLowerCase().includes(text.toLowerCase());
    });
    return filteredData;
  }, []);

  useEffect(() => {
    if (tempText.length > 2) {
      const filteredData = filterSearch(search_list, tempText);
      setSearching(false);
      setFilterData(filteredData);
    }
  }, [tempText, filterSearch, search_list]);

  return { searchText, setSearchText, searching, filterData, setSearching };
}

export default useSearch;
