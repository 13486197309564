import React, { useEffect, useState } from "react";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import Box from "../../compoents/Box";
import Paper from "../../compoents/Paper";
import RTOorder from "./RTOorder";
import Return from "./Return";
// import ReturnOrderFilter from "./ReturnOrderFilter";
import PageTitle from "../../compoents/PageTitle";
import SellerApiService from "../../redux/services/seller-api-service";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";

const ReturnRtoOrders = () => {
  const auth = useAuth();
  const [orderCount, setCount] = useState(null);
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const OrderCountSpan = ({ count, text }) => {
    return (
      <h5
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "3px",
          alignItems: "center",
        }}
        className="font-mute fs-6 mb-0"
      >
        {text}
        <pre
          style={{
            backgroundColor: "#ff4d00",
            borderRadius: "50%",
            color: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "700",
            width: 24,
            height: 24,
            lineHeight: "24px",
          }}
        >
          {count}
        </pre>
      </h5>
    );
  };

  useEffect(() => {
    (async () => {
      const res = await sellerApiService.getOrderCount();
      if (res.data) {
        setCount(res.data.result);
      } else {
        setCount({});
      }
    })();
  }, []);
  console.log("opopopopo", orderCount);

  return (
    <div>
      <PageTitle text="Return/ RTO orders" />

      <Row className="gy-4">
        <Col md={12} lg={12} xl={12}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="return">
            <Box className="">
              <Paper
                className="p-0 bg-transparent "
                style={{
                  padding: "0px",
                  background: "none",
                  backgroundColo: "none",
                }}
              >
                <Nav
                  variant="pills"
                  className="fund-raise-generic-tabs-nav overflow-scroll flex-row text-center button-text flex-nowrap p-0"
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="return"
                      className="cursor-pointer text-grey-light fs-6 fw-bold px-2"
                    >
                      <OrderCountSpan
                        count={orderCount?.RETURN ?? 0}
                        text="Return"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="RTOorder"
                      className="cursor-pointer text-grey-light fw-bold px-2"
                    >
                      <OrderCountSpan
                        count={orderCount?.RTO ?? 0}
                        text="RTO orders"
                      />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Paper>
            </Box>
            <Tab.Content className="mt-2">
              <Tab.Pane eventKey="return">
                <Return />
              </Tab.Pane>
              <Tab.Pane eventKey="RTOorder">
                <RTOorder />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
        {/* <Col md={12} lg={12} xl={4}>
          <ReturnOrderFilter />
        </Col> */}
      </Row>

      {/* This is decided to show product detail in Return/RTO order  */}
      {/* <Row className="gy-3">
                <Col md={8} >
                    <OrderReturnDetail/>
                </Col>
                <Col md={4} >
                    <ReturnOrderCustomer/>
                </Col>
            </Row> */}
    </div>
  );
};

export default ReturnRtoOrders;
