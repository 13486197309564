import React, { useCallback, useState } from "react";
import { GenericButton } from "../../compoents/Button";
import { GenericLabel, OutlinedTextFields } from "../../compoents/Forms2";
import AccountDataService from "../../redux/services/account.service";

import { AiOutlineUser } from "react-icons/ai";
import { toast } from "react-toastify";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import Profile from "./profile";

const CompanyDetails = ({ user, handleChange }) => {
  let auth = useAuth();
  const [companyDetails, setCompanyDetails] = useState(user);
  const [isUpdating, setUpdating] = useState(false);
  const oldAddress = user?.address;

  const handleCompanyDetailsEdit = useCallback(() => {
    setUpdating(true);
    const address = companyDetails.address;
    let isAddressModified = false;
    if (
      address?.street !== oldAddress?.street ||
      address?.landmark !== oldAddress?.landmark ||
      address?.city !== oldAddress?.city ||
      address?.state !== oldAddress?.state ||
      address?.pincode !== oldAddress?.pincode
    ) {
      if (!isAddressModified) isAddressModified = true;
    }
    if (
      !(
        companyDetails?.name &&
        companyDetails?.mobile &&
        companyDetails?.email &&
        companyDetails?.company &&
        companyDetails?.address?.street &&
        companyDetails?.address?.landmark &&
        companyDetails?.address?.city &&
        companyDetails?.address?.pincode &&
        companyDetails?.address?.state
      )
    ) {
      toast.error("Please fill all mandatory fields.");
      return;
    }
    const accountDataService = AccountDataService;
    accountDataService
      .updateCompanyDetailsReq({ ...companyDetails, isAddressModified }, auth)
      .then((resp) => {
        setUpdating(false);
        const { data } = resp;
        if (data?.error) {
          toast.error(data?.error);
        } else if (data?.success) {
          toast.success(data?.success);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong.");
        setUpdating(false);
      });
  }, [companyDetails, auth, oldAddress]);

  return (
    <div>
      <div className="d-flex align-items-center border-botton-grey pb-2">
        <AiOutlineUser size={25} className="me-3" />
        <h5 className="mb-0">Personal Details</h5>
      </div>
      <div className="row">
        <div className="col-md-8">
          <Profile user={user} />
          <div className="my-4">
            <GenericLabel>
              <h6>
                Your Name <span className="text-danger">*</span>
              </h6>
            </GenericLabel>
            <OutlinedTextFields
              name="name"
              type="text"
              value={companyDetails?.name || ""}
              placeholder="Enter your full name"
              onChange={(e) => {
                setCompanyDetails((prev) => {
                  return { ...prev, name: e.target.value };
                });
              }}
              // onBlur={handleBlur}
            />
          </div>
          <div className="my-4">
            <GenericLabel>
              <h6>
                Phone No <span className="text-danger">*</span>
              </h6>
            </GenericLabel>
            <OutlinedTextFields
              name="mobile"
              type="number"
              disabled={true}
              value={companyDetails?.mobile ?? ""}
              placeholder="Enter phone no."
              onChange={(e) =>
                setCompanyDetails((prev) => {
                  return { ...prev, mobile: e.target.value };
                })
              }
              // onBlur={handleBlur}
            />
          </div>

          <div className="my-4">
            <GenericLabel>
              <h6>
                Email <span className="text-danger">*</span>
              </h6>
            </GenericLabel>
            <OutlinedTextFields
              name="email"
              type="email"
              value={companyDetails?.email || ""}
              placeholder="Enter your email"
              onChange={(e) =>
                setCompanyDetails((prev) => {
                  return { ...prev, email: e.target.value };
                })
              }
              // onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="d-flex my-3 align-items-center border-botton-grey pb-2">
        <AiOutlineUser size={25} className="me-3" />
        <h5 className="mb-0">Company Details</h5>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="my-4">
            <GenericLabel>
              <h6>
                Company Name <span className="text-danger">*</span>
              </h6>
            </GenericLabel>
            <OutlinedTextFields
              name="company"
              type="text"
              value={companyDetails?.company ?? ""}
              placeholder="Enter company name"
              onChange={(e) =>
                setCompanyDetails((prev) => {
                  return { ...prev, company: e.target.value };
                })
              }
              // onBlur={handleBlur}
            />
          </div>
          <div className="my-4">
            <GenericLabel>
              <h6>
                Address <span className="text-danger">*</span>{" "}
              </h6>
            </GenericLabel>
            <OutlinedTextFields
              name="address"
              type="text"
              value={companyDetails?.address?.street || ""}
              placeholder="Enter your address"
              onChange={(e) => {
                const street = e.target.value;
                setCompanyDetails((prev) => {
                  const address = { ...prev.address, street };
                  return { ...prev, address };
                });
              }}
              // onBlur={handleBlur}
            />
          </div>

          <div className="my-4">
            <GenericLabel>
              <h6>
                Landmark <span className="text-danger">*</span>
              </h6>
            </GenericLabel>
            <OutlinedTextFields
              name="landmark"
              type="text"
              placeholder="Landmark"
              // onChange={handleChange}
              value={companyDetails?.address?.landmark || ""}
              onChange={(e) => {
                const landmark = e.target.value;
                setCompanyDetails((prev) => {
                  const address = { ...prev.address, landmark };
                  return { ...prev, address };
                });
              }}
              // onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-5">
          <div className="my-4">
            <GenericLabel>
              <h6>
                City <span className="text-danger">*</span>
              </h6>
            </GenericLabel>
            <OutlinedTextFields
              name="city"
              type="text"
              placeholder="Enter City"
              value={companyDetails?.address?.city || ""}
              onChange={(e) => {
                const city = e.target.value;
                setCompanyDetails((prev) => {
                  const address = { ...prev.address, city };
                  return { ...prev, address };
                });
              }}
              // onChange={handleChange}
              // onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-5">
          <div className="my-4">
            <GenericLabel>
              <h6>
                Pincode <span className="text-danger">*</span>
              </h6>
            </GenericLabel>
            <OutlinedTextFields
              name="pincode"
              type="number"
              placeholder="Enter Pincode"
              value={companyDetails?.address?.pincode || ""}
              onChange={(e) => {
                const pincode = e.target.value;
                setCompanyDetails((prev) => {
                  const address = { ...prev.address, pincode };
                  return { ...prev, address };
                });
              }}
              // onChange={handleChange}
              // onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="my-4">
              <GenericLabel>
                <h6>
                  State <span className="text-danger">*</span>{" "}
                </h6>
              </GenericLabel>
              <OutlinedTextFields
                name="state"
                type="text"
                placeholder="Enter state"
                value={companyDetails?.address?.state || ""}
                onChange={(e) => {
                  const state = e.target.value;
                  setCompanyDetails((prev) => {
                    const address = { ...prev.address, state };
                    return { ...prev, address };
                  });
                }}
                // onChange={handleChange}
                // onBlur={handleBlur}
              />
            </div>

            <div className="my-4">
              <GenericLabel>
                <h6>GST Number</h6>
              </GenericLabel>
              <OutlinedTextFields
                name="gst_number"
                type="text"
                value={companyDetails?.gst_number || ""}
                placeholder="Enter GST number"
                onChange={(e) =>
                  setCompanyDetails({
                    ...companyDetails,
                    gst_number: e.target.value,
                  })
                }
                // onBlur={handleBlur}
              />
            </div>
            <GenericButton
              role="button"
              disabled={isUpdating}
              onClick={handleCompanyDetailsEdit}
            >
              Save
            </GenericButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
