import ProfileImage from "./assets/Images/profile.png";
import CompanyLogo from "./assets/Images/groupick.png";
import Illustration1 from "./assets/Images/illus1.jpg";
import DashIllustration from "./assets/Images/dashboard-happy.jpg";
import ReturnCardImage from "./assets/Images/white-solid-cut-outs-gown-women.jpg";
import Dollar from "./assets/Images/dollar.png";
import Info from "./assets/Images/info.png";
import ProductDetailImg from "./assets/Images/product-detail-carousel.jpg";
import ProductDetailImgCard from "./assets/Images/product-detail-carousel-card.png";
import HeroBanner from "./assets/Images/hero.png";
import HeroBgGradient from "./assets/Images/landing-hero-bg-gradient.png";
import Thumbnail1 from "./assets/Images/commision-tile.png";
import Thumbnail2 from "./assets/Images/business-tile.png";
import Thumbnail3 from "./assets/Images/supplier.png";
import AuthBackground from "./assets/Images/auth-bg.png";
import FooterBackground from "./assets/Images/footer-bg.png";

import AboutUs from "./assets/Images/About-us.docx";
import PrivacyPolicy from "./assets/Images/Privacy-policy.docx";
// import Refund from "./assets/Images/Refund.docx";
import Refund from "./assets/Images/Refund2.pdf";

import TermAndCondition from "./assets/Images/terms-of-service.docx";

// v2
import Hero1 from "./assets/Images/v2/Hero1.png";
import Hero2 from "./assets/Images/v2/Hero2.png";
import LoginImg from "./assets/Images/v2/LoginImage.png";
import Register1 from "./assets/Images/v2/Register1.png";
import SellerForm1 from "./assets/Images/v2/SellerForm1.png";
import SellerForm2 from "./assets/Images/v2/SellerForm2.png";
import SellerForm3 from "./assets/Images/v2/SellerForm3.png";
import SellerForm4 from "./assets/Images/v2/SellerForm4.png";
import SellerFormSuccess from "./assets/Images/v2/SellerFormSuccess.png";
import congrats from "./assets/Images/v2/congrats.png";
import select_product from "./assets/Images/v2/select_product.png";
import start_group from "./assets/Images/v2/start_group.png";
import invite_people from "./assets/Images/v2/invite_people.png";
import place_order from "./assets/Images/v2/place_order.png";
// import play_store from "./assets/Images/v2/play_store.png";
// Category images
import beauty_category from "./assets/Images/v2/category/beauty.jpeg";
import kidsfashion_category from "./assets/Images/v2/category/kidsfashion.jpeg";
import womenwear_category from "./assets/Images/v2/category/womenwear.jpeg";
import menswear_category from "./assets/Images/v2/category/menswear.jpeg";
import stationary_category from "./assets/Images/v2/category/stationary.jpeg";
import sports_category from "./assets/Images/v2/category/sports.jpeg";
import bags_category from "./assets/Images/v2/category/bags.jpeg";
import mom_baby_category from "./assets/Images/v2/category/mom_baby.jpeg";
import accessories_category from "./assets/Images/v2/category/accessories.jpeg";
import books_category from "./assets/Images/v2/category/books.jpeg";
import footwear_category from "./assets/Images/v2/category/footwear.jpeg";
import electronics_category from "./assets/Images/v2/category/electronics.jpeg";
import camera_category from "./assets/Images/v2/category/camera.jpeg";
import gaming_category from "./assets/Images/v2/category/gaming.jpeg";
import food_category from "./assets/Images/v2/category/food.jpeg";
import music_category from "./assets/Images/v2/category/music.jpeg";
import computer_category from "./assets/Images/v2/category/computer.jpeg";
import motorcycle_category from "./assets/Images/v2/category/motorcycle.jpeg";
import automobile_category from "./assets/Images/v2/category/automobile.jpeg";
import hobbies_category from "./assets/Images/v2/category/hobbies.jpeg";
import pets_category from "./assets/Images/v2/category/pets.jpeg";
import homedecor_category from "./assets/Images/v2/category/homedecor.jpeg";
import luggage_category from "./assets/Images/v2/category/luggage.jpeg";
import health_category from "./assets/Images/v2/category/health.jpeg";
// ------------------------------------------------------------------------
import seller1 from "./assets/Images/v2/category/seller1.png";
import seller2 from "./assets/Images/v2/category/seller2.png";
import seller3 from "./assets/Images/v2/category/seller3.png";
import orange from "./assets/Images/v2/orange.png";
import black from "./assets/Images/v2/black.png";
import feedback_1 from "./assets/Images/v2/feedback_one.png";
import feedback_2 from "./assets/Images/v2/feedback_two.png";
import feedback_3 from "./assets/Images/v2/feedback_three.png";
import quote_left from "./assets/Images/v2/left-quote.png";
import quote_right from "./assets/Images/v2/right-quote.png";
import order1 from "./assets/Images/v2/orders/order1.jpeg";
import order2 from "./assets/Images/v2/orders/order2.jpeg";
import order3 from "./assets/Images/v2/orders/order3.jpeg";
import order4 from "./assets/Images/v2/orders/order4.jpeg";
import order5 from "./assets/Images/v2/orders/order5.jpeg";
import order6 from "./assets/Images/v2/orders/order6.jpeg";
import order7 from "./assets/Images/v2/orders/order7.jpeg";
import pricing_bg from "./assets/Images/v2/pricing_bg.png";
import pricing_image from "./assets/Images/v2/pricing_image.png";
import groupickPrice from "./assets/Images/v2/groupickPrice.png";
import pricingCommission from "./assets/Images/v2/pricingCommission.png";
import groupickPayout from "./assets/Images/v2/groupickPayout.png";
import proof1 from "./assets/Images/v2/proof1.jpeg";
import proof2 from "./assets/Images/v2/proof2.jpeg";
import delivery from "./assets/Images/v2/delhivery.png";
import about from "./assets/Images/v2/about.png";
// Partners images
import fastracLogo from "./assets/Images/v2/partners/fastrack_logo.png";
import doobidooLogo from "./assets/Images/v2/partners/doobidoo_logo.png";
import droolsLogo from "./assets/Images/v2/partners/drools_logo.png";
import godrejLogo from "./assets/Images/v2/partners/godrej_logo.png";
import mamaearthLogo from "./assets/Images/v2/partners/mamaearth_logo.png";
import titanLogo from "./assets/Images/v2/partners/titan_logo.png";

let IMAGES = {
  AUTH_BG: AuthBackground,
  HERO_BANNER: HeroBanner,
  HERO_BG_GRADIENT: HeroBgGradient,
  THUMB_NAIL1: Thumbnail1,
  THUMB_NAIL2: Thumbnail2,
  THUMB_NAIL3: Thumbnail3,
  PROFLE_IMAGE: ProfileImage,
  COMPANY_LOGO: CompanyLogo,
  DUMMY_ILLUSTRATION: Illustration1,
  Dashboard_Illustration: DashIllustration,
  Return_Card_Image: ReturnCardImage,
  Return_Detail_Card_Image: ReturnCardImage,
  DOLLAR: Dollar,
  INFO: Info,
  PRODUCTDETAILIMG: ProductDetailImg,
  PRODUCTDETAILIMGCARD: ProductDetailImgCard,
  CARD_IMAGE: ReturnCardImage,
  FOOTER_BG: FooterBackground,

  ABOUT_US: AboutUs,
  PRIVACY_POLICY: PrivacyPolicy,
  REFUND: Refund,
  TERM_AND_CONDITION: TermAndCondition,
};

const IMAGESV2 = {
  Hero2,
  Hero1,
  LoginImg,
  Register1,
  SellerForm1,
  SellerForm2,
  SellerForm3,
  SellerForm4,
  SellerFormSuccess,
  congrats,
  select_product,
  start_group,
  invite_people,
  place_order,
  // play_store,
  // category
  beauty_category,
  kidsfashion_category,
  womenwear_category,
  menswear_category,
  stationary_category,
  sports_category,
  bags_category,
  mom_baby_category,
  accessories_category,
  books_category,
  footwear_category,
  electronics_category,
  camera_category,
  gaming_category,
  food_category,
  music_category,
  computer_category,
  motorcycle_category,
  automobile_category,
  hobbies_category,
  pets_category,
  homedecor_category,
  luggage_category,
  health_category,
  // -----------
  seller1,
  seller2,
  seller3,
  feedback_1,
  feedback_2,
  feedback_3,
  quote_left,
  quote_right,
  orange,
  black,
  order1,
  order2,
  order3,
  order4,
  order5,
  order6,
  order7,
  pricing_bg,
  pricing_image,
  groupickPrice,
  pricingCommission,
  groupickPayout,
  proof1,
  proof2,
  delivery,
  about,
  // brands
  fastracLogo,
  doobidooLogo,
  droolsLogo,
  godrejLogo,
  mamaearthLogo,
  titanLogo,
};

let BRAND_NAME = "Mee Too";

const PATH = {
  LANDING_PAGE: "/",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  SELLER_PROFILE: "/seller",
  DASHBOARD: "/dashboard",
  CATALOGUE: "/catalogue",
  BRAND: "/brands",
  VIEWBRAND: "/brand/:id",
  ADD_PODUCT: "/add-product",
  // ADD_PODUCT_v2: "/add-productv2",
  CONTACTS: "/contacts",
  ORDERS: "/orders",
  RETURN_RTO_ORDERS: "/return-rto-order",
  RETURN_ORDER_DETAIL: "/return-order-detail/orderId=:orderId",
  INVENTORY: "/inventory",
  INVENTORY_PRODUCT_DETAIL: "/inventory-product-detail/productId=:productId",
  EDIT_INVENTORY_PRODUCT: "/edit-inventory-product/productId=:productId",
  PAYMENT: "/payment",
  PAYMENT_VIEW_MORE: "/view-more",
  REVIEW_AND_RATING: "/review-and-ratings",
  REVIEW_AND_RATING_SINGLE_PRODUCT: "/review-and-ratings/productId=:productId",
  SETTINGS: "/settings",
  NOTICE: "/notice",
  SUPPORT: "/support",
  TICKET_VIEW: "/support/ticket/:id",
  SUPPORT_BY_ID: "/support/:id",
  NOTIFICATIONS: "/notifications",
  ORDER_DETAIL: "/order-detail/orderId=:orderId",
  // Dashboard Policies
  TERMS_CONDITION: "/terms-and-conditions",
  ANTI_PHISHING_POLICY: "/anti-phishing-policy",
  PRIVACY_POLICIES: "/privacy-policies",
  INTELLECTUAL_PROPERTY_POLICY: "/intellectual-property-policy",
  SUPLIER_AGREEMENT: "/Suplier-agreement",
  SUPLIER_DEACTIVATION: "/seller-deactivation-policy",
  PROHABITIED_AND_RESTRICTED_POLICY:
    "/prohabitied-and-restricted-product-policy",
  ADDITIONAL_SELLER_POLICIES: "/additional-seller-policies",
  SELLER_REFERRAL_PROGRAM: "/seller-referral-program",
  WHISTLE_BLOWSER_POLICY: "/whistle-blower-policy",
  COURIER_PARTNER_CLAIMS: "/courier-partner-preference-and-claims",

  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  PRIVACY_POLICY: "/privacy-policy",
  POLICY: "/privacy-policy",
  TERM_AND_CONDITION: "/terms-and-condition",
  REFUND: "/refund-policy",
  PRICING: "/pricing-charges",
  DELIVERY: "/delivery",
  GALLERY: "/gallery",
  WELL_KNOWN: "/.well-known/assetlinks.json",
  CAMPAIGN: "/campaign",
};

// const baseUrl = "http://127.0.0.1:8000/api/v1";
// const download_base_url = "http://127.0.0.1:8000";
const download_base_url = process.env.REACT_APP_URL;
const baseUrl = process.env.REACT_APP_URL + "/api/v1";
console.log(process.env.REACT_APP_NOT_SECRET_CODE, "sdfsd");
// const baseUrl = "http://127.0.0.1:8000/api/v1";
// const download_base_url = "http://127.0.0.1:8000";
// const download_base_url = "https://api.groupick.in";
// const baseUrl = "https://api.groupick.in/api/v1";

const APP_SETTINGS = {
  API_PATH: {
    razorpaySdk: "https://checkout.razorpay.com/v1/checkout.js",
    ACCOUNT: {
      registerAccount: baseUrl + "/auth/signup/",
      loginAccount: baseUrl + "/auth/login/",
      verifyOtp: baseUrl + "/auth/otp/verify/",
      profileUpdate: baseUrl + "/auth/update/",
      updateCompanyDetails: baseUrl + "/auth/update-company-details/",
      getPaymentDetails: baseUrl + "/get_payment_details",
      getPaymentByType: baseUrl + "/payment_detail_by_type?{{param}}",
      uploadProfileImage: baseUrl + "/auth/update-profile",
      deleteProfileImage: baseUrl + "/auth/update-profile",
      createRegisterFee: baseUrl + "/registerFee/create_order",
      verifyRegisterFee: baseUrl + "/registerFee/verify_payment",
      registerFeePaymentFailed: baseUrl + "/registerFee/payment_failed",
      registerFeePaymentDetail: baseUrl + "/registerFee/payment_detail",

      // Paid service urls
      paidPlanDetail: baseUrl + "/paid_services/plans",
      createPaidServiceOrder: baseUrl + "/paid_services/plan_order/{{PLAN_ID}}",
      paidServiceVerifyPayment: baseUrl + "/paid_services/verify_order",
      paidServiceDetails: baseUrl + "/paid_services/{{PLAN_ID}}",
    },
    PRODUCT: {
      productListing: baseUrl + "/products/",
      createProduct: baseUrl + "/products/",
      uploadImage: baseUrl + "/products",
      updatePreview: baseUrl + "/products",
    },
    CATEGORY: {
      categoryListing: baseUrl + "/categories/",
    },
    CATALOG: {
      bulkupload: baseUrl + "/catalogs/bulk-add/",
      bulkuploadV2: baseUrl + "/catalogs/bulk-add-v2/",
      bulkimageupload: baseUrl + "/product/bulk-add/images/file/",
      getAllImages: baseUrl + "/product/bulk-add/images/file/",

      bulkgetdata: baseUrl + "/product/bulk-add/info",
      download_excel_file: download_base_url + "/media/groupick.xlsx",
      download_calculator: download_base_url + "/media/calculator.xlsx",
      qc: baseUrl + "/qc",
      qc_upload: baseUrl + "/qc/upload",
    },
    BRAND: {
      brandCreate: baseUrl + "/add-brand",
      brandUpdate: baseUrl + "/add-brand?id={{brand_id}}",
      uploadBrandSample: baseUrl + "/add-brand-sample/{{brand_id}}",
      getAllBrands: baseUrl + "/get-brand",
      getBrandById: baseUrl + "/brand/{{brand_id}}",
    },
    FAQ: {
      faqFetch: baseUrl + "/faq_list?user_type={{USER_TYPE}}",
      faqById: baseUrl + "/faq/{{FAQ_ID}}",
      raiseTicket: baseUrl + "/faq/raise/{{TICKET_ID}}",
      sendMsg: baseUrl + "/faq/msg/{{TICKET_ID}}",
      getTicket: baseUrl + "/ticket",
      getTicketById: baseUrl + "/ticket/{{TICKET_ID}}",
    },
    WALLET: {
      init_payment: baseUrl + "/init_payment",
      verify_payment: baseUrl + "/verify_payment",
      get_wallet: baseUrl + "/get_wallet",
    },
    CAMPAIGN: {
      create_campaign: baseUrl + "/create_campaign",
      fetch_campaign: baseUrl + "/campaigns",
    },
  },
};

const GENERIC_MESSAGE = {
  REQUIRED_FIELD_MESSAGE: "This field is required.",
};

const TOAST_STYLE = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export {
  BRAND_NAME,
  IMAGES,
  PATH,
  APP_SETTINGS,
  GENERIC_MESSAGE,
  TOAST_STYLE,
  IMAGESV2,
  baseUrl,
  download_base_url,
};
