import React, { useCallback, useRef, useState } from "react";
import { BrandFormStyled } from "./brandFormStyled";
import { AiOutlineLeft } from "react-icons/ai";
import Paper from "../../../compoents/Paper";
import { Formik, Form as FormikForm } from "formik";
import { Form } from "react-bootstrap";
import CustomTooltip from "../../Product/CustomTooltip";
import ErrorMessage from "../../../compoents/ErrorMessage";
import * as Yup from "yup";
import { GenericButton } from "../../../compoents/Button";
import BrandCategorySelectModal from "./BrandCategorySelectModal";
import {
  AiFillPlusCircle,
  AiFillCheckCircle,
  AiOutlinePlus,
  AiOutlineClose,
} from "react-icons/ai";
import { toast } from "react-toastify";
import { OutlinedTextFields } from "../../../compoents/Forms2";
import Select from "react-select";
import BrandDataService from "./../../../redux/services/brand-api-services";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { useDispatch } from "react-redux";
import { BrandSampleCreateThunk } from "../../../redux/slices/brand";
import { requiredDocumentList } from "../brandConstant";
import { BsPencilSquare } from "react-icons/bs";
function BrandForm({ closeForm, isEdit = false, editData = {} }) {
  const [selectCategory, setSelectCategory] = useState(false);
  const [selectedCategoryIdList, setSelectedCategoryIdList] = useState([]);
  const [uploadObj, setUploadObj] = useState({
    logo: false,
    sampleImages: false,
    document_proof: false,
  });
  const [showSampleUpload, setShowSampleUpload] = useState(!isEdit);
  const [showLogoUpload, setShowLogoUpload] = useState(!isEdit);
  const [showProofUpload, setShowProofUpload] = useState(!isEdit);
  const saveCategoryRef = useRef(null);
  const categoryRef = useRef(null);
  const hiddenSampleImageRef = useRef(null);
  const hiddenDocumentImageRef = useRef(null);
  const hiddenBrandLogoRef = useRef(null);
  const auth = useAuth();
  const dispatch = useDispatch();

  const formConstant = {
    field1: {
      label: "Category",
      isMandate: true,
      tooltip: {
        desc: "Select your brand category.",
      },
    },
    field2: {
      label: "Brand Name",
      isMandate: true,
      tooltip: {
        desc: "Mention your registered brand name.",
      },
    },
    field3: {
      label: "Sample Product Image",
      isMandate: true,
      tooltip: {
        desc: "Attach sample product images",
      },
    },
    field4: {
      label: "Brand Logo",
      isMandate: true,
      tooltip: {
        desc: "Attach brand logo",
      },
    },
    field5: {
      label: "Brand Website",
      isMandate: false,
    },
    field6: {
      label: "Additional Information",
      isMandate: false,
    },
    field7: {
      label: "Submit Document",
      isMandate: true,
      tooltip: {
        desc: "Min. 1 brand document is neccessary",
      },
    },
  };

  // const requiredDocumentList = [
  //   { value: 0, label: "Trademark certificate" },
  //   { value: 1, label: "Brand authorization letter" },
  //   { value: 2, label: "Invoice" },
  // ];
  console.log("111111111", editData);
  const initialValues = {
    categoryList: editData?.brand_ref ?? [],
    name: editData?.name ?? "",
    website_link: editData?.website_link ?? "",
    additional_info: editData?.additional_info ?? "",
    sampleImages: editData?.brand_sample ?? [],
    logo: editData?.logo ? [editData?.logo] : [],
    document: requiredDocumentList[editData?.document ?? 0],
    document_proof: editData?.document_proof ? [editData?.document_proof] : [],
  };

  const validationSchema = Yup.object().shape({
    categoryList: Yup.array().min(1, "Add atleast 1 category."),
    name: Yup.string().required("Enter your brand name."),
    sampleImages: Yup.array().min(1, "Add atleast 1 sample image."),
    logo: Yup.array().min(1, "Add brand logo."),
    document: Yup.object()
      .shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
      .required("Submit any one of the document."),
    document_proof: Yup.array().min(1, "Upload document proof."),
  });

  const hideCategoryModal = () => setSelectCategory(false);

  const CustomFormGroup = ({ formConstant = {}, children }) => {
    return (
      <>
        <Form.Label className="brandForm__label">
          {formConstant?.isMandate && <span className="text-danger">*</span>}{" "}
          {formConstant?.label}{" "}
          {formConstant?.tooltip &&
            Object.keys(formConstant?.tooltip).length > 0 && (
              <CustomTooltip
                description={formConstant?.tooltip?.desc}
                placement="right"
                customStyledClass={{ transform: "translateY(15%)" }}
              />
            )}
        </Form.Label>
        {children}
      </>
    );
  };

  const handleCategorySelect = (setFieldValue, prevValue) => {
    const selectedCategory = categoryRef?.current?.getSelectedCategory() ?? [];

    const idList = selectedCategory.map((category) => category.id);
    const isSelected = selectedCategoryIdList.find(
      (arr) => arr.toString() === idList.toString()
    );

    if (!isSelected) {
      setSelectedCategoryIdList((prev) => [...prev, idList]);
      prevValue.push(selectedCategory);
      setFieldValue("categoryList", prevValue);
      hideCategoryModal(false);
    } else {
      toast.error("Category already selected.");
    }
  };

  const handleSampleUpload = ({
    newFileList,
    list,
    setFieldValue,
    fieldName,
  }) => {
    const oldSampleCount = list.length;
    const newSampleCount = newFileList;

    if (newFileList < 1) {
      toast.error("Please select image.");
      return;
    }
    let newList = list;
    for (const file of newFileList) {
      newList.push(file);
    }
    if (newList.splice(5).length > 0) {
      toast.error("Max. 5 sample can be uploaded.");
    }
    setUploadObj((prev) => ({ ...prev, [fieldName]: true }));
    setFieldValue(fieldName, newList);
  };

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  function getDataURI(data) {
    // Check if data parameter is a string or an object
    if (typeof data === "string") {
      // If it is a base64 string, convert it to File Object and return
      return dataURItoBlob(data);
    } else if (typeof data === "object") {
      // If it is an object, check if it is a file object
      return data;
    }
  }

  const handleCategoryDelete = ({ index, list, setFieldValue }) => {
    const newCategory = list;
    newCategory.splice(index, 1);
    const newIdList = selectedCategoryIdList;
    newIdList.splice(index, 1);
    setFieldValue("categoryList", newCategory);
    setSelectedCategoryIdList(newIdList);
  };

  const groupCategory = (list) => {
    const res = list.map((data) => {
      let category_list = data;
      let category = data.id;
      if (!data?.id) {
        category_list = data.reduce((acc, cur) => {
          acc.push(cur.id);
          return acc;
        }, []);
        const lastChild = data[data.length - 1];
        category = lastChild["id"];
      }
      return { category, category_list };
    });
    return res;
  };

  const handleBrandFormSubmit = async (values) => {
    console.log("Brand data::::", values);
    try {
      const {
        name,
        website_link,
        additional_info,
        document,
        logo,
        document_proof,
      } = values;
      const selectedCategoryList = values.categoryList;
      const category_list = groupCategory(selectedCategoryList);
      const brandData = new FormData();
      brandData.append("name", name);
      brandData.append("website_link", website_link);
      brandData.append("additional_info", additional_info);
      brandData.append("document", document?.value);
      brandData.append("logo", logo[0] ?? undefined);
      brandData.append("document_proof", document_proof[0] ?? undefined);
      brandData.append("category_list", JSON.stringify(category_list));
      brandData.append("upload_record", JSON.stringify(uploadObj));
      if (isEdit) {
        brandData.append("isSampleUpdated", showSampleUpload);
      }
      const finalData = {
        header: auth,
        brandData,
        brand_id: editData?.id,
      };
      let handler;
      if (isEdit) {
        handler = BrandDataService.updateBrand;
      } else {
        handler = BrandDataService.addBrand;
      }
      const res = await handler(finalData);
      const { data } = res;
      if (data?.status === "error") {
        toast.error(data?.msg);
        return;
      }
      if (data?.status === "sucess") {
        toast.success("Brand Created.");
        if (isEdit && !showSampleUpload) return closeForm();
        const brandId = data?.data?.brand_id;
        const samples = values?.sampleImages;
        if (brandId) {
          samples?.map((file, i) => {
            const sampleData = new FormData();
            sampleData.append("file", file);
            sampleData.append("sequence", i);
            const data = {
              sampleData,
              brandId,
              header: auth,
            };
            dispatch(BrandSampleCreateThunk(data));
            return null;
          });
        }
        closeForm();
      }
    } catch (error) {
      console.log("Error@brandCreate:", error);
      toast.error("Error while adding brand.");
    }
  };

  return (
    <BrandFormStyled>
      <div className="brandForm__breadcrumb" role="button" onClick={closeForm}>
        <AiOutlineLeft size={20} className="brandForm__breadcrumbIcon" />
        <span className="fw-bolder brandForm__breadcrumbText">
          {isEdit ? "Edit Brand" : "Add Brand"}
        </span>
      </div>

      <Paper className="brandForm__paper">
        <div className="mb-4 fw-normal">
          Please fill in the required information to add your brand to our
          database, so that you can include it in your product information.
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          // onSubmit={(values) => {
          //   const selectedCategoryList = values.categoryList;
          //   const category = groupCategory(selectedCategoryList);
          //   console.log("Onform submit", values, category);
          // }}
          onSubmit={handleBrandFormSubmit}
        >
          {({ errors, values, setFieldValue, touched }) => (
            <FormikForm className="brandForm__container">
              <div className="brandForm__gridLayout">
                <CustomFormGroup formConstant={formConstant.field1}>
                  <div>
                    <div className="brandForm__categoryContainer">
                      <div
                        className={`brandForm__categoryContent ${
                          touched.categoryList && errors.categoryList
                            ? "category_field_error"
                            : ""
                        }`}
                      >
                        {values.categoryList.map((categorys, i) => {
                          let text;
                          if (isEdit && categorys?.id) {
                            text = categorys?.label;
                          } else {
                            text = categorys.reduce((acc, cur, i, arr) => {
                              return (acc += `${cur.name} ${
                                i + 1 < arr.length ? " > " : ""
                              }`);
                            }, "");
                          }
                          return (
                            <div
                              className="brandForm__category"
                              key={`cate-${i}`}
                            >
                              {text}{" "}
                              <AiOutlineClose
                                role="button"
                                className="closeIcon"
                                onClick={() =>
                                  handleCategoryDelete({
                                    index: i,
                                    setFieldValue,
                                    list: values.categoryList,
                                  })
                                }
                                size={16}
                                color="#dc3545"
                                fontWeight={500}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <AiFillPlusCircle
                        className={`brandForm__addIcon ${
                          selectCategory ? "brandForm__closeIcon" : ""
                        }`}
                        size={32}
                        role="button"
                        onClick={() => setSelectCategory(!selectCategory)}
                      />
                      <div ref={saveCategoryRef} style={{ display: "none" }}>
                        <AiFillCheckCircle
                          className={`brandForm__addIcon brandForm__saveIcon`}
                          size={32}
                          role="button"
                          onClick={() => {
                            handleCategorySelect(
                              setFieldValue,
                              values.categoryList
                            );
                          }}
                        />
                      </div>
                    </div>
                    {touched.categoryList && errors.categoryList && (
                      <ErrorMessage message={errors.categoryList} />
                    )}
                    {selectCategory && (
                      <BrandCategorySelectModal
                        saveRef={saveCategoryRef}
                        ref={categoryRef}
                      />
                    )}
                  </div>
                </CustomFormGroup>
                <CustomFormGroup formConstant={formConstant.field2}>
                  <div>
                    <OutlinedTextFields
                      className="brandForm__inputField"
                      type="text"
                      placeholder="Enter your brand name."
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      hasError={touched.name && errors.name}
                    />
                    {touched.name && errors.name && (
                      <ErrorMessage message={errors.name} />
                    )}
                  </div>
                </CustomFormGroup>
                <CustomFormGroup formConstant={formConstant.field3}>
                  <div className="brandForm__sampleContainer">
                    {showSampleUpload ? (
                      <>
                        {values.sampleImages.map((sample, index) => {
                          return (
                            <img
                              src={window.URL?.createObjectURL(
                                getDataURI(sample)
                              )}
                              alt="sample"
                              className="img-thumbnail img-fluid me-2 brandForm__sampleImages"
                              key={`sampleImage-${index}`}
                            />
                          );
                        })}
                        {!(values.sampleImages.length >= 5) && (
                          <span
                            role="button"
                            onClick={() => {
                              hiddenSampleImageRef.current.click();
                            }}
                            className={`brandForm__sampleImageButton ${
                              touched.sampleImages && errors.sampleImages
                                ? "sample_image_error"
                                : ""
                            }`}
                          >
                            <AiOutlinePlus size={20} />
                          </span>
                        )}
                        <div style={{ display: "block", width: "100%" }}>
                          <input
                            type="file"
                            hidden
                            name="sampleImg"
                            multiple
                            onChange={(e) => {
                              handleSampleUpload({
                                newFileList: e.target.files,
                                list: values.sampleImages,
                                setFieldValue,
                                fieldName: "sampleImages",
                              });
                            }}
                            ref={hiddenSampleImageRef}
                          />
                          {touched.sampleImages && errors.sampleImages && (
                            <ErrorMessage message={errors.sampleImages} />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {values.sampleImages.map((sample, index) => {
                          return (
                            <img
                              src={sample.file}
                              alt="sample"
                              className="img-thumbnail img-fluid me-2 brandForm__sampleImages"
                              key={`sampleImage-${index}`}
                            />
                          );
                        })}
                        <GenericButton
                          className="d-flex"
                          style={{
                            height: "fit-content",
                            width: "fit-content",
                            background: "none",
                            padding: 0,
                            color: "var(--primary-color)",
                            border: "none",
                          }}
                          onClick={() => {
                            setFieldValue("sampleImages", []);
                            setShowSampleUpload(true);
                          }}
                        >
                          <BsPencilSquare height={24} width={24} />
                        </GenericButton>
                      </>
                    )}
                  </div>
                </CustomFormGroup>
                <CustomFormGroup formConstant={formConstant.field4}>
                  <div className="brandForm__brandLogo">
                    {showLogoUpload ? (
                      <>
                        {values.logo.map((sample, index) => {
                          return (
                            <img
                              src={window.URL?.createObjectURL(
                                getDataURI(sample)
                              )}
                              alt="logo"
                              className="img-thumbnail img-fluid me-2 brandForm__sampleImages"
                              key={`logo-${index}`}
                            />
                          );
                        })}
                        {!(values.logo.length >= 1) && (
                          <span
                            role="button"
                            onClick={() => {
                              hiddenBrandLogoRef.current.click();
                            }}
                            className={`brandForm__sampleImageButton ${
                              touched.logo && errors.logo
                                ? "sample_image_error"
                                : ""
                            }`}
                          >
                            <AiOutlinePlus size={20} />
                          </span>
                        )}
                        <div style={{ display: "block", width: "100%" }}>
                          <input
                            type="file"
                            hidden
                            name="logo"
                            onChange={(e) => {
                              handleSampleUpload({
                                newFileList: e.target.files,
                                list: values.logo,
                                setFieldValue,
                                fieldName: "logo",
                              });
                            }}
                            ref={hiddenBrandLogoRef}
                          />
                          {touched.logo && errors.logo && (
                            <ErrorMessage message={errors.logo} />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {values.logo.map((sample, index) => {
                          return (
                            <img
                              src={sample}
                              alt="logo"
                              className="img-thumbnail img-fluid me-2 brandForm__sampleImages"
                              key={`logo-${index}`}
                            />
                          );
                        })}
                        <GenericButton
                          className="d-flex"
                          style={{
                            height: "fit-content",
                            width: "fit-content",
                            background: "none",
                            padding: 0,
                            color: "var(--primary-color)",
                            border: "none",
                          }}
                          onClick={() => {
                            setFieldValue("logo", []);
                            setShowLogoUpload(true);
                          }}
                        >
                          <BsPencilSquare height={24} width={24} />
                        </GenericButton>
                      </>
                    )}
                  </div>
                </CustomFormGroup>
                <CustomFormGroup formConstant={formConstant.field5}>
                  <div>
                    <OutlinedTextFields
                      className="brandForm__inputField"
                      type="text"
                      placeholder="Provide the website URL of your brand."
                      value={values.website_link}
                      onChange={(e) =>
                        setFieldValue("website_link", e.target.value)
                      }
                      hasError={touched.website_link && errors.website_link}
                    />
                    {touched.website_link && errors.website_link && (
                      <ErrorMessage message={errors.website_link} />
                    )}
                  </div>
                </CustomFormGroup>
                <CustomFormGroup formConstant={formConstant.field6}>
                  <div>
                    <OutlinedTextFields
                      className="brandForm__inputField"
                      as="textarea"
                      placeholder="Provide any other information about your brand."
                      value={values.additional_info}
                      onChange={(e) =>
                        setFieldValue("additional_info", e.target.value)
                      }
                      hasError={
                        touched.additional_info && errors.additional_info
                      }
                    />
                    {touched.additional_info && errors.additional_info && (
                      <ErrorMessage message={errors.additional_info} />
                    )}
                  </div>
                </CustomFormGroup>
                <CustomFormGroup formConstant={formConstant.field7}>
                  <div className="brandForm__inputField">
                    <Select
                      className={
                        !(touched.document && errors.document)
                          ? "mb-3"
                          : "select_field_error"
                      }
                      options={requiredDocumentList}
                      placeholder="Select document"
                      value={values.document}
                      onChange={(e) => setFieldValue("document", e)}
                      isSearchable={false}
                    />
                    {touched.document && errors.document && (
                      <ErrorMessage message={errors.document} />
                    )}
                    <div className="brandForm__proof">
                      {showProofUpload ? (
                        <>
                          {values.document_proof.map((image, index) => {
                            return (
                              <img
                                src={window.URL?.createObjectURL(
                                  getDataURI(image)
                                )}
                                alt="document_proof"
                                className="img-thumbnail img-fluid me-2 brandForm__sampleImages"
                                key={`sampleImage-${index}`}
                              />
                            );
                          })}
                          {!(values.document_proof.length >= 1) && (
                            <span
                              role="button"
                              onClick={() => {
                                hiddenDocumentImageRef.current.click();
                              }}
                              className={`brandForm__sampleImageButton ${
                                touched.document_proof && errors.document_proof
                                  ? "sample_image_error"
                                  : ""
                              }`}
                            >
                              <AiOutlinePlus size={20} />
                            </span>
                          )}
                          <div style={{ display: "block", width: "100%" }}>
                            <input
                              type="file"
                              hidden
                              name="proof"
                              onChange={(e) => {
                                handleSampleUpload({
                                  newFileList: e.target.files,
                                  list: values.document_proof,
                                  setFieldValue,
                                  fieldName: "document_proof",
                                });
                              }}
                              ref={hiddenDocumentImageRef}
                            />
                            {touched.document_proof &&
                              errors.document_proof && (
                                <ErrorMessage message={errors.document_proof} />
                              )}
                          </div>
                        </>
                      ) : (
                        <>
                          {values.document_proof.map((image, index) => {
                            return (
                              <img
                                src={image}
                                alt="document_proof"
                                className="img-thumbnail img-fluid me-2 brandForm__sampleImages"
                                key={`sampleImage-${index}`}
                              />
                            );
                          })}
                          <GenericButton
                            className="d-flex"
                            style={{
                              height: "fit-content",
                              width: "fit-content",
                              background: "none",
                              padding: 0,
                              color: "var(--primary-color)",
                              border: "none",
                            }}
                            onClick={() => {
                              setFieldValue("document_proof", []);
                              setShowProofUpload(true);
                            }}
                          >
                            <BsPencilSquare height={24} width={24} />
                          </GenericButton>
                        </>
                      )}
                    </div>
                  </div>
                </CustomFormGroup>
                <div className="brandForm__submitBtn">
                  <GenericButton type="submit">Save</GenericButton>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </Paper>
    </BrandFormStyled>
  );
}

export default BrandForm;
