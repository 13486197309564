import {GenericButton} from "../../compoents/Button";
import React, {useEffect, useRef, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {toast} from "react-toastify";
import {TOAST_STYLE} from "../../config";
import styled from "styled-components";
import {useAuth} from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import {productImageUploadThunk} from "../../redux/slices/product";
import {useDispatch, useSelector} from "react-redux";
import ProductDataService from "../../redux/services/product.service";
import {dashboardCateSelector} from "../../redux/slices/category";

const StyledUploadImage = styled(GenericButton)`
  /* color: black !important;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #868686;
border-radius: 7px;
color:#FF4D00 !important;
font-size:24px;
font-weight:bold;
padding:20px;
display: flex !important;
flex-direction: column;
justify-content: center;
align-items: center; */

  background: #ffffff;
  border: 1px solid #122736;
  border-radius: 43px;
  color: #122736 !important;
`;

const Variation = ({setTabActiveKey}) => {
  const [selectedVariations, setSelectedVariations] = useState([]);
  const hiddenFileInput = useRef(null);
  const [product, setProduct] = useState({});
  const [deletedVariationImages, setDeletedVariationImages] = useState([]);
  let auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const dispatch = useDispatch();
  const [category, setCategory] = useState({});

  const addVariation = () => {
    let temp = {...product};
    temp.color = "";
    temp.size = "";
    temp.sku = "";
    temp.mrp = 0;
    temp.parent = product.id;
    temp.category = product.category.id;
    temp.group_price = 0;
    temp.selling_price = 0;
    temp.stock = 1;
    temp.images = [];
    temp.seller = product.seller;
    temp.specifications = "[]";
    temp.options = "[]";
    setSelectedVariations([...selectedVariations, temp]);
  };

  function uploadFile(e, index) {
    let file = e.target.files[0];
    let tempVariations = [...selectedVariations];
    if (tempVariations[index].images.length < 5) {
      tempVariations[index].images.push(file);
      setSelectedVariations(tempVariations);
    } else {
      toast.error("You can upload only 5 images", TOAST_STYLE);
    }
  }

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  };

  function getDataURI(data) {
    // Check if data parameter is a string or an object
    if (typeof data === "string") {
      // If it is a base64 string, convert it to File Object and return
      return dataURItoBlob(data);
    } else if (typeof data === "object") {
      // If it is an object, check if it is a file object
      return data;
    }
  }

  const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  };

  const saveAndContinue = async () => {
    deletedVariationImages.forEach((image) => {
      sellerApiService
        .deleteProductImages(product.id, image.id)
        .then((res) => {});
    });
    for (let data of selectedVariations) {
      if (data?.id == product.id) {
        let d = new FormData();
        for (let key in data) {
          d.append(key, data[key]);
        }
        let finalPayload = {
          header: auth,
          addProductFormData: d,
        };
        const res = await ProductDataService.createProduct(finalPayload);
        if (res.status == 201) {
          let p = res.data;
          let index = getRandomIntInclusive(9999, 999999);
          for (let image of data.images) {
            if (!image.file?.includes("/")) {
              let formData = new FormData();
              formData.append("file", image);
              formData.append("sequence", index);
              let finalPayload = {
                uploadImage: formData,
                productId: p.id,
                header: auth,
              };
              dispatch(productImageUploadThunk(finalPayload));
              index++;
            }
          }
        }
      } else {
        data.category = product.category.id;
        data.seller = product.seller;
        const res = await sellerApiService.updateProduct(data);
        if (res.data) {
          let index = getRandomIntInclusive(9999, 999999);
          for (let image of data.images) {
            if (!image.file?.includes("/")) {
              let formData = new FormData();
              formData.append("file", image);
              formData.append("sequence", index);
              let finalPayload = {
                uploadImage: formData,
                productId: data.id,
                header: auth,
              };
              dispatch(productImageUploadThunk(finalPayload));
              index++;
            }
          }
        }
      }
    }
    const res = await sellerApiService.getProduct(product.id);
    if (res.data) {
      toast.success("Product updated successfully", TOAST_STYLE);
      const {product} = res.data;
      localStorage.setItem("product", JSON.stringify(product));
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    }
  };

  const imageSrc = (image) => {
    image = getDataURI(image);
    if (image.file?.includes("/")) {
      return "http://api.groupick.in/media/" + image.file;
    } else {
      return window.URL?.createObjectURL(image);
    }
  };

  useEffect(() => {
    let product = JSON.parse(localStorage.getItem("product"));
    setProduct(product);
    product?.variants?.forEach((variation, index) => {
      let res = sellerApiService.getProductImages(variation.id);
      res.then((response) => {
        const {image} = response.data;
        product.variants[index].images = image;
        setSelectedVariations(product.variants);
      });
    });
    setSelectedVariations(product.variants);

    let category =
      JSON.parse(localStorage.getItem("selectedCategory")) || product.category;
    if (category) {
      (async () => {
        if (Object.keys(category).length > 0) {
          const res = await sellerApiService.getCategory(category.id);
          if (res.data) {
            const category = res.data;
            setCategory(category);
          }
        }
      })();
    }
  }, []);

  const Continue = () => {
    setTabActiveKey("images");
  };

  return (
    <div>
      {category?.option_types?.length > 0 && (
        <div>
          <div className="my-5 text-center">
            <GenericButton type="button" onClick={addVariation}>
              Add Variation
            </GenericButton>
          </div>
          <Row className="mb-3">
            <Col xs={12}>
              <h4 className="text-center">Variations</h4>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="text-center">
                    <tr>
                      <th>&nbsp;</th>
                      {category?.option_types?.map((option, index) => (
                        <th key={index}>{option.name}</th>
                      ))}
                      <th>SKU</th>
                      <th>MRP</th>
                      <th>Selling Price</th>
                      <th>Group Price</th>
                      <th>
                        Images <small>(Click image to remove it)</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedVariations?.map((variation, index) => (
                      <tr key={index}>
                        <td align="middle">
                          <span
                            className="text-danger cursor-pointer"
                            onClick={() => {
                              let tempVariations = [...selectedVariations];
                              tempVariations.splice(index, 1);
                              setSelectedVariations(tempVariations);
                            }}
                          >
                            Delete
                          </span>
                        </td>
                        {category?.option_types?.map((option, index) => (
                          <td key={index}>
                            <select
                              className="form-select"
                              onChange={(e) => {
                                // selectedVariations[index].color = e.target.value;
                                // use option's value as name of object property
                                selectedVariations[index][option] =
                                  e.target.value;
                                setSelectedVariations([...selectedVariations]);
                              }}
                            >
                              {category?.options?.map(
                                (opt, i) =>
                                  opt.type_id === option.id && (
                                    <option key={i} value={opt?.id}>
                                      {opt.name}
                                    </option>
                                  )
                              )}
                            </select>
                          </td>
                        ))}
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              selectedVariations[index].sku = e.target.value;
                              setSelectedVariations([...selectedVariations]);
                            }}
                            value={variation.sku}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              selectedVariations[index].selling_price = Number(
                                e.target.value
                              );
                              setSelectedVariations([...selectedVariations]);
                            }}
                            value={variation.selling_price}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              selectedVariations[index].mrp = Number(
                                e.target.value
                              );
                              setSelectedVariations([...selectedVariations]);
                            }}
                            value={variation.mrp}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              selectedVariations[index].group_price = Number(
                                e.target.value
                              );
                              setSelectedVariations([...selectedVariations]);
                            }}
                            value={variation.group_price}
                          />
                        </td>
                        <td>
                          <div className="mt-0 mb-3 mx-2">
                            <StyledUploadImage
                              className="text-white d-block ml-auto py-2 px-2"
                              onClick={() => hiddenFileInput.current.click()}
                              variant="r-pos-light"
                            >
                              {"Upload Image"}
                            </StyledUploadImage>
                            <input
                              type="file"
                              hidden
                              name="img1"
                              onChange={(event) => {
                                uploadFile(event, index);
                              }}
                              ref={hiddenFileInput}
                            />
                          </div>
                          {variation?.images?.map((img, i) => (
                            <div className="d-inline-block px-2 py-2" key={i}>
                              <img
                                src={imageSrc(img)}
                                alt="bank_proof"
                                width="120px"
                                onClick={(e) => {
                                  let deleted = [...deletedVariationImages];
                                  deleted.push(img);
                                  selectedVariations[index].images.splice(i, 1);
                                  setDeletedVariationImages([...deleted]);
                                  setSelectedVariations([
                                    ...selectedVariations,
                                  ]);
                                }}
                                title="Click to remove image"
                                className="img-thumbnail img-fluid"
                              />
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <div className="my-5">
            <GenericButton type="button" onClick={saveAndContinue}>
              Save
            </GenericButton>
          </div>
        </div>
      )}
      {category?.option_types?.length <= 0 && (
        <div>
          <div className="my-5 text-center">
            No variation available for this product.
          </div>
          <div className="my-5">
            <GenericButton type="button" onClick={Continue}>
              Continue
            </GenericButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Variation;
