import React, {useEffect, useState} from "react";
import {Form, Row, Col, FloatingLabel} from "react-bootstrap";
import {GenericButton} from "../../compoents/Button";
import {useDispatch, useSelector} from "react-redux";
import {Formik, Form as FormikForm} from "formik";
import {OutlinedTextFields} from "../../compoents/Forms2";
import * as Yup from "yup";
import {GENERIC_MESSAGE, TOAST_STYLE} from "../../config";
import ErrorMessage from "../../compoents/ErrorMessage";
import {
  createProductSelector,
  createProductThunk,
} from "../../redux/slices/product";
import {useAuth} from "../../Navigation/Auth/ProvideAuth";
import Loader from "../../compoents/Loader";
import SellerApiService from "../../redux/services/seller-api-service";
import {toast} from "react-toastify";
// import { ProductAddContext } from "./addProduct";

const productDescriptionSchema = Yup.object().shape({
  description: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
  key_product_features: Yup.string().required(
    GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE
  ),
  legal_disclaimer_description: Yup.string().required(
    GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE
  ),
});

const Description = (props) => {
  const {finalData, setFinalData, addProductHandler, setTabActiveKey} = props;
  const createProductState = useSelector(createProductThunk);
  const [product, setProduct] = useState({});
  let auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  //
  const dispatch = useDispatch();
  //
  const {
    createProductLoading,
    createProductSuccess,
    createProductFailure,
    createProductError,
    createProductMessage,
    createProductData,
  } = useSelector(createProductSelector);

  useEffect(() => {
    let product = JSON.parse(localStorage.getItem("product"));
    setProduct(product);
  }, []);

  return (
    <div className="px-5 py-3">
      <Formik
        initialValues={{
          description: product.description,
          key_product_features: product.features,
          legal_disclaimer_description: product.legal,
        }}
        enableReinitialize={true}
        validationSchema={productDescriptionSchema}
        onSubmit={async (values) => {
          let data = {
            ...product,
          };
          data.description = values.description;
          data.features = values.key_product_features;
          data.legal = values.legal_disclaimer_description;
          data.category = product.category.id;

          const res = await sellerApiService.updateProduct(data);
          if (res.data) {
            toast.success("Product updated successfully", TOAST_STYLE);
            const res = await sellerApiService.getProduct(data.id);
            if (res.data) {
              const {product} = res.data;
              localStorage.setItem("product", JSON.stringify(product));
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <FormikForm>
            <Row className="mb-3">
              <Col xs={12}>
                <Form.Group as={Col} controlId="formGridProductDesc">
                  <Form.Label className="" style={{color: "#122736"}}>
                    Product Description
                  </Form.Label>
                  <OutlinedTextFields
                    className="my-2"
                    name="description"
                    // type="text"
                    placeholder="write here..."
                    style={{height: "100px"}}
                    as="textarea"
                    onChange={(e) =>
                      setFieldValue("description", e.target.value)
                    }
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.description}
                  />
                  {touched.description && errors.description && (
                    <ErrorMessage message={errors.description} />
                  )}
                  {/* <FloatingLabel
										controlId="floatingTextarea2"
										label="write here"
									>
										<Form.Control
											as="textarea"
											placeholder="write here..."
											style={{ height: "100px" }}
										/>
									</FloatingLabel> */}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6} xs={12}>
                <Form.Group as={Col} controlId="formGridKPF">
                  <Form.Label className="" style={{color: "#122736"}}>
                    Key Product Features
                  </Form.Label>
                  {/* <Form.Control
										type="text"
										placeholder="write here...."
									/> */}
                  <OutlinedTextFields
                    className="my-2"
                    name="key_product_features"
                    type="text"
                    placeholder="Key Product Features"
                    onChange={(e) =>
                      setFieldValue("key_product_features", e.target.value)
                    }
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.key_product_features}
                  />
                  {touched.key_product_features &&
                    errors.key_product_features && (
                      <ErrorMessage message={errors.key_product_features} />
                    )}
                </Form.Group>
              </Col>
              <Col xs={12} className="my-3">
                <Form.Group as={Col} controlId="formGridLDD">
                  <Form.Label className="" style={{color: "#122736"}}>
                    Legal Disclaimer Description
                  </Form.Label>
                  {/* <FloatingLabel
										controlId="floatingTextarea2"
										label="write here"
									>
										<Form.Control
											as="textarea"
											placeholder="write here..."
											style={{ height: "100px" }}
										/>
									</FloatingLabel> */}
                  <OutlinedTextFields
                    className="my-2"
                    name="legal_disclaimer_description"
                    type="text"
                    style={{height: "100px"}}
                    placeholder="write here..."
                    as="textarea"
                    onChange={(e) =>
                      setFieldValue(
                        "legal_disclaimer_description",
                        e.target.value
                      )
                    }
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.legal_disclaimer_description}
                  />
                  {touched.legal_disclaimer_description &&
                    errors.legal_disclaimer_description && (
                      <ErrorMessage
                        message={errors.legal_disclaimer_description}
                      />
                    )}
                </Form.Group>
              </Col>
            </Row>
            <div className="mt-5 text-center">
              <GenericButton type="submit">Save</GenericButton>
            </div>
            {/*<div className="mt-5 text-center">*/}
            {/*  <GenericButton type="submit" disabled={createProductLoading}>*/}
            {/*    {createProductLoading === true ? (*/}
            {/*      <>*/}
            {/*        Loading....*/}
            {/*        <Loader />*/}
            {/*      </>*/}
            {/*    ) : (*/}
            {/*      "Add Product"*/}
            {/*    )}*/}
            {/*  </GenericButton>*/}
            {/*</div>*/}
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default Description;
