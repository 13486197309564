import React from "react";
import styled from "styled-components";
import StyledBox from "../../../compoents/Box";
import { BreadCrumbContainer } from "./../AllFaq";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GenericButton } from "../../../compoents/Button";
import TicketSidebar from "./TicketSidebar";
import { useState } from "react";

const StyledView = styled.div`
  p {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
  min-height: 90vh;
  overflow: auto;
  max-height: 90vh;
  position: relative;
  gap: 12px;
  .heading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .breadcrum__container {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 12px 0 0;
    .breadcrumb {
      margin: 0;
    }
  }
  .description_container {
    font-size: 14px;
  }
  .faq__raiseSection {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .faq__raiseText {
      margin-top: 8px;
      font-weight: 600;
      font-size: 16px;
    }
    .btn {
      width: fit-content;
    }
    .generic__modalTittle {
      color: red;
    }
  }
`;

function FaqView({ data }) {
  const history = useHistory();
  const [raiseTicket, setRaiseTicket] = useState(false);
  return (
    <>
      <StyledBox>
        <StyledView>
          <BreadCrumbContainer className="breadcrum__container">
            <Breadcrumb>
              <Breadcrumb.Item
                href="#"
                onClick={() => history.push("/support")}
              >
                Support
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data?.parent_name}</Breadcrumb.Item>
              <Breadcrumb.Item active>{data?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </BreadCrumbContainer>
          <div className="description_container">
            <div className="heading text-decoration-underline">{data.name}</div>
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
            <hr className="m-0 my-1 p-0" />
            <div className="faq__raiseSection">
              <p className="faq__raiseText">Issue not resolved?</p>
              <GenericButton
                onClick={() => {
                  setRaiseTicket(true);
                }}
              >
                Raise a Ticket
              </GenericButton>
            </div>
          </div>
        </StyledView>
      </StyledBox>
      {raiseTicket && (
        <TicketSidebar
          issueName={data.name}
          issueId={data.id}
          onClose={() => {
            setRaiseTicket(false);
          }}
        />
      )}
    </>
  );
}

export default FaqView;
