/** Request action type dispatch to reducer */
function request(actionType) {
  return {
    type: actionType,
  };
}
function success(actionType, response) {
  return {
    type: actionType,
    payload: response,
  };
}
function failure(actionType, error) {
  return {
    type: actionType,
    payload: error,
  };
}
/** Action types of user requests */
/** Action types of user requests */

const ACCOUNT = {
  CREATE_ACCOUNT_REQUEST: "CREATE_ACCOUNT_REQUEST",
  CREATE_ACCOUNT_SUCCESS: "CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_FAILURE: "CREATE_ACCOUNT_FAILURE",
};

export { request, success, failure, ACCOUNT };
