import React from "react";
import { FcAdvertising } from "react-icons/fc";
import styled from "styled-components";
import { GenericButton } from "../../compoents/Button";

const CampaignHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding-bottom: 8px;
  .campaignHeader_root {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .campaignHeader_headingDiv {
      display: flex;
      flex-direction: column;
      .campaignHeader_heading {
        font-size: 24px;
        font-weight: 600;
        border-bottom: 2px solid grey;
      }
      .campaignHeader_subHeading {
        font-size: 16px;
      }
    }
  }
`;

function CampaignHeader({ hideBtn = false, startCampaign }) {
  return (
    <CampaignHeaderStyled>
      <div className="campaignHeader_root">
        <FcAdvertising size={48} />
        <div className="campaignHeader_headingDiv">
          <div className="campaignHeader_heading">Advertisement Section</div>
          <div className="campaignHeader_subHeading">
            Grow Your Bussiness By Advertising
          </div>
        </div>
      </div>
      {!hideBtn && (
        <GenericButton variant="primary" onClick={() => startCampaign()}>
          Start Campaign
        </GenericButton>
      )}
    </CampaignHeaderStyled>
  );
}

export default CampaignHeader;
