import Dashboard from "./Dashboard/index";
import Contacts from "./Contact/index";
import Orders from "./Orders/index";
import ReturnRtoOrders from "./ReturnRtoOrders/index";
import Inventory from "./Inventory/index";
import Payments from "./Payment/index";
import PaymentViewMore from "./Payment/ViewMore";
import ReviewAndRating from "./ReviewsAndRating/index";
import Settings from "./Settings/index";
import Notice from "./Notice/index";
import SingleProductView from "./ReviewsAndRating/single-product-view";
import AddProduct from "./Product/addProduct";
import AddProductV2 from "./ProductV2";
import Login from "./Authentication/Login";
import Support from "./Support";
import ViewFaq from "./Support/ViewFaq";
import TicketView from "./Support/TicketView";
import OrderReturnDetail from "./ReturnRtoOrders/OrderReturnDetail";
import EditInventoryProduct from "./Inventory/EditInventoryProduct";
import InventoryProductDetail from "./Inventory/InventoryProductDetail";
import Seller from "./Authentication/Seller";
import Landing from "./Authentication/Landing";
import Register from "./Authentication/Register";
import OrderDetail from "./Orders/OrderDetail";
import AboutUs from "./AboutUs";
import EditProduct from "./Inventory/EditProduct";
import PrivacyPolicy from "./PrivacyPolicy";
import Refund from "./Refund";
import TermsConditions from "./TermsAndConditions";
import Catalogue from "./Catalogue";
import Brands from "./Brands";
import ViewBrand from "./Brands/BrandView";
import Pricing from "./Authentication/Pricing/Pricing";
import Delivery from "./Authentication/Delivery/Delivery";
import Notifications from "./Notifications/notifications";
import Gallery from "./Gallery";
import ContactUs from "./ContactUs";
// Dashboard Policies
import AntiPhishingPolicy from "./Policies/AntiPhishingPolicy";
import AdditionalSellerPolicies from "./Policies/AdditionalSellerPolicies";
import CourierPrtnerClaims from "./Policies/CourierPrtnerClaims";
import IntellectualPropertyPolicy from "./Policies/IntellectualPropertyPolicy";
import PrivacyPolicies from "./Policies/PrivacyPolicies";
import ProhibitedAndResProduct from "./Policies/ProhibitedAndResProduct";
import SellerAgreement from "./Policies/SellerAgreement";
import SellerDeactivationPolicy from "./Policies/SellerDeactivationPolicy";
import SellerReferralPolicy from "./Policies/SellerReferralPolicy";
import TermsAndCondition from "./Policies/TermsAndCondition";
import WhistleBrowserPolicy from "./Policies/WhistleBrowserPolicy";
import Assetlinks from "../.well-known/Assetslinks";
import Campaign from "./Campaign";

const WEB_PAGES = {
  LANDING_PAGE: Landing,
  LOGIN: Login,
  REGISTER: Register,
  SELLER_PROFILE: Seller,
  DASHBOARD: Dashboard,
  CATALOGUE: Catalogue,
  BRANDS: Brands,
  VIEWBRAND: ViewBrand,
  CONTACTS: Contacts,
  ADD_PRODUCT: AddProduct,
  ADD_PRODUCT_V2: AddProductV2,
  EDIT_PRODUCT: EditProduct,
  ORDER: Orders,
  RETURN_RTO_ORDERS: ReturnRtoOrders,
  INVENTORY: Inventory,
  PAYMENT: Payments,
  PAYMENT_VIEW_MORE: PaymentViewMore,
  REVIEW_AND_RATING: ReviewAndRating,
  REVIEW_AND_RATING_SINGLE_PRODUCT: SingleProductView,
  SETTINGS: Settings,
  NOTICE: Notice,
  SUPPORT: Support,
  TICKET_VIEW: TicketView,
  VIEW_SUPPORT: ViewFaq,
  RETURN_PRODUCT_DETAIL: OrderReturnDetail,
  RETURN_ORDER_DETAIL: OrderReturnDetail,
  EDIT_INVENTORY_PRODUCT: EditInventoryProduct,
  INVENTORY_PRODUCT_DETAIL: InventoryProductDetail,
  ORDER_DETAIL: OrderDetail,
  ABOUT_US: AboutUs,
  CONTACT_US: ContactUs,
  NOTIFICATIONS: Notifications,

  TERM_AND_CONDITION: TermsConditions,
  REFUND: Refund,
  PRIVACY_POLICY: PrivacyPolicy,

  PRICING: Pricing,
  DELIVERY: Delivery,
  GALLERY: Gallery,
  // Dashboard Policies
  ANTI_PHISHING_POLICY: AntiPhishingPolicy,
  PRIVACY_POLICIES: PrivacyPolicies,
  INTELLECTUAL_PROPERTY_POLICY: IntellectualPropertyPolicy,
  SUPLIER_AGREEMENT: SellerAgreement,
  SUPLIER_DEACTIVATION: SellerDeactivationPolicy,
  PROHABITIED_AND_RESTRICTED_POLICY: ProhibitedAndResProduct,
  TERMS_CONDITION: TermsAndCondition,
  ADDITIONAL_SELLER_POLICIES: AdditionalSellerPolicies,
  SELLER_REFERRAL_PROGRAM: SellerReferralPolicy,
  WHISTLE_BLOWSER_POLICY: WhistleBrowserPolicy,
  COURIER_PARTNER_CLAIMS: CourierPrtnerClaims,
  WELL_KNOWN: Assetlinks,
  CAMPAIGN: Campaign,
};

export { WEB_PAGES };
