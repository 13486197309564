import React from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import accountService from "../../../redux/services/account.service";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { useDispatch } from "react-redux";
import { updateLoggedIn } from "../../../redux/slices/account";
import { useState } from "react";
import Loader from "../../../compoents/Loader";
import { ActiveServiceStyled } from "./paidServiceStyled";
import { AiOutlineCheckCircle } from "react-icons/ai";
import moment from "moment/moment";

function ActiveService(props) {
  const { plan_id = null } = props;
  const [loading, setLoading] = useState(true);
  const [activePlan, setActivePlan] = useState({});
  const auth = useAuth();
  const dispatch = useDispatch();

  const fetchActivePlan = async (id) => {
    try {
      const res = await accountService.fetch_active_plan(auth, id);
      const { data } = res;
      setLoading(false);
      if (data?.status === "error") {
        toast.error(data?.msg);
        return;
      }
      if (data?.code === 1001) {
        dispatch(updateLoggedIn({ active_paid_service: null }));
        toast.error(data?.msg);
        return;
      }
      setActivePlan(data?.data);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchActivePlan(plan_id);
  }, []);

  if (loading)
    return (
      <div className="m-auto">
        <Loader />
        <Loader />
        <Loader />
      </div>
    );

  return (
    <ActiveServiceStyled>
      <div className="activePlan__content">
        <div className="activePlan__label">
          {activePlan?.plan_details?.is_day_limit && <div>Validity:</div>}
          <div>Started at:</div>
          {activePlan?.plan_details?.is_day_limit && <div>Expires at:</div>}
          <div>Product Count:</div>
        </div>
        <div>
          {activePlan?.plan_details?.is_day_limit && (
            <div>{activePlan?.validity_in_days} days</div>
          )}
          <div>{moment(activePlan?.start_date).format("DD-MM-YYYY")}</div>
          {activePlan?.plan_details?.is_day_limit && (
            <div>{moment(activePlan?.end_date).format("DD-MM-YYYY")}</div>
          )}
          <div>{activePlan?.product_count}</div>
        </div>
      </div>
      <div className="activePlan__container">
        <hr className="divider" />
        <div className="activePlan__main">
          <h3 className="activePlan__heading">
            {activePlan?.plan_details?.plan_name}
          </h3>
          <ul className="activePlan__points">
            <div className="mb-2">Included Features:</div>
            {activePlan?.plan_details?.description?.map((point, i) => (
              <li className="activePlan__point" key={`point-${i}`}>
                <AiOutlineCheckCircle className="paidList__checkIcon" />
                {point}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ActiveServiceStyled>
  );
}

export default ActiveService;
