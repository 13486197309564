import React, { useEffect, useState } from "react";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import OrderTable from "./OrderTable";
import CustomLoader from "../../compoents/common/CustomLoader";

const Cancelled = (props) => {
  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [cancelled, setCancelled] = useState([]);
  const [fetching, setFetching] = useState(true);

  const fetchData = async () => {
    try {
      const res = await sellerApiService.getCancelledOrders();
      if (res.data) {
        const { orders } = res.data;
        console.log(orders);
        setCancelled(orders);
      }
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.log("Error@cancelled", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {fetching && <CustomLoader />}
      <OrderTable data={cancelled} orderStatus="cancelled" />
    </>
  );
};

export default Cancelled;
