import React, { useState } from "react";
import Header from "./header";
import { IMAGES } from "../config";

import SideBar from "./sidebar.js";
import { VscThreeBars } from "react-icons/vsc";

const Layout = (props) => {
  let { children } = props;

  const [show, setShow] = useState(false);
  const toggleSidebar = () => {
    setShow((prevState) => !prevState);
  };
  return (
    <div>
      <div>
        <SideBar show={show} setShow={setShow} />
        <div className="wrapper-div side-bar-on">
          {/* <Header show={show} setShow={toggleSidebar} /> */}
          <div className="d-flex align-items-center ms-4 mb-4 d-lg-none d-block">
            {!show && (
              <div className="header-left me-3  ">
                <a href="/" className="logo">
                  <img
                    src={IMAGES.COMPANY_LOGO}
                    alt="fund-raiser-logo"
                    style={{ height: "60px" }}
                  />
                </a>
              </div>
            )}
            {!show && (
              <VscThreeBars
                size={30}
                className="d-lg-none d-block cursor-pointer ms-auto me-4"
                onClick={toggleSidebar}
              />
            )}
          </div>

          <div className="layout-content-child">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
