import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
import { useReactToPrint } from "react-to-print";

const ProductCardButtons = ({
  status,
  orderId,
  disabledBtnList = undefined,
}) => {
  const auth = useAuth();
  const history = useHistory();
  const shipmentLabelRef = useRef(null);
  const [shipmentLabel, setShipmentLabel] = useState(null);

  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const confirmOrder = async () => {
    const res = await sellerApiService.confirmOrder(orderId);
    if (res.status === 200 && res.data.status) {
      toast.success("Product confirmed successfully.");
      history.push("/");
    }
  };
  const cancelOrder = async () => {
    const res = await sellerApiService.cancelOrder(orderId);
    if (res.status === 200 && res.data.status) {
      toast.success("Product cancelled successfully.");
      history.push("/");
    }
  };
  const handleLabelDownload = async () => {
    const res = await sellerApiService.getShipmentLable(orderId);

    if (res.status === 200) {
      // history.push("/");
      const data = res.data;
      if (data) {
        setShipmentLabel(data);
        setTimeout(() => {
          handlePrint();
        }, 100);
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: () => shipmentLabelRef.current,
  });

  if (status === "PLACED") {
    return (
      <>
        <button
          className=" text-white btn rounded-0 d-flex justify-content-center align-items-center"
          style={{
            width: "35%",
            padding: "1rem 0",
            fontSize: "17px",
            backgroundColor: "#109D1B",
            height: "40px",
          }}
          onClick={confirmOrder}
          disabled={disabledBtnList && disabledBtnList?.acceptBtn}
        >
          ACCEPT ITEM
        </button>
        <button
          className=" text-white btn rounded-0 d-flex justify-content-center align-items-center"
          style={{
            width: "35%",
            padding: "1rem 0",
            fontSize: "17px",
            backgroundColor: "#B10000",
            height: "40px",
          }}
          onClick={cancelOrder}
          disabled={disabledBtnList && disabledBtnList?.cancelBtn}
        >
          CANCEL ITEM
        </button>
      </>
    );
  } else if (status === "CONFIRMED") {
    return (
      <>
        <button
          className=" text-white btn rounded-0 d-flex justify-content-center align-items-center"
          style={{
            width: "35%",
            padding: "1rem 0",
            fontSize: "17px",
            backgroundColor: "#222641",
            height: "40px",
          }}
          onClick={handleLabelDownload}
        >
          Download Label
        </button>
        <button
          className=" text-white btn rounded-0 d-flex justify-content-center align-items-center"
          style={{
            width: "35%",
            padding: "1rem 0",
            fontSize: "17px",
            backgroundColor: "#FF4D00",
            height: "40px",
          }}
        >
          Download Manifest
        </button>
        <div style={{ display: "none" }}>
          {shipmentLabel && (
            <div
              ref={shipmentLabelRef}
              dangerouslySetInnerHTML={{ __html: shipmentLabel }}
            />
          )}
        </div>
      </>
    );
  } else if (status === "SHIPPED" || status === "DISPATCHED") {
    return (
      <button
        className=" text-white btn rounded-0 d-flex justify-content-center align-items-center"
        style={{
          padding: "1rem 0",
          fontSize: "17px",
          backgroundColor: "#397EF5",
          cursor: "default",
          height: "40px",
        }}
      >
        {status}
      </button>
    );
  } else if (status === "DELIVERED") {
    return (
      <>
        <button
          className=" text-white btn rounded-3 d-flex justify-content-center align-items-center"
          style={{
            width: "35%",
            padding: "1rem 0",
            fontSize: "17px",
            backgroundColor: "#109D1B",
            cursor: "default",
            height: "40px",
          }}
        >
          Delivered
        </button>
        {/* <button
          className="btn btn-outline-info rounded-3 d-flex justify-content-center align-items-center"
          style={{
            width: "35%",
            padding: "1rem 0",
            fontSize: "17px",
            color: "#387CF0",
            height: "40px",
          }}
        >
          View Payment Breakout
        </button> */}
      </>
    );
  } else if (status === "CANCELLED") {
    return (
      <button
        className=" text-white btn rounded-0 d-flex justify-content-center align-items-center"
        style={{
          padding: "1rem 0",
          fontSize: "17px",
          backgroundColor: "#D00000",
          cursor: "default",
          height: "40px",
        }}
      >
        Cancelled
      </button>
    );
  }
};

export default ProductCardButtons;
