import React from "react";
import { OrderSliderStyled } from "./OrderSliderStyled";
import OrderSlider from "./OrderSlider";
// import styled from "styled-components";
import { IMAGESV2 } from "../../../../config";
// import SellCategory from "../Slider";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

// const SuccessOrderStyled = styled.div`
// padding: 0 5rem 3rem;
// width: 100%;
// margin: auto;
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
// gap: 24px;
// @media (min-width: 1400px) {
//   .videoTut__content {
//     max-width: 1320px;
//   }
// }
// @media (max-width: 670px) {
//   padding: 0 24px 24px;
//   gap: 16px;
// }
// `;

function SuccessOrder({ lists, title = "Successful Orders" }) {
  const sliderRef = React.useRef(null);

  const orderImg = [
    // {
    //   imgSrc: IMAGESV2.order1,
    //   title: "Kalyan East",
    // },
    {
      imgSrc: IMAGESV2.order2,
      title: "Pune",
      name: "Pranali Karekar",
    },
    {
      imgSrc: IMAGESV2.order3,
      title: "Kolkata",
      name: "Deepa Ghosh",
    },
    {
      imgSrc: IMAGESV2.order4,
      title: "Telangana",
      name: "Sai Krishna",
    },
    {
      imgSrc: IMAGESV2.order5,
      title: "Madhya Pardesh",
      name: "Robin Lodhi",
    },
    // {
    //   imgSrc: IMAGESV2.order6,
    //   title: "Thane",
    // },
    // {
    //   imgSrc: IMAGESV2.order7,
    //   title: "Sanpada",
    // },
  ];
  const scroll = (direction) => {
    const { current } = sliderRef;
    const scrollPixel = 400;
    if (direction === "left") {
      current.scrollLeft -= scrollPixel;
    } else {
      current.scrollLeft += scrollPixel;
    }
  };
  return (
    <OrderSliderStyled>
      <h3 className="slider__heading">{title}</h3>
      <div className="slider__content">
        <OrderSlider list={lists ? lists : orderImg} sliderRef={sliderRef} />
        <div
          role="button"
          onClick={() => scroll("left")}
          className="slider__arrow slider__leftArrow"
        >
          <AiOutlineLeft size={24} className="slider__icon" />
        </div>
        <div
          role="button"
          onClick={() => scroll("right")}
          className="slider__arrow slider__rightArrow"
        >
          <AiOutlineRight size={24} className="slider__icon" />
        </div>
      </div>
    </OrderSliderStyled>
  );
}

// function SuccessOrder() {

// }

export default SuccessOrder;
