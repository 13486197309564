import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GenericButton } from "../../../compoents/Button";
import { IMAGES, IMAGESV2, PATH } from "../../../config";
import HeroImg from "../../../assets/Images/landing-hero-img.png";
import CommissionIcon from "../../../assets/Images/comission.svg";
import GrowthIcon from "../../../assets/Images/growth.svg";
import AccuracyIcon from "../../../assets/Images/accuracy.svg";
import LimitedIcon from "../../../assets/Images/vomited.svg";
import Rewards1Img from "../../../assets/Images/rewards-1.png";
import Rewards2Img from "../../../assets/Images/rewards-2.png";
import PaymentIcon from "../../../assets/Images/payment-benefit.svg";
import SupportIcon from "../../../assets/Images/support.svg";
import PartnershipIcon from "../../../assets/Images/partnership.svg";
import PolicyIcon from "../../../assets/Images/policy-document.svg";
import HappyCloudIcon from "../../../assets/Images/happy-cloud.svg";
import ExcellentIcon from "../../../assets/Images/excellent.svg";
import GroupickConcept from "../../../assets/Images/groupickConcept.png";

import AuthHeader from "../AuthHeader";
import AuthFooter from "../AuthFooter";
import AuthCTA from "../AuthCTA";
import CustomCarousel from "../CustomCarousel";
import HeroSection from "./HeroSection";
import Stats from "./Stats";
import HowToGrow from "./HowToGrow";
import HowItWorks from "./HowItWork";
import SellCategory from "./Slider";
import VideoTutorial from "./VideoTutorial";
import SuccessOrder from "./SuccessOrder";
import SellerTrust from "./SellerTrust/SellerTrust";
import Cta from "./Cta/Cta";
import FooterBanner from "./BottomBanner/FooterBanner";
import Feedback from "./Feedback/CustomerFeedback";
import Partner from "./Partners/Partner";
const benefitList = [
  "Get Group orders",
  "Deliver products on 26000 pin codes",
  "Easy Registration",
  "Simple listing",
  "7-Day Settlement",
  "1500+ Categories",
  "Lowest Shipping Cost",
  "Reseller Join now",
  "Grow your business from anywhere",
  "Introducing New B2C Marketplace",
  "Go online sell pan India",
  "Best seller support on platform",
  "We help you to make your dream into reality",
  "Deliver products on 26000 pin codes",
  "Sell products 24*7",
  "Your own customer is your marketer",
  "Easy seller dashboard",
  "Sell all varieties of products",
  "Reached 3000+ cities in single click",
  "Heavy competition on other marketplaces",
];

const StyledLandingPage = styled.div`
  background-color: white;
  section {
    padding: 3rem 0;
  }
  .moreBenefits {
    padding-top: 0;
  }
  .stats_container {
    min-width: 100%;
    padding: 0 5rem;
    .stats__col {
      .stats-card {
        background-color: #d9d9d9;
        border-radius: 24px;
        height: 100%;
        max-height: max-content;
        h2 {
          color: var(--orange-primary);
          font-weight: 700;
        }
        h5 {
          font-weight: 700;
          font-size: 1.5rem;
        }
      }
    }
    @media (max-width: 670px) {
      padding: 0 4px;
    }
  }
  @media (max-width: 576px) {
    .stats {
      padding: 32px 0;
      .stats-card {
        max-height: 150px;
        min-height: 150px;
      }
    }
  }

  .tagline__container {
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 5.5rem;
    background: linear-gradient(
      90deg,
      rgba(255, 219, 89, 1) 0%,
      rgba(255, 148, 77, 1) 100%
    );
    font-size: 55px;
    display: flex;
    align-items: center;
    font-weight: 700;
    .tagline__text {
      color: #ff4d00;
      margin-left: 1rem;
    }
    @media (max-width: 992px) {
      font-size: 40px;
    }
    @media (max-width: 670px) {
      font-size: 32px;
      padding-left: 1rem;
      .tagline__text {
        margin-left: 0.25rem;
      }
    }
    @media (max-width: 576px) {
      font-size: 24px;
      padding: 18px 1rem;
    }
  }

  .features {
    ul {
      list-style-type: none;
      padding: 0;
    }
    &-icon {
      width: 34px;
      height: auto;
    }
  }
  .rewards-card {
    box-shadow: 0px 1px 11px 4px #f3f3f3;
  }

  .benefits {
    background-color: #fff4ef;
    padding: 24px 0;

    .benefit-card {
      box-shadow: 0px 3px 16px 0px #00000024;
      border-radius: 12px;
      height: 100%;

      &-image {
      }
      .title {
        color: var(--orange-primary);
      }
    }
  }
  .benefitListDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    margin-top: 32px;
    .benefitData {
      height: 85px;
      background: #e0e0e0;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: calc(100% / 6);
      border-radius: 32px;
      padding: 16px;
      text-align: center;
    }
  }
  .groupickConcept {
    row-gap: 24px;
    img {
      max-width: 500px;
      width: 100%;
    }
  }
  .download__appDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 3rem 5rem;
    margin: auto;
    .download__image {
      border-radius: 12px;
      height: 5rem;
    }
    .download__text {
      font-size: 32px;
      line-height: 130%;
      font-weight: 400;
    }
    .download__mobileShow {
      display: none;
    }
    @media (min-width: 1400px) {
      max-width: 1320px;
      margin: auto;
    }
    @media (max-width: 1200px) {
      padding: 3rem;
    }
    @media (max-width: 900px) {
      gap: 16px;
      .download__image {
        flex: 1;
      }
      .download__text {
        font-size: 20px;
        flex: 1;
      }
    }
    @media (max-width: 670px) {
      padding: 24px 3rem;
      .download__image {
        height: 50px;
        // max-width: 35%;
      }
      .download__text {
        text-align: center;
        font-weight: 600;
      }
      .download__mobileHide {
        display: none;
      }
      .download__mobileShow {
        display: block;
      }
    }
  }
  @media (max-width: 670px) {
    .moreBenefits {
      padding: 0 24px 24px;
    }
    .groupickConcept {
      row-gap: 16px;
    }
    .rewards {
      padding: 24px 0;
      .rewardContent {
        padding: 0 24px;
        margin-top: 16px;
      }
    }
    .benefits {
      padding: 24px;
    }
    .sm-txt-center {
      text-align: center;
    }
  }
`;
const Landing = () => {
  let history = useHistory();
  return (
    <StyledLandingPage>
      <AuthHeader activePage="landing" />
      <HeroSection
        heading1="Sell Online on Groupick"
        heading2="India's New B2C Marketplace"
        desc="Become a groupick seller & Experience a new way of selling"
        buttonText="Start Selling"
        rightImgSrc={IMAGESV2.Hero2}
        bgImg={IMAGESV2.Hero1}
        onClick={() => history.push("/auth/register")}
      />
      <Stats />
      <div className="tagline__container">
        Groupick<span className="tagline__text"> par online bechiye...</span>
      </div>
      <Partner />
      <HowToGrow />
      <Cta />
      <HowItWorks />

      {/* <div className="download__appDiv">
        <img
          role="button"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.groupickapp"
            )
          }
          src={IMAGESV2.play_store}
          alt="download_link"
          className="download__image"
        />
        <div className="download__text download__mobileHide">
          Download groupick app to <br /> experience group shopping feature
        </div>
        <div className="download__text download__mobileShow">Download App!</div>
      </div> */}
      <SellCategory />
      <Container>
        <h3 className="fw-bolder sm-txt-center">More categories</h3>
        <p className=" mt-3 fs-3 sm-txt-center">
          Bags, Health, Travel, Gaming, Automobile, Food, Music, Computer,
          Motorcycle,Stationary, Sports, Pets, Home Decor, Luggage
        </p>
      </Container>
      {/* <CustomCarousel /> */}
      <Feedback />
      <section className="moreBenefits">
        <Container>
          <h3 className="text-center fw-bolder">Why Groupick?</h3>
          <Row className="benefitListDiv">
            {benefitList.map((data, i) => (
              <div
                key={`benefits-${i}`}
                className="col-lg-2 col-md-3 col-sm-5 benefitData"
              >
                {data}
              </div>
            ))}
          </Row>
        </Container>
      </section>
      <Container style={{ padding: "25px 24px" }}>
        <h3 className="text-center fw-bolder">The Concept of Group Buy</h3>
        <Row>
          <Col
            sm="12"
            className="text-center d-flex flex-column justify-content-center align-items-center groupickConcept"
          >
            <img src={GroupickConcept} alt="" />
            <div>
              Group buying, also known as collective buying, offers products and
              services at significantly reduced prices on the condition that a
              minimum number of buyers would make the purchase.
            </div>
          </Col>
        </Row>
      </Container>
      <SellerTrust />
      <section className="rewards" style={{ padding: "20px 0 60px 0" }}>
        <Container>
          <h3 className="text-center fw-bolder mb-4">
            Exclusive Seller+ Rewards for the first 30 days
          </h3>
          <Row className="justify-content-sm-around mt-md-5 mt-sm-3 rewardContent">
            <Col sm="6" className="mb-3 mb-sm-0">
              <div className="p-3 rewards-card rounded-3 h-100">
                <img src={Rewards1Img} alt="" className="w-100 rounded" />
                <div className="reward-info px-2 mt-4">
                  <h4 className="sm-txt-center">Free catalog visibility</h4>
                  <p className="sm-txt-center">
                    Helps to list your catalogs to increase the visibility of
                    your products and get more orders
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="p-3 rounded-3  rewards-card h-100">
                <img src={Rewards2Img} alt="" className="w-100 rounded" />
                <div className="reward-info px-2 mt-4">
                  <h4 className="sm-txt-center">Dedicated Catalog Manager</h4>
                  <p className="sm-txt-center">
                    Clear all your cataloging doubts like how to upload
                    catalogs, correct quality check errors and more
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="benefits">
        <Container style={{ padding: "24px 24px 40px 24px" }}>
          <h3 className="text-center fw-bolder">Benefits with Groupick</h3>
          <Row className="mt-0 gy-3 gy-sm-5 sm-txt-center">
            <Col sm="6" md="4">
              <div className="benefit-card p-4 bg-white">
                <img src={PaymentIcon} alt="" className="benefit-card-image" />
                <h4 className="title fw-bolder mt-3">
                  Timely and Transparent Payouts
                </h4>
                <p className="mt-3 desc">
                  We promise you timely payouts with complete transparency.
                </p>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="benefit-card p-4 bg-white">
                <img src={SupportIcon} alt="" className="benefit-card-image" />
                <h4 className="title fw-bolder mt-3">Logistics Support</h4>
                <p className="mt-3 desc">
                  We provide complete logistic support through the best courier
                  partners.
                </p>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="benefit-card p-4 bg-white">
                <img
                  src={PartnershipIcon}
                  alt=""
                  className="benefit-card-image"
                />
                <h4 className="title fw-bolder mt-3">Long Term Partnerships</h4>
                <p className="mt-3 desc">
                  We share all possible insights to help you make better
                  business decisions.
                </p>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="benefit-card p-4 bg-white">
                <img src={PolicyIcon} alt="" className="benefit-card-image" />
                <h4 className="title fw-bolder mt-3">Fair Seller Policies</h4>
                <p className="mt-3 desc">
                  Our sellerr policy has no hidden or unreasonable clauses or
                  expectations.
                </p>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="benefit-card p-4 bg-white">
                <img
                  src={HappyCloudIcon}
                  alt=""
                  className="benefit-card-image"
                />
                <h4 className="title fw-bolder mt-3">
                  No special product shoots needed
                </h4>
                <p className="mt-3 desc">
                  We prefer flat shoots, so you do not have to worry about fancy
                  product shoots.
                </p>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="benefit-card p-4 bg-white">
                <img
                  src={ExcellentIcon}
                  alt=""
                  className="benefit-card-image"
                />
                <h4 className="title fw-bolder mt-3">
                  Excellent Seller Support{" "}
                </h4>
                <p className="mt-3 desc">
                  A dedicated team will be always available to solve issues, if
                  you have any.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <VideoTutorial />
      <SuccessOrder />

      {/* features section */}
      {/* <section className="features">
        <Container>
          <Row className="w-100 align-items-lg-center justify-content-lg-between mx-0">
            <Col sm="12" lg="5">
              <h2>Why Sellers Love Groupick?</h2>
              <p className="text-muted">
                All the benefits of selling on Groupick are designed to help you
                sell more and make it easier to grow your business.
              </p>
            </Col>
            <Col sm="12" lg="6">
              <div className="rounded-3 p-4 border">
                <article className="d-flex pt-4 pb-2 border-bottom">
                  <span>
                    <img src={GrowthIcon} alt="" className="features-icon" />
                  </span>
                  <span className="ms-4">
                    <h4>Growth for Every Seller</h4>
                    <p className="text-muted">
                      From small to large and branded to generic, all Sellers
                      have grown their businesses on Groupick
                    </p>
                  </span>
                </article>
                <article className="d-flex pt-4 pb-2 border-bottom">
                  <span>
                    <img src={AccuracyIcon} alt="" className="features-icon" />
                  </span>
                  <span className="ms-4">
                    <h4>Ease of Doing Business</h4>
                    <p className="text-muted">
                      <ul>
                        <li>Easy Product Listing</li>
                        <li>Lowest Cost Shipping</li>
                        <li>7-Day Payment Cycle</li>
                      </ul>
                    </p>
                  </span>
                </article>
                <article className="d-flex pt-4 pb-2">
                  <span>
                    <img src={LimitedIcon} alt="" className="features-icon" />
                  </span>
                  <span className="ms-4">
                    <h4>Entry to Limited Sellers</h4>
                    <p className="text-muted">
                      Sellers need to have an account on another platform before
                      registering on Groupick because Groupick wants to assure
                      quality products & good service to our customers.
                    </p>
                  </span>
                </article>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <AuthCTA />
      <FooterBanner />
      <AuthFooter />

      <div className="text-center border-top-1 mx-5 py-4 copy-right-footer">
        <h4>@ 2023 Getzie Technology Pvt Ltd. All Right Reserved.</h4>
      </div>
    </StyledLandingPage>
  );
};

export default Landing;
