import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const ProhibitedAndResProduct = () => {
  return (
    <>
      <Container>
        <StyledBreadCrumb>
          <StyledBreadCrumb.Item active href="#">
            Dashboard
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Settings
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Legal & policies
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Prohabitied and Restricted Product Policy
          </StyledBreadCrumb.Item>
        </StyledBreadCrumb>
        <h1 className="mt-4 mb-4">PROHIBITED AND RESTRICTED PRODUCTS POLICY</h1>

        <p>
          This Prohibited and Restricted Products Policy (“Policy”) is an
          electronic record published in accordance with the provisions of
          Information Technology Act, 2006 and Information Technology
          (Intermediaries Guidelines) Rules, 2011. This Policy shall be read in
          connection with the Supplier Agreement and Supplier Terms and
          Conditions.
        </p>

        <p>
          The domain name https://Groupick.in (“Website”) and any related mobile
          or software applications (“Application”) (hereinafter collectively
          referred to as “Platform”) are owned and operated by Getzie Technology
          Private Limited (including its directors, full-time employees,
          subsidiaries, and affiliates, collectively, the “Company”), a company
          incorporated under the Companies Act, 2013, with its registered office
          at Sairaj COmple, A/204, Sanpada Sector 01, Navi Mumbai, Maharastra -
          400705.
        </p>

        <p>
          Platform is an online marketplace where registered suppliers
          (“Suppliers”) can offer to sell their products (“Products”) to
          registered users of the Platform. Supplier(s) may use the Application
          only for the sale of Products which are, inter alia, legitimately
          procured/produced/sourced. Supplier shall not use the Application for
          selling or otherwise dealing with any product/goods/commodity whose
          sale or otherwise dealing with, is either regulated or prohibited or
          in contravention of any law in force in India, including but not
          limited to:
        </p>

        <ol>
          <li>
            Drugs and Narcotics or Psychotropic substances: Supplier shall not
            be permitted to list or sell any drug, medicine, psychotropic
            substances, narcotics or derivatives thereof in contravention of the
            Applicable Law including but not limited to the Drugs and Cosmetics
            Act, 1940 and rules thereof. Drugs shall refer to the meaning
            prescribed under Drugs and Cosmetics Act, 1940 including but not
            limited to Ayurvedic, Unnani or Siddhi drugs.
          </li>
          <li>
            Sale of any prescribed drugs or medicines in contravention of the
            Applicable Law and/or without any applicable license/approvals from
            the relevant authority for such sale.
          </li>
          <li>
            Sale of Products which may infringe the intellectual property rights
            of third parties.
          </li>
          <li>
            Pre-packaged Products not packed in accordance with the Legal
            Metrology Act, 2009 and rules thereof.
          </li>
          <li>Sale of Products in contravention of BIS standards.</li>
          <li>Sale of spurious Products.</li>
          <li>Sale of replica & counterfeit Products.</li>
          <li>Sale of pornographic or explicit material/contents.</li>
          <li>
            Sale of any adult Products which are obscene or any Product having
            obscene display/representation on its package, which are not legally
            allowed for sale, use or consumption.
          </li>
          <li>
            Sale of promotional samples, free of cost (FOC), not for retail sale
            Products or freebies.
          </li>
          <li>
            Sale of prohibited or restricted Products in contravention of Import
            Policy notified by DGFT.
          </li>
          <li>
            Tobacco, tobacco Products or any Product containing nicotine and
            cigarettes including e-cigarette.
          </li>
          <li>Adulterated or rotten produce.</li>
          <li>
            Animal Products or hides/skins/teeth, nails and other parts etc. of
            animals which are illegal for sale or trade.
          </li>
          <li>Sale of Liquor or Alcoholic beverages.</li>
          <li>Products procured by indulgence in illegal activities.</li>
          <li>Products hazardous to the health or safety of human beings.</li>
          <li>Sale of any pyrotechnic device, explosives or fireworks.</li>
          <li>Sale of any firearms, ammunition or weapons.</li>
          <li>
            Sale of currencies including any old or discontinued currency.
          </li>
          <li>Sale of securities including stocks, bonds, mutual funds.</li>
          <li>
            Products containing any information to; a) defame or slander any
            person or groups of people based on race, ethnicity, national
            origin, religion, sex, or other factors; b) encourage or incite
            violent acts or c) promote intolerance or hatred.
          </li>
          <li>
            Products not conforming to standards and regulations prescribed by
            the Food Safety and Standards Authority of India or banned by the
            relevant authorities for human consumption.
          </li>
          <li>
            Specified Plants protected under the Wildlife (Protection) Act,
            1972.
          </li>
          <li>
            Sale of cardamom not in accordance with the Spice Board Act, 1987.
          </li>
          <li>
            Products ripened by use of carbide gas or Products not fit for human
            consumption.
          </li>
          <li>Sale of tea in contravention of the Tea Act, 1953.</li>
          <li>
            Sale of produce bearing false grade prescription or counterfeiting
            such mark in violation of the Agricultural Produce (Grading and
            Marking) Act, 1937 and rules made thereunder.
          </li>
          <li>
            Products imported or transported in contravention of the Destructive
            Insects and Pests Act, 1914 and the Plant Quarantine Order 2015.
          </li>
          <li>
            Products released by government warehouses for sale by fair price
            shops under the Public Distribution System Order 2001 and Targeted
            distribution system order 2015.
          </li>
          <li>
            Products imported or sold in contravention of any procedure
            established under law.
          </li>
        </ol>

        <p>
          The above list is inclusive and merely indicative in nature and
          accordingly, such Products that are not listed above but either
          regulated or prohibited or in contravention with any law in force in
          India shall by implication be part of the above list, though not
          specifically listed.
        </p>

        <p>
          In case it comes to the attention of Groupick that any Supplier is
          either selling/listing or attempting to do so on the Platform,
          Groupick may terminate the agreement with such Supplier with immediate
          effect including forthwith suspending access to the Platform by such
          Supplier, without the requirement of any notice, and, at its
          discretion, report such incident to the concerned authority, if any.
        </p>
      </Container>
    </>
  );
};

export default ProhibitedAndResProduct;
