import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { GenericButton } from "../../../compoents/Button";
import { IMAGES, IMAGESV2, PATH } from "../../../config";
import HeroImg from "../../../assets/Images/pricing-hero-img.png";

import CalendarIcon from "../../../assets/Images/calendar.svg";
import ProtectIcon from "../../../assets/Images/protect.svg";
import VotingIcon from "../../../assets/Images/voting.svg";
import ListingIcon from "../../../assets/Images/listing.png";
import CollectionIcon from "../../../assets/Images/collection.svg";

import no_cancel_fee from "../../../assets/Images/no_cancel_fee.svg";
import no_fixed_fee from "../../../assets/Images/no_fixed_fee.svg";
import no_hidden_fee from "../../../assets/Images/no_hidden_fee.png";
import tenPercent from "../../../assets/Images/10percent.png";
import sevenDays from "../../../assets/Images/7days.png";

import AuthHeader from "../AuthHeader";
import AuthFooter from "../AuthFooter";
import AuthCTA from "../AuthCTA";
import PricingHeroSection from "./PricingHeroSection";
import PriceCalculator from "./PriceCalculator";
import { TiTick } from "react-icons/ti";
import { MdClose } from "react-icons/md";
// import MemberShip from "./PricingHeroSection/MemberShip/MemberShip";

// const HeroSection = styled.div`
//   background-image: url(${IMAGES.HERO_BG_GRADIENT});
//   background-position: top;
//   background-repeat: no-repeat;
//   background-size: cover;
//   overflow: hidden;
//   padding: 5rem 0;
//   margin-top: 96px;
//   height: 100%;

//   .hero-image {
//     transform: scale(0.7);

//     position: absolute;
//     bottom: -19%;
//     right: -9%;
//     max-width: 700px;

//     @media (max-width: 992px) {
//       bottom: -10%;
//       right: -12%;
//     }
//     @media (max-width: 500px) {
//       bottom: -6%;
//       right: -5%;
//     }
//   }

//   .hero-title-box {
//     width: 60%;
//     @media (max-width: 991px) {
//       margin-bottom: 15rem;
//       width: 80%;
//     }
//     @media (max-width: 500px) {
//       margin-bottom: 9rem;
//     }
//   }
// `;

const StyledPricingPage = styled.div`
  background-color: white;
  section {
    padding: 3rem 5rem;
  }
  .features {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .features__heading {
      margin-bottom: 32px;
    }
    .container {
      padding: 0;
      margin: 0 auto;
      .features__fees {
        gap: 24px;
        margin-bottom: 32px;
      }
    }
  }

  .feature-card {
    box-shadow: 0px 1px 11px 4px #f3f3f3;
    max-width: 400px;

    img {
      width: 40px;
    }
  }
  .payment {
    box-shadow: 0px 1px 11px 4px #f3f3f3;

    img {
      width: 40px;
    }
  }
  .payment-cal {
    display: flex;
    justify-content: space-around;
    background: #fafafa;
    padding: 24px;
    .calc-variable {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      text-align: center;
      max-width: 180px;
      row-gap: 10px;
      & > span:first-child {
        font-size: 14px;
        background: white;
        padding: 8px 10px;
        font-weight: 700;
        border-radius: 8px;
        box-shadow: 0px 1px 11px 4px #f3f3f3;
      }
      .desc {
        font-size: 12px;
      }
      .info {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        margin-left: 5px;
        padding-top: 2px;
      }
    }
  }
  .notes {
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    text-align: center;
    font-weight: bold;

    span {
      img {
        width: 30px;
        height: 30px;
        object-fit: fill;
        margin: 0 10px 0 16px;
      }
    }
  }

  .groupickPrice__main {
    padding: 0 5rem 3rem;
    max-width: 1320px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .groupickPrice__img {
      flex: 1;
      transform: translateX(5%);
      width: 80%;
      max-width: 800px;
    }
  }
  .groupickCommission_main {
    width: 100%;
    .groupickCommission_img {
      width: 100%;
      height: 100%;
    }
  }
  .groupickPayout__main {
    width: 100%;
    height: calc(100vh - 96px);
    position: relative;
    padding: 3rem 5rem;
    background: linear-gradient(
      90deg,
      rgba(90, 220, 228, 1) 0%,
      rgba(0, 74, 173, 1) 100%
    );
    .groupickPayout__content {
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 32px;
      justify-content: center;
    }
    .groupickPayout__img {
      position: absolute;
      bottom: 0;
      right: 0;
      aspect-ratio: 1/1;
      height: 75%;
      width: 50%;
      z-index: 1;
    }
    .groupickPayout__heading {
      width: 100%;
      font-weight: 700;
      line-height: 130%;
      font-size: 48px;
      margin: 0;
      color: #fff;
      z-index: 2;
      .groupickPayout__heading--payoutDay {
        color: #000;
      }
    }
    .groupickPayout__joinBtn {
      background: #fff;
      border-color: #fff;
      color: #000;
      font-weight: 700;
      font-size: 18px;
      z-index: 2;
      &:hover {
        background: none;
        border: 2px solid #fff;
      }
    }
    .groupickPayout__desc {
      width: 100%;
      font-size: 24px;
      font-weight: 500;
      text-align: left;
      margin: 0;
      color: #fff;
      z-index: 2;
    }
  }
  .groupickPolicy__main {
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .groupickPolicy__points {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
    }

    .txt-center {
      text-align: center;
    }
    .main-container {
      display: flex;
      gap: 5%;
      justify-content: space-between;
      margin-top: 30px;
    }
    .left {
      width: 50%;
    }
    .policy_items {
      font-size: 1.5rem;
      font-weight: 400;
    }
    .right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .percent {
      width: 50%;
    }

    .payout-list {
      order: 2;
    }

    .payout-image {
      order: 1;
    }
  }
  .payoutProof__main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    .h {
      margin-top: 30px;
    }
    .payoutProof__content {
      height: fit-content;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      gap: 24px;
      .payoutProof__proof {
        height: auto;
        width: 100%;
        .payoutProof__proofImg {
          width: 100%;
          height: 500px;
        }
        .payoutProof__proofDetail {
          width: 100%;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          line-height: 130%;
        }
      }
    }
  }
  .compare__main {
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    text-align: center;
    h3 {
      margin: 0;
    }
    .compareTable__point {
      max-width: fit-content;
    }
    .compareTable__groupickHead {
      text-align: center;
      background: var(--primary-color);
      color: #fff;
      max-width: fit-content;
    }
    .compareTable__otherHead {
      text-align: center;
      background: rgb(91, 141, 161);
      max-width: fit-content;
    }
    .compareTable__groupick {
      text-align: center;
      background: #ffe5ce;
      .tickIcon {
        color: green;
      }
    }
    .compareTable__other {
      text-align: center;
      background: #cbdbe1;
      .crossIcon {
        color: red;
      }
    }
  }
  .cta {
    background-image: url("../assets/Images/footer-bg.png");
  }
  @media (min-width: 1400px) {
    .features,
    .groupickPrice__main,
    .groupickPayout__main .groupickPayout__content,
    .payoutProof__main,
    .groupickPolicy__main,
    .compare__main {
      max-width: 1320px;
      margin: auto;
    }
  }

  @media (max-height: 670px) {
    .groupickPayout__main {
      height: 100%;
    }
    .payoutProof__main {
      gap: 16px;
      .payoutProof__content {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 670px) {
    h3 {
      text-align: center;
      width: 100%;
    }
    .groupickPayout__main {
      padding: 24px;
    }
    .features {
      padding: 24px;
      text-align: center;
      .features__heading {
        margin-bottom: 24px;
      }
      .container {
        padding: 0;
        margin: auto;
        .features__fees {
          gap: 16px;
          margin-bottom: 24px;
        }
      }
      .payment {
        margin-top: 16px;
      }
    }
    .payment-cal {
      align-items: center;
      gap: 4px;
      max-width: 100%;
      overflow-x: auto;
      justify-content: flex-start;
      align-items: baseline;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .groupickPrice__main {
      padding: 0 24px 24px;
      align-items: flex-start;
      max-height: fit-content;
      .groupickPrice__img {
        width: 100%;
        transform: translateX(0);
      }
    }
    .groupickPayout__main {
      .groupickPayout__content {
        align-items: center;

        .groupickPayout__img {
          height: 75%;
          width: 100%;
          opacity: 0.5;
        }
        .groupickPayout__heading {
          font-size: 24px;
          text-align: center;
        }
        .groupickPayout__desc {
          font-size: 18px;
          text-align: center;
        }
      }
    }
    .groupickPolicy__main {
      padding: 24px;
      gap: 8px;
      .groupickPolicy__points {
        padding: 0 24px;
      }
      .main-container {
        flex-direction: column;
        gap: 30px;
      }
      .left {
        order: 2;
        width: 100%;
        margin-bottom: 20px;
      }
      .policy_items {
        font-size: 22px;
        font-weight: 400;
      }
      .right {
        order: 1;
        width: 100%;
      }
      .percent {
        width: 90%;
      }
    }
    .compare__main {
      padding: 24px;
      gap: 16px;
    }
    .notes {
      flex-direction: column;
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      span {
        img {
          margin: 0 4px 0 0;
        }
      }
    }
    .payoutProof__content {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
`;
const compareList = [
  { point: "Group Shopping", groupick: true, other: false },
  {
    point: "Zero Registeration Charges",
    groupick: true,
    other: false,
  },
  { point: "Easy single/bulk listing", groupick: true, other: false },
  { point: "Entry to only limited sellers", groupick: true, other: false },
  { point: "Only limited/quality products", groupick: true, other: false },
  { point: "Ease of Delivery", groupick: true, other: false },
  { point: "7-Day payment cycle", groupick: true, other: false },
  { point: "1500+ Categories", groupick: true, other: false },
  { point: "Shipping charges on customers", groupick: true, other: false },
  { point: "Ease seller support", groupick: true, other: false },
  { point: "Group order", groupick: true, other: false },
  { point: "Less Inventory", groupick: true, other: false },
  { point: "Genuine Customers", groupick: true, other: false },
  { point: "Helpful for MSMEs", groupick: true, other: false },
  {
    point: "Manufacturer to Customer Model (C2M)",
    groupick: true,
    other: false,
  },
  { point: "Real Reviews", groupick: true, other: false },
  { point: "26000+ Pincode Cover", groupick: true, other: false },
  { point: "Lowest Fees", groupick: "4.72% Only", other: "35%" },
  { point: "No Hidden Charges", groupick: true, other: false },
];
const Pricing = () => {
  let history = useHistory();
  const goToSignUpScreen = () => {
    history.push(PATH.REGISTER);
  };

  return (
    <StyledPricingPage>
      <AuthHeader activePage="pricing & charges" />
      <PricingHeroSection />

      {/* features section */}

      <section className="features">
        <Container>
          <h3 className="text-center fw-bolder features__heading">
            Why Sell on Groupick?
          </h3>

          <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center align-items-sm-stretch features__fees">
            <div className="p-4 rounded-3 feature-card h-auto">
              <img src={VotingIcon} alt="" className="rounded" />
              <div className="reward-info px-2 mt-4">
                <h4>No Registration Fee</h4>
                <p>
                  Registering as a Groupick seller is free - no cost of creating
                  your account or getting your products listed.
                </p>
              </div>
            </div>

            <div className="p-4 rounded-3 feature-card h-auto">
              <img src={CollectionIcon} alt="" className="rounded" />
              <div className="reward-info px-2 mt-4">
                <h4>No Collection Fee</h4>
                <p>
                  You keep 100% of the sale price with no charges on both
                  payment gateway or cash on delivery orders on Groupick.
                </p>
              </div>
            </div>
            <div className="p-4 rounded-3 feature-card h-auto">
              <img src={ListingIcon} alt="" className="rounded" />
              <div className="reward-info px-2 mt-4">
                <h4>No Listing Fee</h4>
                <p>
                  Products listing on the seller panel are totally FREE. You can
                  list as many product catalogs you want.
                </p>
              </div>
            </div>
          </div>
          <div className="p-4 rounded-3 payment h-auto">
            <div className="text-center reward-info px-2 mt-4">
              <h4>How much do you get paid?</h4>
              <p>
                With Groupick, you can sell your products at the lowest
                commission and get your payments on time.
              </p>
            </div>
            <div className="payment-cal">
              <div className="calc-variable">
                <span>Product Price</span>
                <span className="desc">
                  Price you decide to share with Groupick, Do not add shipping
                  charges
                </span>
              </div>{" "}
              <span>-</span>
              <div className="calc-variable">
                <span>Commission Fee</span>
                <span className="desc">
                  Percentage of product price, depends on category
                </span>
              </div>
              <span>-</span>
              <div className="calc-variable">
                <span>GST</span>
                <span className="desc">18% GST on Commission fee</span>
              </div>
              <span>-</span>
              <div className="calc-variable">
                <span className="d-flex align-items-center">
                  TCS <span className="info">i</span>
                </span>
                <span className="desc">1% on total taxable value</span>
              </div>
              <span>-</span>
              <div className="calc-variable">
                <span className="d-flex align-items-center">
                  TDS <span className="info">i</span>
                </span>
                <span className="desc">1% on total</span>
              </div>
              <span>=</span>
              <div className="calc-variable">
                <span>Settlement Amount</span>
                <span className="desc">
                  Deposited to your bank account 7th day of order delivery
                </span>
              </div>
            </div>
            <div className="notes">
              We do not change any other kind of fees to supplier{" "}
              <span>
                <img src={no_fixed_fee} alt="no fixed" />
                No Fixed Fee
              </span>
              <span>
                <img src={no_cancel_fee} alt="no cancel" />
                No Cancellation Fee
              </span>
              <span>
                <img src={no_hidden_fee} alt="no hiden" />
                No Hidden Fees
              </span>
            </div>
          </div>
        </Container>
        <PriceCalculator />
      </section>
      {/*<MemberShip />*/}
      {/* Commission rates */}
      <div className="groupickPolicy__main">
        <h3 className="fw-bolder txt-center">Commission rates</h3>
        <div className="main-container">
          <ul className="groupickPolicy__points left">
            <li className="policy_items">
              Groupick charges a straight 10% commission on sales.
            </li>
            <li className="policy_items">
              This commission rate is for all available categories.
            </li>
            <li className="policy_items">
              The same commission is applicable for all price range products.
            </li>
            <li className="policy_items">
              Other platforms charge 35-40% total commission.
            </li>
          </ul>
          <div className="right">
            <img src={tenPercent} alt="" className="percent" />
          </div>
        </div>
      </div>

      {/* Payout Cycle       */}
      <div className="groupickPolicy__main">
        <h3 className="fw-bolder txt-center">Payout Cycle</h3>
        <div className="main-container">
          <ul className="groupickPolicy__points left payout-list">
            <li className="policy_items">
              Groupick has a 7-day weekly payout cycle.
            </li>
            <li className="policy_items">
              This payout cycle is the same for all available categories.
            </li>
            <li className="policy_items">
              Payout is directly deposited in your registered bank account.
            </li>
          </ul>
          <div className="right payout-image">
            <img src={sevenDays} alt="" className="percent" />
          </div>
        </div>
      </div>

      <div className="groupickPolicy__main">
        <h3 className="fw-bolder">Payment Policy -</h3>
        <ul className="groupickPolicy__points">
          <li className="policy_items">
            We charge a 4% Commission on all categories.
          </li>
          <li className="policy_items">
            The commission is the same for all price ranges.
          </li>
          <li className="policy_items">
            Return to Origin Is <span className="fw-bolder">FREE</span>
          </li>
          <li className="policy_items">
            Return By Customer - Rs. 120 penalty to sellers
          </li>
          <li className="policy_items">
            7-day After delivery + Tuesday = Payout day (Every Tuesday)
          </li>
          <li className="policy_items">Shipping charges add separately.</li>
          <li className="policy_items">
            Packaging & Unboxing video is compulsory for wrong/tempered return
            claims.
          </li>
          <li className="policy_items">
            Penalty applies to wrong product/wrong quantity dispatch, late
            dispatch, canceled order,
          </li>
        </ul>
      </div>

      {/* <div className="groupickPrice__main">
        <h3 className="fw-bolder">What is Group price?</h3>
        <img
          className="groupickPrice__img"
          src={IMAGESV2.groupickPrice}
          alt="groupickPricing"
        />
      </div>

      <div className="groupickCommission_main">
        <img
          className="groupickCommission_img"
          src={IMAGESV2.pricingCommission}
          alt="commissionImg"
        />
      </div> */}

      {/* <div className="groupickPayout__main">
        <div className="groupickPayout__content">
          <img
            className="groupickPayout__img"
            src={IMAGESV2.groupickPayout}
            alt="groupickPayout"
          />
          <h1 className="groupickPayout__heading">
            Weekly Payouts
            <br />
            <span className="groupickPayout__heading--payoutDay">
              7-Day After Delivery
            </span>
            <br />
            Every Tuesday
          </h1>
          <GenericButton
            onClick={goToSignUpScreen}
            className="groupickPayout__joinBtn"
          >
            Join Now!
          </GenericButton>
          <div className="groupickPayout__desc">
            For more payout-related information
            <br /> contact support team.
          </div>
        </div>
      </div> */}

      <div className="payoutProof__main">
        <h3 className="fw-bolder h">Payout Proofs</h3>
        <div className="payoutProof__content">
          <div className="payoutProof__proof">
            <img
              className="payoutProof__proofImg"
              src={IMAGESV2.proof1}
              alt="seller1__proof"
            />
            <div className="payoutProof__proofDetail">Seller 1, Surat</div>
          </div>
          <div className="payoutProof__proof">
            <img
              className="payoutProof__proofImg"
              src={IMAGESV2.proof2}
              alt="seller2__proof"
            />
            <div className="payoutProof__proofDetail">Seller 2, Utarakhand</div>
          </div>
        </div>
      </div>

      <div className="compare__main">
        <h3 className="fw-bolder">Comparison with other marketplace</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th />
              <th className="compareTable__groupickHead">Groupick</th>
              <th className="compareTable__otherHead">Other Marketplaces</th>
            </tr>
          </thead>
          <tbody>
            {compareList?.map((data, i) => (
              <tr key={i}>
                <td className="compareTable__point">{data.point}</td>
                <td className="compareTable__groupick">
                  {typeof data.groupick === "string" ? (
                    <strong>{data.groupick}</strong>
                  ) : (
                    <TiTick className="tickIcon" size={24} />
                  )}
                </td>
                <td className="compareTable__other">
                  {typeof data.groupick === "string" ? (
                    <strong>{data.other}</strong>
                  ) : (
                    <MdClose className="crossIcon" size={24} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AuthCTA />

      <AuthFooter />

      <div className="text-center border-top-1 mx-5 py-4 copy-right-footer">
        <h4>@ 2023 Getzie Technology Pvt Ltd. All Right Reserved.</h4>
      </div>
    </StyledPricingPage>
  );
};

export default Pricing;
