import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import styled from "styled-components";

const StyledPriceCalculator = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  .priceCalci__heading {
    margin: 0 0 8px;
  }
  .priceCalci__main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    overflow-x: auto;
    padding: 8px;
    &::-webkit-scrollbar {
      display: none;
    }
    .priceCalci__groupDiv {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      gap: 8px;
      .priceCalci__title {
        height: 64px;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 130%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--primary-color);
        padding: 8px;
        color: #000;
        line-height: 1.2em;
      }

      .last {
        color: #fff;
        background: #000;
      }

      .priceCalci__value {
        padding: 12px;
        width: 100%;
        text-align: center;
        border: none;
        outline: none;
        background: #ede8d1;
        font-weight: 500;
      }
      .priceCalci__total {
        background: #112735;
        color: #fff;
      }
    }
  }
  @media (max-width: 670px) {
    .priceCalci__main {
      flex-direction: column;
      padding: 0;
      .priceCalci__groupDiv {
        width: 100%;
        flex-direction: row;
        align-items: baseline;
        .priceCalci__title {
          font-size: 18px;
          height: fit-content;
          flex: 2;
          padding: 8px;
        }
        .priceCalci__value {
          flex: 1;
          padding: 8px;
        }
      }
    }
  }
`;

function PriceCalculator() {
  const [price, setPrice] = useState(0);

  const roundAmount = (amount, figure = 2) => amount?.toFixed(figure);
  const calculate = useMemo(() => {
    // charges
    const commissionCharge = 10 / 100; //10% on product price
    const commissionGstCharge = 18 / 100; //18% on commission
    const tcsCharge = 1 / 100; //1% of (netPrice / 1.18)
    const tdsCharge = 1 / 100; //1% of netPrice

    const _price = Number(price);
    const netPrice = _price + 80; //80rs shipping charges

    let tds = 0;
    let tcs = 0;
    let commission = 0;
    let commissionGst = 0;
    if (_price) {
      commission = _price * commissionCharge;
      commissionGst = commission * commissionGstCharge;
      tcs = (netPrice / 1.18) * tcsCharge;
      tds = netPrice * tdsCharge;
    }

    const total = _price - (commission + commissionGst + tds + tcs);
    return {
      total: roundAmount(total),
      tds: roundAmount(tds),
      tcs: roundAmount(tcs),
      commission: roundAmount(commission),
      commissionGst: roundAmount(commissionGst),
    };
  }, [price]);

  return (
    <StyledPriceCalculator>
      <h3 className="fw-bolder priceCalci__heading">Price Calculator :</h3>
      <div className="priceCalci__main" onSubmit={() => {}}>
        <div className="priceCalci__groupDiv">
          <div className="priceCalci__title">Product Price</div>
          <input
            className="priceCalci__value"
            type="number"
            value={price}
            name="price"
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className="priceCalci__groupDiv">
          <div className="priceCalci__title">Commission Fee</div>
          <div className="priceCalci__value">{calculate.commission}</div>
        </div>
        <div className="priceCalci__groupDiv">
          <div className="priceCalci__title">GST on Commission</div>
          <div className="priceCalci__value">{calculate.commissionGst}</div>
        </div>
        <div className="priceCalci__groupDiv">
          <div className="priceCalci__title">TCS</div>
          <div className="priceCalci__value">{calculate.tcs}</div>
        </div>
        <div className="priceCalci__groupDiv">
          <div className="priceCalci__title">TDS</div>
          <div className="priceCalci__value">{calculate.tds}</div>
        </div>
        <div className="priceCalci__groupDiv ">
          <div className="priceCalci__title last">Bank Settlement Amount</div>
          <div className="priceCalci__value priceCalci__total">
            {calculate.total}
          </div>
        </div>
      </div>
    </StyledPriceCalculator>
  );
}

export default PriceCalculator;
