import styled from "styled-components";

const maxInputWidth = "500px";

const BrandFormStyled = styled.div`
  .brandForm__paper {
    border-radius: 12px;
    padding: 16px 24px !important;
  }
  .brandForm__breadcrumb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    width: fit-content;
    margin-bottom: 24px;
    .brandForm__breadcrumbIcon,
    .brandForm__breadcrumbText {
      color: var(--primary-color);
    }
  }
  .brandForm__container {
    .brandForm__gridLayout {
      display: grid;
      grid-template-columns: max-content 1fr;
      gap: 16px;
    }
    .brandForm__label {
      width: 100%;
      text-align: right;
    }
    .brandForm__categoryContainer {
      width: 100%;
      display: flex;
      gap: 4px;
      .brandForm__categoryContent {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 8px 12px;
        max-width: ${maxInputWidth};
        min-height: 40px;

        padding: 0.375rem 0.75rem;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;

        .brandForm__category {
          border: 1px solid;
          border-color: #b4b4b4;
          border-radius: 0.25rem;
          font-size: 16px;
          font-weight: 500;
          padding: 0.25rem;
          line-height: 130%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          .closeIcon {
            margin-top: 1px;
          }
        }
      }
      .category_field_error {
        border: 1px solid #dc3545;
      }
      .brandForm__addIcon {
        color: var(--primary-color);
        transition: rotate 3s ease;
        rotate: 0deg;
      }
      .brandForm__closeIcon {
        rotate: 45deg;
        color: rgb(243, 46, 46);
      }
      .brandForm__saveIcon {
        color: rgb(16, 157, 27);
      }
    }
    .brandForm__inputField {
      max-width: ${maxInputWidth};
    }
    .brandForm__submitBtn {
      grid-column: 2/-1;
    }
    .brandForm__sampleContainer,
    .brandForm__brandLogo,
    .brandForm__proof {
      display: flex;
      flex-wrap: wrap;
      .brandForm__sampleImages {
        objectfit: "cover";
        height: 70px;
        width: 70px;
      }
      .brandForm__sampleImageButton {
        height: 70px;
        width: 70px;
        color: #8ab4f8;
        border: 1px solid #8ab4f8;
        border-radius: 0.25rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
      .sample_image_error {
        border: 1px solid #dc3545;
        color: #dc3545;
      }
    }
    .select_field_error {
      border: 1px solid #dc3545;
    }
  }
`;

const BrandCategoryStyled = styled.div`
  margin-top: 16px;
  .brandCategory__selected {
    color: var(--orange-primary);
  }
  .brandCategory__col {
    max-width: 200px;
    width: fit-content;
  }
  .brandCategory__level {
    width: 100%;
    cursor: pointer;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
  }
`;

export { BrandFormStyled, BrandCategoryStyled };
