import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import {
  GENERIC_MESSAGE,
  IMAGES,
  IMAGESV2,
  PATH,
  TOAST_STYLE,
} from "../../../config";

import { toast } from "react-toastify";
import {
  userSelector,
  updateProfile,
  resetAuth,
} from "../../../redux/slices/account";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { Link, useHistory } from "react-router-dom";
import { Navbar } from "react-bootstrap";

import { StyledContainer, SellerContainer } from "./SellerStyled";
import SellerForm from "./SellerForm";
import SuccessForm from "./SuccessForm";
import { GenericButton } from "../../../compoents/Button";

const Seller = () => {
  let auth = useAuth();
  let dispatch = useDispatch();
  const history = useHistory();

  // const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [cookie, setCookie, removeCookie] = useCookies(["mee_too_user"]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [documentVerified, setDocumentVerified] = useState(false);
  const [bankAccVerified, setBankAccVerified] = useState(false);
  const [formCount, setFormCount] = useState(1);

  const hiddenFileInput = useRef(null);

  const [signature, setSignature] = useState(null);
  const {
    updateProfileSuccess,
    updateProfileFailure,
    verifyOtpMessage,
    verifyOtpError,
    updateProfileMessage,
    userUpdatedProfile,
    userUpdatedProfiletoken,
  } = useSelector(userSelector);

  const handleSuccess = () => {
    removeCookie("mee_too_user");
    dispatch(resetAuth());
    history.push(PATH.LOGIN, { replace: true });
  };

  useEffect(() => {
    if (updateProfileSuccess) {
      let mee_too_user = {
        ...userUpdatedProfile,
        userUpdatedProfiletoken,
      };
      setShowSuccessModal(true);
    }
    if (updateProfileFailure) {
      toast.error(updateProfileMessage, TOAST_STYLE);
    }
  }, [updateProfileSuccess, updateProfileFailure]);

  const AccountSchema = Yup.object().shape({
    company: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    address: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    landmark: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    pincode: Yup.string()
      .min(6)
      .required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    city: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    state: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    // shop_link: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    bank_holder_name: Yup.string().required(
      GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE
    ),
    bank_account_number: Yup.string().required(
      GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE
    ),
    ifsc: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    gst_number: Yup.string(),
    documentType: Yup.string().required("Select document type"),
  });

  const verifyGST = async (values) => {
    let gst_number = values.gst_number;
    if (
      values?.documentType === "GST" &&
      (!gst_number || gst_number?.length < 15)
    ) {
      toast.error("Please enter GST Number.");
    } else if (
      values?.documentType === "PAN" &&
      (!gst_number || gst_number?.length < 10 || gst_number?.length > 10)
    ) {
      toast.error("Please enter PAN Number.");
    } else {
      setDocumentVerified(true);
    }
    // let data = {
    //   gst_number: gst_number,
    // };
    // try {
    //   const res = await sellerApiService.verifyGst(data);
    //   if (res.status == 200) {
    //     toast.success("GST number verified successfully.", TOAST_STYLE);
    //     setDocumentVerified(true);
    //   }
    // } catch (error) {
    //   toast.error(error, TOAST_STYLE);
    // }
  };
  const verifyBankAccount = async (values) => {
    let bank_account_number = values.bank_account_number;
    let ifsc = values.ifsc;
    let bank_holder_name = values.bank_holder_name;
    if (!bank_account_number || !ifsc || !bank_holder_name) {
      toast.error("Please enter all fields");
    } else {
      setBankAccVerified(true);
    }
    // let data = {
    //   bank_account_number: bank_account_number,
    //   ifsc: ifsc,
    // };
    // try {
    //   const res = await sellerApiService.verifyBankAccount(data);
    //   if (res.status == 200) {
    //     toast.success(
    //       "Bank account number verified successfully.",
    //       TOAST_STYLE
    //     );
    //     setShowBankDetailsModal(true);
    //     setBankAccVerified(true);
    //   }
    // } catch (error) {
    //   toast.error(error, TOAST_STYLE);
    // }
  };

  const hanldleAccountReset = () => {
    dispatch(resetAuth());
  };

  const initVal = {
    company: "",
    address: "",
    address2: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    // shop_link: "",
    bank_account_number: "",
    ifsc: "",
    gst_number: "",
    bank_holder_name: "",
    documentType: "GST",
  };

  const onSubmit = (values) => {
    if (!bankAccVerified || !documentVerified) {
      toast.error("Please verify Bank Account and GST.");
      return;
    }
    var formdata = new FormData();
    formdata.append("name", cookie.mee_too_user.name);
    formdata.append("company", values?.company);
    formdata.append(
      "address",
      JSON.stringify({
        street: values?.address,
        pincode: values?.pincode,
        city: values?.city,
        state: values?.state,
        landmark: values?.landmark,
      })
    );
    formdata.append("ifsc", values?.ifsc);
    formdata.append("bank_account_number", values?.bank_account_number);
    formdata.append("bank_holder_name", values?.bank_holder_name);
    formdata.append("signature", signature);
    formdata.append("gst_number", values?.gst_number);
    formdata.append("documentType", values?.documentType);
    let finalPayload = {
      header: auth,
      updateProfileData: formdata,
    };
    dispatch(updateProfile(finalPayload));
  };

  const DisplayHeading = ({ firstLine, secLine, para }) => (
    <div className="seller__heading">
      <h1 className="seller__hideOnLowResolution">
        {firstLine}
        <br />
        {secLine}
      </h1>
      <h1 className="seller__showOnLowResolution">
        {firstLine} {secLine}
      </h1>
      <>
        <p className="seller__hideOnLowResolution" />
        <div className="seller__hideOnLowResolution">{para}</div>
      </>
    </div>
  );

  return (
    <StyledContainer>
      <div
        className="seller__bg"
        style={{
          backgroundImage: `url(${
            showSuccessModal
              ? IMAGESV2.SellerFormSuccess
              : IMAGESV2["SellerForm" + formCount]
          })`,
        }}
      />
      <Navbar expand="lg" className="seller__navbar">
        <Navbar.Brand>
          <Link to={PATH.LANDING_PAGE}>
            <img
              className="seller__logo"
              src={IMAGES.COMPANY_LOGO}
              alt="fund-raiser-logo"
            />
          </Link>
        </Navbar.Brand>
        <GenericButton className="seller_btn" onClick={handleSuccess}>
          Login
        </GenericButton>
      </Navbar>
      {!showSuccessModal ? (
        <div className="seller__container">
          <div className="seller__content">
            {formCount === 1 && (
              <DisplayHeading
                firstLine="Company"
                secLine="Details"
                para="The company details should be matched with registered GST no. details."
              />
            )}
            {formCount === 2 && (
              <DisplayHeading
                firstLine="Bank"
                secLine="Details"
                para="Enter the bank details in which you want to receive payouts."
              />
            )}
            {formCount === 3 && (
              <DisplayHeading
                firstLine="GST"
                secLine="Details"
                para="GST no. is mandatory by Government for Online Selling."
              />
            )}
            {formCount === 4 && (
              <DisplayHeading
                firstLine="Final"
                secLine="Submit"
                para="Add signature image."
              />
            )}
            <SellerContainer>
              <SellerForm
                initVal={initVal}
                validateSchema={AccountSchema}
                onSubmit={onSubmit}
                formCount={formCount}
                setFormCount={setFormCount}
                verifyGST={verifyGST}
                documentVerified={documentVerified}
                verifyBankAccount={verifyBankAccount}
                bankAccVerified={bankAccVerified}
                hiddenFileInput={hiddenFileInput}
                setSignature={setSignature}
              />
            </SellerContainer>
          </div>
        </div>
      ) : (
        <SuccessForm onClick={handleSuccess} />
      )}
    </StyledContainer>
  );
};

export default Seller;
