import React from "react";
import styled from "styled-components";
import { GenericButton } from "../../../compoents/Button";
import { useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { useEffect } from "react";

const CampaignCostSplitStyled = styled.div`
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: fit-content;
  .costSplit__content {
    max-height: 50vh;
    overflow: auto;
    background: #f8f8f8;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 12px;
    .costSplit__listItem {
      display: grid;
      grid-template-columns: minmax(100px, 300px) minmax(100px, 350px);
      gap: 8px;
      margin-bottom: 8px;
      .costSplit__itemName {
        text-align: right;
        font-weight: 500;
      }
      .costSplit__itemCost {
        width: 100%;
        height: 38px;
        border-radius: 4px;
        outline: none;
        border-width: 1px;
        border-color: #c3c3c3;
        padding: 0 8px;
      }
      .costSplit__imageDiv {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
      }
    }
  }
  .costSplit__actionButton {
    margin-left: auto;
    &:disabled {
      background: grey;
      border: none;
      cursor: not-allowed;
    }
  }
  .costSplit__balance {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    font-weight: 400;
    margin: 12px 24px;
  }
`;

const CostField = ({ handleInputChange, prodId, costValue }) => {
  const [value, setValue] = useState(costValue);
  const tempVal = useDebounce(value, 500);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) handleInputChange(tempVal, prodId);
  }, [tempVal]);

  return (
    <input
      value={value}
      className="costSplit__itemCost"
      placeholder="Enter daily budget utilization"
      onChange={(e) => {
        const val = e.target.value;
        setValue(val);
      }}
    />
  );
};

function CampaignCostSplit({ budget = 0, product, updateCost, handleSubmit }) {
  const [usedAmount, setUsedAmount] = useState(0);

  const handleInputChange = (value, prodId) => {
    const parseValue = value ? parseFloat(value) : 0;

    let costRef = 0;
    const newData = product.map((row) => {
      if (row.id === prodId) {
        if (value && parseFloat(value)) {
          costRef = costRef + parseValue;
        }
        return { ...row, cost: parseValue };
      }
      costRef = costRef + (row.cost ?? 0);
      return row;
    });
    setUsedAmount(costRef);
    updateCost(newData);
  };

  return (
    <CampaignCostSplitStyled>
      <div
        className={`costSplit__balance ${
          usedAmount > budget ? "text-danger" : null
        }`}
      >
        <span>
          Used <strong>Rs.{usedAmount}</strong> of <strong>Rs.{budget}</strong>{" "}
        </span>
        {usedAmount > budget && <span>(Limit exceed daily budget!)</span>}
      </div>

      <ul className="costSplit__content">
        {product.map((d) => (
          <li key={d.id} className="costSplit__listItem">
            <div className="costSplit__imageDiv">
              <div className="costSplit__itemName">{d.name}</div>
              <div
                style={{
                  width: 38,
                  aspectRatio: "1/1",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={d.title_image}
                  alt=""
                />
              </div>
            </div>
            <CostField
              handleInputChange={handleInputChange}
              prodId={d.id}
              usedAmount={usedAmount}
              costValue={d?.cost ?? ""}
            />
          </li>
        ))}
      </ul>
      <GenericButton
        disabled={usedAmount > budget}
        className="costSplit__actionButton"
        onClick={handleSubmit}
      >
        Submit
      </GenericButton>
    </CampaignCostSplitStyled>
  );
}

export default CampaignCostSplit;
