import React, { useEffect } from "react";
import "./customLoader.css";
import { FiLoader } from "react-icons/fi";

function CustomLoader() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);
  return (
    <div className="customLoaderRoot">
      <div className="customLoader">
        <FiLoader size="100%" />
      </div>
    </div>
  );
}

export default CustomLoader;
