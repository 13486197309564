import React from "react";
import {Button} from "react-bootstrap";
import styled from "styled-components";
const StyledButton = styled(Button)`
  background: ${(props) =>
    props.variant === "primary"
      ? "#FF4D00"
      : props.background
      ? props.background
      : "white"};
  color: ${(props) =>
    props.variant === "primary"
      ? "white"
      : props.color
      ? props.color
      : "#FF4D00"};
  font-size: 1em;
  /* margin: 1em; */
  padding: 0.25em 1em;
  border: 2px solid #ff4d00;
  border-radius: 4px;

  :hover {
    background: ${(props) =>
      props.variant === "primary" ? "white" : "#FF4D00"};
    color: ${(props) => (props.variant === "primary" ? "#FF4D00" : "white")};
    border-color: #ff4d00;
  }
`;
const GenericButton = ({children, className, ...props}) => {
  // console.log("props", props)
  return (
    <StyledButton className={className} {...props}>
      {children}
    </StyledButton>
  );
};

GenericButton.defaultProps = {
  variant: "primary",
};

export default GenericButton;
