import React, { useCallback, useEffect, useState } from "react";
import Box from "../../compoents/Box";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Paper from "../../compoents/Paper";
import OnHold from "./OnHold";
import Delivered from "./Delivered";
import Shipped from "./Shipped";
import Cancelled from "./Cancelled";
import ReadyToShip from "./ReadyToShip";
import PageTitle from "../../compoents/PageTitle";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";

const Orders = () => {
  const auth = useAuth();
  const [orderCount, setCount] = useState(null);
  const [activeTab, setActiveTab] = useState("on-hold");
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const fetchOrderCount = useCallback(async () => {
    const res = await sellerApiService.getOrderCount();
    if (res.data) {
      setCount(res.data.result);
    } else {
      setCount({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchOrderCount();
  }, [fetchOrderCount]);

  const OrderCountSpan = ({ count, text }) => {
    return (
      <h5
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "3px",
          alignItems: "center",
        }}
        className="font-mute fs-6 mb-0"
      >
        {text}
        <pre
          style={{
            backgroundColor: "#ff4d00",
            borderRadius: "50%",
            color: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "700",
            width: 24,
            height: 24,
            lineHeight: "24px",
          }}
        >
          {count}
        </pre>
      </h5>
    );
  };

  return (
    <>
      <div>
        <PageTitle text="Orders" />
        <Tab.Container
          id="order-tab"
          activeKey={activeTab}
          onSelect={(tab) => setActiveTab(tab)}
        >
          <Box>
            <Paper
              className="overflow-auto p-0 bg-transparent "
              style={{
                padding: "0px",
                background: "none",
                backgroundColo: "none",
              }}
            >
              <Nav
                variant="pills"
                className="fund-raise-generic-tabs-nav flex-row text-center button-text flex-nowrap px-2"
              >
                <Nav.Item className="">
                  <div
                    className="font-bold font-mute"
                    style={{ color: "#888888", width: "120px" }}
                  ></div>
                  <Nav.Link
                    eventKey="on-hold"
                    className="cursor-pointer text-grey-light p-1 w-100"
                  >
                    <OrderCountSpan
                      count={orderCount?.PLACED ?? 0}
                      text="On Hold"
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <div
                    className="font-bold font-mute"
                    style={{ color: "#888888", width: "120px" }}
                  ></div>
                  <Nav.Link
                    eventKey="ready-to-ship"
                    className="cursor-pointer text-grey-light p-1 w-100"
                  >
                    <OrderCountSpan
                      count={orderCount?.CONFIRMED ?? 0}
                      text="Ready To Ship"
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <div
                    className="font-bold fs-6"
                    style={{ color: "#888888", width: "120px" }}
                  ></div>
                  <Nav.Link
                    eventKey="shipped"
                    className="cursor-pointer text-grey-light p-1 w-100"
                  >
                    <OrderCountSpan
                      count={orderCount?.SHIPPED ?? 0}
                      text="Shipped"
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <div
                    className="font-bold fs-6"
                    style={{ color: "#888888", width: "120px" }}
                  ></div>
                  <Nav.Link
                    eventKey="delivered"
                    className="cursor-pointer text-grey-light p-1 w-100"
                  >
                    <OrderCountSpan
                      count={orderCount?.DELIVERED ?? 0}
                      text="Delivered"
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <div
                    className="font-bold fs-6"
                    style={{ color: "#888888", width: "120px" }}
                  ></div>
                  <Nav.Link
                    eventKey="cencelled"
                    className="cursor-pointer text-grey-light p-1 w-100"
                  >
                    <OrderCountSpan
                      count={orderCount?.CANCELLED ?? 0}
                      text="Cancelled"
                    />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Paper>
          </Box>

          <Tab.Content className="mt-2 border-top">
            <Tab.Pane eventKey="on-hold" className="w-100">
              {activeTab === "on-hold" && (
                <OnHold fetchOrderCount={fetchOrderCount} />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="ready-to-ship" className="w-100">
              {activeTab === "ready-to-ship" && <ReadyToShip />}
            </Tab.Pane>
            <Tab.Pane eventKey="shipped">
              {activeTab === "shipped" && <Shipped />}
            </Tab.Pane>
            <Tab.Pane eventKey="delivered">
              {activeTab === "delivered" && <Delivered />}
            </Tab.Pane>
            <Tab.Pane eventKey="cencelled">
              {activeTab === "cencelled" && <Cancelled />}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default Orders;
