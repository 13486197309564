import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from "styled-components";

const StyledBreadCrumb = styled(Breadcrumb)`
  color: #9a9a9a;
  font-size: 16px;
  letter-spacing: 0px;
`;

const WhistleBrowserPolicy = () => {
  return (
    <>
      <Container>
        <StyledBreadCrumb>
          <StyledBreadCrumb.Item active href="#">
            Dashboard
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Settings
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Legal & policies
          </StyledBreadCrumb.Item>
          <StyledBreadCrumb.Item active href="#">
            Whistle Blower Policy
          </StyledBreadCrumb.Item>
        </StyledBreadCrumb>
        <h1 className="mt-4 mb-4">Whistle Blower Policy</h1>
        <section>
          <h4>1.1. Background</h4>
          <p>
            1.1.1. Groupick (together with its group companies “Company” or
            “Groupick”) encourages its employees, consultants, and partners such
            as suppliers, service providers, users, and anyone having any
            connection with Groupick, who have reasonable suspicions of
            misconduct to report such concerns without fear of punishment. This
            Whistle Blower Policy (“Policy”) is created with a purpose to
            provide a secured framework which an employee, consultant, or
            contractor (“Individual(s)”) can use to share his/ her views,
            observations, and objections with regard to unacceptable behavior of
            other Employees, consultants, or contractors of the Company which
            shows malpractice or wrongdoing which may adversely affect the
            rights of the Individuals of the Company or tarnish the image and
            reputation of the Company.
          </p>
        </section>
        <section>
          <h4>1.2. Policy Objectives</h4>
          <p>
            1.2.1. This Policy seeks to provide a channel to the Individuals to
            report to the management, concerns in relation to malpractices and
            events which have taken place/ suspected to take place, involving:
          </p>
          <ul className="list-unstyled">
            <li>(i) Breach of the Code of Conduct of the Company;</li>
            <li>(ii) Breach of integrity and ethics;</li>
            <li>
              (iii) Breach of terms and conditions of employment with the
              Company and rules thereof;
            </li>
            <li>
              (iv) Intentional financial irregularities, including fraud, or
              suspected fraud;
            </li>
            <li>(v) Deliberate violation of laws/regulations;</li>
            <li>
              (vi) Gross or willful negligence causing substantial and specific
              danger to health, safety and environment;
            </li>
            <li>(vii) Manipulation of data/records of the Company;</li>
            <li>(viii) Pilferation of confidential/propriety information;</li>
            <li>
              (ix) Gross wastage/misappropriation of funds/assets of the
              Company.
            </li>
            <li>
              (x) Incidents which are adversely impacting an Individual’s
              performance and engagement during the course of his/ her
              association with the Company.
            </li>
            <li>
              (xi) Any act of physical assault which endangers or puts the fear
              of life endangerment in the mind of an Individual while at work.
            </li>
            <li>
              (xii) Any verbal volley or targeted communication which
              psychologically intimidates the self- respect and social image of
              an Individual in the opinion of another.
            </li>
            <li>
              (xiii) Any issue or grievance which the Individual has experienced
              due to an action or series of actions at work which the Individual
              has reason to believe that it cannot be shared with anyone other
              than a third person who may be able to independently assess and
              help resolve the issues.
            </li>
          </ul>
          <p>
            1.2.2. This mechanism neither releases Individuals from their duty
            of confidentiality in the course of their work nor can it be used as
            a route for raising malicious or unfounded allegations against
            people in authority and / or colleagues in general.
          </p>
        </section>
        <section>
          <h4>1.3. Scope of the Policy</h4>
          <p>
            1.3.1. This Policy covers malpractices and events which have taken
            place / suspected to have taken place involving instances of
            violation of applicable laws, misuse or abuse of authority, breach
            of contract, fraud or suspected fraud, violation of rules and Code
            of Conduct of the Company, manipulation of company data including
            financial data, negligence causing danger to public health and
            safety, misappropriation of Groupick’s assets or funds, and other
            matters or activity on account of which the interest of the Company
            or the Individuals are affected and formally reported by Whistle
            Blowers.
          </p>
        </section>
        <section>
          <h4>1.4. Definitions</h4>
          <p>
            1.4.1. “Code of Conduct” means the code of conduct as applicable to
            the Company.
          </p>
          <p>
            1.4.2. “Complaint” means a concern raised by an Individual or group
            of Individuals, through a written communication and made in good
            faith which discloses or demonstrates information about an unethical
            or improper activity with respect to the Company. However, the
            Complaints should be factual and not speculative or in the nature of
            an interpretation / conclusion and should contain as much specific
            information as possible to allow for proper assessment of the nature
            and extent of the concern.
          </p>
          <p>
            1.4.3. "Individuals” means all Employees, consultants or contractors
            engaged by the Company.
          </p>
          <p>
            1.4.4. “Investigating Officer” means the officer appointed by the
            management of the Company from amongst the Employees to assist in
            the investigation of a Complaint.
          </p>
          <p>1.4.5. “Management” means management of the Company.</p>
          <p>
            1.4.6. “Policy” means this Whistle Blower Policy and including any
            amendments made to it from time to time.
          </p>
          <p>
            1.4.7. “Subject” means a person or group of persons against or in
            relation to whom a Complaint is made or evidence gathered during the
            course of an investigation.
          </p>
          <p>
            1.4.8. “Whistle Blower” is an Individual or group of Individuals who
            make a Complaint under this Policy and also referred in this Policy
            as Whistle Blower.
          </p>
          <p>
            1.4.9. “Whistle Blower Committee” shall mean the committee set up by
            the Company to investigate Complaints.
          </p>
        </section>
        <section>
          <h4>1.5. Receipt and Disposal of Complaints</h4>
          <p>
            1.5.1. Complaints should be reported in writing and should be duly
            signed by the Whistle Blower. The Complaint should be made as soon
            as possible after the occurrence of the event the said Complaint
            relates to and the Whistle Blower becoming aware of the occurrence
            of such event so as to ensure a clear understanding of the issues
            raised.
          </p>
          <p>
            1.5.2. The Complaint made should either be typed or written in
            legible handwriting and may be in English or in Hindi. The Whistle
            Blower shall clearly write his/her name, designation, location,
            e-mail ID, contact number and address on the Complaint. It may be
            noted
          </p>
          <p>
            1.5.3. All Complaints should be addressed to the Whistle Blower
            Committee by sending an email to whistleblower@Groupick.com. A
            complaint can also be made by writing to the following address:
          </p>
          <p>
            The CFO.
            <br />
            Getzie Technology Private Limited.
            <br />
            Sairaj Complex, A/204, Sanpada Sector 01, Navi mumbai Maharashtra -
            400705
          </p>
          <p>
            1.5.4. An individual must exercise sound judgment in raising
            concerned to avoid baseless allegations.
          </p>
          <p>
            1.5.5. On receipt of the Complaint, the Whistle Blower Committee
            shall make a record of the Complaint and also ascertain from the
            Whistle Blower whether he/ she was the person who made the Complaint
            or not for further appropriate investigation and needful action.
          </p>
          <p>
            1.5.6. The Whistle Blower Committee may call for such further
            information or particulars from the Whistle Blower as he deems fit.
          </p>
        </section>
        <section>
          <h4>1.6. Investigation</h4>
          <p>
            1.6.1. All Complaints under this Policy will be recorded and
            thoroughly investigated. The Whistle Blower Committee will
            investigate and may at its discretion consider involving any other
            officer of the Company for the purpose of the investigation. In case
            the Whistle Blower Committee deems necessary it may identify an
            Investigating Officer.
          </p>
          <p>
            1.6.2. The investigation may involve study of documents and holding
            of interviews with various individuals. Any person required to
            provide documents, access to systems and other information for the
            purpose of such investigation shall co- operate in doing so.
            Individuals with whom the Investigating Officer requests an
            interview for the purposes of such investigation shall make
            themselves available for such interview at all reasonable times and
            shall provide the necessary co-operation for such purpose.
          </p>
          <p>
            1.6.3. Subject(s) will normally be informed in writing of the
            allegations at the outset of a formal investigation and shall be
            accorded opportunities for providing their inputs during the
            investigation.
          </p>
          <p>
            1.6.4. The Whistle Blower Committee or the Investigating Officer, as
            the case may be, will make best endeavors to complete the
            investigation and submit to the Management, an investigation report
            within 30 days from the receipt of the Complaint.
          </p>
        </section>
        <section>
          <h4>1.7. Decision and Reporting</h4>
          <p>
            1.7.1. The Whistle Blower Committee along with its recommendations
            will report the findings of the investigation to the Management
            within 30 days of receipt of a Complaint for further action as
            deemed fit. In the event a prima facie case exists against the
            Subject, the Management shall take appropriate action in this regard
            or shall close the matter, as the case may be, for which the Whistle
            Blower Committee shall record the reasons. Copy of the above
            decision shall be addressed to the Management who in turn shall
            intimate the Whistle Blower and the Subject of the same.
          </p>
          <p>
            1.7.2. In case the Subject is any member of the Whistle Blower
            Committee, the Management shall in turn will appoint another
            Employee as a member of the Whistle Blower Committee to examine and
            investigate the Complaint.
          </p>
          <p>
            1.7.3. In the event the Whistle Blower Committee is of the view that
            a Complaint has not been made in good faith such an event shall be
            viewed seriously and the Whistle Blower may be subject to
            appropriate disciplinary action. In case of repeated frivolous
            complaints or false allegations by an Individual, the Whistle Blower
            Committee, may take suitable disciplinary action including reprimand
            against the concerned Individual in accordance with the rules,
            procedures and policies of the Company.
          </p>
          <p>
            1.7.4. The Whistle Blower Committee shall keep and maintain the
            following records in respect of the Complaint, its investigation and
            action taken:
          </p>
          <ul className="list-unstyled">
            <li>
              (i) Brief facts of Complaints stating whether the same Complaint
              was raised previously by anyone, and if so, the outcome thereof;
            </li>
            <li>
              (ii) Details of actions taken by Whistle Blower Committee/
              Investigating Officer for processing the complaint.
            </li>
            <li>
              (iii) Findings of the Whistle Blower Committee/ Investigating
              Officer;
            </li>
            <li>
              (iv) The recommendations of the Whistle Blower Committee/ other
              action(s).
            </li>
          </ul>
        </section>
        <section>
          <h4>1.8. Secrecy / Confidentiality</h4>
          <p>
            1.8.1. The Whistle Blower, Whistle Blower Committee, Management, the
            Subject and everybody involved in the investigation and redressal
            process shall:
          </p>
          <ul>
            <li>
              (i) Maintain utmost confidentiality of all matters under this
              Policy;
            </li>
            <li>
              (ii) Not discuss the matter with any person whatsoever except to
              the extent or with those persons as required under this Policy for
              completing the process of the investigation;
            </li>
            <li>
              (iii) Not keep related documents unattended anywhere at any time;
              and
            </li>
            <li>
              (iv) Keep all related electronic mails / files under password.
            </li>
          </ul>
        </section>
        <section>
          <h4>1.9. Misuse of the Program and Channel</h4>
          <p>
            1.9.1. The Company recognizes the need to offer Individuals this
            safe and secure channel to share their inputs and grievances about
            instances covered under this Policy with a neutral and independent
            panel for investigation and action. It is also important for
            Individuals to be cognizant of the fact that the Company discourages
            and shall take serious action should such channels be misused for
            any other purpose than for which they have been incorporated.
          </p>
          <p>
            1.9.2. The Whistle Blower Committee is required to assist in the
            investigation of a complaint reported by an Individual and ensure
            that every measure of confidentiality is taken to safe guard the
            identity and inputs shared by the Whistle Blower.
          </p>
          <p>
            1.9.3. The program requires equal confidentiality from the Whistle
            Blower as much as the Whistle Blower believes in the confidentiality
            at the end of the Whistle Blower Committee. Thus, any Individual can
            report immediately of any action of repercussion arising out of leak
            of information at his/ her end. Such leak of information may occur
            by error of mention or sheer negligence on the part of the
            Individual. The Whistle Blower Committee reserves the right to
            independently investigate this afresh and take necessary action
            against the erring parties involved (including the reporter if the
            facts of investigation so reveal).
          </p>
          <p>
            1.9.4. The use of this framework and channel is discouraged in any
            of the following scenarios which may be considered as ‘Business as
            Usual’ issues and should be reported to the respective business
            level authorities who are designated to address the same in each
            business by virtue of their roles. The below list is suggestive in
            nature and not exhaustive; the Whistle Blower Committee reserves the
            discretion not to entertain such incidents and may advice the
            Individual to report it to the appropriate manager/ authority.
          </p>
          <ul className="list-unstyled">
            <li>
              Non- functional / Malfunctioning infrastructure, telecommunication
              systems and/ or virtual systems.
            </li>
            <li>
              (ii) Disagreements between Individuals arising out of the normal
              course of discussion with regard to business-as-usual (BAU)
              actions and/ or expectations.
            </li>
            <li>Domestic issues which are personal to an Individual.</li>
            <li>
              Historical conflicts between two or more Individuals which are
              personal in nature.
            </li>
          </ul>
        </section>
        <section>
          <h4>1.10. Protection</h4>
          <p>
            1.10.1. No unfair treatment will be meted out to a Whistle Blower by
            virtue of his/ her having reported a Complaint under this Policy.
            The Company, as a policy, condemns any kind of discrimination,
            harassment, victimization, or any other unfair employment practice
            being adopted against Whistle Blowers. Complete protection will,
            therefore, be given to Whistle Blowers against any unfair practice
            like retaliation, threat or intimidation of termination/ suspension
            of service, disciplinary action, transfer, demotion, refusal of
            promotion, or the like including any direct or indirect use of
            authority to obstruct the Whistle Blower’s right to continue to
            perform his duties / functions including making further Complaints.
            If the Whistle Blower is required to give evidence in criminal or
            disciplinary proceedings, the Company will arrange for the Whistle
            Blower to receive advice about the procedure, etc.
          </p>
          <p>
            1.10.2. A Whistle Blower may report any violation of the above
            clause to the Whistle Blower Committee, who shall investigate into
            the same and recommend suitable action to the Management.
          </p>
          <p>
            1.10.3. The identity of the Whistle Blower shall be kept
            confidential to the extent possible. The identity of the Whistle
            Blower will not be revealed unless he himself has made either his
            details public or disclosed his identity to any other office or
            authority. In the event of the identity of the Whistle Blower being
            disclosed, the Whistle Blower Committee will be authorized to
            initiate appropriate action against the person making such
            disclosure. The identity of the Whistleblower, if known, shall
            remain confidential to those persons directly involved in the
            implementation of this Policy, unless the issue requires
            investigation by law enforcement agencies.
          </p>
          <p>
            1.10.4. Any other Individual assisting in the said investigation
            shall also be protected to the same extent as the Whistle Blower.
            This Policy does not protect an Individual from an adverse action
            taken independent of his/ her disclosure of unethical and improper
            practice etc. unrelated to a disclosure made by such Individual
            pursuant to this Policy.
          </p>
        </section>
        <section>
          <h4>1.11. Communication</h4>
          <p>
            1.11.1. This Policy shall be published on the website of the
            Company.
          </p>
        </section>
        <section>
          <h4>1.12. Retention of Documents</h4>
          <p>
            1.12.1. All Complaints documented along with the results of
            investigations relating thereto, shall be retained by the compliance
            department of the Company for a minimum period of 5 years or as
            required by applicable laws, whichever is more, from the date of the
            decision in specific matters.
          </p>
        </section>
        <section>
          <h4>1.13. Administration and Review of the Policy</h4>
          <p>
            1.13.1. The Whistle Blower Committee shall be responsible for the
            administration, implementation, interpretation, application and
            review of this Policy in consultation with the Management. The
            Whistle Blower Committee shall be empowered to bring about necessary
            changes to this Policy, if required at any stage, with the
            concurrence of the Management. The Management may also establish
            further rules and procedures, from time to time, to give effect to
            the intent of this Policy and further the objective of good
            corporate governance.
          </p>
        </section>
      </Container>
    </>
  );
};

export default WhistleBrowserPolicy;
