import React, { useEffect, useState } from "react";
import { PaidServicesListStyled } from "./paidServiceStyled";
import { amountWithSymbol, loadRazorpay } from "../../../utilities";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import accountService from "../../../redux/services/account.service";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { GenericButton } from "../../../compoents/Button";
import Loader from "../../../compoents/Loader";
import { APP_SETTINGS } from "../../../config";
import { useDispatch } from "react-redux";
import { updateLoggedIn } from "../../../redux/slices/account";

function PaidServicesList() {
  const auth = useAuth();
  const [paymentStarted, setPaymentStarted] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [planDetail, setPlanDetail] = useState([]);
  const razorpaySdk = APP_SETTINGS.API_PATH.razorpaySdk;
  const dispatch = useDispatch();
  const handlePaymentSuccess = async (response) => {
    try {
      console.warn("handlePaymentSuccess:", response);
      const res = await accountService.verify_paid_service_payment(
        response,
        auth
      );
      console.warn("res:", res);
      const { data } = res;
      if (data?.status === "error") {
        toast.error("Payment failed1.");
        return;
      }
      dispatch(updateLoggedIn({ active_paid_service: data?.data?.id ?? null }));
      toast.success(data?.msg ?? "Payment successful.");
    } catch (error) {
      console.log("error2:::", error);
      toast.error("Payment failed2.");
    }
  };

  const handlePayment = async (data) => {
    const handler = (response) => {
      console.warn("razorpay_handler:", response);
      handlePaymentSuccess(response);
    };
    const theme = {
      color: "#ff4d00",
    };
    data.handler = handler;
    data.theme = theme;
    const rzpy = new window.Razorpay(data);
    rzpy.on("payment.failed", async function (response) {
      console.warn("razorpay failed3:", response);
      //   await accountService.payment_failed(response, auth);
      toast.error("Payment failed4.");
    });
    rzpy?.open();
    setSelectedPlan(null);
    setPaymentStarted(false);
  };

  const fetchOrderId = async (planId, callback) => {
    try {
      const res = await accountService.create_paid_service_order(auth, planId);
      const { data } = res;
      if (data?.status === "error") {
        toast.error("Error while initiating payment.");
        return;
      }
      console.log("order init data:", data?.data);
      callback(data?.data ?? {});
      // setPaymentStarted(false);
      // setSelectedPlan(null);
    } catch (error) {
      console.error("Error:", error);
      setSelectedPlan(null);
      setPaymentStarted(false);
      toast.error("Error while initiating payment.");
    }
  };

  const onClickHandler = async (planId) => {
    setPaymentStarted(true);
    setSelectedPlan(planId);
    if (!window.hasOwnProperty("Razorpay")) {
      await loadRazorpay(razorpaySdk);
    }
    await fetchOrderId(planId, (data) => handlePayment(data));
  };

  const fetchPlanDetails = async () => {
    try {
      const res = await accountService.get_paid_plan(auth);
      const { data } = res;
      setLoaded(true);
      if (data?.status === "error") {
        return toast.error(data?.msg);
      }
      setPlanDetail(data?.data ?? {});
    } catch (error) {
      setLoaded(true);
      toast.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  if (!loaded) {
    return null;
  }
  return planDetail?.map((plan) => (
    <PaidServicesListStyled key={plan.id}>
      <h3 className="text-center fw-bold">
        {Object.keys(plan).length > 0 ? plan?.plan_name : "No Plan"}
      </h3>
      {Object.keys(plan).length > 0 ? (
        <div className="paidList__container">
          <div className="paidList__amountDiv">
            <div className="paidList__striked">
              {amountWithSymbol(plan?.actual_amount)}/-
            </div>
            <div className="fw-bold">
              {amountWithSymbol(plan?.offer_amount)}
              {plan?.is_day_limit ? (
                <span className="fw-normal paidList__prefix">
                  /{plan?.validity_in_days} days
                </span>
              ) : (
                "/-"
              )}
            </div>
          </div>
          <ul className="paidList__points">
            <div className="mb-2">Includes :</div>
            {plan?.description?.map((point, i) => (
              <li className="paidList__point" key={`point-${i}`}>
                <AiOutlineCheckCircle className="paidList__checkIcon" />
                {point}
              </li>
            ))}
          </ul>
          <GenericButton
            className="paidList__submitBtn"
            disabled={paymentStarted && selectedPlan === plan.id}
            onClick={() => onClickHandler(plan.id)}
          >
            Pay Now
            {/* {!paymentStarted && selectedPlan !== plan.id ? (
              "Grab Now"
            ) : (
              <>
                <Loader />
                <Loader />
                <Loader />
              </>
            )} */}
          </GenericButton>
        </div>
      ) : null}
    </PaidServicesListStyled>
  ));
}

export default PaidServicesList;
