import React, { useRef, useEffect, useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { FaAdversal } from "react-icons/fa";
import { APP_SETTINGS, IMAGES, PATH } from "../config";
import { useCookies } from "react-cookie";
import DashboardIcon from "../assets/Images/dashboard.svg";
import CatalogueIcon from "../assets/Images/catalogue.svg";
import BrandIcon from "../assets/Images/brand.svg";
import OrdersIcon from "../assets/Images/orders-sidebar.svg";
import ReturnsIcon from "../assets/Images/returns.svg";
import InventoryIcon from "../assets/Images/inventory.svg";
import PaymentIcon from "../assets/Images/payment.svg";
import ReviewsIcon from "../assets/Images/reviews.svg";
import SettingsIcon from "../assets/Images/settings.svg";
// import GalleryIcon from "../assets/Images/gallery.png";
import NoticeIcon from "../assets/Images/notice.svg";
import LogoutIcon from "../assets/Images/logout.svg";
import { GrGallery } from "react-icons/gr";
import { useAuth } from "../Navigation/Auth/ProvideAuth";
import SellerApiService from "../redux/services/seller-api-service";
// import AllImagesModal from "../compoents/AllImagesModal";
// import axios from "axios";
// import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetAuth, setLoggedIn } from "../redux/slices/account";
import { FaRegHandshake } from "react-icons/fa";

const Sidebar = (props) => {
  let [, , removeCookie] = useCookies(["mee_too_user"]);

  let ref = useRef();
  // let history = useHistory();
  let location = useLocation();

  const dispatch = useDispatch();

  let auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);
  const [user, setUser] = React.useState(auth.mee_too_user);
  const [loading, setLoading] = useState(false);

  // const [showModal, setShowModal] = useState(false);
  // const [allImages, setAllImages] = useState([]);
  // const getAllImagesURL = APP_SETTINGS.API_PATH.CATALOG.getAllImages;

  // const getAllImages = async () => {
  //   const getCookie = (cname) => {
  //     let name = cname + "=";
  //     let decodedCookie = decodeURIComponent(document.cookie);
  //     let ca = decodedCookie.split(";");
  //     for (let i = 0; i < ca.length; i++) {
  //       let c = ca[i];
  //       while (c.charAt(0) == " ") {
  //         c = c.substring(1);
  //       }
  //       if (c.indexOf(name) == 0) {
  //         return c.substring(name.length, c.length);
  //       }
  //     }
  //     return "";
  //   };

  //   const token = JSON.parse(getCookie("mee_too_user"));

  //   try {
  //     const { data } = await axios({
  //       url: getAllImagesURL,
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token["token"]}`,
  //         "content-type": "application/json",
  //       },
  //     });
  //     setAllImages(data.data);
  //     setShowModal(true);
  //   } catch (error) {
  //     toast.error("An error has occured.");
  //   }
  // };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await sellerApiService.getUserProfile();
      if (res.data) {
        const { user } = res.data;
        dispatch(setLoggedIn(user));
        setUser(user);
        setLoading(false);
      }
    })();
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.name !== "menuBtn" &&
        event.target.id !== "hamburger-icon" &&
        event.target.parentElement &&
        event.target.parentElement.id !== "hamburger-icon"
      ) {
        props.setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, props, props.show]);

  const signOutHandler = () => {
    removeCookie("mee_too_user");
    dispatch(resetAuth());
    window.location.assign("/auth/login");
  };

  return (
    <div
      ref={ref}
      className={`ecom-seller-sidebar d-flex justify-content-between flex-column ${
        props && props.show ? "disappear" : ""
      }`}
    >
      {props?.show && (
        <div className="outer-div" onClick={() => props?.setShow(false)} />
      )}
      <div
        className="sidebar-content"
        style={{
          margin: "0 4%",
        }}
      >
        <div
          style={{
            padding: ".5rem 0 2rem 0",
            // height: "75px",
            // background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="logo">
            <img
              src={IMAGES.COMPANY_LOGO}
              alt="fund-raiser-logo"
              style={{ width: "auto", height: "60px" }}
            />
            <span className="logo-text text-white ms-3 fw-bolder ">
              Groupick
            </span>
          </div>
        </div>
        <div className="user-box py-4 border-top border-bottom d-flex flex-column align-items-center justify-content-center">
          <span className="user-img mt-0">
            {!loading ? (
              <img
                height="80px"
                width="80px"
                src={user?.image ? user?.image : IMAGES.PROFLE_IMAGE}
                alt="User"
                style={{ objectFit: "fill" }}
                className="avatar-img rounded-circle img_cover_fit"
              />
            ) : (
              <div
                style={{
                  width: 80,
                  height: 80,
                  border: "2px solid #8b8989",
                  borderRadius: "50%",
                }}
              />
            )}
          </span>
          <span className="mt-3 text-center">{user.name}</span>
        </div>
        <div className="list-group mt-4">
          <Link
            to={PATH.DASHBOARD}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === "/dashboard" && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <img src={DashboardIcon} alt="" className="sidebar-icon" />
            </span>
            <span className="mx-2">Dashboard</span>
          </Link>
          <Link
            to={PATH.CATALOGUE}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.CATALOGUE && "active"
            }`}
          >
            <span className="mr-5">
              <img src={CatalogueIcon} alt="" className="sidebar-icon" />
            </span>
            <span className="mx-2">Upload Catalogue</span>
          </Link>
          <Link
            to={PATH.BRAND}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.BRAND && "active"
            }`}
          >
            <span className="mr-5">
              <img src={BrandIcon} alt="" className="sidebar-icon" />
            </span>
            <span className="mx-2">Brand Approval</span>
          </Link>
          {/* <Link
            to={PATH.CONTACTS}
            class={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${location.pathname === PATH.CONTACTS && "active"
              }`}
          >
            <MdPermContactCalendar size={30} className="mr-4" />
            <span className="mx-2">Contacts</span>
          </Link> */}
          <Link
            to={PATH.ORDERS}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.ORDERS && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <img src={OrdersIcon} alt="" className="sidebar-icon" />
            </span>
            <span className="mx-2">Orders</span>
          </Link>
          <Link
            to={PATH.RETURN_RTO_ORDERS}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.RETURN_RTO_ORDERS && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <img src={ReturnsIcon} alt="" className="sidebar-icon" />
            </span>{" "}
            <span className="mx-2">Return/RTO order</span>
          </Link>
          <Link
            to={PATH.INVENTORY}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.INVENTORY && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <img src={InventoryIcon} alt="" className="sidebar-icon" />
            </span>{" "}
            <span className="mx-2">Update Inventory</span>
          </Link>
          <Link
            to={PATH.CAMPAIGN}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.CAMPAIGN && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <FaAdversal size={24} />
            </span>{" "}
            <span className="mx-2">Advertise</span>
          </Link>
          <Link
            to={PATH.PAYMENT}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.PAYMENT && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <img src={PaymentIcon} alt="" className="sidebar-icon" />
            </span>{" "}
            <span className="mx-2">Payment</span>
          </Link>
          <Link
            to={PATH.REVIEW_AND_RATING}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.REVIEW_AND_RATING && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <img src={ReviewsIcon} alt="" className="sidebar-icon" />
            </span>{" "}
            <span className="mx-2">Reviews & Ratings</span>
          </Link>
          <Link
            to={PATH.SETTINGS}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.SETTINGS && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <img src={SettingsIcon} alt="" className="sidebar-icon" />
            </span>{" "}
            <span className="mx-2">Settings</span>
          </Link>
          {/* <Link
            to={PATH.DASHBOARD}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white`}
            onClick={() => {
              getAllImages();
              props.setShow(false);
            }}
          >
            <span className="mr-5 text-white">
              <GrGallery className="bg-white" size={20} />
            </span>{" "}
            <span className="mx-2">Gallery</span>
          </Link> */}
          <Link
            to={PATH.GALLERY}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white`}
            onClick={() => {
              // getAllImages();
              props.setShow(false);
            }}
          >
            <span className="mr-5 text-white">
              <GrGallery className="bg-white" size={20} />
            </span>{" "}
            <span className="mx-2">Gallery/Image Link</span>
          </Link>
          <Link
            to={PATH.NOTICE}
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white ${
              location.pathname === PATH.NOTICE && "active"
            }`}
            onClick={() => props.setShow(false)}
          >
            <span className="mr-5">
              <img src={NoticeIcon} alt="" className="sidebar-icon" />
            </span>{" "}
            <span className="mx-2">Notice</span>
          </Link>
          <Button
            className={`list-group-item list-group-item-action text-h6 text-tags-pills-text-grey bg-transparent text-white`}
            onClick={signOutHandler}
          >
            <span className="mr-5">
              <img src={LogoutIcon} alt="" className="sidebar-icon" />
            </span>
            <span className="mx-2">Log Out</span>
          </Button>
        </div>
      </div>
      {/* <AllImagesModal
        show={showModal}
        onHide={() => setShowModal(false)}
        allImages={allImages}
      /> */}
    </div>
  );
};

export default Sidebar;
