import { MdInfo } from "react-icons/md";
import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import Overlay from "react-bootstrap/Overlay";

const StyledTooltip = styled.button`
  width: fit-content;
  height: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  transform: translateY(-25%);
  outline: navajowhite;
  border: none;
  padding: 0;
  .icon {
    width: 15px;
    height: 15px;
    margin: 0 0 !important;
  }
`;

function CustomTooltip({
  description,
  placement = "top-end",
  customTooltipStyle = "",
  customStyledClass = {},
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  useEffect(() => {
    const targetRef = target?.current;
    targetRef?.addEventListener("click", (e) => {
      e.preventDefault();
    });
    return () =>
      targetRef?.removeEventListener("click", (e) => {
        e.preventDefault();
      });
  }, []);
  return (
    <>
      <StyledTooltip
        variant="danger"
        ref={target}
        onClick={() => setShow(!show)}
        style={{ ...customStyledClass }}
      >
        <MdInfo className="fe fe-bell Icon_feather mx-3 icon" size={20} />
      </StyledTooltip>
      <Overlay target={target.current} show={show} placement={placement}>
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              padding: "2px 10px",
              color: "white",
              maxWidth: "200px",
              height: "fit-content",
              borderRadius: 8,
              marginBottom: 8,
              background: "#263d4a",
              fontSize: 12,
              ...props.style,
              ...customTooltipStyle,
            }}
          >
            {description}
          </div>
        )}
      </Overlay>
    </>
  );
}

export default CustomTooltip;
