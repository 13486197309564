import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Box from "../../compoents/Box";
import ReactDatePicker from "react-datepicker";

const InventoryOtherInfo = ({ product, inputChangeHandler }) => {
  const tax = [
    { value: 0, label: "0%" },
    { value: 3, label: "3%" },
    { value: 5, label: "5%" },
    { value: 12, label: "12%" },
    { value: 18, label: "18%" },
    { value: 28, label: "28%" },
  ];
  const [expiry_details, setExpiryDetails] = useState(
    product?.expiry_details ? new Date(product?.expiry_details) : null
  );

  useEffect(() => {
    if (expiry_details) {
      inputChangeHandler({
        target: { name: "expiry_details", value: expiry_details },
      });
    }
  }, [expiry_details]);

  const MadateLabel = ({ label }) => (
    <Form.Label>
      {label} <span className="text-danger">*</span>
    </Form.Label>
  );

  return (
    <Box className="p-4">
      <Row>
        <h6 className="font-bold mb-2">Delivery Information</h6>
        <Col lg={6} md={6} sm={12}>
          <Form.Group className="mb-2" controlId="formBasicPricing">
            <MadateLabel label="Weight (in grams)" />
            <Form.Control
              type="text"
              placeholder="Weight"
              defaultValue={product.weight}
              name="weight"
              onChange={inputChangeHandler}
            />
          </Form.Group>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Group className="mb-2" controlId="formBasicPricing">
            <MadateLabel label="Dimensions" />
            <Form.Control
              type="text"
              placeholder="Dimensions: Length x Width x Height"
              defaultValue={product.dimensions}
              name="dimensions"
              onChange={inputChangeHandler}
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row>
        <h6 className="font-bold mb-2">Packaging Information</h6>
        <Row>
          <Col>
            <Form.Group className="mb-2" controlId="formBasicPricing">
              <MadateLabel label="Manufacturer" />
              <Form.Control
                type="text"
                placeholder="Manufacturer"
                defaultValue={product.mfg}
                name="mfg"
                onChange={inputChangeHandler}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-2" controlId="formBasicPricing">
              <Form.Label className="">Importer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Importer"
                defaultValue={product.importer}
                name="importer"
                onChange={inputChangeHandler}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-2" controlId="formBasicPricing">
              <Form.Label className="">Packer Details</Form.Label>
              <Form.Control
                type="text"
                placeholder="Packer Details"
                defaultValue={product.packer_details}
                name="packer_details"
                onChange={inputChangeHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <Form.Group className="mb-2" controlId="formBasicPricing">
              <Form.Label className="">Fssai no.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Fssai no."
                defaultValue={product.fssai_no}
                name="fssai_no"
                onChange={inputChangeHandler}
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Form.Group className="mb-2" controlId="formBasicPricing">
              <Form.Label className="">Expiry Date</Form.Label>
              <ReactDatePicker
                placeholderText="Expiry Date"
                selected={expiry_details}
                className="form-control"
                onChange={(date) => {
                  setExpiryDetails(date);
                }}
                minDate={new Date()}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </Form.Group>
          </Col>
        </Row>
      </Row>
      <hr />
      <Row>
        <h6 className="font-bold mb-2">Tax Information</h6>
        <Col>
          <Form.Group className="mb-2" controlId="formBasicPricing">
            <MadateLabel label="HSN Code" />
            <Form.Control
              type="text"
              placeholder="HSN"
              defaultValue={product.hsn}
              name="hsn"
              onChange={inputChangeHandler}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-2" controlId="formBasicPricing">
            <MadateLabel label="Tax (%)" />
            <select
              id="gst"
              name="gst"
              className="form-select"
              defaultValue={product.gst}
              onChange={inputChangeHandler}
            >
              {tax.map((data) => (
                <option value={data.value}>{data.label}</option>
              ))}
            </select>
          </Form.Group>
        </Col>
      </Row>
    </Box>
  );
};

export default InventoryOtherInfo;
