import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Stats() {
  return (
    <section className="stats py-sm-4 py-md-5">
      <Container className="mx-sm-0 w-100 stats_container">
        <Row className="w-100 mx-0 justify-content-center">
          <Col sm="6" md="6" lg="3" className="my-2 stats__col">
            <div className="stats-card p-4 " style={{ background: "#e0e0e0" }}>
              <h2>10,000+</h2>
              <h5 className="mt-3">Sellers already register on platform</h5>
            </div>
          </Col>
          <Col sm="6" md="6" lg="3" className="my-2 stats__col">
            <div className="stats-card p-4 " style={{ background: "#e0e0e0" }}>
              <h2>1 Lac +</h2>
              <h5 className="mt-3">Buying Customers</h5>
            </div>
          </Col>
          <Col sm="6" md="6" lg="3" className="my-2 stats__col">
            <div className="stats-card p-4 " style={{ background: "#e0e0e0" }}>
              <h2>3000+</h2>
              <h5 className="mt-3">Orders processed Daily</h5>
            </div>
          </Col>
          <Col sm="6" md="6" lg="3" className="my-2 stats__col">
            <div className="stats-card p-4 " style={{ background: "#e0e0e0" }}>
              <h2>1600+</h2>
              <h5 className="mt-3">Categories to sell online</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Stats;
