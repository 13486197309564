import React from "react";
import { GenericButton } from "../../../../compoents/Button";
import { StyledHeroSection } from "./HeroStyled";

function HeroSection({
  heading1,
  heading2,
  desc,
  buttonText,
  rightImgSrc,
  bgImg,
  onClick,
  isParallax = false,
}) {
  return (
    <StyledHeroSection>
      <div
        className={`hero__bg ${isParallax ? "parallax" : ""}`}
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="hero__bg-overlay" />
      </div>
      <div className="hero__section hero__section--topdiv">
        <div className="hero__section--details">
          <h1 className="hero_heading">
            <span className="hero__heading--highlight">{heading1}</span>
            <br />
            {heading2}
          </h1>
          <p className="hero__desc">{desc}</p>
          <GenericButton onClick={onClick} className="hero__button">
            {buttonText}
          </GenericButton>
        </div>
      </div>
      <div className="hero__section hero__section--bottomdiv">
        <img src={rightImgSrc} className="hero__img" alt="hero_img" />
      </div>
    </StyledHeroSection>
  );
}

export default HeroSection;
