import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Formik, Form as FormikForm } from "formik";
import { IoIosArrowBack } from "react-icons/io";
import * as Yup from "yup";
import { OutlinedTextFields } from "../../compoents/Forms2";
import ErrorMessage from "../../compoents/ErrorMessage";
import { GenericButton } from "../../compoents/Button";
import DatePicker from "react-datepicker";
import CustomDatePicker from "./CustomDatePicker";
import useWallet from "../../hooks/useWallet";
import {
  formatNumberToCurrency,
  getDateDiff,
  isPast,
  isToday,
} from "../../utilities";
import CampaignInventory from "./CampaignInventory";
import useHandleStep from "./useHandleStep";
import CampaignCostSplit from "./CampaignCostSplit";
import { toast } from "react-toastify";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import CampaignService from "../../redux/services/campaign.service";
import { Link } from "react-router-dom";
const StartCampaignFormStyled = styled.div`
  background: #fff;
  border-radius: 8px;
  position: relative;
  min-height: 70vh;
  h6 {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
  }
  .campaignForm {
    max-width: 1200px;
    padding: 12px 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: max-content;
    margin: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    .campaignForm__formControl {
      display: grid;
      grid-template-columns: 200px 500px;
      place-items: flex-end;
      align-items: center;
      gap: 8px;
      .campaignForm__label {
        font-size: 14px;
        font-weight: 600;
      }
      .react-datepicker-wrapper {
        display: flex;
        align-items: center;
      }
    }
    .campaignForm__actionBtns {
      margin-left: auto;
      display: flex;
      gap: 8px;
      .campaignForm__btn {
        min-width: 100px;
        width: fit-content;
        &:disabled {
          background: grey;
          border: none;
          cursor: not-allowed;
        }
      }
    }
    .campaignForm__totalCost {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      position: relative;
      padding-bottom: 18px;
      .campaignForm__totalCostErr {
        font-size: 14px;
        color: var(--error-color);
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
      }
    }
  }
  .error_msg {
    position: absolute;
    margin: 0 !important;
    bottom: 0;
    transform: translateY(100%);
    font-size: 14px;
  }
  .campaignForm__budget {
    display: flex;
    align-items: center;
    gap: 8px;
    input {
      flex: 1;
    }
    .campaignForm__balanceAmt {
      font-size: 14px;
      min-width: fit-content;
    }
  }
`;

function StartCampaignForm({ goToDashboard }) {
  const pageObj = {
    page1: "main",
    page2: "product",
    page3: "final",
  };
  const auth = useAuth();
  const token = auth.mee_too_user.token;
  const campaignApiService = new CampaignService();
  const { fetchWalletDetail, total } = useWallet(true);
  const [customError, setCustomError] = useState(null);
  const [avlBalance, setAvlBalance] = useState(0);
  const { step, goToStep } = useHandleStep(pageObj.page1);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [finalData, setFinalData] = useState({
    name: "",
    amount: "",
    product: [],
    startDate: "",
    endDate: "",
  });

  const initVal = {
    name: finalData.name,
    startDate: finalData.startDate,
    endDate: finalData.endDate,
    amount: finalData.amount,
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Please enter campaign name."),
    amount: Yup.number().required("Please enter daily budget."),
    startDate: Yup.string().required("Please select start date."),
    endDate: Yup.string().required("Please select end date."),
  });

  useEffect(() => {
    fetchWalletDetail();
  }, []);

  const getTotalCampaignAmount = (days, amount) => {
    const amountPerHourPerDay = amount / 24;
    const totalCost = days * amountPerHourPerDay;
    console.log("total cost:", days, amount, amountPerHourPerDay, totalCost);
    setAvlBalance(totalCost.toFixed(2));
  };

  const handleFormSubmit = (data) => {
    console.log("Submit data", data);
    setFinalData((prev) => ({ ...prev, ...data }));
    goToStep(pageObj.page2);
  };

  useEffect(() => {
    if (avlBalance > total) {
      setCustomError(true);
    } else {
      setCustomError(null);
    }
  }, [avlBalance, total]);

  const handleProductSelect = (data, isSelected, isSingle) => {
    if (!isSingle) {
      const selectedData = data.map((d) => ({
        id: d.id,
        cost: d.cost,
        name: d.name,
        title_image: d.title_image,
      }));
      return setFinalData((prev) => ({ ...prev, product: selectedData }));
    }
    if (isSelected) {
      const newData = [...finalData.product];
      newData.push({
        id: data.id,
        cost: data.cost,
        name: data.name,
        title_image: data.title_image,
      });
      // finalData.product.push(data);
      return setFinalData((prev) => ({ ...prev, product: newData }));
    } else if (!isSelected) {
      const newData = finalData.product.filter((row) => row.id !== data.id);
      return setFinalData((prev) => ({ ...prev, product: newData }));
    }
  };

  const removeAllSelected = () => {
    setFinalData((prev) => ({ ...prev, product: [] }));
  };

  const handleProductSubmit = () => {
    if (!finalData.product.length) {
      return toast.error("Select atleast 1 product.");
    }
    goToStep(pageObj.page3);
  };

  const updateProductDailyCost = (list) => {
    const _list = list.map((product) => {
      let cost = product?.cost;
      if (!product.cost) cost = 0;
      return { ...product, cost };
    });
    setFinalData((prev) => ({ ...prev, product: _list }));
  };

  const handleCampaignCreate = async () => {
    try {
      setIsSubmiting(true);
      console.log("FinalData::", finalData);
      const res = await campaignApiService.create_campaign(finalData, token);
      const { status, data, msg } = res.data;
      setIsSubmiting(false);
      if (status === "error")
        return toast.error(msg ?? "Please try after sometime");
      toast.success("Campaign scheduled.");
      goToDashboard();
    } catch (error) {
      console.log("Campaign create error", error);
      setIsSubmiting(false);
    }
  };

  return (
    <StartCampaignFormStyled>
      <h6>
        <IoIosArrowBack
          size={24}
          role="button"
          onClick={() => {
            switch (step) {
              case pageObj.page1:
                goToDashboard();
                break;
              case pageObj.page2:
                goToStep(pageObj.page1);
                break;
              case pageObj.page3:
                goToStep(pageObj.page2);
                break;
              default:
                goToStep(pageObj.page1);
                break;
            }
          }}
        />
        {step === pageObj.page1 && "New Campaign"}
        {step === pageObj.page2 &&
          `Select Product (${finalData.product?.length} selected)`}
        {step === pageObj.page3 &&
          `Start Campaign (${finalData.product?.length} selected)`}
      </h6>
      {step === pageObj.page1 && (
        <Formik
          validationSchema={schema}
          initialValues={initVal}
          onSubmit={handleFormSubmit}
        >
          {({ errors, values, setFieldValue, touched }) => (
            <FormikForm className="campaignForm">
              {avlBalance ? (
                <div>
                  <div className="campaignForm__totalCost">
                    Total Campaign Cost : Rs. {avlBalance}/-
                    {customError && (
                      <p className="campaignForm__totalCostErr">
                        No sufficient balance in wallet.{" "}
                        <Link to="/settings?tab=7">Click Here</Link>
                      </p>
                    )}
                  </div>
                  <hr className="m-0" />
                </div>
              ) : null}
              <div className="campaignForm__formControl">
                <label className="campaignForm__label" htmlFor="name">
                  Campaign Name:
                </label>

                <div className="w-100 position-relative">
                  <OutlinedTextFields
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Enter your campaign name."
                    value={values.name}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                    hasError={touched.name && errors.name}
                    autoComplete="off"
                  />
                  {touched.name && errors.name && (
                    <ErrorMessage className="error_msg" message={errors.name} />
                  )}
                </div>
              </div>
              <div className="campaignForm__formControl">
                <label className="campaignForm__label" htmlFor="name">
                  Add Daily Budget:
                </label>
                <div className="w-100 position-relative campaignForm__budget">
                  <OutlinedTextFields
                    id="amount"
                    type="number"
                    name="amount"
                    placeholder="Enter daily budget."
                    value={values.amount}
                    onChange={(e) => {
                      const costPerDay = e.target.value;
                      setFieldValue("amount", costPerDay);
                      if (values?.startDate && values?.endDate) {
                        getTotalCampaignAmount(
                          getDateDiff(values.startDate, values.endDate),
                          costPerDay
                        );
                      }
                    }}
                    hasError={touched.amount && errors.amount}
                  />
                  <span
                    className={`campaignForm__balanceAmt ${
                      customError ? "text-danger" : null
                    }`}
                  >
                    ( Avl. - Rs. {formatNumberToCurrency(total)} )
                  </span>
                  {touched.amount && errors.amount && (
                    <ErrorMessage
                      className="error_msg"
                      message={errors.amount}
                    />
                  )}
                </div>
              </div>
              <div className="campaignForm__formControl">
                <label className="campaignForm__label" htmlFor="name">
                  Starts At:
                </label>
                <div className="w-100 position-relative">
                  <DatePicker
                    selected={
                      values.startDate ? new Date(values.startDate) : ""
                    }
                    className="my-2 form-control"
                    minDate={new Date()}
                    minTime={
                      isToday(values.startDate)
                        ? new Date().getTime()
                        : new Date().setHours(0o0, 0o0, 0o0)
                    }
                    maxTime={new Date().setHours(23, 59, 59)}
                    onChange={(date) => {
                      let newStartDate = new Date(date).getTime();
                      if (isPast(date)) {
                        const hrs = new Date().getHours();
                        newStartDate = new Date(date).setHours(
                          hrs + 1,
                          0o0,
                          0o0
                        );
                      }
                      setFieldValue("startDate", newStartDate);
                      // setFieldValue("startDate", new Date(date).getTime());
                      setFieldValue("endDate", "");
                      setAvlBalance(0);
                    }}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showPopperArrow={false}
                    customInput={<CustomDatePicker />}
                    placeholderText="Select start date"
                    // disabled={!values?.amount}
                  />
                  {touched.startDate && errors.startDate && (
                    <ErrorMessage
                      className="error_msg"
                      message={errors.startDate}
                    />
                  )}
                </div>
              </div>
              <div className="campaignForm__formControl">
                <label className="campaignForm__label" htmlFor="name">
                  Ends At:
                </label>
                <div className="w-100 position-relative">
                  <DatePicker
                    selected={values.endDate ? new Date(values.endDate) : ""}
                    className="my-2 form-control"
                    minDate={new Date(values.startDate + 86400000 ?? "")}
                    onChange={(date) => {
                      const dateDiff = getDateDiff(
                        new Date(date),
                        new Date(values.startDate)
                      );
                      let newEndDate = new Date(date).getTime();
                      if (dateDiff < 24) {
                        newEndDate = new Date(date).setHours(
                          new Date(values.startDate).getHours(),
                          new Date(values.startDate).getMinutes()
                        );
                        getTotalCampaignAmount(24, values.amount);
                      } else {
                        getTotalCampaignAmount(dateDiff, values.amount);
                      }
                      // setFieldValue("endDate", new Date(date).getTime());
                      setFieldValue("endDate", newEndDate);
                    }}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showPopperArrow={false}
                    customInput={<CustomDatePicker />}
                    placeholderText="Select End date"
                    disabled={!values.startDate}
                  />
                  {touched.endDate && errors.endDate && (
                    <ErrorMessage
                      className="error_msg"
                      message={errors.endDate}
                    />
                  )}
                </div>
              </div>

              <hr className="m-0" />
              <div className="campaignForm__actionBtns">
                <GenericButton
                  disabled={customError}
                  type="submit"
                  className="campaignForm__btn"
                >
                  Select Product
                </GenericButton>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
      {step === pageObj.page2 && (
        <CampaignInventory
          onSelect={handleProductSelect}
          removeAllSelected={removeAllSelected}
          selected={finalData.product.map((row) => row.id)}
          handleSubmit={handleProductSubmit}
        />
      )}
      {step === pageObj.page3 && (
        <CampaignCostSplit
          product={finalData.product}
          budget={finalData?.amount ? parseFloat(finalData.amount ?? 0) : 0}
          updateCost={updateProductDailyCost}
          handleSubmit={handleCampaignCreate}
        />
      )}
      {/* <ul>
        {finalData.product.map((row) => (
          <li key={row.id}>
            <pre>{JSON.stringify(row)}</pre>
          </li>
        ))}
      </ul> */}
    </StartCampaignFormStyled>
  );
}

export default StartCampaignForm;
