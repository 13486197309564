import React, {useEffect, useState} from "react";
import {Form, Row, Col} from "react-bootstrap";
import {
    addProductSelector,
    setProductOffer,
} from "../../redux/slices/product";
import {useDispatch, useSelector} from "react-redux";
import {Formik, Form as FormikForm} from "formik";
import {OutlinedTextFields} from "../../compoents/Forms2";
import * as Yup from "yup";
import {GENERIC_MESSAGE, TOAST_STYLE} from "../../config";
import ErrorMessage from "../../compoents/ErrorMessage";
import {GenericButton} from "../../compoents/Button";
import Select from "react-select";
import {toast} from "react-toastify";
import {useAuth} from "../../Navigation/Auth/ProvideAuth";
import SellerApiService from "../../redux/services/seller-api-service";
// import { ProductAddContext } from "./addProduct"

const OfferDataSchema = Yup.object().shape({
    seller_sku: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    gst: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    hsn: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    weight: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    dimensions: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    your_price: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    retail_price: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
    group_buy_price: Yup.string().required(
        GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE
    ),
    offer_quantity: Yup.string().required(GENERIC_MESSAGE.REQUIRED_FIELD_MESSAGE),
});

const Offer = (props) => {
    let {finalData, setFinalData, setTabActiveKey} = props;
    // let { setTabActiveKey } = props;
    //
    const dispatch = useDispatch();
    const [product, setProduct] = useState({});
    let auth = useAuth();
    const sellerApiService = new SellerApiService(auth.mee_too_user.token);
    //
    // const { finalData,setFinalData } = React.useContext(ProductAddContext)
    //
    // const { product_offer } = useSelector(addProductSelector);
    //
    const [tax, setTax] = React.useState([
        {value: 0, label: "0%"},
        {value: 3, label: "3%"},
        {value: 5, label: "5%"},
        {value: 12, label: "12%"},
        {value: 18, label: "18%"},
        {value: 28, label: "28%"},
    ]);
    useEffect(() => {
        let product = JSON.parse(localStorage.getItem("product"));
        setProduct(product);
    }, []);

    return (
        <div>
            <Formik
                initialValues={{
                    seller_sku: product.sku,
                    gst: product.gst,
                    hsn: product.hsn,
                    weight: product.weight,
                    dimensions: product.dimensions,
                    your_price: product.selling_price,
                    retail_price: product.mrp,
                    group_buy_price: product.group_price,
                    offer_quantity: product.stock,
                }}
                validationSchema={OfferDataSchema}
                enableReinitialize={true}
                onSubmit={async (values) => {
                    let data = {
                        ...product
                    };
                    data.selling_price = values.your_price;
                    data.mrp = values.retail_price;
                    data.group_price = values.group_buy_price;
                    data.stock = values.offer_quantity;
                    data.gst = values.gst;
                    data.hsn = values.hsn;
                    data.weight = values.weight;
                    data.dimensions = values.dimensions;
                    data.category = product.category.id;

                    const res = await sellerApiService.updateProduct(data);
                    if (res.data) {
                        toast.success(
                            "Product updated successfully",
                            TOAST_STYLE
                        );
                        const res = await sellerApiService.getProduct(data.id);
                        if (res.data) {
                            const {product} = res.data;
                            localStorage.setItem("product", JSON.stringify(product));
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    }
                }}
            >
                {({
                      errors,
                      values,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      setFieldTouched,
                  }) => (
                    <FormikForm>
                        <div className="px-0 px-md-5 py-3">
                            <h4 className="border-bottom pb-2">Tax Information</h4>
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            HSN Code
                                        </Form.Label>
                                        <OutlinedTextFields
                                            className="my-2"
                                            name="hsn"
                                            type="text"
                                            placeholder="HSN COde"
                                            onChange={(e) =>
                                                setFieldValue("hsn", e.target.value)
                                            }
                                            // onChange={handleChange}
                                            // onBlur={handleBlur}
                                            value={values.hsn}
                                        />
                                        {touched.hsn && errors.hsn && (
                                            <ErrorMessage message={errors.hsn}/>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            Tax (%)
                                        </Form.Label>
                                        <Select
                                            options={tax}
                                            placeholder="Search tax"
                                            onChange={(selected) => {
                                                setFieldValue("gst", selected.value)
                                            }}
                                            className="my-2"
                                        />
                                        {touched.gst && errors.gst && (
                                            <ErrorMessage message={errors.gst}/>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h4 className="border-bottom pb-2">Delivery Information</h4>
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            Weight
                                        </Form.Label>
                                        <OutlinedTextFields
                                            className="my-2"
                                            name="weight"
                                            type="text"
                                            placeholder="Weight"
                                            onChange={(e) =>
                                                setFieldValue("weight", e.target.value)
                                            }
                                            // onChange={handleChange}
                                            // onBlur={handleBlur}
                                            value={values.weight}
                                        />
                                        {touched.weight && errors.weight && (
                                            <ErrorMessage message={errors.weight}/>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            Dimensions
                                        </Form.Label>
                                        <OutlinedTextFields
                                            className="my-2"
                                            name="dimensions"
                                            type="text"
                                            placeholder="Dimensions: Length x Width x Height"
                                            onChange={(e) =>
                                                setFieldValue("dimensions", e.target.value)
                                            }
                                            // onChange={handleChange}
                                            // onBlur={handleBlur}
                                            value={values.dimensions}
                                        />
                                        {touched.dimensions && errors.dimensions && (
                                            <ErrorMessage message={errors.dimensions}/>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h4 className="border-bottom pb-2">Offer Information</h4>
                            <Row className="mb-3">
                                <Col md={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            Seller SKU
                                        </Form.Label>
                                        <OutlinedTextFields
                                            className="my-2"
                                            name="seller_sku"
                                            type="text"
                                            placeholder="Seller SKU"
                                            onChange={(e) =>
                                                setFieldValue("seller_sku", e.target.value)
                                            }
                                            // onChange={handleChange}
                                            // onBlur={handleBlur}
                                            value={values.seller_sku}
                                        />
                                        {touched.seller_sku && errors.seller_sku && (
                                            <ErrorMessage message={errors.seller_sku}/>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            Your Price
                                        </Form.Label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">RS</div>
                                            </div>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="inlineFormInputGroup"
                                                name="your_price"
                                                onChange={(e) =>
                                                    setFieldValue("your_price", e.target.value)
                                                }
                                                value={values.your_price}
                                            />
                                        </div>
                                        {touched.your_price && errors.your_price && (
                                            <ErrorMessage message={errors.your_price}/>
                                        )}
                                        {/* <Form.Control type="text" placeholder="Item Name" /> */}
                                    </Form.Group>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            Maximum Retail Price
                                        </Form.Label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">RS</div>
                                            </div>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="inlineFormInputGroup"
                                                name="retail_price"
                                                onChange={(e) =>
                                                    setFieldValue("retail_price", e.target.value)
                                                }
                                                value={values.retail_price}
                                            />
                                        </div>
                                        {touched.retail_price && errors.retail_price && (
                                            <ErrorMessage message={errors.retail_price}/>
                                        )}
                                        {/* <Form.Control type="text" placeholder="Item Name" /> */}
                                    </Form.Group>
                                </Col>
                                <Col xl={3} lg={4} md={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            Group buy Price
                                        </Form.Label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">RS</div>
                                            </div>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="inlineFormInputGroup"
                                                name="group_buy_price"
                                                onChange={(e) =>
                                                    setFieldValue("group_buy_price", e.target.value)
                                                }
                                                value={values.group_buy_price}
                                            />
                                        </div>
                                        {touched.group_buy_price && errors.group_buy_price && (
                                            <ErrorMessage message={errors.group_buy_price}/>
                                        )}
                                        {/* <Form.Control type="text" placeholder="Item Name" /> */}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col lg={6} xs={12}>
                                    <Form.Group as={Col} controlId="formGridItemName">
                                        <Form.Label className="" style={{color: "#122736"}}>
                                            Quantity
                                        </Form.Label>
                                        {/* <Form.Control
											type="text"
											placeholder="Quantity"
											name="offer_quantity"
										/> */}
                                        <OutlinedTextFields
                                            // className="my-2"
                                            name="offer_quantity"
                                            type="text"
                                            placeholder="Offer Quantity"
                                            onChange={(e) =>
                                                setFieldValue("offer_quantity", e.target.value)
                                            }
                                            // onChange={handleChange}
                                            // onBlur={handleBlur}
                                            value={values.offer_quantity}
                                        />
                                        {touched.offer_quantity && errors.offer_quantity && (
                                            <ErrorMessage message={errors.offer_quantity}/>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="mt-5 text-center">
                                <GenericButton type="submit">Save</GenericButton>
                            </div>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default Offer;
