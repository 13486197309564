import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { ImAttachment } from "react-icons/im";
import { MdSend, MdOutlineClose } from "react-icons/md";
import styled from "styled-components";
import TicketMessage from "./TicketMessage";
import supportService from "../../../redux/services/support.service";
import { toast } from "react-toastify";

const StyledChat = styled.div`
  height: calc(100% - 24px);
  position: relative;
  overflow: hidden;
  padding: 12px 4px 56px 12px;
  max-height: 500px;
  box-shadow: 0 0 6px #b3adad inset;
  border-radius: 8px;
  .ticketChat__messages {
    height: 100%;
    overflow: hidden;
  }
  .ticketChat__form {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    // border-top: 1px solid #ddd;
    height: 56px;
    width: 100%;
    left: 0;
    padding: 12px;
    gap: 8px;
    .ticketChat__textField {
      height: 100%;
      flex: 1;
      resize: none;
      border: 1px solid #b3adad;
      border-radius: 4px;
      outline: none;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      font-size: 14px;
    }
    .ticketChat__sendBtn {
      border: none;
      padding: 0;
      background: no-repeat;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .filePreview__container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 12px;
    margin-bottom: 47px;
    width: 100%;
    .filePreview__section {
      width: 100%;
      display: flex;
      align-items: center;
      max-width: 400px;
      gap: 8px;
      .filePreview__filename {
        max-width: 100%;
        width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        color: grey;
      }
      .filePreview__closeIcon {
        min-width: fit-content;
        max-width: fit-content;
        flex: 1;
      }
    }
  }
`;

function TicketChat({ status, token, id, handleChat }) {
  const [msg, setMsg] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!msg) return;
    setSubmiting(true);
    const formData = new FormData();
    formData.append("description", msg);
    formData.append("file", file);
    const payload = {
      id,
      formData,
      token,
    };
    supportService
      .sendMsg(payload)
      .then((result) => {
        const { status, data } = result.data;
        setSubmiting(false);
        if (!status || status === "error") {
          return toast.error("Something went wrong!");
        }
        setMsg("");
        setFile(null);
        handleChat(data);
      })
      .catch((err) => {
        setSubmiting(false);
        return toast.error("Try again later!");
      });
  };

  const browseFile = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    } else {
      setFile(null);
    }
  };

  const removeSelectedFile = () => {
    setFile(null);
  };

  return (
    <StyledChat
      style={{
        paddingBottom: !["open", "re_open"].includes(status.toLowerCase())
          ? "12px"
          : file
          ? "72px"
          : "56px",
      }}
    >
      <div className="ticketChat__messages">
        <TicketMessage />
      </div>
      {file && (
        <div className="filePreview__container">
          <div className="filePreview__section">
            <div className="filePreview__filename">{file.name}</div>
            <MdOutlineClose
              size={18}
              role="button"
              className="filePreview__closeIcon"
              onClick={removeSelectedFile}
            />
          </div>
        </div>
      )}
      {["open", "re_open"].includes(status.toLowerCase()) && (
        <form className="ticketChat__form" onSubmit={handleSubmit}>
          <textarea
            className="ticketChat__textField"
            placeholder="Enter message"
            onChange={(e) => setMsg(e.target.value)}
            value={msg}
          />
          <>
            <input
              type="file"
              hidden
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <ImAttachment size={24} role="button" onClick={browseFile} />
          </>
          <button
            className="ticketChat__sendBtn text-primary"
            disabled={msg.length < 1 || submiting}
            type="submit"
          >
            <MdSend size={24} />
          </button>
        </form>
      )}
    </StyledChat>
  );
}

export default TicketChat;
