import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Alert, Form, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import Box from "../../compoents/Box";
import { GenericButton } from "../../compoents/Button";
import SellerApiService from "../../redux/services/seller-api-service";
import ProductDataService from "../../redux/services/product.service";
import { productImageUploadThunk } from "../../redux/slices/product";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { TOAST_STYLE } from "../../config";

import { BiImage } from "react-icons/bi";
import { IoIosClose, IoMdClose } from "react-icons/io";

const StyledTable = styled("div")`
  overflow-x: auto;
  .table td {
    min-width: 135px;
  }
  .error-row {
    // background-color: rgb(227 1 1 / 47%);
    background-color: #ff000575;
    border: 1px solid #fff;
  }
  .image-info {
    position: absolute;
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    bottom: 0;
    margin-bottom: 4px;
    color: var(--primary-color);
  }
`;

const InventoryVariations = (
  { product, category, saveVariations, updatedData },
  ref
) => {
  const [selectedVariations, setSelectedVariations] = useState([]);
  const hiddenFileInput = useRef(null);
  const [deletedVariationImages, setDeletedVariationImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [errorRow, setErrorRow] = useState([]);

  useImperativeHandle(ref, () => ({
    validateVariation: () => {
      const errorRowCount = [];
      const firstDefaultVariantList = [];
      const errorId = [];
      for (let index in selectedVariations) {
        index = parseInt(index);
        const variant = selectedVariations[index];
        const option_key_list = Object.keys(variant.options_list);
        const defaultOption = variant.default_option;
        if (index === 0) {
          // check for default row
          if (
            !variant.sku ||
            !variant.mrp ||
            !variant.group_price ||
            !variant.selling_price ||
            !variant.stock ||
            !(variant.images.length > 2)
          ) {
            errorId.push(1);
            errorRowCount.push(index);
          } else if (
            variant.mrp < variant.selling_price ||
            variant.selling_price < variant.group_price
          ) {
            errorId.push(2);
            errorRowCount.push(index);
          } else {
            firstDefaultVariantList.push(defaultOption);
          }
        } else if (
          //check if all rows have input field filled except 1st row
          !variant.sku ||
          !variant.mrp ||
          !variant.group_price ||
          !variant.selling_price ||
          !variant.stock ||
          !(
            variant.options_list[option_key_list[0]] &&
            variant.options_list[option_key_list[1]]
          )
        ) {
          errorId.push(3);
          errorRowCount.push(index);
        } else if (
          variant.mrp < variant.selling_price ||
          variant.selling_price < variant.group_price
        ) {
          errorId.push(4);
          errorRowCount.push(index);
        } else if (
          !(variant.images.length > 2) &&
          !firstDefaultVariantList.includes(defaultOption)
        ) {
          errorId.push(5);
          errorRowCount.push(index);
        } else if (!(variant.images.length > 2)) {
          errorRowCount.push(index);
        } else if (
          variant.images.length > 2 &&
          !firstDefaultVariantList.includes(defaultOption)
        ) {
          firstDefaultVariantList.push(defaultOption);
        }
      }

      const errorRowList = Array.from(new Set(errorRowCount));
      setErrorRow(errorRowList);
      // setValidating(false);
      if (errorRowList.length) {
        return true;
      }
      return false;
    },
  }));

  const auth = useAuth();
  const sellerApiService = new SellerApiService(auth.mee_too_user.token);

  const addVariation = () => {
    let opt_types = {};
    if (category?.option_types) {
      opt_types = category?.option_types?.reduce(
        (accumulator, currentValue) => {
          accumulator[currentValue?.name] = null;
          return accumulator;
        },
        {}
      );
    }
    let temp = { ...product };
    temp.color = "";
    temp.size = "";
    temp.sku = "";
    temp.parent = product.id;
    temp.category = product.category.id;
    temp.mrp = null;
    temp.group_price = null;
    temp.selling_price = null;
    temp.stock = null;
    temp.images = [];
    temp.seller = product.seller;
    temp.specifications = "[]";
    temp.options = "[]";
    temp.name = updatedData.name;
    temp.description = updatedData.description;
    temp.id = null;
    temp.options_list = opt_types;

    setSelectedVariations([...selectedVariations, temp]);
  };

  const inputChangeHandler = (e, variationIndex) => {
    let value = e.target.value;
    if (["mrp", "selling_price", "group_price"].includes(e.target.name)) {
      value = Number(value);
    }
    const tempVariations = [...selectedVariations];
    tempVariations[variationIndex][e.target.name] = value;
  };

  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const checkFileSize = (file) => {
    const fileSize = file.size;
    const maxFileSize = 204800; //200Kb = 200*1024
    return fileSize < maxFileSize;
  };

  function uploadFile(e, variationIndex) {
    if (e.target.files.length < 1) {
      toast.error("Please select image.", TOAST_STYLE);
      return;
    }
    const file = e.target.files[0];
    if (!isValidFileUploaded(file)) {
      return toast.error("Image should be of format .png, .jpeg, .jpg.");
    }
    if (!checkFileSize(file)) {
      return toast.error("Image size should not exceed 200Kb.");
    }
    let tempVariations = [...selectedVariations];
    if (tempVariations[variationIndex].images.length < 5) {
      tempVariations[variationIndex].images.push(file);
      setSelectedVariations(tempVariations);
    } else {
      toast.error("You can upload only 5 images", TOAST_STYLE);
    }
  }

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  function getDataURI(data) {
    // Check if data parameter is a string or an object
    if (typeof data === "string") {
      // If it is a base64 string, convert it to File Object and return
      return dataURItoBlob(data);
    } else if (typeof data === "object") {
      // If it is an object, check if it is a file object
      return data;
    }
  }

  const imageSrc = (image) => {
    const imgSrcUrl = "http://api.groupick.in/media/";
    // const imgSrcUrl = "http://localhost:8000/media/";

    image = getDataURI(image);
    if (image?.file?.includes("/")) {
      return imgSrcUrl + image.file;
    } else {
      return window.URL?.createObjectURL(image);
    }
  };

  const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  };

  const saveAndContinue = async () => {
    // let oneImageCheck;
    // selectedVariations.map((v) => {
    //   if (v.images.length === 0) oneImageCheck = true;
    // });

    // if (oneImageCheck) {
    //   // return {
    //   //   status: false,
    //   //   message: "Add at least one image for each variant.",
    //   // };
    // }

    deletedVariationImages.forEach((image) => {
      sellerApiService
        .deleteProductImages(product.id, image.id)
        .then((res) => {});
    });
    for (let data of selectedVariations) {
      if (data?.id == null) {
        const variant_obj = {
          parent: product.id,
          name: product.name,
          description: product.description,
          mrp: data.mrp,
          stock: data.stock,
          selling_price: data.selling_price,
          group_price: data.group_price,
          sku: data.sku,
          category: product.category.id,
          specifications: "[]",
          options: "[]",
          options_list: data?.options_list
            ? JSON.stringify(data?.options_list)
            : "",
        };
        let d = new FormData();
        for (let key in variant_obj) {
          d.append(key, variant_obj[key]);
        }
        let finalPayload = {
          header: auth,
          addProductFormData: d,
        };
        const res = await ProductDataService.createProduct(finalPayload);
        if (res.status == 201) {
          let p = res.data;
          let index = getRandomIntInclusive(9999, 999999);
          for (let image of data.images) {
            if (!image.file?.includes("/")) {
              let formData = new FormData();
              formData.append("file", image);
              formData.append("sequence", index);
              let finalPayload = {
                uploadImage: formData,
                productId: p.id,
                header: auth,
              };
              dispatch(productImageUploadThunk(finalPayload));
              index++;
            }
          }
        }
      } else {
        data.category = product.category.id;
        data.seller = product.seller;
        const res = await sellerApiService.updateProduct(data);
        if (res.data) {
          let index = getRandomIntInclusive(9999, 999999);
          for (let image of data.images) {
            if (!image.file?.includes("/")) {
              let formData = new FormData();
              formData.append("file", image);
              formData.append("sequence", index);
              let finalPayload = {
                uploadImage: formData,
                productId: data.id,
                header: auth,
              };
              dispatch(productImageUploadThunk(finalPayload));
              index++;
            }
          }
        }
      }
    }
    const res = await sellerApiService.getProduct(product.id);
    if (res.data) {
      toast.success("Product updated successfully", TOAST_STYLE);
      const { product } = res.data;
      localStorage.setItem("product", JSON.stringify(product));
    }
  };

  useEffect(() => {
    setLoading(true);
    product?.variants?.forEach(async (variation, index) => {
      let res = await sellerApiService.getProductImages(variation.id);
      const { image } = res.data;
      if (image.length > 0) product.variants[index].images = image;
      else product.variants[index].images = [];
    });

    setSelectedVariations(product.variants);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (saveVariations) {
      saveAndContinue();
    }
  }, [saveVariations]);

  if (loading) {
    return (
      <div className="d-flex align-items-center jusitfy-content-center h-100 w-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Box className="my-3">
      <Row className="p-4  display-flex justify-content-between">
        <div className="col col-md-4">
          <h6 className="font-bold w-100 weight-bold">Variations</h6>
        </div>
        <div className="col col-md-4 text-end">
          <GenericButton onClick={addVariation}>Add Variation</GenericButton>
        </div>
      </Row>
      <StyledTable>
        <table
          className="table text-center fw-bold"
          style={{ fontSize: "12px", overflowX: "auto" }}
        >
          {selectedVariations.length > 0 ? (
            <>
              <thead className="bg-dark text-white">
                <tr>
                  {category?.option_types?.map((option, index) => (
                    <th key={index}>{option.name}</th>
                  ))}
                  <th>Seller SKU</th>
                  {/* <th>Product ID</th> */}
                  <th>MRP</th>
                  <th>Selling Price</th>
                  <th>Group Buy Price</th>
                  <th>Quantity</th>
                  <th>Images</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectedVariations.map((variation, variationIndex) => {
                  return (
                    <tr
                      className={`position-relative ${
                        errorRow.includes(variationIndex) && "error-row"
                      }`}
                      key={variationIndex}
                    >
                      {category?.option_types?.map((option, index) => (
                        <td key={index}>
                          <select
                            className="form-select"
                            value={
                              selectedVariations[variationIndex][
                                "options_list"
                              ][option.name]
                            }
                            onChange={(e) => {
                              // selectedVariations[variationIndex].color = e.target.value;
                              // use option's value as name of object property
                              // selectedVariations[variationIndex][option] =
                              //   e.target.value;
                              // setSelectedVariations([...selectedVariations]);
                              const selected_option = e.target.value;
                              selectedVariations[variationIndex][
                                "options_list"
                              ][option.name] = selected_option;
                              setSelectedVariations([...selectedVariations]);
                            }}
                          >
                            <option value=""> Select {option.name}</option>
                            {category?.options?.map((opt, i) => {
                              return (
                                opt?.type_id == option?.id && (
                                  <option key={i} value={opt?.name}>
                                    {opt.name}
                                  </option>
                                )
                              );
                            })}
                          </select>
                        </td>
                      ))}
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="350"
                          defaultValue={variation.sku}
                          name="sku"
                          onChange={(e) =>
                            inputChangeHandler(e, variationIndex)
                          }
                        />
                      </td>
                      {/* <td className="align-middle">{variation?.id ?? "-"}</td> */}
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="350"
                          defaultValue={variation.mrp}
                          name="mrp"
                          onChange={(e) =>
                            inputChangeHandler(e, variationIndex)
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="350"
                          defaultValue={variation.selling_price}
                          name="selling_price"
                          onChange={(e) =>
                            inputChangeHandler(e, variationIndex)
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="350"
                          defaultValue={variation.group_price}
                          name="group_price"
                          onChange={(e) =>
                            inputChangeHandler(e, variationIndex)
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="350"
                          defaultValue={variation.stock}
                          name="stock"
                          onChange={(e) =>
                            inputChangeHandler(e, variationIndex)
                          }
                        />
                      </td>
                      <td
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(5,70px)",
                          gridTemplateRows: "1fr",
                          placeItems: "center",
                          position: "relative",
                        }}
                        className="pb-4"
                      >
                        {variation?.images.length < 3 && (
                          <div
                            className={`image-info ${
                              errorRow.includes(variationIndex)
                                ? "text-dark"
                                : ""
                            }`}
                          >
                            Upload atleast 3 images
                          </div>
                        )}
                        {Object.values(variation?.images)?.map((img, i) => (
                          <div
                            className="position-relative d-inline-block px-2 py-2"
                            key={i}
                          >
                            <img
                              src={imageSrc(img)}
                              alt="product_img"
                              style={{
                                objectFit: "cover",
                                height: "50px",
                                width: "40px",
                              }}
                              className="img-thumbnail img-fluid me-2"
                              key={i}
                            />
                            <span
                              class="position-absolute "
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "3px",
                                borderRadius: "50%",
                                color: "white",
                                top: "-3px",
                                left: "0px",
                                cursor: "pointer",
                                backgroundColor: "var(--orange-primary)",
                              }}
                              onClick={(e) => {
                                let deleted = [...deletedVariationImages];
                                deleted.push(img);
                                selectedVariations[
                                  variationIndex
                                ].images.splice(i, 1);
                                setDeletedVariationImages([...deleted]);
                                setSelectedVariations([...selectedVariations]);
                              }}
                            >
                              <IoIosClose />
                            </span>
                          </div>
                        ))}

                        {Object.values(variation?.images).length < 5 && (
                          <div>
                            <label
                              htmlFor={`upload-input-${variationIndex + 1}`}
                              className="cursor-pointer m-2"
                              // onClick={() => hiddenFileInput.current.click()}
                            >
                              <span
                                style={{ fontSize: "10px" }}
                                className="p-1 d-flex justify-content-center align-items-center  border border-2 rounded"
                              >
                                <BiImage size={20} />
                              </span>
                            </label>
                            <input
                              type="file"
                              hidden
                              name="img1"
                              id={`upload-input-${variationIndex + 1}`}
                              onChange={(event) => {
                                uploadFile(event, variationIndex);
                              }}
                              ref={hiddenFileInput}
                            />
                          </div>
                        )}
                      </td>
                      {!variation.id ? (
                        <td className="align-middle">
                          <IoMdClose
                            size={22}
                            className="me-2 cursor-pointer text-muted"
                            onClick={() => {
                              let tempVariations = [...selectedVariations];
                              tempVariations.splice(variationIndex, 1);
                              setSelectedVariations(tempVariations);
                            }}
                          />
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <>
              <Alert color="info">No Variations</Alert>
            </>
          )}
        </table>
      </StyledTable>
    </Box>
  );
};

export default forwardRef(InventoryVariations);
